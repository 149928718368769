<div [formGroup]="form" class="container1">
  <div class="left-side"></div>
  <div class="middle">
    <div class="workflow-grid" style="margin-bottom: 30px;">
      <div class="header-label">Work Flows</div>
      <div class="label-style" style="align-self: center;">New Category Name</div>

  <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field appearance="outline" class="medium-field">
      <input formControlName="name" matInput placeholder ="Name"
      [name]="ElementNameForId('162687')" autofill='off' autocomplete='new-password' data-lpignore='true'>
      <mat-error style="margin-top: 17px;">You must specify a name!</mat-error>
    </mat-form-field>
  </div>
  <div style="display: flex; grid-column: 2/3; margin-top: 15px;">
    <div>
      <mat-checkbox formControlName="displayInactiveForms">Display Inactive Catagories</mat-checkbox>
    </div>
    <div style="width: 100%; margin-left: 20px;">
      <mat-checkbox formControlName="active">Active</mat-checkbox>
    </div>
  </div>
  <div class="label-style" style="margin-top: 20px;">Category to Replace</div>
<app-workflow-settings-tree style="margin-top: 20px;" class="tree-width"
  (nodeClicked$)="nodeClicked$.next($event)"
  [checkListDatabase]="CheckListDatabase()"
></app-workflow-settings-tree>

<div class="header-label" style="border-top: gainsboro 2px solid;">Add Child</div>
<div [formGroup]="addChildFormGroup" style="display: contents;">
  <div class="label-style" style="align-self: center;">Name</div>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="outline" class="medium-field">
    <input formControlName="name" matInput placeholder ="Name"
    [name]="ElementNameForId('362687')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    <mat-error style="margin-top: 17px;">You must specify a name!</mat-error>
  </mat-form-field>
  <div style="margin-left: 50px;">
    <button mat-button class="button-light-blue" (click)="displayParentSelection=!displayParentSelection">Change Parent</button>
  </div>
</div>
</div>
<ng-container *ngIf="displayParentSelection" style="display: contents;">
  <div class="label-style" style="margin-top: 20px;">Change Parent Category</div>
  <app-workflow-settings-tree style="margin-top: 20px;" class="tree-width"
  (nodeClicked$)="parentNodeClicked$.next($event)"
  [checkListDatabase]="CheckListDatabase()"
></app-workflow-settings-tree>
</ng-container>

<ng-container [formGroup]="changeDefaultWorkflowFormGroup">
<div class="header-label" style="border-top: gainsboro 2px solid;">Default Workflow</div>
  <div class="label-style" style="align-self: center;">Current Default:</div>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="outline" class="medium-field">
    <input formControlName="currentDefaultWorkflowTitle" matInput placeholder ="Default Workflow"
    [name]="ElementNameForId('1362687')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    <mat-error style="margin-top: 17px;">You must choose a default workflow</mat-error>
  </mat-form-field>
  <div style="margin-left: 50px;">
    <button mat-button class="button-light-blue" (click)="loadWorkflowSelection('defaultWorkflow')">Change Default Workflow</button>
  </div>
</div>

  <div class="label-style" style="align-self: center;">Current Default Estimate:</div>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="outline" class="medium-field">
    <input formControlName="currentDefaultEstimateWorkflowTitle" matInput placeholder ="Default Estimate Workflow"
    [name]="ElementNameForId('1362681')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    <mat-error style="margin-top: 17px;">You must choose a default estimate workflow</mat-error>
  </mat-form-field>
  <div style="margin-left: 50px;">
    <button mat-button class="button-light-blue" (click)="loadWorkflowSelection('estimateWorkflow')">Change Estimate Workflow</button>
  </div>
</div>

<div class="label-style" style="align-self: center;">Current Default Invoice:</div>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="outline" class="medium-field">
    <input formControlName="currentDefaultInvoiceWorkflowTitle" matInput placeholder ="Default Invoice Workflow"
    [name]="ElementNameForId('13626833')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    <mat-error style="margin-top: 17px;">You must choose a default invoice workflow</mat-error>
  </mat-form-field>
  <div style="margin-left: 50px;">
    <button mat-button class="button-light-blue" (click)="loadWorkflowSelection('invoiceWorkflow')">Change Invoice Workflow</button>
  </div>
</div>
</ng-container>

<div class="header-label" style="border-top: gainsboro 2px solid;">Import Fields</div>
<div>
  <input type="file" id="files" (change)="importCsv($event)">
</div>

<!-- Cancel / Submit buttons. -->
  <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%;">
    <div class="button-position">
      <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
    </div>
    <div class="button-position">
      <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
    </div>
  </div>
  </div>
  <div class="right-side"></div>
  </div>
</div>
