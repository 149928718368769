import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, mergeMap, repeat, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Invoice } from '../../../../../../../common/src/data/dao/invoice';
import { InvoicePayment } from '../../../../../../../common/src/data/dao/invoice-payment';
import { InvoicePaymentService } from '../../../../../../../common/src/data/dao-services/invoice-payment.service';
import { InvoiceService } from '../../../../../../../common/src/data/dao-services/invoice.service';
import { AuthenticationService } from '../../../../../../../common/src/util/authentication.service';
import { CustomerCommunicationTemplateService } from '../../../../../../../common/src/data/dao-services/customer-communication-template.service';
import { Discount } from '../../../../../../../common/src/data/dao/discount';
import { DiscountService } from '../../../../../../../common/src/data/dao-services/discount.service';
import { EmployeeService } from '../../../../../../../common/src/data/dao-services/employee.service';
import { Estimate } from '../../../../../../../common/src/data/dao/estimate';
import { EstimateService } from '../../../../../../../common/src/data/dao-services/estimate.service';
import { DataUsedByDataLinkService } from '../../../../form-builder/data-link-populator/data-link.service';
import { FormModelFirestore } from '../../../../../../../common/src/data/dao/form-model-firestore';
import { JobService } from '../../../../../../../common/src/data/dao-services/job.service';
import { JobAttentionRequired } from '../../../../../../../common/src/data/dao/job-attention-required';
import { JobDurationDeltaModificationType } from '../../../../../../../common/src/data/dao/job-duration-delta';
import { LineItem } from '../../../../../../../common/src/data/dao/line-item';
import {LineItemRemovalWithReason } from '../../../../line-item/line-item-display/line-item-display.component';
import { LineItemService } from '../../../../../../../common/src/data/dao-services/line-item.service';
import { FormlyUtilityService } from '../formly-utility.service';
import { FormlyLineItemService } from './formly-line-item.service';
import { LineItemControlType } from '../../line-items/line-item-enums';
import { LocalSettingsService } from '../../../../settings/local-settings.service';
import { where } from 'firebase/firestore';

@Component({
  selector: 'app-formly-line-item',
  templateUrl: './formly-line-item.component.html',
  styleUrls: ['./formly-line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormlyLineItemComponent extends FieldType implements OnInit, OnDestroy, AfterViewInit {

  explicitRenderRows$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  lineItems$ : BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
  discounts$ : BehaviorSubject<Discount[]> = new BehaviorSubject<Discount[]>([]);
  invoicePayments$: BehaviorSubject<InvoicePayment[]> = new BehaviorSubject<InvoicePayment[]>([]);

  addLineItem$ = new Subject<LineItem>();
  removeLineItem$ = new Subject<LineItemRemovalWithReason>();
  editLineItem$ = new  Subject<{old: LineItem, new: LineItem}[]>();

  removeDiscount$ =  new Subject<Discount>();
  addDiscount$ = new Subject<Discount>();
  voidInvoicePayment$ = new Subject<InvoicePayment>();

  jobAttentionRequiredToAdd$ = new Subject<JobAttentionRequired>();
  activeSiteVisitViewingContextDocId: string = "";

  destroyingComponent$ = new Subject();

  get lineItemControlTypeClass() : string {
    switch (this.to.lineItemControlType) {
      case LineItemControlType.EstimateCreator:
        return "line-item-estimate";
      case LineItemControlType.InvoiceDisplay:
      case LineItemControlType.InvoiceEditor:
        return "line-item-invoice";
      case LineItemControlType.LineItemsToComplete:
        return "line-item-to-complete";
      default:
        return "";
    }
  }

  constructor(private estimateService: EstimateService, private jobService: JobService, private invoiceService: InvoiceService,
    private authenticationService: AuthenticationService, private employeeService: EmployeeService, private formlyLineItemService: FormlyLineItemService,
    private lineItemService: LineItemService, public formlyUtilityService:  FormlyUtilityService, private invoicePaymentService: InvoicePaymentService,
    private discountService: DiscountService, private fb: UntypedFormBuilder, private customerCommunicationTemplateService: CustomerCommunicationTemplateService,
    private localSettingsService: LocalSettingsService)
  {
    super();
  }

  createNewEstimate(): void {

    // we must check that estimate does not yet exist b/c it's creation is not (easily) made atomic to updateing the model of formModelFirestore.
    const existingEstimate = this.estimateService.queryFirestoreDeep$([where("jobDocId", "==", this.formlyLineItemService.job.DocId())]);

    const estimateExists = existingEstimate.pipe(
      filter(estimates => estimates.length !== 0),
      map(x => {
        // sort by when estimate was created with most recent first.  Needed b/c there is edge case where multiple estimates can be created if page reloads.
        return x.sort((b, a) => a.createdAt.getTime() - b.createdAt.getTime())[0];
      }),
      switchMap(x => this.estimateService.load$(x.docId))
    );

    const newEstimate = existingEstimate.pipe(
      filter(estimates => estimates.length === 0),
      tap(x => console.error("CREATING NEW ESTIMATE!")),
      map(() => {
        const estimate = new Estimate({customer: this.formlyLineItemService.job.billingCustomers[0], lineItems: this.lineItems$.value, job: this.formlyLineItemService.job,
          employee: this.employeeService.get(this.authenticationService.activelyLoggedInEmployeeDocId), serviceAddress: this.formlyLineItemService.job.serviceAddress,
          discounts: this.discounts$.value});
        return estimate;
      }),
      switchMap(estimate => this.estimateService.retrieveDocId(estimate).pipe(
        map(() => estimate)
      )),
      switchMap(estimate => this.customerCommunicationTemplateService.retrieveWorkPerformedTemplate().pipe(
        map(workflowTemplate => {
          estimate.formModelFirestore = workflowTemplate && workflowTemplate.inlineEstimate ? this.formlyUtilityService.activeFormModelFirestore :
            new FormModelFirestore({formFirestore: this.estimateService.estimateFormFirestore});
          return estimate;
        })
      )),
      switchMap(estimate => this.estimateService.create$(estimate)),
    );

    merge(estimateExists, newEstimate).pipe(
    tap(estimate => this.formControl.patchValue({docId: estimate.DocId(), lineItemIds: [], discountIds: [],invoicePaymentIds: []})),
    tap(estimate => this.formlyLineItemService.explicitEstimateDocId = estimate.DocId()),
    takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  createNewInvoice(): void {

    // we must check that invoice does not yet exist b/c it's creation is not (easily) made atomic to updateing the model of formModelFirestore.
    const existingInvoice = this.invoiceService.queryFirestoreDeep$([where("jobDocId", "==", this.formlyLineItemService.job.DocId())]);

    const invoiceExists = existingInvoice.pipe(
      filter(invoices => invoices.length !== 0),
      map(x => {
        // sort by when invoice was created with most recent first.  Needed b/c there is edge case where multiple estimates can be created if page reloads.
        return x.sort((b, a) => a.createdAt.getTime() - b.createdAt.getTime())[0];
      }),
      switchMap(x => this.invoiceService.load$(x.docId))
    );

    const newInvoice = existingInvoice.pipe(
      filter(invoices => invoices.length === 0),
      tap(x => console.error("CREATING NEW INVOICE!")),
      map(() => {
      const invoice = new Invoice({billingCustomer: this.formlyLineItemService.job.billingCustomers[0], employee: this.employeeService.get(this.authenticationService.activelyLoggedInEmployeeDocId),
      lineItems: this.lineItems$.value,discounts: this.discounts$.value, jobDocId: this.formlyLineItemService.job.DocId()});
      if (invoice.discounts.length === 0) {
        this.formlyLineItemService.job.discounts.forEach(d => invoice.discounts.push(d));
      }
      return invoice;
    }),
    switchMap(invoice => this.invoiceService.retrieveDocId(invoice).pipe(
      map(() => invoice)
    )),
    switchMap(invoice => this.customerCommunicationTemplateService.retrieveWorkPerformedTemplate().pipe(
        map(workflowTemplate => {
          invoice.formModelFirestore = workflowTemplate && workflowTemplate.inlineInvoice ? this.formlyUtilityService.activeFormModelFirestore :
          new FormModelFirestore({formFirestore: this.invoiceService.invoiceFormFirestore});
          return invoice;
        }),
      )),
      switchMap(invoice => this.invoiceService.create$(invoice)),
    );

    merge(invoiceExists, newInvoice).pipe(
      tap(invoice => this.formControl.patchValue({docId: invoice.DocId(), lineItemIds: [], discountIds: [],invoicePaymentIds: []})),
      tap(invoice => this.formlyLineItemService.explicitInvoiceDocId = invoice.DocId()),
      takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  retrieveLineItemsAndDiscountsFromEstimate() : Observable<{lineItems: LineItem[], discounts: Discount[], invoicePayments: InvoicePayment[]}> {
    return this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      filter(x=> x!== null && x !== undefined),
      map(x => x.docId),
      distinctUntilChanged(),
      switchMap(x => this.estimateService.load$(x)),
      filter(x => x !== null),
      tap(estimate => this.formlyLineItemService.estimate = estimate),
      map(estimate => {
        const lineItemCount = estimate.lineItems.length;
        const discountCount = estimate.discounts.length;
        const maxLineItemUpdatedAt = estimate.lineItems.length === 0 ? new Date(1979,12,3) : new Date(Math.max(...estimate.lineItems.filter(q => q.lastUpdatedAt).map(x => x.lastUpdatedAt.getTime())));
        const maxDiscountUpdatedAt = estimate.discounts.length === 0 ? new Date(1979,12,3) : new Date(Math.max(...estimate.discounts.filter(q => q.lastUpdatedAt).map(x => x.lastUpdatedAt.getTime())));
        const lastUpdated = new Date(Math.max(maxLineItemUpdatedAt.getTime(), maxDiscountUpdatedAt.getTime()));
        return {pass : {lineItems: estimate.lineItems, discounts: estimate.discounts, invoicePayments: []}, lineItemCount, discountCount, lastUpdated};
      }),
      distinctUntilChanged((prev,curr) => {
        if (prev.lineItemCount !== curr.lineItemCount || prev.discountCount !== curr.discountCount) {
          return false;
        }
        if (prev.lastUpdated.getTime() !== curr.lastUpdated.getTime()) {
          return false;
        }
        return true;
      }),
      map(x => x.pass),
      ) as Observable<{lineItems: LineItem[], discounts: Discount[], invoicePayments: InvoicePayment[]}>;
  }

  retrieveLineItemsAndDiscountsFromInvoice() : Observable<{lineItems: LineItem[], discounts: Discount[], invoicePayments: InvoicePayment[]}> {
    return this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      filter(x=> x!== null && x !== undefined),
      map(x => x.docId),
      distinctUntilChanged(),
      switchMap(x => this.invoiceService.load$(x)),
      filter(x => x.DocId() !== undefined),
      tap(invoice => {
        this.formlyLineItemService.invoice = invoice;
      }),
      switchMap(x => this.invoicePaymentService.queryFirestoreDeep$([where('invoiceDocId', '==', x.DocId())])),
      map(x=> x.filter(y => !y.void)),
      map(invoicePayments => {
        this.localSettingsService.extraLogInfo = ['retrieveLineItemsAndDiscountsFromInvoice',JSON.stringify(this.formlyLineItemService.invoice)];
        const lineItemCount = this.formlyLineItemService.invoice.lineItems.length;
        const discountCount = this.formlyLineItemService.invoice.discounts.length;
        const maxLineItemUpdatedAt = this.formlyLineItemService.invoice.lineItems.length === 0 ? new Date(1979,12,3) : new Date(Math.max(...this.formlyLineItemService.invoice.lineItems.map(x => x.lastUpdatedAt.getTime())));
        const maxDiscountUpdatedAt = this.formlyLineItemService.invoice.discounts.length === 0 ? new Date(1979,12,3) : new Date(Math.max(...this.formlyLineItemService.invoice.discounts.map(x => x.lastUpdatedAt.getTime())));
        const maxPaymentsUpdatedAt = invoicePayments.length === 0 ? new Date(1979,12,3) : new Date(Math.max(...invoicePayments.map(i => i.lastUpdatedAt.getTime())));
        const payments = [...invoicePayments];
        const lastUpdated = new Date(Math.max(maxLineItemUpdatedAt.getTime(), maxDiscountUpdatedAt.getTime(), maxPaymentsUpdatedAt.getTime()));
        this.localSettingsService.extraLogInfo = null;
        return {pass: {lineItems: this.formlyLineItemService.invoice.lineItems, discounts: this.formlyLineItemService.invoice.discounts, invoicePayments: invoicePayments}, lineItemCount, discountCount, lastUpdated, payments};
      }),
      distinctUntilChanged((prev,curr) => {
        if (prev.lineItemCount !== curr.lineItemCount || prev.discountCount !== curr.discountCount || prev.payments !== curr.payments) {
          return false;
        }
        if (prev.lastUpdated.getTime() !== curr.lastUpdated.getTime()) {
          return false;
        }
        return true;
      }),
      map(x => x.pass),
      ) as Observable<{lineItems: LineItem[], discounts: Discount[], invoicePayments: InvoicePayment[]}>;
  }

  retrieveLineItemsFromJob() : Observable<LineItem[]> {

    const start = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      filter(x=> x!== null && x !== undefined));

    return start.pipe(
      map(x => x.docId),
      distinctUntilChanged(),
      filter(x=> x!== null && this.to.lineItemControlType === LineItemControlType.LineItemsToComplete),
      switchMap(x => this.jobService.load$(x)),
      filter(x => x !== null ),
      map(val => {
        const job = val;
        this.formlyLineItemService.job = job;
        return job;
      }),
      map(job => job.lineItems.concat(job.abandonedLineItems)),
      map(lineItems => {
        const lastUpdated = new Date(Math.max(...lineItems.map(x => x.lastUpdatedAt.getTime())));
        return {lineItems: lineItems, lastUpdated: lastUpdated};
      }),
      distinctUntilChanged((prev,curr) => {
        if (prev.lastUpdated.getTime() !== curr.lastUpdated.getTime()) {
          return false;
        }
        return true;
      }),
      map(x => x.lineItems),
    ) as Observable<LineItem[]>;
  }

  subscribeToLineItemsRestoredToJob() : void {

    this.formlyLineItemService.restoreAbandonedLineItem$.pipe(
    map(x => {
      const abandonedIndex = this.formlyLineItemService.job.abandonedLineItems.findIndex(y => y.DocId() === x.lineItem.DocId());
      const abandonedLineItem = this.formlyLineItemService.job.abandonedLineItems.splice(abandonedIndex, 1)[0];
      const mutateAbandonedToAllowRollback = new LineItem(abandonedLineItem);
      mutateAbandonedToAllowRollback.abandoned = false;
      mutateAbandonedToAllowRollback.lineItemNeedsOrHasBeenRescheduled = false;
      if (mutateAbandonedToAllowRollback.originatingLineItemDocId === null) {
        mutateAbandonedToAllowRollback.originatingLineItemDocId = abandonedLineItem.lineItemDocId;
      }
      this.formlyLineItemService.job.lineItems.push(mutateAbandonedToAllowRollback);
      return {lineItem: mutateAbandonedToAllowRollback, x};
    }),
    switchMap(val => this.lineItemService.retrieveDocId(val.lineItem).pipe(
      map(() => val.x)
    )),
    map(x => {
      const delta = this.formlyLineItemService.job.jobDurationDeltas.filter(z => (z.originatingLineItemDocId === x.lineItem.DocId() || z.originatingLineItemDocId === x.lineItem.originatingLineItemDocId) &&
        z.siteVisitDocId === x.siteVisitDocId && z.active &&
        z.modificationType === JobDurationDeltaModificationType.FIELDTECHTIMEADDITION).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0];
        if (delta) {
          delta.active = false;
        }
      }),
    mergeMap(() => this.jobService.update$(this.formlyLineItemService.job).pipe(
      take(1)
    )),
    tap(() => {
        this.formControl.patchValue({docId: this.formlyLineItemService.job.DocId(),
          lineItemIds: this.formlyLineItemService.job.lineItems.map(q => q.DocId()).concat(this.formlyLineItemService.job.abandonedLineItems.map(q => q.DocId())),
          discountIds: [], invoicePaymentIds: []});
      }),
    takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.formlyLineItemService.restoreRescheduledLineItem$.pipe(
      map(x => {
          const delta = this.formlyLineItemService.job.jobDurationDeltas.filter(z => (z.originatingLineItemDocId === x.lineItem.DocId() || z.originatingLineItemDocId === x.lineItem.originatingLineItemDocId) &&
            z.siteVisitDocId === x.siteVisitDocId && z.active &&
            z.modificationType === JobDurationDeltaModificationType.FIELDTECHTIMEADDITION).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0];
            if (delta) {
              delta.active = false;
            }
          const jobAttentionRequred = this.formlyLineItemService.job.jobAttentionRequired.filter(z => (z.originatingLineItemDocId === x.lineItem.DocId() || z.originatingLineItemDocId === x.lineItem.originatingLineItemDocId) &&
            z.siteVisitDocId === x.siteVisitDocId && z.active).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0];
          if (jobAttentionRequred) {
            jobAttentionRequred.active = false;
          }
          // if removing the delta means job no longer has time which needs schedule, update needsAssigned.
          if (this.formlyLineItemService.job.remainingToScheduleHours <= 0) {
            this.formlyLineItemService.job.needsAssigned = false;
          }
          return this.formlyLineItemService.job;
      }),
      mergeMap(() => this.jobService.update$(this.formlyLineItemService.job).pipe(
        take(1)
      )),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  ngAfterViewInit(): void {

  if (this.formControl.value === undefined || this.formControl.value === null ) {
    if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
      this.createNewEstimate();
    } else {
      if (this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
        this.createNewInvoice();
      } else {
          throw new Error('No way to instantiate line item control in completion mode w/o active job.');
      }
    }
  }
      let lineItemObservable: Observable<{lineItems: LineItem[], discounts: Discount[], invoicePayments: InvoicePayment[]}>;
    switch (this.to.lineItemControlType) {
      case LineItemControlType.EstimateCreator:
        lineItemObservable = this.retrieveLineItemsAndDiscountsFromEstimate();
        break;
      case LineItemControlType.InvoiceDisplay:
        lineItemObservable = this.retrieveLineItemsAndDiscountsFromInvoice();
        break;
      case LineItemControlType.LineItemsToComplete:
        this.retrieveLineItemsFromJob().pipe(
          delay(0),
          tap(l => this.lineItems$.next(l)),
          takeUntil(this.destroyingComponent$)
        ).subscribe();

        this.subscribeToLineItemsRestoredToJob();
        break;
      default:
        throw new Error(`Line item control type ${this.to.lineItemControlType} is not supported.`);
        }

      if (lineItemObservable) {
        lineItemObservable.pipe(
          delay(0),
          tap(l => {
            this.lineItems$.next(l.lineItems);
            this.discounts$.next(l.discounts);
            this.invoicePayments$.next(l.invoicePayments);
          }),
          takeUntil(this.destroyingComponent$)
        ).subscribe();
      }
}

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
}

spliceIfPresent(array: string[], docId: string): string[] {
  const index = array.indexOf(docId);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}

   ngOnInit(): void {

    if (this.formControl.value) {
      if ((this.to.lineItemControlType === LineItemControlType.InvoiceDisplay ||
        this.to.lineItemControlType === LineItemControlType.InvoiceEditor)) {
          this.formlyLineItemService.explicitInvoiceDocId = this.formControl.value.docId;
        }
      if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
        this.formlyLineItemService.explicitEstimateDocId = this.formControl.value.docId;
      }
    }

    if (this.to.disabled) {
      return;
    }
    if ( (!this.formControl.value) && this.formlyLineItemService.explicitEstimateDocId &&
      this.to.lineItemControlType === LineItemControlType.EstimateCreator)  {
      this.formControl.patchValue({docId: this.formlyLineItemService.explicitEstimateDocId, lineItemIds: [], discountIds: [], invoicePaymentIds: []});
    }
    if ( (!this.formControl.value) && this.formlyLineItemService.explicitInvoiceDocId &&
      (this.to.lineItemControlType === LineItemControlType.InvoiceDisplay ||
      this.to.lineItemControlType === LineItemControlType.InvoiceEditor))  {
        this.formControl.patchValue({docId: this.formlyLineItemService.explicitInvoiceDocId, lineItemIds: [], discountIds: [], invoicePaymentIds: []});
    }

    if (this.to.dataLinkSourceData) {
      const dataToInstantiate: DataUsedByDataLinkService = this.to.dataLinkSourceData;
       const jobDocId = dataToInstantiate.jobId;
       this.activeSiteVisitViewingContextDocId = dataToInstantiate.activeSiteVisitDocId;

      if (this.to.lineItemControlType !== LineItemControlType.LineItemsToComplete) {
        this.jobService.load$(jobDocId).pipe(
          // take(1)
          takeUntil(this.destroyingComponent$)
        ).subscribe(x => {
          this.formlyLineItemService.job = x;
        });
      }
      if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
        if (this.formControl.value == null) {
          this.jobService.load$(jobDocId).pipe(
            take(1),
          ).subscribe(x => this.formControl.patchValue({docId: jobDocId,
            lineItemIds: x.lineItems.map(q => q.DocId()),
            discountIds: [], invoicePaymentIds: []}));
        }
      }
    }

    //Initilize adding line items to formlyLineItemService
      this.addLineItem$.pipe(
        tap(lineItemToAdd => {
          if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
            this.formlyLineItemService.addLineItemsToJob$.next([lineItemToAdd]);
          }
          if (this.to.lineItemControlType === LineItemControlType.InvoiceEditor || this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
            this.formlyLineItemService.addLineItemsToInvoice.next([lineItemToAdd]);
          }
          if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
            this.formlyLineItemService.addLineItemsToEstimate$.next([lineItemToAdd]);
          }
          this.formControl.patchValue({docId: this.formControl.value.docId, discountIds: this.formControl.value.discountIds,
            lineItemIds: [lineItemToAdd.DocId()].concat(this.formControl.value.lineItemIds), invoicePaymentIds: this.formControl.value.invoicePaymentIds});
        }),
        takeUntil(this.destroyingComponent$)
        ).subscribe();

        //Initilize adding discounts to formlyLineItemService
        this.addDiscount$.pipe(
          tap(discountToAdd => {
            if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
              this.formlyLineItemService.addDiscountsToJob$.next([discountToAdd]);
            }
            if (this.to.lineItemControlType === LineItemControlType.InvoiceEditor || this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
              this.formlyLineItemService.addDiscountsToInvoice$.next([discountToAdd]);
            }
            if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
              this.formlyLineItemService.addDiscountsToEstimate$.next([discountToAdd]);
            }
            this.formControl.patchValue({docId: this.formControl.value.docId, discountIds: [discountToAdd.DocId()].concat(this.formControl.value.discountIds),
              lineItemIds: this.formControl.value.lineItemIds, invoicePaymentIds: this.formControl.value.invoicePaymentIds});
          }),
          takeUntil(this.destroyingComponent$)
          ).subscribe();

        //Initilize removing discounts to formlyLineItemService
        this.removeDiscount$.pipe(
          tap(discountToRemove => {
            if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
              this.formlyLineItemService.removeDiscountsFromJob$.next([discountToRemove]);
            }
            if (this.to.lineItemControlType === LineItemControlType.InvoiceEditor || this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
              this.formlyLineItemService.removeDiscountsFromInvoice$.next([discountToRemove]);
            }
            if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
              this.formlyLineItemService.removeDiscountsFromEstimate$.next([discountToRemove]);
            }
            this.formControl.patchValue({docId: this.formControl.value.docId, discountIds: this.spliceIfPresent((this.formControl.value.discountIds as Array<string>),discountToRemove.DocId()),
              lineItemIds: this.formControl.value.lineItemIds, invoicePaymentIds: this.formControl.value.invoicePaymentIds});
          }),
          takeUntil(this.destroyingComponent$)
        ).subscribe();

        //Initilize removing invoice payments to formlyLineItemService
        this.voidInvoicePayment$.pipe(
          tap(invoicePaymentToRemove => {
            this.formlyLineItemService.voidInvoicePaymentsFromInvoice$.next([invoicePaymentToRemove]);
          }),
          takeUntil(this.destroyingComponent$)
        ).subscribe();


        //Initilize removing line items to formlyLineItemService
        this.removeLineItem$.pipe(
          tap(lineItemToRemove => {
            if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
              this.formlyLineItemService.removeLineItemsFromJob$.next([lineItemToRemove]);
            }
            if (this.to.lineItemControlType === LineItemControlType.InvoiceEditor || this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
              this.formlyLineItemService.removeLineItemsFromInvoice$.next([lineItemToRemove]);
            }
            if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
              this.formlyLineItemService.removeLineItemsFromEstimate$.next([lineItemToRemove]);
            }
            this.formControl.patchValue({docId: this.formControl.value.docId, discountIds: this.formControl.value.discountIds,
              lineItemIds: this.spliceIfPresent((this.formControl.value.lineItemIds as Array<string>),lineItemToRemove.lineItem.DocId()), invoicePaymentIds: this.formControl.value.invoicePaymentIds});
          }),
          takeUntil(this.destroyingComponent$)
        ).subscribe();

        //Initilize editing line items to formlyLineItemService
        this.editLineItem$.pipe(
          tap(lineItemsToEdit => {
            if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
              this.formlyLineItemService.editLineItemsToJob$.next(lineItemsToEdit);
            }
            if (this.to.lineItemControlType === LineItemControlType.InvoiceEditor || this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
              this.formlyLineItemService.editLineItemsToInvoice$.next(lineItemsToEdit[0]);
            }
            if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
              this.formlyLineItemService.editLineItemsToEstimate$.next(lineItemsToEdit);
            }
            let existingLineItemDocIds = (this.formControl.value.lineItemIds as Array<string>);
            lineItemsToEdit.forEach(l => {
              existingLineItemDocIds = this.spliceIfPresent(existingLineItemDocIds,l.old.DocId());
              existingLineItemDocIds = existingLineItemDocIds.concat(l.new.DocId());
            });
            // if there are any new line item ids.
            if ((this.formControl.value.lineItemIds as string[]).some(z => !existingLineItemDocIds.includes(z)) ||
            existingLineItemDocIds.some(z => !(this.formControl.value.lineItemIds as string[]).includes(z))) {
            this.formControl.patchValue({docId: this.formControl.value.docId, discountIds: this.formControl.value.discountIds,
              lineItemIds: existingLineItemDocIds, invoicePaymentIds: this.formControl.value.invoicePaymentIds});
            }
          }),

          takeUntil(this.destroyingComponent$)
        ).subscribe();

        //Initilize editing discounts to formlyLineItemService
        if (!this.to.disabled) {
          this.formlyLineItemService.editDiscountReferencesThroughoutForms$.pipe(
            tap(x => {
              if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
                this.formlyLineItemService.editDiscountToJob$.next(x);
              }
              if (this.to.lineItemControlType === LineItemControlType.InvoiceEditor || this.to.lineItemControlType === LineItemControlType.InvoiceDisplay) {
                this.formlyLineItemService.editDiscountToInvoice$.next(x);
              }
              if (this.to.lineItemControlType === LineItemControlType.EstimateCreator) {
                this.formlyLineItemService.editDiscountToEstimate$.next(x);
              }
              let existingDiscountDocIds = (this.formControl.value.discountIds as Array<string>);
              existingDiscountDocIds = this.spliceIfPresent(existingDiscountDocIds,x.old.DocId());
              existingDiscountDocIds = existingDiscountDocIds.concat(x.new.DocId());
            this.formControl.patchValue({docId: this.formControl.value.docId, discountIds: existingDiscountDocIds,
              lineItemIds: this.formControl.value.lineItemIds, invoicePaymentIds: this.formControl.value.invoicePaymentIds});
            }),
          takeUntil(this.destroyingComponent$)
          ).subscribe();
        }

    // LINE ITEMS TO COMPLETE
      if (this.to.lineItemControlType === LineItemControlType.LineItemsToComplete) {
        this.jobAttentionRequiredToAdd$.pipe(
          mergeMap(j => this.jobService.addJobAttentionRequired(j, JobDurationDeltaModificationType.FIELDTECHTIMEADDITION, this.formlyLineItemService.job).pipe(
            take(1)
          ))
        ).subscribe();
      }
  }
}
