import { Injectable } from '@angular/core'
import { DatabaseStoreService } from '../../database-backend/database-store.service'
import { AuthenticationService } from '../../../util/authentication.service'
import { StateChangeStoreService } from '../../database-backend/state-change-store.service'
import { FirestoreDiffService } from '../firestore-diff.service'
import { FirestoreBackend } from '../../database-backend/retrieve-from-firestore'
import { PriceBook } from '../../dao/pricebook/pricebook'
import { PriceBookEntryService } from './pricebook-entry.service'

@Injectable({
  providedIn: 'root'
})
export class PriceBookService extends DatabaseStoreService<PriceBook> {

  constructor( fs: PriceBookFirestoreService, store: PriceBookStoreService, authenticationService: AuthenticationService, private priceBookEntryService: PriceBookEntryService) {
super(fs, store, authenticationService,new Map([
    ["priceBookEntries", {associatedDocumentId: "priceBookEntryDocIds", compositionStoreService: priceBookEntryService}]])
);
  }

}

@Injectable({
  providedIn: 'root'
})
export class PriceBookStoreService extends StateChangeStoreService<PriceBook> {
  protected store = "PriceBook-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, PriceBook>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class PriceBookFirestoreService extends FirestoreBackend<PriceBook> {

 protected basePath = "PriceBook";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): PriceBook {
return new PriceBook(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new PriceBook(), authService);
 }
}
