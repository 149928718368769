<app-navigation></app-navigation>
<!-- <button mat-button class="button-green" (click)="Test()">Test Rules</button> -->
<div *ngIf="settingsService.settingsLoaded$">
  <form class="container1" [formGroup]="employeeFormGroup">
    <div class="left-side"></div>
    <div class="middle">
      <div class="employee-grid" style="margin-bottom: 30px;" >
        <div class="header-style">Employee Selection</div>
        <div class="label-style" fxLayout="row" fxLayoutAlign="end start">Employee</div>
        <div>
          <div style="display: flex;">
        <div [formGroup]="form">
          <mat-form-field appearance="outline" class="form-align medium-field">
            <mat-select formControlName="employee" placeholder="Select Employee" [compareWith]="compareWithDocIds">
              <mat-option *ngFor="let employee of employeesToDisplay.controls" [value]="employee.value" >
                {{employee.value.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <button type="button" mat-button style="margin-left: 30px !important;" class="button-green" (click)="NewEmployee()">Add New Employee</button>
        </div>
        <div [formGroup]="form">
          <mat-checkbox formControlName="showAllEmployees">
            Show inactive employees
          </mat-checkbox>
        </div>
        </div>
        </div>





        <div class="employee-grid employee-grid-styling" >
        <div class="header-style">Employee Contact</div>
        <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Name</div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="medium-field">
            <input [name]="generateRandomElementName('1')" formControlName="name" autofill='off' autocomplete='new-password' data-lpignore='true' matInput placeholder="Placeholder">
            <mat-error>Employee name is required.</mat-error>
          </mat-form-field>
        </div>

        <div class="label-style" fxLayout="row" fxLayoutAlign="end center">E-mail</div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline" class="medium-field">
                <input type="email" formControlName="email" [name]="generateRandomElementName('2')" autofill='off' autocomplete='new-password' data-lpignore='true' matInput placeholder="Email Address">
                <mat-error>A valid E-mail address is required.</mat-error>
            </mat-form-field>
        </div>
        <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Phone Number</div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="medium-field">
              <input matInput formControlName="phoneNumber" mask="(000) 000-0000"
              [name]="generateRandomElementName('3')" autofill='off' autocomplete='new-password' data-lpignore='true' placeholder="Placeholder">
          </mat-form-field>
        </div>

        <!--Begin Employee Attributes section.-->
        <div class="header-style">Employee Attributes</div>

        <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Employee Location</div>
          <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="medium-field">
            <mat-select formControlName="employeeLocation" placeholder="Choose Location" [compareWith]="compareWithDocIds">
              <mat-option *ngFor="let loc of employeeLocations.loadAll$() | async" [value]="loc">{{loc.name}}</mat-option>
            </mat-select>
            <mat-error>Employee must be assigned a location.</mat-error>
        </mat-form-field>
        </div>

        <!-- Employee Role -->
          <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Employee Role</div>
          <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="medium-field">
            <mat-select formControlName="employeeRole" placeholder="Choose Role" [compareWith]="compareWithDocIds">
              <mat-option *ngFor="let role of employeeRoles.loadAll$() | async" [value]="role">{{role.name}}</mat-option>
            </mat-select>
            <mat-error>Employee must be assigned a role.</mat-error>
        </mat-form-field>
        <mat-checkbox style="margin-left: 30px;" class="label-style" formControlName="scheduleFieldCallsFor" >Schedule for field calls</mat-checkbox>
        </div>

         <!-- Employee Status -->
         <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Status</div>
         <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
           <mat-form-field appearance="outline">
             <mat-select formControlName="active" placeholder="Choose One" >
               <mat-option [value]="true">Active</mat-option>
               <mat-option [value]="false">Inactive</mat-option>
             </mat-select>
           </mat-form-field>
           </div>


        <!-- Employee Skills -->
        <div style="margin-top: 10px;" class="label-style" fxLayout="row" fxLayoutAlign="end start">Skills</div>
        <app-mulliselect-nested-tree
          [checkListDatabase]="EmployeeSkillCheckListDatabase()"
          (itemsAddedToSelection$)="skillAddedToEmployee($event)"
          (itemsRemovedFromSelection$)="skillRemovedFromEmployee($event)"
          ></app-mulliselect-nested-tree>


        <!-- Employee Privelages -->
        <div style="margin-top: 30px;" class="label-style" fxLayout="row" fxLayoutAlign="end start">Privileges</div>
        <app-mulliselect-nested-tree style="margin-top: 20px;"
          (itemsAddedToSelection$)="permissionAdded$.next($event)"
          (itemsRemovedFromSelection$)="permissionRemoved$.next($event)"
          [checkListDatabase]="CheckListDatabase()"
        ></app-mulliselect-nested-tree>

        <div class="header-style">Employee Commute</div>
        <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Starting Address</div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" style="width: 330px;">
            <input matInput placeholder="Starting Address" formControlName="dispatchOrginAddressDisplay" readonly=true>
          </mat-form-field>
          <button type="button" mat-button style="margin-left: 30px !important;" class="button-light-blue" (click)="UpdateAddress('Starting Address')">Change Start Address</button>
        </div>
        <div class= "label-style" fxLayout="row" fxLayoutAlign="end start" style="padding-top: 3px;">Drive Scheduling</div>
        <mat-radio-group class="example-radio-group" formControlName="dispatchOrginAddressCommuteDispensation">
          <mat-radio-button class="example-radio-button" style="margin-bottom: 15px;" value="Working">Employee begins work at starting address <mat-icon class="mat-icon-radio-position" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Field Technician’s commute time from their starting address is added to their total work time for the day.'
>info</mat-icon></mat-radio-button>
          <mat-radio-button class="example-radio-button" value="Commuting">Employee begins work at first site visit <mat-icon class="mat-icon-radio-position" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Field Technician’s commute time from their starting address is not added to their total work time for the day.'
>info</mat-icon></mat-radio-button>
        </mat-radio-group>
        <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Ending Address</div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" style="width: 330px;">
            <input matInput placeholder="Ending Address" formControlName="dispatchDestinationAddressDisplay" readonly=true>
          </mat-form-field>
          <button type="button" mat-button style="margin-left: 30px !important;" class="button-yellow" (click)="UpdateAddress('Ending Address')">Change End Address</button>
        </div>
        <div class= "label-style" fxLayout="row" fxLayoutAlign="end start" style="padding-top: 3px;">Drive Scheduling</div>
        <mat-radio-group class="example-radio-group" formControlName="dispatchDestinationAddressCommuteDispensation">
          <mat-radio-button class="example-radio-button" style="margin-bottom: 15px;" value="Working">Employee workday ends at ending address <mat-icon class="mat-icon-radio-position" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Field Technician’s commute time from last job to their ending address is added to their total work time for the day.'
>info</mat-icon></mat-radio-button>
          <mat-radio-button class="example-radio-button" value="Commuting">Employee workday ends upon leaving last site visit <mat-icon class="mat-icon-radio-position" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Field Technician’s commute time from last job to their ending address is not added to their total work time for the day.'
>info</mat-icon></mat-radio-button>
        </mat-radio-group>

          <div class="header-style">Employee Schedule</div>
          <div *ngFor = "let day of workWeekToRender()" style="display: contents;">
            <mat-checkbox class="label-style" [checked]="day.activeWorkDay" (change)="setCheckDay(day)">{{day.date | date:'EEEE'}}</mat-checkbox>
            <div style="display: flex; flex-direction: column;">
            <div class="time-field-specificity" style="display: flex;">

            <app-time-field
              [value]= "day.workStartTime"
              [fieldLabel]= "'Start'"
              [readOnly]="!day.activeWorkDay"
              [granularity]="15"
              [minDate] = "minDateToDisplay"
              [maxDate]="maxDateToDisplay"
            ></app-time-field>

            <div class="to-style">To</div>
            <app-time-field
              [value]= "day.workEndTime"
              [fieldLabel]= "'End'"
              [readOnly]="!day.activeWorkDay"
              [granularity]="15"
              [minDate] = "minDateToDisplay"
              [maxDate]="maxDateToDisplay"
            ></app-time-field>
            <div class="label-style" style="margin-left: 30px;">{{GetHoursScheduled(day)}}</div>
</div>
            <div style="display: flex;">
            <div *ngIf="!day.startEndTimesValidate" style="color: #f44336;" class="alert alert-danger">
              Start of work day must be before the end of the work day.
          </div>

            </div>
            
          </div>
        </div>

      <div class="label-style" style="grid-column: 2/3; text-align: left; font-weight: bold;">{{GetTotalHoursScheduled(workWeekToRender())}}</div>


      <div><button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button></div>
      <div style="display: flex; justify-content: flex-end;"><button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button></div>
      <!-- <button type="button" (click)="Test()">Test</button> -->
    </div>
  </div>
  </form>
</div>
