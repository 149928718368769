import { Injectable } from '@angular/core';
import { subMinutes } from 'date-fns';
import {  merge, Observable, of,  ReplaySubject,  zip } from 'rxjs';
import {  filter, map, mergeMap, share, switchMap, take, tap } from 'rxjs/operators';
import { Invoice } from '../../../../common/src/data/dao/invoice';
import { AssignmentService } from '../../../../common/src/data/dao-services/assignment.service';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';
import { Employee } from '../../../../common/src/data/dao/employee';
import { EmployeeService } from '../../../../common/src/data/dao-services/employee.service';
import { Estimate } from '../../../../common/src/data/dao/estimate';
import { DataLinkService, DataUsedByDataLinkService } from '../form-builder/data-link-populator/data-link.service';
import { Job } from '../../../../common/src/data/dao/job';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';
import { SettingsService } from '../settings/settings.service';
import { CustomerCommuncationStatus, CustomerCommunication } from '../../../../common/src/data/dao/customer-communication';
import { CustomerCommunicationScheduling } from '../../../../common/src/data/dao/customer-communication-scheduling';
import { CustomerCommunicationSchedulingService } from '../../../../common/src/data/dao-services/customer-communication-scheduling.service';
import { CustomerCommunicationCategory, CustomerCommunicationPurpose, CustomerCommunicationTemplate, CustomerCommunicationType } from '../../../../common/src/data/dao/customer-communication-template';
import { CustomerCommunicationTemplateService } from '../../../../common/src/data/dao-services/customer-communication-template.service';
import { CustomerCommunicationService } from '../../../../common/src/data/dao-services/customer-communication.service';
import { CustomerService } from '../../../../common/src/data/dao-services/customer.service';
import { where } from 'firebase/firestore';
import { FirestoreBackend } from '../../../../common/src/data/database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationManagementService {

  constructor(private customerCommunicationSchedulingService: CustomerCommunicationSchedulingService,
    private customerCommunicationTemplateService: CustomerCommunicationTemplateService,
    private customerCommunicationService: CustomerCommunicationService, private employeeService: EmployeeService, private assignmentService:
    AssignmentService, private settingsService: SettingsService, private auth: AuthenticationService, private customerService: CustomerService) {

      if (this.auth.mobileSite) {
        this.customerCommunicationSchedulingService.loadAll$(false).pipe(
          take(1)
          ).subscribe();
      } else {
        this.customerCommunicationSchedulingService.loadAll$().subscribe();
      }
    }

   private buildCustomerCommunications(template: CustomerCommunicationTemplate, schedule: CustomerCommunicationScheduling,
    workflowDocId: string, job: Job | undefined, siteVisit: SiteVisit | undefined, source: string, generatePdfFromWorkflow: boolean = false,
     pdfUrl: string | null = null, invoiceDocId: string | null = null, estimateDocId: string | null = null, workflowUpdatedDate: Date | null = null,
    invoice: Invoice | undefined, explicitEmployee: Employee | null = null,
    explicitCustomerDocId: string | undefined = undefined) : Observable<CustomerCommunication[]> {
    // console.error("BUILD CUSTOMER COMMUNICATIONS CALLED");
    // return of([]);
    // let activeJob : Job = this.jobFirestoreService.getValueIfCached(jobDocId);
    let activeEmployee = undefined;
    let activeJob: Job = job;

    let employeeRetrieved : Observable<any>;

    if (explicitEmployee) {
      activeEmployee = explicitEmployee;
      employeeRetrieved = of(undefined);
    } else {
      if (siteVisit.assignments !== undefined && siteVisit.assignments.length > 0) {
        employeeRetrieved = this.employeeService.load$(siteVisit.assignments[0].employeeDocId);
      } else {
        employeeRetrieved = this.assignmentService.queryFirestoreShallow$([where("siteVisitDocId", "==", siteVisit.DocId())]).pipe(
          switchMap(assignments => this.employeeService.load$(assignments[0].employeeDocId))
        );
      }
      employeeRetrieved = employeeRetrieved.pipe(
        tap(e => activeEmployee = e),
        // switchMap(() => this.invoiceService.queryFirestoreShallow$([where("jobDocId", "==", job.DocId())])),
      );
    }


    return employeeRetrieved.pipe(
        // map(invoice =>  (invoice && invoice.length > 0) ? invoice[0] : undefined),
        // switchMap(i => i === undefined ? of (undefined) : this.invoiceService.load$(i?.docId)),
        map(() => new DataUsedByDataLinkService({customer: activeJob ? activeJob.customer : this.customerService.get(explicitCustomerDocId),
          firstSiteVisit: activeJob?.siteVisits.find(x => x.DocId() === activeJob.firstSiteVisitDocId),
          job: activeJob, activeSiteVisit : siteVisit, invoice: invoice, activeSiteVisitEmployee: activeEmployee })),
        // get firestore doc id.
        switchMap(s => this.customerCommunicationService.retrieveFirestoreRawDocId().pipe(
          map(x => {
            return {uniqueCommunicationIdAcrossChannels: x, val: s}
          })
          )),
          map( x => {
          const retVal : CustomerCommunication[] = [];
          const dataLinkSourceData = x.val;
          const uniquePerCommunicationRequestAcrossChannels : string =  x.uniqueCommunicationIdAcrossChannels;
          if (template.customerCommuncationType === CustomerCommunicationType.EMAIL) {
            this.addEmailCommunication(retVal, template, schedule, workflowDocId, job.DocId(), siteVisit,
              generatePdfFromWorkflow, dataLinkSourceData, activeJob, uniquePerCommunicationRequestAcrossChannels, source, explicitCustomerDocId,
                pdfUrl, invoiceDocId, estimateDocId,  workflowUpdatedDate);
          } else {
            if (template.customerCommuncationType === CustomerCommunicationType.SMS) {
              this.addSmsCommunications(retVal, template, schedule, workflowDocId, job.DocId(), siteVisit, generatePdfFromWorkflow, dataLinkSourceData, activeJob,
                uniquePerCommunicationRequestAcrossChannels, source, explicitCustomerDocId, pdfUrl, invoiceDocId, estimateDocId, workflowUpdatedDate);
            }
            if (template.customerCommuncationType === CustomerCommunicationType.BOTH) {
              this.addEmailCommunication(retVal, template, schedule, workflowDocId, job.DocId(), siteVisit, generatePdfFromWorkflow,
                dataLinkSourceData, activeJob, uniquePerCommunicationRequestAcrossChannels, source, explicitCustomerDocId, pdfUrl, invoiceDocId,
                  estimateDocId, workflowUpdatedDate);
              this.addSmsCommunications(retVal, template, schedule, workflowDocId, job.DocId(), siteVisit, generatePdfFromWorkflow,
                dataLinkSourceData, activeJob, uniquePerCommunicationRequestAcrossChannels, source, explicitCustomerDocId, pdfUrl, invoiceDocId,
                estimateDocId, workflowUpdatedDate);
            }
          }
          retVal.forEach(c => c.primaryCustomer = this.customerService.get(c.customerDocId));
          return retVal;
        }),
      take(1)
      );
    }

  private addSmsCommunications(retVal: CustomerCommunication[], template: CustomerCommunicationTemplate, schedule: CustomerCommunicationScheduling,
    workflowDocId: string, jobDocId: string | undefined, siteVisit: SiteVisit | undefined, generatePdfFromWorkflow: boolean,
    dataLinkSourceData: DataUsedByDataLinkService, activeJob: Job,
    uniquePerCommunicationRequestAcrossChannels: string, source: string, explicitCustomerDocId: string | undefined, url: string = null, invoiceDocId : string = null,
    estimateDocId: string = null, workflowUpdatedDate: Date = null ) {
      const smsTemplate = new CustomerCommunicationTemplate({...template});
      smsTemplate.customerCommuncationType = CustomerCommunicationType.SMS;
      smsTemplate.retrieveContacts(activeJob).map(z => z.primaryPhoneNumber).forEach(phoneNumber => {
        retVal.push(this.buildSingleCustomerCommunication(smsTemplate, schedule, workflowDocId, jobDocId, siteVisit,
          generatePdfFromWorkflow, url, dataLinkSourceData, activeJob, invoiceDocId, estimateDocId,  workflowUpdatedDate,
          uniquePerCommunicationRequestAcrossChannels, source, explicitCustomerDocId, phoneNumber.replace(/\D/g,'')));
        });
  }
  private addEmailCommunication(retVal: CustomerCommunication[], template: CustomerCommunicationTemplate, schedule: CustomerCommunicationScheduling,
    workflowDocId: string, jobDocId: string | undefined, siteVisit: SiteVisit | undefined, generatePdfFromWorkflow: boolean, dataLinkSourceData: DataUsedByDataLinkService, activeJob: Job,
    uniquePerCommunicationRequestAcrossChannels: string, source: string, explicitCustomerDocId: string | undefined, url: string = null,
    invoiceDocId : string = null, estimateDocId : string = null, workflowUpdatedDate: Date = null) {
    const emailTemplate = new CustomerCommunicationTemplate({...template});
    emailTemplate.customerCommuncationType = CustomerCommunicationType.EMAIL;
    retVal.push(this.buildSingleCustomerCommunication(emailTemplate, schedule, workflowDocId, jobDocId, siteVisit,
      generatePdfFromWorkflow, url, dataLinkSourceData, activeJob, invoiceDocId, estimateDocId, workflowUpdatedDate, uniquePerCommunicationRequestAcrossChannels,
        source, explicitCustomerDocId, undefined));
  }

  private buildSingleCustomerCommunication(template: CustomerCommunicationTemplate, schedule: CustomerCommunicationScheduling,
      workflowDocId: string, jobDocId: string | undefined, siteVisit: SiteVisit | undefined, generatePdfFromWorkflow: boolean = false, pdfUrl: string,
      dataLinkSourceData: DataUsedByDataLinkService, activeJob: Job | undefined, invoiceDocId: string, estimateDocId: string,
      workflowUpdatedDate: Date, uniquePerCommunicationRequestAcrossChannels: string, source: string, explictCustomerDocId: string | undefined, to? : string) : CustomerCommunication {
      const retVal = new CustomerCommunication({
        estimateDocId : estimateDocId, invoiceDocId : invoiceDocId,
        body: DataLinkService.replaceDataLinkPlaceholders(template.body, dataLinkSourceData),
        subject: DataLinkService.replaceDataLinkPlaceholders(template.subject, dataLinkSourceData),
        customerCommunicationType: template.customerCommuncationType,
        customerCommunicationTemplate: template, customerCommunicationScheduling: schedule, messageSequenceNumber: 1,
        numberOfAdditionalMessagesToSend: schedule.numberOfAdditionalMessagesToSend, delayDaysToSendFollowUpMessage: schedule.delayDaysToSendFollowUpMessage,
        followUpMessageTimeSend: schedule.timeOfDayToSendMessage, customerCommunicationStatus: CustomerCommuncationStatus.SCHEDULED,
        customerCommunicationCatagory: template.communicationCatagory, scheduledDeliveryDate: this.customerCommunicationSchedulingService.getInitialCustomerCommunicationDate(schedule,
          this.customerCommunicationSchedulingService.InitialTriggeringEventDate(template, schedule, siteVisit)),
        workflowDocId: workflowDocId, jobDocId: jobDocId === undefined ? null : jobDocId, siteVisitDocId: siteVisit === undefined ? null : siteVisit.DocId(), customerDocId: explictCustomerDocId ? explictCustomerDocId : activeJob.customer.DocId(),
        to: template.customerCommuncationType === CustomerCommunicationType.EMAIL ?  (activeJob !== undefined ? template.retrieveContacts(activeJob).map(z => z.customerEmail) :
        [this.customerService.get(explictCustomerDocId).customerEmail]) : [to],
        replyTo: this.settingsService.getValue("companyEmailAddress"),
        bucketId: this.auth.bucketId, generatePdfFromWorkflow: generatePdfFromWorkflow, url : pdfUrl, workflowUpdatedDate : workflowUpdatedDate, sendFollowUpMessaging: template.sendFollowUpMessaging,
        uniquePerCommunicationRequestAcrossChannels: uniquePerCommunicationRequestAcrossChannels,
        source: source, bcc: this.settingsService.getValue("companyBccEmailAddress"),
        workflowVersionNumber : 6,
      });
      return retVal;
    }

    createEstimateCustomerCommunications(job: Job, c: CustomerCommunicationCategory,
      siteVisit: SiteVisit, estimate: Estimate, invoice: Invoice | undefined, source: string, b?: string | null) : Observable<CustomerCommunication[]> {
        let workflowCommunicationScheduling: CustomerCommunicationScheduling;
        let workflowDocId : string;
        let workflowUpdatedDate : Date = null;

        if (c !== CustomerCommunicationCategory.ESTIMATE) {
          throw Error("This method is only meant to generate communications on estimates.");
        }

        // If there are no line items in the estimate, don't generate any communications.
        if (!estimate.lineItems || estimate.lineItems.length === 0) {
          return of([]);
        }

        // If SP is not sending out estimate communcations, there is no communication to generate.
        const notSendingEstimates = this.customerCommunicationSchedulingService.loadAll$().pipe(
          map(c => c.map(t=> t.customerCommunicationTemplate)),
          map(c => c.filter(t => t.communicationCatagory === CustomerCommunicationCategory.ESTIMATE)),
          map(c => c.filter(t => t.enabled === true)),
          filter(c => c.length === 0),
          map(() => [])
        );

        const retriveTemplates = this.customerCommunicationSchedulingService.loadAll$().pipe(
          filter(x => x.length > 0),
          tap(x => workflowCommunicationScheduling = x.find(y => y.customerCommunicationTemplate.communicationCatagory === CustomerCommunicationCategory.WORK_PERFORMED)),
          map(x => x.map(z => z.customerCommunicationTemplate)),
        );

        const estimatesConsidered =  retriveTemplates.pipe(
        // SP also has ability to inline Esimate and / or Invoice, which changes elminates initial communication, and so instead we would scheduled
        // the first follow up communication.
        tap( () => workflowDocId = estimate.formModelFirestoreDocId),
        tap( () => {
          if (estimate.formModelFirestore) {
            workflowUpdatedDate = estimate.formModelFirestore.lastUpdatedAt;
          }
        }),
        map(x => x.filter(q => q.enabled && q.communicationCatagory === CustomerCommunicationCategory.ESTIMATE)),
        map(x => x.map(q => q.DocId())),
        switchMap(x => this.customerCommunicationSchedulingService.loadAll$().pipe(
        filter(b =>  b.length > 0),
        map(b => b.filter(s=> x.includes(s.customerCommunicationTemplateDocId) )),
        // all scheduled estimate communications.
        switchMap(d => {
          const toZip : Observable<CustomerCommunication[]>[] = [of(null)];
          const estimateInitialSchedule = d.find(s => s.customerCommunicationTemplate.communicationCatagory === CustomerCommunicationCategory.ESTIMATE &&
            s.customerCommunicationTemplate.customerCommunicationPurpose === CustomerCommunicationPurpose.INITIAL_CONTACT_RE_TOPIC);
            const explicitEmployee = siteVisit === undefined ? estimate.employee : null;
          d.forEach(scheduledCommunication =>
            {
              let initialContactScheduling: CustomerCommunicationScheduling = estimateInitialSchedule;
              let generateContact : boolean = true;
              const pdfUrl = estimate.formModelFirestore && estimate.formModelFirestore.formFirestore.formSummaryDocId ===
              this.settingsService.getValue("defaultFormFirestoreSummaryDocId") ? null :
              estimate.customer.primaryPhoneNumber.substring(0,3) === "555" ?
              // `https://service-vanguard--v2-rotf85f9.web.app/load-customer-preview;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${estimate.formModelFirestoreDocId};estimateDocId=${estimate.DocId()};jobDocId=${job.DocId()}` :
              `https://service-vanguard--workflow-bqx4afyo.web.app/load-customer-preview;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${estimate.formModelFirestoreDocId};estimateDocId=${estimate.DocId()};jobDocId=${job.DocId()}` :
              job.formModelFirestore.formFirestore.formSummaryDocId === "og9DyVsCUGpqL7gCxQ4Y" ?
              `https://service-vanguard--workflow-bqx4afyo.web.app/load-workflow/facing;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${estimate.formModelFirestoreDocId};estimateDocId=${estimate.DocId()};jobDocId=${job.DocId()}` :
              `https://service-vanguard--workflow-bqx4afyo.web.app/load-workflow/facing;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${estimate.formModelFirestoreDocId};estimateDocId=${estimate.DocId()};jobDocId=${job.DocId()}`;
              const communication = this.buildCustomerCommunications(scheduledCommunication.customerCommunicationTemplate, scheduledCommunication,
                workflowDocId, job, siteVisit, source, true,  pdfUrl, null, estimate.DocId(), workflowUpdatedDate, invoice, explicitEmployee, estimate.customer.DocId() );
                console.log(communication);
            // If we inline the estimate, and the estimate has an associated job workflow, we do not send inital communication; and the scheduling for the
            // (inital) follow up communication is based on the timeline of the job workflow.
            if (workflowCommunicationScheduling && workflowCommunicationScheduling.customerCommunicationTemplate.inlineEstimate &&
              estimate.formModelFirestore && estimate.formModelFirestore.formFirestore.formSummaryDocId === this.settingsService.getValue("defaultFormFirestoreSummaryDocId") ) {
              if (scheduledCommunication.customerCommunicationTemplate.customerCommunicationPurpose !== CustomerCommunicationPurpose.INITIAL_CONTACT_RE_TOPIC) {
                initialContactScheduling = workflowCommunicationScheduling;
              } else {
                generateContact = false;
              }
            }
            if (generateContact) {
              if (scheduledCommunication.customerCommunicationTemplate.customerCommunicationPurpose !== CustomerCommunicationPurpose.INITIAL_CONTACT_RE_TOPIC) {
              toZip.push(communication.pipe(
                map(c => {
                  const inner: CustomerCommunication[] = [];
                  c.forEach(communication => {
                    communication.scheduledDeliveryDate =
                    this.customerCommunicationSchedulingService.InitialTriggeringEventDateSeperateFollowUpCommunications(initialContactScheduling,scheduledCommunication);
                  inner.push(communication);
                  });
                  return inner;
                }),
              ));
            } else {
              toZip.push(communication);
            }
          }
      });
      return zip(...toZip).pipe(
        map(x => x.reduce((acc, curr) => acc.concat(curr), [])),
      )
    }),
    )),
    map(x => x.filter(y => y !== null)),
    switchMap(x => this.customerCommunicationService.createMultiple$(x,b)),
    take(1)
    ) as Observable<CustomerCommunication[]>;

    return merge(estimatesConsidered,notSendingEstimates).pipe(
      take(1)
    );
    }

    createInvoiceCustomerCommuinications(job: Job, c: CustomerCommunicationCategory, siteVisit: SiteVisit, invoice: Invoice, source: string, b?: string | null) {
        let workflowCommunicationScheduling: CustomerCommunicationScheduling;
        let workflowDocId : string;
        let workflowUpdatedDate : Date = null;

        if (c!== CustomerCommunicationCategory.PAYMENT) {
          throw Error("This method is only meant to generate communications on invoices.");
        }


        // If there are no line items in the invoice, don't generate any communications.
        if (!invoice.lineItems || invoice.lineItems.length === 0) {
          return of([]);
        }

         // If SP is not sending out invoices communcations, there is no communication to generate.
         const notSendingInvoices = this.customerCommunicationSchedulingService.loadAll$().pipe(
          map(c => c.map(t=> t.customerCommunicationTemplate)),
          map(c => c.filter(t => t.communicationCatagory === CustomerCommunicationCategory.PAYMENT)),
          map(c => c.filter(t => t.enabled === true)),
          filter(c => c.length === 0),
          map(() => [])
        );

        const retriveTemplates : ReplaySubject<CustomerCommunicationTemplate[]> = new ReplaySubject<CustomerCommunicationTemplate[]>(1);
        this.customerCommunicationSchedulingService.loadAll$().pipe(
          filter(x => x.length > 0),
          tap(x => workflowCommunicationScheduling = x.find(y => y.customerCommunicationTemplate.communicationCatagory === CustomerCommunicationCategory.WORK_PERFORMED)),
          map(x => x.map(z => z.customerCommunicationTemplate)),
          take(1)
        ).subscribe(r => retriveTemplates.next(r));

        // If we inline the invoice (initial) and the invoice is paid in full, there is no additional messaging that needs to go out.
        const noInvoiceMessagingNeedsSent = retriveTemplates.pipe(
          filter(() => workflowCommunicationScheduling.customerCommunicationTemplate.inlineInvoice && invoice.formModelFirestore.formFirestore.formSummaryDocId ===
            this.settingsService.getValue("defaultFormFirestoreSummaryDocId") && invoice.outstandingDue === 0),
            map(() => null)
        );

        const invoiceMessagingNeedsSent = retriveTemplates.pipe(
          filter(() => c === CustomerCommunicationCategory.PAYMENT),
          filter(() => !(workflowCommunicationScheduling.customerCommunicationTemplate.inlineInvoice && invoice.formModelFirestore.formFirestore.formSummaryDocId ===
          this.settingsService.getValue("defaultFormFirestoreSummaryDocId") && invoice.outstandingDue === 0)),
          tap(() => workflowDocId = invoice.formModelFirestoreDocId),
          tap(() => workflowUpdatedDate = invoice.formModelFirestore.lastUpdatedAt),
          map(x => x.filter(q => q.enabled && q.communicationCatagory === CustomerCommunicationCategory.PAYMENT)),
          map(x => x.map(q => q.DocId())),
          switchMap(x => this.customerCommunicationSchedulingService.loadAll$().pipe(
            map(b => b.filter(s=> x.includes(s.customerCommunicationTemplateDocId) )),
            switchMap(d => {
              const toZip : Observable<CustomerCommunication[]>[] = [];
              const paymentInitialSchedule = d.find(s => s.customerCommunicationTemplate.communicationCatagory === CustomerCommunicationCategory.PAYMENT);
              // console.error(d);
              d.forEach(scheduledCommunication =>
                {
                  // If the invoice is paid in full, we do not need to send any additional follow up messages.
                  if (invoice.outstandingDue === 0) {
                    scheduledCommunication.customerCommunicationTemplate.sendFollowUpMessaging = false;
                  }
                  let initialContactScheduling: CustomerCommunicationScheduling = paymentInitialSchedule;
                  const pdfUrl = invoice.formModelFirestore.formFirestore.formSummaryDocId ===
                  this.settingsService.getValue("defaultFormFirestoreSummaryDocId") ? null :
                  // `https://service-vanguard.firebaseapp.com/load-workflow/facing;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${invoice.formModelFirestoreDocId};jobDocId=${job.DocId()};invoiceDocId=${invoice.DocId()}`;
                  job.customer.primaryPhoneNumber.substring(0,3) === "555" ?
                  `https://service-vanguard--workflow-bqx4afyo.web.app/load-customer-preview;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${invoice.formModelFirestoreDocId};jobDocId=${job.DocId()};invoiceDocId=${invoice.DocId()}` :
                  job.formModelFirestore.formFirestore.formSummaryDocId === "og9DyVsCUGpqL7gCxQ4Y" ? `https://service-vanguard--greg-test-iz8n4q4j.web.app/load-workflow/facing;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${invoice.formModelFirestoreDocId};jobDocId=${job.DocId()};invoiceDocId=${invoice.DocId()}` :
                   `https://service-vanguard--workflow-bqx4afyo.web.app/load-workflow/facing;expandLineItemControls=true;cf=true;formModelFirestoreDocId=${invoice.formModelFirestoreDocId};jobDocId=${job.DocId()};invoiceDocId=${invoice.DocId()}`;
                  const communication = this.buildCustomerCommunications(scheduledCommunication.customerCommunicationTemplate, scheduledCommunication,
                    workflowDocId, job, siteVisit, source, true, pdfUrl, invoice.DocId(), null, workflowUpdatedDate,invoice  );
                // If we inline payment, and payment has an associated job workflow, we do not send inital communication; and the scheduling for the
                // (inital) follow up communication is based on the timeline of the job workflow.
                console.log(workflowCommunicationScheduling.customerCommunicationTemplate.inlineInvoice, invoice.formModelFirestore.formFirestore.formSummaryDocId,
                  this.settingsService.getValue("defaultFormFirestoreSummaryDocId"));
                  // If we inline the invoice ( include it in the PDF that is generated for the workflow) and the invoice uses the same workflow as the workflow technician completes, then we schedule an additonal
                  // message to go out ( versus seperate handling when no additonal messaging needs sent, and where the invoice is a seperate message)
                if (workflowCommunicationScheduling.customerCommunicationTemplate.inlineInvoice && invoice.formModelFirestore.formFirestore.formSummaryDocId ===
                    this.settingsService.getValue("defaultFormFirestoreSummaryDocId") ) {
                    initialContactScheduling = workflowCommunicationScheduling;
                  toZip.push(communication.pipe(
                    map(c => {
                      const inner: CustomerCommunication[] = [];
                      c.forEach(communication => {
                        communication.scheduledDeliveryDate =
                        this.customerCommunicationSchedulingService.InitialTriggeringEventDateScheduleSecondCommunication(initialContactScheduling,scheduledCommunication,siteVisit);
                        communication.messageSequenceNumber = 2;
                        inner.push(communication);
                      });
                      return inner;
                    }),
                  ));
                } else {
                  toZip.push(communication);
                }
              });
          return zip(...toZip).pipe(
            mergeMap(x => x),
          )
        }),
        mergeMap(x => this.customerCommunicationService.createMultiple$(x,b)),
        )));

        return merge(noInvoiceMessagingNeedsSent, invoiceMessagingNeedsSent, notSendingInvoices).pipe(
          take(1)
        );
      }

  createWorkPerformedCustomerCommunications(workflowDocId: string,job: Job, siteVisit: SiteVisit, invoice: Invoice, estimate: Estimate,
    workflowUpdatedDate: Date, source: string, b?: string | null)  : Observable<CustomerCommunication[]> {

    const workFlowCommunications =  this.customerCommunicationTemplateService.loadAll$().pipe(
      filter(x => x.length > 0),
      map(x => x.filter(q => q.enabled && q.communicationCatagory === CustomerCommunicationCategory.WORK_PERFORMED)),
      share()
    );

    const sendOutWorkflowCommunicationOnCompletion = workFlowCommunications.pipe(
      filter(x => x.length > 0),
      map(x => x[0]),
      switchMap(x => this.customerCommunicationSchedulingService.loadAll$()
      .pipe(
        filter(x => x.length > 0),
        map(com => com.filter(q => q.customerCommunicationTemplateDocId === x.DocId())[0])
      )),
      take(1),
      // Inclusion of invoiceDocId and / or estimateDocId is so generated communication is retrieved for letting SP know communication of this type occurred.
      switchMap(x => this.buildCustomerCommunications(x.customerCommunicationTemplate, x, workflowDocId, job, siteVisit, source, true, null,
        x.customerCommunicationTemplate.inlineInvoice ? invoice.DocId() : null,
        x.customerCommunicationTemplate.inlineEstimate ? estimate.DocId() : null, workflowUpdatedDate, invoice)),
      switchMap(x => this.customerCommunicationService.createMultiple$(x,b)),
      take(1),
      );

      const noWorkflowCommunicationOnCompletion = workFlowCommunications.pipe(
        filter(x => x.length === 0),
        map(() => []),
        take(1),
      );

      const workFlowCommunicationIfSending = merge(sendOutWorkflowCommunicationOnCompletion, noWorkflowCommunicationOnCompletion,
        this.customerCommunicationSchedulingService.loadAll$().pipe(
          filter(x => x.length === 0),
          map(() => [])
        ));

      const additonalCommunicationsGoOut = workFlowCommunicationIfSending.pipe(
        filter(() => this.settingsService.getValue('triggerInvoiceEstimateCommunicationOnWorkflowCompletion')),
        mergeMap(x => this.createEstimateCustomerCommunications(job, CustomerCommunicationCategory.ESTIMATE, siteVisit, estimate, invoice, source, b).pipe(
          map(val => x.concat(val))
        )),
        tap(x => console.log(x,` string`)),
        mergeMap(x => this.createInvoiceCustomerCommuinications(job, CustomerCommunicationCategory.PAYMENT, siteVisit, invoice, source, b).pipe(
          map(val => x.concat(val))
        )),
        tap(x => console.log(x,` string`)),
      );

      const noAdditionalCommunications = workFlowCommunicationIfSending.pipe(
        filter(() => !this.settingsService.getValue('triggerInvoiceEstimateCommunicationOnWorkflowCompletion')),
        tap(x => console.log(x,` string`)),
      );

      return merge(additonalCommunicationsGoOut, noAdditionalCommunications);
  }

  createCustomerContactsFromSiteVisit(siteVisit: SiteVisit, previousValue: SiteVisit, job: Job, source: string, invoice: Invoice, b?: string | null ) : Observable<any> {

    // If site visit arrival window was not updated, then we do not need to modify customer commmunications on when appointment will be.
    if (previousValue !== undefined &&
    siteVisit.arrivalWindowStartDate.getTime() === previousValue.arrivalWindowStartDate.getTime() &&
    siteVisit.arrivalWindowEndDate.getTime() === previousValue.arrivalWindowEndDate.getTime())
    {
      return of(null);
    }

    // console.error("CREATE CONTACTS FROM SITE VISIT");
    // console.error(siteVisit);
    // return of(null);

    // console.warn(siteVisit,siteVisit.DocId());
    let sentCustomerCommunications : CustomerCommunication[] = [];

    // Check for existing scheduled customer communcation generated from site visit.
    const retrieveExisting = this.customerCommunicationService.queryFirestoreShallow$([where("siteVisitDocId", "==", siteVisit.DocId()),
      where("customerCommunicationStatus",  "!=", "Canceled"),
      where("customerCommunicationCatagory", "==", CustomerCommunicationCategory.APPOINTMENT)]).pipe(
        map(x=> {
          const sent = x.filter(y => CustomerCommunication.CommunicationHasBeenSentToCustomer(y.customerCommunicationStatus));
          sentCustomerCommunications = sent;
          return x.filter(y => y.customerCommunicationStatus === CustomerCommuncationStatus.SCHEDULED);
        }),
        switchMap(x => this.customerCommunicationService.loadMultiple$(x.map(y => y.DocId()))),
        take(1),
      share()
    );

    // if communications exist, we need to update them to canceled.
    const communicationsExist = retrieveExisting.pipe(
        filter(x=> x.length > 0),
        map(x => x.map(q => {
          q.customerCommunicationStatus = CustomerCommuncationStatus.CANCELED;
          q.stateChangeNotes = "Cancelled while creating new appointment.";
          return this.customerCommunicationService.update$(q,b)
        })),
        switchMap(x => zip(...x))
    );

    // If communication(s) do not exist:
    const communicationsDoNotYetExist = retrieveExisting.pipe(
      filter(x => x.length === 0),
    );

    return merge(communicationsExist,communicationsDoNotYetExist).pipe(
      take(1),
      switchMap(x => this.customerCommunicationSchedulingService.loadAll$(true)),
      map(x => x.filter(q => q.customerCommunicationTemplate.enabled && q.customerCommunicationTemplate.communicationCatagory === CustomerCommunicationCategory.APPOINTMENT)),
      map(x => x.map(z => {
        // If we have sent communication and communication template purpose is reminder, then we force SP to confirm they want to re-send out
        // communication with the updated informaton.  I am not super-keen on this, but Greg thinks it is a good idea.
        if (! (z.customerCommunicationTemplate.customerCommunicationPurpose === CustomerCommunicationPurpose.CONFIRMATION &&
          sentCustomerCommunications.filter(s => s.customerCommunicationTemplateDocId === z.customerCommunicationTemplateDocId).length > 0)) {
          return this.buildCustomerCommunications(z.customerCommunicationTemplate, z, null, job,  siteVisit, source, false, null, null, null, null, invoice  ).pipe(
            // If customer communication is scheduled for the past ( for example, you send out notice 24 hours before appointment, but you reschedule and
            // the new time is within the 24 hour window; then we do not want to send out message. )
            switchMap(x => x.length > 0 &&  x[0].scheduledDeliveryDate.getTime() > subMinutes(new Date(),2).getTime()
              ? this.customerCommunicationService.createMultiple$(x,b) : of(null))
          );
        } else {
          return of(null);
        }
      })),
      switchMap(x => zip(...x,of(null))),
      map(() => null),
      take(1),
    );
  }

  sendCustomerCommunicationReflectingUpdatedArrivalTime(siteVisit: SiteVisit, job: Job, source, invoice: Invoice, b?: string | null) : Observable<any> {
    return this.customerCommunicationSchedulingService.loadAll$().pipe(
      map(x => x.filter(q => q.customerCommunicationTemplate.enabled && q.customerCommunicationTemplate.communicationCatagory === CustomerCommunicationCategory.APPOINTMENT &&
        q.customerCommunicationTemplate.customerCommunicationPurpose === CustomerCommunicationPurpose.CONFIRMATION)[0]),
      switchMap(x => this.buildCustomerCommunications(x.customerCommunicationTemplate, x, null,
      job, siteVisit, source, false, null, null,null,null,invoice)),
      switchMap(x => this.customerCommunicationService.createMultiple$(x,b)));
  }

  cancelCustomerCommunicationsForEstimate(estimateDocId: string, cancellationOrigin: string, b?: string | null) : Observable<any> {
    // Get associated customer communications that are scheduled for this estimate.
    return this.customerCommunicationService.queryFirestoreDeep$([where("estimateDocId", "==", estimateDocId),
      where("customerCommunicationStatus", "==", CustomerCommuncationStatus.SCHEDULED)]).pipe(
        map(docs => {
          const toUpdate : Observable<CustomerCommunication>[] =  [];
          toUpdate.push(of(null));
          docs.forEach(x => {
            x.customerCommunicationStatus = CustomerCommuncationStatus.CANCELED;
            x.stateChangeNotes = `${cancellationOrigin} on ${new Date().toLocaleString()}`;
            toUpdate.push(this.customerCommunicationService.mergeUpdate(x, ['customerCommunicationStatus', 'stateChangeNotes'],b ));
          });
          return toUpdate;
        }),
        take(1),
        switchMap(toUpdate => zip(...toUpdate)),
    );
  }

  cancelWorkflowCommunications(workflowDocId: string, cancellationOrigin: string,  b?: string | null) {
    // Get associated customer communications that are scheduled for this estimate.
    return this.customerCommunicationService.queryFirestoreDeep$([where("workFlowDocId", "==", workflowDocId),
      where("customerCommunicationStatus", "==", CustomerCommuncationStatus.SCHEDULED)]).pipe(
        map(docs => {
          const toUpdate : Observable<CustomerCommunication>[] =  [];
          toUpdate.push(of(null));
          docs.forEach(x => {
            x.customerCommunicationStatus = CustomerCommuncationStatus.CANCELED;
            x.stateChangeNotes = `${cancellationOrigin} on ${new Date().toLocaleString()}`;
            toUpdate.push(this.customerCommunicationService.mergeUpdate(x, ['customerCommunicationStatus', 'stateChangeNotes'],b ));
          });
          return toUpdate;
        }),
        take(1),
        switchMap(toUpdate => zip(...toUpdate))
    );
  }

  cancelCustomerCommunicationsForInvoice(invoiceDocId: string, cancellationOrigin: string, b?: string | null) : Observable<CustomerCommunication[]> {
    // Get associated customer communications that are scheduled for this estimate.
    return this.customerCommunicationService.queryFirestoreDeep$([where("invoiceDocId", "==", invoiceDocId),
      where("customerCommunicationStatus", "==", CustomerCommuncationStatus.SCHEDULED)]).pipe(
        map(docs => {
          const toUpdate : Observable<CustomerCommunication>[] =  [of(null)];
          docs.forEach(x => {
            x.customerCommunicationStatus = CustomerCommuncationStatus.CANCELED;
            x.stateChangeNotes = `${cancellationOrigin} on ${new Date().toLocaleString()}`;
            toUpdate.push(this.customerCommunicationService.mergeUpdate(x, ['customerCommunicationStatus', 'stateChangeNotes'],b ));
          });
          return toUpdate;
        }),
        take(1),
        switchMap(toUpdate => zip(...toUpdate))
    );
  }

  cancelCustomerCommunicationFromSiteVisit(siteVisitDocId: string, stateChangeNotes: string, b?: string | null) : Observable<any>{
    return this.customerCommunicationService.queryFirestoreShallow$([where("siteVisitDocId", "==", siteVisitDocId),
      where("customerCommunicationStatus", "==", CustomerCommuncationStatus.SCHEDULED),
      where("customerCommunicationCatagory", "==", CustomerCommunicationCategory.APPOINTMENT)]).pipe(
        map(docs => {
          const toUpdate : Observable<CustomerCommunication>[] =  [];
          toUpdate.push(of(null));
          docs.forEach(x => {
            x.customerCommunicationStatus = CustomerCommuncationStatus.CANCELED;
            // toUpdate.push(this.customerCommunicationService.update$(x,b));
            toUpdate.push(this.customerCommunicationService.mergeUpdate(x, ['customerCommunicationStatus'],b ));
          });
          return toUpdate;
        }),
        take(1),
        switchMap(toUpdate => zip(...toUpdate))
    );
  }
}
