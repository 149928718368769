import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { merge, Subject } from 'rxjs';
import { delay, filter, map, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FormCatagory } from '../../../../../common/src/data/dao/form-catagory';
import { FormCatagoryTreeService, FormCatagoryTreeStructure } from 'web-app/src/app/form-builder/storage/form-catagory-tree.service';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { LoadFormComponent } from 'web-app/src/app/form-builder/storage/load-form/load-form.component';
import { ChecklistDatabase, ItemCatagoryFlatNode } from 'web-app/src/app/multiselect-nested-tree/multiselect-nested-tree.component';
import { CompanySettings } from '../../../../../common/src/data/dao/company-settings';
import { CompanySettingsService } from '../../../../../common/src/data/dao-services/company-settings.service';
import { randomElementName } from '../../../../../common/src/util/util';
import { SettingsService } from '../../settings/settings.service';
import { PriceBookService } from '../../../../../common/src/data/dao-services/pricebook/pricebook.service';
import {PriceBookEntry} from '../../../../../common/src/data/dao/pricebook/pricebook-entry';
import {PriceBook} from '../../../../../common/src/data/dao/pricebook/pricebook';
import {parse} from 'papaparse';
import { CostStructureService } from '../../../../../common/src/data/dao-services/pricebook/cost-structure.service';
import { CostStructure } from '../../../../../common/src/data/dao/pricebook/cost-structure';
import { Cost } from '../../../../../common/src/data/dao/pricebook/cost';
import { PriceBookUnitOfMeasureService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service';
import { PriceBookUnitOfMeasure } from '../../../../../common/src/data/dao/pricebook/pricebook-unit-of-measure';
import { PriceBookCatagoryService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-catagory.service';
import { PriceBookCatagory } from '../../../../../common/src/data/dao/pricebook/pricebook-catagory';

@Component({
  selector: 'app-workflow-settings',
  templateUrl: './workflow-settings.component.html',
  styleUrls: ['./workflow-settings.component.scss']
})
export class WorkflowSettingsComponent implements OnInit {

nodeClicked$: Subject<ItemCatagoryFlatNode<FormCatagoryTreeStructure> | FormCatagoryTreeStructure[]> = new Subject<ItemCatagoryFlatNode<FormCatagoryTreeStructure> | FormCatagoryTreeStructure[]>();
parentNodeClicked$ : Subject<ItemCatagoryFlatNode<FormCatagoryTreeStructure> | FormCatagoryTreeStructure[]> = new Subject<ItemCatagoryFlatNode<FormCatagoryTreeStructure> | FormCatagoryTreeStructure[]>();
checkListDatabase :ChecklistDatabase<FormCatagoryTreeStructure> = undefined;
addChildFormGroup: UntypedFormGroup;
form: UntypedFormGroup;
changeDefaultWorkflowFormGroup: UntypedFormGroup;
selectedNode: FormCatagoryTreeStructure;
loadFormDialogRef: MatDialogRef<LoadFormComponent>;
companySettings: CompanySettings;
unitsOfMeasure: PriceBookUnitOfMeasure[] = [];
priceBookCatagories: PriceBookCatagory[] = [];

costStructures: CostStructure[] = [];

displayParentSelection: boolean = false;

  destroyingComponent$ = new Subject();

  constructor(private fb: UntypedFormBuilder, private formCatagoryTreeService: FormCatagoryTreeService, private formCatagoryService: FormCatagoryService, private dialog: MatDialog,
    private companySettingsService: CompanySettingsService, private formFirestoreSummaryService: FormFirestoreSummaryService, private settingsService: SettingsService,
    private costStructureService: CostStructureService, private priceBookService: PriceBookService, private priceBookUnitOfMeasureService: PriceBookUnitOfMeasureService,
    private pricebookCatagoryService : PriceBookCatagoryService) {

    this.pricebookCatagoryService.loadAll$().pipe(
      tap(x => this.priceBookCatagories = x),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.costStructureService.loadAll$().pipe(
      tap(x => this.costStructures = x),
      take(1)
    ).subscribe();

    this.priceBookUnitOfMeasureService.loadAll$().pipe(
      tap(x => this.unitsOfMeasure = x),
      tap(x => {
      console.log(x,` string`);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.addChildFormGroup = this.buildAddChildFormGroup();
    this.form = this.buildFormGroup();
    this.changeDefaultWorkflowFormGroup = this.buildChangeDefaultWorkflowFormGroup();

    this.form.get("displayInactiveForms").valueChanges.pipe(
      tap(x => this.formCatagoryTreeService.displayInactiveForms.next(x)),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    this.nodeClicked$.pipe(
      filter(x => !Array.isArray(x)),
      tap(x => this.patchParentNodeSelection(x as ItemCatagoryFlatNode<FormCatagoryTreeStructure>)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.parentNodeClicked$.pipe(
      filter(x => !Array.isArray(x)),
      tap(x => this.patchParentNodeSelection(x as ItemCatagoryFlatNode<FormCatagoryTreeStructure>)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  ngOnInit(): void {

    const loadCompanySettings = this.companySettingsService.loadAll$().pipe(
      delay(1),
      filter(x=>x.length > 0),
      map(x => x[0]),
      tap(x => this.companySettings = x),
      share()
    );

    const defaultFirestoreSummaryDoc = loadCompanySettings.pipe(
      map(x => x.defaultFormFirestoreSummaryDocId),
      filter(x => x !== ""),
      switchMap( x=> this.formFirestoreSummaryService.load$(x)),
      tap(x => {
        this.changeDefaultWorkflowFormGroup.patchValue({currentDefaultWorkflowDocId: x.DocId(), currentDefaultWorkflowTitle: x.title});
        }));

    const defaultEstimateFirestoreSummaryDoc = loadCompanySettings.pipe(
      map(x => x.defaultFormFirestoreSummaryEstimateDocId),
      filter(x => x !== ""),
      switchMap( x=> this.formFirestoreSummaryService.load$(x)),
      tap(x => {
        this.changeDefaultWorkflowFormGroup.patchValue({currentDefaultEstimateWorkflowDocId: x.DocId(), currentDefaultEstimateWorkflowTitle: x.title});
        }));

    const defaultInvoiceFirestoreSummaryDoc = loadCompanySettings.pipe(
      map(x => x.defaultFormFirestoreSummaryInvoiceDocId),
      filter(x => x !== ""),
      switchMap( x=> this.formFirestoreSummaryService.load$(x)),
      tap(x => {
        this.changeDefaultWorkflowFormGroup.patchValue({currentDefaultInvoiceWorkflowDocId: x.DocId(), currentDefaultInvoiceWorkflowTitle: x.title});
        }));

      merge(defaultFirestoreSummaryDoc, defaultEstimateFirestoreSummaryDoc, defaultInvoiceFirestoreSummaryDoc).pipe(
        takeUntil(this.destroyingComponent$)
      ).subscribe();

  }

  buildAddChildFormGroup() {
    return this.fb.group({
      name: ['', Validators.required],
    });
  }

  buildFormGroup() {
    const retVal =  this.fb.group({
      name: ['', Validators.required],
      active: [true],
      parentDocId: [''],
      displayInactiveForms: [false],
    });
    return retVal;
}

  buildChangeDefaultWorkflowFormGroup() {
    const retVal =  this.fb.group({
      currentDefaultWorkflowTitle: ['', Validators.required],
      currentDefaultWorkflowDocId: [],
      currentDefaultEstimateWorkflowTitle: ['', Validators.required],
      currentDefaultEstimateWorkflowDocId: [],
      currentDefaultInvoiceWorkflowTitle: ['', Validators.required],
      currentDefaultInvoiceWorkflowDocId: [],
    });
    retVal.controls["currentDefaultWorkflowTitle"].disable();
    retVal.controls["currentDefaultEstimateWorkflowTitle"].disable();
    retVal.controls["currentDefaultInvoiceWorkflowTitle"].disable();
    return retVal;
  }


  resolvePriceBookEntries(data: any[], fileName: string) {
    const uomResolved: Subject<any> = new Subject();
    const priceBookEntries : PriceBookEntry[]= [];
    uomResolved.pipe(
      tap(() => {
        data.forEach((element) => {
          if (element['Category Code'] !== "") {
            const costs = [
              new Cost({costStructure: this.costStructures.find(x => x.code === "Material Cost"), amount: element['Material Cost']}),
              new Cost({costStructure: this.costStructures.find(x => x.code === "Labor Cost"), amount: element['Labor Cost']}),
              new Cost({costStructure: this.costStructures.find(x => x.code === "Permit Cost"), amount: element['Permit Cost']}),
              new Cost({costStructure: this.costStructures.find(x => x.code === "Sub Cost"), amount: element['Sub Cost']})
              ]
              const priceBookEntry = new PriceBookEntry({title: element['Item'],
                priceBookCatagory: this.priceBookCatagories.find(x =>x.code === element['Category Code']),
                itemCode: element['Code'],
                unitOfMeasure: this.unitsOfMeasure.find(x => x.code === element['UOM']),
                divideByCalc: 1,
                costs: costs,
                productDescription: element['Product Description'],
                importLastUpdated: new Date()
              });
              priceBookEntries.push(priceBookEntry);
          }
        });
        const p = new PriceBook({title: "Sunroom Pricebook",
          fileName: fileName,
          validated: true,
          priceBookEntries: priceBookEntries,
          importedLastDate: new Date(),
        });
        this.priceBookService.create$(p).pipe(
          take(1)
        ).subscribe();
      }),
      take(1)
    ).subscribe();


    const uom = new Set<string>(data.map(x => x['UOM']).filter(x => x !== ""));
    const uomToAdd = Array.from(uom).filter(x => !this.unitsOfMeasure.map(x => x.code).includes(x));
    if (uomToAdd.length > 0) {
      this.priceBookUnitOfMeasureService.createMultiple$(uomToAdd.map(x => new PriceBookUnitOfMeasure({code: x}))).pipe(
        tap(x => this.unitsOfMeasure.push(...x)),
        tap(() => uomResolved.next(null)),
        take(1)
      ).subscribe();
    } else {
      uomResolved.next(null);
    }
  }


  importCsv($event: Event) {
    console.log($event);
    const file = $event.target['files'][0];
    parse(file, {
      header: true,
      complete: (result) => {
        console.log(result);

        //TBDWORKFLOW
        // Validate import file.
        //    - Check for required columns.
        //    - Check new UOM, confirm they are desired.
        //    - Check new Catagories, confirm they are desired.
        //    - Write imported file off to storage controlled by us for potential debugging needs.

        //Add any new Catagories
        const catsToAdd: PriceBookCatagory[] = [];
        result.data.forEach((element) => {
          if (element['Category Code'] == "" || this.priceBookCatagories.map(x => x.code).includes(element['Category Code'])) {
            return;
          } else if (!catsToAdd.map(x => x.code).includes(element['Category Code'])) {
            const cat = new PriceBookCatagory({name: element['Category'], code: element['Category Code'], importLastUpdated: new Date()});
            catsToAdd.push(cat);
          }
        });

        if (catsToAdd.length > 0) {
          this.pricebookCatagoryService.createMultiple$(catsToAdd).pipe(
            delay(5000),
            tap(() => this.resolvePriceBookEntries(result.data, file.name)),
          ).subscribe();
        } else {
          this.resolvePriceBookEntries(result.data,file.name);
        }
      }
    })
  }

loadWorkflowSelection(workflowToChange: string) {
  const editorConfig = new MatDialogConfig();

  this.loadWorflowForm(editorConfig, workflowToChange);
  // this.formCatagoryService.load$("Udyc8F8cU8Tjx49tgqNe").pipe(
  //   tap(() => this.loadWorflowForm(editorConfig, workflowToChange)),
  //   take(1),
  // ).subscribe();
}

  private loadWorflowForm(editorConfig: MatDialogConfig<any>, workflowToChange: string) {
    Object.assign(editorConfig, {
      data: {
        // formCatagory: this.formCatagoryService.get("Udyc8F8cU8Tjx49tgqNe"),
        formCatagory: null,
        formType: "topLevel"
      }
    });

    this.loadFormDialogRef = this.dialog.open(LoadFormComponent, editorConfig);

    // Event dialog closure:
    this.loadFormDialogRef.afterClosed().pipe(
      take(1),
      filter(x => x !== undefined),
      map(x => x as FormFirestore),
      map(x => x.formSummary),
      tap(x => {
        if (workflowToChange === "defaultWorkflow") {
          this.changeDefaultWorkflowFormGroup.patchValue({ currentDefaultWorkflowTitle: x.title });
          this.changeDefaultWorkflowFormGroup.patchValue({ currentDefaultWorkflowDocId: x.DocId() });
        }
        if (workflowToChange === "estimateWorkflow") {
          this.changeDefaultWorkflowFormGroup.patchValue({ currentDefaultEstimateWorkflowTitle: x.title });
          this.changeDefaultWorkflowFormGroup.patchValue({ currentDefaultEstimateWorkflowDocId: x.DocId() });
        }
        if (workflowToChange === "invoiceWorkflow") {
          this.changeDefaultWorkflowFormGroup.patchValue({ currentDefaultInvoiceWorkflowTitle: x.title });
          this.changeDefaultWorkflowFormGroup.patchValue({ currentDefaultInvoiceWorkflowDocId: x.DocId() });
        }
      }),
      take(1)
    ).subscribe();
  }

patchFormToSelectedNode() {
  this.selectedNode.formCatagory.name = this.form.get('name').value;
  this.selectedNode.formCatagory.active = this.form.get('active').value;
  this.selectedNode.formCatagory.parentDocId = this.form.get('parentDocId').value;
}

patchNodeSelection(node: ItemCatagoryFlatNode<FormCatagoryTreeStructure>) {
  this.selectedNode = node.item;
  this.form.patchValue({
    name: node.name,
    active: node.item?.formCatagory?.active,
    parentDocId: node.item?.formCatagory?.parentDocId
  });
  if (this.form.get('parentDocId').value === undefined) {
    this.form.controls["name"].disable();
    this.form.controls["active"].disable();
    this.form.controls["parentDocId"].disable();
  } else {
    this.form.controls["name"].enable();
    this.form.controls["active"].enable();
    this.form.controls["parentDocId"].enable();
  }
}

patchParentNodeSelection(node: ItemCatagoryFlatNode<FormCatagoryTreeStructure>) {
  this.form.patchValue({
    parentDocId: node.item?.formCatagory?.DocId()
  });
}

  CheckListDatabase() : ChecklistDatabase<FormCatagoryTreeStructure> {
  if (this.checkListDatabase === undefined) {
    this.checkListDatabase = new ChecklistDatabase<FormCatagoryTreeStructure>(this.formCatagoryTreeService.rootNode.pipe(filter(node => node!== undefined)),
        this.formCatagoryTreeService.allNodes,this.formCatagoryTreeService.selectedNodes, (x=>x.parentCatagoryKey === null ? undefined : x.parentCatagoryKey),
        (x => x.name),(x=>x.key), (x=> x ? x.description : ""),false);
  }
  return this.checkListDatabase;
}

Cancel() {
  this.reset();
}

reset() {
  this.addChildFormGroup.reset();
  this.form.reset();
  this.formCatagoryTreeService.selectedNodes.next([]);
}

Save() {
  if (this.addChildFormGroup.valid) {
    const formCatagory = new FormCatagory({name: this.addChildFormGroup.get('name').value, parentDocId: this.selectedNode ?
    this.selectedNode.formCatagory.DocId() : this.settingsService.getValue('defaultWorkCatagoryDocId')});
    this.formCatagoryService.create$(formCatagory).pipe(take(1)).subscribe();
    this.reset();
  }

  let updatedCompanySettings : boolean = false;
  this.changeDefaultWorkflowFormGroup.controls["currentDefaultWorkflowTitle"].enable();
  if (this.changeDefaultWorkflowFormGroup.valid && this.changeDefaultWorkflowFormGroup.get("currentDefaultWorkflowDocId").value !== this.companySettings.defaultFormFirestoreSummaryDocId) {
    this.companySettings.defaultFormFirestoreSummaryDocId = this.changeDefaultWorkflowFormGroup.get("currentDefaultWorkflowDocId").value;
    updatedCompanySettings = true;
  }
  this.changeDefaultWorkflowFormGroup.controls["currentDefaultWorkflowTitle"].disable();

  this.changeDefaultWorkflowFormGroup.controls["currentDefaultEstimateWorkflowTitle"].enable();
  if (this.changeDefaultWorkflowFormGroup.valid && this.changeDefaultWorkflowFormGroup.get("currentDefaultEstimateWorkflowDocId").value !== this.companySettings.defaultFormFirestoreSummaryEstimateDocId) {
    this.companySettings.defaultFormFirestoreSummaryEstimateDocId = this.changeDefaultWorkflowFormGroup.get("currentDefaultEstimateWorkflowDocId").value;
    updatedCompanySettings = true;
  }
  this.changeDefaultWorkflowFormGroup.controls["currentDefaultEstimateWorkflowTitle"].disable();

  this.changeDefaultWorkflowFormGroup.controls["currentDefaultInvoiceWorkflowTitle"].enable();
  if (this.changeDefaultWorkflowFormGroup.valid && this.changeDefaultWorkflowFormGroup.get("currentDefaultInvoiceWorkflowDocId").value !== this.companySettings.defaultFormFirestoreSummaryInvoiceDocId) {
    this.companySettings.defaultFormFirestoreSummaryInvoiceDocId = this.changeDefaultWorkflowFormGroup.get("currentDefaultInvoiceWorkflowDocId").value;
    updatedCompanySettings = true;
  }
  this.changeDefaultWorkflowFormGroup.controls["currentDefaultInvoiceWorkflowTitle"].disable();

  if (updatedCompanySettings) {
    this.companySettingsService.update$(this.companySettings).pipe(
      take(1)
    ).subscribe();
  }

  if (this.form.valid && this.form.touched) {
    this.patchFormToSelectedNode();
    this.formCatagoryService.update$(this.selectedNode.formCatagory).pipe(
      tap(() => this.reset()),
      take(1)
    ).subscribe();
  } else {
      if (this.form.touched) {
        this.form.markAllAsTouched();
      }
  }

}

}
