import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PricebookEntryDisplayComponent } from './pricebook-entry-display.component';
import { MatTableModule } from '@angular/material/table';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatTableModule,
  ],
  providers: [CurrencyPipe],
  declarations: [PricebookEntryDisplayComponent],
  exports: [PricebookEntryDisplayComponent],

})

export class PriceBookEntryDisplayModule { }
