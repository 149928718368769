import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, merge, Observable, of,  Subject, throwError, zip } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Address } from '../../../../../common/src/data/dao/address';
import { AddressSearchModalComponent } from 'web-app/src/app/address_search/address-search-modal/address-search-modal.component';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { CustomerCommunicationDisplayModalComponent } from 'web-app/src/app/customer-communication/customer-communication-display-modal/customer-communication-display-modal.component';
import { CustomerCommunicationService } from '../../../../../common/src/data/dao-services/customer-communication.service';
import { CustomerService } from '../../../../../common/src/data/dao-services/customer.service';
import { GenericServiceProviderSetting } from '../../../../../common/src/data/dao/generic-service-provider-setting';
import { GenericServiceProviderSettingService } from '../../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { JobType } from '../../../../../common/src/data/dao/job-type';
import { JobTypeService } from '../../../../../common/src/data/dao-services/job-type.service';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { Job } from '../../../../../common/src/data/dao/job';
import { MultipleJobSummaryComponent } from 'web-app/src/app/multiple-job-summary/multiple-job-summary.component';
import {randomElementName} from '../../../../../common/src/util/util';
import { CustomerDetailsModalComponent } from '../customer-details-modal/customer-details-modal.component';
import { EXPLICIT_JOB_EVENT, JobEvent } from '../../../../../common/src/data/dao/job-event';
import { CancelJobConfirmationModalComponent } from 'web-app/src/app/jobs/cancel-job-confirmation-modal/cancel-job-confirmation-modal.component';
import { JobAttachmentsComponent } from '../../job-attachments/job-attachments.component';
import { CustomerCommunicationManagementService } from '../../customer-communication/customer-communication-management.service';
import { VerticalSchedulerService } from '../../vertical.scheduler.service';
import { PhysicalAddressRoutingService } from '../../physical-address-routing.service';
import { limit, orderBy, where } from 'firebase/firestore';
import { searchSource } from '../../../../../common/src/data/services/search.service';
import { FirestoreBackend } from '../../../../../common/src/data/database-backend/retrieve-from-firestore';

@Component({
  selector: 'app-customer-job-section',
  templateUrl: './customer-job-section.component.html',
  styleUrls: ['./customer-job-section.component.scss']
})
export class CustomerJobSectionComponent implements OnInit {

  @Input() customers: BehaviorSubject<{customer: Customer, role: string, primary: boolean}[]>;
  @Input() job: BehaviorSubject<Job>;
  @Output() addedCustomer: Subject<Customer> = new Subject<Customer>();
  jobCountAtAddress$ = new BehaviorSubject<string>("0");

  jobTypes$: Observable<JobType[]>;
  jobPriorities$: Observable<GenericServiceProviderSetting[]>;
  destroyingComponent$ = new Subject();
  customerDetailsModalRef: MatDialogRef<CustomerDetailsModalComponent>;
  jobDetailsFormGroup: UntypedFormGroup;
  customerDetailsFormGroup: UntypedFormGroup;
  viewCustomerCommuncationsModalRef: MatDialogRef<CustomerCommunicationDisplayModalComponent>;

  PropertyHistoryModalRef: MatDialogRef<MultipleJobSummaryComponent>;
  updateAddressModalRef: MatDialogRef<AddressSearchModalComponent>;

  constructor(private dialog: MatDialog, private customerService: CustomerService, private jobService: JobService,
  private fb: UntypedFormBuilder, private jobTypeService: JobTypeService,
  private genericServiceProviderSettingService: GenericServiceProviderSettingService, private router: Router,
  private customerCommuncationService: CustomerCommunicationService, private customerCommunicationManagementService: CustomerCommunicationManagementService,
  private schedulerService: VerticalSchedulerService, private physicalAddressRoutingService: PhysicalAddressRoutingService) { }

  get disablePropertyHistory() { return this.jobCountAtAddress$.value === "0"; }
  get disableAttachments() { return this.job.value.externalAttachments.length === 0;}
  get externalAttachementCount() { return this.job.value.externalAttachments.length; }

  ngOnInit(): void {

    this.jobDetailsFormGroup = this.buildJobDetailsFormGroup();
    this.customerDetailsFormGroup = this.buildCustomerFormGroup();

    this.jobTypes$ = this.jobTypeService.loadAll$().pipe(
      map(x=> x.filter(q=>q.active)),
      takeUntil(this.destroyingComponent$)
    );
    this.jobPriorities$ = this.genericServiceProviderSettingService.allJobPriorities.pipe(map(x=>x.filter(q=>q.active)));

    this.job.pipe(
      filter(x => x !== null),
      tap( x => {
          this.patchJobDetailsFormGroup();
          this.patchUpdatesToJobDetailsToCustomerFormGroup();
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.customers.pipe(
      filter(x => x.length > 0),
      tap(() => this.patchCustomerFormGroup()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    merge(this.jobDetailsFormGroup.valueChanges,this.destroyingComponent$).pipe(
      map(() => this.patchJobFormGroupToJobResultedInUpdate()),
      filter(x => x===true),
      switchMap(() => this.jobService.update$(this.job.value)),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      }),
      takeUntil(this.destroyingComponent$.pipe(delay(800))),
    ).subscribe();

    //update previous count of jobs @ address when address changes.
    this.customerDetailsFormGroup.get("serviceAddress").valueChanges.pipe(
      startWith(this.customerDetailsFormGroup.get("serviceAddress").value),
      filter(a => a !== ""),
      map(x => (x as Address).DocId()),
      distinctUntilChanged(),
      switchMap(a => this.jobService.queryFirestoreShallow$([where('serviceAddressDocId', '==', a),
        limit(25)])),
        map(q => (q as Job[]).filter(j => j.DocId() !== this.job.value.DocId())),
        map(q => q.length >= 25 ? ">25" : `${q.length}`),
        takeUntil(this.destroyingComponent$)
      ).subscribe(this.jobCountAtAddress$);

  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

    public ViewPropertyHistoryClick() {
      const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '500px',
        data         :
        {
          title: "Service Address History",
          jobs$: this.jobService.queryFirestoreDeep$([where('serviceAddressDocId', '==',
          (this.customerDetailsFormGroup.get("serviceAddress").value as Address).DocId()),limit(25)]),
        }
        });
      this.PropertyHistoryModalRef = this.dialog.open(MultipleJobSummaryComponent, editorConfig);
    }
    buildJobDetailsFormGroup() : UntypedFormGroup {
      return this.fb.group( {
        noAutoFill: [{value: randomElementName(), disabled: true}],
        jobType: ["", [Validators.required]],
        jobPriority: ["", [Validators.required]],
        jobDurationHours: [""],
        notes: ["",{updateOn: 'blur'} ],
        jobTags: [[]],
        jobDurationActualAndScheduledHours: [true],
        estimatedJobDurationHours: [],
        timeSpentOnJobToDateHours: [],
        remainingTimeScheduledForJobHours: [],
        locationAssignedWork: ["", [Validators.required]],
      });
    }

    ViewExternalAttachmentsClick() {
      const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '700px',
        outerHeight  :  '200px',
        data         :
        {
          attachments: this.job.value.externalAttachments,
          job: this.job.value,
          hideUpload: false,
        }
        });
      let dialogRef = this.dialog.open(JobAttachmentsComponent, editorConfig);
    }

    cancelJob() {
      const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '700px',
        outerHeight  :  '200px',
        data         :
        {
          job: this.job.value
        }
        });
      let dialogRef = this.dialog.open(CancelJobConfirmationModalComponent, editorConfig);

      dialogRef.afterClosed().pipe(
        filter(x => x && x['yes']),
        switchMap(x => FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
          map(batch => ({batch, val: x}) )
        )),
        switchMap(x => this.jobService.update$(this.job.value, x.batch).pipe(
          map(() => x),
          take(1)
        )),
        switchMap(x => zip(of(null),...x.val['siteVisitCanceled'].map(s => this.customerCommunicationManagementService.cancelCustomerCommunicationFromSiteVisit(s, "Cancelled from customer job section", x.batch))).pipe(
          map(() => x),
          take(1)
        )),
        switchMap(x => zip(of(null),...x.val['siteVisitCanceled'].map(s => this.schedulerService.removeAssignment(s, x.batch))).pipe(
          map(() => x.batch)
        )),
        switchMap(x => this.jobService.commitExternallyManagedFirestoreBatch(x)),
        take(1)
      ).subscribe();
    }

    reactivateJob() {
      const reactivateJob = new JobEvent({eventType: EXPLICIT_JOB_EVENT.REACTIVATED, message: "Reactivated from job page."});
      const job = this.job.value;
      job.explicitJobEvents.push(reactivateJob);
      job.needsAssigned= job.needsAssignedStateWhenCanceled;
      job.needsAssignedStateWhenCanceled = null;
      this.jobService.update$(job).pipe(
        take(1)
      ).subscribe();
    }

    buildCustomerFormGroup() : UntypedFormGroup {

      return this.fb.group ({
        noAutoFill: [{value: randomElementName(), disabled: true}],
        customers: new UntypedFormArray([]),
        customerPopulated: "",
        primaryCustomerDocId: "",
        serviceAddressToDisplay: [{value: "", disabled: true}],
        serviceAddressToValidateOn: ["", [Validators.required]],
        serviceAddressUnit: [{value: "", disabled: true}],
        serviceAddress: "",
        customersPassedFromAddressSelection: {},
        primaryCustomer: "",
        customerAddedFromServiceSelection: false,
        resetActiveCustomerDocIds: "",
        billingAddressAssociatedWithAddedCustomer: "",
      });
    }

    ChangeAddress() {
      const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '700px',
        outerHeight  :  '200px',
        data         :
        {
          title: `Update Service Address`,
          searchSources: [searchSource.GooglePlaceAutoComplete],
          provideAddressToShopRoutingInfo: false,
          provideJobCountAtAddressInfo: false
        }
        });
      this.updateAddressModalRef = this.dialog.open(AddressSearchModalComponent, editorConfig);
      this.updateAddressModalRef.afterClosed().pipe(
        filter(x => x !== undefined),
        map(x => ({newAddress: x, oldAddress: this.job.value.serviceAddress})),
        tap(address => this.job.value.serviceAddress = address.newAddress),
        mergeMap(x => this.jobService.update$(this.job.value).pipe(map(() => x))),
        tap(x => // alert user after 150ms.
          setTimeout( () =>
          window.alert(`Address has been updated from \n ${x.oldAddress.formattedAddress()} to: \n ${x.newAddress.formattedAddress()}.  ` +
          "Please note it may take a moment to update commute times to / from new address, updates to addresses may cause schedule to be invalid or inferior, so it may be wise to check schedule.")
          , 150)),
        mergeMap(x => this.physicalAddressRoutingService.updateAddressServerSide(x.oldAddress.DocId(), x.newAddress.DocId()).pipe(map(() => x))),
        take(1)
      ).subscribe();
    }

    loadWorkflow() {
      if (this.job.value.siteVisits.length > 0) {
        this.router.navigateByUrl(`/load-workflow/${this.job.value.formModelFirestoreDocId}/job/${this.job.value.DocId()}/sv/${this.job.value.lastSiteVisitDocId}/cust/${this.job.value.customer.DocId()}`)
      } else {
        this.router.navigateByUrl(`/load-workflow/${this.job.value.formModelFirestoreDocId}/job/${this.job.value.DocId()}/cust/${this.job.value.customer.DocId()}`)
      }
    }

    patchCustomerFormGroup() : void {
      this.customerDetailsFormGroup.patchValue({
        primaryCustomerDocId: this.customers.value.find(x=>x.primary).customer.DocId(),
      })
    }

    launchCustomerPdfView() : void {
      this.router.navigate(['/load-customer-preview/', {formModelFirestoreDocId: this.job.value.formModelFirestoreDocId, customerDocId: this.job.value.customer.DocId(),
      jobDocId: this.job.value.DocId(), siteVisitDocId: this.job.value.lastSiteVisitDocId}]);
    }

    DisplayCustomerCommunicationModal() : void {

      const editorConfig = new MatDialogConfig();

      const endCustomerCommuncationObservable: Subject<any> = new Subject();

      const communications = this.customerCommuncationService.queryFirestoreDeep$([where('jobDocId', '==',
      this.job.value.DocId()),
      orderBy('createdAt','desc'),
      limit(100)]).pipe(
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
        takeUntil(endCustomerCommuncationObservable)
      );

      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '500px',
        data         :
        {
          dataSource: communications,
        }
        });

      this.viewCustomerCommuncationsModalRef = this.dialog.open(CustomerCommunicationDisplayModalComponent, editorConfig);

      this.viewCustomerCommuncationsModalRef.afterClosed().pipe(
        tap(() => endCustomerCommuncationObservable.next(null)),
        take(1)
      ).subscribe();
    }

    patchUpdatesToJobDetailsToCustomerFormGroup() : void {
      this.customerDetailsFormGroup.patchValue({
        serviceAddressToDisplay: this.job.value.serviceAddress.formattedAddress(),
        serviceAddressToValidateOn: this.job.value.serviceAddress,
        serviceAddressUnit: this.job.value.serviceAddress.unit,
        serviceAddress: this.job.value.serviceAddress,
      });
    }

    patchJobDetailsFormGroup() : void {
      this.jobDetailsFormGroup.patchValue({
        jobType: this.job.value.jobType,
        jobPriority: this.job.value.jobPriority,
        jobDurationHours: this.job.value.durationExpectedHours,
        notes: this.job.value.notes,
        jobTags: this.job.value.jobTags,
        estimatedJobDuration: true,
        estimatedJobDurationHours: [this.job.value.durationExpectedHours],
        timeSpentOnJobToDateHours: [this.job.value.actualTimeSpentOnJobHours],
        remainingTimeScheduledForJobHours: [this.job.value.remainingScheduledTimeOnJobHours],
        locationAssignedWork: this.job.value.locationAssignedWork,
      },);
    }

    patchJobFormGroupToJobResultedInUpdate() : boolean {
      let retVal = false;
      const fields = ["jobType", "jobPriority", "jobDurationHours", "notes", "locationAssignedWork", "jobTags"];
      fields.forEach(f => {
          if (this.jobDetailsFormGroup.controls[f]['_pendingValue'] !== this.job.value[f]) {
          retVal = true;
          this.job.value[f] = this.jobDetailsFormGroup.controls[f]['_pendingValue'];
          console.warn(this.job.value[f]);
        }
      });
      return retVal;
    }


    public EditCustomerInfoClick() {
      const editorConfig = new MatDialogConfig();
      const form = this.customerDetailsFormGroup;
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '500px',
        data         :
        {
          customerDetailsForm: form
        }
        });

      this.customerDetailsModalRef = this.dialog.open(CustomerDetailsModalComponent, editorConfig);

      of(null).pipe(
        delay(1),
        tap(() => form.patchValue({customersPassedFromAddressSelection: this.customers.value }) ),
        take(1),
      ).subscribe();

      // Event dialog closure:
      this.customerDetailsModalRef.afterClosed().pipe(
        take(1)
        ).subscribe(x => this.eventDialogClosure(x));
    }

    eventDialogClosure(customers: {customer: Customer, role: string, primary: boolean}[]) {
      if (customers !== undefined) {
        console.log(customers);
        this.job.value.billingCustomers=[];
        this.job.value.siteVisitContactCustomers=[];
        customers.forEach(c => {
          if (c.role === "All") {
            this.job.value.billingCustomers.push(c.customer);
            this.job.value.siteVisitContactCustomers.push(c.customer);
          } else if (c.role === "Billing Contact") {
            this.job.value.billingCustomers.push(c.customer);
          } else {
            this.job.value.siteVisitContactCustomers.push(c.customer);
          }
          if (c.primary) {
            this.job.value.customer = c.customer;
          }
        });
        this.jobService.update$(this.job.value).pipe(
          take(1)
        ).subscribe();
      }
    }

}
