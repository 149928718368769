import { CostStructure } from "./cost-structure";

export class Cost {

  costStructure: CostStructure;
  amount: number;
  importLastUpdated: Date;

  public constructor(init?: Partial<Cost>) {
    Object.assign(this, init);
  }

}
