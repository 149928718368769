import { Injectable, NgZone } from "@angular/core";
import { ControlContainerComponent } from "./component-models/control-container.component";
import { map, Observable, of, switchMap, zip } from "rxjs";
import { DerivedWorkflowFieldService } from "./derived-workflow-field.service";
import { TextboxControlComponent } from "./component-models/textbox-control/textbox-control.component";
import { BranchingContainerComponent } from "./branching-container/branching-container.component";
import { FormlyBranchingContainerComponent } from "./component-models/formly-controls/formly-branching-container";
import { UntypedFormBuilder } from "@angular/forms";
import { ComponentFromFormlyFactoryService } from "./component-from-formly-factory.service";
import { FormCatagoryService } from "../../../../common/src/data/dao-services/form-catagory.service";
import { FormFirestoreService } from "../../../../common/src/data/dao-services/form-firestore.service";
import { FormFirestoreSummaryService } from "../../../../common/src/data/dao-services/form-firestore-summary.service";
import { LocalSettingsService } from "../settings/local-settings.service";
import { FormlyComponentUtilityService } from "./component-models/formly-controls/formly-component-utility.service";
import { ImportWorkflowAssignmentService } from "./import-workflow-assignment.service";
import { PriceBookUnitOfMeasureService } from "../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service";
import { SectionComponent } from "./section/section.component";
import { FormlyUtilityService } from "./component-models/formly-controls/formly-utility.service";
import { PriceBookEntryService } from "../../../../common/src/data/dao-services/pricebook/pricebook-entry.service";
import { ControlContainsControlsComponent } from "./component-models/control-contains-controls.component";
import { FormlyNodeStructureComponentMapping } from "./component-models/formly-controls/utilities/component-path";
import { FormFirestore } from "../../../../common/src/data/dao/form-firestore";
import { PriceBookEntry } from "../../../../common/src/data/dao/pricebook/pricebook-entry";

export class ComponentResolutionResult{
  PriceBookEntryDocIds: string[];
  PriceBookEntries: PriceBookEntry[] = [];
  derivedFieldName: string | undefined = undefined;
  firstParentSectionTitle: string;
  linkedGuid: string;
  fieldName: string;
  fieldGuid: string | undefined;
  role: "Price Book Entry" | "Calculated Field" | "Input Field used in Calculation";

  constructor(init?: Partial<ComponentResolutionResult>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RetrieveNestedComponentsService {

  constructor(private fb: UntypedFormBuilder,
      private componentFromFormlyFactory: ComponentFromFormlyFactoryService, private _ngZone: NgZone, private formCatagoryService: FormCatagoryService,
      private formFirestoreService: FormFirestoreService, private formFirestoreSummaryService: FormFirestoreSummaryService, private localSettingsService: LocalSettingsService,
      private formlyComponentUtilityService: FormlyComponentUtilityService, private importDataWorkflowService: ImportWorkflowAssignmentService,
      private unitOfMeasureService: PriceBookUnitOfMeasureService, private formlyUtilityService: FormlyUtilityService, private priceBookEntryService: PriceBookEntryService,
      private derivedWorkflowFieldService: DerivedWorkflowFieldService) {

      }

  GetYoungestSummaryDocId(mappingPath: string) : string | null {
    // Return last referenced formSummaryDocId.  So below will return lfiYPsY5x0rxedCaq43m
    //"[5]-formSum:rw0kGyjcbW000IbExvX4.fieldGroup[0].fieldGroup[2]-formSum:lfiYPsY5x0rxedCaq43m.fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0]"
    const matches = mappingPath.match(/-formSum:([^[]+)/g);
    if (matches) {
      let retVal = matches[matches.length - 1].replace("-formSum:", "");
      retVal = retVal.replace(".fieldGroup","");
      return retVal;
    } else {
      return null;
    }
  }

  GetResultsFromNodeMappings(nodeMappings: FormlyNodeStructureComponentMapping[], calculationRole: "Calculated" | "Input",
    parentSectionFormSummaryDocId: string, fieldName: string, fieldGuid: string) : Observable<ComponentResolutionResult[]> {
    const zippers : Observable<ComponentResolutionResult[]>[] = [of([])];
    for (const x of nodeMappings) {
      let formSummaryDocId =  calculationRole === "Calculated" ? this.GetYoungestSummaryDocId(x.CalculatedComponentPath.pathToComponentFromFirstCommonFormSummaryAncestor) :
        this.GetYoungestSummaryDocId(x.InputComponentPath.pathToComponentFromFirstCommonFormSummaryAncestor);
      let guidSeeking = calculationRole === "Calculated" ? x.CalculatedComponentPath.controlGuid : x.InputComponentPath.controlGuid;
      if (formSummaryDocId === null) {
        formSummaryDocId = parentSectionFormSummaryDocId;
      }
      zippers.push(this.formFirestoreSummaryService.load$(formSummaryDocId).pipe(
        switchMap(formSummary => this.formFirestoreService.load$(formSummary.currentDesignFirestoreDocId)),
        map(formFirestore => {
          const section = this.getSectionFromFormFirestore(formFirestore);
          let dropListData : [];
          let priceBookEntries = [];
          if (section['type'] === "topLevelSection") {
            section.patchFormlyFieldConfigToSection(section);
            priceBookEntries = section['props'].priceBookMappings;
          } else {
            if (section.componentForm.value.priceBookMappings && section.componentForm.value.priceBookMappings.length > 0) {
              priceBookEntries = section.componentForm.value.priceBookMappings.filter(x => x.formlyTextboxGuid === guidSeeking);
            }
          }
          dropListData = section.componentFormGroup.value.columnFormGroups[0].cdkDropListData;
          const parentSectionTitle = formFirestore.title;
          const textBoxComponent = this.derivedWorkflowFieldService.getComponentWithGuid(guidSeeking, dropListData);
          if (textBoxComponent !== null) {
            return [new ComponentResolutionResult({fieldName, fieldGuid, linkedGuid: textBoxComponent.componentForm.value.guid, derivedFieldName: textBoxComponent.componentForm.value.label, firstParentSectionTitle: parentSectionTitle,
              role: calculationRole === "Calculated" ? "Calculated Field" : "Input Field used in Calculation", PriceBookEntryDocIds: priceBookEntries.map(y => y.priceBookEntryDocId)})];
          } else {
            return [];
          }
        })
      ));
    }
    return zip(...zippers).pipe(
      map(x => x.reduce((acc, val) => acc.concat(val), []))
    );
  }

  private getSectionFromFormFirestore(formFirestore: FormFirestore) {
    const section = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, null,
      this.formlyUtilityService, this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService,
      this.formlyComponentUtilityService, this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowFieldService);
    section.componentFormGroup = SectionComponent.generateSectionFormGroup();
    const parsedVal = JSON.parse(formFirestore.form);
    if (!parsedVal.props) {
      parsedVal.props = {};
      parsedVal.props.formFirestoreSummaryDocId = formFirestore.formSummaryDocId;
      parsedVal.props.priceBookMappings = formFirestore.formlySectionPricebookMappings;
      section['props'] = parsedVal.props;
      delete parsedVal.props;
      section['fieldGroup'] = parsedVal.concat({key: '_id', type: 'input', props: {}, hide: true});
      section['type'] = "topLevelSection";
    } else {
      section.patchInFormlyFieldConfig(JSON.parse(formFirestore.form));
    }
    section.patchControlComponentsToFormlyFields();
    section.patchValuesToContainedComponentsAsNeeded();
    section.destroyingComponent$.next(null);
    return section;
  }

  ReturnCalculatedFieldsPresentInComponent(component: ControlContainerComponent | ControlContainerComponent[], calculationRole: "Calculated" | "Input" | "Both",
    parentSectionFormFirestoreSummaryDocId: string) : Observable<ComponentResolutionResult[]> {
    let retVal = of([]);
    if (component instanceof TextboxControlComponent) {
      const zippers : Observable<ComponentResolutionResult[]>[] = [of([])];
      if (calculationRole === "Calculated" || calculationRole === "Both") {
        if (Array.isArray(component.componentForm.value.calculatedComponentNodeMappings) && component.componentForm.value.calculatedComponentNodeMappings.length > 0) {
          zippers.push(this.GetResultsFromNodeMappings(component.componentForm.value.calculatedComponentNodeMappings, "Calculated",
            parentSectionFormFirestoreSummaryDocId, component.componentForm.value.label, component.componentForm.value.guid));
        }
      }
      if (calculationRole === "Input" || calculationRole === "Both") {
        if (Array.isArray(component.componentForm.value.inputComponentNodeMappings) && component.componentForm.value.inputComponentNodeMappings.length > 0) {
          zippers.push(this.GetResultsFromNodeMappings(component.componentForm.value.inputComponentNodeMappings, "Input", parentSectionFormFirestoreSummaryDocId,
            component.componentForm.value.label, component.componentForm.value.guid));
        }
      }
      return zip(...zippers).pipe(
        map(x => x.reduce((acc, val) => acc.concat(val), []))
      );
    }
    //Whether explicit matching is present in BranchingComponent or not, we need to iterate through sections present in branching container.
      //This is also true when updating component movement, and needs to be sorted out there as well.
    if (component instanceof BranchingContainerComponent) {
      const zippers : Observable<ComponentResolutionResult[]>[] = [of([])];
      for (const branch in component.patchToChooser.props.options) {
        const formSummaryDocId = FormlyBranchingContainerComponent.convertSelectionToFormSummaryDocIdAndPriceBookEntryDocId(component.patchToChooser.props.options[branch].value).formSummaryDocId;
        zippers.push(this.formFirestoreSummaryService.load$(formSummaryDocId).pipe(
        switchMap(formSummary => this.formFirestoreService.load$(formSummary.currentDesignFirestoreDocId)),
        map(formFirestore => {
          return this.getSectionFromFormFirestore(formFirestore);
        }),
        switchMap(section => this.ReturnCalculatedFieldsPresentInComponent(section, calculationRole, formSummaryDocId))
        ));
      }
      return zip(...zippers).pipe(
        map(x => x.reduce((acc, val) => acc.concat(val), []))
      );
    }

    if (component instanceof ControlContainsControlsComponent) {
      if (component instanceof SectionComponent) {
        parentSectionFormFirestoreSummaryDocId = component.componentForm.value.formFirestoreSummaryDocId;
      }
      //If component is a control container, recursively call this function on all children.
      const zippers : Observable<ComponentResolutionResult[]>[] = [of([])];
      for (let dropList of component.componentForm.value.columnFormGroups) {
        for (let c of dropList.cdkDropListData) {
          zippers.push(this.ReturnCalculatedFieldsPresentInComponent(c, calculationRole,parentSectionFormFirestoreSummaryDocId));
        }
      }
      return zip(...zippers).pipe(
        map(x => x.reduce((acc, val) => acc.concat(val), []))
      );
    }

    return retVal;
  }

  ReturnPriceBookEntriesPresentInComponent(component: ControlContainerComponent | ControlContainerComponent[], parentSectionFormFirestoreSummaryDocId: string) : Observable<ComponentResolutionResult[]> {
    const retVal = of([]);

      if (component instanceof TextboxControlComponent) {
        return this.formFirestoreSummaryService.load$(parentSectionFormFirestoreSummaryDocId).pipe(
          switchMap(formSummary => this.formFirestoreService.load$(formSummary.currentDesignFirestoreDocId)),
          map(formFirestore => {
            const section = this.getSectionFromFormFirestore(formFirestore);
            const parentSectionTitle = formFirestore.title;
            let priceBookEntries = [];
            if (section.componentForm.value.priceBookMappings && section.componentForm.value.priceBookMappings.length > 0) {
              priceBookEntries = section.componentForm.value.priceBookMappings.filter(x => x.formlyTextboxGuid === component.componentForm.value.guid);
            }
            return priceBookEntries.length > 0 ? [new ComponentResolutionResult({fieldGuid: component.componentForm.value.guid, fieldName: component.componentForm.value.label, linkedGuid: undefined,
              derivedFieldName: undefined, firstParentSectionTitle: parentSectionTitle,
              role: "Price Book Entry", PriceBookEntryDocIds: priceBookEntries.map(y => y.priceBookEntryDocId)})] : [];
          })
        );
      }

      if (component instanceof BranchingContainerComponent) {
      const zippers : Observable<ComponentResolutionResult[]>[] = [of([])];
      for (const branch in component.patchToChooser.props.options) {
        const formSummaryAndPriceBookEntries = FormlyBranchingContainerComponent.convertSelectionToFormSummaryDocIdAndPriceBookEntryDocId(component.patchToChooser.props.options[branch].value);
        //Branching container components are *sometimes* contain sections where mapping to pricebook entry fields is stored w/ the formControl.
        if (formSummaryAndPriceBookEntries.priceBookEntryDocId) {
          zippers.push(this.formFirestoreSummaryService.load$(parentSectionFormFirestoreSummaryDocId).pipe(
            switchMap(formSummary => this.formFirestoreService.load$(formSummary.currentDesignFirestoreDocId)),
            map(formFirestore => {
              return [new ComponentResolutionResult({derivedFieldName: undefined, fieldGuid: undefined, fieldName: `Selection: ${component.patchToChooser.props.options[branch].value} In Branch: ${component.form.value.label}`, firstParentSectionTitle: formFirestore.formSummary.title,
            role: "Price Book Entry", PriceBookEntryDocIds: [formSummaryAndPriceBookEntries.priceBookEntryDocId]})];
            })
          ));
        } else {
          //If there is no explicit mapping, iterate through branch section recursively to check for pricebook entries.
          //20250116
          //This is also true when updating component movement, and needs to be sorted out there as well.
          zippers.push(this.formFirestoreSummaryService.load$(formSummaryAndPriceBookEntries.formSummaryDocId).pipe(
            switchMap(formSummary => this.formFirestoreService.load$(formSummary.currentDesignFirestoreDocId)),
            map(formFirestore => {
              return this.getSectionFromFormFirestore(formFirestore);
            }),
            switchMap(section => this.ReturnPriceBookEntriesPresentInComponent(section, section.componentForm.value.formFirestoreSummaryDocId))
            ));
        }
      }
      return zip(...zippers).pipe(
        map(x => x.reduce((acc, val) => acc.concat(val), []))
      );
      }

      if (component instanceof ControlContainsControlsComponent) {
        if (component instanceof SectionComponent) {
          parentSectionFormFirestoreSummaryDocId = component.componentForm.value.formFirestoreSummaryDocId;
        }
        //If component is a control container, recursively call this function on all children.
        const zippers : Observable<ComponentResolutionResult[]>[] = [of([])];
        for (let dropList of component.componentForm.value.columnFormGroups) {
          for (let c of dropList.cdkDropListData) {
            zippers.push(this.ReturnPriceBookEntriesPresentInComponent(c, parentSectionFormFirestoreSummaryDocId));
          }
        }
        return zip(...zippers).pipe(
          map(x => x.reduce((acc, val) => acc.concat(val), []))
        );
      }
    return retVal;
  }
}
