import { Injectable } from '@angular/core';
import { concat, Observable, of, ReplaySubject, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RemapAssociatedComponentsService {

  remapAssociatedComponentOperations: Observable<any>[] = [];
  activeSubscription: boolean = false;
  completedCycle$: Subject<null> = new Subject<null>();
  activelyRemappingComponents$ : ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor() {
    this.activelyRemappingComponents$.next(false);
  }

  addAssociatedComponentOperation(associatedComponentOperation: Observable<any>) {
    this.remapAssociatedComponentOperations.push(associatedComponentOperation);
    if (!this.activeSubscription) {
      this.activeSubscription = true;
      setTimeout(() => {
        of(null).pipe(
          tap(() => this.activelyRemappingComponents$.next(true)),
          tap(x => {
          console.error(`${this.remapAssociatedComponentOperations.length} REMAP OPERATIONS TO PROCESS.`);
          }),
          tap(() => this.remapAssociatedComponentOperations.push(of("Completed"))),
          switchMap(() => concat(...this.remapAssociatedComponentOperations)),
          tap( x => {
            if (x === "Completed") {
              setTimeout(() => {
                this.activeSubscription = false;
              this.remapAssociatedComponentOperations = [];
              this.completedCycle$.next(null);
              this.activelyRemappingComponents$.next(false);
              console.error("COMPLETED A CYCLE OF REMAP OPERATIONS.");
              },750)
            }
          }),
          takeUntil(this.completedCycle$),
        ).subscribe();
      },100);
    }
  }
}
