import { RetrieveFirestorePropertiesBase } from "../../database-backend/retrieve-firestore-properties";

export class PriceBookCatagory extends RetrieveFirestorePropertiesBase<PriceBookCatagory>  {

  name:string;
  code: string;
  active: boolean = true;
  importLastUpdated: Date;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];


public constructor(init?: Partial<PriceBookCatagory>) {
  super();
  Object.assign(this, init);
}

retrieveFirestoreIgnoredMemberNames() : string[] { return PriceBookCatagory._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return PriceBookCatagory._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return PriceBookCatagory._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return PriceBookCatagory._firestoreCompositionMemberNames;}

}
