import { Injectable } from '@angular/core';
import { SiteVisit } from '../dao/site-visit';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { AuthenticationService } from '../../util/authentication.service'
import { CommuteService } from './commute.service';
import { EmployeeService } from './employee.service';
import { CustomerCommunicationManagementService } from '../../../../web-app/src/app/customer-communication/customer-communication-management.service';
import { Observable, Subject, debounceTime, filter, map, merge, of, switchMap, take, tap } from 'rxjs';
import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitService extends DatabaseStoreService<SiteVisit> {

  private locallyExistingOnlyCache: Map<string, SiteVisit> = new Map();
  private prospectiveSiteVisitDocIds: string[] = [];
  private replinishSiteVisitDocIds$: Subject<void> = new Subject<void>();

  constructor( private commuteService: CommuteService, public fs: SiteVisitFirestoreService, authenticationService: AuthenticationService, store: SiteVisitStoreService,
    private customerCommunicationCreationService: CustomerCommunicationManagementService, private employeeService: EmployeeService,
    private logger: LoggingService ) {
    super(fs, store, authenticationService,
      new Map([["prospectiveCommutes", {associatedDocumentId: "prospectiveCommuteDocIds",compositionStoreService: commuteService}],
               ["actualCommutes", {associatedDocumentId: "actualCommuteDocIds",compositionStoreService: commuteService}],
               ["employee", {associatedDocumentId: "employeeDocId",compositionStoreService: employeeService}],
              ]));


    this.authenticationService.isLoggedIn$.pipe(
    filter(x => x === true),
    switchMap(() => merge(of(null),this.replinishSiteVisitDocIds$)),
    debounceTime(100),
    switchMap(() => this.fs.getProspectiveSiteVisitDocIds()),
    tap(x => this.prospectiveSiteVisitDocIds.push(...x))
    ).subscribe();
  }

  getUnassignedSiteVisitDocId() : string {
    if (this.prospectiveSiteVisitDocIds.length < 100) {
      this.replinishSiteVisitDocIds$.next();
    }
    return this.prospectiveSiteVisitDocIds.pop();
  }

  get(docId: string): SiteVisit {
    let retVal = super.get(docId);
    if (retVal === undefined) {
      return this.locallyExistingOnlyCache.get(docId);
    } else {
      return retVal;
    }
   }

   public cacheSiteVisitLocally(siteVisit: SiteVisit) {
    this.locallyExistingOnlyCache.set(siteVisit.docId, siteVisit);
   }

   override update$(obj: SiteVisit, passBatch?: string | null): Observable<SiteVisit> {
      this.logErrentSiteVisitCreation(obj,"update");
       return super.update$(obj, passBatch);
   }

   override create$(obj: SiteVisit, passBatch?: string | null): Observable<SiteVisit> {
    this.logErrentSiteVisitCreation(obj,"create");
    return super.create$(obj, passBatch);
   }

   private logErrentSiteVisitCreation(obj: SiteVisit, source: string) {
    if (! Array.isArray(obj.actualCommutes) || (obj['_startDate'] === undefined && obj.arrivalWindowStartDate === null)) {
      this.logger.addLog(`job.service.ts Invalid Site Visit ${obj.docId}`, "site-visit", {at: new Date(), siteVisit: obj, source, stackTrace: new Error().stack});
     }
   }

}

@Injectable({
  providedIn: 'root'
})
export class SiteVisitStoreService extends StateChangeStoreService<SiteVisit> {
  protected store = "site-visit-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, SiteVisit>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class SiteVisitFirestoreService extends FirestoreBackend<SiteVisit> {

  protected basePath = "site_visit";

  constructor(protected authService: AuthenticationService) {
    super(new SiteVisit(), authService);
  }

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): SiteVisit {
    return new SiteVisit(obj);
  }

  public getProspectiveSiteVisitDocIds() : Observable<string[]>{

    const guid = Math.random().toString(36).substring(0, 7);

    setTimeout(() =>
      FirestoreBackend.worker.postMessage({
        operation: 'batchDocIds',
        batchSize: 500,
        signalGuid: guid,
        path: this.basePathRead(),
      }),5);

      return FirestoreBackend.firestoreBackendMessage$.pipe(
          filter((y) => y.signalGuid === guid),
          map(x => x['docIds']),
          take(1)
        );
  }
}
