<div style="background-color: #f8f8f8;">
  <div class="header-label header-border">Are you sure you wish to remove this component?</div>
      <div>
            <table mat-table [dataSource]="dataSource | async" class="mat-elevation-z8">

              <!-- displayedColumns: string[] = ['Input Field', 'Section', 'Derived Field', 'Warning', 'Price Book Entry','Price Book Section']; -->

              <ng-container matColumnDef="Input Field">
                <th mat-header-cell *matHeaderCellDef>Input Field</th>
                <td mat-cell *matCellDef="let c"> {{c.fieldName}} </td>
              </ng-container>

              <ng-container matColumnDef="Section">
                <th mat-header-cell *matHeaderCellDef>Section</th>
                <td mat-cell *matCellDef="let c"> {{c.firstParentSectionTitle}} </td>
              </ng-container>

              <ng-container matColumnDef="Derived Field">
                <th mat-header-cell *matHeaderCellDef>Derived Field</th>
                <td mat-cell *matCellDef="let c"> {{c.derivedFieldName}} </td>
              </ng-container>

              <ng-container matColumnDef="Warning">
                <th mat-header-cell *matHeaderCellDef>Warning</th>
                <td mat-cell *matCellDef="let c"> {{c.role}} </td>
              </ng-container>

              <ng-container matColumnDef="Price Book Entry">
                <th mat-header-cell *matHeaderCellDef>Price Book Entry</th>
                <td mat-cell *matCellDef="let c"> {{getPriceBookEntryTitle(c)}} </td>
              </ng-container>

              <ng-container matColumnDef="Price Book Category">
                <th mat-header-cell *matHeaderCellDef>Price Book Category</th>
                <td mat-cell *matCellDef="let c"> {{getPriceBookCategoryTitle(c)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"
                class="example-element-row">
              </tr>

            </table>
      </div>

  <div class="footer">
    <div><button mat-button class="button-gray" (click)="proceed()">Proceed with removal</button></div>
    <div style="display: flex; justify-content: flex-end;">
      <div><button mat-button class="button-gray" (click)="cancel()">Cancel</button></div>
    </div>
  </div>
  </div>
