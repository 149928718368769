import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

enum NgxLoggerLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  FATAL = 6,
  OFF = 7
}

export class LogEntry extends RetrieveFirestorePropertiesBase<LogEntry> {
  userDocId?: string | null;
  userName?: string | null;
  generationTime: Date;
  logType: NgxLoggerLevel;
  version?: string | null;
  app: string | null;
  state: any;
  origin: string | null;
  message?: string | null;
  employeeGuid?: string | null;
  constructorGuid: string = "";
  DeviceId: string = "";
  platform: string = "";
  constructorInitilized: Date | null = null;

  public constructor(init?: Partial<LogEntry>) {
    super();
    Object.assign(this, init);
  }

}
