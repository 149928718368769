<mat-menu #fileMenu="matMenu" >
  <button mat-menu-item [matMenuTriggerFor]="importedDataSubMenu" [disabled]="!importedData">
    <mat-icon style="color: green;">east</mat-icon>
    <span>Work with imported data</span>
  </button>
  <button mat-menu-item (click)="GeneratePricebookData()">
    <mat-icon style="color: green;">money</mat-icon>
    <span>Generate Pricebook Output</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="DeleteSectionDefinition()">
    <mat-icon style="color: red;">delete_sweep</mat-icon>
    <span>Delete Selected Section Definition</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="LiveAction()">
    <mat-icon style="color: blueviolet;">west</mat-icon>
    <span>Live Action</span>
  </button>
</mat-menu>

<mat-menu #importedDataSubMenu="matMenu">
  <button mat-menu-item (click)="LoadCatagory()" [disabled]="DisableCatagory">Price Book Entries by Catagory </button>
  <button mat-menu-item (click)="LoadUnassigned()" [disabled]="DisableUnassigned">Load Unassigned</button>
  <button mat-menu-item (click)="LoadNew()" [disabled]="DisableNew">Load New</button>
  <button mat-menu-item (click)="LoadUnitsOfMeasure()" matTooltip= "Update default workflow associated with imported units of measurement.">Units of Measure </button>
</mat-menu>

<div style="width: 788px;">  <!--width 788 makes gives the header firm width. Which makes the text box not push outside the normal witdth of design view a text box has a width of 80% of 788px-->
  <div class="button-container">
      <button mat-button [matMenuTriggerFor]="fileMenu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>
