import { AngularFireAuth } from "@angular/fire/compat/auth";
import {  } from "@angular/fire/compat/auth";

import { Injectable, NgZone, inject } from '@angular/core';
import { Router } from "@angular/router";
import { endOfDay } from 'date-fns';
import { from, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {  delay, filter, map, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EmployeePermissionBuilder } from '../../../web-app/src/app/employee-permission-builder';
import { FirestoreBackend } from '../data/database-backend/retrieve-from-firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Employee } from '../data/dao/employee';
import { LocalSettingsService } from '../../../web-app/src/app/settings/local-settings.service';
import { Ability } from '@casl/ability';
import { LoggingService } from '../data/logging/logging.service';

interface Token{
  refreshToken : string;
  token: string;
  refreshUrl: string;
  expires: number;
}

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  get firebaseBasePathWrite() : string { return this._firebasePathWrite };
  get firebasePathRead() : string { return this._firebasePathRead };
  get bucketId() : string { return this._bucketId };
  get logExtendedSnapshotInformation() : boolean { return false; }
  get logExtendednapshotInformationDocumentIds() : boolean { return false; }

  get retrieveLocalSettingsService() : LocalSettingsService { return this.localSettingsService; }
  get retrieveLoggingService() : LoggingService { return this.loggingService; }

  _firebasePathWrite : string;
  _firebasePathRead : string;
  _bucketId: string;
  _userId : string | undefined;
  guid = Math.random().toString(36).substring(0, 10);
  private localSettingsService: LocalSettingsService = inject(LocalSettingsService);
  mobileSite: boolean = this.localSettingsService.app === "MOBILE";
  tkn: Token;

  private renewPermissions$ = new Subject<any>();
  private loggedInEmployee : Employee;
  public loginScreenActive: boolean = false;
  public updatedUser$= new ReplaySubject<string>(1);
  public isLoggedIn$ = new ReplaySubject<boolean>(1);
  public employeePermissionsLoaded$ = new ReplaySubject<boolean>(1);
  public settingsLoaded$ : ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private employeePermissionBuilder: EmployeePermissionBuilder, public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,public ngZone: NgZone, private http: HttpClient, private ability: Ability,
    private loggingService: LoggingService) {



    from(this.afAuth.onIdTokenChanged(n => {
      if (n) {
        const refreshToken = n['auth'].currentUser.stsTokenManager.refreshToken;
        const token = n['auth'].currentUser.stsTokenManager.accessToken;
        const refreshUrl = `https://securetoken.googleapis.com/v1/token?key=${n['auth'].config.apiKey}`;
        const expires = n['auth'].currentUser.stsTokenManager.expirationTime;
        this.tkn = {refreshToken, token, refreshUrl, expires};
      }
    })).subscribe();

    const userSignedInTokenResult = this.afAuth.idTokenResult.pipe(
      filter(x => x!==null),
      tap(x => console.log(x,` string`)),
      tap(x => this._userId = x.claims.user_id),
      tap(token => {
        if (token.claims.bucketId) {
          const bucketOverride = this.localSettingsService.loadFromLocalStorage("BirdDog", "activeCollection");
          // const bucketOverride = undefined;
          if (bucketOverride) {
            this._firebasePathRead = `/service-providers/${bucketOverride.id}/`;
            this._firebasePathWrite = `/service-providers/${bucketOverride.id}/`;
            this._bucketId = bucketOverride.id;
          } else {
            this._firebasePathRead = `/service-providers/${token.claims.bucketId}/`;
            this._firebasePathWrite = `/service-providers/${token.claims.bucketId}/`;
            this._bucketId = token.claims.bucketId;
          }
        } else {
          this._firebasePathRead = undefined;
          this._firebasePathWrite = undefined;
          this._bucketId = undefined;
        }
      }),
      map(token => token.claims.bucketId ? token.claims.user_id : ""),
      tap(x => {
        if (x === "") {
          window.alert("E-mail address is not associated with an active account please contact your administrator add or modify your employee settings.");
          this.SignOut();
        }
      }),
      );

    const userSignedOutTokenResult = this.afAuth.idTokenResult.pipe(
      //Comes in hot w/ null on sign out.
      filter(x => x===null),
      tap(() => {
        this._firebasePathRead = undefined;
        this._firebasePathWrite = undefined;
        this._bucketId = undefined;
      }),
      map(() => "")
    );

    this.updatedUser$.pipe(
      tap(x => console.log(x,` string`)),
      map(uuid => uuid === "" ? false : true),
      )
      .subscribe(this.isLoggedIn$);

    merge(userSignedInTokenResult,userSignedOutTokenResult).pipe(share()).subscribe(this.updatedUser$);

  }

  updateUserActive(uid:string, active: boolean) {
    const url = "https://us-central1-service-vanguard.cloudfunctions.net/um-disableOrEnableUser";
    return from(this.afAuth.currentUser).pipe(
      switchMap(currentUser =>
        from(currentUser.getIdToken()).pipe(
          map(tkn => ({token: tkn, user: currentUser }))
        )),
      map(userData => {
        return { query: {
          initiatingUid: userData.user.uid,
          uid: uid,
          disabled: !active
        },
        headers: new HttpHeaders()
          .set('Authorization', userData.token)
          .set('Content-Type', 'application/json')
        };
      }),
      switchMap(x => this.http.post(`${url}?initiatingUid=${x.query.initiatingUid}&uid=${x.query.uid}&disabled=${x.query.disabled}`, {headers: x.headers}))
    )
  }

  createOrUpdateAuthAccount(email: string, displayName:string, uid:string) : Observable<Object>{
    // const url = "http://localhost:5001/service-vanguard/us-central1/um-createUser";
    const url = "https://us-central1-service-vanguard.cloudfunctions.net/um-createUser";
    return from(this.afAuth.currentUser).pipe(
      switchMap(currentUser =>
        from(currentUser.getIdToken()).pipe(
          map(tkn => ({token: tkn, user: currentUser }))
        )),
      map(userData => {
        return { query: {
          initiatingUuid: userData.user.uid,
          email: email,
          displayName: displayName,
          uid: uid,
          bucketId: this.bucketId
        },
        headers: new HttpHeaders()
          .set('Authorization', userData.token)
          .set('Content-Type', 'application/json')
        };
      }),
      switchMap(x => this.http.post(`${url}?initiatingUuid=${x.query.initiatingUuid}&email=${x.query.email}&displayName=${x.query.displayName}&uid=${x.query.uid}&bucketId=${x.query.bucketId}`, {headers: x.headers}))
    )
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      location.reload();
    })
  }

  forceRefreshToken() {
    return from(
    this.afAuth.currentUser.then(user => {
      user.getIdToken(true);
    }));
  }

  get activelyLoggedInEmployeeDocId(): string {  return this.loggedInEmployee ? this.loggedInEmployee?.DocId() : "" }
  get activelyLoggedInEmployee() : Employee { return this.loggedInEmployee ? this.loggedInEmployee : undefined }

  updateEmployeePermissions(e: Employee) {
    this.renewPermissions$.next(null);
    this.loggedInEmployee = e;
    this.localSettingsService.loggedInEmployeeDocId  = e ? e.DocId() : "";
    this.localSettingsService.loggedInEmployeeName = e ? e.name : "";
    this.loggingService.addLog(`Authenticated ${e?.name}`,"authentication.service.ts");
    this.employeePermissionBuilder.addAbilitiesFromPermissions(e === undefined ? [] : e.employeePermissions);
    if (e !== undefined) {
      this.employeePermissionsLoaded$.next(true);
    }

    // Permissions need to be updated daily, b/c (some) of them are based on number of days off current date.
    const waitMilliseconds = endOfDay(new Date()).valueOf() - new Date().valueOf() + 60000;
    of().pipe(
      delay(waitMilliseconds),
      tap(() => this.updateEmployeePermissions(this.loggedInEmployee)),
      tap(() => console.error(this.ability)),
      takeUntil(merge(this.renewPermissions$, FirestoreBackend.destroyingComponent$))
    ).subscribe();

  }


}

