
<div class="resend-communication-grid">
<div class="resend-communication-header">Arrival window has been changed for site visit, please indicate how you would like to proceed.</div>
<div *ngFor="let c of customersWithRolesAndPrimaryDesignation" style="grid-column: 1/3; padding: 10px 200px;">
  <app-customer-display-card
    [customerWithRoleAndPrimary] = c
  ></app-customer-display-card>
</div>
<div class="resend-communication-update">
<h4>Arrival Window Previously Set To:</h4>
<h5>{{arrivalWindowDateFormatStart(previousArrivalWindowStart)}} - {{arrivalWindowDateFormatEnd(previousArrivalWindowEnd)}}</h5>
</div>
<div class="resend-communication-update">
<h4>Arrival Window Updated To:</h4>
<h5>{{arrivalWindowDateFormatStart(currentArrivalWindowStart)}} - {{arrivalWindowDateFormatEnd(currentArrivalWindowEnd)}}</h5>
</div>
<div class="resend-communication-footer">
<button mat-button class="button-gray" (click)="Exit()">Exit</button>
<button mat-button class="button-blue" (click)="Resubmit()">Resend Confirmation</button>
</div>
</div>
