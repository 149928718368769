import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, merge, Observable, of } from 'rxjs';
import { concatMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { LabelLocation, underlineControlOptions } from '../component-models/control-container.component';
import { ControlContainsLiveviewComponent } from '../component-models/control-contains-liveview.component';

export enum ChoiceControlType {
  DropDown = 1,
  Radio = 2,
  CheckBoxes = 3
}

@Component({
  selector: 'app-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceComponent extends ControlContainsLiveviewComponent implements OnDestroy, AfterViewInit, OnInit {
  liveFormlyFieldConfig: FormlyFieldConfig;
  @Input() formlyFieldConfigToPatch: FormlyFieldConfig;
  get changeDetect() : Observable<any> { return this.liveFormlyFieldConfig === undefined ?  (this.fields[0] as FormlyFieldConfig).props.changeDetect as Observable<any> :
      this.liveFormlyFieldConfig.props.changeDetect as Observable<any>; }

  ngOnDestroy(): void {
    this.controlContainerCommonDestruction();
  }

  patchControlComponentsToFormlyFields(): void {
    const obs$ = this.patchControlComponentsToFormlyFieldsCommon();

    // type hides field depending on value.  This only affects the formlyField in Form Builder mode, as
    // control only includes active field when creating live view.

    obs$.push(
      this.form.get("liveFormlyFieldConfig").valueChanges.pipe(
        tap(x => this.liveFormlyFieldConfig = x)
      ));

    obs$.push(this.componentForm.get("choiceControlType").valueChanges.pipe(
      startWith(this.componentForm.get("choiceControlType").value),
      tap(x => {
        this.fields[0].props["hidden"] = x === ChoiceControlType.Radio ? false : true;
        this.fields[1].props["hidden"] = x === ChoiceControlType.DropDown ? false : true;
        this.fields[2].props["hidden"] = x === ChoiceControlType.CheckBoxes ? false : true;
        }),
        // On first emission, don't trigger form update.
        concatMap((value,index) => index > 0 ?
          of(value).pipe(
            tap(() => this.form.patchValue({triggerUpdate: {}}))
          ) : of(value))
      ));

    // obs$.push(merge(this.fields[0].formControl.valueChanges,this.fields[1].formControl.valueChanges,this.fields[2].formControl.valueChanges).pipe(
    //   tap(x => {
    //     if (this.liveFormlyFieldConfig?.formControl) {
    //     this.liveFormlyFieldConfig.formControl.setValue(x);
    //     }
    //   })));

    // obs$.push(this.componentForm.get("value").valueChanges.pipe(
    //   startWith(this.componentForm.get("value").value),
    //   tap(x => {
    //     (this.fields as FormlyFieldConfig[]).forEach(field => field.formControl.setValue(x));
    //     if (this.liveFormlyFieldConfig) {
    //       this.liveFormlyFieldConfig.formControl.setValue(x);
    //     }
    //   })));

      obs$.push(this.patchLabelRelatedFromComponentFormToTemplateOptions((this.fields as FormlyFieldConfig[]).concat(this.liveFormlyFieldConfig)));

      merge(...obs$).pipe(
        tap(() => {
          this.fields[0].props.changeDetect.next();
          if (this.liveFormlyFieldConfig){
            this.liveFormlyFieldConfig.props.changeDetect.next();
          }
        }),
        takeUntil(this.destroyingComponent$)
      ).subscribe();

      this.componentForm.get("options").valueChanges.pipe(
        startWith(this.componentForm.get("options").value),
        debounceTime(10),
        takeUntil(this.destroyingComponent$),
      ).subscribe(this.options$)

  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Choice");
    retVal.patchValue({
      icon: "list",
      iconColor: "#1dc512",
      controlComponentFormGroup: this.createChooserFormGroup(),
    });
    return retVal;
  }

  createChooserFormGroup() {
    return this.fb.group({
      labelLocation: LabelLocation.Left,
      labelBold: false,
      underline: underlineControlOptions.None,
      label:  "Default Label",
      value: "",
      allowAddFromDragDrop: true,
      choiceControlType: ChoiceControlType.Radio,
      options: this.fb.array([
        this.fb.group({
          label: this.fb.control('1st choice'),
          value: 1,
          focused: false,
          priceBookEntryDocId: null,
        }),
        this.fb.group({
          label: this.fb.control('2nd choice'),
          value: 2,
          focused: false,
          priceBookEntryDocId: null,
        }),
        this.fb.group({
          label: this.fb.control('3rd choice'),
          value:  3,
          focused: false,
          priceBookEntryDocId: null,
        })
      ]),
    })
  }

  toFormlyFieldConfigJsonOnly() : FormlyFieldConfig {
    const retVal = super.toFormlyFieldConfigJsonOnly();
    const {options, ...dingo} = retVal.props;
    dingo["options"] = this.options$.getValue();
    retVal.props = dingo;
    // console.log(retVal);
    return retVal;
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    let retVal: FormlyFieldConfig = {};
    let sourceFormlyFieldConfig: FormlyFieldConfig;

    for ( var i=0; i<4; i++) {
      if (!this.fields[i].props.hidden) {
        sourceFormlyFieldConfig = this.fields[i];
        break;
      }
    }
    retVal = super.convertFormlyFieldConfigToLiveViewVersion(sourceFormlyFieldConfig);

    if (retVal.props["value"]) {
       retVal.defaultValue = retVal.props["value"];
    } else {
      retVal.defaultValue = undefined;
    }
    this.form.patchValue({liveFormlyFieldConfig: retVal});
    return retVal;
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {
    super.patchCommonFieldsToForm(formlyConfig);
    for (var prop in formlyConfig.props) {
      if (this.componentForm.controls[prop] !== undefined && prop !== "options") {
        this.componentForm.controls[prop].patchValue(formlyConfig.props[prop]);
      }
    }

    if ((formlyConfig.props.options as Array<any>).length > 0) {
      (this.componentForm.get("options") as UntypedFormArray).clear();
      formlyConfig.props.options.forEach(option => {
        (this.componentForm.get("options") as UntypedFormArray).push(
          this.fb.group({
            label: option["label"],
            value: option["value"]
          }));
      });
    };

    this.patchLabelRelatedClassesToComponentForm(formlyConfig);
  }

  constructor(protected fb: UntypedFormBuilder)
  {
    super(ChoiceComponent,fb);
    this.form = this.initilizeFormGroup();

      this.fields = [
        this.formlyLiveViewFields({
          type: "radio",
          label: "Radio",
          hidden: false,
          props: {
            options: this.options$.asObservable(),
          },
          modelOptions: {
            updateOn: 'change'
          },
          className: "choice-spec",
        }),
        this.formlyLiveViewFields({
          type: "select",
          label: "Select",
          hidden: true,
          props: {
            appearance: "outline",
            options: this.options$.asObservable(),
          },
          modelOptions: {
            updateOn: 'change'
          },
          className: ""
        }),
        this.formlyLiveViewFields({
          type: "multicheckbox",
          label: "Checkbox",
          hidden: true,
          props: {
            readonly: true,
            options: this.options$.asObservable(),
          },
          modelOptions: {
            updateOn: 'change'
          },
          className: "choice-spec checkbox-choice-spec"
        }),
  ];
  this.patchControlComponentsToFormlyFields();
  }
  ngOnInit(): void {
    if (this.formlyFieldConfigToPatch) {
      this.patchInFormlyFieldConfig(this.formlyFieldConfigToPatch);
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.NumberComponents++;

    (this.fields[0] as FormlyFieldConfig).props.changeDetect.next(null);

    if (this.liveFormlyFieldConfig) {
      this.liveFormlyFieldConfig.props.changeDetect.next(null);
    }

  }

}
