import {isEqual} from 'lodash-es';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, OnDestroy, ElementRef, ChangeDetectionStrategy, ViewChildren, QueryList } from '@angular/core';
import {SchedulerComponent} from 'bryntum-angular-shared';
import {initilizeSchedulerConfig} from './schedulerConfig';
import {VerticalSchedulerService, ByrntumData} from '../vertical.scheduler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, BehaviorSubject, ReplaySubject, Subject, combineLatest, merge, zip,  of, forkJoin, throwError, from } from 'rxjs';
import { Job, JOB_STATUS } from '../../../../common/src/data/dao/job';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { tap, filter, map, delay, delayWhen, throttleTime, switchMap, skip, distinctUntilChanged, take, debounceTime,  takeUntil, share, mergeMap, debounce, catchError, finalize } from 'rxjs/operators';
import { addDays, endOfWeek, startOfWeek, addMinutes, subMinutes, startOfDay, subDays, addHours, add } from 'date-fns';
import { BryntumAssignment, BryntumEvent } from '../../../../common/src/bryntum/bryntum-event';
import { BryntumResourceDateSummation } from '../../../../common/src/bryntum/bryntum-resource';
import { FormBuilder, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EventDetailsComponent } from './event-details/event-details.component';
import { SchedulingGuidanceService, SchedulingGuidance } from '../scheduling/scheduling-guidance.service';
import { ResourceAvailibility, RESOURCE_AVAILIBILITY_CALCULATION_METHOD } from '../scheduling/resource-availibility';
import { ResourceAvailibilityService } from '../scheduling/resource-availibility.service';
import { SettingsService } from '../settings/settings.service';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import { Employee } from '../../../../common/src/data/dao/employee';
import { ModifyEmployeeScheduleModalComponent } from './modify-employee-schedule-modal/modify-employee-schedule-modal.component';
import { EmployeeAvailability } from '../../../../common/src/data/dao/employee-availability';
import { EmployeeAvailabilityService } from '../../../../common/src/data/dao-services/employee-availability.service';
import { LocalSettingsService } from '../settings/local-settings.service';
import { CustomerCommunicationManagementService } from '../customer-communication/customer-communication-management.service';
import {  CustomerCommunication } from '../../../../common/src/data/dao/customer-communication';
import { ResendCustomerCommunicationModalComponent } from '../customer-communication/resend-customer-communication-modal/resend-customer-communication-modal.component';
import { PhysicalAddressRoutingService } from '../physical-address-routing.service';
import { EmployeeService } from '../../../../common/src/data/dao-services/employee.service';
import { SiteVisitService } from '../../../../common/src/data/dao-services/site-visit.service';
import { VerticalSchedulerViewWindow } from './scheduler-view-header/day-week-view/day-week-view.component';
import { CustomerCommunicationService } from '../../../../common/src/data/dao-services/customer-communication.service';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';
import { LiteDomClassList } from '../../../../common/src/util/util';
import { JobDurationDeltaModificationType } from '../../../../common/src/data/dao/job-duration-delta';
import { CustomerCommunicationCategory } from '../../../../common/src/data/dao/customer-communication-template';
import { AddressService } from '../../../../common/src/data/dao-services/address.service';
import { AddressRoutingService } from '../../../../common/src/data/dao-services/address-routing.service';
import { DexieCacheService } from '../../../../common/src/data/dao-services/dexie-cache.service';
import { ScheduleModificationReqModalComponent } from './schedule-modification-req-modal/schedule-modification-req-modal.component';
import { CommuteCharacterization } from '../../../../common/src/data/dao/commute';
import { Address } from '../../../../common/src/data/dao/address';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';
import { MinimizeSiteVisitMutationService } from '../scheduling/minimize-site-visit-mutation.service';
import { where } from 'firebase/firestore';
import { EmployeeGeofenceLogService } from '../../../../common/src/data/dao-services/employee-geofence-log-service';
import {EmployeeLocationService} from '../../../../common/src/data/dao-services/employee-location-service';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { FirestoreBackend } from '../../../../common/src/data/database-backend/retrieve-from-firestore';
import { LoggingService } from '../../../../common/src/data/logging/logging.service';
import { BryntumSettingsService } from '../settings/bryntum-settings.service';

enum SCHEDULE_TIME_VALIDITY_STATE  {
  INVALID,
  VALID_NO_MODIFICATION_NEEDED,
  VALID_AFTER_MODIFICATION,
  // SP choses to book themselves in two disparate physical locations at the same time
  SCHRODINGER_CAT_VALID,
}

@Component({
selector: 'app-scheduler-view',
templateUrl: './scheduler-view.component.html',
styleUrls: ['./scheduler-view.component.scss'],
changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerViewComponent implements OnInit, AfterViewInit, OnDestroy {

numberMinutesAllowedForCommute = 1440;
toggleEventMutability = false;
fileNameDialogRef: MatDialogRef<EventDetailsComponent>;
changeWorkingHoursDialogRef: MatDialogRef<ModifyEmployeeScheduleModalComponent>;
resendCustomerCommunicationDialogRef: MatDialogRef<ResendCustomerCommunicationModalComponent>;
updateRequiresScheduleModificationDialogRef: MatDialogRef<ScheduleModificationReqModalComponent>;

workSplitterStartingX = 0;
initialSidebarWidth = 0;
sidebarWidth = 300;
delayTriggeringBryntumEvents = false;
draggingWorkSplitter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

destroyingComponent$ = new Subject();
viewWindowMovementEnabled$ = new BehaviorSubject<boolean>(true);
filterResourceCommuteDeltaTime$ = new ReplaySubject<number>(1);
resouceCommutePercentage: number = 100;
changeLengthOfSiteVisitBeingScheduled$ = new Subject<number>();
employeesRemovedFromTreeDisplay$ = new Subject<Employee[]>();
employeesAddedToTreeDisplay$ = new Subject<Employee[]>();
updatedViewWindow$: BehaviorSubject<VerticalSchedulerViewWindow>;
changeViewWindowStart$ = new Subject<number>();
currentDate$ = new BehaviorSubject<Date>(startOfDay(new Date()));
updateNumberWorkdaysToDisplay$ = new Subject<number>();
setViewWindowToToday$ = new Subject<null>();
viewWindowUpdatedViaCalendar$ = new Subject<Date>();
currentStartDate : Date;
currentEndDate: Date;

loadedConfig$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


employeesFilteredBySkillSet$: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>(null);
employeesFilteredByRole$: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>([]);
activeEmployeesWeScheduleFor$: ReplaySubject<Employee[]> = new ReplaySubject(1);
activeEmployeesToDisplay: Employee[] = [];
allEmployeesWeScheduleFor: Employee[] = [];
private showingSingleResouce = false;
private displayedActualResourceIds = [];


numberWorkDaysToDisplay = 3;

assignments: BryntumAssignment[] = [];
events: BryntumEvent[] = [];
resources: BryntumResourceDateSummation[] = [];
resourceAvailibility: ResourceAvailibility[] = [];

scheduleLoaded = false;
updatedViewWindow = false;
schedulerLoaded$: ReplaySubject<boolean> = new ReplaySubject(1);
schedulingMode = false;
sideNavOpen = true;

generateDataForActiveView$: Observable<ByrntumData>;
generatedDataOnce$: ReplaySubject<boolean> = new ReplaySubject(1);
loadingFromParams: boolean = false;
draggingEventOnScheduler : boolean = false;
invalidateDrag: boolean = false;


get schedulerOne() : SchedulerComponent { return this.schedulerOneQuery?.first; }

  @ViewChildren('scheduler1') schedulerOneQuery: QueryList<SchedulerComponent>;

@ViewChild('fullPage') parentContainerRef: ElementRef;
@ViewChild('sidenavContainer') sideNavContainer : MatSidenavContainer;

updateSidebarWidth$: Subject<null> = new Subject<null>();
schedulerConfig: any = undefined;


jobsNeedingAssignment$ = new BehaviorSubject<Job[]>([]);
jobsNeedingAssignmentCount$: Observable<number> = new Observable<number>;
onDeckJob$ = new BehaviorSubject<{job: Job, hoursWorkingOnScheduling: number, prospectiveSiteVisitDurationHours: number}>(null);
onDeckJobRespectMinVisitLength$ = new BehaviorSubject<{job: Job, hoursWorkingOnScheduling: number, prospectiveSiteVisitDurationHours: number}>(null);

unassignedWorkClickHandler$ = new Subject<Job>();

assignSiteVisitToEmployee$ = new Subject<{job: Job, employeeDocId: string, startTime: Date, endTime: Date, dayOfYear: Date,
                                          jobHoursActivelyWorkingOnScheduling: number, siteVisitDocId: string,
                                          minimizeSiteVisitMutationsOnReconciliation: boolean, explicitlyErrored: boolean }>();
regenerateBryntumData$ = new Observable<{startDate: Date, endDate: Date, employeeDocIds: string[]}>();
completedSchedulingFromOnDeck$ = new Subject<null>();
schedulerLater$ = new Subject<null>();
toggleSideNav$ = new Subject<null>();
creatingBryntumFromDrag = false;
filterSearch$ = new BehaviorSubject<string>("");
private styleNode: HTMLStyleElement = document.createElement('style');
defaultAnimationDuration = 450;

constructor(public schedulerService: VerticalSchedulerService, private route: ActivatedRoute, private jobService: JobService, private siteVisitService: SiteVisitService,
  private employeeService: EmployeeService, private fb: UntypedFormBuilder, private typedFormBuilder: FormBuilder, private dialog: MatDialog,
  private schedulingGuidanceService: SchedulingGuidanceService, private router: Router, private resourceAvailibilityService: ResourceAvailibilityService,
  private settingsService: SettingsService, private employeeAvailabilityService: EmployeeAvailabilityService, private localSettingsService: LocalSettingsService,
  private customerCommunicationCreationService: CustomerCommunicationManagementService, private customerCommunicationService: CustomerCommunicationService,
  private physicalAddressRoutingService: PhysicalAddressRoutingService, private addressService: AddressService,
  private verticalSchedulingService: VerticalSchedulerService, public addrService: AddressRoutingService, private dexieCacheService: DexieCacheService,
  private customerCommunicationManagementService: CustomerCommunicationManagementService, private authenticationService: AuthenticationService,
  private siteVisitNumberService: MinimizeSiteVisitMutationService, private employeeGeofenceLogService: EmployeeGeofenceLogService,
  private employeeLocationService: EmployeeLocationService, private loggingService: LoggingService, private bryntumSettingsService: BryntumSettingsService )   {
    console.log("Enter constructor");

    this.jobsNeedingAssignmentCount$ = this.jobsNeedingAssignment$.pipe(
      map(x => x.length)
    );

    this.employeeService.loadAll$().pipe(
      tap(x => this.allEmployeesWeScheduleFor = x.filter(y => y.scheduleFieldCallsFor && y.active==true)),
      take(1)
    ).subscribe();

    combineLatest([this.employeesFilteredByRole$, this.employeesFilteredBySkillSet$]).pipe(
      debounce(() => this.schedulerLoaded$),
    map(x => {
      const employeesMatchingSelectedRoles = x[0];
      const employeesMatchingSelectedSkills = x[1];
      if (employeesMatchingSelectedRoles && employeesMatchingSelectedRoles.length === 0 && employeesMatchingSelectedSkills === null) {
        this.schedulerOne.schedulerEngine.mask("To display schedule there must be one or more resources selected to schedule.");
      } else {
        this.schedulerOne.schedulerEngine.unmask();
      }
      let activeEmployees = this.allEmployeesWeScheduleFor;
      if (employeesMatchingSelectedRoles !== null) {
        activeEmployees = activeEmployees.filter(y => employeesMatchingSelectedRoles.some(z => z.docId === y.docId));
      }
      if (employeesMatchingSelectedSkills !== null) {
        activeEmployees = activeEmployees.filter(y => employeesMatchingSelectedSkills.some(z => z.docId === y.docId));
      }
      return activeEmployees;
    }),
    distinctUntilChanged((prev, current) => {
      return prev.map(x => x.DocId()).every((value, index) => current.find(y => y.DocId() === value) !== undefined) &&
      current.map(x => x.DocId()).every((value, index) => prev.find(y => y.DocId() === value) !== undefined);
    }),
    tap(activeEmployees => {
      this.activeEmployeesToDisplay = activeEmployees;
      this.activeEmployeesWeScheduleFor$.next(activeEmployees);
    }),
    takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.activeEmployeesWeScheduleFor$.pipe(
      debounce(() => this.schedulerLoaded$),
      tap(x => {
      if (x.length > 0) {
        this.schedulerOne.schedulerEngine.resourceColumns.show();
        this.schedulerOne.schedulerEngine.unmask();
      }

      this.schedulerOne.schedulerEngine.resourceStore.removeFilter("rolesAndSkills");
      this.schedulerOne.schedulerEngine.resourceStore.
      filter({id: "rolesAndSkills", filterBy: record => x.map(y => y.docId).includes(record.actualResourceId)});
        if (this.onDeckJobRespectMinVisitLength$.value !== null && x.length > 0) {
        this.enterScheduling();
      }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.activeEmployeesWeScheduleFor$.pipe(
      debounce(() => this.schedulerLoaded$),
      filter(x => x.length === 0 && this.allEmployeesWeScheduleFor.length > 0),
      tap(x => {
      console.log(x,` string`);
      }),
      tap(() => {
        this.schedulerOne.schedulerEngine.resourceColumns.hide();
        if (this.employeesFilteredBySkillSet$.value !== null && this.employeesFilteredBySkillSet$.value.length === 0) {
          this.schedulerOne.schedulerEngine.mask("There are no resources possessing the required skillset to complete job.");
        } else {
          this.schedulerOne.schedulerEngine.mask("To display schedule there must be one or more resources selected to schedule.");
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    this.sidebarWidth = localSettingsService.loadFromLocalStorage("SchedulerView", "sidebarWidth", 300);
    this.sideNavOpen = localSettingsService.loadFromLocalStorage("SchedulerView", "sideNavOpen", true);
    this.updatedViewWindow$ = new BehaviorSubject<VerticalSchedulerViewWindow>
      (localSettingsService.loadFromLocalStorage("SchedulerView", "viewWindow", VerticalSchedulerViewWindow.WorkDay));
    this.numberWorkDaysToDisplay = localSettingsService.loadFromLocalStorage("SchedulerView", "numberWorkDaysToDisplay", 3);
  }

  ngOnDestroy(): void {
  console.warn("Destroy SchedulerViewComponent");
  this.destroyingComponent$.next(null);
  this.schedulerService.schedulerViewActive$.next(false);
  this.schedulerService.schedulerDestroyed$.next(null);
  this.destroyingComponent$.complete();
}

ngAfterViewInit(): void {

  this.updateSidebarWidth$.pipe(
    debounceTime(25),
    tap(() => this.sideNavContainer.updateContentMargins()),
    takeUntil(this.destroyingComponent$)
  ).subscribe();

  const onDeckJobWithApplicibleResourcesPopulated$ = this.onDeckJob$.pipe(
    filter(x => x !== null),
    tap(x => console.log(x.hoursWorkingOnScheduling)),
    map(job => {
      const minimumSiteVisitLength = this.settingsService.getValue("minimumSiteVisitLength");
      if (job.hoursWorkingOnScheduling < (minimumSiteVisitLength.getHours() + minimumSiteVisitLength.getMinutes() / 60)) {
        job.hoursWorkingOnScheduling = minimumSiteVisitLength.getHours() + minimumSiteVisitLength.getMinutes() / 60;
        job.prospectiveSiteVisitDurationHours = job.hoursWorkingOnScheduling;
      }
      return job;
    }),
    tap(y => {
      if (y.job.lineItems.flatMap(j => j.requiredSkills).filter(f => f !== null).length > 0 ){
        this.employeesFilteredBySkillSet$.next(this.allEmployeesWeScheduleFor.filter(x => {
          let matchFound = false;
          y.job.lineItems.flatMap(j=>j.requiredSkills).forEach
            (s => {
              if (x.employeeSkills.findIndex(z => z.DocId() === s.DocId()) !== -1) {
                matchFound = true;
              }
            });
          return matchFound;
          }));
      } else {
        this.employeesFilteredBySkillSet$.next(this.allEmployeesWeScheduleFor);
      }
    }),
    share()
  );

  onDeckJobWithApplicibleResourcesPopulated$.pipe(
    filter(() => this.activeEmployeesToDisplay.length > 0),
    takeUntil(this.destroyingComponent$),
    ).subscribe(this.onDeckJobRespectMinVisitLength$);

  onDeckJobWithApplicibleResourcesPopulated$.pipe(
    filter(() => this.activeEmployeesToDisplay.length === 0),
    tap(x => window.alert(`No availiable resouces match the requirements to schedule job for
    ${x.job.customer.customerName}.  Please update employee SkillSet, or reduce the number of SkillSets assigned to the job.`)),
    takeUntil(this.destroyingComponent$),
    ).subscribe();

  const placedOnDeck: Subject<boolean> = new Subject();
  const routedLoadOfSiteVisit = this.route.paramMap.pipe(
    filter(params => params.get("onDeckJob") !== null),
    map(params => params.get("onDeckJob")),
    tap(() => this.loadingFromParams = true),
    takeUntil(this.destroyingComponent$));

  const considerIfJobReadyToBePlacedOnDeck = routedLoadOfSiteVisit.pipe(
    switchMap(docId => this.jobService.load$(docId)),
    // This is actually problem that load comes back w/ job that does not have a properly populated line item object.  Slow CPU or not.
    tap(j => {
      if (j.durationExpectedHours === 0) {
        console.error(j.lineItems.map(x => x), j.lineItemDocIds, j.lineItems.map(x=>x), {...j.serviceAddress}, j.serviceAddress?.generatedCommuteMatrix, "load issue?");
        console.error({...j});
      }
    }),
    filter(job => job.durationExpectedHours - job.siteVisitScheduledHours > 0 && job.jobStatus !== JOB_STATUS.CANCELLED),
    switchMap(job => this.physicalAddressRoutingService.retrieveOnDemandFromDixie(job.serviceAddress).pipe(
      map(() => job),
    )),
    debounce(() => this.schedulerLoaded$),
    debounce(() => this.verticalSchedulingService.processingChangeToSchedule$.pipe(filter(x => x === false))),
    tap(() => {
      this.schedulerOne.schedulerEngine.mask("Generating Commute Matrix");
    }),
    switchMap(x => this.addressService.load$(x.serviceAddress.DocId()).pipe(
      map(addr => {
        x.serviceAddress = addr;
        return x;
        })
    )),
    takeUntil(placedOnDeck),
    // subsequent updating is done via common path (regardless of whether job was put on deck
    // by activated route or by user clicking on unassigned work)
    share()
  ) as Observable<Job>;

  // If commute matrix not generated and 500 ms passes, attempt to generate it!
  const attemptToGenerateServiceAddress = considerIfJobReadyToBePlacedOnDeck.pipe(
    filter(z => !z.serviceAddress.generatedCommuteMatrix),
    tap(x => console.warn("COM MAT")),
    tap(x => this.physicalAddressRoutingService.generateCommuteMatrixForAddressWithRetryCount$.next({address: x.serviceAddress, retryCount: 0})),
    tap(x => console.log(x,`Attempted Commute Matrix Generation`)),
    take(1)
    );


  // If commute matrix generated, and added to dexie and in memory put on deck!
  const placeOnDeck = considerIfJobReadyToBePlacedOnDeck.pipe(
    filter(z => z.serviceAddress.generatedCommuteMatrix),
    tap(x => console.warn(x,` string`)),
    tap(x => console.warn(x.serviceAddress.DocId())),
    delayWhen(job => this.dexieCacheService.monitoredAddresses$.pipe(
      filter(x => x.includes(job.serviceAddress.DocId())),
      tap(x => console.warn(x,` string`)),
    )),
    switchMap(job => this.physicalAddressRoutingService.retrieveOnDemandFromDixie(job.serviceAddress).pipe(
      map(() => job))),
    tap(() => {
      this.schedulerOne.schedulerEngine.unmask();
      this.loadingFromParams = false;
    }),
    tap(job => {
      console.warn("ACTIVATED ROUTING LOAD OF JOB HERE.");
      this.onDeckJob$.next({job: job, hoursWorkingOnScheduling: job.durationExpectedHours - job.siteVisitScheduledHours,
                            prospectiveSiteVisitDurationHours: job.durationExpectedHours - job.siteVisitScheduledHours > 8 ? 8 :
                            job.durationExpectedHours - job.siteVisitScheduledHours});
      }),
      tap(() => placedOnDeck.next(true)),
      take(1)
      );

    merge(attemptToGenerateServiceAddress, placeOnDeck).pipe(
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    this.assignSiteVisitToEmployee$.pipe(
      throttleTime(300),
     tap(x => this.schedulerService.bryntumAddSiteVisit$.next(
         {jobDocId: x.job.jobDocId, employeeDocId: x.employeeDocId, bryntumStartTime: x.startTime,
          bryntumEndTime: x.endTime, actualDate: x.dayOfYear, siteVisitDocId: x.siteVisitDocId,
          minimizeSiteVisitMutationsOnReconciliation: x.minimizeSiteVisitMutationsOnReconciliation,
          explicitlyErrored: x.explicitlyErrored})),
          takeUntil(this.destroyingComponent$))
     .subscribe();

     this.schedulerService.jobsNeedingAssignment$.pipe(
       map(x => x.filter(y =>  !y.serviceAddress.generatedCommuteMatrix)),
       tap(x => {
         x.forEach(y => this.physicalAddressRoutingService.addressNeedsPopulatedToSchedule$.next(y.serviceAddress))
       }),
       take(1)
     ).subscribe();

     combineLatest([this.schedulerService.jobsNeedingAssignment$.pipe(distinctUntilChanged((prev,current) =>
       {
         return isEqual(prev.map(z=>z.jobAttentionRequired),current.map(z=>z.jobAttentionRequired)) &&
         isEqual(prev.map(job => job.customer.customerName),current.map(job => job.customer.customerName)) &&
         isEqual(prev.map(job => job.jobType.name),current.map(job => job.jobType.name)) &&
         isEqual(prev.map(job => job.remainingToScheduleHours),current.map(job => job.remainingToScheduleHours)) &&
         isEqual(prev.map(job => job.serviceAddress.generatedCommuteMatrix),current.map(job => job.serviceAddress.generatedCommuteMatrix)) &&
         isEqual(prev.map(job => job.lineItems.map(l => l.DocId())),current.map(job => job.lineItems.map(l => l.DocId())) &&
         isEqual(prev.map(job => job.jobType.name),current.map(job => job.jobType.name)) &&
         isEqual(prev.map(job => job.jobAttentionRequired.filter(x=>x.active)),current.map(job => job.jobAttentionRequired.filter(x=>x.active)))

         );
       }
       ),
       ),
         this.onDeckJob$.pipe(distinctUntilChanged(isEqual))]).pipe(
         map( ([updated, onDeck]) => onDeck !== null ? updated.filter(x => x.jobDocId !== onDeck.job.jobDocId) :
         updated),
         takeUntil(this.destroyingComponent$)).subscribe(this.jobsNeedingAssignment$);

  this.toggleSideNav$.pipe(
    tap(() => this.sideNavOpen = !this.sideNavOpen),
    tap(() => {
      if (this.sideNavOpen) {
        if (this.sidebarWidth < 75) {
          this.sidebarWidth = 75;
          this.localSettingsService.saveToLocalStorage("SchedulerView","sidebarWidth",this.sidebarWidth);
        }
      }
    }),
    tap(() => this.localSettingsService.saveToLocalStorage("SchedulerView", "sideNavOpen", this.sideNavOpen)),
    takeUntil(this.destroyingComponent$)
  ).subscribe();

  this.bryntumSettingsService.settingsReadFromLocalStorage$.pipe(
    filter(x => x),
    delay(1),
    tap(() => {
      this.schedulerConfig = initilizeSchedulerConfig();
      this.loadedConfig$.next(true);
    }),
    take(1),
  ).subscribe();


  if (this.schedulerOne!==undefined) {
    this.initilizeScheduler();
  }

    this.schedulerOneQuery.changes.pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(
        (next: QueryList<SchedulerComponent>) => {
            this.initilizeScheduler();
         }
    );


  this.maskAndUnmaskWhenUpdatingData();

  this.updatedViewWindow$.pipe(
    tap(x => this.localSettingsService.saveToLocalStorage("SchedulerView", "viewWindow", x)),
    takeUntil(this.destroyingComponent$)
  ).subscribe();

}

    /**
     * Sets the duration of animation.
     */
setAnimationDuration(value: number) {
      this.schedulerOne.schedulerEngine.transitionDuration = value;
      this.styleNode.innerHTML = `.b-grid-row,.b-sch-event-wrap { transition-duration: ${value / 1000}s !important; }`;
  }

removeGuidanceFromSchedule() {
  if (this.scheduleLoaded) {
  this.schedulerService.resourceAvailibility = undefined;
  const updatedData = this.schedulerService.retrieveCopyOfBryntumDataFromSchedulingService();
  this.processUpdates(updatedData);
  }
}

buildBryntumViewResourceAvailibilityWhenNotScheduling(resources: BryntumResourceDateSummation[]) : ResourceAvailibility[] {
  const retVal : ResourceAvailibility[] = [];
  resources.forEach(r => {
    const nonWorking  = this.resourceAvailibilityService.addTimeWhenEmployeesNotWorking(r.actualResourceId, r.resourceDate, this.businessStartTimeBryntumDisplays(r),
    this.businessEndTimeBryntumDisplays(r), r.id);
    retVal.push(...nonWorking);
    retVal.push(...this.resourceAvailibilityService.addTimesWhenEmployeesWorkingWhileNotScheduling(nonWorking))
  });
  this.convertDatesToBryntumDate(retVal);
  return retVal;
}

convertDatesToBryntumDate(r: ResourceAvailibility[]) : void {
  r.forEach(a => {
    a.startDate = this.resourceAvailibilityService.addTimeToDate(BryntumResourceDateSummation.verticalDate, a.startDate);
    a.endDate = this.resourceAvailibilityService.addTimeToDate(BryntumResourceDateSummation.verticalDate, a.endDate);
  });
}

buildBryntumViewOfResourceAvailibilityGuidance(resourceAvailibility: ResourceAvailibility[], resources: BryntumResourceDateSummation[])  : ResourceAvailibility[] {
  const groupedAvailibilty: {}[] = [];

  resourceAvailibility.filter(x => x!==undefined).forEach( x => {
      const resourceDayKey = x.employeeDocId.concat(x.actualDate.toString());
      if (groupedAvailibilty[resourceDayKey] === undefined) {
        groupedAvailibilty[resourceDayKey] = [];
      }
      groupedAvailibilty[resourceDayKey].push(x);
    });
  const filledOutResourceAvailibility: ResourceAvailibility[] = [];
  resources.forEach( resourceDay => {
      const resourceDayKey = resourceDay.actualResourceId.concat(resourceDay.resourceDate.toString());
      // if (groupedAvailibilty[resourceDayKey] !== undefined) {
      const openTimes = groupedAvailibilty[resourceDayKey] !== undefined ? groupedAvailibilty[resourceDayKey] : [];
      filledOutResourceAvailibility.push(...this.resourceAvailibilityService.fillOutDaysAvailibility(resourceDay.actualResourceId,
          resourceDay.resourceDate,this.businessStartTimeBryntumDisplays(resourceDay),this.businessEndTimeBryntumDisplays(resourceDay),
          openTimes, resourceDay.id));
      // }
      });

  filledOutResourceAvailibility.forEach(x => {
    x.startDate = this.schedulerService.AddTimePortionFromActualToVerticalDate(x.startDate);
    x.endDate = this.schedulerService.AddTimePortionFromActualToVerticalDate(x.endDate);
  });
  return filledOutResourceAvailibility;
}

  private businessEndTimeBryntumDisplays(resourceDay: BryntumResourceDateSummation): Date {
    return addMinutes(resourceDay.resourceDate, getHours(this.settingsService.getValue("businessEndHour")) * 60 +
      getMinutes(this.settingsService.getValue("businessEndHour")) +
      this.settingsService.getValue("hoursAfterBusinessEndTimeToDisplay") * 60);
  }

  private businessStartTimeBryntumDisplays(resourceDay: BryntumResourceDateSummation): Date {
    return addMinutes(resourceDay.resourceDate, getHours(this.settingsService.getValue("businessStartHour")) * 60 +
      getMinutes(this.settingsService.getValue("businessStartHour")) -
      this.settingsService.getValue("hoursBeforeBusinessStartTimeToDisplay") * 60);
  }

modifyScheduleToIncludeProspectives(prospectives:
  {resourceAvailibility: { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility[]}[],
   schedulingGuidance: SchedulingGuidance[]}) {
  const updatedData = this.schedulerService.retrieveCopyOfBryntumDataFromSchedulingService();

  this.schedulerService.prospectiveSiteVisits = [];
  this.schedulerService.prospectiveAssignments = [];

  let firstSiteVisit: SiteVisit;
  if (prospectives !== null) {
    prospectives.schedulingGuidance.forEach(prospect => {
      if (prospect.siteVisits !== undefined) {
      const newSiteVisit = prospect.siteVisits.find(x => x.docId === prospect.assignment.siteVisitDocId);
      if (firstSiteVisit === undefined) {
        firstSiteVisit = new SiteVisit(newSiteVisit);
      }
      this.schedulerService.prospectiveSiteVisits.push(newSiteVisit);
      prospect.assignment.siteVisitDocId = newSiteVisit.docId;
      prospect.assignment.siteVisitAddressDocId = newSiteVisit.siteVisitAddress.DocId();
      this.schedulerService.prospectiveAssignments.push(prospect.assignment);
      const newEvent =  this.schedulerService.generateVerticalBryntumEventFromJobSiteVisit(this.onDeckJobRespectMinVisitLength$.getValue().job,
      newSiteVisit);
      updatedData.events.push(newEvent);
      const bryntumResourceId = this.schedulerService.resourcesVertical.find(x => x.actualResourceId ===
        prospect.assignment.employeeDocId && x.resourceDate.getDate() === newEvent.actualStartDate.getDate()).id;
      const anAssignment = new BryntumAssignment({resourceId: bryntumResourceId, eventId: newEvent.id});
      updatedData.assignments.push(anAssignment);
      prospect.siteVisits.forEach(siteVisit => {
        if (!siteVisit.prospectiveSiteVisit) {
        const existingEventIndex = updatedData.events.findIndex(x => x.siteVisitDocId === siteVisit.docId);
        updatedData.events.splice(existingEventIndex, 1,
          ...[this.schedulerService.generateVerticalBryntumEventFromJobSiteVisit(this.schedulerService.jobs.find(j => j.DocId() === updatedData.events[existingEventIndex].jobDocId), siteVisit)]);
        } else {
          this.siteVisitService.cacheSiteVisitLocally(siteVisit);
        }
      });
    }
    });
  }
  updatedData.resources = this.schedulerService.regenerateSubsetOfBryntumResourceDateSummationsFromPassedIn(
    this.schedulerService.resourcesVertical.map(x => x.id),
    updatedData.assignments, updatedData.events, updatedData.resources);
  updatedData.resources.forEach(x => x.siteVisitBooking = firstSiteVisit);

  updatedData.availibilityInfo = this.buildBryntumViewOfResourceAvailibilityGuidance(
      prospectives !== null ? prospectives.resourceAvailibility.filter(x => x.CalcuationModel === RESOURCE_AVAILIBILITY_CALCULATION_METHOD.DEFAULT).flatMap(q => q.ResourceAvailibility) : [],
      updatedData.resources);
      if (prospectives !== null) {
        this.siteVisitNumberService.resourceAvailabilityMinimizingSiteVisitMutations =
        prospectives.resourceAvailibility.filter(x => x.CalcuationModel === RESOURCE_AVAILIBILITY_CALCULATION_METHOD.MINIMZE_SITE_VISIT_MUTATIONS).flatMap(q => q.ResourceAvailibility);
      }
  this.schedulerService.resourceAvailibility = updatedData.availibilityInfo;
  this.processUpdates(updatedData);
}

retrieveGuidanceForSiteVisit(siteVisit: SiteVisit, siteVisitsToConsider: SiteVisit[], employeesToConsider: Employee[]):
{resourceAvailibility: { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility[]}[], schedulingGuidance: SchedulingGuidance[]}
{


  const resourceDaysToConsider = this.updatedViewWindow$.value === VerticalSchedulerViewWindow.WorkDay ?
  this.schedulerService.resourcesVertical.filter(x => this.schedulerService.isWorkDay(x.resourceDate)) :
  this.schedulerService.resourcesVertical;
  const prospectiveDates = resourceDaysToConsider.
    filter(x => this.activeEmployeesToDisplay.map (x => x.docId).some(z => z === x.actualResourceId))
      .map(x => x.resourceDate).reduce((a: Date[], b: Date) => {
        if (!a.some(t => t.getTime() === b.getTime())) {
          a.push(b);
        }
        return a;
      }, []);
    // Ensure that commute matrix is generated for all site visit addresses which will be considrered for scheduling

    return this.schedulingGuidanceService.retrieveProspectiveSiteVisitAssignments(siteVisit,
      siteVisitsToConsider, this.schedulerService.assignments, prospectiveDates,
      employeesToConsider.map(x=> x.docId), this.draggingEventOnScheduler);
}

enterScheduling() {
  const siteVisitScheduling = this.onDeckJobRespectMinVisitLength$.value;

  const employeesToConsider = this.displayedActualResourceIds.length !== 0 ?
  this.activeEmployeesToDisplay.filter(x => this.displayedActualResourceIds.some(y => y === x.docId)) : this.activeEmployeesToDisplay;

  if (employeesToConsider.length === 0) {
    window.alert("There are no displayed resources which can meet the criteria for job.  Please display additonal employees, or add additional skills to a resource assigned to the same office as the job.");
  }

  const prospectives = this.retrieveGuidanceForSiteVisit(
    new SiteVisit({_durationHours: siteVisitScheduling.prospectiveSiteVisitDurationHours,
                   jobDocId: siteVisitScheduling.job.jobDocId, siteVisitAddress:siteVisitScheduling.job.serviceAddress }), this.schedulerService.siteVisits, employeesToConsider);
    this.modifyScheduleToIncludeProspectives(prospectives);
}

private addDaysToWorkDate(start: Date, daysToAdd: number): Date {
      let retVal = start;
      let Added = 0;
      retVal = this.retrieveViewStartDateIfOnDay(start);
      while (Added < Math.abs(daysToAdd)) {
        if (daysToAdd > 0) {
          retVal = addDays(retVal,1)
        } else {
          retVal = subDays(retVal,1)
        }
        if (this.verticalSchedulingService.isWorkDay(retVal)) {
          Added++;
        }
      }
      return retVal;
}

private retrieveViewEndDate(): Date {
  return this.retrieveViewEndDateIfOnDay(this.currentDate$.value);
}

private retrieveViewStartDate(): Date {
  return this.retrieveViewStartDateIfOnDay(this.currentDate$.value);
}

private retrieveViewEndDateIfOnDay(currentDate: Date) {
  let retVal: Date;
  switch (this.updatedViewWindow$.value) {
    case VerticalSchedulerViewWindow.WorkDay: {
      retVal = this.retrieveViewStartDateIfOnDay(currentDate);
      retVal = this.addDaysToWorkDate(retVal, this.numberWorkDaysToDisplay);
      break;
    }
    case VerticalSchedulerViewWindow.Week: {
      retVal = addDays(endOfWeek(currentDate),1);
      break;
    }
    case VerticalSchedulerViewWindow.Day: {
      retVal = addDays(new Date(currentDate),1);
      break;
    }
  }
  return startOfDay(retVal);
}

private retrieveViewStartDateIfOnDay(currentDate: Date) {
  let retVal: Date;
  switch (this.updatedViewWindow$.value) {
    case VerticalSchedulerViewWindow.WorkDay: {
      retVal = new Date(currentDate);
        while (!this.verticalSchedulingService.isWorkDay(retVal)) {
          retVal = addDays(retVal, 1);
        };
        return retVal;
      }
    case VerticalSchedulerViewWindow.Week: {
      retVal = startOfWeek(currentDate);
      break;
    }
    case VerticalSchedulerViewWindow.Day: {
      retVal = new Date(currentDate);
      break;
    }
  }
  return retVal;
}

modifyScheduleStylingToRemoveGuidance() {
  if (this.schedulerOne) {
      this.schedulerOne.schedulerEngine.resourceMargin = 2;
    if (this.schedulerOne.schedulerEngine.element === undefined) {
      console.error('this.schedulerOne.schedulerEngine.element is undefined');
      console.log(this.schedulerOne.schedulerEngine);
    } else {
      this.schedulerOne.schedulerEngine.element.classList['remove']('b-guided-scheduling');
    }
  }
}

modifyScheduleStylingToAddGuidance()
{
  if (this.schedulerOne) {
    this.schedulerOne.schedulerEngine.resourceMargin = 20;
  if (this.schedulerOne.schedulerEngine.element === undefined) {
    console.error('this.schedulerOne.schedulerEngine.element is undefined');
    console.log(this.schedulerOne.schedulerEngine);
  } else {
    this.schedulerOne.schedulerEngine.element.classList['add']('b-guided-scheduling');
  }
  }
}

modifyEventsBasedOnFilter(filter) {

  console.log('modifyEventsBasedOnFilter');
  const lowerCaseFilter = filter === '' ? '' : filter.toLowerCase();
  // event name match for POC
  this.schedulerOne.schedulerEngine.eventStore.forEach(task => {
    try {
    if (!task.data.readOnly && !task._prospectiveEvent) {
    const taskClassList = new LiteDomClassList(task.cls.value);

    if ((lowerCaseFilter !== '' && task.name.toLowerCase().includes(lowerCaseFilter)) ||
    (this.jobService.get(task.jobDocId) && this.jobService.get(task.jobDocId).jobTags.some(a => a.name.toLowerCase().includes(lowerCaseFilter))) ||
    task.addressString.toLowerCase().includes(lowerCaseFilter)) {
        taskClassList.add('b-match');
    } else {
        taskClassList.remove('b-match');
    }
    if (taskClassList?.value) {
      task.cls = taskClassList.value;
    }
  }
  } catch (e) {
    if (!e.message.includes('Cannot convert undefined')) {
      throw e;
    }
  }
}, this);
  this.schedulerOne.schedulerEngine.element.classList[filter.length > 0 ? 'add' : 'remove']('b-highlighting');
}

removeAssignment(resourceId, eventId) {
  const assignmentVisualization = this.schedulerOne.schedulerEngine.assignmentStore.
        getAssignmentForEventAndResource(eventId, resourceId);
  if (assignmentVisualization !== undefined) {
        this.schedulerOne.schedulerEngine.assignmentStore.remove(assignmentVisualization);
      }
  if (this.assignments.findIndex( y => y.resourceId === resourceId && y.eventId === eventId) > -1) {
      this.assignments.splice(this.assignments.findIndex( y => y.resourceId === resourceId
        && y.eventId === eventId), 1);
    }
}

addAssignment(toAdd: BryntumAssignment) {
  this.assignments.push(toAdd);
  const assignmentVisualization = this.schedulerOne.schedulerEngine.assignmentStore.
        getAssignmentForEventAndResource(toAdd.eventId, toAdd.resourceId);
  if (assignmentVisualization === undefined) {
          this.schedulerOne.schedulerEngine.assignmentStore.add(toAdd);
        }
}

processUpdates(updatedData: ByrntumData) {
  let updatedResourceStore: boolean = false;
  const visibility = updatedData.resources.find(x => startOfDay(x.resourceDate).getTime() === startOfDay(new Date()).getTime()) ? 'visible' : 'hidden';
  this.parentContainerRef.nativeElement.style.setProperty('--visibility',visibility);

  this.numberMinutesAllowedForCommute = this.schedulerService.getCommuteDeltaMaxAssociatedWithSliderPercent(this.resouceCommutePercentage);
  this.schedulerService.commuteSliderUpdate = true;
  let eventUpdatesPresent: boolean = false;
  const activeScheduling = updatedData.availibilityInfo !== undefined;
  console.log(updatedData);
  console.error("START PROCESS UPDATES!")
  console.log(updatedData.events.filter(q => q.prospectiveEvent));
  let resourcesToDisplayConsideringBusinessDays: BryntumResourceDateSummation[] = updatedData.resources;
  if (this.updatedViewWindow$.value === VerticalSchedulerViewWindow.WorkDay) {
      resourcesToDisplayConsideringBusinessDays = resourcesToDisplayConsideringBusinessDays.filter(x => this.schedulerService.isWorkDay(x.resourceDate));
    }

  this.schedulerOne.schedulerEngine.assignmentStore.beginBatch();
  this.schedulerOne.schedulerEngine.resourceTimeRangeStore.beginBatch();

  if (this.schedulerOne.schedulerEngine.resourceTimeRangeStore.count > 2) {
      this.schedulerOne.schedulerEngine.resourceTimeRangeStore.removeAll();
      this.resourceAvailibility = [];
    }

  const eventsToAdd = updatedData.events.filter(x => ! this.events.some(y => y.id === x.id));
  const eventsToRemove = this.events.filter(x => !updatedData.events.some(y => y.id === x.id));
  const resourcesToAdd = resourcesToDisplayConsideringBusinessDays.filter(x => ! this.resources.some(y => y.id === x.id));
  const resourcesToRemove = this.resources.filter(x => ! resourcesToDisplayConsideringBusinessDays.some(y => y.id === x.id));

  if (eventsToAdd.length > 0 || eventsToRemove.length > 0) {
    eventUpdatesPresent = true;
    this.schedulerOne.schedulerEngine.eventStore.beginBatch();
  }

  if (!activeScheduling) {
    this.modifyScheduleStylingToRemoveGuidance();
    updatedData.resources.forEach(x => x.siteVisitBooking = undefined);
  }

  const assignmentsToAdd = updatedData.assignments.filter(x => ! this.assignments
      .some(y => y.resourceId === x.resourceId && y.eventId === x.eventId));
  const assignmentsToRemove = this.assignments.filter(x => !updatedData.assignments
      .some(y => y.resourceId === x.resourceId && y.eventId === x.eventId));
  updatedData.events.filter(x => !eventsToAdd.some(y => y.id === x.id) && !eventsToRemove.some(y => y.id === x.id)).forEach( x => {

        const associatedDataEvent = this.events.find(y => y.id === x.id);
        if (associatedDataEvent !== undefined) {
          const associatedEvent = this.schedulerOne.schedulerEngine.eventStore.getById(x.id);
          if (! BryntumEvent.equalForBryntumDisplay(associatedEvent, x)) {
            // tslint:disable-next-line: forin
            if (!eventUpdatesPresent) {
              eventUpdatesPresent = true;
              this.schedulerOne.schedulerEngine.eventStore.beginBatch();
            }
          for (const key in x) {
            associatedDataEvent[key] = x[key];
            associatedEvent.set(key, x[key]);
          }
          associatedEvent["cls"].value = x["cls"];
        }
      }

    });
  eventsToRemove.forEach(x => {
        this.events.splice(this.events.findIndex(y => y.id === x.id), 1);
        const associcatedEventFromStore = this.schedulerOne.schedulerEngine.eventStore.getById(x.id);
        if (associcatedEventFromStore !== undefined) {
          this.schedulerOne.schedulerEngine.eventStore.remove(associcatedEventFromStore);
        }
      });
      if (eventsToAdd.length > 0) {
        this.events.push(...eventsToAdd);
        this.schedulerOne.schedulerEngine.eventStore.add(eventsToAdd);
      }

  updatedData.resources.forEach( x => {
        const associatedResourceData = this.resources.find(y => y.id === x.id);
        if (associatedResourceData !== undefined && !BryntumResourceDateSummation.equalForBryntumDisplay(x, associatedResourceData, true)) {
            if (updatedResourceStore === false) {
              this.schedulerOne.schedulerEngine.resourceStore.beginBatch();
              updatedResourceStore = true;
            }
            const associatedResource = this.schedulerOne.schedulerEngine.resourceStore.getById(x.id);
            // tslint:disable-next-line: forin
            for (const key in x) {
              associatedResourceData[key] = x[key];
              associatedResource.set(key, x[key]);
            }
        }
        });
  if (!this.scheduleLoaded  || (resourcesToAdd.length > 0 || resourcesToRemove.length > 0 && !updatedResourceStore)) {
    updatedResourceStore = true;
    this.schedulerOne.schedulerEngine.resourceStore.beginBatch();
  }
  if (resourcesToAdd.length > 0) {
    this.resources.push(...resourcesToAdd);
    this.schedulerOne.schedulerEngine.resourceStore.add(resourcesToAdd);
  }

  resourcesToRemove.forEach(x => {
      this.resources.splice(this.resources.findIndex(y => y.id === x.id), 1);
      this.schedulerOne.schedulerEngine.resourceStore.getById(x.id).remove();
    });
  assignmentsToRemove.forEach(x => this.removeAssignment(x.resourceId, x.eventId));
  assignmentsToAdd.forEach(adder => {
    if (this.resources.some(q => q.id === adder.resourceId)) {
      this.addAssignment(adder);
    }
  });

  if (activeScheduling) {
    this.modifyScheduleStylingToAddGuidance();
    const updatedAvailibilityInfo = this.updateResourcesDisplayingAsGreen();
    if (updatedAvailibilityInfo.length > 0) {
      updatedData.availibilityInfo = updatedAvailibilityInfo;
    }
  } else {
    updatedData.availibilityInfo = this.buildBryntumViewResourceAvailibilityWhenNotScheduling(updatedData.resources);
  }

  // updates specific to guided scheduling
  if (updatedData.availibilityInfo !== undefined) {
    this.schedulerOne.schedulerEngine.resourceTimeRangeStore.removeAll();
    this.resourceAvailibility = [];
    // this.schedulerService.resourceAvailibility = updatedData.availibilityInfo;
    updatedData.availibilityInfo.forEach(x => {
        // Move time range store modification out of here, since it is also triggered
        // from non-update events (i.e. intiating drag on an existing event)
        this.schedulerOne.schedulerEngine.resourceTimeRangeStore.add(x);
        this.resourceAvailibility.push(x);
      });
    if (this.toggleEventMutability) {
    updatedData.events.filter(x => !x._prospectiveEvent).forEach(x => {
        x.resizable = false;
        x.draggable = false;
      });
    } else {
    updatedData.events.forEach(x => {
        x.resizable = true;
        x.draggable = true;
      });
    }
    // this.toggleEventMutability = false;
  }


  if (updatedResourceStore) {
    this.schedulerOne.schedulerEngine.resourceStore.sort( (a,b) =>  {
      return  (a.resourceDate < b.resourceDate ? -1 : a.resourceDate > b.resourceDate ? 1 :
        a.employeeCreationDate.getTime() > b.employeeCreationDate.getTime() ? 1 : a.employeeCreationDate.getTime() < b.employeeCreationDate.getTime() ? -1 :
        a.actualResourceId < b.actualResourceId ? -1 : a.actualResourceId > b.actualResourceId ? 1 : 0);
      });
      //This seems likely to be Bryntum bug, but if we don't end the batch here
      // and also immediately below, then we don't properly update displayed resources.
      this.schedulerOne.schedulerEngine.resourceStore.endBatch();
  }


  this.schedulerOne.schedulerEngine.resourceTimeRangeStore.endBatch();
  if (eventUpdatesPresent) {
    this.schedulerOne.schedulerEngine.eventStore.endBatch();
  }
  this.schedulerOne.schedulerEngine.assignmentStore.endBatch();
  if (updatedResourceStore) {
    this.schedulerOne.schedulerEngine.resourceStore.endBatch();
  }

  if (!this.scheduleLoaded || this.updatedViewWindow || this.schedulerOne.schedulerEngine.resourceStore.filters.count === 0 || updatedResourceStore) {
    this.schedulerOne.schedulerEngine.resourceStore.removeFilter("default");
    this.schedulerOne.schedulerEngine.resourceStore.filter({id : "default", filterBy: () => true});
  }
  this.updatedViewWindow = false;
  this.scheduleLoaded = true;
  console.log("FINISH PROCESS UPDATES!");
}

retrieveDateModificationsFromUnitsMoved(numberUnitsMoved: number, selectedUnitsOfMovement: string | null = null): number {
  if (selectedUnitsOfMovement === null) {
    selectedUnitsOfMovement = this.updatedViewWindow$.value;
  }
  switch (selectedUnitsOfMovement) {
    case VerticalSchedulerViewWindow.WorkDay: {
      return numberUnitsMoved * this.numberWorkDaysToDisplay;
    }
    case VerticalSchedulerViewWindow.Week: {
      return numberUnitsMoved * 7;
    }
    case VerticalSchedulerViewWindow.Day: {
      return numberUnitsMoved;
    }
  }
  }

scheduleCommuteMatrixGenerationFromEventModalIfNeeded(bryntumEvent: BryntumEvent) {
  if (startOfDay(bryntumEvent.actualStartDate).getTime() < startOfDay(new Date()).getTime()) {
    this.addressService.load$(bryntumEvent.addressDocId).pipe(
      map(a => {
        a.generatedCommuteMatrix = false;
        return a;
      }),
      take(1),
      switchMap(a => this.addressService.update$(a)),
      tap(x => this.physicalAddressRoutingService.addressNeedsPopulatedToSchedule$.next(x)),
      take(1),
    ).subscribe();
  }
}


eventDialogClosure(closingArguments: any) {
  console.log(closingArguments);
  if (closingArguments.action) {
    switch (closingArguments.action) {
      case "modify": {
        this.router.navigate(['/job-page', {jobDocId: this.siteVisitService.get(closingArguments.siteVisitDocId).jobDocId}]);
        break;
      }
      case "reschedule": {
        const remover = this.events.splice(this.events.findIndex(x => x.siteVisitDocId === closingArguments.siteVisitDocId), 1)[0];
        this.schedulerService.bryntumRescheduleSiteVisit$.next(remover);
        // if site visit is on of after current date, it has no gap in commute matrix generation.
        this.scheduleCommuteMatrixGenerationFromEventModalIfNeeded(remover);
        break;
      }

      case "cancel_job" :
      case "delete": {
        const indexJobToRemove = this.events.findIndex(x => x.siteVisitDocId === closingArguments.siteVisitDocId);
        if (indexJobToRemove > -1) {
          const deleter = this.events.splice(indexJobToRemove, 1)[0];
          this.schedulerService.bryntumDeleteSiteVisit$.next(deleter);
        } else {
          let wb: string = null;
          FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
            tap(b => wb = b),
            switchMap(() => this.jobService.update$(this.jobService.get(closingArguments.jobDocId), wb)),
            switchMap(() => this.schedulerService.removeAssignment(closingArguments.siteVisitDocId, wb)),
            switchMap(() => this.customerCommunicationManagementService.cancelCustomerCommunicationFromSiteVisit(closingArguments.siteVisitDocId, "canceled from site visit modal", wb)),
            switchMap(() => this.jobService.commitExternallyManagedFirestoreBatch(wb)),
            take(1)
          ).subscribe();
        }
        break;
      }

      case "addSiteVisit": {
        const remover = this.events.find(x => x.siteVisitDocId === closingArguments.siteVisitDocId);
        this.scheduleCommuteMatrixGenerationFromEventModalIfNeeded(remover);
        let jobToAdd = this.jobService.get(this.events.find(x => x.siteVisitDocId === closingArguments.siteVisitDocId).jobDocId);
        jobToAdd = this.schedulerService.addTimeDiffToJob(jobToAdd, closingArguments.numberHours,
          JobDurationDeltaModificationType.ADDITIONALSITEVISITPUTONDECK, null);
        jobToAdd.needsAssigned = true;
        this.physicalAddressRoutingService.retrieveOnDemandFromDixie(jobToAdd.serviceAddress).pipe(
           switchMap(() => this.jobService.update$(jobToAdd)),
           switchMap(q => this.jobService.load$(q.DocId())),
            take(1)
          ).subscribe(jobToAdd => {
            this.onDeckJob$.next({job: jobToAdd, hoursWorkingOnScheduling: closingArguments.numberHours,
              prospectiveSiteVisitDurationHours: closingArguments.numberHours > 8 ? 8 : closingArguments.numberHours });
          });
        break;
      }
      case "siteVisitFromUnassigned": {
        const jobToAdd = this.jobService.get(this.events.find(x => x.siteVisitDocId === closingArguments.siteVisitDocId).jobDocId);
        this.physicalAddressRoutingService.retrieveOnDemandFromDixie(jobToAdd.serviceAddress).pipe(
          take(1)
        ).subscribe(() => this.onDeckJob$.next({job: jobToAdd,  hoursWorkingOnScheduling: closingArguments.numberHours,
                              prospectiveSiteVisitDurationHours: closingArguments.numberHours > 8 ? 8 : closingArguments.numberHours}));
        break;
      }
      default: {
        console.log(closingArguments);
        break;
      }
    }
  }
    if (closingArguments.refreschScheduleOnExit) {
      this.updatedViewWindow$.next(this.updatedViewWindow$.value);
    }
    if (closingArguments.updatedArrivalWindow) {
      this.schedulerService.siteVisitArrivalWindowUpdated$.next({job:closingArguments.activeJob, siteVisitDocId: closingArguments.siteVisit.DocId(),
        orignalWindow: { startTime: closingArguments.originalArrivalWindowStart, endTime: closingArguments.originalArrivalWindowEnd},
        newWindow: { startTime: closingArguments.siteVisit.arrivalWindowStartDate, endTime: closingArguments.siteVisit.arrivalWindowEndDate}});
    }
}


maskAndUnmaskWhenUpdatingData(): void {
  const savingMaskDelay$ = this.schedulerService.processingChangeToSchedule$.pipe(
    filter(x => x === true),
    tap(() => this.schedulerOne.schedulerEngine.mask("Saving")),
    delay(250));

  const dataSaved$ = this.schedulerService.processingChangeToSchedule$.pipe(
    filter(x => x === false),
      skip(1));

  zip(savingMaskDelay$, dataSaved$).pipe(
    takeUntil(this.destroyingComponent$)
  ).subscribe(() => this.schedulerOne.schedulerEngine.unmask());
}

stateChangePropagatingFromUpdatesToOnDeckJob(): void {

  const jobAddedToScheduleOnDeck = this.onDeckJob$.pipe(
    filter(x => x !== null),
    tap(() => this.toggleEventMutability = true),
    takeUntil(this.destroyingComponent$),
  ).subscribe();

  this.onDeckJobRespectMinVisitLength$.pipe(
    filter(x => x !== null),
    delay(1),
    debounce(() => this.generatedDataOnce$),
    debounce(() => this.verticalSchedulingService.processingChangeToSchedule$.pipe(filter(x => x === false))),
    tap(() => console.log("Adding Scheduling")),
    tap(() => this.schedulerOne.schedulerEngine.unmask()),
    takeUntil(this.destroyingComponent$)
  ).subscribe(() => {
    this.enterScheduling();
  });

  this.onDeckJob$.pipe(
    distinctUntilChanged(),
    filter(x => x === null),
    tap(() => this.toggleEventMutability = false),
    debounce(() => this.schedulerLoaded$),
    debounce(() => this.verticalSchedulingService.processingChangeToSchedule$.pipe(filter(x => x === false))),
    // filter(() => this.schedulerService.loadingData$.getValue() === false),
    takeUntil(this.destroyingComponent$),
    tap(() => console.log("Removin Guidance")),
    tap(() => this.onDeckJobRespectMinVisitLength$.next(null)),
    // when there is no job on deck, we are not filtering employees by presence of skills to complete a given job.
    tap(() => {
      if (this.allEmployeesWeScheduleFor.length > 0) {
        this.employeesFilteredBySkillSet$.next(this.allEmployeesWeScheduleFor);
      } else {
        this.employeesFilteredBySkillSet$.next(null);
      }
      })
    ).subscribe();

  this.completedSchedulingFromOnDeck$.pipe(tap(() => console.log("Completed Scheduling")),
  tap(() => this.schedulerOne.schedulerEngine.mask("Saving")),
  map(x => {
    const retVal = this.onDeckJob$.value.job;
    retVal.needsAssigned = false;
    return retVal;
  }),
  switchMap(x => this.jobService.update$(x)),
    takeUntil(this.destroyingComponent$)).subscribe( () => {
    this.schedulerOne.schedulerEngine.unmask();
    this.onDeckJob$.next(null);
    this.router.navigate(['/app-scheduler-view'])
  });

  this.schedulerLater$.pipe(
    tap(() => console.log("Schedule Later")),
    tap(() => this.schedulerService.updateResourceVertical),
    takeUntil(this.destroyingComponent$)
    ).subscribe(() => {
    this.onDeckJob$.next(null);
    this.router.navigate(['/app-scheduler-view']);
    this.removeGuidanceFromSchedule();
  });

}

private filterDisplayedDaysBasedOnViewSelectionUpdates() {
  this.changeViewWindowStart$.pipe(
    tap(days => {
      if (this.updatedViewWindow$.value === VerticalSchedulerViewWindow.WorkDay) {
        this.currentDate$.next(this.addDaysToWorkDate(this.currentDate$.value, this.retrieveDateModificationsFromUnitsMoved(days)));
      } else {
      this.currentDate$.next(addDays(this.currentDate$.value, this.retrieveDateModificationsFromUnitsMoved(days)));
      }
    }),
    takeUntil(this.destroyingComponent$)
    ).subscribe();

  this.setViewWindowToToday$.pipe(
    tap(() => {
      this.currentDate$.next(startOfDay(new Date()));
    }
  ),
  takeUntil(this.destroyingComponent$)).subscribe();

  this.viewWindowUpdatedViaCalendar$.pipe(
    tap(d => {
      this.currentDate$.next(d);
    }
  ),
  takeUntil(this.destroyingComponent$)).subscribe();

  this.updateNumberWorkdaysToDisplay$.pipe(
    takeUntil(this.destroyingComponent$)
    ).subscribe(x => {
    this.numberWorkDaysToDisplay = x;
    this.localSettingsService.saveToLocalStorage("SchedulerView", "numberWorkDaysToDisplay", x);
    if (this.updatedViewWindow$.value === VerticalSchedulerViewWindow.WorkDay) {
      this.updatedViewWindow$.next(VerticalSchedulerViewWindow.WorkDay);
    }
  });
}

testIt() {
  console.log(this.schedulerOne);
}

private filterDisplayedRecordsBasedOnEmployeeTree() {

  const removeEmployeesFromTreeDisplay$ = this.employeesRemovedFromTreeDisplay$.pipe(
    map(x => x.filter(q => q!==undefined)),
    tap(removals => {
      this.employeesFilteredByRole$.next(this.employeesFilteredByRole$.value.filter(x => !removals.some(z => z.docId === x.docId)));
    }));

  const addEmployeesFromTreeDisplay$ = this.employeesAddedToTreeDisplay$.pipe(
    map(x => x.filter(q => q!==undefined)),
    map(x => x.filter(q => !this.employeesFilteredByRole$.value.some(z => z.docId === q.docId))),
    filter(x => x.length > 0),
    tap(additions => this.employeesFilteredByRole$.next(this.employeesFilteredByRole$.value.concat(additions))));

  // Employee tree updates
  merge(removeEmployeesFromTreeDisplay$, addEmployeesFromTreeDisplay$).pipe(
    debounce(() => combineLatest([this.settingsService.settingsLoaded$, this.schedulerLoaded$])),
    delay(1),
    takeUntil(this.destroyingComponent$)).subscribe();
}

updateResourcesDisplayingAsGreen() : ResourceAvailibility[] {
  if (this.numberMinutesAllowedForCommute !== 1440)
  {
    let resourceAvail = this.schedulerService.retrieveAvailibilityInfoFromSchedulingService().filter(x => x.available);
    resourceAvail = this.buildBryntumViewOfResourceAvailibilityGuidance(resourceAvail,
      this.resources);
      resourceAvail.forEach(avail => {
        if (avail.commuteMinutesDelta === undefined) {
          avail.timeRangeColor = "red";
        } else {
          avail.commuteMinutesDelta > this.numberMinutesAllowedForCommute ?
        avail.timeRangeColor = "yellow" : avail.timeRangeColor = "green";
        }
      });
      return resourceAvail;
  } else {
    return this.schedulerService.retrieveAvailibilityInfoFromSchedulingService();
  }
}


private retrieveEndDateForSuceedingWindow() : Date {
  const workDaySelectedPostceedingDate = this.addDaysToWorkDate(this.currentDate$.value, this.retrieveDateModificationsFromUnitsMoved(1, VerticalSchedulerViewWindow.WorkDay));
  const weekSelectedPostceedingDate = this.retrieveViewStartDateIfOnDay(addDays(this.currentDate$.value, this.retrieveDateModificationsFromUnitsMoved(1, VerticalSchedulerViewWindow.Week)));
  return workDaySelectedPostceedingDate.getTime() > weekSelectedPostceedingDate.getTime() ? workDaySelectedPostceedingDate : weekSelectedPostceedingDate;
}

private retrieveStartDateForPreceedingWindow() : Date {
  const workDaySelectedPreceedingDate = this.addDaysToWorkDate(this.currentDate$.value, this.retrieveDateModificationsFromUnitsMoved(-1,VerticalSchedulerViewWindow.WorkDay));
  const weekSelectedPreceedingDate = this.retrieveViewStartDateIfOnDay(addDays(this.currentDate$.value, this.retrieveDateModificationsFromUnitsMoved(-1, VerticalSchedulerViewWindow.Week)));
  return workDaySelectedPreceedingDate.getTime() < weekSelectedPreceedingDate.getTime() ? workDaySelectedPreceedingDate : weekSelectedPreceedingDate;
}

ngOnInit(): void {
  console.log("Enter ngOnInit");

  this.updatedViewWindow$.pipe(
    distinctUntilChanged((prev,curr) => prev === curr),
    tap(() => this.updatedViewWindow = true),
    takeUntil(this.destroyingComponent$)
  ).subscribe();

  this.updateNumberWorkdaysToDisplay$.pipe(
    tap(() => this.updatedViewWindow = true),
    takeUntil(this.destroyingComponent$)
  ).subscribe()

  this.schedulerService.schedulerViewActive$.next(true);
  const existingCommunications = this.schedulerService.siteVisitArrivalWindowUpdated$.pipe(
    tap(x => console.error(x,` string`)),
    // Retrieve communcations which are related to site visit.
      switchMap(x => this.customerCommunicationService.queryFirestoreShallow$([where("siteVisitDocId", "==", x.siteVisitDocId)]).pipe(
      map(y => {
        return {existingCommunciations: y, siteVisitUpdates: x};
      }),
      take(1),
    )),
    );

    // If we have already sent out initial appointment confirmation, present modal to user to determine if they want to resend.

    if (this.authenticationService && this.authenticationService._userId !== "OgJHFCUleFUTe9VJtJfFoyUXBJ52") {
      existingCommunications.pipe(
        filter(x=> x.existingCommunciations.findIndex(q => q.customerCommunicationCatagory === CustomerCommunicationCategory.APPOINTMENT &&
          CustomerCommunication.CommunicationHasBeenSentToCustomer(q.customerCommunicationStatus) && q.messageSequenceNumber === 1) !== -1),
          tap(x => {
            const editorConfig = new MatDialogConfig();
            Object.assign(editorConfig, {
              disableClose : true,
              width        : '500px',
              data: {
              job: x.siteVisitUpdates.job,
              siteVisitDocId: x.siteVisitUpdates.siteVisitDocId,
              previousArrivalWindowEnd: x.siteVisitUpdates.orignalWindow.endTime,
              currentArrivalWindowEnd: x.siteVisitUpdates.newWindow.endTime,
              previousArrivalWindowStart: x.siteVisitUpdates.orignalWindow.startTime,
              currentArrivalWindowStart: x.siteVisitUpdates.newWindow.startTime,
              }});
            this.resendCustomerCommunicationDialogRef = this.dialog.open(ResendCustomerCommunicationModalComponent,editorConfig);
              this.resendCustomerCommunicationDialogRef.afterClosed().pipe(
                filter(x => x !== undefined),
                switchMap(x => this.customerCommunicationCreationService.sendCustomerCommunicationReflectingUpdatedArrivalTime(
                  this.siteVisitService.get(x.siteVisitDocId), this.jobService.get(x.jobDocId),  "Scheduler changed arrival window", null)),
                  take(1)
              ).subscribe();
          }),
          takeUntil(this.destroyingComponent$)
      ).subscribe();
    }

  this.employeeService.loadAll$().pipe(
    filter(x => x !== null),
    debounce(() => this.schedulerLoaded$),
    tap(x => {
      const explicitNoDisplay = this.localSettingsService.loadFromLocalStorage("SchedulerViewHeader", "employeeIdsNotDisplaying", []);
      this.employeesFilteredByRole$.next(x.filter(z => z.active && z.scheduleFieldCallsFor && !explicitNoDisplay.includes(z.docId)));
  }),
  takeUntil(this.destroyingComponent$)
  ).subscribe();

  let firstBlush = true;
  this.generateDataForActiveView$ = combineLatest([this.updatedViewWindow$.pipe(tap(() => firstBlush = true)), this.currentDate$, this.settingsService.settingsLoaded$]).pipe(
    map(() => ({startDate: this.retrieveViewStartDate(), endDate: this.retrieveViewEndDate(), employeeDocIds:null})),
    tap(x => {
      this.currentStartDate = x.startDate;
      this.currentEndDate = x.endDate;
    }),
    distinctUntilChanged((prev,curr) => {
      return prev.startDate.getTime() === curr.startDate.getTime() && prev.endDate.getTime() === curr.endDate.getTime();
    }),
    debounce(() => this.schedulerLoaded$),
    tap(() => this.schedulerOne !== undefined ? this.schedulerOne.schedulerEngine.mask("Loading") : null),
    map( x=> {
      const retVal =  {val: x, guid: Math.random().toString(36).substring(0,14)};
      this.verticalSchedulingService.activeVerticalSchedulerGuid$.next(retVal.guid);
      return retVal;
    }),
    switchMap(x => this.schedulerService.generateBryntumVerticalData(x.val.startDate, x.val.endDate, x.val.employeeDocIds, x.guid, this.retrieveStartDateForPreceedingWindow(),
    this.retrieveEndDateForSuceedingWindow(), firstBlush)),
    tap(() => {
      this.generatedDataOnce$.next(true);
      firstBlush = false;
    }),
    share()) as Observable<ByrntumData>;

  const generateUpdatesFromBackEndWithoutCombinedFrontEndInput$ = this.generateDataForActiveView$.pipe(
    filter(x => x.changeInHoursScheduledAgainstOnDeckJob === 0 || this.onDeckJob$.value === null),
    share()
  );

  const generateUpdatesChangingOnDeckScheduling = this.generateDataForActiveView$.pipe(
    // filter(x => x.changeInHoursScheduledAgainstOnDeckJob !== 0 && this.onDeckJob$.value !== null),
    filter(x => this.onDeckJob$.value !== null),
    tap(x => {
      const onDeckJob = this.onDeckJobRespectMinVisitLength$.value;
      const deltaHours = x.changeInHoursScheduledAgainstOnDeckJob;
      if (onDeckJob.hoursWorkingOnScheduling - deltaHours < .016666) {
        this.onDeckJob$.next(null);
        this.router.navigate(['/app-scheduler-view']);
      } else {
          if (deltaHours === 0) {
            this.onDeckJob$.next({job: onDeckJob.job, hoursWorkingOnScheduling: onDeckJob.hoursWorkingOnScheduling - deltaHours,
              prospectiveSiteVisitDurationHours: onDeckJob.prospectiveSiteVisitDurationHours });
          } else {
            this.onDeckJob$.next({job: onDeckJob.job, hoursWorkingOnScheduling: onDeckJob.hoursWorkingOnScheduling - deltaHours,
              prospectiveSiteVisitDurationHours: onDeckJob.hoursWorkingOnScheduling - deltaHours > 8 ? 8
              : onDeckJob.hoursWorkingOnScheduling - deltaHours });
          }
      }
    }),
    takeUntil(this.destroyingComponent$),
  ).subscribe();

  const processUpdatesWhenNotScheduling = generateUpdatesFromBackEndWithoutCombinedFrontEndInput$.pipe(
    filter(() => this.onDeckJob$.value === null && !this.loadingFromParams),
    tap(() => console.log("Null on deck")),
    debounce(() => this.verticalSchedulingService.processingChangeToSchedule$.pipe(filter(x => x === false))),
    takeUntil(this.destroyingComponent$)
    ).subscribe(data => {
        this.schedulerService.prospectiveSiteVisits = [];
        this.processUpdates(data);
        if (this.activeEmployeesToDisplay.length > 0) {
          this.schedulerOne.schedulerEngine.unmask();
        }
      });

    const processUpdatesWhenScheduling = generateUpdatesFromBackEndWithoutCombinedFrontEndInput$.pipe(
      filter(() => this.onDeckJob$.value !== null && this.onDeckJob$.value.hoursWorkingOnScheduling > 0),
      debounce(() => this.verticalSchedulingService.processingChangeToSchedule$.pipe(filter(x => x === false))),
      debounce(() => this.generateDataForActiveView$),
      filter(() => this.onDeckJobRespectMinVisitLength$.value !== null && this.onDeckJobRespectMinVisitLength$.value.hoursWorkingOnScheduling > 0),
      // This is needed b/c we currently sometimes use routing to add jobs to on deck.  Should be refactored
      // so that we either always, or never do this.
      skip(this.route.snapshot.params['onDeckJob'] ? 1 : 0),
      tap(() => console.log("Value on deck")),
      takeUntil(this.destroyingComponent$)
      ).subscribe(() => {
        try
        {
        this.enterScheduling();
        this.schedulerOne.schedulerEngine.unmask();
        } catch (err) {
          console.error(err);
        }
      }
      );

document.head.appendChild(this.styleNode);

this.schedulerService.viewWindowMovementEnabled$.pipe(
    delay(1),
    tap(x => this.viewWindowMovementEnabled$.next(x)),
    takeUntil(this.destroyingComponent$)
).subscribe();

this.filterResourceCommuteDeltaTime$.pipe(
  tap(x => this.resouceCommutePercentage = x),
  map(x => this.schedulerService.getCommuteDeltaMaxAssociatedWithSliderPercent(x)),
  distinctUntilChanged(),
  skip(1),
  filter(x => x !== this.numberMinutesAllowedForCommute),
  delay(1),
  tap(() => this.processUpdates(new ByrntumData({assignments: this.assignments, events: this.events, resources: this.resources,
    availibilityInfo: this.schedulerService.retrieveAvailibilityInfoFromSchedulingService()}))),
  takeUntil(this.destroyingComponent$)
).subscribe();

this.changeLengthOfSiteVisitBeingScheduled$.pipe(
  debounceTime(200),
  map(x => ({job: this.onDeckJob$.value.job, hoursWorkingOnScheduling: this.onDeckJob$.value.hoursWorkingOnScheduling,
             prospectiveSiteVisitDurationHours: x})),
  tap(x => this.onDeckJob$.next({job: x.job, hoursWorkingOnScheduling: x.hoursWorkingOnScheduling,
                                 prospectiveSiteVisitDurationHours: x.prospectiveSiteVisitDurationHours})),
    takeUntil(this.destroyingComponent$)
).subscribe();

let spHasFilteredASearch = false;
merge(this.generateDataForActiveView$,this.filterSearch$.pipe(
  distinctUntilChanged(),
  debounceTime(200),
)).pipe(
  filter(() => this.filterSearch$.value !== "" || spHasFilteredASearch),
  tap(() => spHasFilteredASearch = true),
  map(() => this.filterSearch$.value),
  tap(filter => this.modifyEventsBasedOnFilter(filter)),
  takeUntil(this.destroyingComponent$)
).subscribe();

this.schedulerService.updateProcessedWithoutChange$.pipe(
  debounce(() => this.schedulerLoaded$),
  debounce(() => this.verticalSchedulingService.processingChangeToSchedule$.pipe(filter(x => x === false))),
    takeUntil(this.destroyingComponent$)
    ).subscribe( () => {
  if (this.onDeckJob$.value === null) {
    this.removeGuidanceFromSchedule();
  }});


const sidebarClicked = this.unassignedWorkClickHandler$.pipe(
  map(job => {
    const entriesForAddress = this.physicalAddressRoutingService.orginAddressIdToAssociatedCommutes.get(job.serviceAddressDocId);
    const addressDocIdsOfInterest =
      this.verticalSchedulingService.jobs.filter(j => j.siteVisits.filter(sv => sv.startDate.getTime() > startOfDay(new Date()).getTime()).length > 0).map(x => x.serviceAddressDocId)
      .concat(this.physicalAddressRoutingService.addressDocIdsToAlwaysConsider)
      .filter(x => x !== job.serviceAddressDocId);
      let allFound = true;
    if (addressDocIdsOfInterest.find(addressDocId => !entriesForAddress.has(addressDocId))) {
      console.log(addressDocIdsOfInterest.find(addressDocId => !entriesForAddress.has(addressDocId)));
      allFound = false;
    }
    return {job, found: allFound};
  })
);

const allCommutesFound = sidebarClicked.pipe(
  filter(x => x.found),
);

const allCommutesNotFound = sidebarClicked.pipe(
  filter(x => !x.found),
  tap(x => {
    console.log("Generating Commute Matrix");
    this.schedulerOne.schedulerEngine.mask("Generating Commute Matrix");
    this.physicalAddressRoutingService.generateCommuteMatrixForAddressWithRetryCount$.next({address: x.job.serviceAddress, retryCount: 0})
  }),
  delay(1),
  debounce(val => this.dexieCacheService.monitoredAddresses$.pipe(
    filter(x => x.includes(val.job.serviceAddress.DocId()))
  )),
  tap(() => this.schedulerOne.schedulerEngine.unmask()),
  tap(x => console.log(x,` string`)),
);

merge(allCommutesFound, allCommutesNotFound).pipe(
  takeUntil(this.destroyingComponent$)
).subscribe(val => this.onDeckJob$.next(
  {job: val.job, hoursWorkingOnScheduling: val.job.remainingToScheduleHours,
   prospectiveSiteVisitDurationHours: val.job.remainingToScheduleHours > 8 ? 8 : val.job.remainingToScheduleHours}));


this.stateChangePropagatingFromUpdatesToOnDeckJob();
this.filterDisplayedRecordsBasedOnEmployeeTree();
this.filterDisplayedDaysBasedOnViewSelectionUpdates();



}

resetShowingSingleResource() {
  this.schedulerOne.schedulerEngine.resourceStore.removeFilter("singleResourceClick");
      this.showingSingleResouce = false;
      this.displayedActualResourceIds = [];
}

// Ideally this wouldn't be global to the scheduler view, but I am not sure how to suscribe to only events
// from the header renderer since I am only able to pass undecorated html to it. Later perhaps figure out.
@HostListener('click', ['$event.target'] ) onClickResource(header) {
  if (header.attributes !== undefined && header.attributes.resourceid !== undefined) {
    if (this.showingSingleResouce) {
      this.resetShowingSingleResource();
    } else {
    const resourceId = header.attributes.resourceid.value;
    const unTypedResourceRecord = this.schedulerOne.schedulerEngine.resourceStore.find(x => x.id === resourceId) as any;
    const actualResouceId = unTypedResourceRecord.data.actualResourceId;
    this.schedulerOne.schedulerEngine.resourceStore.filter
    ({id: "singleResourceClick", filterBy : record => record.actualResourceId === actualResouceId});
    this.showingSingleResouce = true;
    this.displayedActualResourceIds.push(actualResouceId);
    }
    if (this.onDeckJobRespectMinVisitLength$.value !== null) {
      this.enterScheduling();
    }
  }
}

dragUnassignedWorkSplitter(event: any) {
  if (event.x !== 0) {
    this.dragSidebarSplitter(event);
  }
}

dragSidebarSplitter(event: any) {
  const splitterDelta = this.workSplitterStartingX - event.x;
  this.sidebarWidth = this.initialSidebarWidth + splitterDelta;
  this.localSettingsService.saveToLocalStorage("SchedulerView","sidebarWidth",this.sidebarWidth);
  this.updateSidebarWidth$.next(null);
}

dragStartUnassignedWorkSplitter(event: any) {
  this.schedulerOne.schedulerEngine.suspendEvents();
  this.workSplitterStartingX = event.x;
  this.initialSidebarWidth = this.sidebarWidth;
  this.draggingWorkSplitter$.next(true);
}

dragEndUnassignedWorkSplitter(event: any) {
  this.schedulerOne.schedulerEngine.resumeEvents();
  this.dragSidebarSplitter(event);
  this.draggingWorkSplitter$.next(false);
}

getOnDeckWidthStyling() {
  return {width: `${this.sidebarWidth}px`};
}

onChangeEvent(eventArgs: any) {

  const removeCommuteTimeWhenUpdating = eventArgs.record.removeCommuteTimeWhenUpdating;
  let triggerBryntumEvent : Observable<any>;
  if (this.delayTriggeringBryntumEvents) {
    triggerBryntumEvent = of(null).pipe(
      delayWhen(() => this.schedulerService.freshDataRetrievedForScheduleView$),
      tap( () => this.delayTriggeringBryntumEvents = false),
    );
  } else {
    triggerBryntumEvent = of(null);
  }

  triggerBryntumEvent.pipe(
    tap(() => {
      if (!eventArgs.record.readOnly  && eventArgs.action !== 'dataset' && eventArgs.action !== 'filter'
      && eventArgs.action !== 'add' && eventArgs.action !== 'remove' ) {
        this.schedulerOne.schedulerEngine.suspendEvents(false);
        eventArgs.record.removeCommuteTimeWhenUpdating = removeCommuteTimeWhenUpdating;
        this.schedulerOne.schedulerEngine.resumeEvents();
        let siteVisitStartDate: Date = null;
        if (eventArgs.changes.startDate !== undefined) {
          siteVisitStartDate = eventArgs.changes.startDate.value;
        } else {
          siteVisitStartDate = addMinutes(eventArgs.record.data.startDate, eventArgs.record.data.commuteMinutesToSite);
        }
        const availiablityMatches = this.resourceAvailibility.filter(x => x.resourceId === eventArgs.record.assignments[0].data.resourceId && x.startDate <= siteVisitStartDate
          && x.endDate > siteVisitStartDate);
        this.schedulerService.bryntumChangeEvent.next({...eventArgs, siteVisitNumber: availiablityMatches.length > 0 ? availiablityMatches[0].siteVisitNumber : null});
      }
    }),
    take(1)
  ).subscribe();
}

onChangeAssignment(assignmentArgs: any) {
  let triggerBryntumEvent : Observable<any>;
  if (this.delayTriggeringBryntumEvents) {
    triggerBryntumEvent = of(null).pipe(
      delayWhen(() => this.schedulerService.freshDataRetrievedForScheduleView$),
      tap( () => this.delayTriggeringBryntumEvents = false)
    );
  } else {
    triggerBryntumEvent = of(null);
  }

  triggerBryntumEvent.pipe(
    tap(() => {
      if (assignmentArgs.action !== 'dataset'  && assignmentArgs.action !== 'filter'
      && assignmentArgs.action !== 'remove' && assignmentArgs.action !== 'add') {
        const availiablityMatches = this.resourceAvailibility.filter(x => x.resourceId === assignmentArgs.changes.resourceId.value && x.startDate <= assignmentArgs.record.event.data.startDate
          && x.endDate > assignmentArgs.record.event.data.startDate);
        this.schedulerService.bryntumChangeAssignment.next({...assignmentArgs, siteVisitNumber: availiablityMatches.length > 0 ? availiablityMatches[0].siteVisitNumber : null});
        }
    }),
  take(1)
  ).subscribe();
}

createEventByDraggingProspectiveOrClickingSpaceOnSchedule(eventRecord: any, resourceRecord: any, job: Job, hoursWorkingOnScheduling: number, explicitlyErrored: boolean) {

    this.schedulerOne.schedulerEngine.mask("Saving");
    if (this.creatingBryntumFromDrag) {
        eventRecord.siteVisit.minimizeSiteVisitMutationsOnReconciliation = true;
        this.assignSiteVisitToEmployee$.next({job , employeeDocId: resourceRecord.actualResourceId ,
                                              startTime: eventRecord.originalData.startDate, endTime: eventRecord.originalData.endDate,
                                              dayOfYear: resourceRecord.resourceDate, jobHoursActivelyWorkingOnScheduling:
                                              hoursWorkingOnScheduling, siteVisitDocId: eventRecord.siteVisitDocId, minimizeSiteVisitMutationsOnReconciliation: false,
                                              explicitlyErrored});
    } else {
      let startTime = new Date(eventRecord.startDate);
      if (eventRecord.commuteMinutesToSite !== undefined) {
        startTime = addMinutes(startTime, eventRecord.commuteMinutesToSite);
      }
      const endTime = addMinutes(startTime, Math.round(hoursWorkingOnScheduling * 60));
      this.assignSiteVisitToEmployee$.next({job, employeeDocId: resourceRecord.actualResourceId ,
                                            startTime, endTime, dayOfYear: resourceRecord.resourceDate,
                                            jobHoursActivelyWorkingOnScheduling: hoursWorkingOnScheduling,
                                            siteVisitDocId: eventRecord.siteVisitDocId, minimizeSiteVisitMutationsOnReconciliation: false,
                                            explicitlyErrored});
    }
}

beforeEventDrag(data) {
  if (data.eventRecord.lockedEventStartTime || data.eventRecord.lockedEventOrderNext || data.eventRecord.lockedEventOrderPrevious) {
    return false;
  }
  if (! data.eventRecord._prospectiveEvent && this.schedulerService.jobs.find(j => j.DocId() === data.eventRecord.jobDocId).lineItems.flatMap(l => l.requiredSkills).length > 0) {
    this.schedulerOne.schedulerEngine.mask("Jobs that have required skills assigned are not eligiable to be scheduled by dragging from one resource to another.  Please double click event then remove to reschedule.");
    setTimeout(() => this.schedulerOne.schedulerEngine.unmask(), 1000);
    return false;
  }
  let dragEligable = true;
  this.schedulerOne.schedulerEngine.suspendEvents(false);
  this.verticalSchedulingService.delaySchedulerUpdates$.next(true);
  this.draggingEventOnScheduler = true;
  this.setAnimationDuration(0);
  data.eventRecord.dragging = true;
  data.eventRecord.readOnly = true;
  let siteVisit = this.schedulerService.siteVisits.find(x=>x.docId === data.eventRecord.siteVisitDocId);
  if (siteVisit !== undefined)
  {
    const retrieveFromDexie = this.physicalAddressRoutingService.retrieveOnDemandFromDixie(siteVisit.siteVisitAddress).pipe(
      map(dexie => {
        return {dexie, addressesWeNeed: this.physicalAddressRoutingService.addressDocIdsToAlwaysConsider.concat(this.schedulerService.siteVisits.filter(x => x.startDate.getTime() > startOfDay(new Date()).getTime()).map(x => x.siteVisitAddress.DocId()))};
      }),
      share()
    );

    const missingDexie = retrieveFromDexie.pipe(
      filter(val => {
        for (var i in val.addressesWeNeed) {
          if (val.dexie.findIndex(x => x.originAddressDocId === val.addressesWeNeed[i] || x.destinationAddressDocId === val.addressesWeNeed[i]) === -1) {
            return true;
          }
        }
        return false;
      }),
      // If commute addresses we need to properly schedule are missing, we add job to on deck and wait until they are generated.
      tap(() => {
        console.log("Drag Not Eligable");

        this.draggingEventOnScheduler = false;
        data.eventRecord.readOnly = false;
        data.eventRecord.dragging = false;
        this.verticalSchedulingService.delaySchedulerUpdates$.next(false);
        this.schedulerOne.schedulerEngine.resumeEvents();
        dragEligable = false;
        this.invalidateDrag = true;
        this.schedulerOne.schedulerEngine.mask("You can not drag this event, Double click event then remove to reschedule.");
      })
      );

    const allPresent = retrieveFromDexie
      .pipe(
      filter(val => {
        for (var i in val.addressesWeNeed) {
          if (val.dexie.findIndex(x => x.originAddressDocId === val.addressesWeNeed[i] || x.destinationAddressDocId === val.addressesWeNeed[i]) === -1) {
            return false;
          }
        }
        return true
      }),
      tap(() => {


    //Ticket #34  This is where we *could* either hit a cache of lighter Dixie objects and instantiate our commute objects on demand, or perhaps just hit
    //dixie directly doing a merge on orgin and destination addresses.
    const employeesToConsider = this.displayedActualResourceIds.length !== 0 ?
  this.activeEmployeesToDisplay.filter(x => this.displayedActualResourceIds.some(y => y === x.docId)) : this.activeEmployeesToDisplay;

  if (employeesToConsider.length === 0) {
    window.alert("There are no displayed resources which can meet the criteria for job.  Please display additonal employees, or add additional skills to a resource assigned to the same office as the job.");
  }

    const prospectives = this.retrieveGuidanceForSiteVisit(new SiteVisit({_durationHours: siteVisit.expectedDurationHours,
                                                                          jobDocId: siteVisit.jobDocId, siteVisitAddress: siteVisit.siteVisitAddress}), this.schedulerService.siteVisits.filter(x=>x.docId !== siteVisit.docId),
                                                                          employeesToConsider);

    const existingData = this.schedulerService.retrieveCopyOfBryntumDataFromSchedulingService();
    existingData.availibilityInfo = this.buildBryntumViewOfResourceAvailibilityGuidance(
      prospectives !== null ? prospectives.resourceAvailibility.filter(x => x.CalcuationModel === RESOURCE_AVAILIBILITY_CALCULATION_METHOD.MINIMZE_SITE_VISIT_MUTATIONS).flatMap(q => q.ResourceAvailibility) : [],
       existingData.resources);
    this.siteVisitNumberService.resourceAvailabilityMinimizingSiteVisitMutations =
    prospectives !== null && prospectives.resourceAvailibility !== undefined ?
      prospectives.resourceAvailibility.filter(x => x.CalcuationModel === RESOURCE_AVAILIBILITY_CALCULATION_METHOD.MINIMZE_SITE_VISIT_MUTATIONS).flatMap(q => q.ResourceAvailibility) : [];
    this.processUpdates(existingData);

    const updateEvent = this.schedulerService.eventsVertical.find(x => x.siteVisitDocId === siteVisit.docId);
    updateEvent.removeCommuteTimeWhenUpdating = false;

    const theEvent = existingData.events.find(x => x.id === data.eventRecord.id);
    theEvent.endDate = subMinutes(theEvent.endDate, theEvent.commuteMinutesToSite + theEvent.commuteMinutesSiteToShop);
    data.eventRecord.endDate = subMinutes(data.eventRecord.endDate, data.eventRecord.data.commuteMinutesToSite + data.eventRecord.data.commuteMinutesSiteToShop);
    data.eventRecord.removeCommuteTimeWhenUpdating = false;
    data.eventRecord.commuteEndDate = new Date(data.eventRecord.startDate);

    // data.eventRecord.previousStartDate = addMinutes(startOfDay(context.activeResource.resourceDate), getHours
    theEvent.commuteEndDate = new Date(theEvent.startDate);
    theEvent.removeCommuteTimeWhenUpdating = false;
    }),
    tap(() => this.schedulerOne.schedulerEngine.resumeEvents()),
    take(1)
    );

    merge(missingDexie, allPresent).pipe(
      take(1)
    ).subscribe();

  } else {
    siteVisit = this.schedulerService.prospectiveSiteVisits.find(x=>x.docId === data.eventRecord.id);
    const updatedEvent = this.events.find(x => x.id === data.eventRecord.id);
    data.eventRecord.removeCommuteTimeWhenUpdating = false;
    data.eventRecord.endDate = subMinutes(updatedEvent.endDate, updatedEvent.commuteMinutesToSite + updatedEvent.commuteMinutesSiteToShop);
    //20230613
    data.eventRecord.commuteEndDate = new Date(data.eventRecord.startDate);
    this.schedulerOne.schedulerEngine.resumeEvents();
  }
}

validateDraggingSiteVisitLength(employeeDocId: string, siteVisitDocId, proposedStartTime: Date, proposedEndTime: Date ) : Observable<SCHEDULE_TIME_VALIDITY_STATE> {
  // Find associated site visit record.
  const siteVisit = this.schedulerService.siteVisits.find(x => x.docId === siteVisitDocId);
  // If the proposed start time is later then the current start time, schedule is valid.
  if (proposedStartTime.getTime() > siteVisit.startDate.getTime()) {
    return of(SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED);
  }
  // If proposed end time is earlier then current end time, schedule is valid.
  if (proposedEndTime.getTime() < siteVisit.endDate.getTime()) {
    return of(SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED);
  }

  // Otherwise schedule needs checked w/ guidance service.
  // Retrieve site visits that occur on specified day for specified employee.
  const siteVisitsOnDay = this.schedulerService.siteVisits.filter(x => startOfDay(x.startDate).getTime() === startOfDay(proposedStartTime).getTime());
  const assignementsForEmployee = this.schedulerService.assignments.filter(x => x.employeeDocId === employeeDocId);
  const siteVisitsForEmployeeOnDay = siteVisitsOnDay.filter(x => assignementsForEmployee.find(y => y.siteVisitDocId === x.docId) !== undefined);
  const employeeStartTime = this.employeeAvailabilityService.getEmployeeStartTimeForDate(proposedStartTime, employeeDocId);
  const employeeEndTime = this.employeeAvailabilityService.getEmployeeEndTimeForDate(proposedStartTime, employeeDocId);

  // replace start and end time for site visit SP is changing.
  const sv = this.siteVisitService.getCloneOfCachedValue(siteVisitDocId);
  sv.startDate = proposedStartTime;
  sv.endDate = proposedEndTime;
  siteVisitsForEmployeeOnDay[siteVisitsForEmployeeOnDay.findIndex(x => x.docId === siteVisitDocId)] = sv;

  siteVisitsForEmployeeOnDay.forEach(s => s.siteVisitAddress = this.schedulerService.jobs.find(x => x.siteVistDocIds.find(y => y === siteVisitDocId) !== undefined).serviceAddress);

  let invalidSchedule: boolean = false;
  ({ invalidSchedule } = this.schedulingGuidanceService.CheckIfValidSchedulePossible(siteVisitsForEmployeeOnDay, employeeDocId,
    startOfDay(proposedStartTime), employeeStartTime, employeeEndTime, invalidSchedule, false, false) );
  console.log(invalidSchedule);
    if (invalidSchedule) {
    return this.validateProposedSiteVisitStartEndTimes(sv.startDate, subMinutes(sv.startDate, sv.commuteTimeMinutesToSite), sv.endDate,
      addMinutes(sv.endDate, sv.commuteTimeMinutesFromSiteBackToShop), employeeDocId);
  } else {
    return of(SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED);
  }
}

validateSiteVisitAdditionAtSpecifiedStartTime(employeeDocId: string, startTime: Date, durationHours: number, siteVisitAddress: Address) : Observable<SCHEDULE_TIME_VALIDITY_STATE> {

  const bryntumStartTime = addMinutes(startOfDay(this.resourceAvailibility[0].startDate), startTime.getHours() * 60 + startTime.getMinutes());
  // find associated resource record.  B/c times that start of edges of ranges are both green / red, we first check for valid.
  let avail = this.resourceAvailibility.find(x => x.employeeDocId === employeeDocId && startOfDay(startTime).getTime() === x.actualDate.getTime() &&
      bryntumStartTime.getTime() >= x.startDate.getTime() && bryntumStartTime.getTime() <= x.endDate.getTime() && (x.timeRangeColor === "green" || x.timeRangeColor === "yellow"));
  if (avail === undefined) {
    avail = this.resourceAvailibility.find(x => x.employeeDocId === employeeDocId && startOfDay(startTime).getTime() === x.actualDate.getTime() &&
      bryntumStartTime.getTime() >= x.startDate.getTime() && bryntumStartTime.getTime() <= x.endDate.getTime());
  }
  console.log(avail);
  console.log(bryntumStartTime);
  console.log(this.resourceAvailibility.filter(x => x.employeeDocId === employeeDocId && startOfDay(startTime).getTime() === x.actualDate.getTime()));
  if (avail === undefined || avail.timeRangeColor === 'red') {
    let proposedCommuteStartTime = startTime;
    let proposedCommuteEndTime = addMinutes(startTime, durationHours * 60);
    // first site visit of day.
    if (avail?.preceedingSiteVisits === undefined || avail.preceedingSiteVisits.length === 0)
    {
        if (this.employeeService.get(employeeDocId).dispatchOrginAddressCommuteDispensation === CommuteCharacterization.WORKDAY) {
          const matchedCommute = this.physicalAddressRoutingService.orginAddressIdToAssociatedCommutes.get(siteVisitAddress.docId)
          .get(this.employeeService.get(employeeDocId).dispatchOrginAddress.DocId());
          const minutesCommute = matchedCommute? matchedCommute.timeEstimateSeconds / 60 : 120;
          proposedCommuteStartTime = subMinutes(startTime, minutesCommute);
        }
    }
    if (avail?.postceedingSiteVisits === undefined || avail.postceedingSiteVisits.length === 0) {
      if (this.employeeService.get(employeeDocId).dispatchDestinationAddressCommuteDispensation === CommuteCharacterization.WORKDAY) {
        const minutesCommute = this.physicalAddressRoutingService.orginAddressIdToAssociatedCommutes.get(this.employeeService.get(employeeDocId).dispatchDestinationAddress.DocId())
        .get(siteVisitAddress.docId) ? this.physicalAddressRoutingService.orginAddressIdToAssociatedCommutes.get(this.employeeService.get(employeeDocId).dispatchDestinationAddress.DocId())
        .get(siteVisitAddress.docId).timeEstimateSeconds / 60 : 120;
        proposedCommuteEndTime = addMinutes(proposedCommuteEndTime, minutesCommute);
      }
    }
    return this.validateProposedSiteVisitStartEndTimes(startTime, proposedCommuteStartTime, addMinutes(startTime, durationHours*60), proposedCommuteEndTime, employeeDocId);
  } else {
    return of(SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED);
  }
}

validateProposedSiteVisitStartEndTimes(proposedStartTime: Date, proposedCommuteStartTime: Date, proposedEndTime: Date, proposedCommuteEndTime: Date, employeeDocId: string) : Observable<SCHEDULE_TIME_VALIDITY_STATE> {
{
  const employeeScheduledStartTime = this.employeeAvailabilityService.getEmployeeStartTimeForDate(proposedStartTime, employeeDocId);
  const employeeScheduledEndTime = this.employeeAvailabilityService.getEmployeeEndTimeForDate(proposedStartTime, employeeDocId);
  const startTimeToRunSchedule = this.employeeService.get(employeeDocId).dispatchOrginAddressCommuteDispensation ===  CommuteCharacterization.WORKDAY ?
    proposedCommuteStartTime : proposedStartTime;
  const endTimeToRunSchedule = this.employeeService.get(employeeDocId).dispatchDestinationAddressCommuteDispensation ===  CommuteCharacterization.WORKDAY ?
    proposedCommuteEndTime : proposedEndTime;

  let earliestAllowedEndTime = undefined;
  let latestAllowedStartTime = undefined;
  let warningRationale = "Red Zone";

  if (startOfDay(proposedStartTime).getTime() < startOfDay(new Date()).getTime()) {
    warningRationale = "Past Date";
  } else if (employeeScheduledStartTime === null || startTimeToRunSchedule.getTime() < employeeScheduledStartTime.getTime() || endTimeToRunSchedule.getTime() > employeeScheduledEndTime.getTime()) {
    earliestAllowedEndTime =  employeeScheduledEndTime === null || endTimeToRunSchedule.getTime() > employeeScheduledEndTime.getTime() ? endTimeToRunSchedule : undefined;
    latestAllowedStartTime = employeeScheduledStartTime === null || startTimeToRunSchedule.getTime() < employeeScheduledStartTime.getTime() ? startTimeToRunSchedule : undefined;
    warningRationale = "Outside Employee Schedule"
  }

    // Add code here to only launch confirmation modal when user has not yet clicked to dismiss all ( https://github.com/sethrevelle/service-vanguard/issues/596 )
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '500px',
        data         : {
          earliestAllowedEndTime,
          latestAllowedStartTime,
          warningRationale,
        }
        });

        this.updateRequiresScheduleModificationDialogRef = this.dialog.open(ScheduleModificationReqModalComponent, editorConfig);

    // Event dialog closure:
    const changeWorkingDialogRef = this.updateRequiresScheduleModificationDialogRef.afterClosed().pipe(
      share(),
      take(1));

    const closeCancel = changeWorkingDialogRef.pipe(
      filter(output => output === 'CANCEL' || output === undefined),
      map(() => SCHEDULE_TIME_VALIDITY_STATE.INVALID)
    );

    const closeDisableWarning = changeWorkingDialogRef.pipe(
      filter(output => output === 'DISABLE_WARNING')
    );

    const closeProceed = changeWorkingDialogRef.pipe(
      filter(output => output === 'PROCEED')
    );

    const proceedMustUpdateTime = merge(closeProceed, closeDisableWarning).pipe(
      filter(() => warningRationale === "Outside Employee Schedule"),
      switchMap(() => this.modifyWorkingHoursToSatisfySchedule(employeeDocId, startOfDay(startTimeToRunSchedule), endTimeToRunSchedule, startTimeToRunSchedule)),
      map(retVal => retVal ? SCHEDULE_TIME_VALIDITY_STATE.VALID_AFTER_MODIFICATION : SCHEDULE_TIME_VALIDITY_STATE.INVALID)
    )

    const proceedBookToRedZone = merge(closeProceed, closeDisableWarning).pipe(
      filter(() => warningRationale === "Red Zone" || warningRationale === "Past Date"),
      map(() => SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID)
    );

    return merge(proceedMustUpdateTime, closeCancel, proceedBookToRedZone).pipe(
      take(1)
    );

  }
}

modifyWorkingHoursToSatisfySchedule(employeeId: string, dateInQuestion: Date, minimumEndTime: Date, maximumStartTime: Date) : Observable<boolean> {
  const editorConfig = new MatDialogConfig();

  Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         : {
        employeeId,
        dateInQuestion,
        employeeScheduleModificationReason: "resolve_schedule",
        minimumEndTime,
        maximumStartTime
      }
      });

  this.changeWorkingHoursDialogRef = this.dialog.open(ModifyEmployeeScheduleModalComponent, editorConfig);

  // Event dialog closure:
  const changeResult =  this.changeWorkingHoursDialogRef.afterClosed().pipe(
    share()
  );

  const validates = changeResult.pipe(
    filter(output => output.action === "update"),
    map(output => output.avail as EmployeeAvailability),
    tap(() => this.schedulerOne.schedulerEngine.mask("Saving")),
    tap(() => this.delayTriggeringBryntumEvents = true),
    tap(() => this.verticalSchedulingService.processingChangeToSchedule$.next(true)),
    switchMap(avail => this.employeeAvailabilityService.update$(avail)),
    map(() => true)
  );

  const invalid = changeResult.pipe(
    filter(output => output.action !== "update"),
    map(() => false)
  );

  return merge(validates, invalid).pipe(
    take(1)
  );
}


beforeEventDropFinalize({context, event, source}) {

  context.async = true;
  // Validate here.

  if (this.invalidateDrag) {
    context.newResource = context.oldResource;
    context.startDate = subMinutes(context.origStart, context.record.data.commuteMinutesToSite);
    context.endDate = context.origEnd;
    this.invalidateDrag = false;
    this.schedulerOne.schedulerEngine.unmask();
    context.finalize(false)
  } else {
    this.siteVisitNumberService.resourceAvailabilityMinimizingSiteVisitMutations = undefined;

    context.record.removeCommuteTimeWhenUpdating = false;
    const startDateFromBryntum = context.record.roundedDate;
    const durationHours = (context.record.actualEndDate.getTime() - context.record.actualStartDate.getTime()) / 1000 / 60 / 60;
    const actualStartDate = addMinutes(startOfDay(context.newResource.resourceDate), getHours(startDateFromBryntum)*60 + getMinutes(startDateFromBryntum));
    const validate = this.validateSiteVisitAdditionAtSpecifiedStartTime(context.newResource.actualResourceId, actualStartDate,
      durationHours,this.addressService.get(context.record.data.addressDocId)).pipe(
        share());

    const validNeedsChanges = validate.pipe(
          filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.VALID_AFTER_MODIFICATION),
          tap(x => console.log(x,` string`)),
          tap(() => {
            this.setAnimationDuration(this.defaultAnimationDuration);
            context.record.commuteEndDate = new Date(startDateFromBryntum);
            context.record.readOnly = false;
            context.record['explicitErrored'] = false;
          }),
        );

    const validNoChangesNeeded = validate.pipe(
        filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID || valid === SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED),
        tap(valid => {
          this.setAnimationDuration(this.defaultAnimationDuration);
          //20230613
          context.record.commuteEndDate = new Date(startDateFromBryntum);
          context.record.readOnly = false;
          if (valid === SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID) {
            context.record['explicitErrored'] = true;
          } else {
            context.record['explicitErrored'] = false;
          }
          this.modifyScheduleStylingToRemoveGuidance();
          this.schedulerService.resourceAvailibility = undefined;
          }),
      );

      const recordDropAlowed = merge(validNoChangesNeeded, validNeedsChanges).pipe(
        tap(() => {
          this.modifyScheduleStylingToRemoveGuidance();
          this.schedulerService.resourceAvailibility = undefined;
          this.loggingService.addLog(`Employee dropped site visit ${context.record.siteVisitDocId} at ${actualStartDate}`)
          if (context.record.actualStartDate && startOfDay(context.record.actualStartDate).getTime() < startOfDay(new Date()).getTime()) {
            console.warn("GENERATING COMMUTE RECORD");
            this.physicalAddressRoutingService.addressNeedsPopulatedToSchedule$.next(
              this.verticalSchedulingService.siteVisits.find(x => x.DocId() === context.record.siteVisitDocId).siteVisitAddress);
          }
          }),
      );

    const invalid = validate.pipe(
        filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.INVALID),
    );


    merge(invalid, recordDropAlowed).pipe(
      tap(() => {
        this.draggingEventOnScheduler = false;
        setTimeout(() => this.verticalSchedulingService.delaySchedulerUpdates$.next(false), 1);
      }),
      take(1),
    ).subscribe(valid => context.finalize(valid));
  }

}

onBeforeEventResizeFinalize({context, event, source}) {
  // Validate here.

  context.async = true;

  const durationHours = (context.endDate.getTime() - context.startDate.getTime()) / 1000 / 60 / 60 - context.eventRecord.commuteMinutesToSite / 60 - context.eventRecord.commuteMinutesSiteToShop / 60;
  const startDate = addMinutes(startOfDay(context.eventRecord.actualStartDate), getHours(context.startDate)*60 + getMinutes(context.startDate) + context.eventRecord.commuteMinutesToSite);
  const endDate = addMinutes(startDate, durationHours * 60);

  let validate: Observable<SCHEDULE_TIME_VALIDITY_STATE>;
  if (context.eventRecord._prospectiveEvent) {
    validate = this.validateSiteVisitAdditionAtSpecifiedStartTime(context.resourceRecord.actualResourceId, startDate, durationHours,
      this.addressService.get(context.eventRecord.data.addressDocId)).pipe(
          share(),
      );
  } else {
    validate = this.validateDraggingSiteVisitLength(context.resourceRecord.actualResourceId, context.eventRecord.siteVisitDocId, startDate, endDate).pipe(
      share(),
    );
  }

  const validNeedsChanges = validate.pipe(
        filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.VALID_AFTER_MODIFICATION),
        tap(() => {
          context.eventRecord.commuteEndDate = addMinutes(context.startDate,context.eventRecord.commuteMinutesToSite);
          context.eventRecord['explicitErrored'] = false;
        }),
        delayWhen(() => this.schedulerService.freshDataRetrievedForScheduleView$)
      );

  const validNoChangesNeeded = validate.pipe(
      filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID || valid === SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED),
      tap(valid => {
        context.eventRecord.commuteEndDate = addMinutes(context.startDate,context.eventRecord.commuteMinutesToSite);
        if (valid === SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID) {
          context.eventRecord['explicitErrored'] = true;
        } else {
          context.eventRecord['explicitErrored'] = false;
        }
      })
  );

  const invalid = validate.pipe(
      filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.INVALID),
  );


  merge(validNeedsChanges, validNoChangesNeeded, invalid).pipe(
    tap(valid => context.finalize(valid)),
    take(1),
  ).subscribe();
}

onEventMouseDown(data) {
  data.eventRecord.startingY = data.event.y;
}

onScroll(data) {
  this.schedulerOne.scheduleTooltipFeature['generateTipContent']({date: new Date(),event: undefined, resourceRecord: undefined});
  this.localSettingsService.saveToLocalStorage("SchedulerView", "scrollPosition", data.scrollTop);
}

onEventDragAbort(data) {
  if (this.invalidateDrag) {
    data.context.startDate = subMinutes(data.context.origStart, data.context.record.data.commuteMinutesToSite);
    data.context.endDate = data.context.origEnd;
    this.schedulerOne.schedulerEngine.unmask();
    this.invalidateDrag = false;
  } else {
    // If aborting b/c we dragged event back to same location, manually trigger update.
    // This is b/c event is dropped in same startDate location, but commute is removed
    // so it actually represents a different start time.
    if (data.context.origStart.getTime() === data.context.startDate.getTime()){
      this.schedulerService.manuallyUpdateStartTimeSiteVisit$.next({siteVisit:  this.siteVisitService.get(data.eventRecords[0].event.siteVisitDocId),
                                                                    bryntumEventRawStartTime: data.context.startDate});
    }
    this.removeGuidanceFromSchedule();
    this.draggingEventOnScheduler = false;
    this.verticalSchedulingService.delaySchedulerUpdates$.next(false);
    this.schedulerOne.schedulerEngine.unmask();
  }
}

beforeEventEdit(
  { eventRecord, resourceRecord, eventEdit }:
      { eventRecord: BryntumEvent, resourceRecord: BryntumResourceDateSummation, eventEdit: any }
): boolean {

  if (eventRecord.actualStartDate === undefined || eventRecord._prospectiveEvent) {

    if (this.onDeckJob$.value !== null) {
      const job = this.onDeckJob$.value.job;
      const hoursWorkingOnScheduling = this.onDeckJob$.value.prospectiveSiteVisitDurationHours;

    let validateSiteVisitAdditon : Observable<SCHEDULE_TIME_VALIDITY_STATE>;

    console.log('turkey');
      // this.physicalAddressRoutingService.addressNeedsPopulatedToSchedule$.next(
    if (this.creatingBryntumFromDrag) {
      //validate here
      validateSiteVisitAdditon = this.validateSiteVisitAdditionAtSpecifiedStartTime(resourceRecord.actualResourceId, eventRecord['originalData'].startDate,
          (eventRecord['originalData'].endDate.getTime() - eventRecord['originalData'].startDate.getTime())/1000/60/60,job.serviceAddress);
    } else {
      let startTime = new Date(eventRecord.startDate);
      if (eventRecord.commuteMinutesToSite !== undefined) {
        startTime = addMinutes(startTime, eventRecord.commuteMinutesToSite);
      }
      startTime = EmployeeAvailabilityService.convertTimeToSameTimeOnSpecifiedDate(startTime, resourceRecord.resourceDate);
      //validate here
      validateSiteVisitAdditon = this.validateSiteVisitAdditionAtSpecifiedStartTime(resourceRecord.actualResourceId, startTime,
        hoursWorkingOnScheduling,job.serviceAddress);
    }

    const validate = validateSiteVisitAdditon.pipe(
      share()
    )

    // do nothing for invalid schedule.
    const invalidSchedule = validate.pipe(
      filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.INVALID));

    const validAfterUpdate = validate.pipe(
      filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.VALID_AFTER_MODIFICATION),
      delayWhen(() => this.schedulerService.freshDataRetrievedForScheduleView$),
      tap(() => this.createEventByDraggingProspectiveOrClickingSpaceOnSchedule(eventRecord, resourceRecord, job, hoursWorkingOnScheduling, false)),
    );

    const validWithoutModification = validate.pipe(
      filter(valid => valid === SCHEDULE_TIME_VALIDITY_STATE.VALID_NO_MODIFICATION_NEEDED || valid === SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID),
      tap(valid => this.createEventByDraggingProspectiveOrClickingSpaceOnSchedule(eventRecord, resourceRecord, job, hoursWorkingOnScheduling,
        valid === SCHEDULE_TIME_VALIDITY_STATE.SCHRODINGER_CAT_VALID ? true : false)),
    );

    merge(invalidSchedule, validAfterUpdate, validWithoutModification).pipe(
      take(1)
    ).subscribe()
  }

  this.creatingBryntumFromDrag = false;

} else {

  const siteVisit = this.schedulerService.siteVisits.find(z => z.docId === eventRecord.siteVisitDocId);

  this.jobService.load$(eventRecord.jobDocId).pipe(
    tap(activeJob => {
      const siteVisitDetailsFormGroup = this.typedFormBuilder.group ({
        serviceAddress: [{value: activeJob.serviceAddress.formattedAddress(), disabled : true}],
        serviceAddressUnit: [{value: activeJob.serviceAddress.unit, disabled : true}],
        customerName: [{value: activeJob.customer.customerName, disabled : true}],
        customerPhone: [{value: activeJob.customer.primaryPhoneNumber, disabled : true}],
        jobTypeName: [{value: activeJob.jobType.name, disabled : true}],
        resource: [{value: resourceRecord.name, disabled : true}],
        hoursScheduled: [{value: activeJob.siteVisitScheduledHours, disabled: false}],
        hoursRequired : [{value: activeJob.durationExpectedHours, disabled : false}],
        siteVisitStartDate: [{value: eventRecord.actualStartDate, disabled : true}],
        siteVisitEndDate: [{value: eventRecord.actualEndDate, disabled : true}],
        siteVisitArrivalWindowStartDate: [{value: eventRecord._arrivalWindowStartDate, disabled :false}],
        siteVisitArrivalWindowEndDate: [{value: eventRecord._arrivalWindowEndDate, disabled: false}],
        siteVisitSummaryInformation: [{value: activeJob.generateHeaderForEventDetailsModal(
                          siteVisit.DocId()),  disabled: true }],
        siteVisitDispatchStatus: [{value: siteVisit.siteVisitDispatchStatus, disabled : true}],
        paymentStatus: [{value: "DoWeWant?", disabled : true}],
        jobStatus: [{value: "DoWeWant?", disabled : true}],
        jobNotes: [{value: activeJob.notes, disabled : false}],
        siteVisitNotes: [{value: siteVisit.notes, disabled : false}],
        siteVisitCommuteTimeFront: [{value: addMinutes(startOfDay(new Date()),siteVisit.getRoundedCommuteTimeMinutes(siteVisit.commuteTimeMinutesToSite, this.settingsService, 5)), disabled: false}],
      });
      const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
          disableClose : false,
          autoFocus    : true,
          width        : '500px',
          data         : {
            siteVisitDetailsFormGroup,
            title: eventRecord.name,
            customer : activeJob.customer,
            customerTags: activeJob.customer.customerTags,
            jobTags: activeJob.jobTags,
            siteVisitDocId: eventRecord.siteVisitDocId,
            job: activeJob,
            outstandingTimeOnJob: activeJob.durationExpectedHours > activeJob.siteVisitScheduledHours,
            siteVisit,
            employeeDocId: resourceRecord.actualResourceId,
          }
          });
      this.fileNameDialogRef = this.dialog.open(EventDetailsComponent, editorConfig);
      // Event dialog closure:
      this.fileNameDialogRef.afterClosed().pipe(take(1)).subscribe(x => this.eventDialogClosure({...x, siteVisit, activeJob}));
    }),
    take(1)
  ).subscribe();
}

  // suppress default event editor
  return false;
}


filteredLogging(event: any) {
  switch (event.type) {
    case "eventdragstart":
      case "eventkeyup":
    console.log(event);
    break;
    case "mouseout":
    case "mouseover":
    case "schedulemousemove":
    case "cellmouseout":
    case "cellmouseover":
      break;
    default:
      console.log(event.type);
      console.log(event);
  }
}

dragCreateIfAcceptable(event: any): boolean {
  if (this.onlyCreateWhenEventOnDeck(event)) {
    this.creatingBryntumFromDrag = true;
    return true;
  } else {
    return false;
  }
}

onlyCreateWhenEventOnDeck(event: any): boolean {
  if (this.onDeckJob$.value === null) {
    return false;
  } else {
    return true;
  }
}

onScheduleContextMenuItemClicked(data) {
  const employeeId = data.resourceRecord.actualResourceId;
  const dateInQuestion = data.resourceRecord.resourceDate;

  if (data.item._ref === "showGeofenceEntries") {
    this.employeeGeofenceLogService.outputToConsole(employeeId, dateInQuestion);
  }
  if (data.item._ref === "showCurrentLocation") {
    this.employeeLocationService.queryFirestoreShallow$(([where("employeeDocId", "==", employeeId)])).pipe(
      debounceTime(200),
      tap(x => window.alert(`Location last observed at: ${x[0].lastRecordedAt }`)),
      tap(x => window.open(`https://www.google.com/maps/search/?api=1&query=${x[0].latitude},${x[0].longitude}`, "_blank")),
      take(1)
    ).subscribe();
  }
  if (data.item._ref === "addEvent") {
    this.launchChangeTimeOffModal(employeeId, dateInQuestion);
  }
  if (data.item._ref === "earliestStart") {
    const siteVisits = this.verticalSchedulingService.retrieveSiteVisitsForGivenEmployeeDay(employeeId, dateInQuestion);
    this.schedulingGuidanceService.setSiteVisitsToEarliestPossible(siteVisits, employeeId, dateInQuestion);
  }
  if (data.item._ref === "latestStart") {
    const siteVisits = this.verticalSchedulingService.retrieveSiteVisitsForGivenEmployeeDay(employeeId, dateInQuestion);
    this.schedulingGuidanceService.setSiteVisitsToLatestPossible(siteVisits, employeeId, dateInQuestion);
  }
  if (data.item._ref === "lockSiteVisitOrder" ) {
    const siteVisits = this.verticalSchedulingService.retrieveSiteVisitsForGivenEmployeeDay(employeeId, dateInQuestion);
    siteVisits.forEach(x => x.lockNextSiteVisitOrder = true);
    siteVisits.forEach(x => x.lockPreceedingSiteVisitOrder = true);
    zip(of(null), ...siteVisits.map(s => this.siteVisitService.update$(s))).pipe(
      take(1)
    ).subscribe();
  }
  if (data.item._ref === "unlockSiteVisitOrder" ) {
    const siteVisits = this.verticalSchedulingService.retrieveSiteVisitsForGivenEmployeeDay(employeeId, dateInQuestion);
    siteVisits.forEach(x => x.lockNextSiteVisitOrder = false);
    siteVisits.forEach(x => x.lockPreceedingSiteVisitOrder = false);
    zip(of(null), ...siteVisits.map(s => this.siteVisitService.update$(s))).pipe(
      take(1)
    ).subscribe();
  }
}

onEventContextMenuClicked(data) {
  const activeSiteVisit = this.verticalSchedulingService.siteVisits.find(x => x.DocId() === data.eventRecord.data.siteVisitDocId);

  // toggle site visit arrival time lock.
  if (data.item._ref === "lockArrivalTime" || data.item._ref === "unlockArrivalTime") {
    activeSiteVisit.lockStartTime = !activeSiteVisit.lockStartTime;
    console.log(activeSiteVisit);
  }
  //toggle site visit relation to preceeding lock.
  if (data.item._ref === "lockPreceedingSiteVisitOrder") {
    activeSiteVisit.lockPreceedingSiteVisitOrder = true;
    if (activeSiteVisit.previousSiteVisit) {
      activeSiteVisit.previousSiteVisit.lockNextSiteVisitOrder = true;
      this.siteVisitService.update$(activeSiteVisit.previousSiteVisit).pipe(take(1)).subscribe();
    }
  }
  if (data.item._ref === "unlockPreceedingSiteVisitOrder") {
    activeSiteVisit.lockPreceedingSiteVisitOrder = false;
    if (activeSiteVisit.previousSiteVisit) {
      activeSiteVisit.previousSiteVisit.lockNextSiteVisitOrder = false;
      this.siteVisitService.update$(activeSiteVisit.previousSiteVisit).pipe(take(1)).subscribe();
    }
  }
  //toggle site visit relation to next site visit lock.
  if (data.item._ref === "lockSucceedingSiteVisitOrder") {
    activeSiteVisit.lockNextSiteVisitOrder = true;
    // update next site visit if it exists.
    const nextSiteVisit = this.verticalSchedulingService.siteVisits.find(x => x.previousSiteVisit && x.previousSiteVisit.DocId() === data.eventRecord.data.siteVisitDocId);
    if (nextSiteVisit) {
      nextSiteVisit.lockPreceedingSiteVisitOrder = true;
      this.siteVisitService.update$(nextSiteVisit).pipe(take(1)).subscribe();
    }
  }

  if (data.item._ref === "unlockSucceedingSiteVisitOrder") {
    activeSiteVisit.lockNextSiteVisitOrder = false;
    // update next site visit if it exists.
    const nextSiteVisit = this.verticalSchedulingService.siteVisits.find(x => x.previousSiteVisit && x.previousSiteVisit.DocId() === data.eventRecord.data.siteVisitDocId);
    if (nextSiteVisit) {
      nextSiteVisit.lockPreceedingSiteVisitOrder = false;
      this.siteVisitService.update$(nextSiteVisit).pipe(take(1)).subscribe();
    }
  }

  this.siteVisitService.update$(activeSiteVisit).pipe(
    tap(() => this.verticalSchedulingService.triggerRerenderingManually$.next(null)),
    take(1)
  ).subscribe();
}

launchChangeTimeOffModal(employeeId: string, dateInQuestion: Date) {
  const editorConfig = new MatDialogConfig();

  Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         : {
        employeeId,
        dateInQuestion,
        employeeScheduleModificationReason: "unknown"
      }
      });

  this.changeWorkingHoursDialogRef = this.dialog.open(ModifyEmployeeScheduleModalComponent, editorConfig);

  // Event dialog closure:
  this.changeWorkingHoursDialogRef.afterClosed().pipe(take(1)).subscribe(x => this.changingWorkingHoursDialogClosure(x));
}

changingWorkingHoursDialogClosure(output: any) {
  if (output !== undefined && output !== "") {
    console.log(output);
    const avail = (output.avail as EmployeeAvailability);
    if (output.action === "update") {
    this.schedulerOne.schedulerEngine.mask("Saving");
    this.employeeAvailabilityService.update$(avail).pipe(
      tap(() => this.schedulerOne.schedulerEngine.unmask()),
      tap(() => this.updatedViewWindow$.next(this.updatedViewWindow$.value)),
      take(1)
    ).subscribe();
    } else if (output.action === "delete") {
      this.schedulerOne.schedulerEngine.mask("Deleting");
      this.employeeAvailabilityService.delete$(avail).pipe(
        tap(() => this.schedulerOne.schedulerEngine.unmask()),
        tap(() => this.updatedViewWindow$.next(this.updatedViewWindow$.value)),
        take(1)
      ).subscribe();
    }
  }
}

initilizeScheduler() {

this.schedulerOne.schedulerEngine.eventStore.on('change', (event) =>
this.onChangeEvent(event));
this.schedulerOne.schedulerEngine.assignmentStore.on('change', (event) =>
this.onChangeAssignment(event));

this.schedulerOne.schedulerEngine.on('beforeEventEdit', this.beforeEventEdit.bind(this));
this.schedulerOne.schedulerEngine.on('beforedragcreate', this.dragCreateIfAcceptable.bind(this));
this.schedulerOne.schedulerEngine.on('scheduledblclick', this.onlyCreateWhenEventOnDeck.bind(this));
this.schedulerOne.schedulerEngine.on('beforeEventDrag', this.beforeEventDrag.bind(this));
this.schedulerOne.schedulerEngine.on('beforeeventdropfinalize', this.beforeEventDropFinalize.bind(this));
this.schedulerOne.schedulerEngine.on('eventDragAbort', this.onEventDragAbort.bind(this));
this.schedulerOne.schedulerEngine.on('beforeeventresizefinalize', this.onBeforeEventResizeFinalize.bind(this));
this.schedulerOne.schedulerEngine.on('eventmousedown', this.onEventMouseDown.bind(this));
this.schedulerOne.schedulerEngine.on('ScheduleContextMenuItem', this.onScheduleContextMenuItemClicked.bind(this));
this.schedulerOne.schedulerEngine.on('EventContextMenuItem', this.onEventContextMenuClicked.bind(this));
this.schedulerOne.schedulerEngine.on('scroll', this.onScroll.bind(this));

this.schedulerOne.schedulerEngine.features.eventTooltip.tooltip.hoverDelay = 300;
this.schedulerOne.schedulerEngine.features.eventTooltip.tooltip.hideDelay = 0;

// this.schedulerOne.schedulerEngine.on({catchAll: event => {this.filteredLogging(event);}});
this.schedulerOne.schedulerEngine.resourceStore.addSorter({
  fn : (recordA, recordB) => {
      return new Date(recordA.resourceDate).getTime() < new Date(recordB.resourceDate).getTime() ? -1 :
      new Date(recordA.resourceDate).getTime() > new Date(recordB.resourceDate).getTime() ? 1 :
      0;
  }});


  of(null).pipe(
    tap(() => this.schedulerOne.schedulerEngine.mask("Loading")),
    debounce(() => combineLatest([this.dexieCacheService.monitoredAddresses$, this.dexieCacheService.resourceDayAddressesCached$]).pipe(debounceTime(250))),
    tap(() => this.schedulerOne.schedulerEngine.unmask()),
    tap(() => this.schedulerLoaded$.next(true)),
    tap(x => console.log("Scheduler Loaded")),
    take(1)
  ).subscribe();

  const scrollTo = this.localSettingsService.loadFromLocalStorage("SchedulerView", "scrollPosition", 0);
  from(this.schedulerOne.schedulerEngine.scrollVerticallyTo(scrollTo)).pipe(
  ).subscribe();
}
}
