import { inject, Injectable } from "@angular/core";
import { Audience, FormlyUtilityService } from "../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-utility.service";
import { BehaviorSubject, catchError, combineLatest, debounce, debounceTime, delay, delayWhen, distinctUntilChanged, filter, finalize, map, merge, mergeMap, Observable, of, pairwise, ReplaySubject, share, skip, startWith, Subject, switchMap, take, takeUntil, tap, throwError, timer } from "rxjs";
import { FormlyFieldConfig, FormlyFormBuilder, FormlyFormOptions } from "@ngx-formly/core";
import { FetchUpdatedWorkflowService, WORKFLOW_STAGE } from "../../../../web-app/src/app/form-builder/component-models/formly-controls/utilities/fetch-updated-workflow.service";
import { FormModelFirestore } from "../../data/dao/form-model-firestore";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { FormFirestore } from "../../data/dao/form-firestore";
import { DataUsedByDataLinkService } from "../../../../web-app/src/app/form-builder/data-link-populator/data-link.service";
import { FormlyLineItemService } from "../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-line-item/formly-line-item.service";
import { JobService } from "../../data/dao-services/job.service";
import { FormModelFirestoreService } from "../../data/dao-services/form-model-firestore.service";
import { FormFirestoreService } from "../../data/dao-services/form-firestore.service";
import { LoggingService } from "../../data/logging/logging.service";
import { AuthenticationService } from "../../util/authentication.service";
import { SettingsService } from "../../../../web-app/src/app/settings/settings.service";
import { PriceBookEntryService } from "../../data/dao-services/pricebook/pricebook-entry.service";
import { PricebookEntryDisplayComponent, PriceBookEntryOutput } from "../pricebook-entry-display/pricebook-entry-display.component";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import {RemapAssociatedComponentsService} from "../../../../web-app/src/app/form-builder/component-models/textbox-control/remap-associated-components.service";

@Injectable({
  providedIn: 'root'
})

export class FormFirestoreWorkflowComponentService {

destroyAllFormFirestoreWorkflowComponentObservables : Subject<void> = new Subject<void>();
destoryTechViewObservables$: Subject<void> = new Subject<void>();
patchInTechViewUpdatesInitilized : boolean = false;
ngOnInitReplacementInitilized: boolean = false;

formlyFields = new BehaviorSubject<FormlyFieldConfig[]>([]);
workflowStage: WORKFLOW_STAGE = WORKFLOW_STAGE.DEPLOYED;
form!: FormGroup;
loadedInitialFormModelFirestore$ = new BehaviorSubject<boolean>(false);
model = {};
private skipExternalEmission : boolean = false;
activeFormModelFirestoreDocId: string | null;
hardFormlyReset$ = new Subject();
formlyOptions: FormlyFormOptions = {};
jobInitiallyLoaded$ = new ReplaySubject<boolean>(1);
savedFormHistorySnapshot: boolean = false;

manualChangeDetection$ = new Subject<void>();

loadFormDialogRef: MatDialogRef<PricebookEntryDisplayComponent>;

loadedFromRoute : ReplaySubject<FormModelFirestore> = new ReplaySubject<FormModelFirestore>(1);

 jobDocId: string;
 customerDocId: string | null;
 activeSiteVisitDocId: string | null;

private loggingService: LoggingService = inject(LoggingService);

get formModelFirestore() : FormModelFirestore {
  return this.formlyUtilityService.activeFormModelFirestore;
}

set formModelFirestore(val: FormModelFirestore) {
  this.formlyUtilityService.activeFormModelFirestore = val;
}

get audience(): Audience {
  return this.formlyUtilityService.activeAudience;
}

set audience(val: Audience) {
  this.formlyUtilityService.activeAudience = val;
}

constructor(private formlyUtilityService: FormlyUtilityService,private fb: FormBuilder,private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService,
  private formlyLineItemService: FormlyLineItemService, private builder: FormlyFormBuilder, private jobService: JobService, private formModelFirestoreService: FormModelFirestoreService,
  private formFirestoreService: FormFirestoreService, private authService: AuthenticationService, private settingsService: SettingsService, private priceBookEntryService: PriceBookEntryService,
  private dialog: MatDialog, private remapAssociatedComponentService: RemapAssociatedComponentsService) {
  this.form = this.fb.group({});
  this.workflowStage = this.formlyUtilityService.workFlowStage;
}

onDeployedDestruction() {
  this.patchInTechViewUpdatesInitilized = false;
  this.ngOnInitReplacementInitilized = false;
  this.formlyFields = new BehaviorSubject<FormlyFieldConfig[]>([]);
  this.form = this.fb.group({});
  this.loadedInitialFormModelFirestore$ = new BehaviorSubject<boolean>(false);
  this.model = {};
  this.skipExternalEmission  = false;
  this.activeFormModelFirestoreDocId = null;
  this.hardFormlyReset$ = new Subject();
  this.formlyOptions = {};
  this.jobInitiallyLoaded$ = new ReplaySubject<boolean>(1);
  this.savedFormHistorySnapshot = false;
  this.loadedFromRoute  = new ReplaySubject<FormModelFirestore>(1);
  this.jobDocId = null;
  this.customerDocId= null;
  this.activeSiteVisitDocId= null;
}

ngOnInitReplacement() {
  if (this.ngOnInitReplacementInitilized) {
    return;
  }

  this.initilizeFormFirestoreWorkflowObservables();
  this.ngOnInitReplacementInitilized = true;
  this.formlyUtilityService.triggerReload$.pipe(
    takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
  ).subscribe(() => {
    this.updateFormlyForm(this.formModelFirestore, false, true)
  });

  this.formlyUtilityService.spliceWorkflowSelectedFromBranchingControl$.pipe(
    delayWhen(() =>this.jobInitiallyLoaded$.pipe(filter(x => x === true))),
    tap((x) => this.spliceWorkflowSelectedFromBranchingControl(x)),
    takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
  ).subscribe();


  const sectionFromBranchPopulated = this.formlyUtilityService.removeSectionFromBranch$.pipe(
    map(x => {
      const associatedSectionKey = this.formlyUtilityService.leafKeyFromDocAndBranchAndPriceBookEntryKey(x.sectionDocId, x.branchKey, x.parentId, x.priceBookEntryDocId);
      return {sectionKey: associatedSectionKey, parentId: x.parentId};
    })
  );

  merge(this.formlyUtilityService.removeSectionFromWorkflow$,sectionFromBranchPopulated ).pipe(
    tap(x => console.log(x,` string`)),
    tap((x) => this.removeSectionFromWorkflow(x.sectionKey, x.parentId)),
    takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
  ).subscribe();


  const jobLoad = this.jobService.load$(this.jobDocId).pipe(
    filter(x => x !== null),
    tap(() => this.jobInitiallyLoaded$.next(true))
    );

    const loadFormModelFirestoreRaw$ = new ReplaySubject<FormModelFirestore>(1);

    // Disable / enable form input as needed.
    this.formlyUtilityService.disableFormInput$.pipe(
      distinctUntilChanged(),
      tap(x => x ? this.form.disable() : this.form.enable()),
      takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
    ).subscribe();


    let instantiatedThisTime = false;

    // If workflow is being instantiated for the first time, we ensure that we are using the latest deployed version and update if needed.
    const instantiateWorkflowUpdateFormFirestoreIfNeededSource = this.loadedFromRoute.pipe(
      filter(x => x.instantiatedLatestForm === null && (x.model === undefined || x.model === "") && x.formFirestore!==undefined),
      tap(() => instantiatedThisTime = true),
      mergeMap(formModelFirestore => this.formlyUtilityService.returnLatestFormFirestore(formModelFirestore.formFirestore, this.workflowStage).pipe(map(formFirestore => {
        return {
          formModelFirestore: formModelFirestore,
          formFirestore: formFirestore,
          formFirestoreFormPreUpdate: formModelFirestore.formFirestore.form,
        };
      }))),
      mergeMap(obj => this.formlyUtilityService.updateSectionsIfNeeded(obj.formFirestore, this.workflowStage, obj.formModelFirestore.DocId()).pipe(
        map(() => obj),
        switchMap(obj => this.formModelFirestoreService.retrieveFirestoreBatchString().pipe(
          map(wb => {
            return {val: obj, wb: wb}
          })
        )),
      )),
      share()
      );

      const updateNeeded = instantiateWorkflowUpdateFormFirestoreIfNeededSource.pipe(
        filter(x =>  x.val.formFirestore.form  !== x.val.formFirestoreFormPreUpdate),
        switchMap(x => this.formFirestoreService.retrieveDocId(x.val.formFirestore).pipe(
          map(() => {
            console.log("update needed");
            return x;
          })
        )),
        tap(x => {
          this.loggingService.addLog(`Attempting to update formFirestore : ${x.val.formFirestore.DocId()}`,"FormFirestoreWorkflowComponent.ts" ,
        {curr: x.val.formFirestore.form, prev: x.val.formFirestoreFormPreUpdate, workflowStage: this.workflowStage});
        console.log(x);
        }),
        map(obj => {
          obj.val.formModelFirestore.formFirestoreDocId = obj.val.formFirestore.docId;
          obj.val.formModelFirestore.formFirestore = obj.val.formFirestore;
          return obj;
        }),
        switchMap(obj => this.formFirestoreService.create$(obj.val.formFirestore, obj.wb).pipe(map(() => obj),
        )));

      const noUpdateNeeded = instantiateWorkflowUpdateFormFirestoreIfNeededSource.pipe(
        filter(x => x.val.formFirestore.form  === x.val.formFirestoreFormPreUpdate),
        tap(() => {
          console.log("NO UPDATE NEEDED FORM FIRESTORE! ");
        }),
      );

      const loadMergedUpdateNeededOrNot =merge(updateNeeded, noUpdateNeeded).pipe(
      take(1),
      map(obj => {
        const updatedFormFirestoreModel = new FormModelFirestore({docId: obj.val.formModelFirestore.docId,
          formFirestore: obj.val.formFirestore,
          formFirestoreDocId: obj.val.formFirestore.DocId(),
          instantiatedLatestForm: true,
          formFirestoreSummaryDocIdToInstantiated: obj.val.formModelFirestore.formFirestoreSummaryDocIdToInstantiated,
          model: ""});
        return {val: updatedFormFirestoreModel, wb: obj.wb, formModelFirestore: obj.val.formModelFirestore};
      }),
      mergeMap(obj => this.formModelFirestoreService.mergeUpdate(obj.val, ["_formFirestore", "formFirestoreDocId", "instantiatedLatestForm", "model"], obj.wb, false).pipe(
        map(() => obj)
      )),
      switchMap(x => this.formModelFirestoreService.commitExternallyManagedFirestoreBatch(x.wb).pipe(
        map(() => x.formModelFirestore),
      )),
      take(1),
      switchMap(x => this.formModelFirestoreService.load$(x.DocId())),
      filter(x => x.formFirestore !== undefined),
      share()
      );

      const designUpdate = loadMergedUpdateNeededOrNot.pipe(
        filter(() => this.workflowStage === WORKFLOW_STAGE.DESIGN),
        take(1)
      );

      const deployUpdate = loadMergedUpdateNeededOrNot.pipe(
        filter(() => this.workflowStage === WORKFLOW_STAGE.DEPLOYED),
      );

      merge(designUpdate, deployUpdate).pipe(
        tap(x => loadFormModelFirestoreRaw$.next(x)),
        takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables),
    ).subscribe();


  //If workflow has been instantiated we next raw form model firestore observable w/o checking to ensure it is most current.
  const loadFirst = this.loadedFromRoute.pipe(
    filter(x => !instantiatedThisTime &&  (x.instantiatedLatestForm === true || (x.model !== undefined && x.model !== ""))),
    switchMap(x => this.formModelFirestoreService.load$(x.DocId())),
    tap(x => loadFormModelFirestoreRaw$.next(x))
  );

  merge(loadFirst, this.destroyAllFormFirestoreWorkflowComponentObservables).pipe(
    take(1)
  ).subscribe();

  //If workflow has been instantiated we next raw form model firestore observable w/o checking to ensure it is most current.
  const routeLoad = this.loadedFromRoute.pipe(
    filter(x => !instantiatedThisTime &&  (x.instantiatedLatestForm === true || (x.model !== undefined && x.model !== ""))),
    switchMap(x => this.formModelFirestoreService.load$(x.DocId())),
    debounce(() => this.loadedInitialFormModelFirestore$),
    skip(1),
    share()
  );

  routeLoad.pipe(
     // if update is initilized by instance in question, do not populate as it may no longer be the most up to date.
     filter( x=> x.lastUpdatedByGuid !== this.authService.guid),
    filter(x => x.model !== ""),
    tap(() => this.skipExternalEmission = true),
    tap(x => this.model = JSON.parse(x.model)),
    takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables),
  ).subscribe();



  // We do not want to save the first emission.
  const loadFormModelFirestoreModelPopulated = loadFormModelFirestoreRaw$.pipe(
    filter(f => f.formFirestore && f.formFirestore.form !== undefined),
    take(1),
    tap(f => this.formlyUtilityService.skippedFirstPopulatedEmission = true)
    );

    // If change to form model was triggered by a different user, we want to skip saving the next change to the form.
    loadFormModelFirestoreRaw$.pipe(
      skip(this.formlyUtilityService.workFlowStage === WORKFLOW_STAGE.DESIGN ? 0 : 1),
      filter( x=> x.lastUpdatedByGuid !== this.authService.guid),
      tap(() => this.skipExternalEmission = true),
      tap(f => {
        this.formModelFirestore = f;
      this.updateFormlyForm(f,false);
      }),
      tap(() => this.manualChangeDetection$.next(void 0)),
      takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
      ).subscribe();

  const loadFormModelFirestore =
  this.settingsService.settingsLoaded$.pipe(
    switchMap(() => loadFormModelFirestoreModelPopulated),
    debounce(() => this.jobInitiallyLoaded$),
    tap(f => {
      this.formModelFirestore = f;
      this.updateFormlyForm(f,false);
      if (this.formModelFirestore.previousLastUpdatedAt === null){
        this.formModelFirestore.previousLastUpdatedAt = new Date(this.formModelFirestore.lastUpdatedAt);
        this.formModelFirestore.employeeLoggedInWheLastUpdated = this.formModelFirestore.lastUpdatedByEmployeeDocId;
      }
      this.loadedInitialFormModelFirestore$.next(true);
    }),
    catchError(err => {
    console.log('Error caught in observable.', err);
    return throwError(err);
    })
  );


  const initialLoad = combineLatest([jobLoad, loadFormModelFirestore]).pipe(
    tap(() => this.formlyUtilityService.skippedFirstPopulatedEmission = true),
    catchError(err => {
    console.log('Error caught in observable.', err);
    return throwError(err);
    }));

    initialLoad.pipe(
      take(1)
    ).subscribe();

  if (this.formlyUtilityService.workFlowStage !== WORKFLOW_STAGE.DESIGN) {
    this.initilizeSavingUpdatesToFirestore(this.form);
  }

}

initilizeSavingUpdatesToFirestore(formToObserve: FormGroup) {

  const destroyOrHardReset = merge(this.destroyAllFormFirestoreWorkflowComponentObservables,this.hardFormlyReset$).pipe(
    delayWhen(() => this.formlyUtilityService.activeSaveInPipe$.pipe(filter(x => x === false))),
    tap(() => console.error("DESTROY OR HARD RESET CALLED.")),
    share()
  );

  formToObserve.valueChanges.pipe(
    filter(() => this.skipExternalEmission === true),
    switchMap(() => timer(500).pipe(
      take(1)
    )),
    tap(() => this.skipExternalEmission = false),
    takeUntil(destroyOrHardReset)
  ).subscribe();

  const updateFromFormToObserve = formToObserve.valueChanges.pipe(
    filter(() => this.skipExternalEmission === false),
    // We use model here b/c otherwise hidden controls aren't included (i.e. _id field)
    map(() => JSON.stringify(this.model)),
    filter(x => x !== "{}"),
    filter(x => this.formModelFirestore !== undefined && x !== this.formModelFirestore.model),
    // tap(x => console.error(x)),
    tap(() => this.formlyUtilityService.activeSaveInPipe$.next(true)),
    tap(x => this.formModelFirestore.model = x),
    share()
    ) as Observable<string>;

    const addToPreviousForms = updateFromFormToObserve.pipe(
      filter(() => !this.savedFormHistorySnapshot && this.formlyUtilityService.skippedFirstPopulatedEmission),
        delay(1),
        tap(() => this.savedFormHistorySnapshot = true),
        switchMap(x => this.generateCopyOfForm().pipe(
          map( copyOfForm => {
            return {current: x, copy: copyOfForm};
          }),
        )),
        switchMap(x => this.formModelFirestoreService.retrieveFirestoreBatchString().pipe(
          map(wb => {
            return {...x, wb: wb};
          })
        )),
        mergeMap(x => this.formModelFirestoreService.create$(x.copy, x.wb).pipe(
          map(() => x),
          take(1),
        )),
        tap(x => {
          this.formModelFirestore.previousFormModelFirestoreVersionDocIds.push(x.copy.docId);
            this.formModelFirestore.title = "";
            this.formModelFirestore.previousLastUpdatedAt = new Date();
            this.formModelFirestore.employeeLoggedInWheLastUpdated = this.authService.activelyLoggedInEmployeeDocId;
        }),
        tap(() => console.warn("ACTIVE SAVE IN PIPE.")),
        mergeMap(f => this.formModelFirestoreService.update$(this.formModelFirestore, f.wb).pipe(
          map(() => f.wb),
          take(1)
        ))
        ) as Observable<string>;


    const alreadyAdded = updateFromFormToObserve.pipe(
      filter(() => this.savedFormHistorySnapshot && this.formModelFirestore.model !== undefined),
      switchMap(x => this.formModelFirestoreService.retrieveFirestoreBatchString()),
      tap(() => console.warn("ACTIVE SAVE IN PIPE.")),
      debounceTime(500),
      mergeMap(wb => this.formModelFirestoreService.mergeUpdate(this.formModelFirestore,["model"], wb,true).pipe(
        map(() => wb),
        take(1)
      )),
    );

      merge(addToPreviousForms,alreadyAdded).pipe(
      switchMap(wb => this.formModelFirestoreService.commitExternallyManagedFirestoreBatch(wb)),
      delay(100),
      tap(() => this.formlyUtilityService.activeSaveInPipe$.next(false)),
      tap(x => console.warn("Saved form model firstore to database.")),
      takeUntil(destroyOrHardReset.pipe(tap(x => console.log("WE DESTROYED THE OUTERS!")))),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      }),
      finalize(() => console.log("FINALIZE OUTER DESTRUCTIONS"))
  ).subscribe();
}

generateCopyOfForm(formToGenerateCopyOf :FormModelFirestore = this.formModelFirestore) : Observable<FormModelFirestore> {
  const copyOfForm = new FormModelFirestore({...formToGenerateCopyOf});
  copyOfForm.employeeLoggedInWheLastUpdated = formToGenerateCopyOf.lastUpdatedByEmployeeDocId;
  copyOfForm.previousLastUpdatedAt = formToGenerateCopyOf.lastUpdatedAt;
  copyOfForm.previousForms=[];
  return this.formModelFirestoreService.retrieveDocId(copyOfForm).pipe(
    map(() => copyOfForm)
  );
}

removeSectionFromWorkflow(key: string | null , parentId: string | null) {
  const updated = this.iterateThroughFormlySearchingForKey(this.formlyFields.value, {spliceKey: key, formlyConfig: null, parentId},"Remove");
  if (updated) {
    this.formlyFields.next(this.formlyFields.value);
  }
}

spliceWorkflowSelectedFromBranchingControl(val: {spliceKey: string, parentId: null | string, formlyConfig: FormlyFieldConfig} ) {

  this.formlyUtilityService.setActiveViewAndDisabledTemplateOptionsAllTheWayDown(
    val.formlyConfig,
    "techView",
    false,
    this.audience, undefined, this.formModelFirestore.DocId()
  );
  this.formlyUtilityService.patchInDataLinksIfNeededAllTheWayDown(
    val.formlyConfig,
    new DataUsedByDataLinkService({
      customerDocId: this.customerDocId,
      firstSiteVisitDocId: this.activeSiteVisitDocId,
      activeSiteVisitDocId : this.activeSiteVisitDocId,
      jobId: this.jobDocId,
      invoiceDocId: this.formlyLineItemService.explicitInvoiceDocId,
    })
  );
  const updated = this.iterateThroughFormlySearchingForKey(this.formlyFields.value, val);
  if (updated) {
    this.formlyFields.next(this.formlyFields.value);
  }
}

iterateThroughFormlySearchingForKey(formly: FormlyFieldConfig[] | FormlyFieldConfig, val: {spliceKey: string | null, parentId: string | null,
  formlyConfig: FormlyFieldConfig | null}, removeOrSplice = "Splice") : boolean{

  let retVal = false;
  if (val.parentId === "top_level") {
    const elementIndex = (formly as FormlyFieldConfig[]).findIndex(x => x.key === val.spliceKey);
    if (elementIndex > -1) {
      if (removeOrSplice === "Splice") {
        (formly as FormlyFieldConfig[]).splice(elementIndex+1, 0,val.formlyConfig!);
        console.error(`SPLICED IN:  ${val.formlyConfig!.key} @ ${val.spliceKey}`);
        this.model={...this.model};
      } else if (removeOrSplice === "Remove") {
        (formly as FormlyFieldConfig[]).splice(elementIndex, 1);
        console.error(`REMOVED! ${val.spliceKey}`);
      }
      return true;
    } else {
      return false;
    }
  }

  if (Array.isArray(formly)) {

    for (let formlyConfig of formly) {
      if (formlyConfig.fieldGroup) {
        const sectionIndex = formlyConfig.fieldGroup.findIndex(z => z.key === "_id" && z.model._id === val.parentId);
        if (sectionIndex > -1) {
          if (val.spliceKey === null) {
            if (removeOrSplice === "Remove") {
              const lengthBeforeModification = formlyConfig.fieldGroup.length;
              formlyConfig.fieldGroup = formlyConfig.fieldGroup.filter(x => x.props.generatedFromBranch !== true);
              console.error(`REMOVED ALL sections from branch`);
              return lengthBeforeModification !== formlyConfig.fieldGroup.length;
            } else {
              throw new Error("Splicing entries without specifying key to splice is not supported.");
            }
          } else {
          // Find the index of the specified branch.
          const branchingKey = val.spliceKey.split("0.").length > 1 ? "0.".concat(val.spliceKey.split("0.")[1]) : val.spliceKey;
          if (removeOrSplice === "Splice") {
            const spliceIndex = formlyConfig.fieldGroup.findIndex(z => z.key === branchingKey) + 1;
              formlyConfig.fieldGroup.splice(spliceIndex, 0,val.formlyConfig!);
            console.error(`SPLICED IN:  ${val.formlyConfig!.key} @ ${val.spliceKey} @${val.parentId}`);
            this.model={...this.model};
          } else if (removeOrSplice === "Remove") {
            const spliceIndex = formlyConfig.fieldGroup.findIndex(z => z.key === val.spliceKey);
            if (spliceIndex !== -1) {
              formlyConfig.fieldGroup.splice(spliceIndex, 1);
              console.error(`REMOVED! ${val.spliceKey}`);
            }
          }
          return true;
        }
        } else {
          retVal = this.iterateThroughFormlySearchingForKey(formlyConfig.fieldGroup, val, removeOrSplice);
          if (retVal) {
            return retVal;
          }
        }
      }
    }
  } else {
    return false;
  }
}

initilizeFormFirestoreWorkflowObservables() {
  this.formlyUtilityService.generatePricebookData$.pipe(
    takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
  ).subscribe(() => {
    this.generatePricebookData();
  });

  this.formlyFields.pipe(
    tap(x => this.formlyUtilityService.activeFormlyFieldConfig = x),
    takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables),
    finalize(() => {
      if (this.workflowStage === WORKFLOW_STAGE.DEPLOYED) {
        this.formlyUtilityService.activeFormlyFieldConfig = []
      }
    })
  ).subscribe();

}

generatePricebookData(){
  const retVal = this.formlyUtilityService.generatePricebookData(this.formlyFields.value, this.formModelFirestore.formFirestore.formlySectionPricebookMappings);
  this.priceBookEntryService.loadMultiple$(retVal.map(x => x.priceBookEntryDocId)).pipe(
    map(x => {
      const r : PriceBookEntryOutput[] =[];
      retVal.forEach(y => {
        r.push(new PriceBookEntryOutput({priceBookEntry: x.find(z => z.DocId() === y.priceBookEntryDocId), quantity: y.quantity}));
      });
      return r;
    }),
    map(r => r.filter(x => x.quantity > 0)),
    tap(r => {
      const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      data: r
    });

    this.loadFormDialogRef = this.dialog.open(PricebookEntryDisplayComponent, editorConfig);
    }),
    take(1)
  ).subscribe();
}

patchInTechViewUpdates() {
  if (this.patchInTechViewUpdatesInitilized) {
    return;
  }
  this.patchInTechViewUpdatesInitilized = true;
  const patchDesignToTechView = this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.pipe(
    filter(x => x !== undefined && x !== null && x.form !== undefined),
    debounce(() => merge(this.loadedInitialFormModelFirestore$.pipe(filter(x => x===true)), of(this.loadedInitialFormModelFirestore$.value).pipe(filter(x => x === true)))),
    debounce(() => this.remapAssociatedComponentService.activelyRemappingComponents$.pipe(filter(x => x === false))),
    debounceTime(200),
    tap(() => console.error("PATCH IS GOING THROUGH NOW")),
    tap(() => {
      this.formlyUtilityService.resetSectionMappings();
      this.fetchUpdatedWorkflowService.resetMappings();
    this.form = new FormGroup({});
    this.model = {columns: [{}],};
    }),
    startWith(null),
    pairwise(),
    map(([prev, curr]) => {
      return {val: curr, previousForm: prev?.form};
    }),
    tap(() => this.fetchUpdatedWorkflowService.formModelFirestoreMapping = new Map<string, FormModelFirestore>()),
    tap(() => this.formModelFirestore.resetMappings()),
    switchMap(x => this.formlyUtilityService.updateSectionsIfNeeded(x.val, this.workflowStage, this.formModelFirestore.DocId()).pipe(
      map(() => x),
      take(1),
    )),
    ) as Observable<{val: FormFirestore, previousForm: string}>;


    patchDesignToTechView.pipe(
      tap(x => {
        this.formModelFirestore.formFirestore = x.val;
        this.formModelFirestore.skipSave = true;
      }),
      tap(() => this.updateFormlyForm(this.formModelFirestore, false, false)),
      tap(() => this.formlyUtilityService.updatedFormlyView$.next(null)),
      takeUntil(this.destroyAllFormFirestoreWorkflowComponentObservables)
    ).subscribe();
}

updateFormlyForm(f: FormModelFirestore, disabled: boolean, explictlyRebuild: boolean = false) {
  this.activeFormModelFirestoreDocId = f.DocId();
  if (f.model) {
    this.model = JSON.parse(f.model);
  }
  let parsedForm = JSON.parse(f.formFirestore.form);
  if (!Array.isArray(parsedForm)) {
    parsedForm = (parsedForm as any).fieldArray.fieldGroup;
  }
  parsedForm = parsedForm.filter(x => this.formlyUtilityService.getAudienceFilter(this.audience)(x));
  (parsedForm as FormlyFieldConfig[]).forEach(f => this.formlyUtilityService.patchInDataLinksIfNeededAllTheWayDown(f,
    new DataUsedByDataLinkService(
      {customerDocId: this.customerDocId,
        jobId: this.jobDocId,
        activeSiteVisitDocId : this.activeSiteVisitDocId!,
        invoiceDocId: this.formlyLineItemService.explicitInvoiceDocId})));
  (parsedForm as FormlyFieldConfig[]).forEach(f => this.formlyUtilityService.setActiveViewAndDisabledTemplateOptionsAllTheWayDown(f,"techView",disabled, this.audience,
    this.formlyUtilityService.designMode ? (this.form as AbstractControl<any>) : undefined, this.activeFormModelFirestoreDocId));

  if (explictlyRebuild) {
    console.warn("EXPLICTLY REBUILDING FORM");
    this.hardFormlyReset$.next(true);
    this.formlyUtilityService.resetSectionMappings();
    this.form = new FormGroup({});
    this.formlyFields.next([]);
    this.model = {columns: [{}],};
    this.builder.buildForm(this.form,parsedForm,this.model,this.formlyOptions );
    // this.initilizeSavingUpdatesToFirestore(this.form);
    console.log(this.form);
  }
  // If there is no tick here, the form will not be populated.
  of(null).pipe(
    delay(1),
    tap(x => console.log(parsedForm)),
    tap(() => {
      if (JSON.stringify(this.formlyFields.value) !== JSON.stringify(parsedForm)) {
        this.formlyFields.next(parsedForm);
      }
    }),
    take(1),
  ).subscribe();
}

}
