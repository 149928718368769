import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { PriceBookUnitOfMeasure } from '../../../../common/src/data/dao/pricebook/pricebook-unit-of-measure';
import { PriceBookEntry } from '../../../../common/src/data/dao/pricebook/pricebook-entry';
import { FormlyUtilityService } from './component-models/formly-controls/formly-utility.service';
import { FormFirestoreSummary } from '../../../../common/src/data/dao/form-firestore-summary';
import { PriceBookUnitOfMeasureService } from '../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service';
import { PriceBookEntryService } from '../../../../common/src/data/dao-services/pricebook/pricebook-entry.service';
import { Observable, of, Subject, take, tap, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportWorkflowAssignmentService {

  workflowAssignmentActive: boolean = false;
  selection = new SelectionModel<PriceBookUnitOfMeasure|PriceBookEntry>(true, [],true,(a,b) => a.DocId() === b.DocId());
  externalSelectedWorkflowAssignmensUpdated$ : Subject<null> = new Subject<null>();

  constructor(private formlyUtilityService: FormlyUtilityService, private priceBookUnitOfMeasureService: PriceBookUnitOfMeasureService,
      private priceBookEntryService: PriceBookEntryService){
        this.selection.changed.pipe(
          tap(x => {
          console.log(x,` string`);
          }),
        ).subscribe();
      }

  defineWorkflowWithSection(formSummary: FormFirestoreSummary, priceBookEntry: PriceBookEntry) : Observable<PriceBookEntry>{
    priceBookEntry.formFirestoreSummaryDefiningPriceBookEntry = formSummary;
    return this.priceBookEntryService.update$(priceBookEntry);
  }

  associateWorkflowWithPriceBookEntry(formSummary: FormFirestoreSummary, priceBookEntry: PriceBookEntry) : Observable<PriceBookEntry>{
    priceBookEntry.formFirestoreSummaryContainingPriceBookEntries.push(formSummary);
    return this.priceBookEntryService.update$(priceBookEntry);
  }

  removeWorkflowFromPriceBookEntry(formSummary: FormFirestoreSummary, priceBookEntry: PriceBookEntry) : Observable<PriceBookEntry>{
    priceBookEntry.formFirestoreSummaryContainingPriceBookEntries = priceBookEntry.formFirestoreSummaryContainingPriceBookEntries.filter(x => x.docId !== formSummary.docId);
    return this.priceBookEntryService.update$(priceBookEntry);
  }

  removeAllWorkflowsFromPriceBookEntry(priceBookEntry: PriceBookEntry) : Observable<PriceBookEntry>{
    priceBookEntry.formFirestoreSummaryContainingPriceBookEntries = [];
    return this.priceBookEntryService.update$(priceBookEntry);
  }

  deleteWorkflowAssociationWithPriceBookEntry() : void {
    const updates: Observable<any>[] = [of(null)];
    this.selection.selected.forEach((selected) => {
      if (selected instanceof PriceBookEntry) {
        (selected as PriceBookEntry).formFirestoreSummaryDefiningPriceBookEntry = undefined;
        updates.push(this.priceBookEntryService.update$(selected as PriceBookEntry));
      }
    });
    zip(...updates).pipe(
      take(1)
    ).subscribe();
  }

  associatedWorkflowWithSelections(formSummary: FormFirestoreSummary) {
    const updates: Observable<any>[] = [];
    this.selection.selected.forEach((selected) => {
      if (selected instanceof PriceBookUnitOfMeasure) {
        (selected as PriceBookUnitOfMeasure).formFirestoreSummary = formSummary;
        updates.push(this.priceBookUnitOfMeasureService.update$(selected as PriceBookUnitOfMeasure));
      } else {
        (selected as PriceBookEntry).formFirestoreSummaryContainingPriceBookEntries.push(formSummary);
        updates.push(this.priceBookEntryService.update$(selected as PriceBookEntry));
      }
    });
    zip(...updates).pipe(
      take(1)
    ).subscribe();
  }

}
