import { RetrieveFirestorePropertiesBase } from "../../database-backend/retrieve-firestore-properties";
import { PriceBookEntry } from "./pricebook-entry";

export class PriceBook extends RetrieveFirestorePropertiesBase<PriceBook>  {
  title: string;
  fileName: string;
  immutable: boolean = true;
  active: boolean = true;
  validated: boolean = false;
  priceBookEntries: PriceBookEntry[];
  priceBookEntryDocIds: string[];
  importedLastDate: Date;
  vanguardUrl: string;


  public constructor(init?: Partial<PriceBook>) {
    super();
    Object.assign(this, init);
  }

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["priceBookEntries"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
  .concat(["priceBookEntryDocIds"]);
  static _firestoreCompositionMemberNames = ["priceBookEntries"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return PriceBook._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return PriceBook._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return PriceBook._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return PriceBook._firestoreCompositionMemberNames;}

}
