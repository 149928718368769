import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { FormCatagory } from '../../../../../common/src/data/dao/form-catagory';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { FORM_TYPE, FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { FirestoreBackend } from '../../../../../common/src/data/database-backend/retrieve-from-firestore';
import { where } from 'firebase/firestore';


export enum WorkflowSelectionNodeType {
  CATAGORY = "catagory",
  WORKFLOW = "workflow"
}

export class WorkflowSelectionTreeStructure {
  name: string;
  key: string;
  parentCatagoryKey: string;
  formCatagory: FormCatagory | FormFirestoreSummary ;
  description: string = "";
  type: WorkflowSelectionNodeType;

  get classDecoration() : any {
    if (this.type === WorkflowSelectionNodeType.CATAGORY) {
      return { 'catagory': true};
    } else {
      return { 'workflow': true};
    }
  }

  constructor(init?: Partial<WorkflowSelectionTreeStructure>) {
    Object.assign(this, init);
    if ( (this.formCatagory as FormFirestoreSummary).formType !== undefined) {
      const asFormFirestoreSummary = this.formCatagory as FormFirestoreSummary;
      this.name = asFormFirestoreSummary.title;
      this.parentCatagoryKey = asFormFirestoreSummary.formCatagory.DocId();
      this.type = WorkflowSelectionNodeType.WORKFLOW;
    } else {
      const asFormCatagory = this.formCatagory as FormCatagory;
      this.name = asFormCatagory.name;
      this.parentCatagoryKey = (asFormCatagory.DocId() !== "JQUdbMAbc4pIA8PILCAC" && asFormCatagory.parentDocId === "") ? "JQUdbMAbc4pIA8PILCAC"  : asFormCatagory.parentDocId ;
      this.type = WorkflowSelectionNodeType.CATAGORY;
    }
    this.key = this.formCatagory.DocId();
  }
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowSelectionTreeService {

  topLevelTreeStructureNodes: WorkflowSelectionTreeStructure[] = [];
  allNodes: BehaviorSubject<WorkflowSelectionTreeStructure[]> = new BehaviorSubject<WorkflowSelectionTreeStructure[]>([]);
  selectedNodes: BehaviorSubject<WorkflowSelectionTreeStructure[]> = new BehaviorSubject<WorkflowSelectionTreeStructure[]>([]);
  rootNode: BehaviorSubject<WorkflowSelectionTreeStructure> = new BehaviorSubject<WorkflowSelectionTreeStructure>(undefined);

  updateSelectedNodes(selectedNodes: (FormCatagory | FormFirestore)[]) {
    this.selectedNodes.next(this.allNodes.value.filter(x => selectedNodes.some(z => z.DocId() === x.key)));
  }

  setSelectedNodes(formFirestoreDocIds: string[]) {
    this.selectedNodes.next(this.allNodes.value.filter(x => formFirestoreDocIds.some(z => z === x.key)));
  }

  constructor(private formCatagoryService: FormCatagoryService, private formFirestoreService: FormFirestoreService,
    private settingsService: SettingsService, private formFirestoreSummaryService: FormFirestoreSummaryService) {

    const loadActiveFormCatagories = this.formCatagoryService.queryFirestoreDeep$([where("active", '==', true)]);
    const loadActiveFormFirestoreSummaries = this.formFirestoreSummaryService.queryFirestoreDeep$([where("active", '==', true), where("formType", "!=", FORM_TYPE.TOP_LEVEL)]);


    combineLatest([loadActiveFormCatagories, loadActiveFormFirestoreSummaries]).pipe(
      map(([formCatagories, formFirestoreSummaries]) => {
        const retVal :WorkflowSelectionTreeStructure[] = [];
        retVal.push(...formCatagories.map(x => new WorkflowSelectionTreeStructure({formCatagory: x})));
        retVal.push(...formFirestoreSummaries.filter(x => x.formType !== undefined && x.formCatagory !== null).sort((a,b) => b.createdAt.getTime() - a.createdAt.getTime()).map(x =>  new WorkflowSelectionTreeStructure({formCatagory: x})));
        return retVal;
      }),
      takeUntil(FirestoreBackend.destroyingComponent$),
      ).subscribe(this.allNodes);

    this.formCatagoryService.load$(this.settingsService.getValue('defaultWorkCatagoryDocId')).pipe(
      map(x => new WorkflowSelectionTreeStructure({formCatagory: x})),
      tap(x => this.rootNode.next(x)),
      takeUntil(FirestoreBackend.destroyingComponent$)).subscribe();

  }
}
