import { RetrieveFirestorePropertiesBase } from "../../database-backend/retrieve-firestore-properties";
import { FormFirestoreSummary } from "../form-firestore-summary";

export class PriceBookUnitOfMeasure extends RetrieveFirestorePropertiesBase<PriceBookUnitOfMeasure>  {

  code: string;
  formFirestoreSummaryDocId: string;
  formFirestoreSummary: FormFirestoreSummary;
  title: string;
  notes: string;
  importLastUpdated: Date;

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames;
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["formFirestoreSummaryDocId"]);
  static _firestoreCompositionMemberNames = ["formFirestoreSummary"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["formFirestoreSummary"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return PriceBookUnitOfMeasure._firestoreIgnoredMemberNames};
  retrievefirestoreIgnoreDiffTrackingMembers() : string[] {return PriceBookUnitOfMeasure._firestoreIgnoreDiffTrackingMembers;}
  retrievefirestoreCompositionMemberNames() { return PriceBookUnitOfMeasure._firestoreCompositionMemberNames; }
  retrievefirestoreCompositionalDiffMemberNames() { return PriceBookUnitOfMeasure._firestoreCompositionalDiffMemberNames; }

  public constructor(init?: Partial<PriceBookUnitOfMeasure>) {
    super();
    Object.assign(this, init);
  }

}
