import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { PriceBookEntry } from '../../data/dao/pricebook/pricebook-entry';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class PriceBookEntryOutput {
  priceBookEntry: PriceBookEntry;
  quantity: number;

  public constructor(init?: Partial<PriceBookEntryOutput>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'pricebook-entry-display',
  templateUrl: './pricebook-entry-display.component.html',
  styleUrls: ['./pricebook-entry-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricebookEntryDisplayComponent  {

  // columnsToDisplay: string[] = ["Catagory","Title","Quantity","Total Cost","Retail","Doc Id"];
  columnsToDisplay: string[] = ["Catagory","Title","Quantity","Total Cost","Retail"];
  importedDataSource = new MatTableDataSource<(any)>();

  columnsToDisplayStandardText = this.columnsToDisplay.filter(x => x !== "Total Cost" && x !== "Retail");
  columnsToDisplayCurrency = ["Total Cost", "Retail"];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    const d = (data as PriceBookEntryOutput[]);
    this.importedDataSource.data = d.map(x => {
      return {"Catagory" : x.priceBookEntry.priceBookCatagory.name, "Title": x.priceBookEntry.title,
      "Quantity": x.quantity, "Total Cost": x.priceBookEntry.generateTotalCost(x.quantity), "Retail": x.priceBookEntry.generateRetail(x.quantity), "Doc Id": x.priceBookEntry.DocId()};
  });
  }


  }
