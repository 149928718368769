<mat-dialog-content class="modify-employees-schedule-grid">
  <div class="header-label" style="padding-top: 30px;">Workflow Deployment</div>

<mat-dialog-content class="mat-typography" style="display: contents;">
  <div style="grid-column: 1/3; font-size: 18px; font-weight: bold; padding: 10px;">The Workflow "{{data.workflow.title}}" Contains Undeployed Sub Workflows</div>
  <div style="grid-column: 1/3; padding: 10px;">The current design view of workflow contains sub sections which have updates which have never been deployed.
    If you proceed to deploy {{data.workflow.title}} without deploying sub workflows listed below, when workflow is
    loaded it will either reference the deployed version of sub-workflow (if it is has been deployed) or the workflow
    will be missing entierly (if it has never been deployed).  For most use cases, you likely want to deploy all, which
    ensures that the workflow loaded by employees will match the workflow which is displayed in the design view.
  </div>
  <div style="grid-column: 1/3; font-size: 18px; font-weight: bold; padding: 10px;">Subsections</div>
  <mat-list style="grid-column: 1/3;" role="list" *ngFor="let workflow of data.outOfSyncWorkflows">
    <mat-list-item role="listitem">
      {{workflow.title}}  -  {{WorkflowState(workflow)}}
    </mat-list-item>
  </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="grid-column: 1/3; padding: 0px;">
    <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="grid-column: 1/-1; width: 100%; padding-top: 20px;">
    <div>
      <button mat-button mat-dialog-close class="button-gray">Cancel</button>
    </div>
    <div class="button-alignment">
    <div style="margin-right: 20px;">
      <button mat-button mat-dialog-close="DEPLOY_ONLY" class="button-light-blue">Deploy Only "{{data.workflow.title}}"</button>
    </div>
    <div>
      <button mat-button mat-dialog-close="DEPLOY_ALL" cdkFocusInitial class="button-blue">Deploy All</button>
    </div>
  </div>
    </div>
  </mat-dialog-actions>
</mat-dialog-content>