import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { combineLatest,  Observable,  of,  Subject } from 'rxjs';
import { distinctUntilChanged, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { CommonControlContainerFormGroup, ControlContainerComponent } from '../component-models/control-container.component';
import { ControlReportsOnViewInitilizationInterface } from '../containsComponents';


@Component({
  selector: 'app-photo-adder',
  templateUrl: './photo-adder.component.html',
  styleUrls: ['./photo-adder.component.scss']
})
export class PhotoAdderComponent extends ControlContainerComponent implements AfterViewInit, ControlReportsOnViewInitilizationInterface {

  afterViewInitilized$: Subject<boolean> = new Subject<boolean>();

  patchControlComponentsToFormlyFields(): void {
    const obs$ = this.patchControlComponentsToFormlyFieldsCommon(this.fields as FormlyFieldConfig[]);
    const fieldList = ["label","minimumPhotoCount","maximumPhotoCount"];
    fieldList.forEach(f => {
      obs$.push(this.componentForm.get(f).valueChanges.pipe(
        startWith(this.componentForm.get(f).value),
        tap(x => (this.fields[0] as FormlyFieldConfig).props[f] = x),
      ))
    });

    obs$.push(
      this.form.get("indexInParentContainer").valueChanges.pipe(
        startWith(this.form.get("indexInParentContainer").value),
        tap(x => (this.fields[0] as FormlyFieldConfig).props.indexInParentContainer = x)
      ));

    this.componentForm.get("maximumPhotoCount").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.componentForm.controls["minimumPhotoCount"].setValidators([Validators.min(0),Validators.max(x)])),
      tap(() => this.componentForm.controls["minimumPhotoCount"].updateValueAndValidity()),
      takeUntil(this.destroyingComponent$)).subscribe();

    this.componentForm.get("minimumPhotoCount").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.componentForm.controls["maximumPhotoCount"].setValidators([Validators.min(x), Validators.max(40)])),
      tap(() => this.componentForm.controls["maximumPhotoCount"].updateValueAndValidity()),
      takeUntil(this.destroyingComponent$)).subscribe();

    combineLatest([...obs$]).pipe(
      tap(() => (this.fields[0] as FormlyFieldConfig).props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Photo Adder");
    retVal.patchValue({
      icon: "add_a_photo",
      iconColor: "#15cadc",
      controlComponentFormGroup: this.createPhotoAdderFormGroup(),
      ControlRequiresFullWidth: true,
    });
    return retVal;
  }

  createPhotoAdderFormGroup() {
    return this.fb.group({
      label:  "Add Photos",
      minimumPhotoCount: [0,[Validators.min(0),Validators.max(4)]],
      maximumPhotoCount: [4,[Validators.min(1), Validators.max(40)]]
  })}

  fixIgnoredValidationErrors(temps: FormlyTemplateOptions) {
    if (temps.minimumPhotoCount === undefined ||  temps.minimumPhotoCount < 0) {
      temps.minimumPhotoCount=0;
     } else if (temps.minimumPhotoCount > temps.maximumPhotoCount) {
      temps.minimumPhotoCount = temps.maximumPhotoCount;
     }

     if (temps.maximumPhotoCount === undefined) {
       if (temps.minimumPhotoCount > 0) {
        temps.maximumPhotoCount = temps.minimumPhotoCount;
       } else {
        temps.maximumPhotoCount = 1;
       }
     }

     if (temps.maximumPhotoCount > 40) {
      temps.maximumPhotoCount = 40;
     } else if (temps.maximumPhotoCount < 1) {
      temps.maximumPhotoCount=1;
     }
  }

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig | FormlyFieldConfig[] {
    const retVal = (this.toFormlyFieldConfig() as FormlyFieldConfig[])[0];
     //Json only representation strips out formControl, and changeDetection subject from props.
     const {formControl, ...newObj} = retVal;
     const {changeDetect,destroyComponents$,...temps} = retVal.props;
     this.fixIgnoredValidationErrors(temps);
     newObj.props = temps;
     newObj.wrappers = [];
    return newObj;
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    const {formControl, ...newObj} = (this.fields as FormlyFieldConfig);
    newObj[0].props.indexInParentContainer = this.form.get("indexInParentContainer").value;
    return newObj;
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {
    super.patchCommonFieldsToForm(formlyConfig);
    for (var prop in formlyConfig.props) {
      if (this.componentForm.controls[prop] !== undefined) {
        this.componentForm.controls[prop].patchValue(formlyConfig.props[prop]);
      }
    }
  }

  constructor(protected fb: UntypedFormBuilder)
  {
    super(PhotoAdderComponent,fb);
    this.form = this.initilizeFormGroup();
    this.fields =
      [{
        key: `${this.perInstance}-photoAdderComponent`,
        type: 'formlyPhotoAdder',
        props: {
          label: "Add Photos",
          minimumPhotoCount: 0,
          maximumPhotoCount: 4,
          changeDetect: new Subject<any>(),
          destroyComponents$: new Subject<any>(),
          indexInParentContainer: 0,
          guid: this.guid,
        },
        defaultValue: "",
        wrappers : ["change-detect"]
      } as FormlyFieldConfig
    ];

    // Directly emit clicks as there is no parent component.
    this.unfilteredComponentClicked$.pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(this.filteredComponentClicked$);

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.patchControlComponentsToFormlyFields();
    this.form.patchValue({triggerUpdate: {}});

    // Directly emit clicks as there is no parent component.
    this.unfilteredComponentClicked$.pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(this.filteredComponentClicked$);

      of(true).pipe(take(1)).subscribe(this.afterViewInitilized$);
  }



}
