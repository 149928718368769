// tslint:disable: max-line-length

import resourceHeaderRenderer from './resourceHeaderRenderer';
import { addHours, addMinutes, addSeconds, differenceInMinutes, format, getHours, getMinutes, roundToNearestMinutes, startOfDay, subHours, subMinutes, subSeconds } from 'date-fns';
import { BryntumResourceDateSummation } from '../../../../common/src/bryntum/bryntum-resource';
import {Injector} from '@angular/core';
import { ArrivalWindowDressingService } from '../scheduling/arrival-window-dressing.service';
import { BryntumSettingsService } from '../settings/bryntum-settings.service';
import { MinimizeSiteVisitMutationService } from '../scheduling/minimize-site-visit-mutation.service';
import { ScrollManager } from 'bryntum-scheduler/scheduler.umd.js';

const injector = Injector.create({
  providers: [
    {provide: ArrivalWindowDressingService},
    {provide: BryntumSettingsService},
    {provide: MinimizeSiteVisitMutationService}
  ]
});

const arrivalWindowDressingService = injector.get(ArrivalWindowDressingService);
const bryntumSettingsService = injector.get(BryntumSettingsService);
const minimizeSiteVisitMutationService = injector.get(MinimizeSiteVisitMutationService);


function mutateDateTimeToToday(date: Date) {
   return addMinutes(startOfDay(new Date()), getMinutes(date) + getHours(date) * 60);
}

function roundedDate (date: Date) {
  // const minutes = Math.round(date.getMinutes() / environment.minutesFidelitySchedulingArrivalTimes)  * environment.minutesFidelitySchedulingArrivalTimes;
  const minutes = Math.round(date.getMinutes() / bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes"))  *
  bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes");
  return addMinutes(subSeconds(date, date.getMinutes()* 60 + date.getSeconds()), minutes);
};

function estimatedStartTimeDisplayString (date: Date) {
  return `${format(roundedDate(date), 'h:mm')}`;
};

function bryntumDateToDate (a: Date) {
  return addMinutes(addHours(startOfDay(BryntumResourceDateSummation.verticalDate), getHours(a)),getMinutes(a));
}

function arrivalWindowDisplayString (date: Date, resourceRecord: any)  {
const arrivalWindow = arrivalWindowDressingService.regenerateArrivalWindow (bryntumDateToDate(date), resourceRecord.siteVisitBooking,
resourceRecord.employeeWorkStartTime, resourceRecord.employeeWorkEndTime,  BryntumSettingsService.configuration);
return  `${format(arrivalWindow.arrivalWindowStartDate, 'h:mm')}-${format(arrivalWindow.arrivalWindowEndDate, 'h:mm')}`;
};


function currentResourceTimeRange (date: Date, resourceRecord: any) {
  let currentDragDate = date;

  if (currentDragDate === null) {
    return undefined;
  }
  const minutes = Math.round(currentDragDate.getMinutes() /  bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes"))  *
  bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes");

  currentDragDate = roundToNearestMinutes(currentDragDate);
  currentDragDate = addMinutes(subMinutes(currentDragDate, currentDragDate.getMinutes()), minutes);
  return resourceRecord.timeRanges
  .find(x => x.data.commuteMinutesDelta !== undefined &&  x.startDate.getTime() <= currentDragDate.getTime() && x.endDate.getTime() >= currentDragDate.getTime());
};

function estimatedCommuteTime (date: Date, resourceRecord: any) {
  const resourceTimeRange = currentResourceTimeRange(date, resourceRecord);
  if (resourceTimeRange === undefined) {
    return "Invalid";
  } else {
    return `${resourceTimeRange.data.commuteMinutesDelta} Commute min. added`;
  }
};

function siteVisitNumber (date: Date, resourceRecord: any) {
  const resourceTimeRange = currentResourceTimeRange(date, resourceRecord);
  if (resourceTimeRange === undefined) {
    return "n/a";
  } else {
    return `Visit # ${resourceTimeRange.data.siteVisitNumber}`;
  }
};

export function initilizeSchedulerConfig() {

  return {
    id            : 'scheduler1',
    rowHeight     : 60,
    minHeight     : '20em',
    selectedEvent : '',

    timeResolution : bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes"),
    startDate     : mutateDateTimeToToday(subHours(bryntumSettingsService.getValue("businessStartHour"), bryntumSettingsService.getValue("hoursBeforeBusinessStartTimeToDisplay") )),
    endDate       : mutateDateTimeToToday(addHours(bryntumSettingsService.getValue("businessEndHour"), bryntumSettingsService.getValue("hoursAfterBusinessEndTimeToDisplay") )),
    eventColor    : 'green',
    eventStyle    : 'border',
    mode          : 'vertical',
    resourceMargin: 2,
    // mode             : 'horizontal',
    useInitialAnimation: 'slide-from-left',

    viewPreset: {
      base : 'hourAndDay',
      displayDateFormat : 'h:mm a',
    },

    eventResizeFeature : {
      validatorFn({ eventRecord : task, endDate, startDate }) {
          const minimumSiteVisitPlusCommuteTime = task._prospectiveEvent ? bryntumSettingsService.getValue("minimumSiteVisitLengthMinutes") :
            task.commuteMinutesSiteToShop + task.commuteMinutesToSite + bryntumSettingsService.getValue("minimumSiteVisitLengthMinutes");
          const isValid = differenceInMinutes(endDate, startDate) >= minimumSiteVisitPlusCommuteTime;
          const validationMessage = isValid ? '' : `You can not reduce site visit time below commute time plus
            ${bryntumSettingsService.getValue("minimumSiteVisitLengthMinutes")} minutes on site.`;
          return {
            valid : isValid,
            message: {validationMessage, startDate, endDate, commuteMinutesSiteToShop: task.commuteMinutesSiteToShop, commuteMinutesToSite: task.commuteMinutesToSite }
          };
      },
      tipTemplate : (data) =>  {
        const eventStartTime = addMinutes(data.message.startDate, data.message.commuteMinutesToSite);
        const eventEndTime = subMinutes(data.message.endDate, data.message.commuteMinutesSiteToShop);
        const invalidValidTipClass = data.valid ? "b-sch-tip-valid" : "b-sch-tip-invalid";
        return `<div class="b-sch-tooltip-enddate"></div>
        <div class=${invalidValidTipClass}>
          <dd>${format(eventStartTime, 'h:mm aaaa')}</dd>
          <dd>${format(eventEndTime, 'h:mm aaaa')}</dd>
          <div class=b-sch-tip-message>${data.message.validationMessage}</div>
          </div>
          `;
          // Default Bryntum function, added here for posterity.
          // return "\n                <div class=\"b-sch-tip-".concat(data.valid ? 'valid' : 'invalid', "\">\n                    ").concat(data.startClockHtml, "\n                    ").concat(data.endClockHtml, "\n                    <div class=\"b-sch-tip-message\">").concat(data.message, "</div>\n                </div>\n            ");
      },
    },

    eventTooltipFeature: {
      template : ({ eventRecord }) => {
        let retVal =
        `<dl>
             <dd>${eventRecord.name}</dd>
             <dd>${eventRecord.addressString}</dd>
              <dd>
                 ${format(eventRecord.actualStartDate,'h:mm')} - ${format(eventRecord.actualEndDate,'h:mm')}
             </dd>
      `;
      if (eventRecord._prospectiveEvent) {
        retVal += `<dd>
        ${eventRecord.arrivalWindowString}
        </dd>`;
      }
      if (eventRecord.data.errored || eventRecord.data.lockedEventStartTime || eventRecord.data.lockedEventOrderNext || eventRecord.data.lockedEventOrderPrevious) {
        const adders = [];
        if (eventRecord.data.lockedEventStartTime) {
          adders.push('Start Time Locked');
        }
        if (eventRecord.data.lockedEventOrderPrevious) {
          adders.push('Site visit order locked with previous visit');
        }
        if (eventRecord.data.lockedEventOrderNext) {
          adders.push('Site visit order locked with next visit');
        }
        if (eventRecord.data.errored) {
          adders.push('Unrunnable Schedule');
        }
        retVal += `<dd>
        ${adders.join('</dd><dd>')}</dd>`;
      }
      retVal += `</dl>`;
      return retVal;
      }
   },

    resourceTimeRangeFeature: {

   },

    eventDragFeature: {
      validatorFn({ context, event }) {
          const isValid = isNaN(context.startDate);
          return {
            valid : isValid,
            message: isValid ? '' : `Site can not be moved outside the schedule!`
          };
        },

      getTipHtml(event: any) {

        const dateToBryntumDate = (date) => addMinutes(addHours(BryntumResourceDateSummation.verticalDate,getHours(date)),getMinutes(date));

        const yValueStartEvent = () => event.event.y - this.dragData.pointerOffsetStart;

        const roundedDate = () => {
          const schedulerDateFromYValueStartEvent = this.scheduler.getDateFromCoordinate(yValueStartEvent());
          // minutes rounded to 5 minute fidelity.
          const minutes = Math.round( (schedulerDateFromYValueStartEvent.getSeconds() >= 30 ? schedulerDateFromYValueStartEvent.getMinutes() + 1:
          schedulerDateFromYValueStartEvent.getMinutes()) / bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes"))  *
          bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes");
          const retVal = addMinutes(startOfDay(schedulerDateFromYValueStartEvent), schedulerDateFromYValueStartEvent.getHours()*60 + minutes);
          return retVal;
      };

      const arrivalWindowDisplayString = () =>  {
        if (this.dragData.arrivalWindowDisplayString === undefined) {
            this.dragData.arrivalWindowDisplayString = `${format(this.dragData.arrivalWindowStart, 'h:mm')}-${format(this.dragData.arrivalWindowEnd, 'h:mm')}`;
        }
        return this.dragData.arrivalWindowDisplayString;
      };

        const estimatedCommuteTime = () => {
              let dateToConsider = startOfDay(this.dragData.newResource.resourceDate);
              const schedulerDate = roundedDate();
              dateToConsider = addHours(dateToConsider, getHours(schedulerDate));
              dateToConsider = addMinutes(dateToConsider, getMinutes(schedulerDate));
              const commuteDelta = minimizeSiteVisitMutationService.getCommuteDelta(dateToConsider, this.dragData.newResource.data.actualResourceId);
              if (commuteDelta === undefined) {
                return "Invalid"
              } else {
                return `${commuteDelta} Commute min. added`;
              }
        };

        const siteVisitNumber = () => {
              let dateToConsider = startOfDay(this.dragData.newResource.resourceDate);
              const schedulerDate = roundedDate();
              dateToConsider = addHours(dateToConsider, getHours(schedulerDate));
              dateToConsider = addMinutes(dateToConsider, getMinutes(schedulerDate));
              const svNumber = minimizeSiteVisitMutationService.getSiteVisitNumberDragging(dateToConsider, this.dragData.newResource.data.actualResourceId);
              if (svNumber === undefined) {
                return "Invalid"
              } else {
                return `Visit # ${svNumber}`;
              }
        };

        // Initilize Data
        if (this.dragData.arrivalWindowStartPixelUnmutated === undefined) {
          this.dragData.activeResource = this.dragData.newResource;
          this.dragData.originalResourceDate = true;
          this.dragData.pointerOffsetStart = this.dragData.draggedRecords[0].event.startingY - this.scheduler.getCoordinateFromDate(this.dragData.draggedRecords[0].event.startDate);
          const halvedFidelity = bryntumSettingsService.getValue("minutesFidelitySchedulingArrivalTimes")/2;
          const secondsOfFidelity = halvedFidelity === Math.floor(halvedFidelity) ? 0 : 30;
          this.dragData.supportedFidelityPixelOffset = this.scheduler.getCoordinateFromDate(addSeconds(this.dragData.draggedRecords[0].event.startDate,
            Math.floor(halvedFidelity) * 60 + secondsOfFidelity)) - this.scheduler.getCoordinateFromDate(this.dragData.draggedRecords[0].event.startDate);

          this.dragData.arrivalWindowStartPixelUnmutated = this.scheduler.getCoordinateFromDate(dateToBryntumDate(
            new Date(this.dragData.draggedRecords[0].event._arrivalWindowStartDate.getTime())));
          this.dragData.arrivalWindowEndPixelUnmutated = this.scheduler.getCoordinateFromDate(dateToBryntumDate(
            new Date(this.dragData.draggedRecords[0].event._arrivalWindowEndDate.getTime())));
          this.dragData.arrivalWindowStartUnmutated = new Date(this.dragData.draggedRecords[0].event._arrivalWindowStartDate.getTime());
          this.dragData.arrivalWindowEndUnmutated = new Date(this.dragData.draggedRecords[0].event._arrivalWindowEndDate.getTime());


          this.dragData.arrivalWindowStartPixel = this.scheduler.getCoordinateFromDate(dateToBryntumDate(new Date(this.dragData.draggedRecords[0].event._arrivalWindowStartDate.getTime())));
          this.dragData.arrivalWindowEndPixel = this.scheduler.getCoordinateFromDate(dateToBryntumDate(new Date(this.dragData.draggedRecords[0].event._arrivalWindowEndDate.getTime())));
          this.dragData.arrivalWindowStart = new Date(this.dragData.draggedRecords[0].event._arrivalWindowStartDate.getTime());
          this.dragData.arrivalWindowEnd = new Date(this.dragData.draggedRecords[0].event._arrivalWindowEndDate.getTime());
        }

        // Determine if we need to regenerate arrival windows.
        let generateFreshTimeWindow = false;
        let regenerate = false;

          // When resource boundry is crossed, we need to confirm dates.
        if (this.dragData.newResource !== this.dragData.activeResource) {
          if (this.dragData.newResource.resourceDate.getTime() !== this.dragData.activeResource.resourceDate.getTime()) {
            generateFreshTimeWindow = true;
            this.dragData.originalResourceDate = this.dragData.newResource.resourceDate.getTime() === this.dragData.resourceRecord.resourceDate.getTime();
          }
          this.dragData.activeResource = this.dragData.newResource;
        }

        // If outside the unmuated time window, we always revert to unmuated window if possible.
        if (this.dragData.originalResourceDate && this.dragData.arrivalWindowStartPixelUnmutated !== this.dragData.arrivalWindowStartPixel
          && yValueStartEvent() >= this.dragData.arrivalWindowStartPixelUnmutated
          && yValueStartEvent() <= this.dragData.arrivalWindowEndPixelUnmutated) {
            this.dragData.arrivalWindowStart = this.dragData.arrivalWindowStartUnmutated;
            this.dragData.arrivalWindowEnd = this.dragData.arrivalWindowEndUnmutated;
            regenerate = true;
        } else {
          // If time window hasn't triggered mutation.
          if (this.dragData.arrivalWindowStartPixelUnmutated === this.dragData.arrivalWindowStartPixel && this.dragData.originalResourceDate) {
            // If moving from unmuated -> mutated
              if (yValueStartEvent() < this.dragData.arrivalWindowStartPixel || yValueStartEvent() > this.dragData.arrivalWindowEndPixel) {
                generateFreshTimeWindow = true;
              }
          } else {
              // If mutated, update every chosen fidelity minutes of movement.
              if (yValueStartEvent() > this.dragData.arrivalWindowStartPixel + this.dragData.supportedFidelityPixelOffset || yValueStartEvent() <
              this.dragData.arrivalWindowStartPixel - this.dragData.supportedFidelityPixelOffset) {
              generateFreshTimeWindow = true;
              }
            }
        }

        if (generateFreshTimeWindow) {
          if ( !isNaN(dateToBryntumDate(this.scheduler.getDateFromCoordinate(yValueStartEvent())).getTime())) {

        const arrivalWindow = arrivalWindowDressingService.regenerateArrivalWindow (dateToBryntumDate(this.scheduler.getDateFromCoordinate(yValueStartEvent())), this.dragData.siteVisit,
        this.dragData.activeResource.employeeWorkStartTime, this.dragData.activeResource.employeeWorkEndTime,  BryntumSettingsService.configuration);


          this.dragData.arrivalWindowStart = arrivalWindow.arrivalWindowStartDate;
          this.dragData.arrivalWindowEnd = arrivalWindow.arrivalWindowEndDate;
          regenerate = true;
          }
        }

        if (regenerate) {
          this.dragData.arrivalWindowStartPixel = this.scheduler.getCoordinateFromDate(dateToBryntumDate(this.dragData.arrivalWindowStart));
          this.dragData.arrivalWindowEndPixel = this.scheduler.getCoordinateFromDate(dateToBryntumDate(this.dragData.arrivalWindowEnd));
          this.dragData.arrivalWindowDisplayString = undefined;
        }

        const roundDate = roundedDate();
        this.dragData.draggedRecords[0].event['roundedDate'] = roundDate;

        return `<dd class="start-time-tooltip">${siteVisitNumber()}</dd>
                <dd class="start-time-tooltip">${`${format(roundDate, 'h:mm')}`}</dd>
                <dd class="arrival-window-tooltip">${arrivalWindowDisplayString()}</dd>
                <dd>${estimatedCommuteTime()}</dd>`;
      }
   },

   eventDragCreateFeature: {},
  timeRangesFeature : {
      showCurrentTimeLine : true,
  },

  schedulerContextMenuFeature: {
    items : {
      addEvent: {
        text: 'Modify working hours'
      },
      showGeofenceEntries: {
        text: 'Show Geofence Entries'
      },
      showCurrentLocation: {
        text: 'Last Known Location'
      },
      earliestStart: {
        text: 'Earliest start'
      },
      latestStart: {
        text: 'Latest start'
      },
      lockSiteVisitOrder: {
        text: 'Lock All Site Visit Order',
        icon   : 'b-fa b-fa-fw b-fa-lock',
      },
      unlockSiteVisitOrder: {
        text: 'Unlock All Site Visit Order',
        icon   : 'b-fa b-fa-fw b-fa-unlock',
      },
    },
    processItems({date, resourceRecord, items}) {
    }
  },

  eventContextMenuFeature: {
    items: {
      lockArrivalTime: {
        text: 'Lock arrival time',
        icon   : 'b-fa b-fa-fw b-fa-lock',
      },
      unlockArrivalTime: {
        text: 'Unlock arrival time',
        icon   : 'b-fa b-fa-fw b-fa-unlock',
      },
      lockPreceedingSiteVisitOrder: {
        // html: `<div><i class="material-icons" style="font-size: 14px; margin-right: 2px">directions_car</i>bunky</div>`,
        // html: 'Group w/ Previous',
        text: 'Group w/ Previous',
        icon: 'b-fa b-fa-fw b-fa-arrow-up'
      },
      unlockPreceedingSiteVisitOrder: {
        text: 'Ungroup w/ Previous',
        icon: 'b-fa b-fa-fw b-fa-arrow-up'
      },
      lockSucceedingSiteVisitOrder: {
        text: 'Group w/ Next',
        icon: 'b-fa b-fa-fw b-fa-arrow-down'
      },
      unlockSucceedingSiteVisitOrder: {
        text: 'Ungroup w/ Next',
        icon: 'b-fa b-fa-fw b-fa-arrow-down'
      },
      deleteEvent: false,
      unassignEvent: false,
    },
    processItems({ eventRecord, items }) {
      if (eventRecord.data.lockedEventStartTime) {
        items.lockArrivalTime = false;
      } else {
        items.unlockArrivalTime = false;
      }
      if (eventRecord.data.lockedEventOrderPrevious) {
        items.lockPreceedingSiteVisitOrder = false;
      } else {
        items.unlockPreceedingSiteVisitOrder = false;
      }
      if (eventRecord.data.lockedEventOrderNext) {
        items.lockSucceedingSiteVisitOrder = false;
      } else {
        items.unlockSucceedingSiteVisitOrder = false;
      }
    }
  },



    scheduleTooltipFeature : {

    generateTipContent({ date, event, resourceRecord }) {

      if (resourceRecord !== undefined && resourceRecord !== null && resourceRecord.siteVisitBooking !== undefined) {
        return `<dd class="start-time-tooltip">${siteVisitNumber(date,resourceRecord)}</dd>
                <dd class="start-time-tooltip">${estimatedStartTimeDisplayString(date)}</dd>
                <dd class="arrival-window-tooltip">${arrivalWindowDisplayString(date,resourceRecord)}</dd>
                <dd>${estimatedCommuteTime(date,resourceRecord)}</dd>`;
      } else {
        // No tooltip when mousing over non-event time.
        return undefined;
      }
  }
  },

  ScrollManager : {
    scrollSpeed : 1000,
  },

    // columnWidth can over-ride above width for over, but not under
    resourceColumns : {
        columnWidth : 10,
        height : 125,
        headerRenderer : resourceHeaderRenderer,
      },

    // eventBodyTemplate is used to render markup inside an event. It is populated using data from eventRenderer()
    eventBodyTemplate : values => values.map(value => `
        <div class="${value.className}" style="top: ${value.top}px;height: ${value.height}px; width:100%">
            ${value.name}
        </div>
    `).join(''),


    // eventRenderer is here used to translate the dates of nested events into pixels, passed on to the eventBodyTemplate
    eventRenderer({ eventRecord, tplData }) {
      // getCoordinateFromDate gives us a px value in time axis, subtract events left from it to be within the event
      const dateToPx = (date) => this.getCoordinateFromDate(date) - tplData.top;

      const mainEventRender = (eventRecord: any) => {
        let retVal = `
              <div class="site-visit-event-content" style="order: 3;">
                <div><i class="material-icons" style="font-size: 14px; margin-right: 2px">directions_car</i>${eventRecord.arrivalWindowString}</div>
                <div>${eventRecord.name}</div>
              </div>
      `;
      if (eventRecord.commuteDeltaFromAdding !== undefined) {
        retVal +=
        `<div style="order: 1;">
        <table style="display: flex; justify-content: center;">
        <tr>
          <td style="background-color: gold; color: black; border-radius: 4px;">&#916; ${eventRecord.commuteDeltaFromAdding}m</td>
        </tr>
        </table>
        </div>`
      }
      return retVal;
      };

      const renderEventIconIfNeeded = (eventRecord: any) => {
        let retVal: string;
        if (eventRecord.iconCls && eventRecord.iconCls.length > 0) {
          retVal = `<div style="order: 2;"><table class ="b-sch-event-footer">${eventRecord.iconCls.map(item => `<li title="${item.name}" class="${
            item.class}"></li>`).join(' ')}</table></div>`.concat(mainEventRender(eventRecord));
        } else {
          retVal = mainEventRender(eventRecord);
        }
        if (eventRecord.data.techLocatedAtSiteVisit) {
          retVal += `<i class="material-icons" style="font-size: 14px; color: yellow; position: absolute; right: 2px">star</i>`;
        } else if (eventRecord.data.techBeenHere) {
          retVal += `<i class="material-icons" style="font-size: 14px; color: white; position: absolute; right: 2px">beenhere</i>`;
        }
        return retVal;
      };

      const commuteFront = {
        top: dateToPx(eventRecord.startDate),
        height: dateToPx(eventRecord.commuteEndDate) - dateToPx(eventRecord.startDate),
        name: eventRecord.data.techLocatedCommuteStart ? `${eventRecord.commuteMinutesToSite}m` +
          `<i class="material-icons" style="font-size: 14px; color: yellow; position: absolute; right: 2px">star</i>`
        : `${eventRecord.commuteMinutesToSite}m`,
        className: eventRecord.errored ? "commute" : "commute",
      };

      const mainSiteVisit = {
        top: dateToPx(eventRecord.commuteEndDate),
        height: dateToPx(eventRecord.returnCommuteStartDate) - dateToPx(eventRecord.commuteEndDate),
        name: renderEventIconIfNeeded(eventRecord),
        className: eventRecord.errored ? "errored-event site-visit" : "site-visit",
      };

      const commuteEnd = {
        top: dateToPx(eventRecord.returnCommuteStartDate),
        height: dateToPx(eventRecord.endDate) - dateToPx(eventRecord.returnCommuteStartDate),
        name:  eventRecord.data.techLocatedCommuteEnd ? eventRecord.commuteMinutesSiteToShop === 0 ? `` : `${eventRecord.commuteMinutesSiteToShop}m` +
        `<i class="material-icons" style="font-size: 14px; color: yellow; position: absolute; right: 2px">star</i>` :
        eventRecord.commuteMinutesSiteToShop === 0 ? `` : `${eventRecord.commuteMinutesSiteToShop}m`,
        className: eventRecord.errored ? "commute" : "commute",
      };

      return [commuteFront, mainSiteVisit, commuteEnd];
  },


}; // eo const schedulerConfig
}

export let schedulerConfig = initilizeSchedulerConfig();


// eof

//  Changing below and CSS changes shows proof of concept of mutating commute window sizes on partial resize of events.  This is CSS work, I am leaving sorting of it as an excercise
// for Grid-Greg, or such is my dream.  <SRR>

    // // eventBodyTemplate is used to render markup inside an event. It is populated using data from eventRenderer()
    // eventBodyTemplate : values => `<div class="bryntum-event-body" style = "display: flex; flex-direction: column; height:100%">` + values.map(value => `
    //     <div class="${value.className}" style="flex-grow: 1;">
    //         ${value.name}
    //     </div>
    // `).join('') + `</div>`,

    // .commute {
    //   background-color: gray;
    //   // display: flex;
    //   // position: absolute;

    //   justify-content: center;
    //   align-items: center;
    // }

    // .site-visit {
    //   // display: flex;
    //   // position: absolute;
    //   background-color: inherit;
    //   // justify-content: center;
    //   // align-items: flex-start;

    // }
