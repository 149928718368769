import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest,  merge } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ControlContainsLiveviewComponent } from '../control-contains-liveview.component';
import { RatingControlDefaultService } from './rating-control-default.service';

@Component({
  selector: 'app-rating-control',
  templateUrl: './rating-control.component.html',
  styleUrls: ['./rating-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingControlComponent extends ControlContainsLiveviewComponent implements AfterViewInit  {

  columns$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  data$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(protected fb: UntypedFormBuilder, private ratingDefaultService: RatingControlDefaultService) {
    super(RatingControlComponent,fb);
    this.form = this.initilizeFormGroup();

    this.fields =
      [{
        key: `${this.perInstance}-ratingComponent`,
        type: 'formlyRating',
        props: {
          label: "",
          disabled: true,
          columns: this.columns$,
          changeDetect: this.changeDetect$,
        },
        defaultValue: this.data$,
        className: "rating-control-spec",
        wrappers: ["change-detect"],
      }];

      // this.subjectsToCopyToControlContainer.push({key: "columns$", value: this.columns$});
      // this.subjectsToCopyToControlContainer.push({key: "data$", value: this.data$});

  }
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.NumberComponents++;
    this.patchControlComponentsToFormlyFields();

    merge(this.columns$,this.data$).pipe(
      takeUntil(this.destroyingComponent$),
    ).subscribe(() => this.changeDetect$.next(null));
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Rating Scale");
    retVal.patchValue({
      icon: "format_list_numbered_rtl",
      iconColor: "#b74915",
      controlComponentFormGroup: this.createRatingControlFormGroup(),
    });
    return retVal;
  }

  createRatingControlFormGroup() {
    return this.fb.group({
      label: "Rate It!",
      columns:  this.ratingDefaultService.createOneFiveScaleFormArray(),
      data: this.createQuestionFormArray(),
      ratingPrepopulatedOption: {},
      allowAddFromDragDrop: true,
    })
  };



  createQuestionFormArray() : UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        label: this.fb.control('Question One'),
        rating: 0,
        id: "1"
      }),
      this.fb.group({
        label: this.fb.control('Question Two'),
        rating: 0,
        id: "2"
      }),
    ]);
  }

  patchControlComponentsToFormlyFields(): void {

    const obs$ = this.patchControlComponentsToFormlyFieldsCommon();

    combineLatest([...obs$]).pipe(
      tap(() => {
        this.fields[0].props.changeDetect.next();
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.componentForm.get("ratingPrepopulatedOption").valueChanges.pipe(
      tap(x => {
        const cols = (this.componentForm.get("columns") as UntypedFormArray);
        cols.clear();
        x.controls.forEach(val => cols.push(val));
        }),
      takeUntil (this.destroyingComponent$)
    ).subscribe();


    this.componentForm.get("columns").valueChanges.pipe(
      startWith(this.componentForm.get("columns").value),
      //Name is spliced in here b/c we don't want user to be able to remove first column from control.
      map(x => x as object[]),
      map(x => {
        if (x.length ===0 || x[0]["label"]!=="name"){
          (x as object[]).splice(0,0,{label: 'name', value: "0"});
        }
        return x;
      }),
      takeUntil(this.destroyingComponent$),
    ).subscribe(this.columns$);

    this.componentForm.get("data").valueChanges.pipe(
      startWith(this.componentForm.get("data").value),
      map(x => (x as []).map(val => {
          if (val["id"]) {
            return val;
          } else {
            return {label: val["label"], rating: 0, id: val["value"]}
          }
        })),
        tap(x => this.data$.next(x)),
      takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig ): void {
    const propertiesToSkip : string[] = ["columns"];
    super.patchCommonFieldsToForm(formlyConfig);
    for (var prop in formlyConfig.props) {
      if (this.componentForm.controls[prop] !== undefined && !propertiesToSkip.some(x => x === prop)) {
        this.componentForm.controls[prop].patchValue(formlyConfig.props[prop]);
      }
    }

    // Move into a base implementation <<REFACTOR>>
    propertiesToSkip.forEach(arrayToPatch => {
      (this.componentForm.get(arrayToPatch) as UntypedFormArray).clear();
      (formlyConfig.props[arrayToPatch] as ({key: string, val: string}[])[]).forEach(val => {
        (this.componentForm.get(arrayToPatch) as UntypedFormArray).push(
          this.fb.group(
            {...val}
          ));
      });
    });

    // Name is included in FormArray (it is first element of the Formly control), but we add it later b/c we do not want to allow user to
    // remove or edit it (unlike other columns)
    (this.componentForm.get("columns") as UntypedFormArray).removeAt(0);

    const questionArray = (this.componentForm.get("data") as UntypedFormArray);
    questionArray.clear();
      for (let i=0; i < formlyConfig.defaultValue.length; i++) {
        questionArray.push(
          this.fb.group({
            label: formlyConfig.defaultValue[i].label,
            rating: formlyConfig.defaultValue[i].rating,
            id: formlyConfig.defaultValue[i].id
          })
        );
      }
  }

  toFormlyFieldConfigJsonOnly() : FormlyFieldConfig {
    const retVal = super.toFormlyFieldConfigJsonOnly();
    retVal.props.columns = this.columns$.getValue();
    retVal.defaultValue = this.data$.getValue();
    return retVal;
  }

  toFormlyFieldConfig(): FormlyFieldConfig | FormlyFieldConfig[] {
    const retVal = super.convertFormlyFieldConfigToLiveViewVersion(this.fields[0]);
    retVal.defaultValue = (this.fields[0] as FormlyFieldConfig).defaultValue;
    retVal.props.columns = this.fields[0].props.columns;
    return retVal;
  }
}
