import { RetrieveFirestorePropertiesBase } from "../../database-backend/retrieve-firestore-properties";
import { FormFirestoreSummary } from "../form-firestore-summary";
import { Cost } from "./cost";
import { PriceBookCatagory } from "./pricebook-catagory";
import { PriceBookUnitOfMeasure } from "./pricebook-unit-of-measure";


export class PriceBookEntry extends RetrieveFirestorePropertiesBase<PriceBookEntry>  {
  title: string;
  itemCode: string;
  divideByCalc: number;
  costs: Cost[];
  active: boolean = true;
  notes: string;
  importLastUpdated: Date;
  productDescription: string;

  formFirestoreSummaryContainingPriceBookEntryDocIds: string[] = [];
  formFirestoreSummaryContainingPriceBookEntries: FormFirestoreSummary[] = [];
  formFirestoreSummaryDefiningPriceBookEntryDocId: string;
  formFirestoreSummaryDefiningPriceBookEntry: FormFirestoreSummary;
  unitOfMeasureDocId: string;
  unitOfMeasure : PriceBookUnitOfMeasure;
  priceBookCatagory: PriceBookCatagory;
  priceBookCatagoryDocId: string;

  priceBookCatagoryString: string;

  public constructor(init?: Partial<PriceBookEntry>) {
    super();
    Object.assign(this, init);
  }

  generateTotalCost(quantity: number) : number {
    const retVal = this.costs.reduce((a, b) => a + b.amount * 1, 0);
    return  quantity * retVal;
    // return  quantity / this.divideByCalc * retVal;
  }

  generateMarkup(quantity: number) : number {
    // return quantity / this.divideByCalc * this.costs.reduce((a, b) => a + b.amount * b.costStructure.markup,0);
    return quantity * this.costs.reduce((a, b) => a + b.amount * b.costStructure.markup,0);
  }

  generateRetail(quantity: number) : number {
    return this.generateMarkup(quantity)/.75;
  }

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([ "unitOfMeasure", "formFirestoreSummary", "priceBookCatagory",
    "priceBookCatagoryString", "formFirestoreSummaryContainingPriceBookEntries", "formFirestoreSummaryDefiningPriceBookEntry"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["unitOfMeasureDocId", "formFirestoreSummaryDocId", "priceBookCatagoryDocId",
    "priceBookCatagoryString","formFirestoreSummaryContainingPriceBookEntryDocIds", "formFirestoreSummaryDefiningPriceBookEntryDocId" ]);

  static _firestoreCompositionMemberNames = ["unitOfMeasure", "formFirestoreSummary", "priceBookCatagory", "formFirestoreSummaryContainingPriceBookEntries","formFirestoreSummaryDefiningPriceBookEntry"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["unitOfMeasure", "formFirestoreSummary", "priceBookCatagory", "formFirestoreSummaryContainingPriceBookEntries","formFirestoreSummaryDefiningPriceBookEntry"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return PriceBookEntry._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return PriceBookEntry._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return PriceBookEntry._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return PriceBookEntry._firestoreCompositionMemberNames;}
}
