import { Injectable } from '@angular/core'
import { DatabaseStoreService } from '../../database-backend/database-store.service'
import { AuthenticationService } from '../../../util/authentication.service'
import { StateChangeStoreService } from '../../database-backend/state-change-store.service'
import { FirestoreDiffService } from '../firestore-diff.service'
import { FirestoreBackend } from '../../database-backend/retrieve-from-firestore'
import { PriceBookEntry } from '../../dao/pricebook/pricebook-entry'
import { FormFirestoreSummaryService } from '../form-firestore-summary.service'
import { PriceBookUnitOfMeasureService } from './pricebook-unit-of-measure.service'
import { PriceBookCatagoryService } from './pricebook-catagory.service'

@Injectable({
  providedIn: 'root'
})
export class PriceBookEntryService extends DatabaseStoreService<PriceBookEntry> {

  constructor( fs: PriceBookEntryFirestoreService, store: PriceBookEntryStoreService, authenticationService: AuthenticationService,
    private formFirestoreSummaryService: FormFirestoreSummaryService, private unitOfMeasureService: PriceBookUnitOfMeasureService,
    private priceBookCatagoryService: PriceBookCatagoryService) {
    super(fs, store, authenticationService,new Map([
      ["formFirestoreSummary", {associatedDocumentId: "formFirestoreSummaryDocId", compositionStoreService: formFirestoreSummaryService}],
      ["unitOfMeasure", {associatedDocumentId: "unitOfMeasureDocId", compositionStoreService: unitOfMeasureService}],
      ["priceBookCatagory", {associatedDocumentId: "priceBookCatagoryDocId", compositionStoreService: priceBookCatagoryService}],
      ["formFirestoreSummaryContainingPriceBookEntries", {associatedDocumentId: "formFirestoreSummaryContainingPriceBookEntryDocIds", compositionStoreService: formFirestoreSummaryService}],
      ["formFirestoreSummaryDefiningPriceBookEntry", {associatedDocumentId: "formFirestoreSummaryDefiningPriceBookEntryDocId", compositionStoreService: formFirestoreSummaryService}]
    ]));
    }
}

@Injectable({
  providedIn: 'root'
})
export class PriceBookEntryStoreService extends StateChangeStoreService<PriceBookEntry> {
  protected store = "PriceBookEntry-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, PriceBookEntry>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class PriceBookEntryFirestoreService extends FirestoreBackend<PriceBookEntry> {

 protected basePath = "PriceBookEntry";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): PriceBookEntry {
return new PriceBookEntry(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new PriceBookEntry(), authService);
 }
}
