import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, debounceTime, merge, Observable, startWith, Subject, zip } from 'rxjs';
import {  filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChecklistDatabase } from '../../../multiselect-nested-tree/multiselect-nested-tree.component';
import { SettingsService } from '../../../../app/settings/settings.service';
import { WORKFLOW_STAGE } from '../../component-models/formly-controls/utilities/fetch-updated-workflow.service';
import { FormCatagoryTreeService, FormCatagoryTreeStructure } from '../form-catagory-tree.service';
import { FormFirestoreService } from '../../../../../../common/src/data/dao-services/form-firestore.service';
import { FormFirestoreSummary } from '../../../../../../common/src/data/dao/form-firestore-summary';
import { FormCatagory } from '../../../../../../common/src/data/dao/form-catagory';
import { FormFirestoreSummaryService } from '../../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { FormCatagoryService } from '../../../../../../common/src/data/dao-services/form-catagory.service';
import { where } from 'firebase/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-load-form',
  template: `
  <mat-checkbox (change)="toggleInactiveWorkflows()">Load Inactive Workflows</mat-checkbox>
  <div class="filter-container">
  <div class="label-style">Workflow Type</div>
    <div class="medium-field">
      <form [formGroup]="form">
        <mat-form-field appearance="outline" class="form-align">
          <mat-select formControlName="formType" [compareWith]=compareTypes #workflowType>
            <mat-option *ngFor="let w of WorkflowTypesToShow" [value]="w" >
              {{w.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="label-style">Filter</div>
  <mat-form-field appearance="outline">
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" cdkFocusInitial>
  </mat-form-field>
    </div>

  <!-- Workflow Catagories -->
  <div class="header-label" style="display: flex; padding: 0px; height: 80px;">
  <div class="header-label" style="border: none; width: 75%;">Choose Workflow Catagory</div>
  </div>
    <app-workflow-settings-tree
      [checkListDatabase]="CheckListDatabase() | async"
    ></app-workflow-settings-tree>
  <div class="header-label choose-workflow-header-styling">Choose Workflow</div>
  <div class="workflow-table-container">
  <table mat-table [dataSource]="importedDataSource">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplayStandard">
    <th mat-header-cell *matHeaderCellDef> {{columnsToDisplayHumanReadable.get(column)}} </th>
    <td mat-cell *matCellDef="let element;" class="td-align-center"><mat-icon style="font-size: 24px; color:#878787b0; margin-right: 10px">assignment_outline</mat-icon><div class="y-scrollbar">{{element[column]}}</div></td>
  </ng-container>
  <!-- Form Catagory -->
  <ng-container matColumnDef="formCatagory">
    <th mat-header-cell *matHeaderCellDef> Catagory </th>
    <td mat-cell *matCellDef="let element;"><div class="y-scrollbar">{{element.formCatagory.name}}</div></td>
  </ng-container>
  <!-- date created needs piped.-->
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef>Created </th>
    <td mat-cell *matCellDef="let element">{{element.createdAt | date:'medium'}} </td>
  </ng-container>
  <!-- Form Type -->
  <ng-container matColumnDef="formType">
    <th mat-header-cell *matHeaderCellDef> Type </th>
    <td mat-cell *matCellDef="let element">{{friendlyFormTypeName(element.formType)}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></tr>
<tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
(click)="selectFile(element)">
></tr>
</table>
</div>
  `,
  styleUrls: ['./load-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadFormComponent implements OnInit, AfterViewInit, OnDestroy {

  displayInactive: boolean = false;
  refreshInactive$: Subject<any> = new Subject<any>();

  formCatagory: string = "all";
  importedDataSource = new MatTableDataSource<(FormFirestoreSummary)>();
  columnsToDisplay = ['title','formCatagory','createdAt','formType'];
  columnsToDisplayStandard = ['title'];
  columnsToDisplayHumanReadable = new Map([["title", "Title"], ["formCatagory", "Form Catagory"], ["formType", "Type"]]);
  checkListDatabase :BehaviorSubject<ChecklistDatabase<FormCatagoryTreeStructure>> = new BehaviorSubject<ChecklistDatabase<FormCatagoryTreeStructure>>(undefined);
  workflowStage: WORKFLOW_STAGE = WORKFLOW_STAGE.DESIGN;

  AllWorkflowTypes = [{name: "TopLevel", value: "topLevel"},{name: "Section", value: "section"},{name: "All", value: "all"}];
  WorkflowTypesToShow = this.AllWorkflowTypes;
  form: UntypedFormGroup;

  formCatagories: FormCatagory[];

  loadAllCatagories: Observable<any> = new Observable<any>();

  @ViewChild(MatTable) table: MatTable<any>;

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  this.formCatagoryTreeService.selectedNodes.next([]);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LoadFormComponent>,
  private formService: FormFirestoreService, private formFirestoreSummaryService: FormFirestoreSummaryService,
  private formFirestoreService: FormFirestoreService, private formCatagoryTreeService: FormCatagoryTreeService,
  private settingsService: SettingsService, private ref: ChangeDetectorRef, private formCatagoryService: FormCatagoryService, private fb: UntypedFormBuilder) {

    this.form = this.createForm();

    this.loadAllCatagories = this.formCatagoryService.loadAll$().pipe(
      tap(x => this.formCatagories=  x),
      take(1)
    );

    if (data) {
      if (data.formCatagory) {
        this.formCatagory = data.formCatagory.DocId();
      }
      this.form.patchValue({formType: this.WorkflowTypesToShow.find(x => x.value === data.formType)});
      if (data.workflowStage) {
        this.workflowStage = data.workflowStage;
      }
      if (data.explicitWorkflowList) {
        this.WorkflowTypesToShow = this.WorkflowTypesToShow.filter(x => data.explicitWorkflowList.includes(x.value));
      }
    }
  }
  compareTypes(i1, i2) {
    return i1 && i2 && i1.value===i2.value;
  }

  friendlyFormTypeName(type: string) : string {
    if (type === "topLevel") {
      return "Top Level";
    } else if (type === "section") {
      return "Section";
    } else {
      return "";
    }
  }

  applyFilter(event: KeyboardEvent) {
    let filterValue: string = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.importedDataSource.filter = filterValue;
  }

  createForm() {
    return this.fb.group ({
      formType: [''],
    });
}

  getAllDescendants(formCatagoryDocId: string) : string[] {
    const retVal = this.formCatagories.filter(x=>x.parentDocId === formCatagoryDocId).map(x=>x.DocId());
    if (retVal.length > 0) {
      retVal.forEach(x => {
        retVal.push(...this.getAllDescendants(x));
      });
    }
    if (retVal.findIndex(x=>x === formCatagoryDocId) === -1) {
      retVal.push(formCatagoryDocId);
    }
    return [...new Set<string>(retVal)];
  }

  viewAllForms(formType: string) : Observable<FormFirestoreSummary[]> {
    const queryArray = [where("active", "==", !this.displayInactive), where("formCatagoryDocId", "!=", null)];
    if (formType !== "all") {
      queryArray.push(where("formType", "==", formType));
    }
    return this.formFirestoreSummaryService.queryFirestoreDeep$(queryArray).pipe(
      map(x => x.sort((a,b) => a.createdAt.getTime() < b.createdAt.getTime() ? 1 : -1)),
      )
  }

  viewSubsetOfForms(formCatagoryDocId: string, formType: string) : Observable<FormFirestoreSummary[]> {
    const formCatagoryAndDescendants = this.getAllDescendants(formCatagoryDocId);
    const subsetObs = formType === "all" ? combineLatest([...formCatagoryAndDescendants.map(f => this.formFirestoreSummaryService.queryFirestoreDeep$([where("formCatagoryDocId", "==", f),
    where("active", "==", !this.displayInactive)]))]):
    combineLatest([...formCatagoryAndDescendants.map(f => this.formFirestoreSummaryService.queryFirestoreDeep$([where("formCatagoryDocId", "==", f),
    where("formType", "==", formType),
    where("active", "==", !this.displayInactive)]))]);
    return subsetObs.pipe(
      map(x => x.reduce((a,b) => a.concat(b), [])),
      map(x => x.sort((a,b) => a.createdAt.getTime() < b.createdAt.getTime() ? 1 : -1)));
  }

  ngAfterViewInit(): void {

    const selectedValue = combineLatest([this.form.controls.formType.valueChanges.pipe(startWith(this.form.controls.formType.value)),
      this.checkListDatabase.value.selectedNodes.pipe(startWith(this.checkListDatabase.value.selectedNodes.value))
      ]).pipe(
      filter(x => x[1].length > 0 && x[1][0].item.formCatagory.docId !== this.settingsService.getValue('defaultWorkCatagoryDocId')),
      tap(x => console.log(x,` string`)),
      mergeMap(x => this.viewSubsetOfForms(x[1][0].item.formCatagory.docId,x[0].value)));

    const unselectedValue = combineLatest([this.form.controls.formType.valueChanges.pipe(startWith(this.form.controls.formType.value)),
      this.checkListDatabase.value.selectedNodes.pipe(startWith(this.checkListDatabase.value.selectedNodes.value))
      ]).pipe(
      filter(x => x[1].length === 0 || x[1][0].item.formCatagory.docId === this.settingsService.getValue('defaultWorkCatagoryDocId')),
      tap(x => console.log(x,` string`)),
      mergeMap(x => this.viewAllForms(x[0].value))
      );


    merge(this.loadAllCatagories,this.refreshInactive$).pipe(
    switchMap(() => merge(selectedValue,unselectedValue)),
      tap(x => this.importedDataSource.data = x),
      tap(() => this.table.renderRows()),
      tap(() => this.ref.markForCheck()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  toggleInactiveWorkflows() {
    this.displayInactive = !this.displayInactive;
    this.refreshInactive$.next(null);
  }

  CheckListDatabase() : Observable<ChecklistDatabase<FormCatagoryTreeStructure>> {
    if (this.checkListDatabase.value === undefined) {
      this.checkListDatabase.next(new ChecklistDatabase<FormCatagoryTreeStructure>(this.formCatagoryTreeService.rootNode.pipe(filter(node => node!== undefined)),
        this.formCatagoryTreeService.allNodes,this.formCatagoryTreeService.selectedNodes, (x=>x.parentCatagoryKey === null ? undefined : x.parentCatagoryKey),
        (x => x.name),(x=>x.key), (x=>x === undefined ? "" : x.description), false));
    }
    return this.checkListDatabase as Observable<ChecklistDatabase<FormCatagoryTreeStructure>>;
  }

  selectFile(formFirestoreSummary: FormFirestoreSummary) {
    this.formFirestoreSummaryService.load$(formFirestoreSummary.DocId()).pipe(
    switchMap(x => this.formFirestoreService.load$(this.workflowStage === WORKFLOW_STAGE.DESIGN ? x.currentDesignFirestoreDocId : x.currentDeployedFirestoreDocId)),
      filter(x => x!==undefined),
      take(1),
      ).subscribe(fire => this.dialogRef.close(fire));
  }

  ngOnInit(): void {

  }

}
