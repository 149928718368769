import { Injectable } from '@angular/core'
import { DatabaseStoreService } from '../../database-backend/database-store.service'
import { AuthenticationService } from '../../../util/authentication.service'
import { StateChangeStoreService } from '../../database-backend/state-change-store.service'
import { FirestoreDiffService } from '../firestore-diff.service'
import { FirestoreBackend } from '../../database-backend/retrieve-from-firestore'
import { PriceBookCatagory } from '../../dao/pricebook/pricebook-catagory'

@Injectable({
  providedIn: 'root'
})
export class PriceBookCatagoryService extends DatabaseStoreService<PriceBookCatagory> {

  constructor( fs: PriceBookCatagoryFirestoreService, store: PriceBookCatagoryStoreService, authenticationService: AuthenticationService) {
super(fs, store, authenticationService);
  }

}

@Injectable({
  providedIn: 'root'
})
export class PriceBookCatagoryStoreService extends StateChangeStoreService<PriceBookCatagory> {
  protected store = "PriceBookCatagory-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, PriceBookCatagory>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class PriceBookCatagoryFirestoreService extends FirestoreBackend<PriceBookCatagory> {

 protected basePath = "PriceBookCatagory";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): PriceBookCatagory {
return new PriceBookCatagory(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new PriceBookCatagory(), authService);
 }
}
