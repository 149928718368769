

<div style="height: 100%; background-color: rgb(248, 248, 248); display: flex; flex-direction: column; justify-content: space-between;">
  <div style="padding: 10px 30px 0px 30px; display: grid; grid-template-columns: 1fr 1fr; background-color: rgb(248, 248, 248);">
   <div class="calculations-header" style="grid-column: 1/-1;">Calculations Overview</div>
   <div style="font-family: Arial, Roboto, Helvetica Neue, sans-serif; line-height: 1.4; font-size: 16px; grid-column: 1/-1; margin-bottom: 20px;">
   To create calculation, select 1 or more numeric fields by clicking on them.  Optionally combine with mathmatical operators.
   Currently grouping () multiplication * division /  addition + and subtraction - are supported.
   Additionally FLOOR() , CEIL(), ROUND(), MIN(), MAX(), SQRT(), ABS(), PI, ARCTAN(), COS() are supported.
   </div>

   <form [formGroup]="formula" style="display: contents;">
   <div style="display: grid; grid-template-columns: 1fr; grid-column: 1/-1; min-height: 160px; border: 2px solid gainsboro; border-bottom: none; padding: 10px 10px 0px 10px;">
     <div>
   <div class="calculations-header">Formula</div>
   <tagify name="formula" formControlName="tags" [settings]="mixedSettings">
   </tagify>
   </div>
   <div>
   <div class="calculations-header">Formula Results</div>
   <mat-form-field appearance="outline" style="width:100%;">
     <ng-container *ngIf="formulaPreviewIsNumeric$|async; else formulaError">
      <textarea class="change-disabled-text-color"
        matInput
        [disabled]="true"
        [cdkTextareaAutosize] style="height: auto;"
      >{{formulaPreviewNumeric$|async}}</textarea>
     </ng-container>
      <ng-template #formulaError>
        <textarea class="change-disabled-text-color" [disabled]="true" matInput [cdkTextareaAutosize] style="height: auto;">{{formulaPreview$|async}}</textarea>
      </ng-template>
   </mat-form-field>
  </div>
  </div>

  <div style="display: grid; grid-template-columns: 1.3fr 3fr; grid-column: 1/-1; border: 2px solid gainsboro; border-top: none; padding: 20px 10px 20px 10px;">
   <div class="calculations-header">Formula Notes:</div>
   <mat-form-field appearance="outline" style="width:100%; min-height: 80px;">
     <textarea matInput [cdkTextareaAutosize] formControlName="notes" placeholder="Explanation and notes on how this formula works"></textarea>
   </mat-form-field>
  </div>
  </form>
  <!-- Preview of formula results: -->

  <!--Components used in formula section-->
  <div style="display: grid; grid-template-columns: 1fr; margin-top: 20px; column-gap: 20px; grid-column: 1/-1;">
    <div class="calculations-header" style="grid-column: 1/-1;">Input components:</div>
      <div style="padding-top: 35px; display: contents;"> <!--This div only exists so the above H2 tag does not overlap the live view proper-->
        <div *ngFor="let component of componentsUsedInCalculation$|async;" class = "main-form-content" style="display: contents;">
            <div [ngStyle]="SelectBackgroundColor(component)" style="display: contents;">
              <div style="display: grid; grid-template-columns: 1fr 100px; align-items: center;">
                <app-form-element
                  [componentToInstantiate]="component"
                ></app-form-element>
                <div style="margin: 9px 0px 0px 20px;">
                  <button mat-button class="button-red" (click)="DeleteFromCalc(component)">Remove</button>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>

  <!--footer-->

  </div>
  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column: 1/-1; background-color: rgb(248, 248, 248); padding: 30px 15px 20px 15px;">

  <div style="display: flex;">
    <div>
      <button mat-button class="button-gray" (click)="Exit()">
        Exit
      </button>
    </div>
  </div>

  <div style="display: flex; justify-content: flex-end;">
    <button mat-button class="button-blue" (click)="Save()">
    Save
    </button>
  </div>


  </div>
  </div>
