
<div style="display:none">{{changeDetect$|async}}</div>
<div class="control-layout-header">Edit Component</div>
<div class="edit-control-layout">
    <div [formGroup]="form$|async" style="display: contents;">
      <!-- Label location and text. -->
      <div *ngIf="provideLabel((form$ | async).get('controlName').value)" [formGroup]="controlComponentFormGroup" style="display: contents;">
        <!--<h3>Label Information!</h3>-->
        <div class="component-section">Edit Label</div>
          <div *ngIf="labelLocationSpecified((form$|async).get('controlName').value)">
            <mat-label style="font-size: 14px;">Label Alignment</mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-select placeholder="Label Location" formControlName="labelLocation">
                <mat-option *ngFor="let location of labelLocationOptions" [value]="location.value">
                  {{location.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-label style="font-size: 14px;">Bold</mat-label>
            <mat-checkbox formControlName="labelBold">Bold</mat-checkbox>
            <mat-label style="font-size: 14px;">Underline Options</mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-select placeholder="Underline Options" formControlName="underline">
                <mat-option *ngFor="let underlineOption of underlineOptions" [value]="underlineOption.value">
                  {{underlineOption.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
          <mat-label style="font-size: 14px;">Label </mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <input placeholder="Label Here!" formControlName="label" matInput>
          </mat-form-field>
        </div>
      </div>
      <!--End of "label name" placeholder-->

      <!-- Label -->
      <div *ngIf="(form$|async).get('controlName').value === 'Label'" [formGroup]="(form$|async).get('controlComponentFormGroup').value" style="display: contents;">
        <div class="component-section">Edit Label</div>
        <div class="label-width">
          <mat-label style="font-size: 14px;">Text</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <textarea placeholder="Default Value" formControlName="value" matInput ></textarea>
          </mat-form-field>
        </div>
      </div>

      <!-- Text Input -->
      <div *ngIf="(form$|async).get('controlName').value === 'Text Box'" [formGroup]="controlComponentFormGroup" style="display: contents;">
        <!--<h3>Text Input Specific!</h3>-->
        <div class="component-section">Edit Textbox</div>
        <div>
          <mat-label style="font-size: 14px;">Default Text</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <ng-container *ngIf="controlComponentFormGroup.get('numericMaskDesired').value; else notNumericMaskBlock" >
          <textarea #defaultText placeholder="Default Value" formControlName="value" matInput
              [dropSpecialCharacters]="false"
              [mask]="numericMask"
              [thousandSeparator]="thousandSeparator"
              [clearIfNotMatch]="false">
          </textarea>
          </ng-container>
          <ng-template #notNumericMaskBlock>
            <textarea #defaultText placeholder="Default Value" formControlName="value" matInput>
            </textarea>
          </ng-template>
          </mat-form-field>
        </div>
        <div>
          <mat-label style="font-size: 14px;">Placeholder</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput placeholder="Placeholder" formControlName="placeholder">
          </mat-form-field>
        </div>
        <div class="component-section">Numeric Field</div>
        <div>
          <mat-checkbox formControlName="numericMaskDesired">
            Numeric Only
          </mat-checkbox>
        </div>
        <div>
          <mat-label style="font-size: 14px;">Number Decimal Places</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <input type="number" formControlName="numberDecimalPlaces" matInput mask="0*">
          </mat-form-field>
        </div>

        <div class="component-section">Data Link</div>

          <div style="margin: 0 auto;">
          <button style="min-width: 120px;" class="button-green" mat-button (click)="loadDataLinkModal()">
            <!--<mat-icon style="color: red;">add_link</mat-icon>-->
            Insert Link
          </button>
          </div>
          <div style="margin: 0 auto;">
            <button style="min-width: 120px;" class="button-red" mat-button (click)="deleteDataLink()">
              <!--<mat-icon style="color: red;">delete</mat-icon>-->
              Delete Link
            </button>
          </div>
          <div class="component-section">Calculated Field</div>
          <div style="margin: 0 auto;">
            <button style="min-width: 120px;" class="button-green" mat-button (click)="buildCalculatedField()">
              Build Field
            </button>
          </div>
          <div style="margin: 0 auto;">
            <button style="min-width: 120px;" class="button-red" mat-button [disabled] = "!(calculatedFieldPresent|async)" (click)="deleteCalcualtedField()">
              Delete Field
            </button>
          </div>

      </div>

      <!-- Choice populator -->
      <ng-container *ngIf="(form$|async).get('controlName').value === 'Choice'"
        [ngTemplateOutlet]='chooserTemplate'
          [ngTemplateOutletContext]="{formGroup:controlComponentFormGroup}"
        ></ng-container>

        <!--<h3>Chooser Specific!</h3>-->
        <ng-template #chooserTemplate let-formGroup="formGroup">
        <div [formGroup]="formGroup" style="display: contents;">
        <app-drag-drop-list-builder [form]="initilizeAvailibleChoicesForm(formGroup)" style="display: contents;"></app-drag-drop-list-builder>
        <div>
          <mat-label style="font-size: 14px;">Data Display</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="choiceControlType">
            <mat-option *ngFor="let choice of chooserOptions" [value]="choice.value">
              {{choice.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </div>
      </div>
      </ng-template>


      <!-- Rating Scale Populator -->
      <div *ngIf="(form$|async).get('controlName').value === 'Rating Scale'" [formGroup]="controlComponentFormGroup" style="display: contents;">
        <!-- <h5>Rating Scale</h5> -->
        <div>
        <mat-label style="font-size: 14px;">Standard Options</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select formControlName="ratingPrepopulatedOption" placeHolder="Prepopulated Options">
          <mat-option *ngFor="let option of scaleOptions" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
        <app-drag-drop-list-builder [form]="initilizeRatingScaleForm()" style="display: contents;"></app-drag-drop-list-builder>
        <h5 Rating Questions></h5>
        <app-drag-drop-list-builder [form]="initilizeRatingQuestionsForm()" style="display: contents;"></app-drag-drop-list-builder>
      </div>

      <!-- Photo Adder -->
      <div *ngIf="(form$|async).get('controlName').value === 'Photo Adder'" [formGroup]="controlComponentFormGroup" style="display: contents;">
        <div class="component-section">Photo Options</div>
        <div>
          <mat-label style="font-size: 14px;">Minimum # Photos</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <input placeholder="Default Value" formControlName="minimumPhotoCount" matInput >
          <div *ngIf="(minimumPhotoCount$|async)?.invalid && ((minimumPhotoCount$|async)?.dirty || (minimumPhotoCount$|async)?.touched)">
            <mat-error *ngIf="(minimumPhotoCount$|async)?.errors?.min">Minimum photo count must be at least 0.</mat-error>
            <mat-error *ngIf="(minimumPhotoCount$|async)?.errors?.max">Minimum photo count can not exceed maximum allowed photos.</mat-error>
          </div>
          </mat-form-field>
        </div>
        <div>
          <mat-label style="font-size: 14px;">Maximum # Photos</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <input placeholder="Default Value" formControlName="maximumPhotoCount" matInput >
          <div *ngIf="(maximumPhotoCount$|async)?.invalid && ((maximumPhotoCount$|async)?.dirty || (maximumPhotoCount$|async)?.touched)">
            <mat-error *ngIf="(maximumPhotoCount$|async)?.errors?.min">Minimum photo can not be below minimum photo count.</mat-error>
            <mat-error *ngIf="(maximumPhotoCount$|async)?.errors?.max">Maximum photo count can not exceed 40.  But talk to us if this doesn't fit your use case.</mat-error>
          </div>
          </mat-form-field>
        </div>
      </div>

      <!-- Branching -->
      <div *ngIf="(form$|async).get('controlName').value === 'Branching'" [formGroup]="controlComponentFormGroup" style="display: contents;">
        <div class="component-section">Branching Options</div>
        <button (click)="loadWorkflowSelectionModal()">Select Workflows</button>
        <button (click)="addSelectedPricebookEntries()">Select Price Book Entries</button>

        <ng-container *ngIf="controlComponentFormGroup.get('chooserFormGroup').value !== null"
        [ngTemplateOutlet]='chooserTemplate'
          [ngTemplateOutletContext]="{formGroup:controlComponentFormGroup.get('chooserFormGroup').value.value}"
        ></ng-container>
      </div>

      <!-- Section -->
      <div *ngIf="(form$|async).get('controlName').value === 'Section'" [formGroup]="controlComponentFormGroup" style="display: contents;">
        <div class="component-section">Section Options</div>

        <!-- Title of section, and options on allowing / defaulting to compressed view. -->
        <div>
          <mat-label style="font-size: 14px;">Title</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <input placeholder="Default Value" formControlName="itemName" matInput >
          </mat-form-field>
        </div>

        <div>
          <mat-checkbox formControlName="collapsable">Allow Collapse</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="defaultCollapsed">Start Collapsed</mat-checkbox>
        </div>

        <!-- Repeating? -->
        <div>
          <mat-checkbox formControlName="repeating">Repeating</mat-checkbox>
        </div>

        <!-- Options for repeating sections. -->
          <div>
            <mat-label style="font-size: 14px;">Minimum # </mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
            <input placeholder="Default Value" formControlName="minimumNumberInstances" matInput >
            <div *ngIf="(minimumNumberInstances$|async)?.invalid && ((minimumNumberInstances$|async)?.dirty || (minimumNumberInstances$|async)?.touched)">
              <mat-error *ngIf="(minimumNumberInstances$|async)?.errors?.min">Minimum count must be at least 0.</mat-error>
              <mat-error *ngIf="(minimumNumberInstances$|async)?.errors?.max">Minimum count can not exceed maximum count.</mat-error>
            </div>
            </mat-form-field>
          </div>
          <div>
            <mat-label style="font-size: 14px;">Maximum # </mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
            <input placeholder="Default Value" formControlName="maximumNumberInstances" matInput >
            <div *ngIf="(maximumNumberInstances$|async)?.invalid && ((maximumNumberInstances$|async)?.dirty || (maximumNumberInstances$|async)?.touched)">
              <mat-error *ngIf="(maximumNumberInstances$|async)?.errors?.min">Minimum can not be below minimum.</mat-error>
              <mat-error *ngIf="(maximumNumberInstances$|async)?.errors?.max">Maximum can not exceed 100.  But talk to us if this doesn't fit your use case.</mat-error>
            </div>
            </mat-form-field>
          </div>

      </div>

      <!-- Line Items-->
      <div *ngIf="(form$|async).get('controlName').value === 'Line Items'" [formGroup]="(form$|async).get('controlComponentFormGroup').value" style="display: contents;">
        <div class="component-section">Line Item Options</div>
        <div>
          <mat-label style="font-size: 14px;">Line Item Estimate Relationship</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="lineItemControlType">
            <mat-option *ngFor="let choice of lineItemDisplayTypeOptions" [value]="choice.value">
              {{choice.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Common settings for all controls. -->
      <!--<h3>Common to All Information!</h3>-->
      <div class="component-section">Control Display</div>
      <mat-slide-toggle formControlName="readonly" [disableRipple]="true" style="padding-left: 15px; align-self: center;">Read Only</mat-slide-toggle>
      <div>
      <mat-label style="font-size: 14px;">Audience </mat-label>
      <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select placeholder="Audience" formControlName="audience">
        <mat-option *ngFor="let audience of audienceOptions" [value]="audience.value">
          {{audience.label}}
        </mat-option>
      </mat-select>
      </mat-form-field>
      </div>

    <!-- Border colors and settings.-->
    <div *ngIf="(parentForm$|async).get('controlComponentFormGroup').value.get('border')">
    <div [formGroup]="(parentForm$|async).get('controlComponentFormGroup').value" style="display: contents;">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select placeholder="Border" formControlName="border" [compareWith]="compareFn">
          <mat-select-trigger>
            <mat-icon>{{(parentForm$|async).get('controlComponentFormGroup').value.get('border').value.icon}}</mat-icon>
            {{(parentForm$|async).get('controlComponentFormGroup').value.get('border').value.label}}
          </mat-select-trigger>
          <mat-option *ngFor="let border of borderOptions" [value]="border">
            <mat-icon>{{border.icon}}</mat-icon>
            {{border.label}}
          </mat-option>
      </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select placeholder="Border Color" formControlName="borderColor">
        <mat-option *ngFor="let borderColor of borderColors" [value]="borderColor.value">
          {{borderColor.label}}
        </mat-option>
      </mat-select>
      </mat-form-field>
    </div>
    </div>
    <!-- End Border colors and settings.-->


  </div>
  <!-- <button (click)="logFormState()">Testing</button> -->
