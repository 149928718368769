import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {TextFieldModule} from '@angular/cdk/text-field';
import { FormlyTextboxAutosizeTriggerableComponent } from './formly-textbox-autosize-triggerable.component';
import { FormlyModule } from '@ngx-formly/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [FormlyTextboxAutosizeTriggerableComponent],
  imports: [
    MatIconModule,
    MatTooltipModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    CommonModule,
    TextFieldModule,
    FormlyMaterialModule,
    FormlyModule.forChild({
      types: [
        {name: 'formlyTextAreaAutoSizing', component: FormlyTextboxAutosizeTriggerableComponent},
      ]}),
  ],
  exports: [FormlyTextboxAutosizeTriggerableComponent],
  providers: [provideNgxMask()]
})
export class FormlyTextboxAutosizeTriggerableModule { }
