import { Injectable } from '@angular/core';
import { addHours, addMinutes, getHours, getMinutes, startOfDay, subHours } from 'date-fns';
import { BehaviorSubject, filter } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

function mutateDateTimeToToday(date: Date) {
  return addMinutes(startOfDay(new Date()), getMinutes(date) + getHours(date) * 60);
}

@Injectable({
  providedIn: 'root'
})

export class BryntumSettingsService {

  public static configuration = {};
  public updatedSettings$ = new BehaviorSubject<boolean>(false);
  public settingsReadFromLocalStorage$ = new BehaviorSubject<boolean>(false);


  getValue(key: string): any {
    return BryntumSettingsService.configuration[key];
  }

  mapDateSettingsToDates(settings: any)  : any {
    const datedSettings = {};
        for (var prop in settings)
          datedSettings[prop] =   ( typeof(settings[prop]) === 'number' || isNaN(Date.parse(settings[prop]))) ? settings[prop] : new Date(Date.parse(settings[prop]));
        return datedSettings;
  }

  bryntumStartDate() : Date {
    return mutateDateTimeToToday(subHours(this.getValue("businessStartHour"), this.getValue("hoursBeforeBusinessStartTimeToDisplay") ));
  }

  bryntumEndDate() : Date {
    return mutateDateTimeToToday(addHours(this.getValue("businessEndHour"), this.getValue("hoursAfterBusinessEndTimeToDisplay") ));
  }


  constructor() {
    BryntumSettingsService.configuration = this.mapDateSettingsToDates(localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : {});
    this.updatedSettings$.pipe(
      filter(x => x),
      map(() => localStorage.getItem('settings')),
      distinctUntilChanged(),
      map(x => x ? JSON.parse(localStorage.getItem('settings')) : {}),
      map(settings => this.mapDateSettingsToDates(settings)),
      tap(settings => BryntumSettingsService.configuration = settings),
      tap(() => this.settingsReadFromLocalStorage$.next(true)),
    ).subscribe();
  }
}
