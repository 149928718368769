import {  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder,  UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable,  Subject, switchMap } from 'rxjs';
import { delay, distinctUntilChanged,  filter,  map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { WorkflowSelectionModalComponent } from '../branching-container/workflow-selection-modal/workflow-selection-modal.component';
import { ChoiceControlType } from '../choice/choice.component';
import { BorderColor, BorderOptionType } from '../column-splitter/column-splitter.component';
import { underlineControlOptions } from '../component-models/control-container.component';
import { LineItemControlType } from '../component-models/line-items/line-item-enums';
import { RatingControlDefaultService } from '../component-models/rating-control/rating-control-default.service';
import { DataLinkPopulatorComponent } from '../data-link-populator/data-link-populator.component';
import { DataLink, DataLinkService } from '../data-link-populator/data-link.service';
import { Audience } from '../component-models/formly-controls/formly-utility.service';
import { DerivedWorkflowFieldService } from '../derived-workflow-field.service';
import { ImportWorkflowAssignmentService } from '../import-workflow-assignment.service';
import { PriceBookEntry } from '../../../../../common/src/data/dao/pricebook/pricebook-entry';
import { FormlyComponentUtilityService } from '../component-models/formly-controls/formly-component-utility.service';

interface LabelLocation {
  value: number;
  label: string;
}

interface underlineOptions {
  value: underlineControlOptions;
  label: string;
}

interface AudienceInterface {
  value: Audience;
  label: string;
}

interface ChooserOptions {
  value: ChoiceControlType;
  label: string;
}

interface LineItemControlTypeOptions {
  value: LineItemControlType;
  label: string;
}

interface StandardRatingOptions {
  value: UntypedFormArray,
  label: string
}

interface BorderOptions {
  value: BorderOptionType;
  label: string;
  icon: any;
}

interface BorderColorOptions {
  value: BorderColor;
  label: string;
}

@Component({
  selector: 'app-sidebar-component-editor',
  templateUrl: './sidebar-component-editor.component.html',
  styleUrls: ['./sidebar-component-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponentEditorComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('defaultText') bodyRef: ElementRef;

  changeDetect$: Subject<any> = new Subject();

  @Input () form$: BehaviorSubject<UntypedFormGroup>;
  @Input () parentForm$: BehaviorSubject<UntypedFormGroup>;
  @Input () newControl: Observable<any>;
  @Output() buildCaclulatedField: Subject<void> = new Subject<void>();
  @Output() deleteCalculatedField: Subject<void> = new Subject<void>();
  guid: string = undefined;
  ratingGuid: string = undefined;
  questionsGuid: string = undefined;
  dataLinkDialogRef: MatDialogRef<DataLinkPopulatorComponent>;
  workflowSelectionModalDialogRef: MatDialogRef<WorkflowSelectionModalComponent>;
  destroyingComponent$ = new Subject();
  suscribeToNewDataLink$ = new Subject<string>();
  numericMask = "";
  thousandSeparator=",";

  avaliableChoicesFormGroup : UntypedFormGroup;
  ratingScaleFormGroup: UntypedFormGroup;
  ratingQuestionsFormGroup: UntypedFormGroup;

  labelLocationOptions: LabelLocation[] = [
    { value: 1, label: "Left"},
    { value: 0, label: "Above"},
  ];

  underlineOptions : underlineOptions[] = [
    {value: underlineControlOptions.None, label: "None"},
    {value: underlineControlOptions.Black, label: "Black"},
    {value: underlineControlOptions.Grey, label: "Grey"},
  ];

  audienceOptions: AudienceInterface[] = [
    { value: Audience.All, label: 'All' },
    { value: Audience.Internal, label: 'Tech' },
    { value: Audience.Customer, label: 'Customer' },
  ];

  borderOptions: BorderOptions[] = [
    {value: BorderOptionType.InnerAndOuter, label : 'Inner and Outer', icon : 'border_inner'},
    {value: BorderOptionType.Outer, label: 'Outer', icon : 'border_outer'},
    {value: BorderOptionType.None, label: 'None',  icon : 'border_clear'},
  ];

  scaleOptions!: StandardRatingOptions[];

  compareFn(f1: BorderOptions, f2: BorderOptions): boolean {
    return f1 && f2? f1.value === f2.value : f1 === f2;
  }

  borderColors: BorderColorOptions[] = [
    {value: BorderColor.Black, label: 'Black'},
    {value: BorderColor.Grey, label: 'Grey'},
  ];

  chooserOptions: ChooserOptions[] = [
    { value: 1, label: 'Drop Down' },
    { value: 2, label: 'Radio Buttons' },
    { value: 3, label: 'Checkboxes' },
  ];

  lineItemDisplayTypeOptions: LineItemControlTypeOptions[] = [
    {value: 1, label: 'Estimate Creator'},
    {value: 2, label: 'Line Items to Complete'},
    {value: 3, label: 'Invoice Creator'}
  ];

  constructor(private fb: UntypedFormBuilder, private dialog: MatDialog, private ratingDefaultsService: RatingControlDefaultService,
    private dataLinkService: DataLinkService, private derivedWorkflowService: DerivedWorkflowFieldService,
    private importWorkflowAssignmentService: ImportWorkflowAssignmentService, private formlyComponentUtilityService: FormlyComponentUtilityService) {
      this.scaleOptions = this.ratingDefaultsService.Defaults();
  }

  get minimumPhotoCount$() {
    return this.controlComponentFormGroup.valueChanges.pipe(
      map(() => this.controlComponentFormGroup.controls.minimumPhotoCount),
    );
  }
  get maximumPhotoCount$() {
    return this.controlComponentFormGroup.valueChanges.pipe(
      map(() => this.controlComponentFormGroup.controls.maximumPhotoCount),
    );
  }
  get minimumNumberInstances$() {
    return this.controlComponentFormGroup.valueChanges.pipe(
      map(() => this.controlComponentFormGroup.controls.minimumNumberInstances),
    );
  }
  get maximumNumberInstances$() {
    return this.controlComponentFormGroup.valueChanges.pipe(
      map(() => this.controlComponentFormGroup.controls.maximumNumberInstances),
    );
  }

  get controlComponentFormGroup() {
    return this.form$.value.controls['controlComponentFormGroup'].value as UntypedFormGroup;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.form$.pipe(
      map(x => x.get("readOnlyDisabled").value),
      distinctUntilChanged(),
      tap(disabled => disabled ? this.form$.value.controls["readonly"].disable() : this.form$.value.controls["readonly"].enable()),
      tap(() => this.form$.next(this.form$.value)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    //Change masking behavior on textbox controls depending on whether field is meant to only support
    //numeric input, and the number of decimal places desired.
    this.form$.pipe(
      filter(x => x.value.controlName === "Text Box"),
      switchMap(() => combineLatest(
        [this.controlComponentFormGroup.controls['numericMaskDesired'].valueChanges.pipe(startWith(this.controlComponentFormGroup.controls['numericMaskDesired'].value)),
        this.controlComponentFormGroup.controls['numberDecimalPlaces'].valueChanges.pipe(startWith(this.controlComponentFormGroup.controls['numberDecimalPlaces'].value))])),
      distinctUntilChanged((a,b) => a[0] === b[0] && a[1] === b[1]),
      tap(numericOnly => {
        if (numericOnly[0]) {
          const defaultValue = this.controlComponentFormGroup.get('value').value;
          if (defaultValue === "") {
            this.controlComponentFormGroup.patchValue({value: "0"});
          }
          this.numericMask = `separator.${numericOnly[1]}`;
          this.thousandSeparator=",";
        } else {
          this.numericMask = "";
          this.thousandSeparator="";
        }
        this.changeDetect$.next(null);
      }),
      tap(numericOnly => numericOnly[0] ? this.controlComponentFormGroup.get('numberDecimalPlaces').enable() :
        this.controlComponentFormGroup.get('numberDecimalPlaces').disable()),
      tap(() => this.form$.next(this.form$.value)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

   firstChange: boolean = false;

  logFormState(): void {
    console.log(this.form$.value);
    console.log(this.parentForm$.value);
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
}

  provideLabel(controlName: string) : boolean {
    switch (controlName) {
      case "Text Box":
      case "Choice":
      case "Photo Adder":
      case "Rating Scale":
      case "Line Items":
      case "Branching":
      return true;
      break;
      default:
        return false;
    }
  }

  labelLocationSpecified(controlName: string) : boolean {
    switch (controlName) {
      case "Text Box":
      case "Choice":
        return true;
        break;
      case "Photo Adder":
        return false;
        break;
      default:
        return false;
    }
  }


  deleteDataLink()
  {
    this.suscribeToNewDataLink$.next(this.form$.value.get("guid").value);
    const formToPatch = this.controlComponentFormGroup;
    formToPatch.controls['value'].enable();
    formToPatch.patchValue({value: "", dataLink: {}});
  }

  loadDataLinkModal() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      formCatagory: "all"
    });

    this.dataLinkDialogRef = this.dialog.open(DataLinkPopulatorComponent, editorConfig);

    // Event dialog closure:
    this.dataLinkDialogRef.afterClosed().pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.eventDialogClosure(x));

  }

  addSelectedPricebookEntries() {
    const options = this.controlComponentFormGroup.get('chooserFormGroup').value.value.get('options') as UntypedFormArray;
    (this.importWorkflowAssignmentService.selection.selected as PriceBookEntry[]).forEach((selected) => {
      const formSummaryDocId = selected.formFirestoreSummaryDefiningPriceBookEntry ? selected.formFirestoreSummaryDefiningPriceBookEntry.DocId() :
         selected.unitOfMeasure.formFirestoreSummaryDocId;
      const newVal = this.fb.group({
        label: this.fb.control(selected.title),
        value: `${formSummaryDocId}-${selected.DocId()}`,
        priceBookEntryDocId: selected.DocId(),
        focused: false
      });
      options.push(newVal);
      this.importWorkflowAssignmentService.selection.clear();
      this.importWorkflowAssignmentService.externalSelectedWorkflowAssignmensUpdated$.next(null);
    });
  }

  loadWorkflowSelectionModal() {

    const editorConfig = new MatDialogConfig();

    const options = this.controlComponentFormGroup.get('chooserFormGroup').value.value.get('options') as UntypedFormArray;
    const activeDocIds = [];
    options.controls.forEach(c => {
      activeDocIds.push(c.get("value").value);
    });
    Object.assign(editorConfig, {
      data: {
      activeDocIds: activeDocIds
      }
    });

    this.workflowSelectionModalDialogRef = this.dialog.open(WorkflowSelectionModalComponent, editorConfig);

    // Event dialog closure:
    this.workflowSelectionModalDialogRef.afterClosed().pipe(
      filter(x => x),
      map(x => x as FormFirestoreSummary[]),
      tap(x => {
        const options = this.controlComponentFormGroup.get('chooserFormGroup').value.value.get('options') as UntypedFormArray;
        options.clear();
        x.forEach(formFirestoreSummary => {
        const newVal = this.fb.group({
          label: this.fb.control(formFirestoreSummary.title),
          value: formFirestoreSummary.DocId(),
          focused: false
        });
        options.push(newVal);
      });
      this.changeDetect$.next(null);
      }),
      take(1)
      ).subscribe();
  }

  eventDialogClosure(dataLinks: DataLink[]) {
    if (dataLinks !== undefined) {
      const insertString = DataLinkService.mapDataLinksToInsertString(dataLinks);
        const loc = this.bodyRef.nativeElement.selectionStart;
        const formGroup = this.controlComponentFormGroup;
        const prev = formGroup.value.value as string;
        if (prev !== "") {
          formGroup.patchValue({value: prev.substr(0,loc) + insertString + prev.substr(loc), dataLinks: dataLinks });
        } else {
          formGroup.patchValue({value: insertString, dataLinks: dataLinks});
        }
    }
  }

  initilizeAvailibleChoicesForm(choiceFormGroup: UntypedFormGroup) : UntypedFormGroup {
    if (this.guid !== this.form$.value.get("guid").value) {
      this.avaliableChoicesFormGroup = this.fb.group({
        title: ["Choices"],
        draggableComponents: (choiceFormGroup.get('options') as UntypedFormArray),
        allowAddFromDragDrop: choiceFormGroup.get('allowAddFromDragDrop').value,
      }, {updateOn: "blur"});
      this.guid = this.form$.value.get("guid").value;
    }
    return this.avaliableChoicesFormGroup;
  }

  initilizeRatingScaleForm() : UntypedFormGroup {
    if (this.ratingGuid !== this.form$.value.get("guid").value) {
      this.ratingScaleFormGroup = this.fb.group({
        title: ["Rating Scale"],
        draggableComponents: (this.controlComponentFormGroup.get('columns') as UntypedFormArray),
        allowAddFromDragDrop: this.controlComponentFormGroup.get('allowAddFromDragDrop').value,
      });
      this.ratingGuid = this.form$.value.get("guid").value;

      this.form$.value.get("ratingPrepopulatedOption")?.valueChanges.pipe(
        tap(x => {
          const cols = (this.ratingScaleFormGroup.get("draggableComponents") as UntypedFormArray);
          cols.clear();
          x.controls.forEach(val => cols.push(val));
          }),
        takeUntil (this.destroyingComponent$)
      ).subscribe();
    }
    return this.ratingScaleFormGroup;
  }

  initilizeRatingQuestionsForm() : UntypedFormGroup {
    if (this.questionsGuid !== this.form$.value.get("guid").value) {
      this.ratingQuestionsFormGroup = this.fb.group({
        title: ["Questions"],
        draggableComponents: (this.controlComponentFormGroup.get('data') as UntypedFormArray),
        allowAddFromDragDrop: this.controlComponentFormGroup.get('allowAddFromDragDrop').value,
      });
      this.questionsGuid = this.form$.value.get("guid").value;
    }
    return this.ratingQuestionsFormGroup;
  }

  buildCalculatedField() : void {
    this.buildCaclulatedField.next(void 0);
  }

  deleteCalcualtedField() : void {
    this.deleteCalculatedField.next(void 0);
  }

  get calculatedFieldPresent() : Observable<boolean> {
    return this.controlComponentFormGroup.get('derivedCalculation').valueChanges.pipe(
      startWith(this.controlComponentFormGroup.get('derivedCalculation').value),
      map(x => x !== null && x !== ""),
    )
  }

}
