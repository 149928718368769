import { Component, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, filter, of, Subject, switchMap} from 'rxjs';
import { debounceTime, delay,  map,  startWith, take, takeUntil, tap } from 'rxjs/operators';
import { LabelLocation, underlineControlOptions } from '../control-container.component';
import { ControlContainsLiveviewComponent } from '../control-contains-liveview.component';
import { DataLink, DataLinkService, DataRetrievedFromDataLink } from '../../data-link-populator/data-link.service';
import { PriceBookEntry } from '../../../../../../common/src/data/dao/pricebook/pricebook-entry';
import { PriceBookUnitOfMeasure } from '../../../../../../common/src/data/dao/pricebook/pricebook-unit-of-measure';
import { DerivedWorkflowFieldService } from '../../derived-workflow-field.service';
import { FormlyUtilityService } from '../formly-controls/formly-utility.service';
import { RemapAssociatedComponentsService } from './remap-associated-components.service';


@Component({
  selector: 'app-textbox-control',
  templateUrl: './textbox-control.component.html',
  styleUrls: ['./textbox-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TextboxControlComponent extends ControlContainsLiveviewComponent implements OnDestroy, AfterViewInit, OnInit  {

  @ViewChild('resizeTemplate') resizeContainerRef: ElementRef;
  resizeHeight$: Subject<any>  = new Subject<any>();
  selectedForDerivedField: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  badgeNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  linkedToImportedData$: BehaviorSubject<PriceBookEntry|PriceBookUnitOfMeasure> = new BehaviorSubject<PriceBookEntry|PriceBookUnitOfMeasure>(null);
  importedDataToolTip$: BehaviorSubject<string> = new BehaviorSubject<string|null>(null);
  remapAssociatedInputComponents$: Subject<null> = new Subject<null>();
  remapAssociatedCalculatedComponents$: Subject<null> = new Subject<null>();

  constructor(protected fb: UntypedFormBuilder,private dataLinkService: DataLinkService, private formlyUtilityService: FormlyUtilityService,
    private remapAssociatedComponentsService: RemapAssociatedComponentsService, private derivedWorkflowFieldService: DerivedWorkflowFieldService)
  {
    super(TextboxControlComponent,fb);
    this.form = this.initilizeFormGroup();

    this.remapAssociatedCalculatedComponents$.pipe(
      tap(() => this.addRemapOperation("Input")),
    ).subscribe();

    this.remapAssociatedInputComponents$.pipe(
      tap(() => this.addRemapOperation("Calculated")),
    ).subscribe();

    this.fields = [
      this.formlyLiveViewFields({
        type: "input",
        label: "",
        hidden: true,
        props: {
          appearance: "outline",
          asLabel: false,
          numericMaskDesired: false,
          numberDecimalPlaces: 2,
          guid: Math.random().toString(36).substring(0,14),
          calculatedComponentNodeMappings: [],
          inputComponentNodeMappings: [],
          derivedCalculation: null,
          derivedCalculationNote: "",
        },
        className: "",
      }),

      this.formlyLiveViewFields({
        type: "formlyTextAreaAutoSizing",
        label: "",
        hidden: false,
        props: {
          autosize: true,
          autosizeMinRows: 0,
          autosizeMaxRows: 10000,
          appearance: "outline",
          asLabel: false,
          numericMaskDesired: false,
          numberDecimalPlaces: 2,
          guid: Math.random().toString(36).substring(0,14),
          calculatedComponentNodeMappings: [],
          inputComponentNodeMappings: [],
          derivedCalculation: null,
          derivedCalculationNote: "",
        },
        className: "bigGreg",
      }),
    ];

  }

  toFormlyFieldConfigJsonOnly() : FormlyFieldConfig {
    const retVal = super.toFormlyFieldConfigJsonOnly();
    if (retVal.props.dataLinks?.length > 0) {
      retVal.defaultValue = "";
      const dataLinksToPopulate = (retVal.props.dataLinks as Array<DataLink>).map(x => new DataRetrievedFromDataLink({dataLinkId: x.id}));
      const {dataLinks,priceBookEntryToolTip$, ...newObj} = retVal.props;
      newObj.dataLinksToPopulate = dataLinksToPopulate;
      if (newObj.appearance === "none") {
        newObj.appearance = "fill";
      }
      retVal.props = newObj;
    } else {
      const {priceBookEntryToolTip$, ...newObj} = retVal.props;
      retVal.props = newObj;
    }
    retVal.wrappers = ["manual-label","form-field"];
    return retVal;
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    // Currently textbox control does not trigger re-generating formly field on converstion from textarea -> input ( though it appears to, and functionally does for
    // current known requirement set).  If need trigger update, look to Choice Component for example of how to do this.
    if (this.fields[1].props.appearance === "none") {
      this.fields[1].props.appearance = "fill";
    }
    if (this.fields[0].props.appearance === "none") {
      this.fields[0].props.appearance = "fill";
    }
    let retVal: FormlyFieldConfig = {};
    if (this.fields[1].props.hidden) {
      retVal = super.convertFormlyFieldConfigToLiveViewVersion(this.fields[0]);
    } else {
      retVal = super.convertFormlyFieldConfigToLiveViewVersion(this.fields[1]);
      retVal.wrappers = ["manual-label","change-detect", "form-field"];
    }
    if (retVal.props["value"]) {
       retVal.defaultValue = retVal.props["value"];
    }
  return retVal;
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Text Box");
    retVal.patchValue({
      icon: "text_fields",
      controlComponentFormGroup: this.createTextBoxFormGroup(),
    });
    return retVal;
  }

  private resizeObserver = new ResizeObserver((entries) => {
    this.resizeHeight$.next(null);
  });

  private addRemapOperation(mapping: "Input" | "Calculated") {
    //20241219 Uncomment when testing sections perhaps.
    console.log(mapping, "crow");
    console.log(this,"crow");
    this.remapAssociatedComponentsService.addAssociatedComponentOperation(
      of(null).pipe(
      switchMap(() => this.derivedWorkflowFieldService.updateComponentNodeMappings(this.componentForm.value.guid, this.formlyUtilityService.mainDropZoneComponents,mapping)),
      take(1)
      )
    );
  }

  ngOnInit(): void {
    this.linkedToImportedData$.pipe(
      tap(x => {
        if (x===null) {
          this.importedDataToolTip$.next(null);
          return;
        }
        if (x instanceof PriceBookEntry) {
          this.importedDataToolTip$.next(`Linked to: ${x.title} \n Catagory: ${ (x as PriceBookEntry).priceBookCatagory.name}`);
        } else {
          this.importedDataToolTip$.next(`Linked to Unit of Measure: ${x.title}`);
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.controlContainerCommonDestruction();
    this.NumberComponents--;
  }

  createTextBoxFormGroup() {
    return this.fb.group({
      numericMaskDesired: false,
      numberDecimalPlaces: 2,
      guid: Math.random().toString(36).substring(0,14),
      calculatedComponentNodeMappings: [[]],
      inputComponentNodeMappings: [[]],
      derivedCalculation: null,
      derivedCalculationNote: "",
      labelLocation: LabelLocation.Left,
      labelBold: false,
      underline: underlineControlOptions.None,
      label:  "Default label",
      placeholder: "Default Placeholder",
      value: "",
      multiline: true,
      dataLinks: [[]],
      priceBookEntryToolTip$: new BehaviorSubject<string | null>(null),
      appearance: "outline",
    });
  }

  convertToLabel() {
    this.componentForm.patchValue({cls: "mat-form-field-appearance-none", label: ""});
    this.componentForm.patchValue({labelLocation: LabelLocation.Above});
    this.form.patchValue({icon: "label", iconColor: "#dc1414", controlName: "Label", "readonly": true});
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {
    super.patchCommonFieldsToForm(formlyConfig);
    for (var prop in formlyConfig.props) {
      if (prop === "appearance" && formlyConfig.props[prop] === "none") {
        formlyConfig.props[prop] = "fill";
      }
      if (this.componentForm.controls[prop] !== undefined) {
        this.componentForm.controls[prop].patchValue(formlyConfig.props[prop]);
      }
    }

    if (formlyConfig.props.dataLinksToPopulate !== undefined && formlyConfig.props.dataLinksToPopulate.length > 0) {
      (formlyConfig.props.dataLinksToPopulate as Array<any>).forEach(link => this.componentForm.controls["dataLinks"].value.push
        (DataLinkService.dataLinks.find(x => x.id === link.dataLinkId)));
      }

    if (formlyConfig.props.asLabel) {
      this.convertToLabel();
    }

    this.patchLabelRelatedClassesToComponentForm(formlyConfig);

    this.derivedWorkflowFieldService.textBoxesByGuid.set(formlyConfig.props.guid, this);
  }

  patchControlComponentsToFormlyFields(): void {

    const obs$ = this.patchControlComponentsToFormlyFieldsCommon();

    //simple patches
    const simplePatches = ["numericMaskDesired","numberDecimalPlaces", "guid", "calculatedComponentNodeMappings", "inputComponentNodeMappings", "derivedCalculation", "derivedCalculationNote"];
    simplePatches.forEach(x => {
      obs$.push(this.componentForm.get(x).valueChanges.pipe(
        startWith(this.componentForm.get(x).value),
        tap(y => this.patchValueToFields(x, [this.fields[0], this.fields[1]], y))));
    });

    // Multiline hides textarea / input field depending on value.  This only affects the formlyField in Form Builder mode, as
    // control only includes active field when creating live view.
    obs$.push(this.componentForm.get("multiline").valueChanges.pipe(
      startWith(this.componentForm.get("multiline").value),
      tap(x => {
        this.fields[0].props["hidden"] = x;
        this.fields[1].props["hidden"] = !x;
      })));

      obs$.push(this.importedDataToolTip$.pipe(
        startWith(this.importedDataToolTip$.value),
        tap(x => {
          this.fields[0].props["priceBookEntryToolTip$"].next(x);
          this.fields[1].props["priceBookEntryToolTip$"].next(x);
        })
      ));

      obs$.push(this.patchLabelRelatedFromComponentFormToTemplateOptions([this.fields[0], this.fields[1]]));

      obs$.push(this.form.get("controlName").valueChanges.pipe(
        startWith(this.form.get("controlName").value),
        map(x => x === "Label" ? true : false),
        tap(x => {
          this.fields[0].props["asLabel"] = x;
          this.fields[1].props["asLabel"] = x;
          if (x) {
            this.componentForm.patchValue({labelLocation: LabelLocation.Above});
          }
        })));

    obs$.push(this.componentForm.get("value").valueChanges.pipe(
      startWith(this.componentForm.get("value").value),
      tap(x => {
        this.fields[0].formControl.setValue(x);
        this.fields[0].props.placeholder = x;
        this.fields[1].formControl.setValue(x);
        })));

    combineLatest([...obs$]).pipe(
      tap(() => {
        this.fields[0].props.changeDetect.next();
      }),
      takeUntil(this.destroyingComponent$.pipe(delay(10000)))
    ).subscribe();
    }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.NumberComponents++;

    this.resizeHeight$.pipe(
      debounceTime(100),
      tap(() => this.changeDetect$.next(null)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    of(null).pipe(
      delay(1),
      tap(() => this.patchControlComponentsToFormlyFields()),
      take(1),
    ).subscribe();

    of(null).pipe(
      delay(1),
      tap(() => this.resizeObserver.observe(this.resizeContainerRef.nativeElement,  {box: "content-box"})),
      tap(() =>  this.changeDetect$.next(null)),
      take(1)
    ).subscribe();

    this.destroyingComponent$.subscribe(() => this.resizeObserver.disconnect());

    this.fields[1].form.valueChanges.pipe(
      debounceTime(10),
      tap(() => this.changeDetect$.next(null)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }
}
