import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { merge, Observable, Subject } from 'rxjs';
import {  delay, distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ControlContainerComponent } from '../control-container.component';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad-editor.component.html',
  styleUrls: ['./signature-pad-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SignaturePadEditorComponent extends ControlContainerComponent implements AfterViewInit {

  @ViewChild('rootContainer') rootContainer: ElementRef;

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig  {
    const retVal = this.toFormlyFieldConfig() as FormlyFieldConfig;
     //Json only representation strips out formControl, and changeDetection subject from props.
     const {formControl, ...newObj} = retVal;
     const {changeDetect,...temps} = retVal.props;
     newObj.props = temps;
     newObj.wrappers = [];
    return newObj;
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    return  this.fields[0];
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Signature");
    retVal.patchValue({
      icon: "border_color",
      iconColor: "marroon",
      controlComponentFormGroup: this.createSignaturePadFormGroup(),
    });
    return retVal;
  }

  createSignaturePadFormGroup() {
    return this.fb.group({
    })
  }

  patchControlComponentsToFormlyFields(): void {
    const obs$ : Observable<any>[] = this.patchControlComponentsToFormlyFieldsCommon();

    obs$.push(
      this.form.get("indexInParentContainer").valueChanges.pipe(
        startWith(this.form.get("indexInParentContainer").value),
        tap(x => this.fields[0].props.indexInParentContainer = x)
      )
    );

    merge(...obs$).pipe(
      tap(() => this.fields[0].props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig ): void {
    super.patchCommonFieldsToForm(formlyConfig);
  }

  constructor(protected fb: UntypedFormBuilder) {
    super(SignaturePadEditorComponent,fb);

    this.form = this.initilizeFormGroup();

    this.fields =
    [
      {
        key: `${this.perInstance}-signatureComponent`,
        type: 'formlySignaturePad',
        props: {
          imageWidthPixels: 0,
          imageHeightPixels: 0,
          changeDetect: new Subject<any>(),
          resizing: false,
          imageUrl: "/assets/SignHere.png"

        },
        wrappers: ["change-detect"],
        formControl: this.fb.group ({
          imageWidthPixels: 0,
          imageHeightPixels: 0,
        }),
      }
    ]
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.patchControlComponentsToFormlyFields();
    this.suscribeToChangesInWidth();
    const width = this.rootContainer.nativeElement.offsetWidth;
    const height = 99 * width / 432;
    this.fields[0].props.imageHeightPixels = height;
    this.fields[0].props.imageWidthPixels = width;
    this.fields[0].formControl.patchValue({imageHeightPixels: height, imageWidthPixels: width});
    this.fields[0].props.changeDetect.next();

    this.form.get("resizing").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.fields[0].props.resizing = x),
      tap(() => this.fields[0].props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  suscribeToChangesInWidth() {
    this.form.controls["percentTotalWidth"].valueChanges.pipe(
      startWith(100),
      delay(1),
      map(() => this.rootContainer.nativeElement.offsetWidth),
      tap(width => {
        // Sign Here.png is 432 x 99 , so we are scaling w/ below.
        const height = 99 * width / 432;
        this.fields[0].props.imageHeightPixels = height;
        this.fields[0].props.imageWidthPixels = width;
        this.fields[0].formControl.patchValue({imageHeightPixels: height, imageWidthPixels: width});
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

}
