import { Injectable } from '@angular/core';
import { NgxLoggerLevel } from 'ngx-logger';
import { Subject } from 'rxjs';
import { LogEntry } from './logEntry';
import { LocalSettingsService } from '../../../../web-app/src/app/settings/local-settings.service';

@Injectable({
  providedIn: 'root'
})

export class LoggingService {

  logEntryToAdd : Subject<LogEntry> = new Subject<LogEntry>();
  constructor(private localSettingsService: LocalSettingsService) {
  }

  public generateLog(message : string, origin: string | null = null, obj: any = null, logLevel: NgxLoggerLevel = NgxLoggerLevel.LOG) {
    const logEntry = new LogEntry();
    logEntry.generationTime = new Date();
    logEntry.logType = logLevel;
    logEntry.origin = origin;
    logEntry.message = message;
    logEntry.state = obj;
    logEntry.app = this.localSettingsService.app;
    logEntry.constructorGuid = this.localSettingsService.constructorGuid;
    logEntry.userName = this.localSettingsService.loggedInEmployeeName;
    logEntry.userDocId = this.localSettingsService.loggedInEmployeeDocId;
    logEntry.DeviceId = this.localSettingsService.deviceId;
    logEntry.platform = this.localSettingsService.platform;
    logEntry.constructorInitilized = this.localSettingsService.constructorInitilized;
    return logEntry;
  }

  public addLog(message : string, origin: string | null = null, obj: any = null, logLevel: NgxLoggerLevel = NgxLoggerLevel.LOG){

    const logEntry = this.generateLog(message, origin, obj, logLevel);
    this.logEntryToAdd.next(logEntry);
  }
}
