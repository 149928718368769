import { AfterViewInit, Component,  OnInit, ChangeDetectionStrategy, OnDestroy, inject, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, ReplaySubject,  Subject,  } from 'rxjs';
import { filter, map, switchMap, delayWhen, tap, takeUntil, } from 'rxjs/operators';

import { EmployeeService } from '../../data/dao-services/employee.service';
import { FormlyLineItemService } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-line-item/formly-line-item.service';
import { FormlyUtilityService } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-utility.service';
import { WORKFLOW_STAGE } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/utilities/fetch-updated-workflow.service';
import { FormModelFirestore } from '../../data/dao/form-model-firestore';
import { FormModelFirestoreService } from '../../data/dao-services/form-model-firestore.service';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { Audience } from '../../../../web-app/src/app/line-item/line-item-display/line-item-display.component';
import { FormFirestoreWorkflowComponentService } from './form-firestore-workflow-component.service';

/**
 * Responsible for rendering a workflow.
 */
@Component({
  selector: 'app-form-firestore-workflow',
  templateUrl: './form-firestore-workflow.component.html',
  styleUrls: ['./form-firestore-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFirestoreWorkflowComponent implements OnInit, AfterViewInit, OnDestroy {

  get formModelFirestore() : FormModelFirestore {
    return this.formlyUtilityService.activeFormModelFirestore;
  }

  get model() { return this.formFirestoreWorkflowComponentService.model; }
  set model(val) { this.formFirestoreWorkflowComponentService.model = val; }
  get formlyFields() { return this.formFirestoreWorkflowComponentService.formlyFields; }
  get workflowStage() { return this.formFirestoreWorkflowComponentService.workflowStage; }
  get form() { return this.formFirestoreWorkflowComponentService.form; }
  get loadedInitialFormModelFirestore$() { return this.formFirestoreWorkflowComponentService.loadedInitialFormModelFirestore$; }
  get hardFormlyReset$() { return this.formFirestoreWorkflowComponentService.hardFormlyReset$; }
  get formlyOptions() { return this.formFirestoreWorkflowComponentService.formlyOptions; }
  get savedFormHistorySnapshot() { return this.formFirestoreWorkflowComponentService.savedFormHistorySnapshot; }

  destroyingComponent$ = new Subject();


  jobSuccessfullyMarkedAsCompleted$ = new BehaviorSubject<boolean>(true);

  jobInitiallyLoaded$ = new ReplaySubject<boolean>(1);
  // this is used as optimization so that we only update the model if possible, rather then re-render all formly-form components
  previousBranchKeysToSelectedFirestoreDocIds : any;

  get audience(): Audience {
    return this.formFirestoreWorkflowComponentService.audience;
  }


  constructor(private route: ActivatedRoute, private formModelFirestoreService: FormModelFirestoreService, public formlyUtilityService: FormlyUtilityService,
    public employeeService: EmployeeService, private formlyLineItemService: FormlyLineItemService,  private settingsService: SettingsService,
    private formFirestoreWorkflowComponentService: FormFirestoreWorkflowComponentService, public ref: ChangeDetectorRef) {

      this.formFirestoreWorkflowComponentService.manualChangeDetection$.pipe(
        tap(() => this.ref.markForCheck()),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
    }


    ngOnDestroy(): void {
      console.log("DESTRYOING THE FORM FIRESTORE WORKFLOW COMPONENT");
      //make updates to underlying service depending on whether in design or deployed stage.
      if (this.formlyUtilityService.workFlowStage === WORKFLOW_STAGE.DEPLOYED) {
        this.formlyUtilityService.resetBranchesAndLeafs();
        this.formFirestoreWorkflowComponentService.destroyAllFormFirestoreWorkflowComponentObservables.next(void 0);
        this.formFirestoreWorkflowComponentService.onDeployedDestruction();
      }
      this.destroyingComponent$.next(null);
      this.destroyingComponent$.complete();
      }

    /**
     * Patches in routing params.  These should be populated to the workflow service, nothing more.
     */
    patchInRoutingParams() {
      if (this.route.snapshot.paramMap.get('siteVisitDocId')) {
        this.formFirestoreWorkflowComponentService.activeSiteVisitDocId = this.route.snapshot.paramMap.get('siteVisitDocId');
      }

      if (this.route.snapshot.paramMap.get('jobDocId')) {
        this.formFirestoreWorkflowComponentService.jobDocId = this.route.snapshot.paramMap.get('jobDocId')!;
      }

      if (this.route.snapshot.paramMap.get('customerDocId')) {
        this.formFirestoreWorkflowComponentService.customerDocId = this.route.snapshot.paramMap.get('customerDocId');
      }

      if (this.route.snapshot.paramMap.get('expandLineItemControls')) {
        this.formlyUtilityService.expandLineItems = true;
      }

      if (this.route.snapshot.paramMap.get('cf')) {
        this.formlyUtilityService.customerFacing = true;
      }


      if (this.route.snapshot.paramMap.get('invoiceDocId')) {
        if (this.route.snapshot.paramMap.get('invoiceDocId') !== 'null') {
          this.formlyLineItemService.explicitInvoiceDocId = this.route.snapshot.paramMap.get('invoiceDocId');
        }
      }
      if (this.route.snapshot.paramMap.get('estimateDocId')) {
        if (this.route.snapshot.paramMap.get('estimateDocId') !== 'null') {
          this.formlyLineItemService.explicitEstimateDocId = this.route.snapshot.paramMap.get('estimateDocId');
        }
    }
  }

    ngAfterViewInit(): void {
     this.formFirestoreWorkflowComponentService.patchInTechViewUpdates();
  }

    ngOnInit(): void {
      this.formlyUtilityService.generateNewFormInstanceUuid();
      this.patchInRoutingParams();

      this.formFirestoreWorkflowComponentService.ngOnInitReplacement();

      // On routing to component load form model firestore parameter.
    this.route.paramMap.pipe(
      delayWhen(() => this.settingsService.settingsLoaded$),
      map(params => params.get("formModelFirestoreDocId")! ),
      filter(docId => docId !== null),
      switchMap(docId => this.formModelFirestoreService.load$(docId)),
      filter(x=>x!==null && x.formFirestoreDocId !== undefined),
    ).subscribe(this.formFirestoreWorkflowComponentService.loadedFromRoute);


    }
  }
