import { AfterViewInit, ChangeDetectionStrategy, Component, Inject,  OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject,  delayWhen,  merge,  Subject } from 'rxjs';
import { delay, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChecklistDatabase, ItemCatagoryFlatNode } from '../../../multiselect-nested-tree/multiselect-nested-tree.component';
import { FormCatagoryTreeService, FormCatagoryTreeStructure } from '../form-catagory-tree.service';
import { FormFirestoreService } from '../../../../../../common/src/data/dao-services/form-firestore.service';
import { randomElementName } from '../../../../../../common/src/util/util';
import { FormFirestoreSummary, FORM_TYPE } from '../../../../../../common/src/data/dao/form-firestore-summary';
import { FormFirestore } from '../../../../../../common/src/data/dao/form-firestore';
import { FormCatagoryService } from '../../../../../../common/src/data/dao-services/form-catagory.service';

@Component({
  selector: 'app-save-form',
  templateUrl: './save-form.component.html',
  styleUrls: ['./save-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SaveFormComponent implements OnInit, AfterViewInit {

  randomElementName : string = randomElementName();
  form: UntypedFormGroup;
  formFirestoreForm: string;
  formType: FORM_TYPE;
  formFirestore: FormFirestore = undefined;
  defaultTitle : string = undefined;

  proposedFormFirestoreDocId: string;
  proposedFormFirestoreSummaryDocId: string;

  displayInactiveFormCatagories: BehaviorSubject<boolean> = new BehaviorSubject(false);
  nodeClicked$: Subject<ItemCatagoryFlatNode<FormCatagoryTreeStructure>> = new Subject<ItemCatagoryFlatNode<FormCatagoryTreeStructure>>();
  checkListDatabase :ChecklistDatabase<FormCatagoryTreeStructure> = undefined;

  destroyingComponent$ = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SaveFormComponent>, private dialog: MatDialog,
  private formService: FormFirestoreService, private formCatagoryService: FormCatagoryService, private formCatagoryTreeService: FormCatagoryTreeService,
    private fb: UntypedFormBuilder) {

    this.checkListDatabase = new ChecklistDatabase<FormCatagoryTreeStructure>(this.formCatagoryTreeService.rootNode.pipe(filter(node => node!== undefined)),
      this.formCatagoryTreeService.allNodes,this.formCatagoryTreeService.selectedNodes, (x=>x.parentCatagoryKey === null ? undefined : x.parentCatagoryKey),
      (x => x.name),(x=>x.key), (x=> x ? x.description : ""), false);

    this.form = this.buildForm();

    if (data) {
      this.formFirestoreForm = data.form;
      this.formType = data.formType;
      this.defaultTitle = data.defaultTitle;
      if (data.formFirestore) {
        this.formFirestore = data.formFirestore;
        this.patchExistingValueToForm();
      } else if (this.defaultTitle) {
        this.patchExistingValueToForm();
      }
      this.proposedFormFirestoreDocId= data.formFirestoreDocId;
      this.proposedFormFirestoreSummaryDocId= data.formFirestoreSummaryDocId;
    }
  }

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  buildForm() : UntypedFormGroup {
    return this.fb.group({
      title: ['', Validators.required],
      formCatagory: [''],
      selectedFormCatagory: ['',Validators.required],
    });
  }

  patchExistingValueToForm() {
    this.form.patchValue({
      title: this.defaultTitle ? this.defaultTitle : this.formFirestore.title,
    });
  }

  buildDisplayInactiveForms() {
    return this.fb.group({
      displayInactiveFormCatagories: [false],
      displayInactiveWorkflows: [false]
    });
  }

  submit() {
    if (this.form.valid) {
      const newFormFireStore = new FormFirestore();
      newFormFireStore.SetDocId(this.proposedFormFirestoreDocId);
      newFormFireStore.uncommitedDocId = true;

      if (this.formFirestore === undefined) {
        newFormFireStore.formSummary = new FormFirestoreSummary({title: this.form.get("title").value, formCatagory: this.form.get("formCatagory").value, formType: this.formType});
        newFormFireStore.formSummary.SetDocId(this.proposedFormFirestoreSummaryDocId);
        newFormFireStore.formSummary.uncommitedDocId = true;
      } else {
        newFormFireStore.formSummary = this.formFirestore.formSummary;
      }
      newFormFireStore.form = this.formFirestoreForm;

      newFormFireStore.formSummary.title = this.form.get("title").value;
      newFormFireStore.formSummary.formCatagory = this.form.get("formCatagory").value;
      newFormFireStore.formSummary.formType = this.formType;
      newFormFireStore.formSummary.currentDesignFirestoreDocId = this.proposedFormFirestoreDocId;
      newFormFireStore.title = this.form.get("title").value;

      this.formService.create$(newFormFireStore).pipe(
        tap(() => {
          this.dialogRef.close(newFormFireStore);
        }),
        take(1)
      ).subscribe();
    }
  }

  close() {
      this.dialogRef.close();
    }

    CheckListDatabase() : ChecklistDatabase<FormCatagoryTreeStructure> {
      return this.checkListDatabase;
    }

    ngAfterViewInit(): void {

      if (this.formFirestore !== undefined) {
          this.formCatagoryTreeService.allNodes.pipe(
            delayWhen(() => this.formCatagoryTreeService.allNodes.pipe(filter(x => x.length > 0))),
            filter(x => x.find(z => z.formCatagory.docId === this.formFirestore.formSummary.formCatagory.docId) !== undefined),
            tap(() => this.formCatagoryTreeService.updateSelectedNodes([this.formFirestore.formSummary.formCatagory])),
            take(1)
            ).subscribe();
        }
    }

  ngOnInit(): void {
    const selectedValue = this.checkListDatabase.selectedNodes.pipe(
      filter(x => x.length > 0),
      map(x => x[0]),
      delay(1),
      tap(x => this.form.patchValue({formCatagory: x.item === null ? null : x.item.formCatagory, selectedFormCatagory: true})));

    const unselectedValue = this.checkListDatabase.selectedNodes.pipe(
      filter(x => x.length === 0),
      tap(x => this.form.get("formCatagory").reset()),
      tap(x => this.form.get("selectedFormCatagory").reset()));


    merge(selectedValue,unselectedValue).pipe(
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }


}
