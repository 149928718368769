import { addMinutes, startOfDay } from "date-fns";
import addHours from "date-fns/addHours";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { EmployeeAvailability } from "../../../../common/src/data/dao/employee-availability";


export class SchedulerSettings extends RetrieveFirestorePropertiesBase<SchedulerSettings>  {

workWeek: EmployeeAvailability[] = [];
employeeAvailabilityDocIds: string[] = [];

minimumSiteVisitLength: Date = addMinutes(startOfDay(Date.now()), 15);
defaultArrivalWindowLength: Date = addHours(startOfDay(Date.now()), 4);
numberFillsDaySiteVisits: number = 2;
numberFirstAvailiableSiteVisits: number = 2;
numberLowCommuteSiteVisits: number = 2;
defaultStartTimeVirginSiteVisits: Date = addHours(startOfDay(Date.now()), 10);
hideUnavailableDaysWhenScheduling: boolean = false;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["workWeek"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["employeeAvailabilityDocIds"]);
static _firestoreCompositionMemberNames = ["workWeek"];
static _firestoreCompositionalDiffMemberNames: string[] = ["workWeek"];

retrieveFirestoreIgnoredMemberNames() : string[] { return SchedulerSettings._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return SchedulerSettings._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return SchedulerSettings._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return SchedulerSettings._firestoreCompositionMemberNames;}

public constructor(init?: Partial<SchedulerSettings>) {
super();
Object.assign(this, init);
}
}
