import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { LineItemRemovalWithReason } from 'web-app/src/app/line-item/line-item-display/line-item-display.component';
import { GenericServiceProviderSettingService } from '../../../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { JobTypeService } from '../../../../../../common/src/data/dao-services/job-type.service';
import { PricingMethodologySettingsService } from '../../../../../../common/src/data/dao-services/pricing-methodology-settings.service';
import { GenericServiceProviderSetting } from '../../../../../../common/src/data/dao/generic-service-provider-setting';
import { JobType } from '../../../../../../common/src/data/dao/job-type';
import { LineItem } from '../../../../../../common/src/data/dao/line-item';
import { PricingMethodologySettings } from '../../../../../../common/src/data/dao/pricing-methodology-settings';
import { randomElementName } from '../../../../../../common/src/util/util';
import { where } from 'firebase/firestore';

@Component({
  selector: 'app-job-type',
  templateUrl: './job-type.component.html',
  styleUrls: ['./job-type.component.scss']
})
export class JobTypeComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  formDisplayInactives: UntypedFormGroup;
  formPricingMethodology: UntypedFormGroup;

  jobTypesToDisplay$ : Observable<JobType[]>;
  jobPrioritiesToDisplay$ : Observable<GenericServiceProviderSetting[]>;


  standardFieldsToPatch = ["jobPriority","lineItems","name","color","active"];
  public jobType: JobType;
  private defaultPricingMethodology: PricingMethodologySettings;
  private pricingMethodologySetting: PricingMethodologySettings;

  // Line items section.
  lineItems$: BehaviorSubject<LineItem[]> = new BehaviorSubject([]);
  addLineItem$ = new Subject<LineItem>();
  removeLineItem$ = new Subject<LineItemRemovalWithReason>();
  editLineItem$ = new Subject<{old: LineItem, new: LineItem}[]>();

  constructor(public jobTypeService: JobTypeService, private genericServiceProviderSettingService: GenericServiceProviderSettingService,
    private fb: UntypedFormBuilder, private snackBar: MatSnackBar, private pricingMethodologyService: PricingMethodologySettingsService) {
      this.buildForms();
    }

    destroyingComponent$ = new Subject();

    ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

  randomElementName : string = randomElementName();
  ElementNameForId(id: any) {
    return this.randomElementName.concat(id);
  }

  ngOnInit(): void {

    const retrieveDefaultPricingMethodology = this.pricingMethodologyService.queryFirestoreDeep$([where('default','==',true)]).pipe(
      filter(x => x.length > 0),
      map(x => x[0]),
      tap(x => this.defaultPricingMethodology = x),
      take(1));

    this.jobTypesToDisplay$ = combineLatest([this.jobTypeService.loadAll$(),
      this.formDisplayInactives.get("showAllJobTypes").valueChanges.pipe(startWith(false)),retrieveDefaultPricingMethodology]).pipe(
      map(([jobTypes, showAllJobTypes,defaultPricing]) => {
        let retVal: JobType[];
        if (showAllJobTypes) {
          retVal = jobTypes;
        } else {
          retVal =  jobTypes.filter(x => x.active);
        }
        retVal.forEach(y => {
          if (y.pricingMethodology === undefined || y.pricingMethodology === null) {
            y.pricingMethodology = defaultPricing;
          }
        });
        return retVal;
    }),
      takeUntil(this.destroyingComponent$));

      this.jobPrioritiesToDisplay$ = combineLatest([this.genericServiceProviderSettingService.allJobPriorities,
      this.formDisplayInactives.get("showAllJobPriorities").valueChanges.pipe(startWith(false))]).pipe(
      map(([jobPriorities, showAllJobPriorities]) => {
        if (showAllJobPriorities) {
          return jobPriorities;
        } else {
          return  jobPriorities.filter(x => x.active);
        }}),
      takeUntil(this.destroyingComponent$));

    // LINE ITEMS BEGIN
    this.form.get("jobType").valueChanges.pipe(
      filter(x => x !== undefined && x!==null),
      map(x => (x as JobType).lineItems),
      tap(x => console.log(x.map(x=>x),` string`)),
      takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.lineItems$.next(x));

    const addLineItems = this.addLineItem$.pipe(
      tap(x => this.form.get("jobType").value.lineItems.push(x)),
    );

    const removeLineItems = this.removeLineItem$.pipe(
      tap(x => {
        const lineItems = this.form.get("jobType").value.lineItems;
        const indexOfLineItem =  lineItems.findIndex(z => z.DocId() === x.lineItem.DocId());
        lineItems.splice(indexOfLineItem,1);
      }));

    const editLineItems = this.editLineItem$.pipe(
      tap(edits => {
        edits.forEach(x =>
        {
        const newLineItem = x.new;
        newLineItem.lineItemDocId = x.old.lineItemDocId;
        newLineItem.lineItemPrototypeDocId = null;
        const lineItems = this.form.get("jobType").value.lineItems;
        const indexOfLineItem = lineItems.findIndex(z => z.DocId() === x.old.DocId());
        lineItems.splice(indexOfLineItem,1,newLineItem);
        });
      }));

    merge(addLineItems,removeLineItems,editLineItems).pipe(
      tap(() => this.form.patchValue({"jobType" : this.form.get("jobType").value})),
      takeUntil(this.destroyingComponent$)).subscribe();

  // LINE ITEMS END
  }

  buildForms() : void {
    this.form = this.fb.group({
      jobType: [],
      jobPriority:["",Validators.required],
      jobPriorityName: [],
      jobPriorityActive: [],
      lineItems:[],
      name:[],
      color:[],
      hourlyLaborRate:[],
      active:[],
    });

    this.form.get("jobType").valueChanges.pipe(
      filter(x => x !== null),
      tap(x => this.jobType = x),
      tap(x => this.patchJobTypeSettings(x)),
      takeUntil(this.destroyingComponent$)).subscribe();

    this.form.get("jobPriority").valueChanges.pipe(
      filter(x => x !== undefined && x !== null),
      tap(x => this.form.get("jobPriorityName").patchValue(x.name)),
      tap(x => this.form.get("jobPriorityActive").patchValue(x.active)),
      takeUntil(this.destroyingComponent$)).subscribe();

    this.formDisplayInactives = this.fb.group({
      showAllJobPriorities: [false],
      showAllJobTypes: [false],
    });

    this.formPricingMethodology = this.pricingMethodologyService.initilizePricingMethodologyForm();
  }

  addNewJobType() {
      const j = new JobType();
      j.pricingMethodology = this.defaultPricingMethodology;
        this.form.patchValue({jobType: j});
    }


  addNewJobPriority() {
    const j = new GenericServiceProviderSetting({parentCollectionName : "job_priorities"});
    this.form.get("jobPriority").patchValue(j);
  }

  patchPricingMethodologySettings(settings: PricingMethodologySettings) {
    this.pricingMethodologySetting = settings;
    console.log(settings);
    this.pricingMethodologyService.pricingMethodologyFieldsToPatch.forEach(field => {

      this.formPricingMethodology.get(field).patchValue(settings[field]);
    });
  }

  patchFormGroupToPricingMethodologySettings() {
    this.pricingMethodologyService.pricingMethodologyFieldsToPatch.forEach(field =>  this.pricingMethodologySetting[field] = this.formPricingMethodology.get(field).value);
  }


  patchFormGroupToJobTypeSettings() {
    if (this.jobType !== undefined) {
      this.standardFieldsToPatch.forEach(field =>  this.jobType[field] = this.form.get(field).value);
    }
    this.patchFormGrouptoJobPrioritySettings();
  }

  patchFormGrouptoJobPrioritySettings() {
    this.jobType.jobPriority.name = this.form.get("jobPriorityName").value;
    this.jobType.jobPriority.active = this.form.get("jobPriorityActive").value;
  }

  patchJobTypeSettings(settings: JobType) {
    this.standardFieldsToPatch.forEach(field => {
      this.form.get(field).patchValue(settings[field]);
    });
    this.patchJobPrioritySettings(settings.jobPriority);
    if (settings.pricingMethodology !== undefined && settings.pricingMethodology !== null) {
    this.patchPricingMethodologySettings(settings.pricingMethodology);
    }
  }

  patchJobPrioritySettings(settings: GenericServiceProviderSetting) {
    if (settings !== undefined && settings !== null) {
      this.form.get("jobPriorityName").patchValue(settings.name);
    this.form.get("jobPriorityActive").patchValue(settings.active);
    }
  }

  Cancel() {
    this.form.reset();
    this.patchJobTypeSettings(this.jobType);
  }

  pricingMethodologyUpdated(currentPricingMethodologyValue: PricingMethodologySettings) : boolean {
    const oldValue = this.pricingMethodologyService.getCloneOfCachedValue(currentPricingMethodologyValue.DocId());
    return oldValue.defaultHourlyBillingRate !== currentPricingMethodologyValue.defaultHourlyBillingRate ||
    oldValue.materialMarkupRate !== currentPricingMethodologyValue.materialMarkupRate ||
    oldValue.pricingMethodologyType !== currentPricingMethodologyValue.pricingMethodologyType;
  }

  Save() {
    if (this.form.valid) {
      this.snackBar.open("Saving Updates",undefined);
      this.patchFormGroupToJobTypeSettings();
      this.patchFormGroupToPricingMethodologySettings();
      if (this.pricingMethodologyUpdated(this.jobType.pricingMethodology)) {
        this.jobType.pricingMethodology = new PricingMethodologySettings({default: false, defaultHourlyBillingRate: this.jobType.pricingMethodology.defaultHourlyBillingRate,
        materialMarkupRate: this.jobType.pricingMethodology.materialMarkupRate, pricingMethodologyType: this.jobType.pricingMethodology.pricingMethodologyType} );
      }
      console.log(this.jobType);
      this.jobTypeService.update$(this.jobType).pipe(
        take(1)
        ).subscribe(() => this.snackBar.dismiss());
    }
  }

  compareCommonServiceProviderSettings(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.docId === o2.docId;
  }

  compareJobTypes(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.jobTypeDocId === o2.jobTypeDocId;
  }

}
