import { Injectable  } from '@angular/core';
import { Calculation } from './shunting-yard-calc';

@Injectable({
  providedIn: 'root'
})

export class CalculateFieldService {
  calc = new Calculation();

  CalcExpression(expression: string) {
    return this.calc.calculate(expression);
  }


}
