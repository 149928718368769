import { Injectable} from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from '../../util/authentication.service';
import { LocalSettingsService } from '../../../../web-app/src/app/settings/local-settings.service';
import { LoggingService } from './logging.service';
import { LogEntry } from './logEntry';
import { NgxLoggerService } from './ngx-logger.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingBackendService {

  platform: Platform;

  constructor(platform: Platform, private authenticationService: AuthenticationService, private localSettingsService: LocalSettingsService,
    private loggingService: LoggingService, private ngxLoggerService: NgxLoggerService) {
    this.platform = platform;
  }

  public startLogging() {
    this.loggingService.logEntryToAdd.subscribe(l => this.addLog(l));
  }

  private addLog(l: LogEntry) {

    l.version = this.localSettingsService.appVersion;
    l.app = this.localSettingsService.app;
    const emp = this.authenticationService.activelyLoggedInEmployee;
    if (emp) {
      l.userDocId = emp.DocId();
      l.userName = emp.name;
    }
    this.ngxLoggerService.addLog(l);
  }
}
