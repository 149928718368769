import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {  UntypedFormBuilder } from '@angular/forms';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyUtilityService } from '../formly-utility.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-formly-section',
  template: `
  <!-- <div style="display:none">{{to.changeDetect|async}}</div> -->
    <!-- Tech View -->
    <div class="hellokitty" style="margin-bottom: 10px; transition: 10s ease;" *ngIf="to.activeView === 'techView'">

    <!-- Buttons to expand and collapse the section. -->
    <ng-container *ngIf="(contentCanBeCollapsed$ | async)===true">
      <ng-container *ngIf="(contentExpanded$ | async)===false; else collapsedButton$">
      <div style="background-color: #d0d0d0;"> <!-- Styling for header when section expanded. -->
        <button mat-button (click)="contentExpanded$.next(true)" style="width: 100%">
        <div class="accordian-button">
          {{to.title}}
          <mat-icon>add_circle_outline</mat-icon>
          </div>
        </button>
        </div>
      </ng-container>
      <ng-template #collapsedButton$>
            <div style="background-color: #d0d0d0;"> <!-- Styling for header when section collapsed. -->
        <button mat-button (click)="contentExpanded$.next(false)" style="width: 100%">
        <div class="accordian-button">
          {{to.title}}
          <mat-icon>remove_circle_outline</mat-icon>
        </div>
          </button>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="(contentCanBeCollapsed$ | async) === false || (contentExpanded$ | async)===true; else collapsedSection$">
      <div *ngIf="to.generatedFromBranch" class="design-view-button-container">
      <div class="button-container do-not-print-this">
          <button mat-button (click)="removeFormly()" [disabled]="formControl.disabled">
          <!-- <mat-icon class="delete-section">cancel</mat-icon> -->
          <mat-icon >close</mat-icon>
        </button>
      </div>
      <div class="design-view-style">{{to.title}}</div>
      </div>
      <!-- Begining of actual section. -->
        <div class="outer-section">
          <div *ngIf="field.fieldGroup.length > 0; else elseBlock">
            <div *ngFor="let field of field.fieldGroup; index as i trackBy:trackSection">
            <!-- <h5>{{field.key}}</h5> -->
                <div class="inner-section">
                  <div class="section-header" [ngClass]="{'noRepeat':!to.repeating}">{{to.itemName}} - {{i+1}}</div>
                  <formly-field class="row" [field]="field"></formly-field>
                  <div style="margin-top: 10px;" class="button-no-print" [ngClass]="{'noRepeat':!to.repeating}">
                    <button [ngClass]="{'noRepeat':!to.repeating}" class="button-no-print button-red" mat-button [hidden]="!to.minimumNumberInstances === to.maximumNumberInstances" [disabled]="formControl.disabled" type="button" (click)="remove(i)">Delete {{to.itemName}}</button>
                    <button [ngClass]="{'noRepeat':!to.repeating}" class="button-no-print button-green" style="margin-left: 15px;" mat-button [hidden]="!to.minimumNumberInstances === to.maximumNumberInstances" [disabled]="formControl.disabled" (click)="add(i)">Add {{to.itemName}} </button>
                  </div>
                </div>
            </div>
          </div>
          <ng-template #elseBlock>
          <div style="margin-top: 10px;" class="button-no-print" [ngClass]="{'noRepeat':!to.repeating}">
                    <button [ngClass]="{'noRepeat':!to.repeating}" class="button-no-print button-green" style="margin-left: 15px;" mat-button [hidden]="!to.minimumNumberInstances === to.maximumNumberInstances" [disabled]="formControl.disabled" (click)="add(0)">Add {{to.itemName}} </button>
                  </div>
          </ng-template>
        </div>
        </ng-container>
        <ng-template #collapsedSection$>
        <!-- <div class="design-view-style">{{to.title}}</div> -->
        </ng-template>
    </div>

    <!-- Print View -->
    <div style="margin-bottom: 10px;" *ngIf="to.activeView === 'printView'">
      <div class="outer-section">
        <div *ngFor="let field of field.fieldGroup; index as i">
            <div class="inner-section">
              <div class="section-header" [ngClass]="{'noRepeat':!to.repeating}">{{to.itemName}} - {{i+1}}</div>
              <formly-field class="row" [field]="field"></formly-field>
            </div>
        </div>
    </div>
  </div>
  `,
  styleUrls: ['./formly-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormlySectionComponent extends FieldArrayType implements AfterViewInit, OnInit {

  contentExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  contentCanBeCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private formlyUtilityService:  FormlyUtilityService, private fb: UntypedFormBuilder) {
    super();
  }
  ngOnInit(): void {
    if (this.props.collapsable === true) {
      this.contentCanBeCollapsed$.next(true);
    } else {
      this.contentCanBeCollapsed$.next(false);
    }
    setTimeout(() => {
      if (this.props.defaultCollapsed === true) {
        this.contentExpanded$.next(false);
      } else {
        this.contentExpanded$.next(true);
      }
    },1)
  }

  trackSection(index: number, item: FormlyFieldConfig) {
    return item.id;
  }

  ngAfterViewInit(): void {
    if (this.field.fieldGroup.length > 0) {
      const last = this.field.fieldGroup[0].fieldGroup.length - 1;
      if (this.field.fieldGroup[0].fieldGroup[last].formControl.value === null) {
        this.field.fieldGroup[0].fieldGroup[last].formControl.patchValue(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
      }
    }
  }

  override onPopulate(field: FieldArrayTypeConfig): void {
      super.onPopulate(field);
  }

  get parentId() : string {
    return this.field.parent ? this.field.parent.model._id : null;
  }

  removeFormly() {
    this.formlyUtilityService.removeSectionFromWorkflow$.next({
      sectionKey: (this.key as string),
      branchKey: this.to.sourceKey === undefined ? null : this.to.sourceKey ,
       parentId: this.parentId, originatedFromSection: true,
       priceBookEntryDocId: (this.key as string).split("+")[1] === undefined ? null : (this.key as string).split("+")[1]});
   }

  remove(i: number) {
    if (this.field.fieldGroup.length -1 >= this.to.minimumNumberInstances) {
      super.remove(i);
    } else {
      window.alert(`Unable to remove, ${this.to.minimumNumberInstances} ${this.to.itemName} required.`)
    }
  }

  add(i: number) {
    if (this.field.fieldGroup.length +1 <= this.to.maximumNumberInstances) {
      const initialModel = {_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)};
      super.add(this.field.fieldGroup.length +1,initialModel);
      if (i !== this.field.fieldGroup.length-2) {
        const addedFieldGroup = this.field.fieldGroup.pop();
        this.field.fieldGroup.splice(i+1, 0, addedFieldGroup);
        for (let j = i+1; j < this.field.fieldGroup.length; j++) {
          this.field.fieldGroup[j].key = j.toString();
        }
        const addedModel = this.model.pop();
        this.model.splice(i+1, 0, addedModel);
      }
    } else {
      window.alert(`Unable to add, no more then ${this.to.maximumNumberInstances} ${this.to.itemName} allowed.`)
    }
  }
}
