<!-- <button (click)="test()">TETEST</button> -->
 <!-- <h5>{{guid}}</h5> -->
<div style="display:none">{{fields[0].props.changeDetect|async}}</div>
<app-workflow-header
      [isMain]="false"
      [loadedFromBranch]="!serializeToDatabase"
      [activeFormFirestore$]="activeFormFirestore"
      [referencedComponents]="containedControls"
      [unsavedChanges$]="unsavedChanges"
      [activeWorkflowType]="sectionWorkflowType"
      (copyWorkflowClicked)="copyWorkflow()"
      (loadWorkflowClicked)="loadSection()"
      (saveWorkflowClicked)="saveSection()"
      (removeWorkflowClicked)="removeSection()"
      (newWorkflowClicked)="newSection($event)"
      (associateWithImportedDataClicked)="associateWithImportedData()"
      (assignSectionToPricebookEntryClicked)="internalAssociateSectionToPriceBookEntryWorkflow()"
      (deleteImportedDataAssociationClicked)="internalDeleteImportedDataAssociation()"
      (revertToDeployedWorkflowClicked) = "revertToDeployedWorkflow()"
      (deployWorkflowClicked) = "deployWorkflow($event)"
      (workflowActivationStatusToggled)="toggleWorkflowActivationStatus()"
    ></app-workflow-header>

<div class="section-color" [formGroup]="componentForm" style="width: 100%; height:auto"
>

  <div formArrayName="columnFormGroups" >
    <div *ngFor="let activeColumnFormGroup of columnFormGroups.controls; index as i;" [formGroupName]="i">
    <div class="section-header" id={{idForContainingDiv}} [ngClass]="{'repeating':componentForm.get('repeating').value}">{{componentForm.get('itemName').value}}</div>
    <!-- <div>{{activeColumnFormGroup.get('id').value}}</div> -->
    <div cdkDropList style="min-height: 200px;"
      [id]="activeColumnFormGroup.get('id').value"
      [cdkDropListConnectedTo]="activeColumnFormGroup.get('cdkDropListConnectedTo').valueChanges | async"
      [cdkDropListData]="activeColumnFormGroup.get('cdkDropListData').value"
      (cdkDropListDropped)="onTalkDrop($event)"
      [cdkDropListDisabled]=disableDesignComponentInteraction
      class = "card-list mat-elevation-z1"
      [cdkDropListEnterPredicate]="forbidSectionComponentsPredicate"
      >
      <div *ngFor="let component of activeColumnFormGroup.get('cdkDropListData').value;" cdkDrag
          [cdkDragStartDelay]="25"
          [cdkDragData]="component"
          (cdkDragStarted)="component.dragStarted($event)"
          (cdkDragEnded)="component.dragEnded($event)">
            <div *ngIf="component.parentContainer === null else standard">
              <app-form-element
                id={{component.perInstance}}
                [componentToInstantiate]="component"
                (componentClicked$)="subComponentClicked$.next($event)"
              ></app-form-element>
            </div>
            <ng-template #standard>
              <app-form-element
                id={{component.perInstance}}
                [componentToInstantiate]="component"
              ></app-form-element>
            </ng-template>
      </div>
  </div>
</div>


</div>
</div>
