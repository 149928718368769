import {where,QueryConstraint, QueryFieldFilterConstraint} from 'firebase/firestore';
import {  Observable,of,  combineLatest,  merge,  ReplaySubject,  Subject,  throwError,  BehaviorSubject, timer, race} from 'rxjs';
import {  delay,map,share,mergeMap,filter,distinctUntilChanged,switchMap,take,takeUntil,tap,catchError,startWith,debounce,finalize,timeout,delayWhen} from 'rxjs/operators';
import { RetrieveFirestoreProperties } from './retrieve-firestore-properties';
import { FirestoreDiff } from '../dao/firestore-diff';
import { AuthenticationService } from '../../util/authentication.service';
import { cloneDeep } from 'lodash-es/';
import { LocalSettingsService } from '../../../../web-app/src/app/settings/local-settings.service';
import { LoggingService } from '../logging/logging.service';
import { inject } from '@angular/core';
import { IonicPlatformService } from '../services/ionic-platform.service';
import { NgxLoggerLevel } from 'ngx-logger';
import superjson from 'superjson';
import { format } from 'date-fns';
import { isEmpty } from '../../util/util'
import { GenerateFirestoreDeltas } from '../../../../web-app/src/app/database backend/generate-firestore-deltas';

enum DENORMALIZATION_STATE {
  DENORMALIZED_FULLY = "DENORMALIZED_FULLY",
  NOT_DENORMALIZED_OBJECT = "NOT_DENORMALIZED_OBJECT",
  DENORMALIZED_COMPOSED_MEMBERS_NOT = "DENORMALIZED_COMPOSED_MEMBERS_NOT",
  DENORMALIZED_NEEDS_POPULATED = "DENORMALIZED_NEEDS_POPULATED",
  DENORMALIZED_MORE_RECENT = "DENORMALIZED_MORE_RECENT",
}

export abstract class FirestoreBackend<T extends RetrieveFirestoreProperties> {
  // The last populated value of a given document id.
  private cachedValues: Map<string, T> = new Map();
  public _PurecachedValues: Map<string, T> = new Map();
  // Replay subject for each document id.
  private cachedObservables: Map<string, BehaviorSubject<T>> = new Map();
  // Subject to next on when value is set from within app.
  private locallySetValueToObserve$: Subject<T> = new Subject<T>();
  protected abstract basePath: string;
  private counter = 0;
  public static unsubscriptionTimer = 10000;
  get PrototypeObject(): T { return this.prototypeObject; }

  get baseP() : string { return this.basePath; }

  private debounceLoad$: Map<string,ReplaySubject<boolean>> = new Map();
  public static debounceAll$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private static _dateLastServerUpdate$: Subject<Date> = new Subject<Date>();
  private static _dateLastServerUpdate: Date = null;

  public static unsubscriber = new Subject<string>();
  public static subscriberCount = new Map<string, number>();
  private activelyObservedDocIds = new Set<string>();
  public static retrievingDocId$ = new Subject<string>();
  private static deadDocIds = [];
  private static networkEnabled = true;
  public static forceRestart$: Subject<null> = new Subject<null>();

  public static dateLastServerUpdate$() : Subject<Date> { return FirestoreBackend._dateLastServerUpdate$; }
  public static dateLastServerUpdate() : Date { return FirestoreBackend._dateLastServerUpdate; }

  static destroyingComponent$ = new Subject();
  static numberObservers = 0;
  static numberCollectionObservers = 0;
  static verboseFirestoreObjectCRUDLogging = false;

  public static activeFillz: Map<string, number> = new Map<string, number>();

  protected static worker: Worker = undefined;
  protected static heartBeatMessageResponse$ = new Subject<null>();
  protected static firestoreBackendMessage$ = new ReplaySubject<any>(1);
  private static accessFirestoreInnodbToggled$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected static firestoreBackendMessageDelayMs : number = 5;

  private static loggingService: LoggingService;
  private static platformService: IonicPlatformService;
  private static localSettingsService: LocalSettingsService;
  private static initializedStaticMembers = false;
  private static reinitilizingFirestoreWorkers$ = new Subject<null>();
  private static consecutiveDisableNetworkFailures = 0;

  sorted = [];

  compositionServices: Map<string,{associatedDocumentId: string;firestoreBackEnd: FirestoreBackend<RetrieveFirestoreProperties>;}> =
  new Map<string,{associatedDocumentId: string;firestoreBackEnd: FirestoreBackend<RetrieveFirestoreProperties>;}>();

  protected abstract RetrieveInstantiatedFirestoreObjectFromJson(obj: Partial<T>): T;

  //Errors that are returned by firestore when Innodb is not accessible b/c of errors w/ webKit.  These errors require firestore to be newed up again
  //to avoid having to reload the page.
  private static fatalErrorsRegExp = [
    /Target ID already exists:*/,
    /A write batch can no longer be used after commit/,
    /missing stream token/,
    /UnknownError: Attempt to delete/,
    /UnknownError: Attempt to get a record from database/,
    /UnknownError: Attempt to get records from database/,
    /FIRESTORE \(\d*\.\d*\.\d*\) INTERNAL ASSERTION FAILED: Unexpected state/,
    /UnknownError: Attempt to iterate a cursor that doesn/,
    /UnknownError: Attempt to open a cursor in database without/,
    /Attempt to iterate a cursor that doesn\'t exist/,
    /Attempt to open a cursor in database without an in-progress transaction/,
    /Connection to Indexed Database server lost. Refresh the page to try again/,
    /Uncaught FirebaseError: An internal error occurred./,
    /Attempt to get all index records from database without an in-progress transaction/,
    /AbortError:/,
    /UnknownError: An internal error was encountered in the Indexed Database server/,
    /FirebaseError: Missing or insufficient permissions./,
    /TypeError: n.indexOf is not a function. \(In/,
    /TypeError: e is not an Object./,
    /Unable to store record in object store/,
  ];

  private static ignoreErrorsRegExp = [
    // /AbortError:/,
  ];

  /**
   *
   * @param compositionServices : compositionServices Key is name of firestoreCompositionMember in obj.  Value
   * : Pair {associatedDocumentId in obj , service used to commit composed object.}
   */
  constructor(private prototypeObject: T,protected authService: AuthenticationService) {
    // firebase.firestore.setLogLevel('debug')
    // firestore.setLogLevel('debug');

    if (!FirestoreBackend.initializedStaticMembers) {

      FirestoreBackend.initializedStaticMembers = true;
      FirestoreBackend.accessFirestoreInnodbToggled$.next(false);
      FirestoreBackend.loggingService = authService.retrieveLoggingService;
      FirestoreBackend.platformService = inject(IonicPlatformService);
      FirestoreBackend.localSettingsService = inject(LocalSettingsService);

      FirestoreBackend.loggingService.addLog(`firestore backend init static members ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {at: new Date()});

      FirestoreBackend.forceRestart$.pipe(
        tap(() => {
          FirestoreBackend.loggingService.addLog(`Exceeded allowed soft restarts of firestore web worker at ${window.location.href}. ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {at: new Date()});
          throw new Error(`Exceeded allowed soft restarts of firestore web worker at ${window.location.href}`);
        }),
        catchError(err => {
          return throwError(err);
          })
      ).subscribe();

      // When app sent to background, unsubscribe all observers of firestore collection changes and disable firestore network access.
      FirestoreBackend.platformService.appSentToBackground$.pipe(
        tap(() => {
          FirestoreBackend.accessFirestoreInnodbToggled$.next(false);
          FirestoreBackend.disabledFirestoreNetworkAccess();
        })
      ).subscribe();

      FirestoreBackend.firestoreBackendMessage$.pipe(
        filter(x => x.signalGuid === "heartbeat"),
        tap(() => FirestoreBackend.heartBeatMessageResponse$.next(null)),
        tap(() => FirestoreBackend.loggingService.addLog(`firestore web worker heartBeatMessageRESPONSE ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {filter: "heartBeatMessage", at: new Date()})),
        tap(() => FirestoreBackend.InitilizeFirestoreForUse()),
      ).subscribe();

      FirestoreBackend.platformService.appSentToForeground$.pipe(
        tap(() => {
          FirestoreBackend.firestoreWorkerHeartbeatMessage();
        })
      ).subscribe();

      of(null).pipe(
        delayWhen(() => this.authService.isLoggedIn$.pipe(filter(x => x===true))),
        tap(() => {
          FirestoreBackend.initilizeFirestoreWorker();
        }),
        catchError((err) => {
          FirestoreBackend.loggingService.addLog(`firestore web worker failed to initilize ${format(new Date(), 'H:mm:ss:SSS')}  ${err}` , "firestore-web-worker", {at: new Date()});
          return throwError(err);
        }),
        takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
        take(1)
      ).subscribe();

    }
  }

  /** Disables firestore network access, and posts log entry on sucess */
  private static disabledFirestoreNetworkAccess() {
    const disableGuid = Math.random().toString(36).substring(0, 7);
    const successfullyDisabled$ = new Subject<null>();

    FirestoreBackend.firestoreBackendMessage$.pipe(
      filter(x => x.signalGuid === disableGuid),
      tap(() => this.loggingService.addLog(`retrieve-from-firestore.ts ---- sucessfully disable network disabledFirestore() BACKGROUND ${format(new Date(), 'H:mm:ss:SSS')}` , "retrieve-from-firestore", {filter: "disableNetwork", at: new Date()})),
      tap(() => FirestoreBackend.networkEnabled = false),
      tap(() => successfullyDisabled$.next(null)),
      tap(() => this.consecutiveDisableNetworkFailures = 0),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();

    // It appears from logs that in some cases heartbeat message successfully returns within time frame, but firestore fails to respond to message to disable network.  Therefore
    // we will add a race here as well, re-instantiating worker if message to disable network fails to return in time.
    race(successfullyDisabled$, timer(2000)).pipe(
      tap(x => {
        if (x === 0) {
          FirestoreBackend.loggingService.addLog(`firestore web worker disableNetworkFAILEDtoReturnInTime BACKGROUND #${this.consecutiveDisableNetworkFailures} ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {filter: "disableNetwork", at: new Date()});
          // re-initilization if not done within alloted time will occurr on app being moved back to foreground.
          // (FirestoreBackend.worker as any).removeAllListeners();
          //   FirestoreBackend.worker.terminate();
          //   FirestoreBackend.initilizeFirestoreWorker(++this.consecutiveDisableNetworkFailures);
        }
      }),
      catchError((err) => {
        FirestoreBackend.loggingService.addLog(`firestore web worker failed to initilize ${format(new Date(), 'H:mm:ss:SSS')}  ${err}` , "firestore-web-worker", {at: new Date()});
        return throwError(err);
      }),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();


    FirestoreBackend.worker.postMessage({
      operation: "disableNetwork",
      signalGuid: disableGuid,
    });
  }

private static InitilizeFirestoreForUseWeb() {

  const enableGuid = Math.random().toString(36).substring(0, 7);

  FirestoreBackend.firestoreBackendMessage$.pipe(
    filter(x => x.signalGuid === enableGuid),
    tap(() => this.accessFirestoreInnodbToggled$.next(true)),
    tap(() => this.loggingService.addLog(`retrieve-from-firestore.ts ---- sucessfully enable network InitilizeFirestoreForUse() ${format(new Date(), 'H:mm:ss:SSS')}  GUID: ${enableGuid}` , "retrieve-from-firestore", {at: new Date()})),
    tap(() => FirestoreBackend.networkEnabled = true),
    takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
    take(1)
  ).subscribe();

  FirestoreBackend.worker.postMessage({
    operation: "enableNetwork",
    signalGuid: enableGuid,
  });

}

private static InitilizeFirestoreForUseMobile() {
  const disableGuid = Math.random().toString(36).substring(0, 7);
    const enableGuid = Math.random().toString(36).substring(0, 7);
    const successfullyDisabled$ = new Subject<null>();

    FirestoreBackend.firestoreBackendMessage$.pipe(
      filter(x => x.signalGuid === enableGuid),
      tap(() => this.loggingService.addLog(`retrieve-from-firestore.ts ---- sucessfully enable network InitilizeFirestoreForUse() ${format(new Date(), 'H:mm:ss:SSS')}  GUID: ${enableGuid}` , "retrieve-from-firestore", {at: new Date()})),
      tap(() => FirestoreBackend.networkEnabled = true),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();

    FirestoreBackend.firestoreBackendMessage$.pipe(
      filter(x => x.signalGuid === disableGuid),
      tap(() => this.loggingService.addLog(`retrieve-from-firestore.ts ---- sucessfully disable network InitilizeFirestoreForUse()${format(new Date(), 'H:mm:ss:SSS')}  GUID: ${disableGuid}` , "retrieve-from-firestore", {at: new Date()})),
      tap(() => successfullyDisabled$.next(null)),
      tap(() => this.consecutiveDisableNetworkFailures = 0),
      tap(() => FirestoreBackend.networkEnabled = false),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();


    successfullyDisabled$.pipe(
      tap(() => this.accessFirestoreInnodbToggled$.next(true)),
      delay(1500),
      tap(() => {
        FirestoreBackend.worker.postMessage({
          operation: "enableNetwork",
          signalGuid: enableGuid,
        });
      }),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();

    // It appears from logs that in some cases heartbeat message successfully returns within time frame, but firestore fails to respond to message to disable network.  Therefore
    // we will add a race here as well, re-instantiating worker if message to disable network fails to return in time.
    race(successfullyDisabled$, timer(2000)).pipe(
      tap(x => {
        if (x === 0) {
          FirestoreBackend.loggingService.addLog(`firestore web worker disableNetworkFAILEDtoReturnInTime #${this.consecutiveDisableNetworkFailures} ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {filter: "disableNetwork", at: new Date()});
          (FirestoreBackend.worker as any).removeAllListeners();
            FirestoreBackend.worker.terminate();
            FirestoreBackend.initilizeFirestoreWorker(++this.consecutiveDisableNetworkFailures);
        }
      }),
      catchError((err) => {
        FirestoreBackend.loggingService.addLog(`firestore web worker failed to initilize ${format(new Date(), 'H:mm:ss:SSS')}  ${err}` , "firestore-web-worker", {at: new Date()});
        return throwError(err);
      }),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();


    FirestoreBackend.worker.postMessage({
      operation: "disableNetwork",
      signalGuid: disableGuid,
    });
}

  /**
   * We disable firestore network access initially b/c dicey internet connections cause a lot of unnecessary delay to return results.  Then on sucess we enable networking after 500 ms.
   */
  private static InitilizeFirestoreForUse() {
    if (this.localSettingsService.app === "WEB") {
      FirestoreBackend.InitilizeFirestoreForUseWeb();
    }
    if (this.localSettingsService.app === "MOBILE") {
      FirestoreBackend.InitilizeFirestoreForUseMobile();
    }
  }

  /**
   * We send heartbeat message to cause firestore message, firestoreWorkerHeartbeatMessageif returned initilize firestore for use.  If Innodb error, will re-instantiate worker.
   */
  private static firestoreWorkerHeartbeatMessage(iErrorCount = 0) {

    // If heartbeat doesn't respond in 2 seconds, then re-instantiate worker.
    race(FirestoreBackend.heartBeatMessageResponse$, timer(2000)).pipe(
      tap(x => {
        if (x === 0) {
          FirestoreBackend.loggingService.addLog(`firestore web worker heartBeatMessageFAILEDtoReturnInTime ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {filter: "heartBeatMessage", at: new Date()});
          (FirestoreBackend.worker as any).removeAllListeners();
            FirestoreBackend.worker.terminate();
            FirestoreBackend.initilizeFirestoreWorker(++iErrorCount);
        }
      }),
      catchError((err) => {
        FirestoreBackend.loggingService.addLog(`firestore web worker failed to initilize ${format(new Date(), 'H:mm:ss:SSS')}  ${err}` , "firestore-web-worker", {at: new Date()});
        return throwError(err);
      }),
      takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
      take(1)
    ).subscribe();

    FirestoreBackend.loggingService.addLog(`firestore web worker heartBeatMessagePOST  netEnabled:${FirestoreBackend.networkEnabled} ${format(new Date(), 'H:mm:ss:SSS')}` , "firestore-web-worker", {filter: "heartBeatMessage", at: new Date()});
    FirestoreBackend.worker.postMessage({
      operation: "docId",
      signalGuid: "heartbeat",
      path: "fish",
      docId: "fish",
    });
  }

  //** Instantiate firestore web worker, and add handling for messages and errors coming back from it. */
  private static initilizeFirestoreWorker(errorCount: number = 0) {

    if (errorCount >= 3) {
      FirestoreBackend.forceRestart$.next(null);
      return;
    }
    FirestoreBackend.reinitilizingFirestoreWorkers$.next(null);

    if (typeof Worker !== 'undefined') {
      console.log('creating worker!');
      FirestoreBackend.worker = new Worker(
        new URL(
          '../../../../service-vanguard-mobile/src/app/services/firestore-web-worker.worker',
          import.meta.url
        ), {type: 'module'}
      );
      console.log(FirestoreBackend.worker);

      FirestoreBackend.worker.onmessage = ({ data }) => {
        FirestoreBackend.firestoreBackendMessage$.next(data);
      };

      FirestoreBackend.worker.onerror = (err) => {
        this.loggingService.addLog(`firestore web worker error ${format(new Date(), 'H:mm:ss:SSS')}   ${err.message}` , "firestore-web-worker", {at: new Date(), err: err.message, fullErr: JSON.stringify(err)});
        let matchFound = false;
        for (const fatalErrorRegExp of FirestoreBackend.fatalErrorsRegExp) {
          const re = new RegExp(fatalErrorRegExp);
          const isMatch = re.test(err.message);
          if (isMatch) {
            matchFound = true;
            (FirestoreBackend.worker as any).removeAllListeners();
            FirestoreBackend.worker.terminate();
            this.loggingService.addLog(`firestore web worker reinitilizing ${format(new Date(), 'H:mm:ss:SSS')}  errorCount: ${errorCount}` , "firestore-web-worker", {at: new Date(), err: err.message, fullErr: JSON.stringify(err)});
            FirestoreBackend.initilizeFirestoreWorker(++errorCount);
            break;
          }
        };
        if (!matchFound) {
          for (const ignoreErrorRegExp of FirestoreBackend.ignoreErrorsRegExp) {
            const re = new RegExp(ignoreErrorRegExp);
            const isMatch = re.test(err.message);
            if (isMatch) {
              this.loggingService.addLog(`firestore web worker error.  IGNORE ERROR ${format(new Date(), 'H:mm:ss:SSS')}  ${err.message}` , "firestore-web-worker", {at: new Date(), err: err.message, fullErr: JSON.stringify(err)});
              matchFound = true;
              break;
            }
          };
        }
        if (!matchFound) {
          this.loggingService.addLog(`**UNHANDLED** firestore web worker error. ${err.message}` , "firestore-web-worker", {at: new Date(), err: err.message, fullErr: JSON.stringify(err)});
        }
      };

      const guid = Math.random().toString(36).substring(0, 7);

      FirestoreBackend.firestoreBackendMessage$.pipe(
        filter((x) => x.signalGuid === guid),
        tap((x) => {
          if (x['result'] === "SUCCESS") {
            this.loggingService.addLog(`firestore initilized success ${format(new Date(), 'H:mm:ss:SSS')} errorCount: ${errorCount}`);
            FirestoreBackend.firestoreWorkerHeartbeatMessage(errorCount);
          } else {
            this.loggingService.addLog(`firestore worker failed to initilize ${format(new Date(), 'H:mm:ss:SSS')}  ${x['error']}`);
          }
        }),
        takeUntil(FirestoreBackend.reinitilizingFirestoreWorkers$),
        take(1)
      ).subscribe();

        FirestoreBackend.worker.postMessage({
          operation: 'initilize',
          signalGuid: guid,
          config: FirestoreBackend.localSettingsService.environment.firebase,
          appName: this.localSettingsService.app ===  "WEB" ? "SV-WEB" : "SV-MOBILE"
        });
    } else {
      // Web Workers are not supported in this environment.
      window.alert(
        'Web workers not supported, this is a fatal error.  Please upgrade to a modern web browser.'
      );
      throw new Error('Web workers not supported');
    }
  }

  private retrieveInstantiatedObjectFromFirestore(obj: Partial<T>, retrievedFromFirestore: boolean) : T {
    const retVal = this.RetrieveInstantiatedFirestoreObjectFromJson(obj);
    if (retrievedFromFirestore) {
      this.populateDenormlizedObjects(retVal);
    }
    return retVal;
  }

  private populateDenormlizedObjects(obj: T) {
    if (obj.retrieveFirestoreDenormalizedMemberNames().length > 0) {
      // For each denormalized member, check if present in current object.
      obj.retrieveFirestoreDenormalizedMemberNames().forEach(denormed => {
        if (obj[this.retrieveDenormalizedName(denormed)] !== undefined && obj[this.retrieveDenormalizedName(denormed)] !== null) {
          const composedObject = obj[this.retrieveDenormalizedName(denormed)];
          const fb = this.compositionServices.get(denormed).firestoreBackEnd;
          if (Array.isArray(composedObject)) {
            obj[denormed] = [];
            composedObject.forEach(c => {
              const instantiatedArrayMember = fb.retrieveInstantiatedObjectFromFirestore(c,true);
              if (instantiatedArrayMember.lastUpdatedAt !== null && obj[this.compositionServices.get(denormed).associatedDocumentId].includes(instantiatedArrayMember.DocId())) {
                obj[denormed].push(instantiatedArrayMember);
              }
            });
          } else {
            const composorDocumentMemberDocIdName: string  = this.compositionServices.get(denormed).associatedDocumentId;
            const instantiatedComposedDocument = fb.retrieveInstantiatedObjectFromFirestore(composedObject,true);
            if (instantiatedComposedDocument.lastUpdatedAt !== null && obj[composorDocumentMemberDocIdName] === instantiatedComposedDocument.DocId()) {
              obj[denormed] = instantiatedComposedDocument;
            } else {
              if (obj[composorDocumentMemberDocIdName] !== instantiatedComposedDocument.DocId() && obj[denormed]!==undefined) {
                FirestoreBackend.loggingService.addLog(`doc id mismatch @ ${this.basePath}  for ${denormed}`,"retrieve-from-firestore.ts" , obj);
                console.warn("doc id mismatch",this.basePath,denormed, obj[denormed], "composed", composedObject);
              }
            }
          }
        }
      });
    }
  }

  private denormalizedDataNeedsUpdated(obj: T): string[] {
    // denormalized data needs updated on client side when it has never been populated, or if it was populated but no longer is, or if the document id no longer matches the document
    // id of the denormalized object.
    const retVal: string[] = [];
    // console.log("Evaluating obj for denormalized data", obj);
    // console.log(JSON.stringify(obj));
    if (obj.retrieveFirestoreDenormalizedMemberNames().length > 0) {
      obj.retrieveFirestoreDenormalizedMemberNames().forEach((denormed) => {
        // denormalized data has never been populated.
        if ( ((obj[this.retrieveDenormalizedName(denormed)] === undefined || obj[this.retrieveDenormalizedName(denormed)] === null)
                && obj[denormed] !== undefined && obj[denormed] !== null && obj[denormed] !== "")
        // associated object is an array, and is a different length.
        || (obj[denormed] && Array.isArray(obj[denormed]) && obj[this.compositionServices.get(denormed).associatedDocumentId].length !==
              obj[this.retrieveDenormalizedName(denormed)].length)
        // associated object has been set to undefined or null, but is populated in denormalized data.
        || ((obj[denormed] === undefined || obj[denormed] === null) && obj[this.retrieveDenormalizedName(denormed)] !== undefined
            && obj[this.retrieveDenormalizedName(denormed)] !== null)
        // not an array, has been updated to differnt document id.
        || (obj[denormed] && !Array.isArray(obj[denormed]) &&
              obj[denormed].DocId() !== this.compositionServices.get(denormed).firestoreBackEnd.retrieveInstantiatedObjectFromFirestore(obj[this.retrieveDenormalizedName(denormed)],false).DocId())
        // is an array, and the document ids of the denormalized objects do not match the document ids of the associated document ids.
        || (obj[denormed] && Array.isArray(obj[denormed]) && !(obj[this.compositionServices.get(denormed).associatedDocumentId]
               .every(val =>   this._PurecachedValues.get(obj.DocId())[this.compositionServices.get(denormed).associatedDocumentId].includes(val))))
        // associated object.updateRequired is true.
        || (obj[denormed] && !Array.isArray(obj[denormed]) && (obj[denormed].updateRequired === true || obj[denormed].updatedThisCycle === true))
        // associated object is an array, and one of the objects in the array has updateRequired set to true.
        || (obj[denormed] && Array.isArray(obj[denormed]) && obj[denormed].some(val => val.updateRequired === true || val.updatedThisCycle === true))
        )
        {
          retVal.push(denormed);
        }
      });
    }
    return retVal;
  }

  // Retruns member names of all denormalized members where denormalized copy has not yet been created.
  private denormalizedDataNeedsAdded(obj: T, creating: boolean = false): string[] {
    const retVal: string[] = [];

    if (Array.isArray(obj)) {
      if (creating) {
        if (obj.length > 0){
        return obj[0].retrieveFirestoreDenormalizedMemberNames();
        } else {
          return [];
        }
      }
    }
      obj.retrieveFirestoreDenormalizedMemberNames();
    if (obj.retrieveFirestoreDenormalizedMemberNames().length > 0) {

      // When first adding an object, all denormalized data needs fresh built.
      if (creating) {
        return obj.retrieveFirestoreDenormalizedMemberNames();
      }
      // For each denormalized member, check if present in current object.
      obj.retrieveFirestoreDenormalizedMemberNames().forEach(denormed => {
        try {
          if (
            // denormalized data has never been populated.
            ((obj[this.retrieveDenormalizedName(denormed)] === undefined || obj[this.retrieveDenormalizedName(denormed)] === null) &&
              // the backing document ids are populated.
              ((obj[this.compositionServices.get(denormed).associatedDocumentId] !== undefined &&
                obj[this.compositionServices.get(denormed).associatedDocumentId] !== null &&
                obj[this.compositionServices.get(denormed).associatedDocumentId] !== '' &&
                !FirestoreBackend.deadDocIds.includes(obj[this.compositionServices.get(denormed).associatedDocumentId]
                ) && (!Array.isArray(
                  obj[this.compositionServices.get(denormed).associatedDocumentId]
                ) ||(Array.isArray(obj[this.compositionServices.get(denormed).associatedDocumentId]
                  ) &&obj[this.compositionServices.get(denormed).associatedDocumentId].length > 0))) ||
                // the root object is populated.
                (!Array.isArray(obj[denormed]) && obj[denormed] !== undefined &&
                  obj[denormed] !== null && obj[denormed] !== '') ||
                (Array.isArray(obj[denormed]) && obj[denormed].length > 0)))
            // end denormalized data never populated.

        // associated object is an array and is different length then backing document id array.
        || ((obj[this.retrieveDenormalizedName(denormed)] && Array.isArray(obj[this.retrieveDenormalizedName(denormed)]) && obj[this.retrieveDenormalizedName(denormed)].length !==
              obj[this.compositionServices.get(denormed).associatedDocumentId].length))

        // associated object is an array, and is a different length
        || (obj[denormed] && Array.isArray(obj[denormed]) && obj[denormed].length !== obj[this.retrieveDenormalizedName(denormed)]?.length &&
          !(obj[denormed].length === 0 && obj[this.retrieveDenormalizedName(denormed)] === undefined))

           // is an array, and the document ids of the denormalized objects do not match the document ids of the associated document ids.
        || (obj[denormed] && Array.isArray(obj[denormed]) && !(obj[this.compositionServices.get(denormed).associatedDocumentId]
        .every(val => obj[denormed].map(q => q.DocId()).includes(val))))

        ||
        // associated object has been set to undefined or null, but is populated in denormalized data.
        (obj[denormed] === undefined || obj[denormed] === null) && obj[this.retrieveDenormalizedName(denormed)] !== undefined && obj[this.retrieveDenormalizedName(denormed)] !== null) {
          console.log(this.basePath, obj.DocId(), denormed);
          console.log(obj[denormed], denormed, this.basePath, obj[this.retrieveDenormalizedName(denormed)], obj.DocId());
          retVal.push(denormed);
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    });
    }
    return retVal;
  }

  private populateMissingDenormalizedData(obj: T, denormalizedDataNeedsAdded:string[], creating: boolean = false ) : void {
    if (denormalizedDataNeedsAdded.length > 0) {
        const populatedDenormalized = [];
        if (creating) {
          populatedDenormalized.push(...denormalizedDataNeedsAdded);
        } else {
          denormalizedDataNeedsAdded.forEach(d => {
            if (Array.isArray(obj[d]) && obj[d].map(q => q.DocId()).filter(b => !obj[this.compositionServices.get(d).associatedDocumentId].includes(b)).length ===0
             && obj[this.compositionServices.get(d).associatedDocumentId].filter(b => !obj[d].map(q=>q.DocId()).includes(b)).length ===0) {
              populatedDenormalized.push(d);
            }
            else if (!Array.isArray(obj[d])) {
              if (obj[d] && obj[d].DocId() === obj[this.compositionServices.get(d).associatedDocumentId]) {
                populatedDenormalized.push(d);
              }
              else if (!obj[d] && !obj[this.compositionServices.get(d).associatedDocumentId]) {
                populatedDenormalized.push(d);
              }
            }
            // if member has not been properly populated, then remove it from the composer object.
            if (!populatedDenormalized.includes(d)) {
              delete obj[d];
              delete obj[this.retrieveDenormalizedName(d)];
            }
          });
        }

        populatedDenormalized.forEach(denormed => {
        this.populateDenormlizedForObject(obj, denormed, creating);
        });
      }
    }

  private populateDenormlizedForObject(obj: T, denormed: string, creating: boolean = false) {
    const composedObject = obj[denormed];
    if (composedObject && creating) {
      const toDenormalize =  this.compositionServices.get(denormed).firestoreBackEnd.denormalizedDataNeedsAdded(composedObject,true);
          if (toDenormalize.length > 0) {
            this.compositionServices.get(denormed).firestoreBackEnd.populateMissingDenormalizedData(composedObject, toDenormalize, creating);
          }
    }
    if (composedObject) {
      const fb = this.compositionServices.get(denormed).firestoreBackEnd;
      if (Array.isArray(composedObject)) {
        obj[this.retrieveDenormalizedName(denormed)] = [];
        composedObject.forEach(c => {
          if (c.lastUpdatedAt === null) {
            c.lastUpdatedAt = new Date(2000,0,1);
          }
          obj[this.retrieveDenormalizedName(denormed)].push(fb.RetrievePrincipleObjectToCommit(c));
        });
      } else {
        if (composedObject.lastUpdatedAt === null) {
          composedObject.lastUpdatedAt = new Date(2000,0,1);
        }
          obj[this.retrieveDenormalizedName(denormed)] = fb.RetrievePrincipleObjectToCommit(composedObject);
      }
    } else {
      obj[this.retrieveDenormalizedName(denormed)] = null;
    }
  }

  retrieveDenormalizedName(memberName: string) : string {
    return "_"+memberName;
  }

  retrieveNormalizedName(denormalizedName: string) : string {
    return denormalizedName.substring(1);
  }


  public static debounceAllForTime(time: number) : void {
    FirestoreBackend.debounceAll$.next(true);
    setTimeout(() => FirestoreBackend.debounceAll$.next(false), time);
  }

  debounceDocId(docId: string) : void {
      const debounceLoad : ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
      debounceLoad.next(true);
      this.debounceLoad$.set(docId, debounceLoad);
  }

  turnOffDebounce(docId: string) : void {
    const debounceSubject = this.debounceLoad$.get(docId);
    if (debounceSubject) {
      debounceSubject.next(false);
    }
  }

  incrementSubscribers(docId: string) {
    if (FirestoreBackend.subscriberCount.get(docId) !== undefined) {
      const counter = FirestoreBackend.subscriberCount.get(docId) + 1;
      FirestoreBackend.subscriberCount.set(docId,counter);
    } else {
      FirestoreBackend.subscriberCount.set(docId,1);
    }
  }

  unsubscribeOnFinalize(docId: string) {
    const subscriberCount = FirestoreBackend.subscriberCount.get(docId);
    if ( subscriberCount > 1 ) {
      FirestoreBackend.subscriberCount.set(docId, subscriberCount - 1);
    } else {
      FirestoreBackend.retrievingDocId$.pipe(
        filter(y => y === docId),
        timeout(Math.random() * (65000-55000) + 55000),
        // timeout(5000),
        take(1)
      ).subscribe(
        // because another subsriber has been added (emission occurred b/f timeout); we will always decrement here.
        value => {
          const counter = FirestoreBackend.subscriberCount.get(docId) -1;
          FirestoreBackend.subscriberCount.set(docId,counter);
        },
        // since there is no fresh subscriber it is possible (though not guranteed) that we no longer want to maintain an active observation of
        // docId.
        err => {
          // console.log("UNSUBSCRIBING LAUNCHED HERE");
          const counter = FirestoreBackend.subscriberCount.get(docId) -1;
          if (counter > 0) {
            FirestoreBackend.subscriberCount.set(docId,counter);
          } else {
            FirestoreBackend.subscriberCount.delete(docId);
            setTimeout(() => FirestoreBackend.unsubscriber.next(docId), FirestoreBackend.unsubscriptionTimer);
          }
        });
    }
  }


  private cloneDateValuesWhenNeeded(o: object) : object {
    for (const prop in o) {
      if (o[prop] && o[prop].getUTCDay) {
        o[prop] = new Date(o[prop]);
      }
    }
    return o;
  }

  /**
   * Shallow clones object and removes properties not serialized to Firestore.
   */
  RetrievePrincipleObjectToCommit(source: T): object {
    const retVal = { ...cloneDeep(source) };
    source
      .retrieveFirestoreIgnoredMemberNames()
      .forEach((ignore) => delete retVal[ignore]);
    return retVal;
  }

  public writeBatchNeeded(b?: string | null): boolean {
    return b === undefined || b === null ? true : false;
  }

  public static retrieveFirestoreWriteBatchIdentifier(): Observable<string> {
    const guid = Math.random().toString(36).substring(0, 7);

    setTimeout(() =>
        FirestoreBackend.worker.postMessage({
          operation: 'createBatch',
          signalGuid: guid,
        }),FirestoreBackend.firestoreBackendMessageDelayMs);

      return FirestoreBackend.firestoreBackendMessage$.pipe(
      filter((x) => x.signalGuid === guid),
      map((x) => {
        return x['batchId'] as string
      }),
      take(1),
      share(),
    );
  }

  public commitFirestoreWriteBatch(b: string): Observable<void> {

    if (b === null || b === undefined || !(typeof b === 'string')) {
      const docIdType = b === undefined ? "undefined" : b === null ? 'null' : typeof b;
      FirestoreBackend.loggingService.addLog(`batch id is ${docIdType} in commitFirestoreWriteBatch`, 'retrieve-from-firestore.ts',{basePath: this.basePath, catagory:"firestore invalid operation"});
      return;
    }

    setTimeout(() => FirestoreBackend.worker.postMessage({
      operation: 'commitBatch',
      batchId: b,
      signalGuid: b,
      waitForSuccess: false,
    }),FirestoreBackend.firestoreBackendMessageDelayMs);

    return FirestoreBackend.firestoreBackendMessage$.pipe(
      filter((x) => x.signalGuid === b),
      map(() => void 0),
      take(1)
    );
  }

  protected basePathRead(): string { return `${this.authService.firebasePathRead}${this.basePath}`; }
  protected basePathWrite(): string { return `${this.authService.firebaseBasePathWrite}${this.basePath}`;}

  public retrieveDocId(): Observable<string> {
    const guid = Math.random().toString(36).substring(0, 7);

    setTimeout(() =>
        FirestoreBackend.worker.postMessage({
          operation: 'docId',
          signalGuid: guid,
          path: this.basePathWrite(),
        }),FirestoreBackend.firestoreBackendMessageDelayMs);

      return FirestoreBackend.firestoreBackendMessage$.pipe(
      filter((x) => x.signalGuid === guid),
      map((x) => x['docId'] as string),
      take(1)
    );
  }

  private atomicInitiation(batchId: string): {guid: string;retVal: Observable<string>;} {
    const guid = Math.random().toString(36).substring(0, 7);
    const retVal = FirestoreBackend.firestoreBackendMessage$.pipe(
      filter((x) => x.signalGuid === guid),
      map(() => batchId),
      take(1)
    );
    return { guid, retVal };
  }

  public atomicallyIncrementField(obj: T,fieldName: string,toAdd: number,batchId: string): Observable<string> {
    const val = this.atomicInitiation(batchId);

    setTimeout(() => FirestoreBackend.worker.postMessage({
      operation: 'atomicallyIncrementField',
      signalGuid: val.guid,
      collection: this.basePathWrite(),
      docId: obj.DocId(),
      fieldName: fieldName,
      toAdd: toAdd,
      batchId: batchId,
      createIfAbsent: false,
    }),FirestoreBackend.firestoreBackendMessageDelayMs);

    return val.retVal;
  }

  public atomicallyIncrementFieldCreateIfAbsent(obj: T,fieldName: string,toAdd: number,batchId: string): Observable<string> {
    const val = this.atomicInitiation(batchId);

    setTimeout(() =>
        FirestoreBackend.worker.postMessage({
          operation: 'atomicallyIncrementField',
          signalGuid: val.guid,
          collection: this.basePathWrite(),
          docId: obj.DocId(),
          fieldName: fieldName,
          toAdd: toAdd,
          batchId: batchId,
          createIfAbsent: true,
        }),FirestoreBackend.firestoreBackendMessageDelayMs);

    return val.retVal.pipe(
    map(() => batchId)
    );
  }

  /**
   * If object has firestore diffs, we add a creational entry when object is created.
   * @param composerObject
   * @param batch
   */
  private commitCreationalFirestoreDiffIfTracked(composerObject: T,b: string): Observable<T> {
    const firestoreDiffBackend = this.compositionServices.get('firestoreDiffs');
    if (firestoreDiffBackend === undefined) {
      return of(composerObject);
    } else {
      const creationalDiff = new FirestoreDiff({
        activeDocId: composerObject.DocId(), changeType: 'add', associatedDocId: composerObject.DocId(),
        dateCreated: new Date(), employeeInitiatingChangeDocId:this.authService.activelyLoggedInEmployeeDocId,
        userMessage: `Created at ${new Date().toLocaleString()}`,
      });
      return firestoreDiffBackend.firestoreBackEnd
        .createToFirestore(creationalDiff, b)
        .pipe(
          map((created) => {
            composerObject[firestoreDiffBackend.associatedDocumentId].push(
              created.DocId()
            );
            return composerObject;
          })
        );
    }
  }

  /**
   * Iterate through composition members, committing them in order.  Returns hydrated composerObject including DocIds.
   * @param composerObject Principle object we are committing.
   */
  private commitCompisitionMembers(composerObject: T,batchString?: string): Observable<T> {
    if (composerObject.retrievefirestoreCompositionMemberNames().length === 0) {
      return of(composerObject);
    }
    const aggregateObs$: Observable<{composorDocumentMemberDocIdName: string, composorDocumentMemberName: string;
      aggregateProperty: | RetrieveFirestoreProperties | RetrieveFirestoreProperties[];
    }>[] = [];

      const numberAggreateObjects = composerObject.retrievefirestoreCompositionMemberNames().length;

      composerObject.retrievefirestoreCompositionMemberNames().forEach(composorDocumentMemberName => {
        if (!this.compositionServices.has(composorDocumentMemberName)) {
          throw new Error(`${composorDocumentMemberName} has no associated FirestoreBackend provided in Map.`);
        }
        const composorDocumentMemberDocIdName: string  = this.compositionServices.get(composorDocumentMemberName).associatedDocumentId;
        const associatedBackend = this.compositionServices.get(composorDocumentMemberName).firestoreBackEnd;

        if (Array.isArray(composerObject[composorDocumentMemberName])) {
          const subObs$: Observable<({composorDocumentMemberDocIdName: string, composorDocumentMemberName: string,
                                      aggregateProperty: RetrieveFirestoreProperties})>[] = [];
          if (composerObject[composorDocumentMemberName].length > 0) {
            composerObject[composorDocumentMemberName].forEach((element) => {
              subObs$.push(
                associatedBackend
                  .update(element, batchString)
                  .pipe(
                    map((x) => ({
                      composorDocumentMemberDocIdName,
                      composorDocumentMemberName,
                      aggregateProperty: x,
                    }))
                  )
              );
            });
          } else {
            subObs$.push(of({composorDocumentMemberDocIdName, composorDocumentMemberName,
                             aggregateProperty: null}));
          }
          const mappedToArray$ = combineLatest(...subObs$).pipe(
            map( x => {
              const retVal = { composorDocumentMemberDocIdName, aggregateProperty : [], composorDocumentMemberName};
              x.forEach(single => {
                if (single.aggregateProperty !== null) {
                  retVal.aggregateProperty.push(single.aggregateProperty);
                }});
              return retVal;
            }));
          aggregateObs$.push(mappedToArray$);
          // not array.
        } else {
        const oneAggregateObs$ = associatedBackend.update(composerObject[composorDocumentMemberName],  batchString).pipe(
          map( x => ({composorDocumentMemberDocIdName, composorDocumentMemberName, aggregateProperty: x})),
          );
        aggregateObs$.push(oneAggregateObs$);
        }
      });
      const combo$ = combineLatest([of(composerObject), ...aggregateObs$]).pipe(
        map(x => {
          const retVal = x[0];
          for (let i = 1; i <= numberAggreateObjects; i++) {
            const typed = x[i] as ({composorDocumentMemberDocIdName: string , composorDocumentMemberName: string,
              aggregateProperty: RetrieveFirestoreProperties | RetrieveFirestoreProperties[]} );
            retVal[typed.composorDocumentMemberName] = typed.aggregateProperty;
            if (Array.isArray(typed.aggregateProperty)) {
              retVal[typed.composorDocumentMemberDocIdName] = typed.aggregateProperty.filter(q => q !== null).map(z => z.DocId());
            } else {
            if (Array.isArray(retVal[typed.composorDocumentMemberDocIdName])) {
              retVal[typed.composorDocumentMemberDocIdName] = [];
            } else {
              if (typed.aggregateProperty !== undefined && typed.aggregateProperty !== null) {
                try {
                  retVal[typed.composorDocumentMemberDocIdName] = typed.aggregateProperty.DocId();
                  } catch (error) {
                    console.log(x);
                    console.log(x[i]);
                    console.log(error);
                    throw error;
                  }
              } else {
                retVal[typed.composorDocumentMemberDocIdName] = null;
              }
            }
          }
        }
          return retVal;
        }),
      );
      return combo$;
    }


    public trackChange(changeType: string) {
      switch (changeType) {
        case "created":
        case "updated":
        case "deleted": {
          return true;
          break;
        }
          default: {
            return false;
          }
      }
    }




  /**
   * Method creates object if doesn't yet exist (to enable recursive updtates of composed objects).
   * Returns Observable of the updated / created object
   * @param obj The object to update
   * @param batch WriteBatch for committing.
   */
  public update(obj: T, batch?: string, errorCount: number = 0): Observable<T> {
    // There is no concept of storing null or undefined object values -> firestore for RetrieveFirestoreProperties objects.
    if (obj === undefined || obj == null) {
      return of(obj);
    }


    if (FirestoreBackend.verboseFirestoreObjectCRUDLogging) {
      console.warn(`Updating ${obj.DocId()}  basepath : ${this.basePath}`);
    }

      // If object was lazy-loaded, it can only be updated through lazy retrieve from firestore, b/c the clent of object is responsible
      // for management of all composed objects.
      if (obj.lazyLoaded) {
        throw new Error(`Cannot compositionally update lazy loaded object.  Attempting to update : ${obj.DocId}  composed of : ${obj.retrievefirestoreCompositionMemberNames().join()}`);
      }

      if (obj.immutable && obj.updateRequired && !(obj.DocId() === undefined || obj.DocId() === null || obj.uncommitedDocId)) {
        console.error("PREVIOUS:");
        const dist = this.getValueFromPreceedingEmissionIfCached(obj.DocId());
        console.log(JSON.stringify(this.getValueFromPreceedingEmissionIfCached (obj.DocId())));
        console.error("CURRENT:");
        console.log(JSON.stringify(obj));
        FirestoreBackend.loggingService.addLog(`Attempting to update immutable object : ${obj.DocId()}  basepath : ${this.basePath}`,"retrieve-from-firestore.ts" ,
          {prev: JSON.stringify(this.getValueFromPreceedingEmissionIfCached(obj.DocId())), curr: JSON.stringify(obj)}, NgxLoggerLevel.ERROR);
        // throw new Error(`Cannot compositionally update immutable object.  Attempting to update : ${obj.DocId()}  basepath : ${this.basePath}`);
      }

      // If object hasn't been added to firestore, create it.  Otherwise can't update parent w/ nested children that require creation.
      if (obj.DocId() === undefined || obj.DocId() === null || obj.uncommitedDocId) {
        return this.createToFirestore(obj, batch);
      }

      if (!obj.updateRequired) {
        return of(obj);
      } else {
        if (FirestoreBackend.verboseFirestoreObjectCRUDLogging) {
          console.warn('Update required', obj);
        }
      }

    const responsibleForCommit = this.writeBatchNeeded(batch);
    let batchResolved = of(batch);
    if (responsibleForCommit) {
      batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
    }

    let batchString: string;

    batchResolved = batchResolved.pipe(
      tap((x) => (batchString = x)),
      share()
    );


    // Hydrate document id's of compositional objects.
    // if we are actively creating object in question, we must wait for it to be created b/f attempting to update it.
    const compositionalUpdates$ = batchResolved.pipe(
      switchMap((b) => this.commitCompisitionMembers(obj, b))
    );

    const sourceWithCompositionalUpdatesPopulated$ = compositionalUpdates$.pipe(
      map((x) => {
        return { val: x, toDenormalize: this.denormalizedDataNeedsUpdated(x) };
      }),
      share()
    );

    const updateSourceWithCompositional$ = sourceWithCompositionalUpdatesPopulated$.pipe(
        filter((x) => x.toDenormalize.length > 0 || x.val.updatePrincipalRequired),
        map((x) => {
          if (x.toDenormalize.length > 0) {
            this.populateMissingDenormalizedData(x.val, x.toDenormalize);
          }
          x.val.bucketId = this.authService.bucketId;
          x.val.lastUpdatedAt = new Date();
          x.val.appVersionUpdate = FirestoreBackend.localSettingsService.appVersion;
          x.val.lastUpdatedByGuid = this.authService.guid;
          x.val.lastUpdatedByEmployeeDocId = this.authService.activelyLoggedInEmployeeDocId;
          return {
            val: x.val, guid: Math.random().toString(36).substring(0, 7),
          };
        }),
        tap((x) => {
          if (x.val.DocId() === null || x.val.DocId() === undefined || !(typeof x.val.DocId() === 'string')) {
            const docIdType = x.val.DocId() === undefined ? "undefined" : x.val.DocId() === null ? 'null' : typeof x.val.DocId();
            FirestoreBackend.loggingService.addLog(`Attempt to update ${docIdType} in updateSourceWIthCompositional`, 'retrieve-from-firestore.ts',
              {basePath: this.basePath,catagory:"firestore invalid operation"});
            throw new Error(`Attempt to update ${docIdType} in updateSourceWIthCompositional`);
          } else {
            setTimeout(() => FirestoreBackend.worker.postMessage({
              operation: 'update',
              signalGuid: x.guid,
              collection: this.basePathWrite(),
              docId: x.val.DocId(),
              val: this.RetrievePrincipleObjectToCommit(x.val),
              batchId: batchString,
              merge: false,
            }),FirestoreBackend.firestoreBackendMessageDelayMs);
          }
        }),
        switchMap((x) =>
          FirestoreBackend.firestoreBackendMessage$.pipe(
            filter((y) => y.signalGuid === x.guid && y.docId === x.val.DocId()),
            map(() => {
              x.val.updateRequired = false;
              x.val.updatePrincipalRequired = false;
              return x.val;
            }),
            take(1)
          )
        )
      );

    const noSourceUpdateRequired$ =
      sourceWithCompositionalUpdatesPopulated$.pipe(
        filter((x) => x.toDenormalize.length === 0 && !x.val.updatePrincipalRequired),
        map((x) => {
          if (x.val.updateRequired && !x.val.createdObject) {
            console.warn('UPDATE REQUIRED BUY UPDATE PRINCIPLE NOT REQUIRED?');
            console.warn(x);
            console.warn('UPDATE REQUIRED BUY UPDATE PRINCIPLE NOT REQUIRED?');
            x.val.lastUpdatedAt = new Date();
          }
          return x.val;
        })
      );

    const mergedUpdate$ = merge(updateSourceWithCompositional$,noSourceUpdateRequired$);

    // Because these methods are take(1) we do not need to increment number of subscribers or finalize.
    if (responsibleForCommit) {
      return mergedUpdate$.pipe(
        switchMap((x) => this.commitFirestoreWriteBatch(batchString).pipe(map(() => x))),
        switchMap((fresh) =>
          this.readFromCacheOrFirestore(fresh.DocId(), fresh).pipe(map(() => fresh))
        ),
        take(1)
      );
    } else {
      return mergedUpdate$.pipe(
        //2025-1-2 We may want to put this back in, it is removed because it causes us post two updates to any observers of the
        // object which is being updated, one here and a second on when the batch is committed.  Which can cause jenky-ness as more
        //we then render twice, calculate twice, etc.....
        // switchMap((fresh) => this.readFromCacheOrFirestore(fresh.DocId(),fresh).pipe(map(() => fresh))
        take(1)
      );
    }
  }

  public createMultipleAtomically(obj: T[], batch?: string): Observable<T[]> {
    let batchResolved = of(batch);
    const responsibleForCommit = this.writeBatchNeeded(batch);
    if (responsibleForCommit) {
      batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
    }

    let b: string;
    batchResolved = batchResolved.pipe(
      tap((x) => (b = x)),
      share()
    );

    const created$ = batchResolved.pipe(
      map((batchId) => {
        const multiples: Observable<T>[] = [];
        obj.forEach((x) => multiples.push(this.createToFirestore(x, batchId)));
        return multiples;
      }),
      switchMap((multiples) => combineLatest([...multiples]))
    );

    if (responsibleForCommit) {
      return created$.pipe(
        switchMap((x) => this.commitFirestoreWriteBatch(b).pipe(map(() => x))),
        take(1)
      );
    } else {
      return created$;
    }
  }

    /**
     * Creates object, first iterating through updating all compositional members.  Errors if object has pre-populated document id.
     * Returns (newly) cached observable for object.
     */
  public createToFirestore(obj: T, batch?: string, errorCount: number = 0): Observable<T> {
    // console.warn(obj.DocId(), "createToFirestore",this.basePath);
    if (obj.DocId() !== undefined && obj.DocId() !== null && obj.uncommitedDocId === false) {
      throw new Error(
        `Can't create objects with assigned firestore document ids. ${obj} docid : ${obj.DocId()}  uncommitted: ${
          obj.uncommitedDocId}  basepath : ${this.basePath}`
      );
    } else {
      let batchResolved = of(batch).pipe(share());
      const responsibleForCommit = this.writeBatchNeeded(batch);
      if (responsibleForCommit) {
        batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
      }

      let b: string;
      batchResolved = batchResolved.pipe(
        tap((x) => (b = x)),
        share()
      );

      // if obj has uncommitted docId
      const uncommittedDocId$ = batchResolved.pipe(
        filter(() => obj.uncommitedDocId === true),
        delay(1),
        tap(() => (obj.uncommitedDocId = false)),
        take(1)
      );

      // if object needs doc id
      const needsDocId$ = batchResolved.pipe(
        filter(() => obj.uncommitedDocId === false),
        switchMap((batchId) => this.retrieveDocId().pipe(
            tap((x) => obj.SetDocId(x)),
            map(() => batchId)
          )
        ),
        take(1)
      );


      // merge(uncommittedDocId$, needsDocId$).pipe(
      //   tap(x => console.log(x,` string`)),
      // ).subscribe();

      const compositionalUpdates$ = merge(uncommittedDocId$, needsDocId$).pipe(
        take(1),
        switchMap((batchId) => this.commitCompisitionMembers(obj, batchId))
      );

      let sourceWithCompositionalUpdatesPopulated$ = compositionalUpdates$.pipe(
        // switchMap(z => this.commitCreationalFirestoreDiffIfTracked(z, batch).pipe(
        map((x) => {
          const toDenormalize = this.denormalizedDataNeedsAdded(x,true);
          if (toDenormalize.length > 0) {
            console.warn('aaaaaaaaaaaaaaaaaaaaa');
            this.populateMissingDenormalizedData(x, toDenormalize, true);
          }
          const atomicDate = new Date();
          x.createdAt = atomicDate;
          x.lastUpdatedAt = atomicDate;
          x.bucketId = this.authService.bucketId;
          x.appVersionUpdate = FirestoreBackend.localSettingsService.appVersion;
          x.appVersionCreate = FirestoreBackend.localSettingsService.appVersion;
          x.lastUpdatedByGuid = this.authService.guid;
          x.lastUpdatedByEmployeeDocId = this.authService.activelyLoggedInEmployeeDocId;
          x.createdObject = true;
          return { val: x, guid: Math.random().toString(36).substring(0, 7) };
        }),
        tap((x) => {
          setTimeout(() =>
          FirestoreBackend.worker.postMessage({
            operation: 'create',
            signalGuid: x.guid,
            collection: this.basePathWrite(),
            docId: x.val.DocId(),
            val: this.RetrievePrincipleObjectToCommit(x.val),
            batchId: b,
            merge: false,
          }),FirestoreBackend.firestoreBackendMessageDelayMs);
        }),
        switchMap((x) =>
          FirestoreBackend.firestoreBackendMessage$.pipe(
            filter((y) => y.signalGuid === x.guid && y.docId === x.val.DocId()),
            map(() => {
              x.val.updateRequired = false;
              x.val.updatePrincipalRequired = false;
              x.val.createdObject = true;
              return x.val;
            }),
            take(1)
          )
        )
      );

      if (responsibleForCommit) {
        sourceWithCompositionalUpdatesPopulated$ =
          sourceWithCompositionalUpdatesPopulated$.pipe(
            mergeMap((x) =>
              this.commitFirestoreWriteBatch(b).pipe(map(() => x))
            ),
            take(1)
          );
      }

      // We populate the observation of this document, but we want to return the object as it will be after it has been fully committed to firestore
      // and read back as such.
      if (this.basePathRead() === this.basePathWrite()) {
        return sourceWithCompositionalUpdatesPopulated$.pipe(
          mergeMap((popObj) =>
            this.readFromCacheOrFirestore(popObj.DocId(), popObj)
          ),
          take(1)
        );
      } else {
        return sourceWithCompositionalUpdatesPopulated$.pipe(take(1));
      }
    }
  }

  /**
   * Deletes obj. from firestore and removes from cache.
   * Does not delete (or remove observables for) nested objects.
   */
  public delete(obj: RetrieveFirestoreProperties, batch?: string): Observable<void> {

    const guid = Math.random().toString(36).substring(0, 7);

    let batchResolved = of(batch).pipe(share());
    const responsibleForCommit = this.writeBatchNeeded(batch);
    if (responsibleForCommit) {
      batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
    }

    let retVal = batchResolved.pipe(
      tap((x) => {
        setTimeout(() =>
        FirestoreBackend.worker.postMessage({
          operation: 'delete',
          signalGuid: guid,
          collection: this.basePathWrite(),
          docId: obj.DocId(),
          batchId: x,
        }),FirestoreBackend.firestoreBackendMessageDelayMs);
      }),
      switchMap((x) =>
        FirestoreBackend.firestoreBackendMessage$.pipe(
          filter((y) => y.signalGuid === guid),
          map(() => x)
        )
      )
    );

    if (responsibleForCommit) {
      return retVal.pipe(
        switchMap((x) => this.commitFirestoreWriteBatch(x)),
        map(() => void 0),
        take(1)
      );
    } else {
      return retVal.pipe(
        map(() => void 0),
        take(1)
      );
    }
  }

  private readArrayFromFirestore(docIds: string[], forceFresh: boolean = false): Observable<T[]> {

      if (docIds.length === 0) {
        return of([]);
      }

      const aggregateObs$: Observable<T>[] = [];
      docIds.forEach(x => {
        this.incrementSubscribers(x);
        aggregateObs$.push(this.readFromCacheOrFirestore(x, undefined, forceFresh).pipe(
          finalize(() => this.unsubscribeOnFinalize(x))
        ));
      });
      return combineLatest([...aggregateObs$]);
    }

  /**
   * Remember that this method does not hydrate composed objects, nor does it cache objects.
   * So depending on use case one may want to pipe results through
   * load multiple.
   * @param queryFn : firestore query
   */
  public QueryFirestoreShallowValueChanges(queryFn: QueryConstraint[], errorCount: number = 0): Observable<T[]> {

    if (queryFn !== undefined) {
      for (const q of queryFn) {
        if (q.type !== "orderBy" && (q as any)._value === undefined && (q as any)._field !== undefined) {
          console.warn(`Attempt to query with field constraint type undefined in queryFirestoreShallowValueChanges`);
          FirestoreBackend.loggingService.addLog(`Attempt to query with field constraint type undefined in queryFirestoreShallowValueChanges`,
             'retrieve-from-firestore.ts',{basePath: this.basePath, field: (q as any)._field.segments,catagory:"firestore invalid operation"});
          return of ([]);
        }
        if ( q instanceof QueryFieldFilterConstraint) {
          if ( ["in","array-contains","array-contains-any"].indexOf((q as any)._op) > -1 && (q as any)._value.indexOf(null) > -1) {
            FirestoreBackend.loggingService.addLog(`Attempt to query with field constraint type undefined in queryFirestoreShallowValueChanges`,
              'retrieve-from-firestore.ts',{basePath: this.basePath,field: (q as any)._field.segments,catagory:"firestore invalid operation"});
            const updatedQueryFn = queryFn.filter(x => x !== q);
            // if only null is present, then result set is logically an empty array.  Otherwise we return non-null values as results of query
            if (updatedQueryFn.length === 0) {
              return of([]);
            } else {
              return this.QueryFirestoreShallowValueChanges(updatedQueryFn,errorCount);
            }
          }
        }
      }
    }

      let source: Observable<T[]> = undefined;
      const cachedDocIds = new Set<string>();

      let guid = null;
      FirestoreBackend.numberObservers++;
      FirestoreBackend.numberCollectionObservers++;

    const rawSource = FirestoreBackend.accessFirestoreInnodbToggled$.pipe(filter((x) => x === true)).pipe(
      tap(() => {
        if (guid !== null) {
          FirestoreBackend.worker.postMessage({
            operation: 'unsubscribe',
            signalGuid: guid,
          });
        }
        guid = Math.random().toString(36).substring(0, 14);
        if (
          this.authService.logExtendedSnapshotInformation &&
          !this.sorted.some((s) => this.basePathRead().includes(s))
        ) {
          if (!FirestoreBackend.activeFillz.has(`${this.basePathRead()} - ${queryFn}`)) {
            FirestoreBackend.activeFillz.set(`${this.basePathRead()} - ${queryFn}`,1);
          } else {
            FirestoreBackend.activeFillz.set(`${this.basePathRead()} - ${queryFn}`,FirestoreBackend.activeFillz.get(`${this.basePathRead()} - ${queryFn}`) + 1);
          }
          console.log(`FILLZ ${this.basePathRead()} - ${queryFn} - ${FirestoreBackend.numberCollectionObservers}`);
        }
      }),
      tap(() => FirestoreBackend.platformService.outstandingSubscriptions++),
      tap(() => {
        const { json, meta } = superjson.serialize(queryFn);
        setTimeout(() => FirestoreBackend.worker.postMessage({
          operation: 'querySnapshot',
          signalGuid: guid,
          collection: this.basePathRead(),
          queryFn: { json, meta},
        }),FirestoreBackend.firestoreBackendMessageDelayMs);
      }),
      switchMap(() => FirestoreBackend.firestoreBackendMessage$.pipe(
          filter((y) => y.signalGuid === guid),
          map(x => x['docs']),
          takeUntil(FirestoreBackend.accessFirestoreInnodbToggled$.pipe(filter((x) => x === false))),
          finalize(() => {
            FirestoreBackend.platformService.outstandingSubscriptions--;
            FirestoreBackend.worker.postMessage({
              operation: 'unsubscribe',
              signalGuid: guid,
            });
          }),
        )
      )
    );

    source = rawSource.pipe(
      debounce(() =>
        FirestoreBackend.debounceAll$.pipe(filter((x) => x === false))
      ),
      map((x) => {
        if (x !== undefined) {
          return x.map((y) => y as T);
        } else {
          return [];
        }
      })
    );

    const result = source.pipe(
      map((x) => {
        const retVal: T[] = [];
        x.forEach((val) => {
          // When firestore has cached knowledge a blank document exists, it returns empty object as part of array
          // which isn't what we want.
          if (!isEmpty(val)) {
            const r = this.retrieveInstantiatedObjectFromFirestore(val as object,true);
            // If object has no composite members, it should be cached here for consistency (as we do this with all methods which return
            // more complex objects.)
            // NEED TO EXPAND THIS SO THAT IF ALL COMPOSED MEMBERS ARE DENORMALIZED AND LOADED WE ALSO WILL CACHE HERE>
            if (this.PrototypeObject.retrievefirestoreCompositionMemberNames().length !== 0) {
              r.lazyLoaded = true;
            }
            retVal.push(r);
          }
        });
        return retVal;
      }),
      share()
    );

        const ceaseObservationSignalForCompositions = Math.random().toString(36).substring(0, 10);

        // if there are no composed members, we can cache the results here.
        result.pipe(
          tap(results => {

            if ( this.PrototypeObject.retrievefirestoreCompositionMemberNames().length === 0 || (results.length > 0 &&  results.every(x => this.PrototypeObject.retrieveFirestoreDenormalizedMemberNames().forEach(p => x[p] !== undefined))) ) {
              if (this.PrototypeObject.retrievefirestoreCompositionMemberNames().length > 0) {
                console.log("WEALSAL PENISE");
              }
              results.forEach(r => {
                if (!cachedDocIds.has(r.DocId())) {
                  cachedDocIds.add(r.DocId());
                  this.setupCachedObservableFromNonComposedStream(r.DocId(), result, ceaseObservationSignalForCompositions, this, r);
                  }
              });
            }
          }),
          takeUntil(FirestoreBackend.unsubscriber.pipe(filter(y => y === ceaseObservationSignalForCompositions))),
        ).subscribe();

          return result.pipe(
          finalize(() =>{
            FirestoreBackend.numberObservers--;
            FirestoreBackend.numberCollectionObservers--;
            if (this.authService.logExtendedSnapshotInformation && !this.sorted.some(s => this.basePathRead().includes(s))) {
              if (FirestoreBackend.activeFillz.has(`${this.basePathRead()} - ${queryFn}`) ) {
                console.log("HAD SHALLOW QUERY");
                FirestoreBackend.activeFillz.set(`${this.basePathRead()} - ${queryFn}`, FirestoreBackend.activeFillz.get(`${this.basePathRead()} - ${queryFn}`) - 1);
                if (FirestoreBackend.activeFillz.get(`${this.basePathRead()} - ${queryFn}`) === 0) {
                  FirestoreBackend.activeFillz.delete(`${this.basePathRead()} - ${queryFn}`);
                }
              } else {
                console.warn("DIDNT HAVE SHALLOW QUERY");
              }
              console.log(`FILLZ FINALIZE ON SHALLOW ${this.basePathRead()} - ${queryFn} - tot: ${FirestoreBackend.numberObservers} col:${FirestoreBackend.numberCollectionObservers}`);
            }
            // FirestoreBackend.unsubscriber.next(ceaseObservationSignalForCompositions);
            setTimeout(() => FirestoreBackend.unsubscriber.next(ceaseObservationSignalForCompositions), FirestoreBackend.unsubscriptionTimer);
          }),
          catchError(err => {
            if (errorCount < 5) {
              FirestoreBackend.loggingService.addLog(err.message,"retrieve-from-firestore.ts" , {method: "QueryFirestoreShallowValueChanges", queryFn: queryFn, errorCount: errorCount});
              return this.QueryFirestoreShallowValueChanges(queryFn, errorCount + 1);
            }
            else {
              return throwError(err);
            }
        }),
          );
  }

  /**
   * Returns shallow objects which match provided in query.
   * @param fieldPath
   * @param values
   */
  public queryFirestoreForInValues(fieldPath: string, values: any[], queryArray: boolean = false): Observable<T[]> {
    const theMix: Observable<T[]>[] = [];
    if (values.length === 0) {
      return of([]);
    }
    const unique = [...new Set(values)];
    do {
      const query = queryArray ? this.QueryFirestoreShallowValueChanges([where(`${fieldPath}`, 'array-contains-any', (unique.splice(0, 10)))]) :
      this.QueryFirestoreShallowValueChanges([where(`${fieldPath}`, 'in', (unique.splice(0, 10)))]);
      theMix.push(query.pipe(
        catchError(err => {
          console.log('Error querying firestore.', err);
          return throwError(err);
      })));
    } while (unique.length > 0);
    return combineLatest([...theMix]).pipe(
      map(x => {
        const retVal = [];
        x.forEach(z => retVal.push(...z));
        return retVal;
      }
    ),
    takeUntil(FirestoreBackend.destroyingComponent$),
    );
  }

  public setupCachedObservableFromNonComposedStream(docId: string, source: Observable<T[]>, ceaseObservationSignal: string, fb: FirestoreBackend<RetrieveFirestoreProperties>, initialValue?: T) : void {

      if (! this.activelyObservedDocIds.has(docId)) {
        fb.setupCachedObservable(docId,fb);
      }
      fb.incrementSubscribers(docId);
      const toSubscribe = initialValue !== undefined ? source.pipe(startWith([initialValue])) : source;
      toSubscribe.pipe(
        filter(x => x!== undefined && x[0] !== undefined && x!== null && x[0] !== null),
        map(x => {
          try {
            return x.find(q => q.DocId() === docId)
          }catch (e) {
            console.log(x);
            console.warn(e);
            return undefined;
          }

        }),
        filter(x => x !== undefined),
        map(x => {
          return {val: x, json: JSON.stringify(x)};
        }),
        distinctUntilChanged((prev,curr) => {
          return curr.json === prev.json;
        }),
        map(x => x.val),
        map(x => fb.retrieveInstantiatedObjectFromFirestore(x as object, true)),
        map(x => {
            return {val: x, lastUpdatedAt: new Date(x.lastUpdatedAt)};
          }),
        distinctUntilChanged((prev,curr) => {
          return (curr.lastUpdatedAt.getTime() < prev.lastUpdatedAt.getTime());
        }),
        map(x => x.val),
        tap(x => {
          if (x.lastUpdatedAt === null) {
            x.lastUpdatedAt = new Date(0);
          }
          try {
            if (fb.cachedObservables.get(docId) === undefined) {
              fb.setupCachedObservable(docId,fb);
            }
            if (fb.cachedObservables.get(docId).value === undefined || fb.cachedObservables.get(docId).value === null ||
            fb.cachedObservables.get(docId).value.lastUpdatedAt == null ||
            fb.cachedObservables.get(docId).value.lastUpdatedAt.getTime() < x.lastUpdatedAt.getTime()) {
              fb.cachedObservables.get(docId).next(x);
            }
          } catch (e) {
            console.warn(e);
            console.warn(x);
            console.warn(fb.cachedObservables.get(docId).value);
            console.warn(docId, this.basePath);
            throw e;
          }
        }),
        takeUntil(FirestoreBackend.unsubscriber.pipe(filter(y => y === ceaseObservationSignal))),
        finalize(() => {
          // console.log(`UNSUBSCRIBE ON FINALIZE ${docId} ${this.basePath}`);
          fb.unsubscribeOnFinalize(docId);
        }),
      ).subscribe();
  }


  private setupCachedObservable(docId: string, fb: FirestoreBackend<RetrieveFirestoreProperties>) : void {
    // if (docId === "Az13LZfT58G74a2uCOcl") {
    //   console.log("SETUP CACHED OBSERVABLE");
    // }
    this.activelyObservedDocIds.add(docId);
    if (!fb.cachedObservables.has(docId)) {
      const suscriber = new BehaviorSubject<T>(undefined);
      fb.cachedObservables.set(docId, suscriber);
      fb.cachedObservables.get(docId).pipe(
          filter(x => x !== undefined),
          tap(x => {
            fb.cachedValues.set(docId,x);
            fb._PurecachedValues.set(docId,cloneDeep(x));
          }),
          takeUntil(FirestoreBackend.unsubscriber.pipe(
            filter(x=> x === docId),
            tap(() => {
              fb.cachedObservables.delete(docId);
              this.activelyObservedDocIds.delete(docId);
            })
            )),
        ).subscribe();
    }
  }


  private populatedComposedDenormalizedMembers(obj: T, objObs: Observable<T>, composedMembersThatRequireFurtherRetrival: string[],
    ceaseObservationSignal: string) : void {
    this.prototypeObject.retrievefirestoreCompositionMemberNames().filter(x => !composedMembersThatRequireFurtherRetrival.includes(x))
    .forEach(composedMemberName => {
      const backEnd = this.compositionServices.get(composedMemberName).firestoreBackEnd;
      if (Array.isArray(obj[composedMemberName])) {
        if ((obj[composedMemberName] as Array<any>).length > 0) {
          (obj[composedMemberName] as Array<any>).forEach(r => {
            const popDenorm = this.retrieveDenormalizationInStates(backEnd.retrieveComposedMembersDenormalizationMap(r),
            [DENORMALIZATION_STATE.DENORMALIZED_NEEDS_POPULATED]);
            if (popDenorm.length === 0) {
              this.setupCachedObservableFromNonComposedStream(r.DocId(), objObs.pipe(map(x => x[composedMemberName])), ceaseObservationSignal,
              backEnd, r);
              const furtherRetrivalNeeded = this.retrieveDenormalizationInStates(backEnd.retrieveComposedMembersDenormalizationMap(r),
                [DENORMALIZATION_STATE.NOT_DENORMALIZED_OBJECT, DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT]);
                backEnd.populatedComposedDenormalizedMembers(r,
                  objObs.pipe(map(x => {
                    return x[composedMemberName] === undefined ? [] : x[composedMemberName].filter(q => q.DocId() === r.DocId());
                  }),
                  filter(q => q.length > 0),
                  ),
                  furtherRetrivalNeeded, ceaseObservationSignal);
            }
        });
        }
      } else {
        if (obj[composedMemberName] && obj[composedMemberName] !== "" )
        {
          const popDenorm = this.retrieveDenormalizationInStates(backEnd.retrieveComposedMembersDenormalizationMap(obj[composedMemberName]),
            [DENORMALIZATION_STATE.DENORMALIZED_NEEDS_POPULATED]);
          if (popDenorm.length === 0) {
            this.setupCachedObservableFromNonComposedStream(obj[composedMemberName].DocId(), objObs.pipe(filter(x => x!==null),
            map(x => [x[composedMemberName]])), ceaseObservationSignal,backEnd, obj[composedMemberName]);
            const furtherRetrivalNeeded = this.retrieveDenormalizationInStates(backEnd.retrieveComposedMembersDenormalizationMap(obj[composedMemberName]),
              [DENORMALIZATION_STATE.NOT_DENORMALIZED_OBJECT, DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT]);
              backEnd.populatedComposedDenormalizedMembers(obj[composedMemberName], objObs.pipe(map(x => x[composedMemberName]),
                filter(x => x!==undefined)), furtherRetrivalNeeded, ceaseObservationSignal);
          }
        }
      }
    });
}


private retrieveDenormalizationInStates(fullDenormalizationMap: Map<string,DENORMALIZATION_STATE>, states: DENORMALIZATION_STATE[]) : string[] {
  return [...fullDenormalizationMap.entries()].filter(x => states.includes(x[1])).map(x => x[0]);
}

/**
 *
 * @param obj
 * @returns Map of composed member names with denormalized status.
 */
  private retrieveComposedMembersDenormalizationMap(obj: T) : Map<string,DENORMALIZATION_STATE> {
    const retVal: Map<string,DENORMALIZATION_STATE> = new Map<string,DENORMALIZATION_STATE>();

  if (obj === undefined || obj === null) {
    return retVal;
  }
  // if composed member is not denormalized it needs fetched.
  this.prototypeObject.retrievefirestoreCompositionMemberNames().forEach(x => {
    if (!this.prototypeObject.retrieveFirestoreDenormalizedMemberNames().includes(x)) {
      retVal.set(x, DENORMALIZATION_STATE.NOT_DENORMALIZED_OBJECT);
    }
  });

  // if composed memeber is denormalized, but contains members which are not set up to be fully denormalized
  this.prototypeObject.retrieveFirestoreDenormalizedMemberNames().forEach(x => {
    if(this.compositionServices.get(x).firestoreBackEnd.prototypeObject.retrievefirestoreCompositionMemberNames().length >
    this.compositionServices.get(x).firestoreBackEnd.prototypeObject.retrieveFirestoreDenormalizedMemberNames().length) {
      retVal.set(x, DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT);
    }
   });

  //if composed member is denormalized but it's denormalization is not yet fully populated.
  this.prototypeObject.retrieveFirestoreDenormalizedMemberNames().forEach(x => {
    if (obj && obj[x] && Array.isArray(obj[x])) {
      if ((obj[x] as Array<any>).length === 0 && (obj[this.compositionServices.get(x).associatedDocumentId] as Array<any>).length > 0) {
        setDenormalizedNeedsPopulatedIfNotPresent(x);
      } else {
        // if there is a mismatch between the document ids, and the the denormalized population.
        const documenIds = obj[this.compositionServices.get(x).associatedDocumentId];
        const documentIdsFromObject = obj[x].map(y => y.DocId());
        // const documentIds
        if (!documentIdsFromObject.every(q => documenIds.includes(q)) ||
            !documenIds.every(q => documentIdsFromObject.includes(q))) {
              setDenormalizedNeedsPopulatedIfNotPresent(x);
        }
        let latestUpdateInArray = new Date(2000,0,1);
        for (const y of obj[x]) {
          if (this.compositionServices.get(x).firestoreBackEnd.retrieveDenormalizationInStates(
            this.compositionServices.get(x).firestoreBackEnd.retrieveComposedMembersDenormalizationMap(y),
            [DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT]).length > 0) {
              retVal.set(x, DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT);
              break;
            }
          if (this.compositionServices.get(x).firestoreBackEnd.denormalizedDataNeedsAdded(y).length > 0) {
            console.warn("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
            setDenormalizedNeedsPopulatedIfNotPresent(x);
            break;
          }
          if (y?.lastUpdatedAt && y.lastUpdatedAt.getTime() > latestUpdateInArray.getTime()) {
            latestUpdateInArray = y.lastUpdatedAt;
          }
        };
        let latestDenormalizedUpdateInArray = new Date(2000,0,1);
        if (obj[this.retrieveDenormalizedName(x)]) {
            for (const y of obj[this.retrieveDenormalizedName(x)]) {
            if (y && y.lastUpdatedAt && y.lastUpdatedAt.getTime() > latestDenormalizedUpdateInArray.getTime()) {
              latestDenormalizedUpdateInArray = y.lastUpdatedAt;
            }
          }
        }
        if (latestDenormalizedUpdateInArray.getTime() > latestUpdateInArray.getTime()) {
          setDenormalizedContainsUpdateIfNotPresent(x);
        }

        setDenormalizedFullyIfNotPresent(x);
      }
    } else {
      if ( (obj[x] === undefined || obj[x] === null) && obj[this.compositionServices.get(x).associatedDocumentId] && obj[this.compositionServices.get(x).associatedDocumentId] !== "" )
      {
        setDenormalizedNeedsPopulatedIfNotPresent(x);
      } else if(obj[x] && this.compositionServices.get(x).firestoreBackEnd.denormalizedDataNeedsAdded(obj[x]).length > 0) {
        console.warn("CCCCCCCCCCCCCCCCCCCCCCCCCCCC");
        setDenormalizedNeedsPopulatedIfNotPresent(x);
      }
      //if composed member is denormalized, but it points to a different object then associated document id.
      if (obj[x] && obj[x].DocId() !== obj[this.compositionServices.get(x).associatedDocumentId]) {
        setDenormalizedNeedsPopulatedIfNotPresent(x);
      }
      if (this.compositionServices.get(x).firestoreBackEnd.retrieveDenormalizationInStates(
        this.compositionServices.get(x).firestoreBackEnd.retrieveComposedMembersDenormalizationMap(obj[x]),
        [DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT]).length > 0) {
        retVal.set(x, DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT);
      }
        if (obj[this.retrieveDenormalizedName(x)]?.lastUpdatedAt && obj[x]?.lastUpdatedAt &&
        obj[this.retrieveDenormalizedName(x)].lastUpdatedAt.getTime() > obj[x].lastUpdatedAt.getTime()) {
          setDenormalizedContainsUpdateIfNotPresent(x);
      }
      setDenormalizedFullyIfNotPresent(x);
    }
   });
   return retVal;

    function setDenormalizedNeedsPopulatedIfNotPresent(x: string) {
      if (retVal.get(x) === undefined) {
        retVal.set(x, DENORMALIZATION_STATE.DENORMALIZED_NEEDS_POPULATED);
      }
    }

    function setDenormalizedContainsUpdateIfNotPresent(x: string) {
      if (retVal.get(x) === undefined) {
        retVal.set(x, DENORMALIZATION_STATE.DENORMALIZED_MORE_RECENT);
      }
    }

    function setDenormalizedFullyIfNotPresent(x: string) {
      if (retVal.get(x) === undefined) {
        retVal.set(x, DENORMALIZATION_STATE.DENORMALIZED_FULLY);
      }
    }
  }

  private retrieveFreshObservableFromFirestore(docId: string, initialValue?: T, errorCount: number = 0): Observable<T> {

    if (docId === null || docId === undefined || !(typeof docId === 'string')) {
      const docIdType = docId === undefined ? "undefined" : docId === null ? 'null' : typeof docId;
      FirestoreBackend.loggingService.addLog(`docId is ${docIdType} in retrieveFreshObservableFromFirestore`, 'retrieve-from-firestore.ts',{basePath: this.basePath,catagory:"firestore invalid operation"});
      return;
    }

    let guid = null;

    const ceaseObservationSignalForCompositions = Math.random().toString(36).substring(0, 10);
    let latestEmissionOriginatedFromFirestore: boolean = false;
    let populatedDenormalized: boolean = false;

      if (! this.activelyObservedDocIds.has(docId)) {
      FirestoreBackend.numberObservers++;
      this.setupCachedObservable(docId,this);
      if (this.authService.logExtendednapshotInformationDocumentIds) {
        console.log(this.basePath, docId, FirestoreBackend.numberObservers);
        if (!FirestoreBackend.activeFillz.has(`${this.basePath} - ${docId}`) ) {
          FirestoreBackend.activeFillz.set(`${this.basePath} - ${docId}`, 1);
        }
      }
    }

    const rawSource= FirestoreBackend.accessFirestoreInnodbToggled$.pipe(filter(x => x===true)).pipe(
      tap(() => FirestoreBackend.platformService.outstandingSubscriptions++),
      tap(() => {
        if (guid !== null) {
          FirestoreBackend.worker.postMessage({
            operation: 'unsubscribe',
            signalGuid: guid,
          });
        }
        guid = Math.random().toString(36).substring(0, 14);
        setTimeout( () =>
        {
        // console.warn('Querying Firestore for doc.', this.basePathRead(), docId, this.basePath);
        FirestoreBackend.worker.postMessage({
          operation: 'queryDoc',
          signalGuid: guid,
          collection: this.basePathRead(),
          docId: docId,
        })}, FirestoreBackend.firestoreBackendMessageDelayMs);
      }),
      switchMap((x) => FirestoreBackend.firestoreBackendMessage$.pipe(
          filter((y) => y.signalGuid === guid),
          map(x => {
            return {doc: x['doc'], metadata: x['metaData'], exists: x['exists']};
          }),
          takeUntil(FirestoreBackend.accessFirestoreInnodbToggled$.pipe(
              filter((x) => x === false)
          )),
          finalize(() => {
            FirestoreBackend.platformService.outstandingSubscriptions--;
            FirestoreBackend.worker.postMessage({
              operation: 'unsubscribe',
              signalGuid: guid,
            });
          }),
        )
      ),
    );

      const firestoreSource$ = rawSource.pipe(
      debounce(() => FirestoreBackend.debounceAll$.pipe(filter(x => x===false))),
      debounce(() => this.debounceLoad$.get(docId) ?  this.debounceLoad$.get(docId).pipe(filter(x => x===false)) : of(false)  ),
      filter(x => x!==undefined && x.metadata !== undefined),
      tap(x => {
        if (!x.metadata.fromCache) {
          FirestoreBackend._dateLastServerUpdate = new Date();
          FirestoreBackend._dateLastServerUpdate$.next(FirestoreBackend._dateLastServerUpdate);
        }
      }),
      filter(x => !(!x.exists && initialValue)),
      // Do not let values through if they are from cache and are undefined.
      filter((x) => {
        if (x.metadata === undefined || x.metadata.fromCache === undefined) {
          FirestoreBackend.loggingService.addLog('Metadata from firestore is undefined.', 'retrieve-from-firestore.ts',
            {method: 'retrieveFreshObservableFromFirestore',
              docId: docId,
              basePath: this.basePath,
              loc: "second",
               val: x});
        }
        return !(x.metadata.fromCache && x.doc === undefined)
      }),
      tap((x) => {
        if (x.doc === undefined) {
          if (!FirestoreBackend.deadDocIds.includes(docId)) {
            FirestoreBackend.deadDocIds.push(docId);
          }
          console.error('From Server, no data.', x, this.basePathRead(), docId, initialValue);
          console.warn(this.basePath);
        }
      }),
      map((x) => {
        return { val: x.doc, json: JSON.stringify(x.doc) };
      }),
      distinctUntilChanged((prev,curr) => {
        return curr.json === prev.json;
      }),
      map(x => x.val),
      takeUntil(FirestoreBackend.destroyingComponent$),
      catchError(err => {
      console.log('Error on value changes for firestore.', err);
      return throwError(err);
      }));

    const initValue = of(initialValue).pipe(
      filter(x => x !== undefined && x !== null),
      map(x => {
        return {value: x, originatedFromFirestore: false}
      }),
      // delay is in order to prevent emission b/f shared observers have both subscribed.
      delay(1),
    );

    const firestoreWithValue$ = firestoreSource$.pipe(
      filter(x=> x!==null),
    map(x => this.retrieveInstantiatedObjectFromFirestore(x as object, true)),
    map(x => {
      return {value: x, originatedFromFirestore: true}
    }),
    takeUntil(FirestoreBackend.unsubscriber.pipe(
      filter(x=> x === docId),
      )),
      finalize(() => {
        if (this.authService.logExtendednapshotInformationDocumentIds) {
            console.log(`Removing:  ${this.basePath} - ${docId}`);
            if (FirestoreBackend.activeFillz.has(`${this.basePath} - ${docId}`) ) {
              FirestoreBackend.activeFillz.delete(`${this.basePath} - ${docId}`);
          }
        }
        this.activelyObservedDocIds.delete(docId);
        FirestoreBackend.numberObservers--;
        setTimeout(() => FirestoreBackend.unsubscriber.next(ceaseObservationSignalForCompositions), FirestoreBackend.unsubscriptionTimer);
      }),
    );

    // This is needed (compared to nexting the observable of values w/ the local reference) because when composite objects are present,
    // they may cause updates when they are no longer part of the
    // composor object (because of timing of disparate observations from firestore being arbitrary by nature)
    const locallySetValue$ = this.locallySetValueToObserve$.pipe(
      filter(x => x.DocId() === docId),
      // tap(x => console.log(`${docId}   SET FROM LOCALLY SET VALUE.`)),
      map(x => {
        return {value: x, originatedFromFirestore: false}
      }),
    );

    const sourceSubject = new ReplaySubject<{value: T, originatedFromFirestore: boolean, compositionMembersRequringFurtherRetrival: string[]}>(1);

    const sourceValue = merge(initValue,firestoreWithValue$,locallySetValue$).pipe(

    // const sourceValue = merge(initValue.pipe(tap(x => console.log(x,`init`))),
    // firestoreWithValue$.pipe(tap(x => console.log(x,`fire`))),
    // locallySetValue$.pipe(tap(x => console.log(x,`local`)))).pipe(
      map(x => {
        return {val: x, lastUpdatedAt: new Date(x.value.lastUpdatedAt)}
      }),
      // tap(x => console.log(x,` string`)),
      distinctUntilChanged((prev,curr) => {
        return (curr.lastUpdatedAt.getTime() < prev.lastUpdatedAt.getTime() || curr.val.value.createdObject !== prev.val.value.createdObject);
       }),
       tap(x => latestEmissionOriginatedFromFirestore = x.val.originatedFromFirestore),
       map(x => {
        x.val.value.createdObject = false;
        x.val.value.updateRequired = false;
        x.val.value.updatePrincipalRequired=false;
        x.val.value.updatedThisCycle = false;
        return x.val;
      }),
       map(x => {
        const retVal = {value: x.value, originatedFromFirestore: x.originatedFromFirestore,
          compositionMembersRequringFurtherRetrival: x.originatedFromFirestore ?
          this.retrieveDenormalizationInStates(this.retrieveComposedMembersDenormalizationMap(x.value),
              [DENORMALIZATION_STATE.NOT_DENORMALIZED_OBJECT, DENORMALIZATION_STATE.DENORMALIZED_COMPOSED_MEMBERS_NOT]): [] };
        return retVal;
       }),
       tap(x => sourceSubject.next(x)),
      share()
    );

    const needComposition = sourceValue.pipe(
      filter(x => x.originatedFromFirestore === true && x.value.retrievefirestoreCompositionMemberNames().length > 0),
      tap(x => {
        if (!populatedDenormalized) {
          populatedDenormalized = true;
          this.populatedComposedDenormalizedMembers(x.value, sourceSubject.pipe(filter(z => z.originatedFromFirestore === true),map(s => s.value),
            filter(s => s!==undefined)), x.compositionMembersRequringFurtherRetrival, ceaseObservationSignalForCompositions);
        }
       }),
      map(x => x.value),
       map(x => {
    //if (this.prototypeObject.retrievefirestoreCompositionMemberNames().length - this.prototypeObject.retrieveFirestoreDenormalizedMemberNames().length  > 0) {
        const aggregateObs$: Observable<({composorDocumentMemberName: string,
                                          aggregateProperty: RetrieveFirestoreProperties | RetrieveFirestoreProperties[],
                                          lastUpdated: Date})>[] = [];

        const denormalizationNeedsAdded = this.retrieveDenormalizationInStates(this.retrieveComposedMembersDenormalizationMap(x),
        [DENORMALIZATION_STATE.DENORMALIZED_NEEDS_POPULATED, DENORMALIZATION_STATE.DENORMALIZED_MORE_RECENT]);

        this.prototypeObject.retrievefirestoreCompositionMemberNames().forEach(compositionObjName => {
            if (!this.compositionServices.has(compositionObjName)) {
              throw new Error(`${compositionObjName} has no associated FirestoreBackend provided in Map.`);
            }
            const composorDocumentMemberDocIdName: string  = this.compositionServices.get(compositionObjName).associatedDocumentId;
            const associatedBackend = this.compositionServices.get(compositionObjName).firestoreBackEnd;

            if (Array.isArray(x[composorDocumentMemberDocIdName])) {
              const arrayComposition$ = sourceSubject.pipe(
                filter(z => z.originatedFromFirestore === true),
                map(x => {
                  return { originatedFromFirestore: x.originatedFromFirestore, composed: x.value, mapped: x.value[composorDocumentMemberDocIdName].slice()}
                }),
                distinctUntilChanged((prev,curr) => {
                  const prevSet = new Set(prev.mapped );
                  const curSet = new Set(curr.mapped );
                  if (prevSet.size !== curSet.size) {
                    return false;
                  }
                  for (var p of prevSet) {
                    if (!curSet.has(p)) {
                      return false;
                    }
                  }
                  if (curr.originatedFromFirestore && curr.composed.lastUpdatedByGuid !== this.authService.guid ) {
                    for (var c of curSet) {
                      if (!this.activelyObservedDocIds.has(c as string)) {
                        return false;
                      }
                    }
                  }
                  return true;
                }),
                map(x => x.composed),
                catchError(err => {
                  console.log('Error writing update to firestore.', err);
                  return throwError(err);
                }),
                switchMap(source => associatedBackend.readArrayFromFirestore(source[composorDocumentMemberDocIdName]).pipe(
                  map( x => ({composorDocumentMemberName: compositionObjName, aggregateProperty: x, lastUpdated : x.length === 0 ? new Date(0) :
                      x.map(y => y === undefined || y.lastUpdatedAt === null ? new Date(0) : y.lastUpdatedAt).sort((a,b) => b.getTime() - a.getTime())[0]})),
                  )),
              );
              aggregateObs$.push(arrayComposition$);
            } else {
              const singleComposition$ = sourceSubject.pipe(
                filter(z => z.originatedFromFirestore === true),
                  tap(source => {
                    if (source.value[composorDocumentMemberDocIdName] !== undefined) {
                      this.incrementSubscribers(source.value[composorDocumentMemberDocIdName])
                    }}),
                  switchMap(source => associatedBackend.readFromCacheOrFirestore(source.value[composorDocumentMemberDocIdName], undefined,
                    source.originatedFromFirestore && source.value.lastUpdatedByGuid !== this.authService.guid ).pipe(
                  map( x => {
                    return {composorDocumentMemberName: compositionObjName, aggregateProperty: x, composorDocumentMemberDocIdName, lastUpdated : x === undefined || x === null ? new Date(0) : x.lastUpdatedAt}
                  }),
                  finalize(() => source.value[composorDocumentMemberDocIdName] !== undefined ? this.unsubscribeOnFinalize(source.value[composorDocumentMemberDocIdName]) : null),
                  ))) as Observable<({composorDocumentMemberName: string,
                                    aggregateProperty: RetrieveFirestoreProperties | RetrieveFirestoreProperties[],
                                    composorDocumentMemberDocIdName: string, lastUpdated: Date})>;
              aggregateObs$.push(singleComposition$);
            }
          });

        return {aggregate: aggregateObs$, source: sourceValue, denormalizationNeedsAdded, denormilizationSignal: Math.random().toString(36).substring(0, 13)};
      }),
      switchMap(x => combineLatest([sourceSubject.pipe(map(x => x.value)), ...x.aggregate]).pipe(
        filter(y => latestEmissionOriginatedFromFirestore === true),
        map(z => {
        return {val: z, denormalizationNeedsAdded: x.denormalizationNeedsAdded, denormilizationSignal: x.denormilizationSignal};
      })) ),
          debounce(() => this.debounceLoad$.get(docId) ?  this.debounceLoad$.get(docId).pipe(filter(x => x===false)) : of(false)  ),
          map(full => {
          const x = full.val as Array<any>;
          const retVal: T = x[0];
          for (let i = 1; i <= x.length - 1; i++) {
            if (Array.isArray(x[i].aggregateProperty)) {
              const oldArray = (retVal[x[i].composorDocumentMemberDocIdName] as Array<RetrieveFirestoreProperties>);
              const newArray = (x[i].aggregateProperty as Array<RetrieveFirestoreProperties>);
              if (oldArray && newArray && (oldArray.length !== newArray.length || !oldArray.every(value => newArray.indexOf(value) === -1))) {
                /*
                THIS SHOULD BE REMOVED IF WE DO NOT SEE IT, AND I DO NOT THINK WE WILL.
                CERTAINLY I CAN NOT TRIGGER IT EASILY.
                BUT WE WILL LEAVE IT IN FOR MOMENT.
                */
                console.error("ERRENT SAUCE  ERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCEERRENT SAUCE");
              }
            }
            if (!Array.isArray(x[i].aggregateProperty) && x[i].aggregateProperty!== null && x[i].aggregateProperty.DocId() && retVal[x[i].composorDocumentMemberDocIdName] !== x[i].aggregateProperty.DocId()) {
              FirestoreBackend.retrievingDocId$.next(x[i].aggregateProperty.DocId());
              this.retrieveFreshObservableFromFirestore(x[i].aggregateProperty.DocId(), retVal);
            } else {
              retVal[x[i].composorDocumentMemberName] = x[i].aggregateProperty;
            }
          }
          return {val: retVal, denormalizationNeedsAdded: full.denormalizationNeedsAdded, denormilizationSignal: full.denormilizationSignal};
          })
        ) as Observable<{val: T, denormalizationNeedsAdded: string[], denormilizationSignal: string}>;

        const noComposition = sourceValue.pipe(
          // filter(x => x.compositionMembersRequringFurtherRetrival.length === 0),
          filter(x => x.originatedFromFirestore === false || x.value.retrievefirestoreCompositionMemberNames().length === 0),
          tap(x => {
            if (!populatedDenormalized) {
              populatedDenormalized = true;
            this.populatedComposedDenormalizedMembers(x.value, sourceSubject.pipe(map(s => s.value), filter(s=>s!==undefined)), x.compositionMembersRequringFurtherRetrival, ceaseObservationSignalForCompositions);
            }
           }),
          map(x => {
            return {val:x.value, denormalizationNeedsAdded: [], denormilizationSignal: ""};
          })
        );

        // const fireStoreWithValueAndCompositionObjectsIfApplicible$ = needComposition.pipe(
    const fireStoreWithValueAndCompositionObjectsIfApplicible$ = merge(noComposition,needComposition).pipe(
      map(x => {
        return {val: x, json: JSON.stringify(x.val)};
      }),
      distinctUntilChanged((prev,curr) => {
        return curr.json === prev.json;
        //below is useful for understanding why value is being emitted, but is similar in speed
        //and opens us up to situations where we expect a locally set value w/ temporal concerns
        //to be propagated, but it isn't b/c it's diff is ignored from firestore perspective.
        // const g = new GenerateFirestoreDeltas();
        // const r = g.calculateDeltas(prev.val.val,curr.val.val);
        // const retVal = r.length === 0;
        // if (!retVal) {

        //   console.log(this.basePath, docId);
        //   console.log(r);
        // }
        // return retVal;
      }),
      map(x => x.val),
      map(full => {
        const retVal = full.val;
        let denormalizedToAdd = full.denormalizationNeedsAdded;
        return {retVal, denormalizedToAdd};
      }),
      share()
    );

      const addDenorm = fireStoreWithValueAndCompositionObjectsIfApplicible$.pipe(
        filter(x => x.denormalizedToAdd.length > 0 && x.retVal.DocId() !== undefined && x.retVal.DocId() !== null && x.retVal.DocId() !== ""),
        tap(x => this.populateMissingDenormalizedData(x.retVal, x.denormalizedToAdd)),
        map(x=> {
          const denormalizationNotPopulated = this.retrieveDenormalizationInStates(this.retrieveComposedMembersDenormalizationMap(x.retVal),
              [DENORMALIZATION_STATE.DENORMALIZED_NEEDS_POPULATED]);
            console.log("Frog", denormalizationNotPopulated, this.basePathWrite(),docId, x.denormalizedToAdd);
          return {retVal: x.retVal, denormalizedToAdd: x.denormalizedToAdd.filter(d => denormalizationNotPopulated.indexOf(d) === -1)};
        }),
        map(x => {
          return {retVal: x.retVal, denormalizedToAdd: x.denormalizedToAdd};
        }),
        switchMap(x => this.mergeUpdate(x.retVal, x.denormalizedToAdd.map(d => this.retrieveDenormalizedName(d)), undefined, false).pipe(
          map(() => x),
          take(1)
        )),
      );

      const noDenorm = fireStoreWithValueAndCompositionObjectsIfApplicible$.pipe(
        filter(x => x.denormalizedToAdd.length === 0),
      );

      merge(addDenorm,noDenorm).pipe(
        map(x => x.retVal),
        tap(val => this.cachedObservables.get(docId).next(val)),
        takeUntil(FirestoreBackend.unsubscriber.pipe(
          filter(x=> x === docId))),
        catchError(err => {
          if (errorCount < 3) {
            FirestoreBackend.loggingService.addLog(err.message,"retrieve-from-firestore.ts" , {method: "retrieveFreshObservableFromFirestore", docId: docId, errorCount: errorCount});
            return this.retrieveFreshObservableFromFirestore(docId,initialValue, errorCount + 1);
          } else {
            return throwError(err)
          }
        })
        // finalize(() => {
        //   console.warn(`FINALIZE from retrieveFreshObs ${docId}`);
        //   this.cachedObservables.get(docId).complete();
        //   this.cachedObservables.delete(docId);
        // })
      ).subscribe();

      return this.cachedObservables.get(docId).pipe(filter(x => x !== undefined));
  }

  public getValueIfCached (docId: string) : T {
    return this.cachedValues.get(docId);
  }

  public getCloneOfCachedValue(docId: string) : T {
    const source = this._PurecachedValues.get(docId);
    let retVal = this.RetrieveInstantiatedFirestoreObjectFromJson(
      (source as object));
    retVal = (this.cloneDateValuesWhenNeeded(retVal as object) as T);

      this.prototypeObject.retrievefirestoreCompositionMemberNames().forEach(compositionObjName => {
        if (!this.compositionServices.has(compositionObjName)) {
          throw new Error(`${compositionObjName} has no associated FirestoreBackend provided in Map.`);
        }
        const composorDocumentMemberDocIdName: string  = this.compositionServices.get(compositionObjName).associatedDocumentId;
        const associatedBackend = this.compositionServices.get(compositionObjName).firestoreBackEnd;
        if (Array.isArray(retVal[composorDocumentMemberDocIdName])) {
          const clonedArray = [];
          (retVal[composorDocumentMemberDocIdName] as Array<string>).forEach(val => {
            clonedArray.push(associatedBackend.getCloneOfCachedValue(val));
          });
          retVal[compositionObjName] = clonedArray;
        } else {
          if (retVal[composorDocumentMemberDocIdName]) {
            retVal[compositionObjName] = associatedBackend.getCloneOfCachedValue(retVal[composorDocumentMemberDocIdName]);
          }
        }
        });
    return retVal;
  }

  public getValueFromPreceedingEmissionIfCached (docId: string) : T {
    return this._PurecachedValues.get(docId);
  }

  public readFromCacheOrFirestore(docId: string, initialValue?: T, forceFresh : boolean = false,
      storeSet: (val: T) => Observable<void>[] = null): Observable<T> {

    if (docId === undefined || docId === null || docId === "fish") {
        return of(null);
    }

    FirestoreBackend.retrievingDocId$.next(docId);

    if ( (!forceFresh ||  this.activelyObservedDocIds.has(docId)) &&
        this.cachedObservables.has(docId)) {
        // && this.cachedValues.get(docId) !== undefined) {
      // When initial value is set, we seed observable with it.
      if (initialValue) {
          // if there is an observer of locallaySetValueToObserve for given document id, we do not need to next the cached observable,
          // but if there is not we do not properly cache the current value as active, and thus believe we haven't yet committed it when
          // composing object is committed again.
          this.cachedObservables.get(docId).next(initialValue);
          console.log("FROM CACHE OR FIRESTORE " + docId);
          this.locallySetValueToObserve$.next(initialValue);
      }
      return this.cachedObservables.get(docId).pipe(filter(x => x !== undefined));
    } else {
      this.counter++;
      if (this.counter > 24 && this.counter % 25 === 0) {
        console.error(`Cache miss count: ${this.counter}   ${this.basePath}  docId: ${docId}`);
      }
      if (storeSet === null) {
        return this.retrieveFreshObservableFromFirestore(docId, initialValue);
      } else {
        const emission = this.retrieveFreshObservableFromFirestore(docId, initialValue).pipe(
          share()
        );

        const valueEmission = emission.pipe(
          filter(value => value !== undefined && value !== null),
          switchMap(x => combineLatest([...storeSet(x)]).pipe(
            map(() => x)
          )));

        const noValueEmission = emission.pipe(
          filter(x => x === null),
          );

          return merge(valueEmission, noValueEmission);
      }
    }
  }

  public mergeUpdate(obj: T,fields: string[],batch?: string,nextLocallySetValue: boolean = false): Observable<T> {
    const guid = Math.random().toString(36).substring(0, 7);

    const responsibleForCommit = this.writeBatchNeeded(batch);
    let batchResolved = of(batch);
    if (responsibleForCommit) {
      batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
        tap(x => batch = x)
      );
    }

    if (fields.length > 0) {
      if (fields.filter(x => this.prototypeObject.retrieveFirestoreDenormalizedMemberNames().map(q => this.retrieveDenormalizedName(q)).findIndex(y => y === x) > -1).length > 0) {
        const populateDenoramlized = fields.filter(x => this.prototypeObject.retrieveFirestoreDenormalizedMemberNames().map(q => this.retrieveDenormalizedName(q)).findIndex(y => y === x) > -1);
        populateDenoramlized.forEach(x => {
          if (obj[x] === undefined || obj[x] === null) {
            this.populateDenormlizedForObject(obj,this.retrieveNormalizedName(x),false);
          }
        });
      }
      const objForMergeUpdate = this.RetrievePrincipleObjectToCommit(obj);
      for(let [key,value] of Object.entries(objForMergeUpdate)) {
        if(!fields.includes(key)) {
          delete objForMergeUpdate[key];
        }
      }

      obj.lastUpdatedAt = new Date();
      obj.bucketId = this.authService.bucketId;
      obj.appVersionUpdate = FirestoreBackend.localSettingsService.appVersion;
      obj.lastUpdatedByGuid = this.authService.guid;
      obj.lastUpdatedByEmployeeDocId = this.authService.activelyLoggedInEmployeeDocId;


      const update$ = batchResolved.pipe(
        tap((x) => {
          if (obj.DocId() === null || obj.DocId() === undefined || !(typeof obj.DocId() === 'string')) {
            const docIdType = obj.DocId() === undefined ? "undefined" : obj.DocId() === null ? 'null' : typeof obj.DocId();
            FirestoreBackend.loggingService.addLog(`Attempt to update ${docIdType} in mergeUpdate`, 'retrieve-from-firestore.ts',{basePath: this.basePath,catagory:"firestore invalid operation"});
            return;
          } else {
            setTimeout(() =>
            FirestoreBackend.worker.postMessage({
              operation: 'update',
              signalGuid: guid,
              collection: this.basePathWrite(),
              docId: obj.DocId(),
              val: objForMergeUpdate,
              batchId: x,
              merge: true,
            }), FirestoreBackend.firestoreBackendMessageDelayMs);
          }
        }),
        switchMap((x) =>
          FirestoreBackend.firestoreBackendMessage$.pipe(
            filter((y) => y.signalGuid === guid),
            take(1)
          )
        ),
        map(() => {
          obj.updatePrincipalRequired = false;
          obj.updateRequired = false;
          return obj;
        }),
        tap(() => {
          if (nextLocallySetValue) {
            console.log('FROM MERGE UPDATE ' + obj.DocId());
            this.locallySetValueToObserve$.next(obj);
          }
        }),
      );
      if (responsibleForCommit) {
        return update$.pipe(
          switchMap(x => this.commitFirestoreWriteBatch(batch).pipe(map(() => x)))
        );
      } else {
        return update$;
      }
    } else {
      if (responsibleForCommit) {
        return batchResolved.pipe(
          switchMap(b => this.commitFirestoreWriteBatch(b)),
          map(() => {
            obj.updatePrincipalRequired = false;
            obj.updateRequired = false;
            return obj;
          })
        );
      } else {
        return batchResolved.pipe(map(() => {
          obj.updatePrincipalRequired = false;
          obj.updateRequired = false;
          return obj;
        }));
      }
    }
  }
}
