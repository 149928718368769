<div #parentTemplate class="parent-template outline-for-calculated-fields">
<form [formGroup]="formlyForm" #resizeTemplate class="resizingTemplate control-attributes active-control">
  <div *ngIf="(selectedForDerivedField|async)===true; else standard">
    <formly-form x style="width: 100%;" [model]="model" [fields]="fields" [options]="options" [form]="formlyForm"
      matBadgeSize="small"
      matBadge="{{badgeNumber|async}}"
      matBadgeOverlap="true">
    </formly-form>
  </div>
  <ng-template #standard>
    <formly-form x style="width: 100%;" [model]="model" [fields]="fields" [options]="options" [form]="formlyForm"></formly-form>
  </ng-template>
</form>
</div>
