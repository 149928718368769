import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { concat,  merge, Observable, of, Subject, throwError, zip } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap, pairwise, delay, startWith, share, distinctUntilChanged } from 'rxjs/operators';
import { FormFirestoreService } from '../../../../../../../common/src/data/dao-services/form-firestore.service';
import { FormlyUtilityService } from '../formly-utility.service';
import { FetchUpdatedWorkflowService } from '../utilities/fetch-updated-workflow.service';
import { FormFirestoreWorkflowComponentService } from '../../../../../../../common/src/components/form-firestore-workflow/form-firestore-workflow-component.service';

@Component({
  selector: 'app-formly-branching-container',
  template: `
  <!-- <button (click)="test()">Test</button> -->
  <div style="display:none">{{forceRender|async}}</div>
    <h5>{{to.label}}</h5>
    <div style="margin-bottom: 10px;">
    <formly-field class="row" [field]="chooser"></formly-field>
    </div>
  `,
  styleUrls: ['./formly-branching-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyBranchingContainerComponent extends FieldArrayType implements OnDestroy, OnInit {

  destroyingComponent$ = new Subject();
  firestoreSummaryDocIdPatchedToChooser: string = undefined;

  forceRender: Subject<any> = new Subject();

  get keyString() : string {
    return this.key as string;
  }

  ngOnDestroy(): void {

  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(private formlyUtilityService:  FormlyUtilityService, private formFirestoreService: FormFirestoreService,
    private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService, private formFirestoreWorkflowComponentService: FormFirestoreWorkflowComponentService) {
    super();
  }

  get chooser() {
    if (this.field.fieldGroup[0].fieldGroup[0] === undefined) {
      return null;
    } else {
      return this.field.fieldGroup[0].fieldGroup[0];
    }
  }

  get parentId() : string {
    //top level is needed so we match branch key when branch is not within a section otherwise.
    if( this.field.parent) {
      if (this.field.parent.model._id === undefined || this.field.parent.model._id === null) {
        return "top_level";
      } else {
        return this.field.parent.model._id;
      }
    }  else {
      return "top_level";
    }
  }


  initilizeSectionRemovedFromTechView() {

    const sectionRemovedFromSectionsBranch = this.formlyUtilityService.sectionRemovedFromTechView$.pipe(
      filter(x => x.branchingKey === this.keyString && x.parentId === this.field.parent.model._id)
    );

    const sectionRemovedMultiCheckbox = sectionRemovedFromSectionsBranch.pipe(
      filter(() => this.chooser.type === "multicheckbox"),
      map(x => {
        const formSummaryDocId = this.fetchUpdatedWorkflowService.getActiveFormSummaryDocIdFromFormFirestoreDocId(x.workflowDocId, this.formlyUtilityService.activeFormModelFirestore.DocId());
        if (x.priceBookEntryDocId !== null) {
          return `${formSummaryDocId}-${x.priceBookEntryDocId}`;
        } else {
          return formSummaryDocId;
        }
      }),
      tap(x => {
          const checkedValues = {...this.chooser.formControl.value};
          checkedValues[x] = false;
          this.chooser.formControl.patchValue(checkedValues);
          // Required to update the checked state for formly multi-checkbox control.
          this.chooser.props.options= (this.chooser.props.options as Array<any>).slice();
      })
      );

    const sectionRemovedRadioDropDown = sectionRemovedFromSectionsBranch.pipe(
      filter(() => this.chooser.type !== "multicheckbox"),
      tap(x => {
        this.formlyUtilityService.removeSectionFromBranch$.next({ sectionDocId: this.firestoreSummaryDocIdPatchedToChooser.split("-")[0], branchKey: this.keyString, parentId: this.parentId,
          priceBookEntryDocId: x.priceBookEntryDocId});
        this.firestoreSummaryDocIdPatchedToChooser = undefined;
        this.chooser.formControl.patchValue(undefined);
        this.chooser.props.value = undefined;
      }),
    );

    merge(sectionRemovedMultiCheckbox, sectionRemovedRadioDropDown).pipe(
      takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  static convertSelectionToFormSummaryDocIdAndPriceBookEntryDocId(selection: string) : {formSummaryDocId: string, priceBookEntryDocId: string} {

    try {
      const split = selection.split("-");
      if (split.length === 1) {
        return {formSummaryDocId: split[0], priceBookEntryDocId: null};
      } else {
        return {formSummaryDocId: split[0], priceBookEntryDocId: split[1]};
      }
    } catch (e) {
      return undefined;
    }
  }

  ngOnInit(): void {

    this.initilizeSectionRemovedFromTechView();

      const checkBoxValueChangesRaw = merge(of(null),
        this.chooser.formControl.valueChanges.pipe(
          startWith(this.chooser.formControl.value),
          filter(x=> x!==undefined && x!==null),
          // delay is to ensure of (null) hits first on populated values for pairwise to work properly.
          delay(1),
        )
      ).pipe(
        filter(() => this.chooser.type === "multicheckbox"),
        pairwise(),
        // after below transformation, emitted value is docId : true || false || not present (also false)
        map(x => {
          return {old: x[0], new: x[1]};
        }),
        distinctUntilChanged((prev,curr) => {
          return JSON.stringify(prev) === JSON.stringify(curr) || JSON.stringify(prev.new) === JSON.stringify(curr.new);
        }),
        // Build map of values to remove and add.
        map(x => {
          const toAdd = [];
          const toRemove = [];
          for (const docId in x.new) {
            if (x.new[docId] === true && (x.old === null ||  !x.old[docId] )) {
              toAdd.push(docId);
            }
            if(x.new[docId] === false && (x.old && x.old[docId] )) {
              toRemove.push(docId);
            }
          }
          return {toAdd: toAdd, toRemove: toRemove};
        }),
        share()
      );

      const checkBoxAdded = checkBoxValueChangesRaw.pipe(
        map(x => x.toAdd),
        filter(x => x.length > 0),
        // We must sort sections to add, or field tech has bad experience on re-loading of workflow.
        map(x => this.formlyUtilityService.sortAdditionsToCheckboxControl(x, (this.chooser.props.options as Array<any>).map(x => x.value))),
        map(x => {
          const retVal = [];
          x.forEach(val => retVal.push(FormlyBranchingContainerComponent.convertSelectionToFormSummaryDocIdAndPriceBookEntryDocId(val)));
          return retVal;
        }),
        map(x => {
          const retVal = [];
          x.forEach(y => retVal.push(this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(y.formSummaryDocId, this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
            switchMap(q => this.formFirestoreService.load$(q)),
            take(1),
            map(z => {
              return {selection: y, formFirestore: z};
            })
          )));
          return retVal;
        }),
        switchMap(x => zip(...x)),
        map(x => {
          const a =  x.flatMap(x => x)
          .filter(y => this.formlyUtilityService.parentIdBranchKeySectionDocIdToLeafKey.get(this.parentId)?.get(this.keyString)?.get(y['formFirestore'].DocId())?.get(y['selection'].priceBookEntryDocId) === undefined);
          return a;
        }),
        map(x => {
          const AddedSections : Observable<{sectionKey: string, newFormFirestoreDocId: string, oldFirestoreDocId: string}>[] = [];
          x.forEach(selection => {
            AddedSections.push(
                this.formlyUtilityService.addWorkflowFromBranchingUpdateIfNeededReturnSectionKey(selection['formFirestore'].DocId(),
                (this.keyString as string),
                this.parentId,
                selection['formFirestore'],
                this.formlyUtilityService.activeFormModelFirestore.DocId(),
                //We pass in pricebook label if pricebook present, otherwise null.
                (!selection['selection'].priceBookEntryDocId ? null :
                (this.chooser.props.options as Array<{label,value,priceBookEntryDocId}>)
                  .find(x => x.value.split("-")[0] === selection['selection'].formSummaryDocId && x.value.split("-")[1] === selection['selection'].priceBookEntryDocId).label),
                // We pass in pricebook entry docId if present, otherwise null.
                (!selection['selection'].priceBookEntryDocId ? null : selection['selection'].priceBookEntryDocId,
                this.props.formlySectionConfig === undefined ? null :
                  this.props.formlySectionConfig[selection['selection'].formSummaryDocId] === undefined ? null : this.props.formlySectionConfig[selection['selection'].formSummaryDocId]
                )
                ).pipe(
              tap(x => {
              this.formlyUtilityService.addLeafKeyToBranchKeyWithParent$.next({branchKey: this.keyString, leafKey: x.sectionKey,
                docId: x.newFormFirestoreDocId === null ? x.oldFirestoreDocId : x.newFormFirestoreDocId, parentId: this.parentId, priceBookEntryDocId: selection['selection'].priceBookEntryDocId});
              })));
        });
        return AddedSections;
      }),
        switchMap(x => concat(...x))
      );

      const checkBoxRemoved = checkBoxValueChangesRaw.pipe(
        map(x => x.toRemove.filter(b => b!==undefined)),
        filter(x => x.length > 0),
        map(x => {
          const retVal : {formSummaryDocId: string, priceBookEntryDocId: string | null}[] = [];
          x.forEach(val => retVal.push(FormlyBranchingContainerComponent.convertSelectionToFormSummaryDocIdAndPriceBookEntryDocId(val)));
          return retVal;
        }),
        tap(x => {
        console.log(x,` string`);
        }),
        tap(x => {
          x.forEach(val => {
            if (val !== undefined) {
              this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(val.formSummaryDocId, this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
                tap(associatedFormFirestoreDocId =>
                  this.formlyUtilityService.removeSectionFromBranch$.next({ sectionDocId: associatedFormFirestoreDocId, branchKey: this.keyString, parentId: this.parentId, priceBookEntryDocId: val.priceBookEntryDocId})
                ),
                take(1)
              ).subscribe();
            }
          })
        }),
        tap(() => this.forceRender.next(null)),
      );

      const checkBoxValueChanges = merge(checkBoxAdded, checkBoxRemoved);

      const radioDropDownValueChanges = this.chooser.formControl.valueChanges.pipe(
        startWith(this.chooser.formControl.value),
        filter(() => this.chooser.type !== "multicheckbox"),
        distinctUntilChanged(),
        filter(x => x !== undefined),
        map(x => FormlyBranchingContainerComponent.convertSelectionToFormSummaryDocIdAndPriceBookEntryDocId(x)),
        switchMap(x => this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(x.formSummaryDocId, this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
          map(active => {
            return {activeFormFirestoreDocId: active, selection: x};
          })
        )),
        switchMap(x =>this.formFirestoreService.load$(x.activeFormFirestoreDocId).pipe(
          map(formFirestore => {
            return {formFirestore: formFirestore, selection: x.selection};
          }),
          take(1)
        )),
        map(y => {
          const alreadyPresent = this.formlyUtilityService.parentIdBranchKeySectionDocIdToLeafKey.get(this.parentId)?.get(this.keyString)?.get(y['formFirestore'].DocId())?.get(y['selection'].priceBookEntryDocId) !== undefined;
          if (alreadyPresent) {
            this.firestoreSummaryDocIdPatchedToChooser = y.selection.priceBookEntryDocId !== null ? y.formFirestore.DocId()+`-${y.selection.priceBookEntryDocId}` : y.formFirestore.DocId();
          }
          return alreadyPresent ? null : y;
        }),
        filter(x => x !== null),
        delay(1),
        switchMap( u => this.formlyUtilityService.addWorkflowFromBranchingUpdateIfNeededReturnSectionKey(
        u.formFirestore.DocId(),
        (this.keyString as string),
        this.parentId,
        u.formFirestore,
        this.formlyUtilityService.activeFormModelFirestore.DocId(),
        // pass in pricebook label if present, elsewise null.
        u.selection.priceBookEntryDocId === undefined || u.selection.priceBookEntryDocId === null ? null :
        (this.chooser.props.options as Array<{label,value,priceBookEntryDocId}>)
                  .find(x => x.value.split("-")[0] === u.selection.formSummaryDocId && x.value.split("-")[1] === u.selection.priceBookEntryDocId).label,
        u.selection.priceBookEntryDocId,
        this.props.formlySectionConfig === undefined ? null :
          this.props.formlySectionConfig[u.selection.formSummaryDocId] === undefined ? null : this.props.formlySectionConfig[u.selection.formSummaryDocId]
      )),
      tap(x => {
        // For radio buttons and other selection modes which only allow a single value, removal is called whenever there is a value present (
        // and is distinct from existing value)
            if (this.firestoreSummaryDocIdPatchedToChooser !== undefined) {
              console.warn(`removing ${this.firestoreSummaryDocIdPatchedToChooser} from ${this.keyString}`);
                  this.formlyUtilityService.removeSectionFromBranch$.next({ sectionDocId: this.firestoreSummaryDocIdPatchedToChooser.split('-')[0], branchKey: this.keyString, parentId: this.parentId, priceBookEntryDocId:
                    this.firestoreSummaryDocIdPatchedToChooser.split('-')[1] === undefined ? null : this.firestoreSummaryDocIdPatchedToChooser.split('-')[1]})
            }
      }),
      tap( x => {
        this.formlyUtilityService.addLeafKeyToBranchKeyWithParent$.next({branchKey: this.keyString, leafKey: x.sectionKey,
          docId: x.newFormFirestoreDocId === null ? x.oldFirestoreDocId : x.newFormFirestoreDocId, parentId: this.parentId, priceBookEntryDocId: x.priceBookEntryDocId});
      }),

      tap(x => {
        this.firestoreSummaryDocIdPatchedToChooser = x.newFormFirestoreDocId !== null ?
          x.priceBookEntryDocId !== null ? x.newFormFirestoreDocId+`-${x.priceBookEntryDocId}` : x.newFormFirestoreDocId :
          x.priceBookEntryDocId !== null ? x.oldFirestoreDocId+`-${x.priceBookEntryDocId}` : x.oldFirestoreDocId;
      }),

    );

      const blankValue = this.chooser.formControl.valueChanges.pipe(
        startWith(this.chooser.formControl.value),
        filter(x => x === undefined));

      const emptyCheckbox = this.chooser.formControl.valueChanges.pipe(
        startWith(this.chooser.formControl.value),
        filter(() => this.chooser.type === "multicheckbox"),
        filter(x=> x!== undefined),
        filter(x => Object.values(x).filter(x => x === true).length === 0)
      );

      merge(checkBoxValueChanges, radioDropDownValueChanges,blankValue,emptyCheckbox).pipe(
        // delay here is needed for updated content to have been added to DOM b/f rendering.
        delay(1),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
  }

}
