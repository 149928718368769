import { Injectable } from '@angular/core'
import { DatabaseStoreService } from '../../database-backend/database-store.service'
import { AuthenticationService } from '../../../util/authentication.service'
import { StateChangeStoreService } from '../../database-backend/state-change-store.service'
import { FirestoreDiffService } from '../firestore-diff.service'
import { FirestoreBackend } from '../../database-backend/retrieve-from-firestore'
import { PriceBookUnitOfMeasure } from '../../dao/pricebook/pricebook-unit-of-measure'
import { FormFirestoreSummaryService } from '../form-firestore-summary.service'

@Injectable({
  providedIn: 'root'
})
export class PriceBookUnitOfMeasureService extends DatabaseStoreService<PriceBookUnitOfMeasure> {

  constructor( fs: PriceBookUnitOfMeasureFirestoreService, store: PriceBookUnitOfMeasureStoreService, authenticationService: AuthenticationService,
    private formFirestoreSummaryService: FormFirestoreSummaryService) {
      super(fs, store, authenticationService,new Map([
        ["formFirestoreSummary", {associatedDocumentId: "formFirestoreSummaryDocId", compositionStoreService: formFirestoreSummaryService}],
      ]));
  }

  populateGarretyUnitsOfMeasure() {
    this.create$(new PriceBookUnitOfMeasure({title: "Square Feet", code: "SQFT", importLastUpdated: new Date()})).subscribe();
    this.create$(new PriceBookUnitOfMeasure({title: "Linear Feet", code: "LIN FT", importLastUpdated: new Date()})).subscribe();
    this.create$(new PriceBookUnitOfMeasure({title: "Each", code: "EA", importLastUpdated: new Date()})).subscribe();
    this.create$(new PriceBookUnitOfMeasure({title: "Hour", code: "HR", importLastUpdated: new Date()})).subscribe();
    this.create$(new PriceBookUnitOfMeasure({title: "Day", code: "DAY", importLastUpdated: new Date()})).subscribe();
  }

}

@Injectable({
  providedIn: 'root'
})
export class PriceBookUnitOfMeasureStoreService extends StateChangeStoreService<PriceBookUnitOfMeasure> {
  protected store = "PriceBookUnitOfMeasure-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, PriceBookUnitOfMeasure>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class PriceBookUnitOfMeasureFirestoreService extends FirestoreBackend<PriceBookUnitOfMeasure> {

 protected basePath = "PriceBookUnitOfMeasure";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): PriceBookUnitOfMeasure {
return new PriceBookUnitOfMeasure(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new PriceBookUnitOfMeasure(), authService);
 }
}
