<!-- Information on communication which launched modal. -->
<div class="communication-modal-layout">
  <div class="header-style header-label" style="width: 100%; padding-left: 25px;">{{generalSettingsForm.get('title').value}}</div>
  <div class="big-container">
  <div class="label-style" style="text-align: left; margin-top: 40px;">{{generalSettingsForm.get('description').value}}</div>

<!-- Message timing preferences -->
  <div [formGroup]= "schedulingForm" style="display: contents;">
  <div *ngIf="schedulingForm.get('initialCommunicationTimingType').value==='Preceed Hours' else postceedBlock">
    <div style="display: flex; align-items: center;">
    <div style="font-family: roboto;">Send communication to customer: </div>
    <app-time-field
          [value]= preceedHoursFromTriggeringToInitiallySending
          [fieldLabel]= "'Hours send before'"
          [readOnly]="false"
          [granularity]="30"
          [timeValueList]= "timeListForBefore"
          (valueChange) = "patchInHoursDelayToSendCommunicaiton($event)"
          [minDate]="zeroHoursAsDate"
          style="margin: 0px 10px;"
    ></app-time-field>
    <div style="font-family: roboto;">Hours Before Appointment</div>
    </div>
  </div>
  <ng-template #postceedBlock style="display: contents;">
    <div>
    <mat-radio-group class="example-radio-group communication-grid-layout-2" formControlName="initialCommunicationTimingType"> <!--removing the "-2" from communication grid layout will create a different aesthetic for the radio buttons (which I don't like for this part but kept it just in case)-->

      <!--Radio Button 1-->

      <div class="border-style center-radios">
        <mat-radio-button class="example-radio-button" value="Immediately">Immediately</mat-radio-button>
      </div>

      <!--Radio Button 2 (After X Hours) -->

      <div class="border-style center-radios">
      <mat-radio-button class="example-radio-button" value="Delay Hours">After Delay Of:</mat-radio-button>
      <app-time-field
          [value]= delayHoursFromTriggeringToInitiallySending
          [fieldLabel]= "'Hours to wait to send first communication'"
          [readOnly]="false"
          [granularity]="60"
          (valueChange) = "patchInHoursDelayToSendCommunicaiton($event)"
          [minDate]="zeroHoursAsDate"
      ></app-time-field>
      <label style="margin-left: 10px;">Hours</label>
      </div>

      <!--Radio Button 3 (After X Days Send At Y Time) -->

      <div class="center-radios">
    <mat-radio-button class="example-radio-button" value="Delay Days" ></mat-radio-button>
    <div class="number-input">
      <mat-form-field appearance="outline" style="width: 60px; padding: 0 20px; top: 1px;">
    <input matInput type="number" placeholder="Days" min=0 formControlName="delayDaysToSendInitialMessage"
    [name]="ElementNameForId('280826')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    </mat-form-field>
    </div>
    <label>days in future</label>
    <label style="margin: 0px 10px;">Send at:</label>
      <app-time-field
          [value]= timeOfDayToSendCommunication
          [fieldLabel]= "'Send message at:'"
          [readOnly]="false"
          [granularity]="30"
          (valueChange) = "patchInTimeOfDayToSendCommunication($event)"
          [minDate]="zeroHoursAsDate"
      ></app-time-field>
      </div>
    </mat-radio-group>
    </div>

    <!-- Work Performed communication allows SP to (optionally) include estimate and invoice in output workflow.  -->
    <ng-container *ngIf="customerCommuncationTemplate.considerInline">
      <div [formGroup]= "generalSettingsForm" style="border: 1px solid lightgray; border-radius: 4px;">
        <div class="follow-up-text" style="width: 180px; grid-column: 1/1; border: none;">
          <mat-checkbox style="margin: 5px;" class="example-checkbox" formControlName="inlineEstimate">Include Estimate</mat-checkbox>
        </div>
        <div class="follow-up-text" style="width: 180px; grid-column: 1/1; border: none;">
          <mat-checkbox style="margin: 5px;" class="example-checkbox" formControlName="inlineInvoice">Include Invoice</mat-checkbox>
        </div>
      </div>
    </ng-container>

    <!-- Some customer communications optionally allow SP to include workflow attachment (and any attachments which are inserted through said workflow) -->
    <ng-container *ngIf="customerCommuncationTemplate.allowJobWorkflowAttachment">
      <div [formGroup]= "generalSettingsForm">
        <div class="follow-up-text" style="width: 180px; grid-column: 1/1; border: none;">
          <mat-checkbox style="margin: 5px;" class="example-checkbox" formControlName="includeJobWorkflowAttachment">Include Job Workflow</mat-checkbox>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="customerCommuncationTemplate.allowFollowUpMessaging">

    <!-- Schedule 0..n follow up messages every X days -->
    <div class="label-style" style="text-align: left; margin-top: 40px;">Send Additional Follow Up Messages</div>
    <div class="communication-grid-layout follow-up-layout">

    <div [formGroup]= "generalSettingsForm">
      <div class="follow-up-text" style="width: 180px; grid-column: 1/1; border: none;">
        <mat-checkbox style="margin: 5px;" class="example-checkbox" formControlName="sendFollowUpMessaging">Follow Up Messages</mat-checkbox>
      </div>
    </div>
    <div *ngIf="generalSettingsForm.get('sendFollowUpMessaging').value" style="display: contents;">

      <!--Follow up Checkbox section #1-->
      <div class="follow-up-text">
        <div>Send follow up messages every:</div>
        <div class="number-input">
          <mat-form-field appearance="outline" style="width: 60px; padding: 0 20px; top: 1px;">
            <input matInput type="number" placeholder="Days" formControlName="delayDaysToSendFollowUpMessage"
            [name]="ElementNameForId('180826')" autofill='off' autocomplete='new-password' data-lpignore='true'>
          </mat-form-field>
        </div>
      <label>Days</label>
      </div>

      <!--Send ever X days section #2-->
      <div class="follow-up-text" style='grid-row: 2/3;'>
      <label>Send follow up message at:</label>
      <app-time-field
          [value]= timeOfDayToSendCommunication
          [fieldLabel]= "'Send follow up message at:'"
          [readOnly]="false"
          [granularity]="30"
          (valueChange) = "patchInTimeOfDayToSendCommunication($event)"
          [minDate]="zeroHoursAsDate"
      ></app-time-field>
      </div>

      <!--Send X follow ups section #3-->
      <div class="follow-up-text" style='grid-row: 3/4; border-bottom: none;'>
        <label>Send this many follow up messages at most:</label>
        <div class="number-input">
          <mat-form-field appearance="outline" style="width: 60px; padding: 0 20px; top: 1px;">
            <input matInput type="number" placeholder="Number" formControlName="numberOfAdditionalMessagesToSend">
            <mat-error>Maximum of {{customerCommuncationTemplate.maximumAllowedFollowUpMessages}} messages possible for this communication type.</mat-error>
          </mat-form-field>
        </div>
      </div>

      </div>
    </div>
  </ng-container>

  </ng-template>
  </div>

  <div [formGroup]= "generalSettingsForm">

    <!-- Communication Type Preferences-->
    <div class="label-style" style="text-align: left; margin-top: 40px;">Send message via:  </div>
    <mat-radio-group formControlName="customerCommuncationType" style="border: 1px solid lightgray; border-radius: 4px; padding: 10px 35px 10px 0px;">
      <mat-radio-button *ngFor="let preference of CustomerCommunicationTypes" [value]=preference >
        {{preference}}
      </mat-radio-button>
    </mat-radio-group>

    <div class="label-style" style="text-align: left; margin-top: 40px;">Customize message that your customers will recieve</div>

  <!-- Design View -->
  <div class="message-layout">
    <div class="message-alignment">
      <div class="label-style" style="font-weight: bold; margin-bottom: 20px;" fxLayout="row" fxLayoutAlign="start center">Design View:</div>
  <div style="display: flex;">
  <div class="label-style" fxLayout="row" fxLayoutAlign="start center">Subject:</div>
  <button class="button-green" mat-button (click)="loadDataLinkModal('subject')">
    Subject Data Link
  </button>
  </div>
  <div style="width: 100%;" fxLayout="row">
    <div fxLayoutAlign="start center">
      <mat-form-field appearance="outline" class="medium-field">
        <input #subject matInput formControlName="subject" placeholder="Subject"
        [name]="ElementNameForId('214063')" autofill='off' autocomplete='new-password' data-lpignore='true'>
      </mat-form-field>
    </div>
    <!-- <div fxLayoutAlign="end center">
      <button class="button-green" mat-button (click)="loadDataLinkModal('subject')">
        Insert Data Link
      </button>
    </div> -->
  </div>

  <!-- Body -->
  <div>
    <div style="display: flex;">
      <div class="label-style" fxLayout="row" fxLayoutAlign="start center">Body:</div>
      <button class="button-green" mat-button (click)="loadDataLinkModal('body')">
        Body Data Link
      </button>
    </div>
  <div style="width: 100%;" fxLayout="row">
    <div fxLayoutAlign="start center">
      <mat-form-field appearance="outline" style="width: 320px;">
        <textarea #body matInput formControlName="body" placeholder="Body" style="min-height: 300px;"
        [name]="ElementNameForId('892069')" autofill='off' autocomplete='new-password' data-lpignore='true'></textarea>
      </mat-form-field>
    </div>
  </div>
  <!-- <div fxLayoutAlign="end center">
    <button class="button-green" mat-button (click)="loadDataLinkModal('body')">
      Insert Data Link
    </button>
  </div> -->
</div>
</div>

<!-- Live View  -->
<div class="message-alignment">
<div class="label-style" style="font-weight: bold; margin-bottom: 20px;" fxLayout="row" fxLayoutAlign="start center">Live View:</div>
<div class="label-style" fxLayout="row" fxLayoutAlign="start center">Subject:</div>
  <div style="width: 100%;" fxLayout="row">
    <div fxLayoutAlign="start center">
      <mat-form-field appearance="outline" class="medium-field">
        <input #subject matInput formControlName="subjectLiveView" placeholder="Subject Live View">
      </mat-form-field>
    </div>
  </div>
  <div class="label-style" fxLayout="row" fxLayoutAlign="start center">Body:</div>
<div style="width: 100%;" fxLayout="row">
  <mat-form-field appearance="outline" style="width: 320px;">
    <textarea #liveView matInput formControlName="liveView" placeholder="Live View" style="min-height: 300px;"></textarea>
  </mat-form-field>
</div>
</div>
</div>
</div>

  <!-- Cancel / Submit buttons. -->
  <!-- <div>
    <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
  </div>
  <div style="display: flex; justify-content: flex-end;">
    <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
  </div> -->
  </div>

  <div class="header-label footer-container" style="border-top: 2px solid gainsboro;">
  <div>
    <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
  </div>
  <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
    <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
  </div>
  </div>
  </div>
