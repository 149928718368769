import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, SecurityContext } from '@angular/core';
import {PhotoService} from '../../../../../../../common/src/data/services/photo.service';
import {FieldType} from '@ngx-formly/core';
import { catchError,  debounceTime, delay, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, delayWhen, from, merge, Observable, of, ReplaySubject, Subject, throwError, zip } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { PhotoPickerComponent } from '../../../../testing/photo-picker/photo-picker.component';
import {DomSanitizer} from '@angular/platform-browser';
import { FormlyUtilityService } from '../formly-utility.service';
import { Iphoto } from '../../../../../../../common/src/data/dao/iphoto';
import { LoggingService } from '../../../../../../../common/src/data/logging/logging.service';


interface PhotoWithResolution {
  photo: Iphoto;
  height: number;
  width: number;
}

interface photoAdderInformation {
  guid: string;
  photoCount: number;
}

@Component({
  selector: 'app-formly-photo-adder',
  templateUrl: './formly-photo-adder.component.html',
  styleUrls: ['./formly-photo-adder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyPhotoAdderComponent extends FieldType implements AfterViewInit, OnDestroy {

  placeholderImageLocation = "/assets/add_image.png";
  imageSoonPlaceholder = "/assets/image_soon.png"
  imagePlaceholders : Subject<Array<any>> = new Subject<Array<any>>();
  destroyingComponent$ = new Subject();
  resizedIphotos: ReplaySubject<{photo: Iphoto, height: number}[]> = new ReplaySubject<{photo: Iphoto, height: number}[]>(1);
  pictureCountObservable$ : Observable<number>;
  guid: string = Math.random().toString(36).substring(0, 10);


  get parentId() : string {
    return this.field.parent ? this.field.parent.id : null;
  }

  photoTrack(index: number, item: {photo: Iphoto, height:number}) {
    return item.photo.DocId();
  }

  constructor(public photoService: PhotoService, public modalController: ModalController, private ref: ChangeDetectorRef,
    public sanitization: DomSanitizer, private formlyUtilityService:  FormlyUtilityService, private loggingService: LoggingService) {
      console.log("FORMLY PHOTO ADDER CONSTRUTOR CALLED!")
    super();
   }

  get controlKey(): string { return `${this.formControl.value.guid}-${this.key}`;}
  initilizedControlKey$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get pictureCount(): Observable<number> {
    return this.pictureCountObservable$;
  }

  bypassSantizationResourceUrl(url: string) {
    return url === undefined ? this.imageSoonPlaceholder  : (url?this.sanitization.bypassSecurityTrustResourceUrl(url) : undefined) ?? this.imageSoonPlaceholder;
  }

  getResizedIphotos() : Observable<{photo: Iphoto, height: number}[]> {
    const maxImageHeight = 340;
    const maxWidthRow = 760;

    const photoLoadedOriginalResolution$ : Subject<PhotoWithResolution> = new Subject<PhotoWithResolution>();

    return this.photoService.photosAssignedControl(this.controlKey).pipe(
      delayWhen(() => this.initilizedControlKey$),
      map(x => {
        const retVal : ReplaySubject<PhotoWithResolution>[] = [];
        x.forEach(photo => {
          console.log(photo);
          const img = new Image();

          // add replay subject b/c images may load b/f we subscribe to them.
          const loadedImage = new ReplaySubject<PhotoWithResolution>(1);
          retVal.push(loadedImage);
          photoLoadedOriginalResolution$.pipe(
            filter(x => x.photo.docId === photo.docId),
            tap(x => loadedImage.next(x)),
            take(1)
          ).subscribe();

          photoLoadedOriginalResolution$
          img.onload = function(this: HTMLImageElement) {
            photoLoadedOriginalResolution$.next({photo, height: this.height, width: this.width});
          }
          if (photo.localWebviewPath) {
            img.src = this.sanitization.sanitize(SecurityContext.RESOURCE_URL,this.bypassSantizationResourceUrl(photo.localWebviewPath));
          } else {
            img.src = this.sanitization.sanitize(SecurityContext.RESOURCE_URL,this.bypassSantizationResourceUrl(this.imageSoonPlaceholder));
          }
        });
        return retVal;
      }),
      switchMap(x => x.length > 0 ? zip(...x) : of([])),
      map( x=> {
        const retVal: {photo: Iphoto, height: number}[] = [];
        if (x.length > 0) {
          do {
            // work through images in pairs as they are displayed in rows of two images each.
            const pair = x.splice(0,2);
            // if single, can take up to full width.
            if (pair.length === 1) {
              const img = pair[0];
              const ratio = img.height / img.width;
              const maxWidth = maxImageHeight/ ratio;
              if (maxWidth <= maxWidthRow) {
                retVal.push({photo: img.photo, height: maxImageHeight});
              } else {
                // h1/w1 = h2/maxWidthRow; h2 = h1*maxWidthRow/w1;
                retVal.push({photo: img.photo, height: maxWidthRow * ratio });
              }
            } else {
            // if two images in row, the combined width must be less than the max width.
            // h / w = r 340 / x = h / w ;  xh/w = 340 ; xh = 340w; x = 340w/h
              const a = {photo: pair[0], unmutatedScaledWidth : maxImageHeight * pair[0].width/ pair[0].height, order: 1, height: undefined};
              const b = {photo: pair[1], unmutatedScaledWidth : maxImageHeight * pair[1].width / pair[1].height, order: 2, height: undefined};
              if (a.unmutatedScaledWidth + b.unmutatedScaledWidth <= maxWidthRow) {
                retVal.push({photo: pair[0].photo, height: maxImageHeight});
                retVal.push({photo: pair[1].photo, height: maxImageHeight});
              } else {
                const sortedLargestToSmallest = [a,b].sort((a,b) => b.unmutatedScaledWidth - a.unmutatedScaledWidth);
                // if smaller width image takes up less then 1/2 of the availiable space @ 340 pixels of height.
                if (sortedLargestToSmallest[1].unmutatedScaledWidth <= maxWidthRow / 2) {
                  const maximumWidthLargerImage = maxWidthRow / 2 + maxWidthRow / 2 - sortedLargestToSmallest[1].unmutatedScaledWidth;
                  sortedLargestToSmallest[1].height = maxImageHeight;
                  sortedLargestToSmallest[0].height = maximumWidthLargerImage * (sortedLargestToSmallest[0].photo.height / sortedLargestToSmallest[0].photo.width);
                } else {
                  // if both images natural width are larger then alloted space, we will split alloted space in half for now.
                  const maxWidth = maxWidthRow / 2;
                  // h/w = r h/maxWidth = h1/w1; h = h1*maxWidth/w1;
                  sortedLargestToSmallest[0].height = maxWidth * (sortedLargestToSmallest[0].photo.height / sortedLargestToSmallest[0].photo.width);
                  sortedLargestToSmallest[1].height = maxWidth * (sortedLargestToSmallest[1].photo.height / sortedLargestToSmallest[1].photo.width);
                }
                sortedLargestToSmallest.sort((a,b) => a.order - b.order);
                retVal.push(...sortedLargestToSmallest.map(x => ({photo: x.photo.photo, height: x.height})));
              }
              }
          } while (x.length > 0);
        }
        return retVal;
        }
      ),
      catchError(err => {
      this.loggingService.addLog("Error loading image. getResizedIPhotos()" + " " + err.message, "photo.service.ts");
      return throwError(err);
      })
      );
  }

  ngOnDestroy(): void {
    console.warn("DESTROROORORROROROROR PHHHHHHOOOOOTOOOOO!");
    if (this.formControl !== undefined) {
      this.photoService.ceaseMonitoringPhotoKey.next(this.controlKey);
    }
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  ngOnInit(): void {

    this.pictureCountObservable$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      delayWhen(() => this.initilizedControlKey$),
      map(x => x.photoCount as number),
    );

    const x = this.to.minimumPhotoCount as number < 1 ? 1 : this.to.minimumPhotoCount as number;
      const retVal = [];
        var i: number;
        for (i=0; i < x; i++) {
          retVal.push(null);
        }
        this.imagePlaceholders.next(retVal);

        of(retVal).pipe(
          delay(1),
          tap(x => this.imagePlaceholders.next(x)),
          take(1)
        ).subscribe();
  }

  logIt(x: any) {
    console.log(x);
  }

  ngAfterViewInit() : void {

    let firstTimeThrough = true;

    if (this.to.changeDetect !== undefined) {
    (this.to.changeDetect as Observable<any>).pipe(
      startWith(null),
      delay(1),
      map(() => this.to.minimumPhotoCount as number < 1 ? 1 : this.to.minimumPhotoCount as number),
      tap(x => {
        const retVal = [];
        var i:number;
        for (i=0; i < x; i++) {
          retVal.push(null);
        }
        this.imagePlaceholders.next(retVal);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
    }

    this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      distinctUntilChanged((a,b) => {
        console.log(a,b);
        return a.photoCount === b.photoCount && a.guid === b.guid;
      }),
      filter(x => x !== undefined && x !== "" && x !== null),
      filter(x => x.photoCount > 0 || firstTimeThrough),
      switchMap(() => this.getResizedIphotos()),
      tap(() => firstTimeThrough = false),
      tap( x => {
        const formControlValue = this.formControl.value;
        const toPatch = {guid: formControlValue.guid, photoCount: x.length};
        this.formControl.patchValue(toPatch);
      }),
      filter(x => x.length > 0),
      tap(x => this.resizedIphotos.next(x)),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    if (this.formControl.value === undefined || this.formControl.value === "" || this.formControl.value === null) {
      console.warn(`this.formControl.value = ${this.formControl.value}`);
      console.warn("PATCHING IN NEW FORM CONTROL VALUE FROM ngAfterViewInit");
      const val = {guid: `${this.parentId === undefined ? "" : this.parentId}-${this.guid}`, photoCount: 0} as photoAdderInformation;
      this.formControl.patchValue(val);
      this.ref.markForCheck();
    }
    this.initilizedControlKey$.next(true);
  }

  async presentModal() {
    if (this.initilizedControlKey$.value === true) {
      const modal = await this.modalController.create({
        component: PhotoPickerComponent,
        cssClass: 'my-custom-class',
        componentProps: {
          'guid': this.controlKey,
          'numberPicturesMax': this.to.maximumPhotoCount,
          'numberPicturesMin': this.to.minimumPhotoCount,
          'formModelFirestoreDocId': this.formlyUtilityService.activeFormModelFirestore.DocId()
        }
      });

      return await modal.present().then(() => modal.onDidDismiss()).then(b => {
        this.ref.markForCheck();
        if (b.data) {
          const val = this.formControl.value;
          const toPatch = {guid: val.guid, photoCount : b.data.numberPictures};
          this.formControl.patchValue(toPatch);
          console.log(this.formControl.value);
        } else {
          this.getResizedIphotos().pipe(
            tap(x => {
              const val = this.formControl.value;
              const toPatch = {guid: val.guid, photoCount : x.length};
              this.formControl.patchValue(toPatch);
            }),
            take(1)
          ).subscribe();
        }
      });

    }
}

}
