import { Component, Inject } from '@angular/core';
import { FormFirestoreSummary } from '../../../../../../common/src/data/dao/form-firestore-summary';
import { MatDialogRef,MAT_DIALOG_DATA,MatDialog,MatDialogTitle,MatDialogContent,MatDialogActions,MatDialogClose } from '@angular/material/dialog';

export interface DeploymentDialogData {
  outOfSyncWorkflows: FormFirestoreSummary[],
  workflow: FormFirestoreSummary
}

export enum DeploymentDialogResults {
  DEPLOY_ONLY = "DEPLOY_ONLY",
  DEPLOY_ALL = "DEPLOY_ALL",
}

@Component({
  selector: 'app-workflow-deployment-dialog',
  templateUrl: './workflow-deployment-dialog.component.html',
  styleUrls: ['./workflow-deployment-dialog.component.scss'],
})
export class WorkflowDeploymentDialogComponentDialog {

WorkflowState(f: FormFirestoreSummary ) {
  if (f.currentDesignFirestoreDocId === undefined) {
    return "Never Deployed"
  } else {
    return "Older Version Deployed"
  }
}

constructor(public dialogRef: MatDialogRef<WorkflowDeploymentDialogComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DeploymentDialogData) {}

}
