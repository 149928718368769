<mat-dialog-content class="modify-employees-schedule-grid">
  <div class="header-label">Edit Daily Schedule</div>
  <!-- <div style="display: contents;"> -->
  <!-- Default Availibility for reference. -->
    <div class="employee-scheduling-half" style="border-right: 1px solid gainsboro;">
      <div class="daily-schedule-section-header" style="grid-column: 1/3; grid-row: 1/2;">Default Working Hours</div>
      <!-- <div style="grid-column: 1/3; text-align: left; padding-left: 45px;" class="label-style">{{avail.date | date:'EEEE, MMM d, yyyy'}}</div> -->
      <div style="grid-column: 1/3;"></div>
      <!-- <div class="time-field-time" fxLayout="column" fxLayoutAlign=" center"> -->
        <div class="label-style">Start Time</div>
      <app-time-field

        [value]= "defaultAvailability.workStartTime"
        [fieldLabel]= "'Start'"
        [readOnly]="true"
        [granularity]="15"
        [minDate] = "minDateToDisplay"
        [maxDate]="maxDateToDisplay"
      ></app-time-field>

      <div class="label-style">End Time</div>
      <app-time-field

        [value]= "defaultAvailability.workEndTime"
        [fieldLabel]= "'End'"
        [readOnly]="true"
        [granularity]="15"
        [minDate] = "minDateToDisplay"
        [maxDate]="maxDateToDisplay"
      ></app-time-field>
      <div class="label-style">Total Time</div>
      <div class="label-style" style="margin-left: 5px; text-align: left;">{{GetHoursScheduled(defaultAvailability)}}</div>
    <!-- </div> -->
  </div>
  <!-- <div></div> -->
  <!-- One off availibility.  Either populated (b/c user previously sorted), or a populated w/ default availibility for day. -->
  <div class="employee-scheduling-half" style="border-left: 1px solid gainsboro;">
    <div class="daily-schedule-section-header" style="grid-column: 1/3; grid-row: 1/2;">Updated Working Hours</div>
    <div style="display: flex; grid-column: 1/3; text-align: left; padding-left: 45px; align-items: center; padding-bottom: 10px;">
    <mat-checkbox class="label-style" [checked]="avail.activeWorkDay" (change)="setCheckDay(avail)">{{dateInQuestion | date:'EEEE, MMM d, yyyy'}}</mat-checkbox>
    <mat-icon style="color: #868686;" class="mat-icon-radio-position" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='If unchecked then this will denote employee being off for the entire day'>info</mat-icon>
    </div>
    <!-- <div class="time-field-specificity" fxLayout="row" fxLayoutAlign=" center"> -->
      <div class="label-style">Start Time</div>
    <app-time-field
      [value]= "avail.workStartTime"
      [fieldLabel]= "'Start'"
      [readOnly]="!avail.activeWorkDay"
      [granularity]="15"
      [minDate] = "minDateToDisplay"
      [maxDate]="maxDateToDisplay"
    ></app-time-field>
    <label class="mat-error" *ngIf="startTimeErrored">{{startTimeErrorMessage}}</label>
    <div class="label-style">End Time</div>
    <app-time-field
      [value]= "avail.workEndTime"
      [fieldLabel]= "'End'"
      [readOnly]="!avail.activeWorkDay"
      [granularity]="15"
      [minDate] = "minDateToDisplay"
      [maxDate]="maxDateToDisplay"
    ></app-time-field>
    <label class="mat-error" *ngIf="endTimeErrored">{{endTimeErrorMessage}}</label>
    <div *ngIf="!avail.startEndTimesValidate" style="color: #f44336; grid-column: 1/3; padding: 0px 40px;" class="alert alert-danger">
      Start of work day must be before the end of the work day.
    </div>
    <div class="label-style">Total Time</div>
    <div class="label-style" style="margin-left: 5px; text-align: left;">{{GetHoursScheduled(avail)}}</div>
    <div class="label-style">Notes</div>
    <mat-form-field appearance="outline" class="medium-field" style="width: 250px;">
      <textarea cdkTextareaAutosize matInput placeholder="Note" [(ngModel)]="avail.note"
      name="timeOffNote1" data-lpignore='true'></textarea>
    </mat-form-field>

    </div>
    <mat-dialog-actions class="site-visit-cont spacing" style="display: contents;">
      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
      <div class="button-position">
        <button type="button" class="button-gray" mat-button mat-dialog-close>Close</button>
      </div>
      <div fxLayout="row">
      <div class="button-position">
        <button type="button" [disabled]="disallowRevertToDefault" class="button-light-blue" style="margin-right: 30px;" mat-button mat-dialog-close (click)="deleteClose()">Revert To Default</button>
      </div>
      <div class="button-position">
        <button type="submit" class="button-blue" [disabled]="needValidationBeforeSave" mat-button (click)="saveClose()">Save</button>
      </div>
      </div>
      </div>
  </mat-dialog-actions>
<!-- </div> -->
</mat-dialog-content>

