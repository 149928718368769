import { Component, OnInit } from "@angular/core";
import { FirestoreDiffService } from "../../../../common/src/data/dao-services/firestore-diff.service";
import { AuthenticationService } from "../../../../common/src/util/authentication.service";
import { LocalSettingsService } from "../settings/local-settings.service";
import { AdminService } from "./admin.service";
import { map, switchMap, tap, take, exhaustMap, delay, finalize, share, concatMap } from "rxjs/operators";
import { AddressRoutingService } from "../../../../common/src/data/dao-services/address-routing.service";
import { EmployeeAvailabilityService } from "../../../../common/src/data/dao-services/employee-availability.service";
import { combineLatest, debounceTime, filter, forkJoin, from, interval, Observable, of, zip } from "rxjs";
import { EmployeeAvailability } from "../../../../common/src/data/dao/employee-availability";
import { EmployeeService } from "../../../../common/src/data/dao-services/employee.service";
import { EstimateService } from "../../../../common/src/data/dao-services/estimate.service";
import { InvoiceService } from "../../../../common/src/data/dao-services/invoice.service";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { ResourceDayAddressRoutingService } from "../../../../common/src/data/dao-services/resource-day-address-routing.service";
import { ResourceDayAddressRouting } from "../../../../common/src/data/dao/resource-day-address-routing";
import { JobService } from "../../../../common/src/data/dao-services/job.service";
import { SiteVisitService } from "../../../../common/src/data/dao-services/site-visit.service";
import { FormModelFirestoreService } from "../../../../common/src/data/dao-services/form-model-firestore.service";
import { InvoicePaymentService } from "../../../../common/src/data/dao-services/invoice-payment.service";
import { FirestoreBackend } from "../../../../common/src/data/database-backend/retrieve-from-firestore";
import { LineItemService } from "../../../../common/src/data/dao-services/line-item.service";
import { CustomerService } from "../../../../common/src/data/dao-services/customer.service";
import { Report } from "../../../../common/src/data/dao/report";
import { ReportService } from "../../../../common/src/data/dao-services/report.service";
import { VerticalSchedulerService } from "../vertical.scheduler.service";
import { limit, where } from "firebase/firestore";
import { SettingsService } from "../settings/settings.service";
import { IndustryService } from "../../../../common/src/data/dao-services/industry.service";
import { CustomerCommunicationTemplateService } from "../../../../common/src/data/dao-services/customer-communication-template.service";
import { EmployeeRoleService } from "../../../../common/src/data/dao-services/employee-role.service";
import { TaxRateSettings } from "../../../../common/src/data/dao/tax-rate-settings";
import { TaxRateSettingsService } from "../../../../common/src/data/dao-services/tax-rate-settings.service";
import { EmployeePermissionService } from "../../../../common/src/data/dao-services/employee-permission.service";
import { GenericServiceProviderSettingService } from "../../../../common/src/data/dao-services/generic-service-provider-setting.service";
import { ReferralCampaignService } from "../../../../common/src/data/dao-services/referral-campaign.service";
import { CompanySettingsService } from "../../../../common/src/data/dao-services/company-settings.service";
import { SchedulerSettingsService } from "../../../../common/src/data/dao-services/scheduler-settings.service";
import { PricingMethodologySettingsService } from "../../../../common/src/data/dao-services/pricing-methodology-settings.service";
import { JobTypeService } from "../../../../common/src/data/dao-services/job-type.service";
import { ResourceDayService } from "../../../../common/src/data/dao-services/resource-day.service";
import { EmployeeGeofenceService } from "../../../../common/src/data/dao-services/employee-geofence.service";
import { FormModelFirestore } from "../../../../common/src/data/dao/form-model-firestore";
import { FormFirestoreService } from "../../../../common/src/data/dao-services/form-firestore.service";
import { FormFirestoreSummary } from "../../../../common/src/data/dao/form-firestore-summary";
import { FormFirestoreSummaryService } from "../../../../common/src/data/dao-services/form-firestore-summary.service";
import { Job } from "../../../../common/src/data/dao/job";
import {CostStructureService} from "../../../../common/src/data/dao-services/pricebook/cost-structure.service";
import { PriceBookUnitOfMeasureService } from "../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  collections = [
    { name: "Greg", id: "r2rWZNMrjZ2oHtfMyYSQ" },
    { name: "Karl", id: "dACXoX8cYWsV9IXZBMhm" },
    { name: "Seth", id: "9tMnuJPrIBraRQ9qrTbR" },
    { name: "Charmed Chimney", id: "9bvh0IbtGIodX8wHYYya" },
    { name: "Charming Seth", id: "dlfLvglTzoYOaCJELxeB" },
    { name: "New Charmed Chimney", id: "aKgDbsxAm0iCTnZR53cp" },
    {name: "Child's Express", id: "C1GCzL5z2hzSTBMl6nH5"},
  ];

  activeCollection;
   invoices = [];

  constructor(
    private formFirestoreService: FormFirestoreService,
    private formFirestoreSummaryService: FormFirestoreSummaryService,
    private resourceDayService: ResourceDayService,
    private employeeGeofenceService: EmployeeGeofenceService,
    private localSettingsService: LocalSettingsService,
    public adminService: AdminService,
    private auth: AuthenticationService,
    private diffService: FirestoreDiffService,
    private addressroutingService: AddressRoutingService,
    private employeeAvailabilityService: EmployeeAvailabilityService,
    private employeeService: EmployeeService,
    private estimateService: EstimateService,
    private invoiceService: InvoiceService,
    private resourceDayAddressRoutingService: ResourceDayAddressRoutingService,
    private jobService: JobService,
    private siteVisitService: SiteVisitService,
    private formModelFirestoreService: FormModelFirestoreService,
    private invoicePaymentsService: InvoicePaymentService,
    private lineItemService: LineItemService,
    private customerService: CustomerService,
    private reportService: ReportService,
    private verticalSchedulerService: VerticalSchedulerService,
    private settingsService: SettingsService,
    private industryService: IndustryService,
    private customerCommunicationTemplateService: CustomerCommunicationTemplateService,
    private employeeRoleService : EmployeeRoleService,
    private taxRateSettingsService: TaxRateSettingsService,
    private employeePermissionsService: EmployeePermissionService,
    private genericServiceProviderSettingsService: GenericServiceProviderSettingService,
    private referralCampaignService: ReferralCampaignService,
    private companySettingsService: CompanySettingsService,
    private schedulerSettingsService: SchedulerSettingsService,
    private pricingMethodologyService: PricingMethodologySettingsService,
    private jobTypeService: JobTypeService,
    private costStructureService: CostStructureService,
    private unitOfMeasureService: PriceBookUnitOfMeasureService,
  ) {

    // this.auth._firebasePathRead = "/service-providers/dlfLvglTzoYOaCJELxeB/";
    // this.auth._firebasePathWrite = "/service-providers/aKgDbsxAm0iCTnZR53cp/";


    // let dilly = 0;
    //   const outstandingInvoices = new Report({primaryKey: "primaryKey", bucketId: "aKgDbsxAm0iCTnZR53cp", primaryDateField: "Job Creation Date", name: "Outstanding Invoices"});
    //   const lineItemStauses = new Report({primaryKey: "primaryKey", bucketId: "aKgDbsxAm0iCTnZR53cp", primaryDateField: "Job Creation Date", name: "Line Item Statuses"});
    //   const proposedWorkAndOutcomes = new Report({primaryKey: "primaryKey", bucketId: "aKgDbsxAm0iCTnZR53cp", primaryDateField: "Proposed Job Creation Date", name: "Proposed Work and Outcomes"});

      // const reportsToAdd = [outstandingInvoices, lineItemStauses, proposedWorkAndOutcomes];
      // reportsToAdd.forEach(x => {
      //   this.reportService.create$(x).pipe(
      //     take(1)
      //   ).subscribe();
      // });
    // this.invoiceService.queryFirestoreDeep$().pipe(
    //   take(1),
    //   tap(x => {
    //     do
    //     {
    //       const b = x.splice(0, 500);
    //       const batch = this.invoiceService.retrieveFirestoreBatchWithCreatedDocIds();
    //       b.forEach(y => {
    //         y._totalAmount = y.totalAmount;
    //         y._outstandingDue = y.outstandingDue;
    //         this.invoiceService.mergeUpdate(y,["_outstandingDue"],batch,false);
    //       });
    //       this.invoiceService.commitExternallyManagedFirestoreBatch(batch);
    //     } while (x.length > 0);
    //     }),
    //   tap(() => console.error("DONZE")),
    //   take(1)
    // ).subscribe();

    // this.invoiceService.queryFirestoreDeep$().pipe(
    //   take(1),
    //   tap(x => x.forEach(
    //     y => {
    //       setTimeout( () => {
    //       this.invoiceService.update$(y).pipe(
    //       take(1)
    //     ).subscribe();
    //     console.log("dilly:", dilly++);
    //     }, 500)}
    //   )),
    //   tap(() => console.error("DONZE")),
    //   take(1)
    // ).subscribe();


    // this.lineItemService.load$("hevZ5HcPSfEU7pKvToiG").pipe(
    //   map(x => {
    //     x.originatingLineItemDocId = null;
    //     return x;
    //   }),
    //   switchMap(x => this.lineItemService.mergeUpdate(x, ["originatingLineItemDocId"])),
    //   tap(x => console.log(x,` string`)),
    // ).subscribe();

    // of(null).pipe(
    //   delay(30000),
    //   tap(x => console.log(console.warn(FirestoreBackend.numberObservers))),
    //   switchMap(() => this.jobService.load$("19SZe9j2nBAGcjfjGpWg")),
    //   tap(x => console.log(x,` string`)),
    //   tap(x => console.log(console.warn(FirestoreBackend.numberObservers))),
    //   delay(7000),
    //   tap(x => console.log(console.warn(FirestoreBackend.numberObservers))),
    //   tap(x => console.log(x,` string`)),
    // ).subscribe();

    // update invoice payment to reference second invoice.
    // payments that are assigned to invoice :  O2MNWbJHNatPTVgxKa4S
    // need updated to invoice:  jcplCwrJlb6lbllqgb8c

    // const invoicePayments = this.invoicePaymentsService.queryFirestoreShallow$(ref => ref.where("invoiceDocId", "==", "O2MNWbJHNatPTVgxKa4S")).pipe(
    //   switchMap(x => this.invoicePaymentsService.load$(x[0].docId)),
    //   map(x => {
    //     x.invoiceDocId = "jcplCwrJlb6lbllqgb8c";
    //     return x;
    //   }),
    //   switchMap(x => this.invoicePaymentsService.mergeUpdate(x, ["invoiceDocId"])),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // const inv = this.invoiceService.load$("jcplCwrJlb6lbllqgb8c").pipe(
    //   map(i => {
    //     i.amountPaid = 7900;
    //     return i;
    //   }),
    //   switchMap(x => this.invoiceService.mergeUpdate(x, ["amountPaid"])),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // this.invoiceService.load$("O2MNWbJHNatPTVgxKa4S").pipe(
    //   map(i => {
    //     i.amountPaid = 0;
    //     i.jobDocId = "fish";
    //     i.billingCustomerDocId = "fish";
    //     i.formModelFirestoreDocId = "fish";
    //     return i;
    //   }),
    //   switchMap(x => this.invoiceService.mergeUpdate(x, ["amountPaid", "jobDocId", "billingCustomerDocId", "formModelFirestoreDocId"])),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // this.estimateService.load$("bXJsIpna4t4cg6EMeRBM").pipe(
    //   map(i => {
    //     i.active = false;
    //     i.customerDocId = "fish";
    //     i.formModelFirestoreDocId = "fish";
    //     i.jobDocId = "fish";
    //     i.serviceAddressDocId = "fish";
    //     return i;
    //   }),
    //   switchMap(x => this.estimateService.mergeUpdate(x, ["active", "customerDocId", "formModelFirestoreDocId", "jobDocId", "serviceAddressDocId"])),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // Invoice jcplCwrJlb6lbllqgb8c needs amount paid set to 7900
    // Invoice O2MNWbJHNatPTVgxKa4S needs amount paid set to 0, job => fish, billingCustomerDocId => fish formModelFirestoreDocId => fish

    //Estimate:  bXJsIpna4t4cg6EMeRBM
    // active => false, customerDocId => fish, formModelFirestoreDocId => fish, jobDocId => fish, serviceAddressDocId => fish

    //update some to 2fuPQpZ2wtqJh1ikPN4J to test they properly update on spamming.
    // this.siteVisitService.queryFirestoreShallow$(ref => ref.where ("_startDate", ">=", endOfDay(new Date()))).pipe(
    //   switchMap(x => this.jobService.loadMultiple$(x.map(y => y.jobDocId))),
    //   tap(x => console.log(x,` string`)),
    //   map(x=> x.filter(y => y.formModelFirestore && y.formModelFirestore.instantiatedLatestForm === null && (y.formModelFirestore.model === undefined || y.formModelFirestore.model === "") &&
    //   y.formModelFirestore.formFirestoreDocId !== "W59vevi02EdGUittvYf8")),
    //   tap(x => console.log(x,` string`)),
    //   // tap(x => console.table(x.map(q => { return [q.siteVisits[0]['startDate'] ] }))),
    //   tap(x => console.log(x,` string`)),
    //   // map(x => {
    //   //   const obs= [];
    //   //   x.forEach(y => {
    //   //     y.formFirestoreDocId = "W59vevi02EdGUittvYf8";
    //   //     obs.push(this.formModelFirestoreService.mergeUpdate(y, ["formFirestoreDocId"]))
    //   //   });
    //   //   return obs;
    //   // }
    //   // ),
    //   // switchMap(x => zip(...x))
    // ).subscribe(x => console.log(x));

    // this.addressroutingService.queryFirestoreShallow$(ref => ref.where("originAddressDocId", "==", "yexWfA05jOgaLhGKreya")
    // .where("destinationAddressDocId", "==", "bmGY1aGDaOVRlXiSDwdF")
    // .limit(100)).pipe(
    // tap(x => console.error(x)),
    // ).subscribe();

    // resource day 28th ( retrieved 25, created 25, last updated 25th)
    // this.resourceDayAddressRoutingService.queryFirestoreShallow$(ref => ref.where("originAddressDocId", "==", "yexWfA05jOgaLhGKreya")
    // .where("destinationAddressDocId", "==", "bmGY1aGDaOVRlXiSDwdF")
    // .limit(100)).pipe(
    // tap(x => console.error(x)),
    // ).subscribe();

    // this.db.collection("/service-providers/dACXoX8cYWsV9IXZBMhm/resourceDayAddressRouting/", ref => ref.where("employeeDocId", "==", "jUX4oORk2dPcP7wwTyNl")
    // // .where("resourceDay","==", new firestore.Timestamp(startOfDay(new Date(2022,11,25,0,0,0,0)).getTime()/1000, 0))
    // .where("resourceDay", "==", startOfDay(new Date(2022,11,5,0,0,0,0)))
    // .limit(100)
    // ).get().pipe(
    //   // tap(x => console.error(x,` string`)),
    //   tap(x => console.error(x.docs.map(y => y.data()))),
    //   tap(x => console.error(x.docs.map(y => y.data()['resourceDay'].toDate()))),
    //   tap(x => console.error(x.docs.filter(y => y.data()['destinationAddressDocId'] === "yexWfA05jOgaLhGKreya" && y.data()['originAddressDocId'] === "bmGY1aGDaOVRlXiSDwdF")
    //   .map(y => y.data()))),
    //   tap(x => console.error(x.docs.filter(y => y.data()['destinationAddressDocId'] === "bmGY1aGDaOVRlXiSDwdF" && y.data()['originAddressDocId'] === "yexWfA05jOgaLhGKreya")
    //     .map(y => y.data()))),
    // ).subscribe();

    // this.db.collection("/service-providers/dACXoX8cYWsV9IXZBMhm/resourceDay/", ref => ref.where("employeeDocId", "==", "jUX4oORk2dPcP7wwTyNl")
    // .where("resourceDay", "==", startOfDay(new Date(2022,11,5,0,0,0,0)))
    // ).get().pipe(
    //   // tap(x => console.error(x,` string`)),
    //   tap(x => console.error(x.docs.map(y => y.data()))),
    // ).subscribe();

    // this.employeeAvailabilityService.loadAll$().pipe(
    //   tap(x => console.log(x,` string`)),
    //   map(all => {
    //     const toUpdate = [];
    //     all.forEach(a => toUpdate.push(a));
    //     toUpdate.forEach(a => a.active = false);
    //     return toUpdate.map(a => this.employeeAvailabilityService.update$(a));
    //   }),
    //   take(1),
    //   switchMap(x => zip(...x)),
    //   tap(x => console.error(x,` string`)),
    // ).subscribe();

    // this.employeeService.loadAll$().pipe(
    //   map(emps => (emps.map(e => e.employeeAvailability))),
    //   map(e => e.flatMap(ea => ea)),
    //   map(all => {
    //     const toUpdate = [];
    //     all.forEach(a => toUpdate.push(new EmployeeAvailability({...a})));
    //     toUpdate.forEach(a => a.active = true);
    //     return toUpdate.map(a => this.employeeAvailabilityService.update$(a));
    //   }),
    //   take(1),
    //   switchMap(x => zip(...x)),
    //   tap(x => console.error(x,` string`)),
    // ).subscribe();

    // this.diffService.QueryFirestoreShallowValueChanges(ref=> ref.where("activeDocId","==", "PxUm0dP26JajKsHiXQLJ")).pipe(
    //   map(x => x.sort((a,b) => a.createdAt.getTime() - b.createdAt.getTime())),
    //   tap(x => console.log(x,` string`)),
    // ).subscribe();

    // this.db.collection("commute-matrix-queue", ref => ref.where("addressDocId","==","8YJ2BGE7hX6uIptn0g2Y")).get().pipe(
    //   tap(x => console.log(x,` string`)),
    // ).subscribe();

    // this.addressroutingService.queryFirestoreShallow$(ref => ref.where("originAddressDocId", "==", "8YJ2BGE7hX6uIptn0g2Y")).pipe(
    //   map(x => x.sort((a,b) => a.createdAt.getTime() - b.createdAt.getTime())),
    //   tap(x => console.log(x.map(y => `${y.createdAt} ${y.destinationAddressDocId}`))),
    // ).subscribe();

    // this.addressroutingService.queryFirestoreShallow$(ref => ref.where("destinationAddressDocId", "==", "8YJ2BGE7hX6uIptn0g2Y")).pipe(
    //   map(x => x.sort((a,b) => a.createdAt.getTime() - b.createdAt.getTime())),
    //   tap(x => console.log(x.map(y => `${y.createdAt} ${y.originAddressDocId}`))),
    // ).subscribe();

    this.activeCollection = localSettingsService.loadFromLocalStorage(
      "BirdDog",
      "activeCollection",
      this.collections[0]
    );
  }

  deleteDemoJobInvoices() {
    this.invoiceService
      .queryFirestoreShallow$([where("jobDocId", "==", "NY3Xl6LZifj6kJ28Sgyf")])
      .pipe(
        take(1),
        tap((x) => console.log(x, ` string`)),
        exhaustMap((x) => zip(...x.map((i) => this.invoiceService.delete$(i)))),
        tap((x) => console.log("DELETED"))
      )
      .subscribe();
  }

  deleteOldDatas() {
    //delete all after 2024-06-12 @ 23:57
    this.resourceDayAddressRoutingService.queryFirestoreShallow$([where("resourceDay", ">", new Date(2024, 5, 12, 23, 57))]).pipe(
      debounceTime(15000),
      take(1),
      tap(x => console.log(x, ` string`)),
      exhaustMap(x => zip(...x.map(y => this.resourceDayAddressRoutingService.delete$(y)))),
      tap(x => console.log("DELETED RESOURCE DAY ADDRESS ROUTING"))
    ).subscribe();
   this.resourceDayService.queryFirestoreShallow$([where("resourceDay", ">", new Date(2024, 5, 12, 23, 57))]).pipe(
    debounceTime(15000),
      take(1),
      tap(x => console.log(x, ` string`)),
      exhaustMap(x => zip(...x.map(y => this.resourceDayService.delete$(y)))),
      tap(x => console.log("DELETED RESOURCE DAY"))
    ).subscribe();
    this.employeeGeofenceService.queryFirestoreShallow$([where("dateMonitoring", ">", new Date(2024, 5, 12, 23, 57))]).pipe(
      debounceTime(15000),
      take(1),
      tap(x => console.log(x, ` string`)),
      exhaustMap(x => zip(...x.map(y => this.employeeGeofenceService.delete$(y)))),
      tap(x => console.log("DELETED EMPLOYEE GEOFENCE"))
    ).subscribe();
  }

  siteVisitsToUpdate() {
     let defaultFormFirestore = undefined;
    this.formFirestoreSummaryService.load$("og9DyVsCUGpqL7gCxQ4Y").pipe(
      filter(x => x != null),
      switchMap(summary => this.formFirestoreService.load$(summary.currentDeployedFirestoreDocId)),
      tap(formFirestore => defaultFormFirestore = formFirestore),
      take(1)
    ).subscribe();
    //retrieve all jobs created in the past 6 months that have no site visits.
    const jobsWithoutSiteVisits = this.jobService.queryFirestoreDeep$([where("createdAt", ">=", startOfDay(subDays(new Date(), 180))),
      where("siteVistDocIds", "==", [])]).pipe(
       debounceTime(10500),
     tap(x => {
     console.log(x,` string`);
     }),
   );

   //retrieve all future site visits.
   const futureSiteVisits = this.siteVisitService.queryFirestoreDeep$([where("_startDate", ">", startOfDay(new Date(2024,5,13)) )]).pipe(
     debounceTime(10500),
     tap(x => {
     console.log(x,` string`);
     }),
     switchMap(x => this.jobService.queryFirestoreForInValues("jobDocId", x.map(y => y.jobDocId))),
     take(1),
     switchMap(x => this.jobService.loadMultiple$(x.map(x => x.jobDocId))),
     take(1),
   )

   combineLatest([jobsWithoutSiteVisits, futureSiteVisits]).pipe(
   // jobsWithoutSiteVisits.pipe(
     take(1),
     // convert to unique list of jobs with identical jobDocId
     map( x=> {
       const jobs = x[0].concat(x[1]);
       const uniqueJobs : Job[] = [];
       jobs.forEach(j => {
         if (!uniqueJobs.find(uj => uj.jobDocId === j.jobDocId)) {
           uniqueJobs.push(j);
         }
       }
       );
       return uniqueJobs;
     }),
     tap(x => {
     console.log(x,` string`);
     }),
     // check that formModelFirestore.model === "" or undefined
     map(x => x.filter(j => j.formModelFirestore.model === "" || j.formModelFirestore.model === undefined)),
     tap(x => {
     console.log(x,` string`);
     }),
     // filter out those already done.
     map(x => {
       return (x as Job[]).filter(y => y.formModelFirestore.formFirestoreDocId !== defaultFormFirestore.DocId());
       }),
       tap(x => {
       console.log(x,` string`);
       }),
     map(x => {
       x.forEach(y => {
         y.formModelFirestore = new FormModelFirestore(
         {formFirestore: defaultFormFirestore,
         formFirestoreDocId: defaultFormFirestore.DocId()});
         y.formModelFirestore.formFirestoreSummaryDocIdToInstantiated = {};
       });
       return x;
     }),
     switchMap(x => zip(...x.map(j => this.jobService.update$(j).pipe(take(1))))),
     tap(x => {
     console.error("FINISHED THE WORKS.");
     }),
     take(1),
   ).subscribe();
  }

  popJob() {

    // this.costStructureService.populateGarretyCostStructures();
    // this.unitOfMeasureService.populateGarretyUnitsOfMeasure();

    // this.deleteOldDatas();
    return;

    // const b : Observable<any>[] = [];
    // b.push(of(10));
    // b.push(of(20));

    // forkJoin(b).subscribe(x => console.log(x));

    // this.jobService.queryFirestoreDeep$([where("jobDocId", "==", "5z4GjJJ8cFc7b8fJdknK")]).pipe
    // (
    //   tap(x => {
    //   console.log(x,` string`);
    //   }),
    //   switchMap(() => of(null)),
    //   delay(10000),
    //   tap(x => {
    //   console.log("End Subscription Job");
    //   }),
    //   take(1)
    // ).subscribe();

    // of(null).pipe(
    //   tap(x => {
    //   console.log("Loading via form model firestore.");
    //   }),
    //   switchMap(() => this.formModelFirestoreService.load$("FrPZFuiTTyx54B5dMRga")),
    //   tap(x => {
    //   console.log(x,`FROM FORM MODEL FIRESTORE MONITOR`);
    //   }),
    //   delay(60000),
    //   tap(x => {
    //     console.log("End Subscription Form Model Firestore");
    //     }),
    //     take(1)
    //   ).subscribe();

    //   of(null).pipe(
    //     delay(90000),
    //     tap(x => {
    //     console.log("Seconday Loading via form model firestore.");
    //     }),
    //     switchMap(() => this.formModelFirestoreService.load$("FrPZFuiTTyx54B5dMRga")),
    //     tap(x => {
    //     console.log(x,`SECOND DAY`);
    //     }),
    //     ).subscribe();


    //addressRoutingService.addressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", preceedingSiteVisit.siteVisitAddress.docId),
    //where("destinationAddressDocId", "==", this.siteVisitAddress.docId)]),

    // this.jobTypeService.load$("969J9RYSA3hQLFGUiL1Z").pipe(
    //   tap(x => {
    //   console.log(x,` string`);
    //   }),
    // ).subscribe();

    // this.formModelFirestoreService.load$("zPRBlH1P1L3vZVwMnIDs").pipe(
    //   tap(x => {
    //   console.log(x,` string`);
    //   }),
    // ).subscribe();

    // this.schedulerSettingsService.loadAll$().pipe(
    //   map(x => {
    //     x.forEach(y => y.uncommitedDocId = true);
    //     return x;
    //   }),
    //   switchMap(x => this.schedulerSettingsService.create$(x[0])),
    //   tap(x => console.log(x,` string`)),
    // ).subscribe();

  //   this.schedulerSettingsService.loadAll$().pipe(
  //     map(x => {
  //       x[0].workWeek.forEach(w => {
  //         w.uncommitedDocId = true;
  //     });
  //     return x[0].workWeek;
  //   }),
  //   take(1),
  //   switchMap(x=> this.employeeAvailabilityService.createMultiple$(x))
  // ).subscribe();

    // this.employeeRoleService.loadAll$().pipe(
    //   map(x => {
    //     x.forEach(y => y.uncommitedDocId = true);
    //     return x;
    //   }),
    //   switchMap(x => this.employeeRoleService.createMultiple$(x))
    // ).subscribe();

    // this.pricingMethodologyService.loadAll$().pipe(
    //   map(x => {
    //     x.forEach(y => y.uncommitedDocId = true);
    //     return x;
    //   }),
    //   switchMap(x => this.pricingMethodologyService.createMultiple$(x))
    // ).subscribe();

    // this.genericServiceProviderSettingsService.loadAll$().pipe(
    //   map(x => {
    //     x.forEach(y => y.uncommitedDocId = true);
    //     return x;
    //   }),
    //   take(1),
    //   switchMap(x => this.genericServiceProviderSettingsService.createMultiple$(x))
    // ).subscribe();


    // this.companySettingsService.populateInitial();
    // Add Industries
    // this.industryService.prePopulate();
    // this.customerCommunicationTemplateService.populateInitial()
    // this.taxRateSettingsService.populateInitial();

    // this.employeePermissionsService.loadAll$().pipe(
    //   map(x => {
    //     x.forEach(y => y.uncommitedDocId = true);
    //     return x;
    //   }),
    //   switchMap(x => this.employeePermissionsService.createMultiple$(x))
    // ).subscribe();

    // this.employeeRoleService.populateDefaultRoles();
    // this.genericServiceProviderSettingsService.prePopulate();

    // this.referralCampaignService.prePopulate();


    // Add the demo invoice.
    // this.invoiceService.load$(this.settingsService.getValue("demoInvoiceDocId")).pipe(
    //   map(inv => {
    //     inv.uncommitedDocId = true;
    //     return inv;
    //   }),
    //   take(1),
    //   switchMap(inv => this.invoiceService.create$(inv)),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // // Add the demo estimate.
    // this.estimateService.load$(this.settingsService.getValue("demoEstimateDocId")).pipe(
    //   map(est => {
    //     est.uncommitedDocId = true;
    //     return est;
    //   }),
    //   take(1),
    //   switchMap(est => this.estimateService.create$(est)),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // Add the demo customer.
    // this.customerService.load$(this.settingsService.getValue("demoCustomerDocId")).pipe(
    //   map(cust => {
    //     cust.uncommitedDocId = true;
    //     return cust;
    //   }),
    //   tap(x => {
    //   console.log(x,` string`);
    //   }),
    //   take(1),
    //   //Check why this fails on update?
    //   switchMap(cust => this.customerService.create$(cust)),
    //   tap(x => {
    //   console.log(x,` string`);
    //   }),
    //   take(1)
    // ).subscribe();

    // Add the demo job.
    // const will = this.settingsService.getValue("demoJobDocId");
    // console.log(will);
    // this.jobService.load$(this.settingsService.getValue("demoJobDocId")).pipe(
    //   map(job => {
    //     job.uncommitedDocId = true;
    //     return job;
    //   }),
    //   take(1),
    //   tap(x => console.log(x,` string`)),
    //   switchMap(job => this.jobService.create$(job)),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();

    // Add the demo site visit.
    // this.siteVisitService.load$(this.settingsService.getValue("demoSiteVisitDocId")).pipe(
    //   map(sv => {
    //     sv.uncommitedDocId = true;
    //     return sv;
    //   }),
    //   take(1),
    //   tap(x => console.log(x,` string`)),
    //   switchMap(sv => this.siteVisitService.create$(sv)),
    //   tap(x => console.log(x,` string`)),
    //   take(1)
    // ).subscribe();




  }

  deleteDemoJobEstimates() {
    this.estimateService
      .queryFirestoreShallow$([where("jobDocId", "==", "NY3Xl6LZifj6kJ28Sgyf")])
      .pipe(
        take(1),
        tap((x) => console.log(x, ` string`)),
        exhaustMap((x) =>
          zip(...x.map((y) => this.estimateService.delete$(y)))
        ),
        tap((x) => console.log("DELETED"))
      )
      .subscribe();
  }

  updateCollection(o: object) {
    this.localSettingsService.saveToLocalStorage(
      "BirdDog",
      "activeCollection",
      o
    );
    this.activeCollection = o;
  }

  ngOnInit(): void {}

  fixInvoices() {

    if (this.invoices.length === 0) {

    this.invoiceService.queryFirestoreDeep$().pipe(
      tap(x => x.forEach(y => this.invoices.push(y))),
      tap(x => console.log(x,` string`)),
      take(1),
    ).subscribe();
  } else {
    const bill = this.invoices.map(i => this.invoiceService.update$(i).pipe(delay(500),take(1)));
    from(bill).pipe(
      concatMap((x) => x),
    ).subscribe((val) => {
      console.log(val);
    })
  }

  }

  tests() {
    return this.fixInvoices();



  // async tests(): Promise<void> {

  //   console.warn(FirestoreBackend.numberObservers);


  //   let currentDate = new Date(2022,10,20);
  //   let currentDateToo = new Date(2022,10,20);
  //   let currentDateThree = new Date(2022,10,20);

  //   of(null).pipe(
  //     tap(async () => {
  //       do
  //     {
  //       await this.jobService.queryFirestoreDeep$(ref => ref.where("startDate", ">=", startOfDay(currentDateThree)).where("startDate", "<=", endOfDay(currentDateThree))).pipe(
  //             tap(x => console.log(`${x.length} jobs on ${currentDateThree}`)),
  //             delay(10000),
  //             take(1),
  //           ).toPromise();
  //           console.log("NEXT DAY");
  //           currentDate = addDays(currentDateThree, 1);
  //         } while (currentDateThree.getTime() < new Date(2023,3,20).getTime());
  //     })
  //   ).subscribe();


  //   of(null).pipe(
  //     tap(async () => {
  //       do
  //     {
  //     await this.invoiceService.queryFirestoreDeep$(ref => ref.where("createdAt", ">=", startOfDay(currentDate)).where("createdAt", "<=", endOfDay(currentDate))).pipe(
  //       tap(x => console.log(`${x.length} invoices on ${currentDate}`)),
  //       delay(10000),
  //       take(1),
  //     ).toPromise();
  //     console.log("NEXT DAY");
  //     currentDate = addDays(currentDate, 1);
  //   } while (currentDate.getTime() < new Date(2023,3,20).getTime());
  //     })
  //   ).subscribe();

  //   of(null).pipe(
  //     tap(async () => {
  //   do
  //   {
  //     await this.estimateService.queryFirestoreDeep$(ref => ref.where("createdAt", ">=", startOfDay(currentDateToo)).where("createdAt", "<=", endOfDay(currentDateToo))).pipe(
  //       tap(x => console.log(`${x.length} estimates on ${currentDate}`)),
  //       delay(10000),
  //       take(1),
  //     ).toPromise();
  //     console.log("NEXT DAY");
  //     currentDateToo = addDays(currentDateToo, 1);
  //   } while (currentDateToo.getTime() < new Date(2023,3,20).getTime());
  //     })
  //   ).subscribe();



      // of(null).pipe(
      //   delay(2000),
      //   tap(x => console.log(x,` string`)),
      //   switchMap(() => this.customerService.load$("ozy5Lmd1UccwAE6ScPwf")),
      //   tap(x => console.log(x,` string`)),
      // ).subscribe();

    // this.customerService.load$("PRhMZSlRzFeb3xACaLoz").pipe(
    //   tap((x) => console.log(x, ` string`))
    // ).subscribe();

    //this.jobService.queryFirestoreShallow$(ref => ref.where("jobDocId", "==", "ftR9tKmYkUpk9d38ZRkw")).pipe(
     // tap(x => console.log(x,` string`)),
     // ).subscribe();


     // this.employeeService
    //   .load$("Hufflepuff")
    //   .pipe(
    //     take(1),
    //     map((x) => {
    //       x.name = "Dave Monk";
    //       return x;
    //     }),
    //     switchMap((x) => this.employeeService.update$(x)),
    //     tap((x) => console.log(x, ` string`))
    //   )
    //   .subscribe();
  }
}
