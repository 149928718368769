import { Injectable } from '@angular/core';
import { ControlContainerComponent } from '../control-container.component';

@Injectable({
  providedIn: 'root'
})
export class FormlyComponentUtilityService {

  componentSelectedForSideView : ControlContainerComponent = undefined;

  constructor() { }

  updateControlContainerComponentsPositionInDropList(list: ControlContainerComponent[]) {
    let i = 0;
    list.forEach(control => {
      control.form.patchValue({indexInParentContainer: i});
      i++;
    });
  }
}
