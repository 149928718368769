import { Injectable } from '@angular/core';
import { TextboxControlComponent } from './component-models/textbox-control/textbox-control.component';
import { map, Observable, of, Subject, take, tap, zip } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ComponentPath, FormlyNodeStructureComponentMapping, FormSummaryWithNodeLevel } from './component-models/formly-controls/utilities/component-path';
import { FormlyUtilityService } from './component-models/formly-controls/formly-utility.service';
import { ControlContainerComponent } from './component-models/control-container.component';
import { ControlContainsControlsComponent } from './component-models/control-contains-controls.component';


@Injectable({
  providedIn: 'root'
})
export class DerivedWorkflowFieldService {

  creatingDerivedField: boolean = false;
  activeChipCounter: number = 1;
  componentBuilding: TextboxControlComponent;
  activeDesignViewForm: FormlyFieldConfig[];
  oldestAncestorFormSummaryDocId: string;
  textboxSelectedToAddToCalc$: Subject<TextboxControlComponent> = new Subject<TextboxControlComponent>();
  derivedWorkflowViewAfterViewInit$: Subject<void> = new Subject<void>();
  buildTagifyFromPatchedInputComponents$: Subject<void> = new Subject<void>();
  derivedWorkflowViewExit$: Subject<void> = new Subject<void>();
  textBoxesByGuid: Map<string, TextboxControlComponent> = new Map<string, TextboxControlComponent>();

  constructor(private formlyUtilityService: FormlyUtilityService) {
    this.textboxSelectedToAddToCalc$.subscribe(x => {
      this.activeChipCounter++;
    });
  }

  Reset() {
    this.creatingDerivedField = false;
    this.activeChipCounter = 1;
    this.componentBuilding = undefined;
  }

   ValidateComponentMovement(dropListIdBeingMovedTo: string, currentIndex: number,
    previousDropListId: string, previousIndex: number, componentsInMainDropZone: ControlContainerComponent[])
    : {valid: boolean, message: string|null, componentsNeededUpdate: boolean} {

      let updatedComponent = false;

    let bottom = undefined;
    let top = undefined;
    // if component is moved to a different drop zone, need to ensure that it is still a child of referenced common ancestor.
    if (previousDropListId !== dropListIdBeingMovedTo && previousDropListId !== "sidebarDragDropZone") {
      //modify components in previous drop zone.
      bottom = previousIndex;
      updatedComponent = this.UpdateModifiedCalculatedFieldsInDropList(previousDropListId, componentsInMainDropZone, bottom, top);
      bottom = currentIndex;
    } else if (previousDropListId === "sidebarDragDropZone") {
      // if component is moved from sidebar, need to check from current index -> end of drop zone.
      bottom = currentIndex;
    }
     else {
      bottom = previousIndex > currentIndex ? currentIndex : previousIndex;
      top = previousIndex > currentIndex ? previousIndex : currentIndex;
    }
    // modify components in new drop zone.
    if (dropListIdBeingMovedTo !== "sidebarDragDropZone") {
      updatedComponent =  this.UpdateModifiedCalculatedFieldsInDropList(dropListIdBeingMovedTo, componentsInMainDropZone, bottom, top) || updatedComponent;
    }
    return {valid: true, message: "Errors", componentsNeededUpdate:updatedComponent};
  }



  private UpdateModifiedCalculatedFieldsInDropList(dropListIdBeingMovedTo: string, componentsInMainDropZone: ControlContainerComponent[], bottom: number | undefined, top: number | undefined) : boolean {
    const componentDropListMappedToDropZone = this.GetComponentFromDropListId(dropListIdBeingMovedTo, componentsInMainDropZone);
    let retVal = false;
    if (bottom === undefined) {
      bottom = 0;
    }
    if (top === undefined) {
      top = componentDropListMappedToDropZone.length;
    }
    const modifiedComponents = componentDropListMappedToDropZone.slice(bottom, top+ 1);
    // All modified components need to recursively have calculated fields updated to reflect new step.
    for (const c of modifiedComponents) {
      retVal = this.RecursivelyUpdateCalculatedFieldsForComponent(c) || retVal;
    }
    if (retVal) {
        console.warn(`Triggering unsaved change detection for drop zone: ${dropListIdBeingMovedTo}`);
        this.formlyUtilityService.triggerUnsavedChangeDetection$.next(dropListIdBeingMovedTo);
    }
    return retVal;
  }

    RecursivelyUpdateCalculatedFieldsForComponent(component: ControlContainerComponent) : boolean {
      let retVal = false;
    //If component is a textbox, update it's calculated fields x generations back with step.
    if (component instanceof TextboxControlComponent) {
        if (Array.isArray(component.componentForm.value.calculatedComponentNodeMappings) && component.componentForm.value.calculatedComponentNodeMappings.length > 0) {
          component.remapAssociatedInputComponents$.next(null);
          retVal = true;
        } if (Array.isArray(component.componentForm.value.inputComponentNodeMappings) && component.componentForm.value.inputComponentNodeMappings.length > 0) {
          component.remapAssociatedCalculatedComponents$.next(null);
          retVal = true;
      }
    } else if (component instanceof ControlContainsControlsComponent) {
      //If component is a control container, recursively call this function on all children.
      for (const c of component.componentForm.value.columnFormGroups[0].cdkDropListData) {
        retVal = this.RecursivelyUpdateCalculatedFieldsForComponent(c) || retVal;
      }
    }
    return retVal;
  }


  updateComponentNodeMappings(guid: string, componentsInMainDropZone: ControlContainerComponent[], mapping : "Calculated" | "Input") : Observable<null> {

    const mutatedTextbox = this.textBoxesByGuid.get(guid);
    const patches = [];
    const ComponentNodeMappings : FormlyNodeStructureComponentMapping[] = [];
    const formlyPathToInputComponent = this.RecursivelyGetPathToPassedInGuid(componentsInMainDropZone, guid);
    const nodeMappingsToIterate = mapping === "Calculated" ? mutatedTextbox.componentForm.value.calculatedComponentNodeMappings : mutatedTextbox.componentForm.value.inputComponentNodeMappings;
    nodeMappingsToIterate.forEach(nodeMappingToIterate => {
      const pathToInputComponent = this.PathToComponent(this.activeDesignViewForm,mutatedTextbox.componentForm.controls.guid.value, this.oldestAncestorFormSummaryDocId);

      const pathToMappedComponent = this.PathToComponent(this.activeDesignViewForm,
        mapping === "Calculated" ? nodeMappingToIterate.CalculatedComponentPath.controlGuid : nodeMappingToIterate.InputComponentPath.controlGuid,
        this.oldestAncestorFormSummaryDocId);

      if (pathToInputComponent === null || pathToMappedComponent === null) {
        patches.push(of(null));
      } else {
        const nodeStructureRelationship = mapping === "Calculated" ? this.BuildFormlyNodeStructureMapping(pathToMappedComponent, pathToInputComponent) :
          this.BuildFormlyNodeStructureMapping(pathToInputComponent, pathToMappedComponent);

        const mappedComponetTextboxControl = this.GetComponentFromCalculatedComponentPath(componentsInMainDropZone, formlyPathToInputComponent.calculatedPath as string, nodeStructureRelationship, mapping);
        const mappedComponentNodeMappings = mapping === "Calculated" ?
          (mappedComponetTextboxControl.componentForm.controls.inputComponentNodeMappings.value as Array<FormlyNodeStructureComponentMapping>).slice() :
          (mappedComponetTextboxControl.componentForm.controls.calculatedComponentNodeMappings.value as Array<FormlyNodeStructureComponentMapping>).slice();

        const spliceIndex = mappedComponentNodeMappings.findIndex(y => mapping === "Calculated" ? y.InputComponentPath.controlGuid === mutatedTextbox.componentForm.controls.guid.value :
          y.CalculatedComponentPath.controlGuid === mutatedTextbox.componentForm.controls.guid.value);
        if (spliceIndex > -1) {
          mappedComponentNodeMappings.splice(spliceIndex,1);
        }
        mappedComponentNodeMappings.push(nodeStructureRelationship);
        if (mapping === "Calculated") {
          patches.push(of(null).pipe(
            tap(() => mappedComponetTextboxControl.componentForm.patchValue({inputComponentNodeMappings: mappedComponentNodeMappings}))
          ));
        } else {
          patches.push(of(null).pipe(
            tap(() => mappedComponetTextboxControl.componentForm.patchValue({calculatedComponentNodeMappings: mappedComponentNodeMappings}))
          ));
        }
        ComponentNodeMappings.push(nodeStructureRelationship);
    }
    });

    if (mapping === "Calculated") {
      patches.push(of(null).pipe(
        tap(() => mutatedTextbox.componentForm.patchValue({calculatedComponentNodeMappings: ComponentNodeMappings}))
      ));
    } else {
      patches.push(of(null).pipe(
        tap(() => mutatedTextbox.componentForm.patchValue({inputComponentNodeMappings: ComponentNodeMappings}))
      ));
    }

    return zip(...patches).pipe(
      map(() => null),
      take(1)
    );
  }

  BuildFormlyNodeStructureMapping(calculatedPath: ComponentPath, inputPath: ComponentPath) : FormlyNodeStructureComponentMapping {
    const retVal : FormlyNodeStructureComponentMapping = new FormlyNodeStructureComponentMapping({CalculatedComponentPath: {...calculatedPath}, InputComponentPath: {...inputPath}});
    // Find the first common formSummaryDocId ancestor.
    for (const formSummaryDocId of calculatedPath.formSummariesWithNodeLevel.sort((a, b) => a.nodeLevel - b.nodeLevel).map(q => q.formSummaryDocId))
    {
      if (inputPath && inputPath.formSummariesWithNodeLevel.some(x => x.formSummaryDocId === formSummaryDocId))
      {
        retVal.firstCommonFormFirestoreSummaryDocId = formSummaryDocId;
        break;
      }
    }
    this.BuildPathToComponentFromYoungestAncestor(retVal.InputComponentPath, retVal.firstCommonFormFirestoreSummaryDocId);
    this.BuildPathToComponentFromYoungestAncestor(retVal.CalculatedComponentPath, retVal.firstCommonFormFirestoreSummaryDocId);
    return retVal;
  }

  BuildPathToComponentFromYoungestAncestor(c: ComponentPath, firstCommonFormFirestoreSummaryDocId: string) : void {
    // find formSum: that maps to yougest ancestor and trim off everything before that in arbitrary path plus the path specific to that Form Summary.
    let index = c.pathToComponentFromArbitraryCommonAncestor.lastIndexOf(`formSum:${firstCommonFormFirestoreSummaryDocId}.fieldGroup[0]`)
    if (index === -1) {
      index = c.pathToComponentFromArbitraryCommonAncestor.lastIndexOf(`formSum:${firstCommonFormFirestoreSummaryDocId}`)
      + `formSum:${firstCommonFormFirestoreSummaryDocId}`.length;
    } else {
      index += `formSum:${firstCommonFormFirestoreSummaryDocId}.fieldGroup[0]`.length;
    }
    c.pathToComponentFromFirstCommonFormSummaryAncestor = c.pathToComponentFromArbitraryCommonAncestor.substring(index);
    if (c.pathToComponentFromFirstCommonFormSummaryAncestor.startsWith("-")) {
      c.pathToComponentFromFirstCommonFormSummaryAncestor = c.pathToComponentFromFirstCommonFormSummaryAncestor.substring(1);
    }
    delete c.pathToComponentFromArbitraryCommonAncestor;
    delete c.formSummariesWithNodeLevel;
  }

  GetInputComponentsAssociatedWithCalculation(componentsInMainDropZone: ControlContainerComponent[], calculatedComponent : TextboxControlComponent, ignoreErroredPaths: boolean = false) : TextboxControlComponent[]
    {
    const retVal: TextboxControlComponent[] = [];
    //get path from root to passed in component.
    const pathToCalculatedComponent = this.RecursivelyGetPathToPassedInGuid(componentsInMainDropZone, calculatedComponent.componentForm.value.guid);
    const regEx = /<<([^>>]+)>>/g;
    if (calculatedComponent.componentForm.get("derivedCalculation")?.value) {
      calculatedComponent.componentForm.get("derivedCalculation").value.match(regEx).forEach((match) => {
        const textBoxGuid = match.replace("<<","").replace(">>","");
        try {
          const inputComponentMapping = calculatedComponent.componentForm.value.inputComponentNodeMappings.find(x => x.InputComponentPath.controlGuid  === textBoxGuid);
          const componentTextBoxControl = this.GetComponentFromCalculatedComponentPath(componentsInMainDropZone, pathToCalculatedComponent.calculatedPath as string, inputComponentMapping);
          retVal.push(componentTextBoxControl);
        } catch (e) {
          if (!ignoreErroredPaths) {
            throw e;
          }
        }
      });
    }
    return retVal;
  }

  GetComponentFromDropListId(dropListId: string, components: ControlContainerComponent[]| ControlContainerComponent) : ControlContainerComponent[] {

    if (dropListId === "mainDragDropZone") {
      return components as ControlContainerComponent[];
    }
    if (Array.isArray(components)) {
      for (var component of components) {
        const retVal = this.GetComponentFromDropListId(dropListId, component);
        if (retVal !== null) {
          return retVal;
        }
       };
    } else {
        if ((components as any).id === dropListId) {
        return ((components as any).cdkDropListData) as ControlContainerComponent[];
      } else {
        if (Array.isArray((components as any).columnFormGroups?.value)) {
          for (var c of (components as any).columnFormGroups.value) {
            const retVal = this.GetComponentFromDropListId(dropListId, c);
            if (retVal !== null) {
              return retVal;
            }
          }
        } else {
          if ((components as any).cdkDropListData) {
            for (var c of (components as any).cdkDropListData) {
              const retVal = this.GetComponentFromDropListId(dropListId, c);
              if (retVal !== null) {
                return retVal;
              }
            }
          }
        }
      }
    }
    return null;
  }

  getComponentWithGuid(guid: string, components: ControlContainerComponent[]| ControlContainerComponent) : TextboxControlComponent {
    if (Array.isArray(components)) {
      for (var component of components) {
        const retVal = this.getComponentWithGuid(guid, component);
        if (retVal !== null) {
          return retVal;
        }
       };
    } else {
      if ((components as any).columnFormGroup?.value?.columnFormGroups[0]?.cdkDropListData) {
        for (let section of (components as any).columnFormGroup.value.columnFormGroups) {
          for (var c of section.cdkDropListData) {
            const retVal = this.getComponentWithGuid(guid, c);
            if (retVal !== null) {
              return retVal;
            }
          }
        }
      } else {
        if (components.componentForm.get("guid")?.value === guid) {
          return components as TextboxControlComponent;
        } else {
          return null;
        }
      }
    }
    return null;
  }

  GetComponentFromCalculatedComponentPath(componentsInMainDropZone: ControlContainerComponent[], calculatedComponentPath: string,
    inputComponentNodeMapping: FormlyNodeStructureComponentMapping, pathDesired: "Input"|"Calculated" = "Input") : TextboxControlComponent {

    let pathToInputComponent = pathDesired === "Input" ? inputComponentNodeMapping.InputComponentPath.pathToComponentFromFirstCommonFormSummaryAncestor :
    inputComponentNodeMapping.CalculatedComponentPath.pathToComponentFromFirstCommonFormSummaryAncestor;

    const expectedGuid = pathDesired === "Input" ? inputComponentNodeMapping.InputComponentPath.controlGuid : inputComponentNodeMapping.CalculatedComponentPath.controlGuid;

    //20241219
    // console.log(pathToInputComponent);
    // console.log(pathDesired);
    // console.log(calculatedComponentPath);
    pathToInputComponent = pathToInputComponent.replace(/-formSum:[^[]+\.fieldGroup/g,'.fieldGroup');
        // replace formSum:ABCDEF[ with [
          pathToInputComponent = pathToInputComponent.replace(/formSum:[^[]+\[/g,'[');

    let commonPathToYoungestCommonAncestor = "";
    if (inputComponentNodeMapping.firstCommonFormFirestoreSummaryDocId !== this.formlyUtilityService.activeFormModelFirestore.formFirestore.formSummary.DocId() ) {
      //get path from root until first match against youngest formFirestoreSummary in calculatedComponentPath
      let index = calculatedComponentPath.indexOf(`-formSum:${inputComponentNodeMapping.firstCommonFormFirestoreSummaryDocId}`);
      if (index > -1) {
        commonPathToYoungestCommonAncestor = calculatedComponentPath.substring(0,index);
        commonPathToYoungestCommonAncestor = commonPathToYoungestCommonAncestor.replace(/-formSum:[^[]+\.componentForm/g,'.componentForm');
      }
      // remove first [XX] value present in calculatedComponentPath with .columnFormGroups.controls[0].value.cdkDropListData[XX]
      const match = pathToInputComponent.match(/\[\d+\]/);
      pathToInputComponent = pathToInputComponent.replace(/\[\d+\]/,`.columnFormGroups.controls[0].value.cdkDropListData${match[0]}`);
    }
    //Replace each pair of fieldGroup[x].fieldgroup[y] with columnFormGroups[x].cdkDropListData[y]
    const re = /fieldGroup\[(\d+)\]\.fieldGroup\[(\d+)\]/g;
    let match;
    while ((match = re.exec(pathToInputComponent)) != null) {
      pathToInputComponent = pathToInputComponent.substring(0,match.index) + `columnFormGroups.controls[${match[1]}].value.cdkDropListData[${match[2]}]` +
        pathToInputComponent.substring(match.index + match[0].length);
    };
    // if text starts with .fieldGroup, remove it.
    if (pathToInputComponent.startsWith(".fieldGroup")) {
      pathToInputComponent = pathToInputComponent.substring(".fieldGroup".length);
    }
    const endsWithfieldGroup = pathToInputComponent.lastIndexOf("fieldGroup");
    if (endsWithfieldGroup !== -1) {
      pathToInputComponent = pathToInputComponent.substring(0,endsWithfieldGroup);
    }

    const fullPath = commonPathToYoungestCommonAncestor + pathToInputComponent;
    //assign input component to member present in calculated path.
    const pathArray = fullPath.split(/\.|\[|\]/).filter(x => x !== "");
    //20241219
    // console.log(fullPath);
    // console.log(pathArray);
      const componentTextBoxControl = pathArray.reduce(function(result,currentKey) {
        let res : any;
        try {
          res = result[currentKey];
        } catch (e) {
          console.log(expectedGuid);
          console.log(componentsInMainDropZone);
          console.error(e);
          throw e;
        }
        return result[currentKey]
      }, componentsInMainDropZone);
      if (componentTextBoxControl.componentForm.value.guid !== expectedGuid) {
        console.log("ERROR ERROR ERROR");
        console.log(`Expected ${expectedGuid} but got ${componentTextBoxControl.componentForm.value.guid}`);
        console.log("ERROR ERROR ERROR");
      }
      return componentTextBoxControl;
  }

  RecursivelyGetPathToPassedInGuid(components: ControlContainerComponent[] | ControlContainerComponent, guid : string) :
    {calculatedPath: string | boolean, formSummariesWithNodeLevel:FormSummaryWithNodeLevel[]} {
    if (components instanceof ControlContainerComponent && (components as ControlContainerComponent).componentForm.get("guid")?.value === guid) {
      return {calculatedPath: true, formSummariesWithNodeLevel: []};
    } else if (Array.isArray(components)) {
        let indexInArray = 0;
        for (const component of (components as Array<ControlContainerComponent>)) {
          let retVal = this.RecursivelyGetPathToPassedInGuid(component, guid);
          if (retVal.calculatedPath !== false) {
              if (typeof retVal.calculatedPath === 'string') {
                  retVal.calculatedPath = `[${indexInArray}]${retVal.calculatedPath}`;
              }
              return retVal;
            }
          indexInArray++;
        }
        return {calculatedPath: false, formSummariesWithNodeLevel: []};
      }
      else if ((components as ControlContainerComponent) instanceof(ControlContainsControlsComponent)) {
        let indexInOuterArray = 0;
        let retVal : {calculatedPath: string | boolean, formSummariesWithNodeLevel:FormSummaryWithNodeLevel[]} = {calculatedPath: false, formSummariesWithNodeLevel: []};
        for (const component of (components.componentForm.value.columnFormGroups)) {
          let indexInInnerArray = 0;
          for (const control of component.cdkDropListData) {
            retVal = this.RecursivelyGetPathToPassedInGuid(control, guid);
            if (retVal.calculatedPath !== false) {
              if (typeof retVal.calculatedPath === 'string') {
                retVal.calculatedPath = `.cdkDropListData[${indexInInnerArray}]` + retVal.calculatedPath;
                if (component.componentForm && component.componentForm?.get("formFirestoreSummaryDocId")?.value) {
                  retVal.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: component.componentForm.get("formFirestoreSummaryDocId").value, nodeLevel: retVal.formSummariesWithNodeLevel.length}));
                  retVal.calculatedPath += `-formSum:${component.componentForm.get("formFirestoreSummaryDocId").value}`;
                }
                retVal.calculatedPath = `.componentForm.value.columnFormGroups[${indexInOuterArray}]` + retVal.calculatedPath;
                break;
              } else {
                  retVal.calculatedPath = `.columnFormGroups.value[${indexInOuterArray}]`
                  if (component.componentForm?.get("formFirestoreSummaryDocId")?.value) {
                    retVal.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: component.componentForm.get("formFirestoreSummaryDocId").value, nodeLevel: retVal.formSummariesWithNodeLevel.length}));
                    retVal.calculatedPath+= `-formSum:${component.componentForm.get("formFirestoreSummaryDocId").value}`;
                  }
                break;
              }
            }
            indexInInnerArray++;
          }
          if (retVal.calculatedPath !== false) {
            break;
          }
        }
        if (retVal.calculatedPath && components.componentForm?.get("formFirestoreSummaryDocId")?.value) {
          retVal.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: components.componentForm.get("formFirestoreSummaryDocId").value, nodeLevel: retVal.formSummariesWithNodeLevel.length}));
          retVal.calculatedPath = `-formSum:${components.componentForm.get("formFirestoreSummaryDocId").value}` + retVal.calculatedPath;
        }
        return retVal;
        } else {
        return {calculatedPath: false, formSummariesWithNodeLevel: []};
      }
  }

  PathToComponent(formly: FormlyFieldConfig[], guid: string, oldestFormSummaryDocId: string | null = null) : ComponentPath {

    const p = this.PathToGuid(formly, guid);
    console.log(p);
    if (p.calculatedPath===false) {
      return null;
    } else {
      if (oldestFormSummaryDocId !== null) {
        p.calculatedPath = `formSum:${oldestFormSummaryDocId}${p.calculatedPath}`;
        p.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: oldestFormSummaryDocId, nodeLevel: p.formSummariesWithNodeLevel.length}));
      }
    }
    return new ComponentPath({pathToComponentFromArbitraryCommonAncestor: p.calculatedPath as string, formSummariesWithNodeLevel: p.formSummariesWithNodeLevel, controlGuid: guid});
    }

  PathToGuid(formly: FormlyFieldConfig[] | FormlyFieldConfig, guid: string) : {calculatedPath: string | boolean, formSummariesWithNodeLevel:FormSummaryWithNodeLevel[]} {
    if (formly === undefined) {
      console.warn("Formly is undefined");
      return {calculatedPath: false, formSummariesWithNodeLevel: []};
    }
    if (formly['props'] && formly['props']['guid'] === guid) {
      return {calculatedPath: true, formSummariesWithNodeLevel: []};
    } else if (Array.isArray(formly)) {
        let indexInArray = 0;
        for (const field of (formly as Array<FormlyFieldConfig>)) {
          const path = this.PathToGuid(field, guid);
          if (path.calculatedPath !== false) {
            if (field.props.formFirestoreSummaryDocId) {
              path.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: field.props.formFirestoreSummaryDocId, nodeLevel: path.formSummariesWithNodeLevel.length}));
              path.calculatedPath = `[${indexInArray}]-formSum:${field.props.formFirestoreSummaryDocId}${path.calculatedPath}`;
            } else {
              path.calculatedPath = `[${indexInArray}]${path.calculatedPath}`;
            }
            return path;
          }
          indexInArray++;
        }
        return {calculatedPath: false, formSummariesWithNodeLevel: []};
      }
      else if (Array.isArray(formly['fieldGroup'])) {
        let indexInArray = 0;
        for (const field of (formly['fieldGroup'] as Array<FormlyFieldConfig>)) {
          const path = this.PathToGuid(field, guid);
          if (path.calculatedPath !== false) {
            if (typeof path.calculatedPath === 'string') {
              if (field.props.formFirestoreSummaryDocId) {
                path.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: field.props.formFirestoreSummaryDocId, nodeLevel: path.formSummariesWithNodeLevel.length}));
                path.calculatedPath = `.fieldGroup[${indexInArray}]-formSum:${field.props.formFirestoreSummaryDocId}${path.calculatedPath}`;
              } else {
                path.calculatedPath = `.fieldGroup[${indexInArray}]${path.calculatedPath}`;
              }
              return path;
            } else {
              if (field.props.formFirestoreSummaryDocId) {
                path.formSummariesWithNodeLevel.push(new FormSummaryWithNodeLevel({formSummaryDocId: field.props.formFirestoreSummaryDocId, nodeLevel: path.formSummariesWithNodeLevel.length}));
                path.calculatedPath = `.fieldGroup[${indexInArray}]-formSum:${field.props.formFirestoreSummaryDocId}`;
              } else {
                path.calculatedPath = `.fieldGroup[${indexInArray}]`;
              }
              return path;
              }
            }
            indexInArray++;
          }
        } else {
        return {calculatedPath: false, formSummariesWithNodeLevel: []};
      }
      return {calculatedPath: false, formSummariesWithNodeLevel: []};
    }

}
