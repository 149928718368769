import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ComponentResolutionResult } from '../../retrieve-nested-components.service';

@Component({
  selector: 'app-user-delation-confirmation-modal',
  templateUrl: './user-delation-confirmation-modal.component.html',
  styleUrls: ['./user-delation-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDelationConfirmationModalComponent {

displayedColumns: string[] = ['Input Field', 'Section', 'Derived Field', 'Warning', 'Price Book Entry','Price Book Category'];
dataSource: ReplaySubject<ComponentResolutionResult[]> = new ReplaySubject<ComponentResolutionResult[]>(1);

constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<UserDelationConfirmationModalComponent>){
  this.dataSource.next(data.dataSource);
}

proceed() {
  this.dialogRef.close(true);
}

getPriceBookEntryTitle(c: ComponentResolutionResult) {
  return c.PriceBookEntries.length === 0 ? "" : c.PriceBookEntries[0].title;
}

getPriceBookCategoryTitle(c: ComponentResolutionResult) {
  return c.PriceBookEntries.length === 0 ? "" : c.PriceBookEntries[0].priceBookCatagory.name;
}

cancel() {
  this.dialogRef.close(false);
}

}
