import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyField, FormlyFieldConfig } from '@ngx-formly/core';
import {  merge, of, Subject } from 'rxjs';
import {  debounceTime, delay, distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirestorageService } from '../../../angular-firestorage.service';
import {  ControlContainerComponent } from '../control-container.component';

@Component({
  selector: 'app-image-control',
  templateUrl: './image-control.component.html',
  styleUrls: ['./image-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageControlComponent extends ControlContainerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('imageCanvas') myCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') canvasContainer: ElementRef;
  @ViewChild('rootContainer') rootContainer: ElementRef;

  public imageContext: CanvasRenderingContext2D;
  image = new Image();
  lockAspectRatio: boolean = false;
  hideResize: boolean = true;
  resizing: boolean = false;
  rzHandles: string = "se";

  patchControlComponentsToFormlyFields(): void {

    const obs = this.patchControlComponentsToFormlyFieldsCommon();

    obs.push(this.componentForm.get("imageSrc").valueChanges.pipe(
      tap(x => (this.fields[0] as FormlyFieldConfig).formControl.patchValue({imageSrc: x})),
      tap(() => (this.fields[0] as FormlyFieldConfig).props.changeDetect.next())))

    obs.push(this.componentForm.get("imageWidthPixels").valueChanges.pipe(
      startWith(this.componentForm.get("imageWidthPixels").value),
      tap(x => {
        (this.fields[0] as FormlyFieldConfig).props["imageWidthPixels"] = x;
        (this.fields[0] as FormlyFieldConfig).formControl.patchValue({imageWidthPixels: x});
      }
      )));

    obs.push(this.componentForm.get("imageHeightPixels").valueChanges.pipe(
      startWith(this.componentForm.get("imageHeightPixels").value),
      tap(x => {
        (this.fields[0] as FormlyFieldConfig).props["imageHeightPixels"] = x + 10;
      })));

    obs.push(this.componentForm.get("imageUrl").valueChanges.pipe(
      startWith(this.componentForm.get("imageUrl").value),
      tap(x => console.log(x)),
      tap(x => (this.fields[0] as FormlyFieldConfig).props["imageUrl"] = x),
      tap(() => (this.fields[0] as FormlyFieldConfig).key = Math.random().toString(36).substring(0, 9)),
      tap(() => (this.fields[0] as FormlyFieldConfig).props.changeDetect.next())));

    merge(...obs).pipe(
      debounceTime(1),
      tap(() => {
        (this.fields[0] as FormlyFieldConfig).props.changeDetect.next();
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig | FormlyFieldConfig[] {
    const retVal = this.toFormlyFieldConfig() as FormlyFieldConfig;
     const {formControl, ...newObj} = retVal;
     const {changeDetect,...temps} = retVal.props;
     newObj.props = temps;
     newObj.wrappers= [];
    return newObj;
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig ): void {
    super.patchCommonFieldsToForm(formlyConfig);
    this.componentForm.patchValue({imageHeightPixels: formlyConfig.props["imageHeightPixels"]});
    this.componentForm.patchValue({imageWidthPixels: formlyConfig.props["imageWidthPixels"]});
    this.componentForm.patchValue({imageUrl: formlyConfig.props["imageUrl"]});
    this.componentForm.patchValue({loadingFromDatabase: true});
  }

  initilizeFormlyFieldConfig(): void {
    this.fields =  [{
      type: 'formlyImage',
      key: `${this.perInstance}-image`,
      props: {
        imageUrl: "",
        imageWidthPixels: this.componentForm.get("imageWidthPixels").value,
        imageHeightPixels: this.componentForm.get("imageHeightPixels").value,
        imageSrc: "",
        resizing: false,
        changeDetect: new Subject<any>(),
      },
      wrappers: ["change-detect"],
      formControl: this.fb.group ({
        imageSrc: this.componentForm.get("imageSrc").value,
        imageWidthPixels: this.componentForm.get("imageWidthPixels").value,
      }
      )
    }];
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    const retVal =  (this.fields[0] as FormlyFieldConfig);
    return retVal;
  }

  // modified as needed from here:  https://riptutorial.com/angular2/example/19866/image-picker-with-preview

  // Emit an event when a file has been picked. Here we return the file itself
  //  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();





   initilizeFormGroup(): UntypedFormGroup{
    const retVal = this.createDefaultControlContainerFormGroup("Image");
    retVal.patchValue({
      icon: "insert_photo",
      iconColor: "#156edc",
      controlComponentFormGroup: this.createImageFormGroup()
    });
    return retVal;
  }

  constructor( fb: UntypedFormBuilder, private afStorage: AngularFirestorageService, private http: HttpClient) {
    super(ImageControlComponent,fb);
    this.form = this.initilizeFormGroup();
    this.initilizeFormlyFieldConfig();
  }

  suscribeToChangesInWidth() {
    this.form.controls["percentTotalWidth"].valueChanges.pipe(
      filter(() => !this.resizing),
      map(() => this.rootContainer.nativeElement.offsetWidth),
      tap(width => {
        const height = this.image.height * width / this.image.width;
        this.reRenderImage(width, height);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.form.controls["percentTotalWidth"].valueChanges.pipe(
      filter(() => this.resizing),
      map(() => this.rootContainer.nativeElement.offsetWidth),
      tap(width => {
        const height = this.image.height * width / this.image.width;
        this.reRenderImage(width, height,false);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.NumberComponents++;
    this.patchControlComponentsToFormlyFields();
    this.suscribeToChangesInWidth();

    this.imageContext = this.myCanvas.nativeElement.getContext('2d');

    this.form.get("resizing").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => {
        this.resizing = x;
        (this.fields[0] as FormlyFieldConfig).props.resizing = x;
      }
      ),
      tap(() => (this.fields[0] as FormlyFieldConfig).props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    const fullWidth = this.rootContainer.nativeElement.offsetWidth;
    // Must delay a cycle to patch in value is it is referenced in view.
    of(fullWidth).pipe(
      delay(1),
      take(1)
    ).subscribe(fullWidth => this.componentForm.patchValue({"imageMaxWidthPixels": fullWidth}));


    this.image.onload = () => {
      let width = this.widthFullSizedOrScaledToContainer();
      if (this.componentForm.get("loadingFromDatabase").value) {
        width = this.componentForm.get("imageWidthPixels").value;
        this.componentForm.patchValue({loadingFromDatabase: false});
      }
      this.reRenderImage(width,this.image.height * width / this.image.width);
    };

    // If imageUrl is populated, then we are loading an existing imageControl, so we need
    // to retrieve the image.
    if (this.componentForm.get("imageUrl").value !== "") {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.hideResize=false;
       this.image.src = e.target.result;
        this.componentForm.patchValue({imageSrc: this.image.src});
      };

      this.http.get(this.componentForm.get("imageUrl").value.downloadUrl, { responseType: 'blob'}).pipe(
        tap(x => reader.readAsDataURL(x)),
        take(1),
        ).subscribe();
    }
  }

  // If the input has changed(file picked) we project the file into the img previewer
  updateSource($event: Event) {
    this.lockAspectRatio = false;
    this.hideResize=true;
      this.projectImage($event.target['files'][0]);
  }


  projectImage(file: File) {
      let reader = new FileReader;

      // Save file.
      this.afStorage.uploadFilePopulateDownloadUrl(file,file.name);
      this.afStorage.downloadURL$.pipe(
        filter(x => x.fileName === file.name),
        tap(x => this.componentForm.patchValue({imageUrl: x})),
        tap(x => console.log(x)),
        take(1)
      ).subscribe();

      reader.onload = (e: any) => {
          this.hideResize=false;
          console.log(e);
         this.image.src = e.target.result;
          this.componentForm.patchValue({imageSrc: this.image.src});
      };
      // This will process our file and get it's attributes/data
      reader.readAsDataURL(file);
  }



  widthFullSizedOrScaledToContainer() : number {
    const containerWithGutter = this.rootContainer.nativeElement.offsetWidth - 15;
      // Width is the lesser of the maximum supported by control's parent container, or the provided images width.
    return containerWithGutter > this.image.width ?  this.image.width : containerWithGutter;
  }

  reRenderImage(width: number, height: number, patchToForm: boolean = true) {
    this.myCanvas.nativeElement.width = width;
    this.myCanvas.nativeElement.height = height;
    this.imageContext.drawImage(this.image,0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
    if (patchToForm) {
    this.componentForm.patchValue({imageHeightPixels: this.myCanvas.nativeElement.height, imageWidthPixels: this.myCanvas.nativeElement.width});
    (this.fields[0] as FormlyFieldConfig).props.changeDetect.next();
    }
  }

  stopResize($event:any) {
    this.resizing=false;
    // Set canvas to updated dimensions, and re-render image.
    this.reRenderImage($event.size.width, $event.size.height);
  }

  startResize($event:any) {
    // If aspect ratio is locked before this then ngResize directive fails ( as it preserves initial aspect ratio
    // rather buggily failing to update aspect ratio on client resize of element)
    this.lockAspectRatio = true;
    this.resizing=true;
  }

  createImageFormGroup() {
    return this.fb.group({
      "imageSrc" : "",
      "imageMaxWidthPixels": 767,
      "imageHeightPixels": 100,
      "imageWidthPixels" : 100,
      "imageUrl" : "",
      "loadingFromDatabase" : false,
    })
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }
}
