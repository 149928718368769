import { FirestoreBackend } from './retrieve-from-firestore';
import { RetrieveFirestoreProperties } from './retrieve-firestore-properties';
import { StateChangeStoreService } from './state-change-store.service';
import { Observable, zip, of, combineLatest, merge, throwError, Subject, ReplaySubject } from 'rxjs';
import { map, mergeMap, switchMap, filter, take, tap, share, debounceTime, catchError, takeUntil,  finalize } from 'rxjs/operators';
import { deepDiffMapper } from '../../../../web-app/src/app/retrieve-from-firestore-diff-mapper';
import { AuthenticationService } from '../../util/authentication.service';
import { QueryConstraint } from 'firebase/firestore';
import {cloneDeep} from 'lodash-es/';
import { LoggingService } from '../logging/logging.service';
import { NgxLoggerLevel } from 'ngx-logger';
import { inject } from '@angular/core';

export abstract class DatabaseStoreService<T extends RetrieveFirestoreProperties> {

destroyingComponent$: Subject<any>;
cachedLoadAll: ReplaySubject<T[]>= null;
localObjectCache: Map<string,T> = new Map<string,T>();

private loggingService: LoggingService = inject(LoggingService);

constructor(protected fs: FirestoreBackend<T>, protected store: StateChangeStoreService<T>,
  protected authenticationService: AuthenticationService,
  protected compositionServices?: Map<string, {associatedDocumentId: string, compositionStoreService:
    DatabaseStoreService<RetrieveFirestoreProperties>}>) {
      if (compositionServices !== undefined) {
        for (const [key, value] of compositionServices) {
            fs.compositionServices.set(key, {associatedDocumentId: value.associatedDocumentId,
                                             firestoreBackEnd: value.compositionStoreService.fs});
            }
      }
      this.destroyingComponent$ = FirestoreBackend.destroyingComponent$;
  }

  public mergeUpdate(obj: T, fields: string[], b?: string, nextLocallySetValue: boolean = false) : Observable<T> {
    return this.fs.mergeUpdate(obj, fields, b, nextLocallySetValue).pipe(
      map(() => obj)
    );
  }

  public trackChange(changeType: string) {
    switch (changeType) {
      case "created":
      case "updated":
      case "deleted": {
        return true;
        break;
      }
        default: {
          return false;
        }
    }
  }

pushToLocalCache(obj: T) {
  this.localObjectCache.set(obj.DocId(), obj);
}

getFromLocalCache(docId: string): T {
  const retVal = this.localObjectCache.get(docId);
  this.localObjectCache.delete(docId);
  return retVal;
}

updatePrincipleObjectRequired(obj: T) : boolean {

  obj = this.performAnyCalculationsNeededToStoreObject(obj);

  const cachedValue = this.getPurePreviousEmissionCachedValue(obj.DocId());
  if (cachedValue === undefined) {
    if (FirestoreBackend.verboseFirestoreObjectCRUDLogging) {
      console.warn(`UNCACHED VALUE ${obj.DocId()}`, obj);
    }
    return true;
  }

  const diff = deepDiffMapper.map(obj, cachedValue, obj.retrievefirestoreIgnoreDiffTrackingMembers());
    if ( diff.type  !== "unchanged") {
      if (diff.type === "created") {
        console.warn("Created");
        return true;
      }

      for ( const k in diff.data) {
        if ( diff.data[k] !== null && diff.data[k] !== undefined && this.trackChange(diff.data[k].type) && !obj.retrievefirestoreIgnoreDiffTrackingMembers().includes(k)) {
          // If given field is a composed object, then principal object update shouldn't be set b/c of diffs it contains.
          if(!obj.retrievefirestoreCompositionMemberNames().includes(k)) {
            // console.warn(diff,k, obj);
            // console.warn(JSON.stringify(obj[k]));
            // console.warn(JSON.stringify(cachedValue[k]));
            return true;
        }
      }
    }
    // Need to check if all compositional objects have same docIds.
    const cachedSet = new Map<string,Set<string>>();
    const currSet = new Map<string,Set<string>>();
    obj.retrievefirestoreCompositionMemberNames().forEach(composedElement =>
      {
        if (Array.isArray(cachedValue[composedElement])) {
          if (this.compositionServices.get(composedElement) !== undefined) {
            const composorDocumentMemberDocIdName: string  = this.compositionServices.get(composedElement).associatedDocumentId;
            ( cachedValue[composorDocumentMemberDocIdName] as Array<string>).forEach(val => {
            if (cachedSet[composedElement] === undefined) {
              cachedSet.set(composedElement,new Set<string>());
            }
            cachedSet.get(composedElement).add(val)
          });
            (obj[composedElement] as Array<RetrieveFirestoreProperties>).forEach(val => {
              if (currSet[composedElement] === undefined) {
                currSet.set(composedElement,new Set<string>());
              }
              currSet.get(composedElement).add(val.DocId())
          });
          }
        } else {
          if (cachedValue[composedElement] !== undefined && cachedValue[composedElement] !== null) {
            if (cachedSet[composedElement] === undefined) {
              cachedSet.set(composedElement,new Set<string>());
            }
            cachedSet.get(composedElement).add(cachedValue[composedElement].DocId());
          }
          if (obj[composedElement] !== undefined && obj[composedElement] !== null) {
            if (currSet[composedElement] === undefined) {
              currSet.set(composedElement,new Set<string>());
            }
            currSet.get(composedElement).add(obj[composedElement].DocId());
          }
        }
      });
      //update is required if a compositional object was added, or changed.
      if (currSet.size !== cachedSet.size) {
        return true;
      } else {
        for (let key of currSet.keys()) {
          if (cachedSet.get(key) === undefined || currSet.get(key).size !== cachedSet.get(key).size) {
            return true;
          }
          for (let val of (Array.from(currSet.get(key)))) {
            if (!cachedSet.get(key).has(val)) {
              return true;
            }
          };
        }
        return false;
      }
  } else {
    return false;
  }
}

protected performAnyCalculationsNeededToStoreObject(obj: T) : T {
  return obj;
}

updateSingleComposedElementUpdateRequired(composorDocumentMemberName: string, obj: T) : boolean {
  let updateReq: boolean =  false;

  if (obj !== null && obj !== undefined ) {

    //tippy
    if (obj.updateRequired || obj.updatePrincipalRequired) {
      return true;
    }

    obj.updatePrincipalRequired = this.compositionServices.get(composorDocumentMemberName)
    .compositionStoreService.updatePrincipleObjectRequired(obj) || obj.createdObject;

    if (obj.updatePrincipalRequired && FirestoreBackend.verboseFirestoreObjectCRUDLogging) {
      console.warn(obj);
    }

    const cachedValue = this.compositionServices.get(composorDocumentMemberName).compositionStoreService.getPurePreviousEmissionCachedValue(obj.DocId())

//  const cachedValue = this.compositionServices.get(composorDocumentMemberName).compositionStoreService.getPreviousEmissionCachedValue(obj.DocId());
//   if (cachedValue === undefined) {
//     return true;
//   }
    const diff = deepDiffMapper.map(cachedValue,obj, obj.retrievefirestoreIgnoreDiffTrackingMembers());
    if ( diff.type  === "unchanged") {
          updateReq = false;
    } else {
      if (diff.type === "created") {
        console.log(obj);
        updateReq = true;
      }
      for ( const k in diff.data) {
        if ( diff.data[k] !== null && diff.data[k] !== undefined && this.trackChange(diff.data[k].type) && !obj.retrievefirestoreIgnoreDiffTrackingMembers().includes(k)
              && !obj.retrieveFirestoreDenormalizedMemberNames().map(y => this.fs.retrieveDenormalizedName(y)).includes(k)) {
          // If given field is an object, update only needed if composed element requires it.

          if(obj.retrievefirestoreCompositionMemberNames().includes(k)) {
            let updateBecauseOfComposed: boolean = false;

            if (Array.isArray(obj[k])) {
              obj[k].forEach(element => {
                if (!updateBecauseOfComposed) {
                  updateBecauseOfComposed = this.compositionServices.get(composorDocumentMemberName).compositionStoreService.updateSingleComposedElementUpdateRequired(k, element) || obj.updateRequired;
                }
              });
            } else {
              updateBecauseOfComposed = this.compositionServices.get(composorDocumentMemberName).compositionStoreService.updateSingleComposedElementUpdateRequired(k, obj[k]);
            }
            if (updateBecauseOfComposed === true) {
              updateReq = true;
              break;
            }
          } else {
          updateReq = true;
          break;
          }
        }
      }
    }
    if (updateReq) {
    console.log(obj);
    if (diff.type !== "created") {
    Object.keys(diff.data).forEach(key => {
      if (diff.data[key] !== null && diff.data[key].type !== 'unchanged' && !obj.retrievefirestoreIgnoreDiffTrackingMembers().includes(key)) {
        console.warn(key, diff.data[key], "Composed Update");
        // console.warn(obj,cachedValue);
        }
      });
    } else {
      console.warn("Object Fresh Created from composed update", this.compositionServices.get(composorDocumentMemberName).compositionStoreService.store.storeName);
    }
      obj.updateRequired = true;
      obj.updatedThisCycle = true;
    } else {
      obj.updateRequired = false;
      if (obj.retrievefirestoreCompositionMemberNames().length > 0) {
        obj.retrievefirestoreCompositionMemberNames().forEach(composed => {
           if (obj[composed] !== undefined && obj[composed] !== null && !Array.isArray(obj[composed])) {
            obj[composed].updateRequired = false;
           }
          })
      }
    }
    //Needed to properly recurse down chain to determine if update is required.
    this.compositionServices.get(composorDocumentMemberName).compositionStoreService.populateIfSubObservablesNeedUpdating(obj);
    return obj.updateRequired;
  }
  else {
    return false;
  }
}

populateIfSubObservablesNeedUpdating(obj: T): T {
  this.fs.PrototypeObject.retrievefirestoreCompositionalDiffMemberNames().forEach(composorDocumentMemberName => {
    if (Array.isArray(obj[composorDocumentMemberName])) {
      // if the number of elements in array changed, then update is required.  Needed b/c removed element is't present to iterate over.
      const cachedValue = this.getPurePreviousEmissionCachedValue(obj.DocId());
      if (cachedValue && (!cachedValue[composorDocumentMemberName] || cachedValue[composorDocumentMemberName].length !== obj[composorDocumentMemberName].length)) {
        obj.updatePrincipalRequired = true;
        obj.updatedThisCycle = true;
      }
      obj[composorDocumentMemberName].forEach(element => {
        obj.updateRequired = this.updateSingleComposedElementUpdateRequired(composorDocumentMemberName, element) || obj.updateRequired;
        obj.updatedThisCycle = obj.updateRequired;
      });
    } else {
      obj.updateRequired = this.updateSingleComposedElementUpdateRequired(composorDocumentMemberName,  obj[composorDocumentMemberName]) || obj.updateRequired;
      obj.updatedThisCycle = obj.updateRequired;
    }
  });
  return obj;
}

/**
 *
 * @param obj Object to create.
 * @param batch Batch.
 * @returns Observable of created object, but largely unhydrated.  So one likely wants to switchMap => load if you desire to use
 * created object. ( This is b/c the created object's cached observable is added b/f it is hydrated from cloud firestore.  Could
 * pre-popuate it but that has potential dragons as well.  Better to explicitly load if you need to....)
 */
create$(obj: T, batchId: string = null): Observable<T> {

    obj = this.performAnyCalculationsNeededToStoreObject(obj);

    const responsibleForCommit = this.fs.writeBatchNeeded(batchId);
      let batchResolved = of(batchId);
      if (responsibleForCommit) {
        batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
      }

    let b : string = null;

    batchResolved = batchResolved.pipe(
      tap(batch => b = batch),
    );

    this.populateIfSubObservablesNeedUpdating(obj);
    const createAndSetStoreVal$ = batchResolved.pipe(
      switchMap(batch => this.fs.createToFirestore(obj, batch)),
        mergeMap(x => combineLatest([...this.setComposedObjects(obj, "CREATE From App")]).pipe(
          map(() => x),
      )),
      catchError(err => {
        console.log('Error writing update to firestore.', err);
        return throwError(err);
    })
      );

    if (responsibleForCommit) {
      return createAndSetStoreVal$.pipe(
        mergeMap(result => this.fs.commitFirestoreWriteBatch(b).pipe(
          map(() => {
            result.updatePrincipalRequired = false;
            return result;
          }),
          switchMap(result => this.load$(result.DocId(),result)),
          take(1)
          )));
    } else {
      return createAndSetStoreVal$.pipe(take(1));
    }
  }

retrieveFirestoreBatchString(): Observable<string> {
    return FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
  }

//If usage requires manually knowing b/f or outside of process of committing firestore object.
retrieveFirestoreRawDocId() : Observable<string> {
  return this.fs.retrieveDocId();
}

  // Populates docId w/ GUID from firestore w/o adding object to firestore.  Also updates object
retrieveDocId(obj: T): Observable<void> {
    return this.fs.retrieveDocId().pipe(
      tap(x => {
        obj.SetDocId(x);
        obj.uncommitedDocId = true;
      }),
      map(() => void(0)),
    );
  }


commitExternallyManagedFirestoreBatch(batch: string): Observable<void> {
      return this.fs.commitFirestoreWriteBatch(batch);
  }

createMultiple$(obj: T[], batch: string = null): Observable<T[]> {
    if (obj.length === 0) {
      return of([]);
    }

      let batchResolved = of(batch).pipe(share());
      const responsibleForCommit = batch === null;
      if (responsibleForCommit) {
        batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
          tap(x => batch = x),
          share()
        );
      }


    const subs$: Observable<T>[] = [];
    obj.forEach(element => {
      subs$.push(batchResolved.pipe(switchMap(b => this.create$(element, b))));
    });

    if (!responsibleForCommit) {
      return zip(...subs$).pipe(
        take(1)
      );
    } else {
      return zip(...subs$).pipe(
        switchMap(x => this.fs.commitFirestoreWriteBatch(batch).pipe(
        map(() => x))),
        take(1));
    }
  }

queryFirestoreShallow$(queryFn?: QueryConstraint[]): Observable<T[]> {
    const raw = this.fs.QueryFirestoreShallowValueChanges(queryFn).pipe(
      share());

    const withValue = raw.pipe(
      filter(x => x.length > 0),
        switchMap(arr => combineLatest([...arr.map(x => this.setComposedObjects(x,"From Firestore")).flat()]).pipe(
        map(() => arr)
      )));

      const withoutValue = raw.pipe(
        filter(x => x.length === 0),
        tap(() => console.log(`no value for ${JSON.stringify(queryFn)} ${this.store.storeName}`)),
      )

    return merge(withoutValue, withValue);
  }

/**
 * @param queryFn Function to use to query firestore.
 * @returns Observable of array of objects.
 * @description Returns fully hydrated array of objects resulting from query.
 */
queryFirestoreDeep$(queryFn?: QueryConstraint[]): Observable<T[]> {
      if (this.fs.PrototypeObject.retrievefirestoreCompositionMemberNames().length === 0) {
        return this.fs.QueryFirestoreShallowValueChanges(queryFn);
      } else {
      return this.fs.QueryFirestoreShallowValueChanges(queryFn).pipe(
        switchMap(res => this.loadMultiple$(res.filter(x => x.DocId() !== undefined).map(x => x.DocId()))),
      );
  }
}

  /**
   * Returns shallow objects which match provided in query.
   * @param fieldPath
   * @param values
   */
queryFirestoreForInValues(fieldPath: string, values: any[], queryArray: boolean = false): Observable<T[]> {
    return this.fs.queryFirestoreForInValues(fieldPath, values, queryArray).pipe(
      switchMap(arr => combineLatest(arr.length > 0 ?
        [...arr.map(x => this.setComposedObjects(x,"From Firestore")).flat()] :
        [of([])]).pipe(
        map(() => arr),
        catchError(err => {
          console.log('Error writing to firestore.', err);
          return throwError(err);
      }),
      )));
  }


 setComposedObjects(x: T, event: string = "From Firestore") : Observable<void>[] {

  //All this needs yanked, we don't use storeSets anymore
  return [of(void(0))];
}

get (docId: string) {
  return this.fs.getValueIfCached(docId);
}

private getPurePreviousEmissionCachedValue(docId: string) {
  return this.fs._PurecachedValues.get(docId);
}

public getPreviousPureValueIfCached(docId: string) {
  const retVal = this.fs._PurecachedValues.get(docId);
  if (retVal !== undefined) {
    return cloneDeep(retVal);
  }
}

load$(docId: string, initialValue: T = undefined): Observable<T> {
    this.fs.incrementSubscribers(docId);
    // console.log("FRESH LOAD CALLED ON DOCID: " + docId + "");

    const retVal = this.fs.PrototypeObject.retrievefirestoreCompositionMemberNames().length === 0 ?
    this.fs.readFromCacheOrFirestore(docId, initialValue, true) :
    this.fs.readFromCacheOrFirestore(docId, initialValue, true,  (x:T) => this.setComposedObjects(x));
    //THINK ON THIS.
    // this.fs.readFromCacheOrFirestore(docId, undefined, false) :
    // this.fs.readFromCacheOrFirestore(docId, undefined, false,  (x:T) => this.setComposedObjects(x));
    return retVal
    .pipe(
      share(),
      finalize(() => this.fs.unsubscribeOnFinalize(docId)),
      );
    // const createFromFirestore = this.fs.readFromCacheOrFirestore(docId, undefined, true).pipe(share());

    // const valueEmmission = createFromFirestore.pipe(
    //     filter(value => value !== undefined && value !== null),
    //     // uncomment to see why so many reads?
    //     tap(x => console.warn(x,` string`)),
    //     switchMap(x => combineLatest([...this.setComposedObjects(x)]).pipe(
    //       map(() => x))));

    // const nullEmission = createFromFirestore.pipe(
    //   filter(x => x === null),
    //   );

    // return merge(valueEmmission, nullEmission).pipe(
    //   finalize(() => this.fs.unsubscribeOnFinalize(docId)),
    // )
  }

  loadMultiple$(docIds: string[], DebounceTime: number = 25): Observable<T[]> {

    if (docIds.length === 0) {
      return of([]);
    }

    const unique = [...new Set(docIds)];
    return combineLatest(unique.map(id => this.load$(id))).pipe(
      debounceTime(DebounceTime),
    );
  }


  getCloneOfCachedValue(docId: string) : T {
    return this.fs.getCloneOfCachedValue(docId);
  }

  retrieveLoadAllObs() : Observable<T[]> {

    const ceaseObservationSignalForCompositions = Math.random().toString(36).substring(0, 10);

    // const shallowLoad = this.fs.QueryFirestoreShallowValueChanges(undefined,true).pipe(
      const shallowLoad = this.fs.QueryFirestoreShallowValueChanges(undefined).pipe(
      // This debounce is present b/c cloud firestore greedily returns all matching results, so if we have previously loaded one, it will be returned right away,
      // with the query results coming later with actual data.  Switch to referencing snapshot changes to fix.
      debounceTime(300),
      share()
    )

    const shallow = shallowLoad.pipe(
      filter(() => this.fs.PrototypeObject.retrievefirestoreCompositionMemberNames().length === 0 ),
      share()
    );

    const shallowAllNeeded = shallow.pipe(
      switchMap(vals => {
        const setObs: Observable<void>[] = [];
        vals.forEach(val =>
          {
            setObs.push(...this.setComposedObjects(val,"From Firestore"));
        });
        return zip(...setObs).pipe(
          map(() => vals));
        }),
      tap(() => console.log("SHALLOW ONLY"))
    );

    const deeperNeeded = shallowLoad.pipe(
      filter(() => this.fs.PrototypeObject.retrievefirestoreCompositionMemberNames().length !== 0 ),
      switchMap(x => this.loadMultiple$(x.map(val => val.DocId()))));

      return merge(shallowAllNeeded, deeperNeeded).pipe(
        finalize(() => setTimeout(() => FirestoreBackend.unsubscriber.next(ceaseObservationSignalForCompositions),
        FirestoreBackend.unsubscriptionTimer)),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        })
      );
  }

  loadAll$(cacheObservable: boolean = true): Observable<T[]> {

    if (cacheObservable) {
      if (this.cachedLoadAll === null) {
        this.cachedLoadAll = new ReplaySubject<T[]>(1);
        const loadAllObs = this.retrieveLoadAllObs();
        loadAllObs.pipe(
          catchError(err => {
          console.log('Error caught in observable.', err);
          return throwError(err);
          }),
          takeUntil(this.destroyingComponent$)
        ).subscribe(this.cachedLoadAll);
      }
      return this.cachedLoadAll;
    } else {
      return this.retrieveLoadAllObs();
    }
  }

update$(obj: T, passBatch: string | null = null): Observable<T> {

    if (obj.immutable) {
      throw new Error("Immutable objects can not be updated, they require a new object to be created; and referenced as desired.");
    }


    const responsibleForCommit = this.fs.writeBatchNeeded(passBatch);

    obj.updatePrincipalRequired = this.updatePrincipleObjectRequired(obj);

    this.populateIfSubObservablesNeedUpdating(obj);
    obj.updateRequired = obj.updatePrincipalRequired || obj.updateRequired;
    if (obj.updateRequired) {
      let updateAndStoreDiffs$: Observable<{val: T, batch: string}> = null;
      let batchString : string = null;
      let batchResolved = responsibleForCommit ?  FirestoreBackend.retrieveFirestoreWriteBatchIdentifier() : of(passBatch);
      batchResolved = batchResolved.pipe(
        tap(batch => batchString = batch),
        share()
      );

      updateAndStoreDiffs$ = batchResolved.pipe(
        switchMap(b => this.fs.update(obj, b).pipe(
          map(x => {
            return {val: x, batch: b};
          }),
        )),
        take(1),
          mergeMap( x => combineLatest([...this.setComposedObjects(x.val, "UPDATE From App")]).pipe(
          map(() => x)
        )),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
        take(1)
        );

      if (responsibleForCommit) {
        return updateAndStoreDiffs$.pipe(
          mergeMap(result => this.fs.commitFirestoreWriteBatch(batchString).pipe(
            catchError(err => {
            console.log('Error caught in observable.', err);
            return throwError(err);
            }),
            map(() => result.val)
          )),
          take(1));
      } else {
        return updateAndStoreDiffs$.pipe(
          map(result => result.val),
          take(1),
        )
      }
    } else {
      const docId = obj.DocId();
      this.fs.incrementSubscribers(docId);
      // no update required, so we don't pass along obj to add to cached observable
      return this.fs.readFromCacheOrFirestore(docId).pipe(
        take(1),
        finalize(() => {
          this.fs.unsubscribeOnFinalize(docId);
        }));
    }
  }

deleteMultiple$(obj: T[], batch : string | null = null): Observable<void> {

    if (obj.length === 0) {
      return of(void(0));
    }

    const responsibleForCommit = this.fs.writeBatchNeeded(batch);
    let batchResolved = of(batch);
      if (responsibleForCommit) {
        batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
      }

    const subObs$: Observable<string>[] = [];
    obj.forEach(element => subObs$.push(
      batchResolved.pipe(
        switchMap(b => this.delete$(element, b).pipe(
          map(() => b))
        ))));
    if (!responsibleForCommit) {
      return zip(...subObs$).pipe(take(1),map(() => void(0)));
    } else {
      return zip(...subObs$).pipe(
      switchMap(x => this.fs.commitFirestoreWriteBatch(x[0]).pipe(
        map(() => void(0)))),
        take(1));
    }
  }

delete$(obj: T, batch? : string | null): Observable<void> {
  const responsibleForCommit = this.fs.writeBatchNeeded(batch);
  let batchResolved = of(batch);
    if (responsibleForCommit) {
      batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
    }
    const deleteAndSetStoreObj$ = batchResolved.pipe(
      switchMap(b => this.fs.delete(obj, b).pipe(
      map(() => b))
      ));

    if (responsibleForCommit) {
      return deleteAndSetStoreObj$.pipe(
        switchMap(result => this.fs.commitFirestoreWriteBatch(result).pipe(
          map(() => void(0)))),
          take(1));
    } else {
      return deleteAndSetStoreObj$.pipe(map(() => void(0)));
    }
  }
}
