import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {  AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, debounceTime, interval, merge, Observable, of, share, Subject, zip } from 'rxjs';
import { delay,distinctUntilChanged, filter, map, pairwise, startWith,  switchMap,  take, takeUntil, tap } from 'rxjs/operators';
import { LocalSettingsService } from '../../settings/local-settings.service';
import { LoadFormComponent } from '../storage/load-form/load-form.component';
import { SaveFormComponent } from '../storage/save-form/save-form.component';
import { ColumnSplitterComponent } from '../column-splitter/column-splitter.component';
import { ComponentFromFormlyFactoryService } from '../component-from-formly-factory.service';
import {  ControlContainerComponent } from '../component-models/control-container.component';
import { ControlContainsControlsComponent } from '../component-models/control-contains-controls.component';
import { Audience, FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { ContainsControlComponentsInterface, ControlReportsOnViewInitilizationInterface } from '../containsComponents';
import { FormElementComponent } from '../form-element/form-element.component';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { FormlyComponentUtilityService } from '../component-models/formly-controls/formly-component-utility.service';
import { WorkflowType } from '../form-designer/form-designer.component';
import { ImportWorkflowAssignmentService } from '../import-workflow-assignment.service';
import { TextboxControlComponent } from '../component-models/textbox-control/textbox-control.component';
import { PriceBookEntry } from '../../../../../common/src/data/dao/pricebook/pricebook-entry';
import { FormlySectionPricebookMapping } from '../component-models/formly-controls/formly-section/formly-section-pricebook-mapping';
import { PriceBookEntryService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-entry.service';
import { PriceBookUnitOfMeasureService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service';
import { FormlySectionUnitOfMeasureMapping } from '../component-models/formly-controls/formly-section/formly-section-unit-of-measure-mapping';
import { PriceBookUnitOfMeasure } from '../../../../../common/src/data/dao/pricebook/pricebook-unit-of-measure';
import { DerivedWorkflowFieldService } from '../derived-workflow-field.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})

export class SectionComponent extends ControlContainsControlsComponent implements OnInit, ContainsControlComponentsInterface, ControlReportsOnViewInitilizationInterface, AfterViewInit, OnDestroy {

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  menuX : number = 0;
  menuY: number = 0;
  saveFormDialogRef: MatDialogRef<SaveFormComponent>;
  loadFormDialogRef: MatDialogRef<LoadFormComponent>;

  activeFormFirestore: BehaviorSubject<FormFirestore> = new BehaviorSubject<FormFirestore>(undefined);
  unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  formlyViewInitializing$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  automaticallySaveForm: boolean;
  reset$: Subject<any> = new Subject();
  dropZoneIdAssigned : string = undefined;
  sectionWorkflowType : WorkflowType = WorkflowType.Section;
  extraFunctionEndViewInit: () => void = undefined;

  updatedSectionProperties$: Subject<any> = new Subject<any>();
  activelyManuallySaving : boolean = false;
  patchedFromBranch: boolean = false;
  patchedInUnitOfMeasureMapping: boolean = false;
  patchedInFormlyFieldConfig$: Subject<null> = new Subject<null>();
  patchUpdatedFormlySectionConfig$: Subject<any> = undefined;

  patchUpdatedSectionToFormlyBranch$: Subject<{priceBookEntry: string, summaryDocId: string}> = undefined;

  get afterViewInitilized$() { return this.formlyViewInitializing$.asObservable().pipe(filter(x => x === false)) }

  constructor(fb: UntypedFormBuilder, private componentFromFormlyFactory: ComponentFromFormlyFactoryService, private _ngZone: NgZone, private dialog: MatDialog,
    private formlyUtilityService:  FormlyUtilityService, private formCatagoryService: FormCatagoryService, private formFirestoreService: FormFirestoreService,
    private formFirestoreSummaryService: FormFirestoreSummaryService, private localSettingsService: LocalSettingsService,
    private formlyComponentUtilityService: FormlyComponentUtilityService, private importDataWorkflowService: ImportWorkflowAssignmentService,
    private priceBookEntryService: PriceBookEntryService, private unitOfMeasureService: PriceBookUnitOfMeasureService, private derivedWorkflowFieldService: DerivedWorkflowFieldService)
  {
    super(SectionComponent,fb);
    this.automaticallySaveForm  = localSettingsService.loadFromLocalStorage("WorkflowDesigner", "autoSaveForms", true);
    this.form = this.initilizeFormGroup();

    this.fields = this.generateFormlyFieldConfigSanSubForms();
    this.setupPropagationOfClickEventsInsideSection();
  }

  setupPropagationOfClickEventsInsideSection() {
    let skipOne: boolean = false;

    this.subComponentClicked$.pipe(
      tap(x => this.unfilteredComponentClicked$.next(x)),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    // Always pass through when perInstance !== this.perInstance ( child component clicked)
    this.unfilteredComponentClicked$.pipe(
      filter(x => x.perInstance !== this.perInstance),
      tap(() => skipOne = true),
      takeUntil(this.destroyingComponent$),
      ).subscribe(this.filteredComponentClicked$);

      // When not skipping one, propagate.
      this.unfilteredComponentClicked$.pipe(
        filter(x => x.perInstance === this.perInstance && !skipOne),
        takeUntil(this.destroyingComponent$),
      ).subscribe(this.filteredComponentClicked$);

      // When skipping one.
      this.unfilteredComponentClicked$.pipe(
        filter(x => x.perInstance === this.perInstance && skipOne),
        tap(() => skipOne = false),
        takeUntil(this.destroyingComponent$),
      ).subscribe();
  }


  patchControlComponentsToFormlyFields(): void {
    const obs$ = this.patchControlComponentsToFormlyFieldsCommon(this.fields);
    const fieldList = ["itemName","minimumNumberInstances","maximumNumberInstances","repeating","title","formFirestoreDocId",
      "formFirestoreSummaryDocId","priceBookMappings", "unitOfMeasureMappings","collapsable","defaultCollapsed"];
    const fieldsToPatchToBranch = ["itemName","minimumNumberInstances","maximumNumberInstances","repeating","collapsable","defaultCollapsed"];

    fieldList.forEach(f => {
      obs$.push(this.componentForm.get(f).valueChanges.pipe(
        startWith(this.componentForm.get(f).value),
        tap(x => this.fields[0].props[f] = x),
      ));
    });

    if (this.patchUpdatedFormlySectionConfig$ !== undefined) {
      fieldsToPatchToBranch.forEach(f => {
        obs$.push(this.componentForm.get(f).valueChanges.pipe(
          filter(x => x !== undefined),
          tap(x => this.patchUpdatedFormlySectionConfig$.next({[f]: x})),
        ));
      });
    }

    obs$.push(this.componentForm.get("activeFormFirestore").valueChanges.pipe(
      startWith(this.componentForm.get("activeFormFirestore").value),
      tap(x => this.activeFormFirestore.next(x)),
    ));

    obs$.push(this.form.get("serializeToDatabase").valueChanges.pipe(
      startWith(this.form.get("serializeToDatabase").value),
      tap(x => this.fields[0].props.serializeToDatabase = x),
    ));

    obs$.push(
      this.form.get("indexInParentContainer").valueChanges.pipe(
        startWith(this.form.get("indexInParentContainer").value),
        tap(x => {
            this.fields[0].props.indexInParentContainer = x;
        })
      )
    );

    this.componentForm.get("maximumNumberInstances").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.componentForm.controls["minimumNumberInstances"].setValidators([Validators.min(0),Validators.max(x)])),
      tap(() => this.componentForm.controls["minimumNumberInstances"].updateValueAndValidity()),
      takeUntil(this.destroyingComponent$)).subscribe();

    this.componentForm.get("minimumNumberInstances").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.componentForm.controls["maximumNumberInstances"].setValidators([Validators.min(x), Validators.max(100)])),
      tap(() => this.componentForm.controls["maximumNumberInstances"].updateValueAndValidity()),
      takeUntil(this.destroyingComponent$)).subscribe();

    combineLatest([...obs$]).pipe(
      tap(() => this.fields[0].props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

}

  get componentFormGroup(): UntypedFormGroup {
    return this.componentForm;
  }

  static fb: UntypedFormBuilder = new UntypedFormBuilder();

  static generateSectionFormGroup() : UntypedFormGroup {
    const retVal = this.fb.group({
      repeating: [false],
      title: [""],
      minimumNumberInstances: [1,[Validators.min(0),Validators.max(100)]],
      maximumNumberInstances: [100,[Validators.min(1), Validators.max(100)]],
      itemName: "Untitled",
      sectionUpdated: [],
      //columnFormGroups here is always 1, and not representative of columns per se.
      columnFormGroups: new UntypedFormArray([]),
      connectedDropLists: [],
      addDropZoneId: "",
      removeDropZoneId: "",
      itemDrop: [],
      itemRemoved: {},
      menuLanchedByContainerId: "",
      explicitRegenerateFormly: {},
      formFirestoreDocId: [],
      formFirestoreSummaryDocId: [],
      activeFormFirestore: [],
      priceBookMappings: [new Array()],
      unitOfMeasureMappings: [new Array()],
      preventSave: [false],
      collapsable: [false],
      defaultCollapsed: [false],
    });
    return retVal;
  }

  public get NumberComponents() {
    let subComponentCount = 0;
    ((this.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( column => {
      subComponentCount ++;
      const columnControls = (column["cdkDropListData"] as ControlContainerComponent[]);
      columnControls.forEach(control => subComponentCount += control.NumberComponents);
    });
    return subComponentCount;
  }

  public get idForContainingDiv() : string {
    return `div-${((this.componentForm.get("columnFormGroups") as UntypedFormArray).controls[0].value["id"])}`;
  }

  set componentFormGroup(value: UntypedFormGroup) {
    this.form.patchValue({controlComponentFormGroup: value});
    const columnFormGroup = this.buildColumnFormGroup();
    const id = columnFormGroup.get("id").value;


    // Add subscription to connected drop lists to newly built component.
    this.componentForm.controls["connectedDropLists"].valueChanges.pipe(
      startWith(this.componentForm.get("connectedDropLists").value),
      takeUntil(this.componentForm.controls["removeDropZoneId"].valueChanges.pipe(
        filter(x => x === id))),
      map(q => (q as string[])),
      delay(1),
      tap(connectedGroups => {
        columnFormGroup.patchValue({cdkDropListConnectedTo: connectedGroups.filter(x => x !== id)})
      }),
      takeUntil(merge(this.destroyingComponent$,this.reset$))
    ).subscribe();

    (this.componentForm.get("columnFormGroups") as UntypedFormArray).insert(0,columnFormGroup);
  }

  get containedControls() : ControlContainerComponent[] {
    return (this.componentForm.get("columnFormGroups") as UntypedFormArray).value[0].cdkDropListData as ControlContainerComponent[];
  }

  get columnFormGroups(): UntypedFormArray {
    return this.componentForm.get('columnFormGroups') as UntypedFormArray;
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Section");
    retVal.patchValue({
      icon: "note_add",
      iconColor: "#868686",
      controlComponentFormGroup: SectionComponent.generateSectionFormGroup(),
      composerComponent: true,
      menuLanchedByContainerId: "",
    });
    return retVal;
  }

  removeSection() {
    this.componentForm.patchValue({menuLanchedByContainerId: this.columnFormGroups.value[0].id});
    const idsToRemove = this.associtedDropZoneIds;
    idsToRemove.forEach(i => this.componentForm.patchValue({removeDropZoneId: i}));
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).removeAt(0);
  }

  deleteControlsContainedInSection() {
    const idsToRemove = this.associtedDropZoneIds.filter(x => x !== (this.componentForm.get("columnFormGroups") as UntypedFormArray).value[0].id);
    idsToRemove.forEach(i => this.componentForm.patchValue({removeDropZoneId: i}));
    this.componentFormGroup.patchValue({removeDropZoneId: this.dropZoneIdAssigned});
  }

  ngOnDestroy(): void {
    ((this.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( c => {
      this.componentFormGroup.patchValue({removeDropZoneId:  c["id"]});
    });
    this.componentFormGroup.patchValue({removeDropZoneId: this.dropZoneIdAssigned});
    this.controlContainerCommonDestruction();
  }



  autoSaveFormObservable(formSummary: FormFirestoreSummary, title: string) : Observable<FormFirestore> {
    const newFormFireStore = new FormFirestore();
    return this.formFirestoreService.retrieveDocId(newFormFireStore).pipe(
      tap(() => {
        let formFirestoreSummaryDocId = this.componentForm.get("formFirestoreSummaryDocId").value;
        this.componentForm.patchValue({formFirestoreDocId: newFormFireStore.DocId(), formFirestoreSummaryDocId: formFirestoreSummaryDocId});
        const savedVersion = JSON.stringify(SectionComponent.stripSectionRepeatConfiguration(this.toFormlyFieldConfigJsonOnly() as FormlyFieldConfig));
        newFormFireStore.formSummary = formSummary;
        newFormFireStore.title = title;
        newFormFireStore.form = savedVersion;
      }),
      map(() => {
        newFormFireStore.formSummary.currentDesignFirestoreDocId = newFormFireStore.DocId();
        return newFormFireStore;
      }),
      switchMap(newFormFireStore => this.formFirestoreService.create$(newFormFireStore)),
      tap(() => this.unsavedChanges.next(false)),
      filter(x => x.formSummary !== null),
      tap(x => this.activeFormFirestore.next(x)),
      tap(x => console.log(x,` string`)),
      tap(() => console.log("AUTO SAVING SECTION.")),
      tap(() => this.componentForm.patchValue({sectionUpdated: true})),
      take(1)
    ) as Observable<FormFirestore>;
  }

  autoSaveForm() {
    const readyToSave = this.checkForSaveValidity();
    if (!readyToSave) {
      // window.alert("You first must save sub sections.");
      return;
    }
    this.autoSaveFormObservable(this.activeFormFirestore.value.formSummary, this.activeFormFirestore.value.title).pipe(
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  checkForSaveValidity() : boolean {
    if (this.componentFormGroup.get("preventSave").value === true) {
      return false;
    }
    // We must iterate through all controls and check if there are any formly-sections which have not yet been saved.
    // If there are, we must prompt the user to save the form before proceeding.
    if (this.columnFormGroups.controls[0] === undefined) {
      return false;
    }
    const toCheck = this.columnFormGroups.controls[0].get('cdkDropListData').value.filter(x => x.serializeToDatabase);
    for (const formlySection of toCheck) {
      if (formlySection.component.name === "ColumnSplitterComponent" && formlySection.activelyManuallySaving) {
        return false;
      }
      const sectionRetVal = this.checkForSectionValidity(formlySection.toFormlyFieldConfig() as FormlyFieldConfig);
      if (!sectionRetVal) {
        return false;
      }
    }
    return true;
  }

  associateWithImportedData() : void {
    if (this.associateImportedDataWithContainedControls(this.containedControls,this.activeFormFirestore.value.formSummary)) {
      this.updatedSectionProperties$.next(true);
    }
  }

  static populateMappedImportedDataEntries(containedControls: any[], mappings: (FormlySectionPricebookMapping | FormlySectionUnitOfMeasureMapping)[], unitOfMeasureService: PriceBookUnitOfMeasureService,
    priceBookEntryService: PriceBookEntryService) {
    try {
      mappings.forEach(mapping => {
        for (const control of containedControls) {
          let associatedTextBoxControl = undefined;
          let found = undefined;
          if (control.columnFormGroups !== undefined) {
            for (let column of control.columnFormGroups.value) {
              found = column.cdkDropListData.find(x => x.componentForm.controls.guid !== undefined &&
                x.componentForm.controls.guid.value === mapping.formlyTextboxGuid);
              if (found !== undefined) {
                associatedTextBoxControl = (found as TextboxControlComponent);
                break;
              }
            }
          }
          if (found !== undefined) {
            associatedTextBoxControl = (found as TextboxControlComponent);
            if (mapping['priceBookEntryDocId'] !== undefined) {
              priceBookEntryService.load$(mapping['priceBookEntryDocId']).pipe(
                tap(x => associatedTextBoxControl.linkedToImportedData$.next(x)),
                take(1)
              ).subscribe();
            } else {
              unitOfMeasureService.load$(mapping['unitOfMeasureEntryDocId']).pipe(
                tap(x => associatedTextBoxControl.linkedToImportedData$.next(x)),
                take(1)
              ).subscribe();
            }
            break;
          }
        };
      });
    } catch (e) {
      console.error(e);
    }

  }

  internalDeleteImportedDataAssociation() : void {
    if (this.deleteImportedDataAssociation(this.containedControls, this.activeFormFirestore.value.formSummary)) {
      this.updatedSectionProperties$.next(true);
    }
  }

  deleteImportedDataAssociation(containedControls: any[], formSummary: FormFirestoreSummary) : boolean {
    let associatedTextBoxControl = undefined;
    // if single textbox is contained, validate it and assign it to the pricebook entry.
    if (containedControls.length === 1 &&
      containedControls[0].columnFormGroups.value.length === 1 &&
      containedControls[0].columnFormGroups.value[0].cdkDropListData.length === 1) {
        associatedTextBoxControl = (containedControls[0].columnFormGroups.value[0].cdkDropListData[0] as TextboxControlComponent);
    } else {
      //Currently this will always fail validation, as we only support associating price book and unit of measure entries with text box controls.
      if (this.formlyComponentUtilityService.componentSelectedForSideView.component.name !== "TextboxControlComponent") {
        associatedTextBoxControl = this.formlyComponentUtilityService.componentSelectedForSideView;
      } else {
        const guidToFind = this.formlyComponentUtilityService.componentSelectedForSideView.componentForm.controls.guid.value;
        for (let control of containedControls) {
          const found = control.columnFormGroups === undefined ? undefined : control.columnFormGroups.value[0].cdkDropListData.find(x => x.componentForm.controls.guid !== undefined &&
              x.componentForm.controls.guid.value === guidToFind);
          if (found !== undefined) {
            associatedTextBoxControl = (found as TextboxControlComponent);
            break;
          }
        }
      }
    }
    if (SectionComponent.validateImportedDataDeletion(associatedTextBoxControl,this.componentForm.get("priceBookMappings").value
      .concat(this.componentForm.get("unitOfMeasureMappings").value))) {
      associatedTextBoxControl.linkedToImportedData$.next(null);
      const priceBookMappings = this.componentForm.get("priceBookMappings").value as FormlySectionPricebookMapping[];
        let spliceIndex = 0;
        do {
          spliceIndex = priceBookMappings.findIndex(q => q.formlyTextboxGuid === associatedTextBoxControl.componentForm.get("guid").value);
          if (spliceIndex !== -1) {
            priceBookMappings.splice(spliceIndex,1);
          }
        } while (spliceIndex !== -1);
        this.componentForm.patchValue({priceBookMappings: priceBookMappings.slice()});

      const unitOfMeasureMappings = this.componentForm.get("unitOfMeasureMappings").value as FormlySectionUnitOfMeasureMapping[];
      if (unitOfMeasureMappings.length > 0) {
        const spliceIndex = unitOfMeasureMappings.findIndex(q => q.formlyTextboxGuid === associatedTextBoxControl.componentForm.get("guid").value);
        if (spliceIndex !== -1) {
          unitOfMeasureMappings.splice(spliceIndex,1);
        }
        this.componentForm.patchValue({unitOfMeasureMappings: unitOfMeasureMappings.slice()});
      }
      return true;
    } else {
      return false;
    }
  }

  associateImportedDataWithContainedControls(containedControls: any[], formSummary: FormFirestoreSummary) : boolean{
    let associatedTextBoxControl = undefined;
    // if single textbox is contained, validate it and assign it to the pricebook entry.
    if (containedControls.length === 1 &&
      containedControls[0].columnFormGroups.value.length === 1 &&
      containedControls[0].columnFormGroups.value[0].cdkDropListData.length === 1) {
        associatedTextBoxControl = (containedControls[0].columnFormGroups.value[0].cdkDropListData[0] as TextboxControlComponent);
    } else {
      //Currently this will always fail validation, as we only support associating price book and unit of measure entries with text box controls.
      if (this.formlyComponentUtilityService.componentSelectedForSideView.component.name !== "TextboxControlComponent") {
        associatedTextBoxControl = this.formlyComponentUtilityService.componentSelectedForSideView;
      } else {
        // find component selected for sideview if it is present in section in question.
        const guidToFind = this.formlyComponentUtilityService.componentSelectedForSideView.componentForm.controls.guid.value;
        for (let control of containedControls) {
          let found = undefined;
          if (control.columnFormGroups !== undefined) {
            for (let column of control.columnFormGroups.value) {
              found = column.cdkDropListData.find(x => x.componentForm.controls.guid !== undefined &&
                x.componentForm.controls.guid.value === guidToFind);
              if (found !== undefined) {
                associatedTextBoxControl = (found as TextboxControlComponent);
                break;
              }
            }
          }
        };
      }
    }
    if (SectionComponent.validateImportedDataAssocation(associatedTextBoxControl, this.importDataWorkflowService)) {
      associatedTextBoxControl.linkedToImportedData$.next(this.importDataWorkflowService.selection.selected[0]);
      this.importDataWorkflowService.associatedWorkflowWithSelections(formSummary);
      if (this.importDataWorkflowService.selection.selected[0] instanceof PriceBookEntry) {
        const priceBookEntry = this.importDataWorkflowService.selection.selected[0] as PriceBookEntry;
        const priceBookMappings = this.componentForm.get("priceBookMappings").value;
        priceBookMappings.push( new FormlySectionPricebookMapping({priceBookEntryDocId:  priceBookEntry.DocId(), formlyTextboxGuid: associatedTextBoxControl.componentForm.get("guid").value}));
        this.componentForm.patchValue({priceBookMappings: priceBookMappings.slice()});
      } else {
        const unitOfMeasure = this.importDataWorkflowService.selection.selected[0] as PriceBookUnitOfMeasure;
        this.componentForm.patchValue({unitOfMeasureMappings: [new FormlySectionUnitOfMeasureMapping({unitOfMeasureEntryDocId:  unitOfMeasure.DocId(), formlyTextboxGuid: associatedTextBoxControl.componentForm.get("guid").value})]});
      }
      this.importDataWorkflowService.selection.clear();
      return true;
    } else {
      return false;
    }
  }

  internalAssociateSectionToPriceBookEntryWorkflow() : boolean {
    return this.associateSectionToPriceBookEntryWorkflow(this.containedControls, this.activeFormFirestore.value.formSummary);
  }

  associateSectionToPriceBookEntryWorkflow(containedControls: any[], formSummary: FormFirestoreSummary) : boolean {
    if (SectionComponent.validateSectionReadyToAssociateWithPricebookWorkflow(containedControls)) {
      this.importDataWorkflowService.defineWorkflowWithSection(formSummary, this.importDataWorkflowService.selection.selected[0] as PriceBookEntry).pipe(
        take(1)
      ).subscribe();
      this.importDataWorkflowService.selection.clear();
      return true;
    } else {
      return false;
    }
  }

  static validateSectionReadyToAssociateWithPricebookWorkflow(containedControls: any[]) : boolean
  {
    for (let control of containedControls) {
      const found = control.columnFormGroups?.value[0].cdkDropListData.find(x => x.form.value.controlName === "Text Box");
      if (found !== undefined) {
        return true;
      }
    };
    return false;
  }

  static validateImportedDataDeletion(c : TextboxControlComponent, priceBookMappings: any[]) : boolean {
    let errorMessage = "";
    if (c === undefined || c.component.name !== "TextboxControlComponent") {
      errorMessage = "Component you are attempting to disassociate not associated with any price book entry / unit of measure.";
    } else if (priceBookMappings.find(q => q.formlyTextboxGuid === c.componentForm.get("guid").value) === undefined) {
      errorMessage = "There is no price book entry associated with the text box control.";
    }
    if (errorMessage !== "") {
      alert(errorMessage);
      return false;
    }
    return true;
  }

  static validateImportedDataAssocation(c : TextboxControlComponent, importDataWorkflowService: ImportWorkflowAssignmentService) : boolean {
    let errorMessage = "";
    if (c === undefined) {
      errorMessage = "Component you are attempting to associate is not part of the section you are attempting to associate with price book entry / unit of measure.";
    } else if (c.component.name !== "TextboxControlComponent") {
       errorMessage = "You can only associate text box controls with price book entries and unit of measure.";
    } else if (c.componentForm.get("numericMaskDesired").value === false) {
      errorMessage = "You can only associate price book entries and units of measure with numeric fields.";
    } else if (importDataWorkflowService.selection.selected.length === 0) {
      errorMessage = "You must first select a price book entry or unit of measure to associate with the text box control.";
    } else if (importDataWorkflowService.selection.selected.length > 1) {
      errorMessage = "You can only associate a single price book entry or unit of measure with a text box control.";
    }
    if (errorMessage !== "") {
      alert(errorMessage);
      return false;
    }
    return true;
  }

  checkForSectionValidity(f : FormlyFieldConfig) : boolean {
    if (f.type === "formlySection" && !f.props.formFirestoreSummaryDocId) {
      return false;
    } else {
      if (f.fieldGroup) {
        for (const sub of f.fieldGroup) {
          if (!this.checkForSectionValidity(sub)) {
            return false;
          }
        }
    }
      return true;
    }
  }


  saveToFirestore(toSave: FormFirestore) {
    const readyToSave = this.checkForSaveValidity();
    if (!readyToSave) {
      window.alert("You first must save sub sections.");
      return;
    }
    this.activelyManuallySaving = true;
    const existingformFirestoreDocId = this.componentForm.get("formFirestoreDocId").value;
    const existingFormFirestoreSummaryDocId = this.componentForm.get("formFirestoreSummaryDocId").value;
    let formFirestoreSummaryDocId$ : Observable<string>;
    if ( this.componentForm.get("formFirestoreSummaryDocId").value === null) {
      formFirestoreSummaryDocId$ = this.formFirestoreService.retrieveFirestoreRawDocId();
    } else {
      formFirestoreSummaryDocId$ = of(this.componentForm.get("formFirestoreSummaryDocId").value);
    }

    const formFirestoreDocId$ = this.formFirestoreService.retrieveFirestoreRawDocId();

    zip(formFirestoreDocId$, formFirestoreSummaryDocId$).pipe(
      tap(([formFirestoreDocId, formFirestoreSummaryDocId]) => {
        this.componentForm.patchValue({formFirestoreDocId: formFirestoreDocId, formFirestoreSummaryDocId: formFirestoreSummaryDocId});
        const savedVersion = JSON.stringify(SectionComponent.stripSectionRepeatConfiguration(this.toFormlyFieldConfigJsonOnly() as FormlyFieldConfig));
        const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          data: {
          form: savedVersion,
          formFirestore: toSave,
          formType: "section",
          formFirestoreDocId: formFirestoreDocId,
          formFirestoreSummaryDocId: formFirestoreSummaryDocId,
          }});

        this.saveFormDialogRef = this.dialog.open(SaveFormComponent, editorConfig);

        const savedForm = this.saveFormDialogRef.afterClosed().pipe(
          filter(x => x !== undefined),
          switchMap(x => this.formFirestoreService.load$(x.DocId())),
          filter(x => x.formSummary !== null),
          tap(x => {
            this.activeFormFirestore.next(x);
            this.activelyManuallySaving = false;
            this.unsavedChanges.next(false);
          }),
          tap(x => {
            this.patchInFormlyFieldConfig(JSON.parse(x.form));
          }),
          tap(() => this.componentForm.patchValue({sectionUpdated: true})),
          tap(()=> this.fields[0].props.changeDetect.next())
        );

        const exitedModal = this.saveFormDialogRef.afterClosed().pipe(
          filter(x => x === undefined),
          tap(() => this.componentForm.patchValue({formFirestoreDocId: existingformFirestoreDocId,
            formFirestoreSummaryDocId: existingFormFirestoreSummaryDocId})),
          tap(() => this.activelyManuallySaving = false),
        );

        merge(savedForm,exitedModal).pipe(
          take(1),
        ).subscribe();
      }),
      take(1),
    ).subscribe();

  }

  saveSection() {
    this.saveToFirestore(this.activeFormFirestore.value);
  }

  copyWorkflow() {
    this.formFirestoreService.copyWorkflow(this.activeFormFirestore.value).pipe(
      tap(copy => this.saveToFirestore(copy)),
      take(1)
    ).subscribe();
  }

  revertToDeployedWorkflow() {
    const f = this.activeFormFirestore.value.formSummary;
    if (f.currentDeployedFirestoreDocId === undefined)
    {
      alert("No deployed version to revert to.");
      return;
    }
    f.currentDesignFirestoreDocId = f.currentDeployedFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      switchMap(x => this.formFirestoreService.load$(f.currentDesignFirestoreDocId)),
      filter(x => x.formSummary !== null),
      tap(() => this.formlyViewInitializing$.next(true)),
      tap(x => this.activeFormFirestore.next(x)),
      map(x => JSON.parse(x.form)),
      tap(x => this.patchFormlyFieldConfigFromFormFirestore(x)),
      take(1)
      ).subscribe();
  }

  deployWorkflow(refresh: boolean) {
    const f = this.activeFormFirestore.value.formSummary;
    f.currentDeployedFirestoreDocId = f.currentDesignFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      tap(() => {
        if (refresh) {
          this.loadForm(this.activeFormFirestore.value);
        } else {
          this.activeFormFirestore.next(this.activeFormFirestore.value);
        }
      }),
      take(1)
    ).subscribe();
  }

  loadForm(formFirestore: FormFirestore) {
    this.deleteControlsContainedInSection();
    this.unsavedChanges.next(false);
    this.formlyViewInitializing$.next(true);
    this.activeFormFirestore.next(formFirestore);
    this.patchFormlyFieldConfigFromFormFirestore(JSON.parse(formFirestore.form));
  }


  loadSection() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      data: {
      formCatagory: null,
      formType: "section",
      explicitWorkflowList: ["section"]
      }
    });

    this.loadFormDialogRef = this.dialog.open(LoadFormComponent, editorConfig);

    // Event dialog closure:
    this.loadFormDialogRef.afterClosed().pipe(
      take(1),
      filter(x => x !== undefined),
      map(x => x as FormFirestore),
      tap(x => this.loadForm(x)),
      ).subscribe();
  }

  newSection(workflowType: WorkflowType) {
    if (workflowType !== WorkflowType.Section) {
      throw new Error("Section component can only create new sections.");
    }
    this.reset$.next(null);
    this.componentForm.patchValue({title: "",itemName: "Untitled",sectionUpdated: null,formFirestoreDocId: null, formFirestoreSummaryDocId: null, activeFormFirestore: null});
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).clear();
    this.componentFormGroup = this.form.get("controlComponentFormGroup").value;
    this.fields[0].props.changeDetect.next();
  }

  forbidSectionComponentsPredicate(item: CdkDrag<ControlContainerComponent>) {
    // return !(item.data.form.get("controlName").value === "Section");
    return true;
  }

  patchChangesToDropZonesAndItems(retVal: UntypedFormGroup, newSubComponent : ControlContainsControlsComponent) {
    const toPatchThrough: string[] = ["addDropZoneId","removeDropZoneId","itemRemoved","itemDrop","sectionUpdated"];

    toPatchThrough.forEach(patch => {
      if (retVal.controls[patch] !== undefined) {
      retVal.controls[patch].valueChanges.pipe(
        tap(val => this.componentForm.controls[patch].patchValue(val)),
        takeUntil(merge(this.destroyingComponent$, newSubComponent.destroyingComponent$)),
        ).subscribe();
      }
    });

    this.componentForm.get("connectedDropLists").valueChanges.pipe(
      map(val => val.filter(x => x !== this.dropZoneIdAssigned)),
      tap(val => retVal.controls["connectedDropLists"].patchValue(val)),
      takeUntil(merge(this.destroyingComponent$, newSubComponent.destroyingComponent$)),
    ).subscribe();

    // If final control is removed from a column it should be deleted.
    retVal.controls["removeDropZoneId"].valueChanges.pipe(
      tap(x => {
        if ( (retVal.get("columnFormGroups").value as UntypedFormArray).length === 1 && this.columnFormGroups.value[0] !== undefined) {
          const spliceIndex = this.columnFormGroups.value[0].cdkDropListData.findIndex(c => (c as ControlContainerComponent).form.get("controlName").value === "Columns" &&
            (c as ColumnSplitterComponent).componentForm.get("menuLanchedByContainerId").value == x)
          // Index is only found when deleting a top level component, but a splice on -1 === a splice on last element, and we only want to remove
          // Component if it was deleted, not if a composed component within it was deleted...
            if (spliceIndex > -1) {
              this.columnFormGroups.value[0].cdkDropListData.splice(spliceIndex,1);
            }
        }
      }),
      takeUntil(merge(this.destroyingComponent$, newSubComponent.destroyingComponent$)),
    ).subscribe();
  }

  public dropListsForContainedColumns() : string[] {
    return this.componentForm.get("connectedDropLists").value;
  }

     // ******************** Members required for live view of formly form.   ******************* //

     public buildColumnSplitterFormGroup(newColumnSplitterComponent: ColumnSplitterComponent) : UntypedFormGroup {
      const retVal = ColumnSplitterComponent.buildColumnSplitterFormGroup();
      retVal.patchValue({connectedDropLists: [this.dropListsForContainedColumns()]});
      this.patchChangesToDropZonesAndItems(retVal,newColumnSplitterComponent);
      return retVal;
    }

    public buildSectionFormGroup(newSectionComponent: SectionComponent) : UntypedFormGroup {
      const retVal = SectionComponent.generateSectionFormGroup();
      retVal.patchValue({connectedDropLists: [this.dropListsForContainedColumns()]});
      this.patchChangesToDropZonesAndItems(retVal, newSectionComponent);
      return retVal;
    }

    mutateComponentWhenDroppedInContainer(c: ControlContainerComponent): ControlContainerComponent {
      let retVal: ControlContainerComponent;

      if (c.form.get("composerComponent").value || c.form.get("ControlRequiresFullWidth").value) {
        //When sections are dropped, we must contstruct a new section b/c the existing section patches things to the main form ( as it was instantiated there)
        //But that doesn't work, b/c the responsibility for passing events up and down the chain lies with the parent.
        if (c.form.get("controlName").value === "Section") {
          const droppedSection = c as SectionComponent;
          const sectionComponentToAdd = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog,
            this.formlyUtilityService,this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService,
            this.formlyComponentUtilityService,this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowFieldService);

          sectionComponentToAdd.componentFormGroup = this.buildSectionFormGroup(sectionComponentToAdd);
          if (droppedSection.componentForm.get("activeFormFirestore").value!==null)
          {
            const activeFormFirestoreDocId = droppedSection.componentForm.get("activeFormFirestore").value.DocId();

            sectionComponentToAdd.extraFunctionEndViewInit = () =>
            {
              sectionComponentToAdd.deleteControlsContainedInSection();
              sectionComponentToAdd.unsavedChanges.next(false);
              sectionComponentToAdd.formlyViewInitializing$.next(true);
              const parsedForm = droppedSection.toFormlyFieldConfigJsonOnly() as FormlyFieldConfig;
              sectionComponentToAdd.patchFormlyFieldConfigFromFormFirestore(parsedForm);
              this.formFirestoreService.load$(activeFormFirestoreDocId).pipe(
                tap(x => sectionComponentToAdd.componentForm.patchValue({activeFormFirestore: x, formFirestoreDocId: x.DocId(), formFirestoreSummaryDocId: x.formSummaryDocId})),
                take(1)
              ).subscribe();
            };
          } else {
            sectionComponentToAdd.extraFunctionEndViewInit = () =>
              {
                sectionComponentToAdd.patchFormlyFieldConfigFromFormFirestore(null);
              }
          }

          retVal = sectionComponentToAdd;
          ((droppedSection.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( c => {
            this.componentFormGroup.patchValue({removeDropZoneId:  c["id"]});
          });
          this.componentFormGroup.patchValue({removeDropZoneId: droppedSection.dropZoneIdAssigned});
        } else {
          retVal = c;
        }
      } else {
        const columnSplitterComponent = new ColumnSplitterComponent(this.fb,this.componentFromFormlyFactory,this._ngZone, this.formlyComponentUtilityService, this.formlyUtilityService, this.derivedWorkflowFieldService);
        columnSplitterComponent.columnFormGroup = this.buildColumnSplitterFormGroup(columnSplitterComponent);
        columnSplitterComponent.addColumnAtIndex(0,true);
        const dropListToPushTo = (((columnSplitterComponent.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup).get("cdkDropListData").value as Array<ControlContainerComponent>);
        c.parentContainer = columnSplitterComponent;
        dropListToPushTo.push(c);
        retVal = columnSplitterComponent;
      }
      retVal.parentContainer = this;
      return retVal;
    }

  onTalkDrop(event: CdkDragDrop<ControlContainerComponent[]>) {
    FormElementComponent.emitComponentClicked=true;
    if (event.previousContainer === event.container)  {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.container.data);
        this.componentForm.patchValue({explicitRegenerateFormly: true});
        // this.updatedSectionProperties$.next(true);
      // Moved from a different container.
    } else  {
      const controlWithMutation = this.mutateComponentWhenDroppedInContainer(event.previousContainer.data[event.previousIndex]);
      event.previousContainer.data[event.previousIndex] = controlWithMutation;
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
      this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
      this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.container.data);
      // When an empty section is dropped, it must emit to the section that it is no longer initializing.
      if (event.previousContainer?.id === "sidebarDragDropZone" && controlWithMutation.component.name  === "SectionComponent") {
          (controlWithMutation as SectionComponent).formlyViewInitializing$.next(false);
        }
      }
    this.componentForm.patchValue({itemDrop: event});
    this.changeDetect$.next(null);
  }

  generateFormlyFieldConfigSanSubForms() : FormlyFieldConfig[] {

    return [{
      type: 'formlySection',
      defaultValue: [{}],
      key: `${this.perInstance}-section`,
      fieldGroup: [],

      props: {
        audience: Audience.All,
        className:"",
        changeDetect: this.changeDetect$,
        destroyComponents$: new Subject<any>(),
        itemName: "default item name",
        minimumNumberInstances: 1,
        maximumNumberInstances: 100,
        guid: this.guid,
        formFirestoreDocId: "",
        formFirestoreSummaryDocId: "",
        activeFormFirestore: [],
        priceBookMappings: [],
        unitOfMeasureMappings: [],
      },
      hooks: {
        afterViewInit: (field: FormlyFieldConfig) => {
          setTimeout(() => this.formlyViewInitializing$.next(false), 10000);
        }
      },
      wrappers: ["change-detect"],
    }];
  }


  fixIgnoredValidationErrors(temps: FormlyTemplateOptions) {
    if (temps.minimumNumberInstances === undefined ||  temps.minimumNumberInstances < 0) {
      temps.minimumNumberInstances=0;
     } else if (temps.minimumNumberInstances > temps.maximumNumberInstances) {
      temps.minimumNumberInstances = temps.maximumNumberInstances;
     }

     if (temps.maximumNumberInstances === undefined) {
       if (temps.minimumNumberInstances > 0) {
        temps.maximumNumberInstances = temps.minimumNumberInstances;
       } else {
        temps.maximumNumberInstances = 1;
       }
     }

     if (temps.maximumNumberInstances > 100) {
      temps.maximumNumberInstances = 100;
     } else if (temps.maximumNumberInstances < 1) {
      temps.maximumNumberInstances=1;
     }
  }

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig | FormlyFieldConfig[] {
    const column = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    const idField = {
      'key': '_id',
      'type': 'input',
      'props': {},
      'defaultValue': null,
      'hide': true,
      'resetOnHide' : false,
    };
    const fg = this.retrieveFormlyFieldConfigForContainedElements(column.controls[0] as UntypedFormGroup,true).concat(idField);
    // For working representation ( with add / remove of section instances ) we need to use fieldArray.  But
    // for displaying updates properly in design view, we must use fieldGroup.
    const {fieldGroup,...retVal} = this.fields[0];
    retVal.fieldArray = {
      fieldGroup:fg
    };
    retVal.wrappers = [];
    this.fixIgnoredValidationErrors(retVal.props);
    retVal.hooks = undefined;
    const {changeDetect,destroyComponents$,subPageSectionSizes$,
      addSubPageSection$,removeSubPageSection$,activeFormFirestore,guid,...temps} = retVal.props;
     retVal.props = temps;
    return retVal;
  }

  static stripSectionRepeatConfiguration(input: FormlyFieldConfig) : FormlyFieldConfig {
    const retVal = input;
    const {itemName,minimumNumberInstances,maximumNumberInstances,repeating,collapsable,defaultCollapsed,...retValProps} = input.props;
    retVal.props = retValProps;
    return retVal;
  }

  toFormlyFieldConfig(): FormlyFieldConfig  {

    const column = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    const subForms: FormlyFieldConfig[]=[];
    for (let control of column.controls) {
      const subComponentsFormlyFieldConfigs : FormlyFieldConfig = {};
      subComponentsFormlyFieldConfigs.props = {};
      subComponentsFormlyFieldConfigs.fieldGroup = this.retrieveFormlyFieldConfigForContainedElements(control as UntypedFormGroup,false);
      subForms.push(subComponentsFormlyFieldConfigs);
    }

    const retVal = this.fields[0];
    retVal.props.priceBookMappings = this.componentForm.get("priceBookMappings").value;
    retVal.props.unitOfMeasureMappings = this.componentForm.get("unitOfMeasureMappings").value;
    retVal.fieldGroup = subForms;
    return retVal;
  }

  toggleWorkflowActivationStatus() {
    this.activeFormFirestore.value.formSummary.active = !this.activeFormFirestore.value.formSummary.active;
    this.activeFormFirestore.next(this.activeFormFirestore.value);
    this.formFirestoreSummaryService.update$(this.activeFormFirestore.value.formSummary).pipe(
      take(1)
    ).subscribe();
  }

  patchFormlyFieldConfigToSection(formlyFieldConfig: FormlyFieldConfig) {
    const aGrouping: ControlContainerComponent[] = [];
    const subComponentAfterViewInits: Observable<null>[] = [of(null)];
    if (formlyFieldConfig.type === "input") {
      return;
    }
    if (formlyFieldConfig.fieldArray) {
      formlyFieldConfig.fieldGroup = (formlyFieldConfig.fieldArray as FormlyFieldConfig).fieldGroup;
      formlyFieldConfig.fieldArray=undefined;
    }
    const toIterate = formlyFieldConfig.fieldGroup ? formlyFieldConfig.fieldGroup : [formlyFieldConfig];
    toIterate.forEach(field => {
      var c : ControlContainerComponent;
        if (field.type === "formlySplitter") {
          const val = new ColumnSplitterComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.formlyComponentUtilityService, this.formlyUtilityService, this.derivedWorkflowFieldService);
            val.columnFormGroup = this.buildColumnSplitterFormGroup(val);
            val.columnFormGroup.patchValue({borderColor: field.props["borderColor"], border: field.props["border"]  });
            this.formlyUtilityService.addContainedComponentFormGroup$.next(val.columnFormGroup);
            c = val;
        } else {
          if (field.type === "formlySection") {
            const val = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog,
              this.formlyUtilityService,this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService,
              this.formlyComponentUtilityService,this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowFieldService);
            const subFormGroup = this.buildSectionFormGroup(val);
            if (this.componentFormGroup.get("connectedDropLists").value !== null) {
              this.formlyUtilityService.addContainedComponentFormGroup$.next(subFormGroup);
              val.componentFormGroup = subFormGroup;
              val.maintainDropZoneSettings();
            }
            c = val;
          } else {
            c = this.componentFromFormlyFactory.generateComponent(field);
          }
        }
        c.patchInFormlyFieldConfig(field);
        c.parentContainer = this;
        aGrouping.push(c);
        subComponentAfterViewInits.push(c.afterViewInit$);
      });
      if (this.columnFormGroups.controls[0] !== undefined) {
        (this.columnFormGroups.controls[0] as UntypedFormGroup).patchValue({"cdkDropListData" :aGrouping.reverse()});
      }
      if (formlyFieldConfig.props.priceBookMappings !== undefined) {
        this.componentForm.patchValue({priceBookMappings: formlyFieldConfig.props.priceBookMappings});
      }
      if (formlyFieldConfig.props.unitOfMeasureMappings !== undefined) {
        this.componentForm.patchValue({unitOfMeasureMappings: formlyFieldConfig.props.unitOfMeasureMappings});
      }
      zip(...subComponentAfterViewInits).pipe(
        tap(x => {
          this.formlyViewInitializing$.next(false);
        }),
        take(1)
      ).subscribe();

  }

  patchInSectionConfigurationComponents(formlyConfig: FormlyFieldConfig) {
    const toPatch = ["itemName","minimumNumberInstances","maximumNumberInstances","repeating","collapsable","defaultCollapsed",
    "title","formFirestoreDocId","formFirestoreSummaryDocId","priceBookMappings","unitOfMeasureMappings"];
    toPatch.forEach(x => {
      if (formlyConfig.props[x] !== undefined) {
        this.componentForm.patchValue({[x]: formlyConfig.props[x]});
      }
    });
  }

  patchFormlyFieldConfigFromFormFirestore(formlyConfig: FormlyFieldConfig) : void {

    // Top level workflows are instantiated as sections when we are informing SP of any changes to derived fields that will occur if components are moved to the sidebar.
    // Therefore we must check to ensure that droplists control exists before calling method to subscribe to changes that affect it.
    if (this.componentForm.controls["connectedDropLists"] !== undefined && this.componentForm.controls["id"] !== undefined) {
      this.maintainDropZoneSettings();
    }
    if (formlyConfig !== null) {
      this.patchInSectionConfigurationComponents(formlyConfig);

    // For working representation ( with add / remove of section instances ) we need to use fieldArray.  But
    // for displaying updates properly in design view, we must use fieldGroup.
    if  (formlyConfig.fieldArray!==undefined) {
      formlyConfig.fieldGroup = (formlyConfig.fieldArray as FormlyFieldConfig).fieldGroup.filter(x => x.key !== "_id");
      formlyConfig.fieldArray=undefined;
    }

    //if there are no contained controls in section, we must set view initilizing to false as hook for after view initilized will never fire.
    if (formlyConfig.fieldGroup.length === 0) {
      this.formlyViewInitializing$.next(false);
    }
    formlyConfig.fieldGroup.reverse();
    this.patchFormlyFieldConfigToSection(formlyConfig);
    if (this.columnFormGroups.value[0] !== undefined) {
        SectionComponent.populateMappedImportedDataEntries(this.columnFormGroups.value[0].cdkDropListData,
        this.componentForm.get("priceBookMappings").value, this.unitOfMeasureService, this.priceBookEntryService);
        SectionComponent.populateMappedImportedDataEntries(this.columnFormGroups.value[0].cdkDropListData,
        this.componentForm.get("unitOfMeasureMappings").value, this.unitOfMeasureService, this.priceBookEntryService);
    }
    }
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {

    const designLoad = of(null).pipe(
      filter(() => this.formlyUtilityService.designMode),
      switchMap(() => this.formFirestoreSummaryService.load$(formlyConfig.props["formFirestoreSummaryDocId"])),
      switchMap(f => this.formFirestoreService.load$(f.currentDesignFirestoreDocId)),
      filter(x => x!==null),
      tap(x => this.unsavedChanges.next(false)),
      map(z => this.formlyUtilityService.getSection(JSON.parse(z.form),true,z.title,z.formSummaryDocId,z.DocId())),
      tap(x => this.patchFormlyFieldConfigFromFormFirestore(x)),
      tap(x => super.patchCommonFieldsToForm(x)),
      map(x => this.componentForm.get("formFirestoreSummaryDocId").value),
      take(1),
      switchMap(formFirestoreSummaryDocId => merge(
        // unpopulated firestoreSummaryDocId
        of(null).pipe(filter(() => formFirestoreSummaryDocId ===null)),
        // populated firestoreDocSummaryDocId
        of(null).pipe(filter(() => formFirestoreSummaryDocId !==null),
        switchMap(() => this.formFirestoreSummaryService.load$(this.componentForm.get("formFirestoreSummaryDocId").value).pipe(
          filter(x => x !== null),
          distinctUntilChanged((x,y) => x.currentDeployedFirestoreDocId === y.currentDeployedFirestoreDocId && x.currentDesignFirestoreDocId === y.currentDesignFirestoreDocId)
        )),
        tap(() => this.unsavedChanges.next(false)),
        switchMap(x => this.formFirestoreService.load$(x.currentDesignFirestoreDocId).pipe(
          take(1)
        )),
        filter(x => x?.formSummary !== null),
        tap(x => {
          this.activeFormFirestore.next(x);
          this.componentForm.patchValue({activeFormFirestore: x});
        })))),
        tap(() => this.patchedInFormlyFieldConfig$.next(null)),
      takeUntil(this.destroyingComponent$)
      ).subscribe();
  }

  private buildColumnFormGroup(): UntypedFormGroup {
    if (!this.dropZoneIdAssigned) {
      this.dropZoneIdAssigned = (Math.random().toString(36) + '00000000000000000').slice(2, 14)
    }
  return this.fb.group({
    id: this.dropZoneIdAssigned,
    cdkDropListConnectedTo: [[]],
    cdkDropListData: [[]],
  });
  }

  ngAfterViewInit(): void {
    if (this.extraFunctionEndViewInit !== undefined) {
      this.extraFunctionEndViewInit();
      this.extraFunctionEndViewInit = undefined;
    }

    this.formlyViewInitializing$.pipe(
      filter(x => x === false),
      tap(x => {
        super.ngAfterViewInit();
      }),
      tap(() => this.detectUnsavedChanges()),
      take(1)
    ).subscribe();
  }

  maintainDropZoneSettings(): void {
    const columnFormGroup = (this.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup;
    const id = columnFormGroup.get("id").value;
    this.componentForm.patchValue({addDropZoneId: id});


    // Add subscription to connected drop lists to newly built component.
    this.componentForm.controls["connectedDropLists"].valueChanges.pipe(
      startWith(this.componentForm.get("connectedDropLists").value),
      takeUntil(this.componentForm.controls["removeDropZoneId"].valueChanges.pipe(
        filter(x => x === id))),
        map(q => (q as string[])),
        delay(1),
        //here perhaps
        tap(connectedGroups => columnFormGroup.patchValue({cdkDropListConnectedTo: connectedGroups.filter(x => x !== id)})),
        takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  detectUnsavedChanges() {

    const detectUnsavedChangesInterval = 500;

    const updatedSubComponent = interval(detectUnsavedChangesInterval).pipe(
      filter(() => this.unsavedChanges.value === false),
      filter(() => this.formlyUtilityService.sidebarDebounceActive$.value === false),
      filter(() => this.columnFormGroups.controls.length > 0),
      map(() => {
          const f = this.columnFormGroups.controls[0].get('cdkDropListData').value.filter(x => x.form.get("controlName").value !== "Section"
        && x.serializeToDatabase).map(x => x.toFormlyFieldConfigJsonOnly());
        f.forEach(form => this.formlyUtilityService.stripKeysAllTheWayDown(form));
        return JSON.stringify(f);
      }),
      pairwise(),
      filter(x => x[0] !== x[1]),
      );

      const addedOrRemovedSection = interval(detectUnsavedChangesInterval).pipe(
        filter(() => this.unsavedChanges.value === false),
        map(() =>  {
          return this.columnFormGroups.controls[0] && this.columnFormGroups.controls[0].get('cdkDropListData').value.filter(x => x.form.get("controlName").value === "Section"
            && x.form.get("serializeToDatabase").value === true && (x['patchedFromBranch'] === false) &&
            x.form.value?.controlComponentFormGroup?.value?.formFirestoreSummaryDocId !== null).length
        }),
        pairwise(),
        filter(x => x[0] !== x[1] && x[1] !== undefined),
        );

        interval(detectUnsavedChangesInterval).pipe(
        filter(() => this.unsavedChanges.value === false),
        map(() => {
          const repeating = this.componentForm.get("repeating").value === undefined ? false : this.componentForm.get("repeating").value;
          const itemName = this.componentForm.get("itemName").value;
          const minimumNumberInstances = this.componentForm.get("minimumNumberInstances").value;
          const maximumNumberInstances = this.componentForm.get("maximumNumberInstances").value;
          const audience = this.form.get("audience").value;
          const priceBookMappings = this.componentForm.get("priceBookMappings").value;
          const unitOfMeasureMappings = this.componentForm.get("unitOfMeasureMappings").value;
          const collapsable = this.componentForm.get("collapsable").value;
          const defaultCollapsed = this.componentForm.get("defaultCollapsed").value;
          return JSON.stringify({repeating, itemName, minimumNumberInstances, maximumNumberInstances, audience,priceBookMappings, unitOfMeasureMappings, collapsable,
            defaultCollapsed});
        }),
        pairwise(),
        filter(x => x[0] !== x[1]),
        tap(() => this.updatedSectionProperties$.next(true)),
        takeUntil(this.destroyingComponent$)
        ).subscribe();

        const updateTriggeredWithinSection = this.formlyUtilityService.triggerUnsavedChangeDetection$.pipe(
          filter(x => this.derivedWorkflowFieldService.GetComponentFromDropListId(x,this) !== null),
          );

        merge(updateTriggeredWithinSection,updatedSubComponent, addedOrRemovedSection, this.updatedSectionProperties$.pipe(filter(x=> x === true)),
          ...(this.columnFormGroups.controls[0] as any).controls.cdkDropListData.value
          .filter(x => (x as any).component.name === "SectionComponent")
        .map(y => (y as any).updatedSectionProperties$.pipe(debounceTime(150)))).pipe(
        tap(() => this.unsavedChanges.next(true)),
        takeUntil(merge(this.destroyingComponent$, this.formlyViewInitializing$.pipe(filter(x => x === true)))),
      ).subscribe();

  }

  ngOnInit(): void {

    this.patchControlComponentsToFormlyFields();
    super.patchValuesToContainedComponentsAsNeeded();

    this.unsavedChanges.pipe(
      filter(x => x === true),
      filter(() => this.automaticallySaveForm),
      // Initial save must be manual, even in auto save mode as there is no name or catagory assigned.
      filter(() => this.activeFormFirestore.value !== undefined && this.activeFormFirestore.value !== null),
      tap(() => this.autoSaveForm()),
      tap(x => {
      console.log("Saved Form");
      }),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    this.componentForm.get("repeating").valueChanges.pipe(
      startWith(this.componentForm.get("repeating").value),
      tap(x => {
        if (x) {
          this.componentForm.get("minimumNumberInstances").enable();
          this.componentForm.get("maximumNumberInstances").enable();
        } else {
          this.componentForm.get("minimumNumberInstances").disable();
          this.componentForm.get("maximumNumberInstances").disable();
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.componentForm.get("collapsable").valueChanges.pipe(
      startWith(this.componentForm.get("collapsable").value),
      tap(x => {
        if (x) {
          this.componentForm.get("defaultCollapsed").enable();
        } else {
          this.componentForm.patchValue({defaultCollapsed: false});
          this.componentForm.get("defaultCollapsed").disable();
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
    this.maintainDropZoneSettings();
  }

}
