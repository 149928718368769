import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { EmployeePermission, EmployeePermissionDateRange } from '../../../../../common/src/data/dao/employee-permission';
import { EmployeePermissionTreeService, EmployeePermissionTreeStructure } from 'web-app/src/app/employee-permission-tree.service';
import { EmployeeRole } from '../../../../../common/src/data/dao/employee-role';
import { EmployeeRoleService } from '../../../../../common/src/data/dao-services/employee-role.service';
import { ChecklistDatabase, ItemCatagoryNode } from 'web-app/src/app/multiselect-nested-tree/multiselect-nested-tree.component';
import { randomElementName } from '../../../../../common/src/util/util';
import {SkillSet} from "../../../../../common/src/data/dao/skill-set";
import { SettingsTableColumnDefinition } from '../settings-table/settings-table.component';
import {SkillSetService} from "../../../../../common/src/data/dao-services/skill-set.service";
import {compareWithDocIds} from '../../../../../common/src/util/util';

@Component({
  selector: 'app-role-settings',
  templateUrl: './role-settings.component.html',
  styleUrls: ['./role-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleSettingsComponent implements OnInit, OnDestroy {

  roleForm: UntypedFormGroup;
  employeeSkillsForm: UntypedFormGroup
  employeeRole: EmployeeRole;
  permissionAdded$ = new Subject<EmployeePermissionTreeStructure[]>();
  permissionRemoved$ = new Subject<EmployeePermissionTreeStructure[]>();
  _checkListDatabase :ChecklistDatabase<EmployeePermissionTreeStructure> = undefined;
  _roleChecklistDatabase: ChecklistDatabase<EmployeeRole> = undefined;
  permissionsLoadingFromRole: boolean = false;

  roleFieldsToPath = ["active","name", "scheduleFieldCallsFor"];

  skillSet$ : BehaviorSubject<SkillSet[]> = new BehaviorSubject<SkillSet[]>([]);
  skillColumns : SettingsTableColumnDefinition[] = [{name: 'name', friendlyName: 'Name', type: "string"}];

  activeSkill = new SkillSet();

  destroyingComponent$ = new Subject();
  changedRole$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  get rolesWhichCanBeUpdated() {
    return this.roleService.loadAll$().pipe(
      map(roles => roles.filter(r => r.DocId() !== EmployeeRole.CustomRoleDocId)));
  }

  constructor(public roleService: EmployeeRoleService, private snackBar: MatSnackBar, private employeePermissionTreeService:EmployeePermissionTreeService,
    private fb:UntypedFormBuilder, private skillSetService: SkillSetService, private employeeRoleService: EmployeeRoleService ) {
      this.skillSetService.loadAll$().pipe(
        takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.skillSet$.next(x));
  }

  initilizeSkillForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      name: [[], Validators.required],
      description: [],
      applicibleEmployeeRoles: [[]],
      docId: [],
    });
    return retVal;
  }

  initilizeRoleForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      employeeRole: [],
      employeePermissions: [[]],
      active: [true],
      name: [],
      scheduleFieldCallsFor: [],
    });

    retVal.get("employeeRole").valueChanges.pipe(
      filter(r => r !== null),
      tap(r => {
        this.employeeRole = r;
        this.patchRole(this.employeeRole);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    return retVal;
  }

  setupAssignedEmployeeSkills() : void {
    const selectedNodes : ItemCatagoryNode<EmployeeRole>[] = [];
    this.activeSkill.applicibleEmployeeRoles.forEach(role => {
      const active = this._roleChecklistDatabase.findNode(role);
      if (active !== undefined) {
        selectedNodes.push(active);
      }
    });
      this._roleChecklistDatabase.selectedNodes.next(selectedNodes);
  }

  setupEmployeeRolePermissions() : void {
    const removePermission = this.permissionRemoved$.pipe(
      filter(() => !this.permissionsLoadingFromRole),
      tap(removedPermissions => {
        const permissions = (this.roleForm.get("employeePermissions").value as EmployeePermission[]);
        removedPermissions.filter(x => x.employeePermission !== undefined).forEach(permission => permissions.splice(permissions.indexOf(permission.employeePermission), 1));
      }),
    );

    const addPermission = this.permissionAdded$.pipe(
      filter(() => !this.permissionsLoadingFromRole),
      tap(addedPermissions => {
        const permissions = (this.roleForm.get("employeePermissions").value as EmployeePermission[]);
        addedPermissions.filter(x => x.employeePermission !== undefined).forEach(permission => {
          if (permission.employeePermission.permissionType === "static") {
            permissions.push(permission.employeePermission)
          } else {
            if (permission.employeePermission.permissionType === "dateRange") {
              const shallowClone = new EmployeePermissionDateRange({...permission.employeePermission});
              shallowClone.docId = undefined;
              shallowClone.rootPermission=false;
              this.reflectNumberDaysFromFormToPermission(permission.form, shallowClone);
              this.reflectNumberDaysCatagoryFromFormToPermission(permission.form,shallowClone);
            permissions.push(shallowClone);
            }
          }
        });
      }),
    );

    merge(removePermission,addPermission).pipe(
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  ngOnInit(): void {
    this.roleForm = this.initilizeRoleForm();
    this.employeeSkillsForm = this.initilizeSkillForm();

    // Once user clicks new role, or selects role.
    this.roleForm.get("employeeRole").valueChanges.pipe(
      filter(e=>e!==null),
      tap(() => this.setupEmployeeRolePermissions()),
      take(1)
    ).subscribe();
  }

  newRole() {
    const r = new EmployeeRole();
      this.roleForm.patchValue({employeeRole: r});
  }

  skillAddedToRole(employeeRoles: EmployeeRole[]) {
    const activeRoles = (this.employeeSkillsForm.get("applicibleEmployeeRoles").value as EmployeeRole[]);
    activeRoles.push(...employeeRoles);
    this.employeeSkillsForm.patchValue({applicibleEmployeeRoles: activeRoles});
  }

  skillRemovedFromRole(employeeRoles: EmployeeRole[]) {
    const activeRoles = (this.employeeSkillsForm.get("applicibleEmployeeRoles").value as EmployeeRole[]);
    employeeRoles.forEach(employeeRole => {
      activeRoles.splice(activeRoles.indexOf(employeeRole),1);
    });
    this.employeeSkillsForm.patchValue({applicibleEmployeeRoles: activeRoles});
    }

  compareWithDocIds(o1:any, o2:any) {
    return compareWithDocIds(o1,o2);
  }

  CheckListDatabase() : ChecklistDatabase<EmployeePermissionTreeStructure> {
    if (this._checkListDatabase === undefined) {
      this._checkListDatabase = new ChecklistDatabase<EmployeePermissionTreeStructure>("Employee Privileges",
      this.employeePermissionTreeService.allNodes,this.employeePermissionTreeService.selectedNodes, (x=>x.parentCatagoryKey),(x => x.name),(x=>x.key), (x=>x.description)
      );
    }
    return this._checkListDatabase;
  }

  RoleCheckListDatabase() : ChecklistDatabase<EmployeeRole> {
    if (this._roleChecklistDatabase === undefined) {
      this._roleChecklistDatabase =   new ChecklistDatabase<EmployeeRole>("Employee Role",
      this.employeeRoleService.loadAll$().pipe(
        map(z => z.filter(x=>x.active))),
        this.employeeRoleService.loadAll$().pipe(
        map(z => z.filter(x=>x.active && this.activeSkill.applicableEmployeeRoleDocIds.includes(x.docId))),
        tap(x => console.log(x,` string`)),),
      (x=>undefined),(x => x.name),(x=>x.name)
      );
    }
    return this._roleChecklistDatabase;
  }


  findMatchingEmployeePermission(p: EmployeePermission) : ItemCatagoryNode<EmployeePermissionTreeStructure> {
    if (p.permissionType === "static") {
      return this._checkListDatabase.findNode(new EmployeePermissionTreeStructure({employeePermission: p}));
    }
    if (p.displayType === "days") {
      const asDateRange = p as EmployeePermissionDateRange;
       return this._checkListDatabase.findNode(new EmployeePermissionTreeStructure({employeePermission: p}),
      {sourceMatchGen: (x=> (x.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId),
        existingMatchGen:  (x=> x.employeePermission === undefined ? null :
          (x.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId === undefined ? null
          : (x.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId)} );
    }
  }

  reflectNumberDaysFromFormToPermission(form: UntypedFormGroup, permission: EmployeePermissionDateRange) {
    form.get("numberDays").valueChanges.pipe(
      startWith(form.get("numberDays").value),
      tap(numDays => permission.numberDaysBack = numDays),
      tap(numDays => permission.numberDaysForward = numDays),
      takeUntil(merge(this.destroyingComponent$,this.changedRole$))
    ).subscribe();
  }

  reflectNumberDaysCatagoryFromFormToPermission(form: UntypedFormGroup, permission: EmployeePermissionDateRange) {
    form.get("dateSelectionType").valueChanges.pipe(
      startWith(form.get("dateSelectionType").value),
      tap(selectionType => {
        let numberDays = undefined;
        if (selectionType === "null") {
          numberDays = 365;
        } else if (selectionType === "0") {
          numberDays = 0;
        }
        if (numberDays !== undefined) {
          permission.numberDaysBack = numberDays;
          permission.numberDaysForward = numberDays;
        }
      }),
      takeUntil(merge(this.destroyingComponent$,this.changedRole$))
    ).subscribe();
  }

  patchRole(r: EmployeeRole) {
    this.permissionsLoadingFromRole = true;
    this.employeePermissionTreeService.resetAllForms();
    this.changedRole$.next(null);
    this._checkListDatabase.selectedNodes.next([]);
    const selectedNodes : ItemCatagoryNode<EmployeePermissionTreeStructure>[] = [];
    this.roleFieldsToPath.forEach(field => {
      this.roleForm.get(field).patchValue(r[field]);
    });
    this.roleForm.patchValue({employeePermissions: r.employeePermissions.map(q=>q)});
    r.employeePermissions.forEach(p => {
      const matchingPermission = this.findMatchingEmployeePermission(p);
      if (p.displayType === "days") {
        this.employeePermissionTreeService.patchPermissionToForm(p,matchingPermission.item.form);
        this.reflectNumberDaysFromFormToPermission(matchingPermission.item.form,p as EmployeePermissionDateRange);
        this.reflectNumberDaysCatagoryFromFormToPermission(matchingPermission.item.form,p as EmployeePermissionDateRange);
      }
      selectedNodes.push(matchingPermission);
    });
    this._checkListDatabase.selectedNodes.next(selectedNodes.filter(x=>x!==undefined));
    this.permissionsLoadingFromRole = false;
  }

  patchFormGroupToRoleSettings() {
    if (this.employeeRole === undefined) {
      this.employeeRole = new EmployeeRole();
    }
    this.employeeRole.employeePermissions = this.roleForm.get("employeePermissions").value;
    this.roleFieldsToPath.forEach(field => this.employeeRole[field] = this.roleForm.get(field).value );
  }

  Cancel() {
    this.roleForm.reset();
    if (this.employeeRole !== null) {
      const p = this.roleService.getCloneOfCachedValue(this.employeeRole.DocId());
      this.roleForm.patchValue({employeeRole : p});
    }
  }

  Save() {
    if (this.roleForm.valid) {
      this.snackBar.open("Saving Updates",undefined);
      this.patchFormGroupToRoleSettings();
      this.roleService.update$(this.employeeRole).pipe(
        take(1),
      ).subscribe(() => this.snackBar.dismiss());
    }
  }

  selectSkill(skillSet: SkillSet) {
    this.employeeSkillsForm.patchValue({name: skillSet.name});
    this.employeeSkillsForm.patchValue({description: skillSet.description});
    this.employeeSkillsForm.patchValue({applicibleEmployeeRoles: skillSet.applicibleEmployeeRoles});
    this.employeeSkillsForm.patchValue({docId: skillSet.docId});
    this.activeSkill = skillSet;
    this.setupAssignedEmployeeSkills()
  }

  patchFormToSkill(skillSet: SkillSet) {
    skillSet.name =  this.employeeSkillsForm.get("name").value;
    skillSet.description = this.employeeSkillsForm.get("description").value;
    console.log(this.employeeSkillsForm.get("applicibleEmployeeRoles").value);
    skillSet.applicibleEmployeeRoles = this.employeeSkillsForm.get("applicibleEmployeeRoles").value;
  }

  saveSkill(event: any){
    if (this.employeeSkillsForm.valid) {
      this.snackBar.open("Saving Updates",undefined);
      const skillSet = this.skillSet$.value.find(x => x.docId === this.employeeSkillsForm.get("docId").value) === undefined ?
        new SkillSet() : this.skillSet$.value.find(x => x.docId === this.employeeSkillsForm.get("docId").value);
      this.patchFormToSkill(skillSet);
      this.skillSetService.update$(skillSet).pipe(
        tap(() => this.snackBar.dismiss()),
        take(1),
        ).subscribe();
    }
  }

  addSkill() {
    this.employeeSkillsForm = this.initilizeSkillForm();
  }
}
