import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class Iphoto extends RetrieveFirestorePropertiesBase<Iphoto> {
    // Path to file on device
    filepath: string;
    // Path to serve image as url from device.
    localWebviewPath: string;
    // Device image originated from, used to filter files needing uploading to files that are on device.
    deviceId: String
    // File on Cloud firestore after uploaded.
    storageUrl: string = null;
    // Control registered to.
    controlKeyAssociatedWith: string;
    // Workflow associated with.
    formModelFirestoreDocId: string | null = null;
    // Image file.
    img: string;
    // stored locally
    storedLocally: boolean = true;
    active: boolean = true;
    encodingString: string = "data:image/jpeg;base64,";

    static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
      .concat(["img","localWebviewPath"]);

    static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames
      .concat(["img","localWebviewPath"]);

    retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Iphoto._firestoreIgnoreDiffTrackingMembers; }

    retrieveFirestoreIgnoredMemberNames() : string[] { return Iphoto._firestoreIgnoredMemberNames; }

    public constructor(init?: Partial<Iphoto>) {
      super();
      Object.assign(this, init);
    }
  }
