import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ImportWorkflowAssignmentService } from '../import-workflow-assignment.service';

@Component({
  selector: 'app-auxiliary-header',
  templateUrl: './auxiliary-header.component.html',
  styleUrls: ['./auxiliary-header.component.scss']
})
export class AuxiliaryHeaderComponent {

constructor(private importWorkflowAssignmentService: ImportWorkflowAssignmentService) {
}

// Catagory is disabled untit all units of measure have been initially set up.
get DisableCatagory() : boolean {
  return false;
}

get DisableUnassigned() : boolean {
  return true;
}

get DisableNew() : boolean {
  return true;
}


  @Input() importedData: boolean = true;
  @Input() importedUnitsOfMeasure: boolean = true;
  @Output() loadImportedData: Subject<string> = new Subject<string>();
  @Output() loadLiveAction: Subject<void> = new Subject<void>();
  @Output() generatePricebookData: Subject<void> = new Subject<void>();

  LiveAction() {
    this.loadLiveAction.next(void 0);
  }

  GeneratePricebookData() {
    this.generatePricebookData.next(void 0);
  }

  LoadUnitsOfMeasure() {
    this.loadImportedData.next("unitsOfMeasure");
  }

  DeleteSectionDefinition() {
    this.importWorkflowAssignmentService.deleteWorkflowAssociationWithPriceBookEntry();
  }

  LoadCatagory() {
    this.loadImportedData.next("catagory");
  }

  LoadUnassigned() {
    this.loadImportedData.next("unassigned");
  }

  LoadNew() {
    this.loadImportedData.next("new");
  }

}
