<ion-app>
  <ion-header class="ion-header-styling">
    <ion-label style="padding-left: 16px;">Add Discount</ion-label>
  </ion-header>
  <ion-content fullscreen>
    <form [formGroup]="form">
      <!-- Discount Catagory -->
      <ion-item>
        <ion-label class="dropdown-width">Discount Catagory</ion-label>
        <ion-select formControlName="discountCatagory" (ionFocus)="focusDiscountCatagory()"
        (ionDismiss)="dismissDiscountCatagory()" (ionBlur)="blurDiscountCatagory()">
          <ion-select-option [value]="discountCatagory" *ngFor="let discountCatagory of discountCatagories">
            {{discountCatagory}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div *ngIf="form.controls['discountCatagory'].errors && (form.controls['discountCatagory'].dirty || form.controls['discountCatagory'].touched)">
        <p style="margin-top: 0px; padding-left: 16px; color: red;" padding>Discount Catagory must be selected.</p>
      </div>
      <!-- Discount Type -->
      <ion-item>
        <ion-label class="dropdown-width">Discount Type</ion-label>
        <ion-select formControlName="discountType" (ionFocus)="focusDiscountType()"
        (ionDismiss)="dismissDiscountType()" (ionBlur)="blurDiscountType()">
          <ion-select-option [value]="discountType" *ngFor="let discountType of discountTypes" >
            {{discountType}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div *ngIf="form.controls['discountType'].errors && (form.controls['discountType'].dirty || form.controls['discountType'].touched)">
        <p style="margin-top: 0px; padding-left: 16px; color: red;" padding>Discount Type must be selected.</p>
    </div>
      <ion-item>
        <ion-label class="number-width">Fixed Discount Amount</ion-label>
        <ion-input formControlName="discountFixedAmount"
        inputmode="decimal" min="0"></ion-input>
      </ion-item>
      <div *ngIf="form.errors?.['discountFixedAmount']">
        <p  class="danger" padding>Discount amount must be specified.</p>
      </div>
      <!-- PERCENT DISCOUNT -->
      <ion-item>
        <ion-label class="number-width">Percent Discount</ion-label>
        <ion-input placeholder="0.00%" appPercentageMask type="text"
        formControlName="discountPercentageDisplay"
        ></ion-input>
      </ion-item>
      <div *ngIf="form.errors?.['discountPercentage']">
        <p  class="danger" padding>Discount percent must be specified.</p>
      </div>
      <!-- Title -->
      <ion-item class="margin-time">
        <ion-label class="label-padding">Title:</ion-label>
        <ion-input formControlName="title"></ion-input>
      </ion-item>
      <div *ngIf="form.controls['title'].errors && (form.controls['title'].dirty || form.controls['title'].touched)">
          <p class="danger" padding>Title is required, and must be longer then 3 characters.</p>
      </div>
      <!-- Description -->
      <ion-item>
        <ion-label style="padding: 10px 0px;">Discount Description: <br><span style="visibility: hidden;">.</span></ion-label>
        <ion-textarea formControlName="description"></ion-textarea>
      </ion-item>
      <!-- Internal Notes -->
      <ion-item>
        <ion-label style="padding: 10px 0px;">Internal Notes: <br><span style="visibility: hidden;">.</span></ion-label>
        <ion-textarea formControlName="internalNotes"></ion-textarea>
      </ion-item>
    </form>
  </ion-content>
  <ion-footer>
    <ion-toolbar class="footer-padding">
      <ion-buttons slot="end">
        <button style="border-radius: 4px;" mat-button class="button-blue" type="submit" (click)="save()">Save</button>
      </ion-buttons>
      <ion-buttons slot="start">
        <button style="border-radius: 4px;" mat-button class="button-gray" (click)="exit()">Cancel</button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</ion-app>
