export class FormlyNodeStructureComponentMapping {
  CalculatedComponentPath: ComponentPath;
  InputComponentPath: ComponentPath;
  firstCommonFormFirestoreSummaryDocId: string;

  public constructor(init?: Partial<FormlyNodeStructureComponentMapping>) {
    Object.assign(this, init);
  }
}

export class ComponentPath {
  //Temporary fields used to calculate the full Node structure mapping.
  public pathToComponentFromArbitraryCommonAncestor: string;
  public formSummariesWithNodeLevel: FormSummaryWithNodeLevel[];


  // Stored with the component path.
  public pathToComponentFromFirstCommonFormSummaryAncestor: string;
  public controlGuid: string;

  public constructor(init?: Partial<ComponentPath>) {
    Object.assign(this, init);
  }
}

export class FormSummaryWithNodeLevel {
  public formSummaryDocId: string;
  public nodeLevel: number;

  public constructor(init?: Partial<FormSummaryWithNodeLevel>) {
    Object.assign(this, init);
  }
}
