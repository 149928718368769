import { Component, OnInit, Input, Output, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, AfterViewInit, QueryList, ViewChildren} from '@angular/core';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { BehaviorSubject, combineLatest, distinctUntilChanged, from, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap, debounce,   delayWhen, delay, skip} from 'rxjs/operators';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { LineItem } from '../../../../../common/src/data/dao/line-item';
import { EXIT_STATUS, LINE_ITEM_CREATION_MODE } from '../line-item-creation/line-item-creation.service';
import { FormlyLineItemService } from '../../form-builder/component-models/formly-controls/formly-line-item/formly-line-item.service';
import { MatDialogSingleFieldModalComponent } from '../../utility/mat-dialog-single-field-modal/mat-dialog-single-field-modal.component';
import { SiteVisitRescheduleModalComponent, SiteVisitRescheduleReasons } from '../../jobs/site-visit-reschedule-modal/site-visit-reschedule-modal.component';
import { DiscountCreationModalComponent } from '../../discount/discount-creation-modal/discount-creation-modal.component';
import { CustomerCommunicationManagementService } from '../../customer-communication/customer-communication-management.service';
import { Discount, DiscountType } from '../../../../../common/src/data/dao/discount';
import { InvoicePayment } from '../../../../../common/src/data/dao/invoice-payment';
import { JobAttentionRequired, JOB_ATTENTION_REQUIRED } from '../../../../../common/src/data/dao/job-attention-required';
import { LineItemService } from '../../../../../common/src/data/dao-services/line-item.service';

import { LineItemCreationModalComponent } from '../line-item-creation/line-item-creation-modal/line-item-creation-modal.component';
import { ModalController } from '@ionic/angular';
import { LineItemCreationIonicComponent } from '../line-item-creation/line-item-creation-ionic';
import { Device, DeviceInfo } from '@capacitor/device';
import { SettingsService } from '../../settings/settings.service';
import { DiscountCreationIonicComponent } from '../../discount/discount-creation-ionic/discount-creation-ionic.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FormlyUtilityService } from '../../form-builder/component-models/formly-controls/formly-utility.service';
import { LineItemControlContext, LineItemControlType } from '../../form-builder/component-models/line-items/line-item-enums';
import { Estimate } from '../../../../../common/src/data/dao/estimate';


export enum Audience {
  All = 1,
  Internal = 2,
  Customer = 3
}

export enum LineItemRemovalReason {
  Unknown = 0,
  WillNotComplete = 1,
  Reschedule = 2,
}

export interface LineItemRemovalWithReason {
  lineItem: LineItem;
  reason: LineItemRemovalReason;
}

@Component({
  selector: 'app-line-item-display',
  templateUrl: './line-item-display.component.html',
  styleUrls: ['./line-item-display.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemDisplayComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() disabled: boolean = false;
  @Input() lineItemControlContext : LineItemControlContext = LineItemControlContext.Unknown;
  @Input() lineItemControlType: LineItemControlType = LineItemControlType.Unknown;
  @Input() intendedAudience: Audience = Audience.All;
  @Input()  lineItems: BehaviorSubject<LineItem[]>;
  // Specific to displaying invoices through table.
  @Input() discounts: BehaviorSubject<Discount[]> = new BehaviorSubject<Discount[]>([]);
  @Input() invoicePayments: BehaviorSubject<InvoicePayment[]> = new BehaviorSubject<InvoicePayment[]>([]);

  @Input()  explicitRerenderRows$: Subject<any> = new Subject<any>();
  @Input()  lineItemCreationMode : LINE_ITEM_CREATION_MODE = LINE_ITEM_CREATION_MODE.INSTANTIATE;
  // This allows us to assign line items, or line item characteristics to 1..n site visits.
  @Input()  siteVisitViewingContextDocId : string = "";
  @Input() expandAllLineItems : boolean = false;

  @Input() completedLineItemOperation$ : ReplaySubject<LineItem>;
  @Input() estimate: Estimate | null = null;


  @Output() removeLineItem$: Subject<LineItemRemovalWithReason> = new Subject<LineItemRemovalWithReason>();
  @Output() editLineItem$: Subject<{old: LineItem, new: LineItem}[]> = new Subject<{old: LineItem, new: LineItem}[]>();
  @Output() addLineItem$: Subject<LineItem> = new Subject<LineItem>();

  @Output() removeDiscount$: Subject<Discount> = new Subject<Discount>();
  @Output() editDiscount$: Subject<{old: Discount, new: Discount}> = new Subject<{old: Discount, new: Discount}>();
  @Output() addDiscount$: Subject<Discount> = new Subject<Discount>();

  @Output() voidInvoicePayment$: Subject<InvoicePayment> = new Subject<InvoicePayment>();

  @Output() jobAttentionRequiredToAdd$: Subject<JobAttentionRequired> = new Subject<JobAttentionRequired>();

  sortedLineItems$: BehaviorSubject<(LineItem|Discount|InvoicePayment)[]> = new BehaviorSubject<(LineItem|Discount|InvoicePayment)[]>([]);
  activelyEditingLineItemDocIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  completedSelection : SelectionModel<string>;
  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;
  get table() : MatTable<any> { return this.tables?.first; }
  tableRendered$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  totalPrice$: Observable<number>;
  totalDuration$: Observable<string>;
  destroyCompoenent$: Subject<any> = new Subject();
  lineItemCreationDialogRef: MatDialogRef<LineItemCreationModalComponent>;
  reasonNotCompletingDialogRef: MatDialogRef<MatDialogSingleFieldModalComponent>;
  rescheduleSiteVisitDialogRef: MatDialogRef<SiteVisitRescheduleModalComponent>;
  discountDialogRef: MatDialogRef<DiscountCreationModalComponent>;

  columnsToDisplay : string[];
  columnsToDisplayStandardText = ['title', 'timeDurationString', 'quantity'];

  columnsToDisplayHumanReadable = new Map([["title", "Title"],  ["timeDurationString", "Duration"],
                                           ["quantity", "Qty"]]);

  footerSecondRowColumns = ['addLineItem'];

   expandedElementDocIds: string[] = [];
   deviceInfo$: ReplaySubject<DeviceInfo> = new ReplaySubject<DeviceInfo>();
   deviceInfo: DeviceInfo;
   sendLineItemToCompleted$: Subject<{lineItem: LineItem, checkedState: boolean}> = new Subject<{lineItem: LineItem, checkedState: boolean}>();
   modifyLineItemsOnInvoice$: Subject<{lineItems: LineItem[], checkedState: boolean}> = new Subject<{lineItems: LineItem[], checkedState: boolean}>();

   modifyLineItemOnInvoiceStreamPerDocIdMap : Map<string, Subject<{lineItem: LineItem, checkedState: boolean}>> = new Map<string, Subject<{lineItem: LineItem, checkedState: boolean}>>();
   sendLineItemsToCompleteStreamPerDocIdMap : Map<string, Subject<{lineItem: LineItem, checkedState: boolean}>> = new Map<string, Subject<{lineItem: LineItem, checkedState: boolean}>>();

   skipSendLineItemsToCompleted: boolean = true;
   guid: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
   constructor(public modalController: ModalController, private ref: ChangeDetectorRef, private dialog: MatDialog, private lineItemService: LineItemService,
    private formlyLineItemService: FormlyLineItemService, private customerCommunicationManagementService: CustomerCommunicationManagementService,
    private settingsService: SettingsService, private formlyUtilityService: FormlyUtilityService) {

     from(Device.getInfo()).subscribe(d => {
       this.deviceInfo = d;
       this.deviceInfo$.next(d);
     });

     const initialCompletedSelection = [];
     const allowMultiSelect = true;
      this.completedSelection = new SelectionModel<string>(allowMultiSelect, initialCompletedSelection, true);
    }

   expandedElementContainsLineItem(lineItem: LineItem | Discount) : boolean {
     return this.expandedElementDocIds.includes(lineItem.DocId());
   }

   toggleExpandedElementView(lineItem: LineItem | Discount | InvoicePayment) {
     const index = this.expandedElementDocIds.indexOf(lineItem.DocId());
     if (index > -1) {
       this.expandedElementDocIds.splice(index, 1);
     } else {
      this.expandedElementDocIds.push(lineItem.DocId());
     }
    }

   editLineItemDisabled(lineItem: LineItem | Discount | InvoicePayment) : boolean {
     if (lineItem instanceof InvoicePayment) {
       return true;
     }
     return this.disabled || this.editLineItem$.observers.length === 0 || this.removeLineItem$.observers.length === 0 || this.addLineItem$.observers.length === 0;
   }


   invoiceSelected() {
    const toAdd: LineItem[] = [];
    for (const completedLineItemDocId of this.completedSelection.selected) {
      if (this.activelyEditingLineItemDocIds$.value.includes(completedLineItemDocId)) {
        window.alert(`Actively editing line item: ${completedLineItemDocId}`);
        return;
      }
      if ( !this.formlyLineItemService.invoice.lineItems.map(x => x.DocId()).includes(completedLineItemDocId) &&
        this.sortedLineItems$.value.map(x => x.DocId()).includes(completedLineItemDocId)) {
        toAdd.push(this.sortedLineItems$.value.find(x => x.DocId() === completedLineItemDocId) as LineItem);
      }
    };
    toAdd.forEach(a => a.completed = true);
    this.modifyLineItemsOnInvoice$.next({lineItems: toAdd, checkedState: true});
   }


  ngAfterViewInit(): void {


    this.editLineItem$.pipe(
      tap(x => {
        x.forEach(lineItem => {
          const expandedIndex = this.expandedElementDocIds.indexOf(lineItem.old.DocId());
          if (expandedIndex > -1) {
            this.expandedElementDocIds.splice(expandedIndex, 1);
            this.expandedElementDocIds.push(lineItem.new.DocId());
          }
        });
      }),
      takeUntil(this.destroyCompoenent$)
    ).subscribe();


    if (this.lineItemControlType === LineItemControlType.LineItemsToComplete) {

      const setCompleted = this.sortedLineItems$.pipe(
        skip(1),
        map(x=> x.map(y => y as LineItem)),
        map(x => x.filter(l => l.completed)),
        tap(x => x.forEach(x => this.completedSelection.select(x.DocId()))),
        tap(() => this.skipSendLineItemsToCompleted = false),
        );

      merge(setCompleted, this.destroyCompoenent$).pipe(
        take(1)).subscribe();

      this.completedSelection.changed.pipe(
        filter(() => this.skipSendLineItemsToCompleted === false),
        tap(change => {
          if (change.added.length > 0) {
            if (this.sortedLineItems$.value.find(x => x.DocId() === change.added[0])) {
              this.sendLineItemToCompleted$.next({lineItem: (this.sortedLineItems$.value.find(x => x.DocId() === change.added[0]) as LineItem), checkedState: true});
            }
          } else {
            if (change.removed.length > 0) {
              if (this.sortedLineItems$.value.find(x => x.DocId() === change.removed[0])) {
                this.sendLineItemToCompleted$.next({lineItem: (this.sortedLineItems$.value.find(x => x.DocId() === change.removed[0]) as LineItem), checkedState: false});
              }
            }
          }
        }),
        takeUntil(this.destroyCompoenent$)
      ).subscribe();

    }

    if (this.expandAllLineItems) {
      this.lineItems.pipe(
        filter(x => x && x.length > 0),
        tap(lineItems => lineItems.forEach(l => this.toggleExpandedElementView(l))),
        take(1)
      ).subscribe();

      this.discounts.pipe(
        filter(x => x && x.length > 0),
        tap(discounts => discounts.forEach(d => this.toggleExpandedElementView(d))),
        take(1)
      ).subscribe();
    }

    this.editDiscount$.pipe(
      takeUntil(this.destroyCompoenent$)
    ).subscribe(this.formlyLineItemService.editDiscountReferencesThroughoutForms$);

    if (this.table!==undefined) {
      this.tableRendered$.next(true);
    } else {
      this.tables.changes.subscribe(
        (next: QueryList<MatTable<any>>) => {
          this.tableRendered$.next(true);
       }
      )
    }

    merge(this.explicitRerenderRows$,
      this.addDiscount$,this.editDiscount$, this.removeDiscount$,
    ).pipe(
        debounce(() => this.tableRendered$),
      tap(() => this.table.renderRows()),
      tap(() => this.ref.markForCheck()),
      takeUntil(this.destroyCompoenent$),
      ).subscribe();

  }

  displayCompletedStyling(lineItem: LineItem) {
    if (this.lineItemControlType === LineItemControlType.LineItemsToComplete) {
      return this.completedSelection.isSelected(lineItem.DocId());
    } else {
      return lineItem.completed && this.lineItemControlType !== LineItemControlType.InvoiceDisplay;
    }
  }

  displayRescheduleStyling(lineItem: LineItem) {
    return lineItem.lineItemNeedsOrHasBeenRescheduled && (this.siteVisitViewingContextDocId === "" || this.siteVisitViewingContextDocId === lineItem.siteVisitReschedulingLineItemDocId);
  }

 ngOnDestroy(): void {
    this.destroyCompoenent$.next(null);
    this.destroyCompoenent$.complete();
  }


  removeLineItemUnknown(l: LineItem | Discount) {
    if (l instanceof LineItem) {
      this.removeLineItem$.next({lineItem: l, reason: LineItemRemovalReason.Unknown});
    } else {
      if (l instanceof Discount) {
        this.removeDiscount$.next(l);
      }
      if (l instanceof InvoicePayment) {
        this.voidInvoicePayment$.next(l);
      }
    }
    this.toggleExpandedElementView(l);
  }

  removeLineItemDisabled(lineItem: LineItem | Discount | InvoicePayment) : boolean {
    if (lineItem instanceof InvoicePayment) {
      return false;
    }
    return this.disabled || this.removeLineItem$.observers.length === 0;
  }

  restoreLineItemDisabled(lineItem: LineItem) : boolean {
    return this.disabled || this.formlyLineItemService.restoreAbandonedLineItem$.observers.length === 0;
  }

  removeLineItemRescheduleDisabled(lineItem: LineItem): boolean {
    return this.disabled || this.formlyLineItemService.removeLineItemsFromInvoice$.observers.length === 0 ||
    this.editLineItem$.observers.length === 0 || this.jobAttentionRequiredToAdd$.observers.length === 0;
  }

  removeLineItemWillNotCompleteDisabled(lineItem: LineItem) : boolean {
    return this.disabled || this.removeLineItem$.observers.length === 0 ||
    this.formlyLineItemService.removeLineItemsFromInvoice$.observers.length === 0 ||
    this.removeLineItem$.observers.length === 0 || lineItem.completed;
  }

  removeLineItemWillNotComplete(lineItem: LineItem) {
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      width        : '500px',
      data: {
      textArea: lineItem.internalNotes,
      content: "Please provide documentation on why item can not be completed."
      }});
    this.reasonNotCompletingDialogRef = this.dialog.open(MatDialogSingleFieldModalComponent, editorConfig);

     // Event dialog closure:
     this.reasonNotCompletingDialogRef.afterClosed().pipe(
      filter(result => result !== undefined),
      tap(x => {
        const l = new LineItem(lineItem);
        l.internalNotes = x;
        if (this.formlyLineItemService.invoice.lineItems.find(y => y.DocId() === l.DocId())) {
          setTimeout(() => this.removeFromInvoice(lineItem), 100);
        }
        this.editLineItem$.next([{old: lineItem,new: l}]);
        setTimeout(() => this.removeLineItem$.next({lineItem: lineItem, reason: LineItemRemovalReason.WillNotComplete}), 100);
        this.collapseLineItem(lineItem);
      }),
      take(1)
      ).subscribe();
  }

  collapseLineItem(lineItem: LineItem) {
    if (this.expandedElementDocIds.findIndex(q => q === lineItem.DocId()) > -1) {
      this.expandedElementDocIds.splice(this.expandedElementDocIds.findIndex(q => q === lineItem.DocId()), 1);
     }
  }


  removeLineItemReschedule(lineItem: LineItem) {
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      width        : '500px',
      data: {
        lineItem: lineItem,
        title: "Delete Line Item",
      }
      });
    this.rescheduleSiteVisitDialogRef = this.dialog.open(SiteVisitRescheduleModalComponent, editorConfig);

     // Event dialog closure:
     this.rescheduleSiteVisitDialogRef.afterClosed().pipe(
      filter(result => result !== undefined),
      // send along job attention that needs stored to database w/ job.
      tap(x => {
        const resecheduleReason = x.reason as SiteVisitRescheduleReasons;
        const jobAttentionRequiredReason = resecheduleReason === SiteVisitRescheduleReasons.NEED_PART ? JOB_ATTENTION_REQUIRED.WAITING_FOR_PART : JOB_ATTENTION_REQUIRED.FIELD_TECH_RESCHEDULED;
        const jobAttention = new JobAttentionRequired({jobAttentionRequiredReason,notes: x.notes, jobAttentionRequiredSubReason: resecheduleReason.toString()});
        jobAttention.additionalTimeNeedingScheduled = x.duration;
        jobAttention.originatingLineItemDocId = lineItem.originatingLineItemDocId !== null ? lineItem.originatingLineItemDocId : lineItem.DocId();
        jobAttention.siteVisitDocId = this.siteVisitViewingContextDocId;
        this.jobAttentionRequiredToAdd$.next(jobAttention);
        this.collapseLineItem(lineItem);
      }),
      // send along changes to line item which need stored to database w/ job.
      map( x => {
        const l = new LineItem(lineItem);
        if (l.originatingLineItemDocId === null) {
          l.originatingLineItemDocId = lineItem.lineItemDocId;
        }
        l.abandoned = false;
        l.completed = false;
        l.lineItemNeedsOrHasBeenRescheduled = true;
        l.createdAt = new Date();
        l.siteVisitReschedulingLineItemDocId = this.siteVisitViewingContextDocId;
        if (l.internalNotes === "") {
          l.internalNotes = x.notes;
        } else {
          l.internalNotes += "\n" + x.notes;
        }
        return l;
        }),
        switchMap(l => this.lineItemService.retrieveDocId(l).pipe(
          map(() => l)
        )),
        tap(l => {
        this.editLineItem$.next([{old: lineItem,new: l}]);
        this.removeFromInvoice(lineItem);
        }),
        take(1)
      ).subscribe();
  }

  discountCreationModal(discount: Discount) {
    const editorConfig = new MatDialogConfig();
    let autoFocus = false;

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : autoFocus,
      width        : '500px',
      data         :
      {
        discount : discount,
      }
      });

    this.discountDialogRef = this.dialog.open(DiscountCreationModalComponent, editorConfig);

    const closedWithDiscount = this.discountDialogRef.afterClosed().pipe(
      filter(x => x),
      tap (x => {
        // CREATE
        if (discount===null) {
          this.addDiscount$.next(x.discount);
        } else {
          if (discount.DocId() !== x.discount.DocId()) {
            this.editDiscount$.next({old: discount, new: x.discount});
          }
        }
      }),
      take(1)).subscribe();
  }

  editLineItemClicked(l: LineItem | Discount)
  {
    if (l instanceof LineItem) {
      this.lineItemCreation(l);
    } else {
      this.discountCreation(l);
    }
  }

  lineItemCreation(lineItem: LineItem) {
    console.log(this.deviceInfo.platform);
    if (this.deviceInfo.platform === 'web' && !this.settingsService.getValue('displayMobileSite') ) {
      this.lineItemCreationModal(lineItem);
    } else {
      this.presentLineItemCreationModal(lineItem);
    }
  }

  discountCreation(discount: Discount) {
    if (this.deviceInfo.platform === 'web' && !this.settingsService.getValue('displayMobileSite') ) {
      this.discountCreationModal(discount);
    } else {
      this.presentDiscountCreationModal(discount);
    }
  }

  lineItemCreationModal(lineItem: LineItem) {
    const editorConfig = new MatDialogConfig();
    let autoFocus = false;
    if (lineItem === null) {
      autoFocus=true;
    }
    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : autoFocus,
      width        : '500px',
      data         :
      {
        lineItem : lineItem,
        lineItemCreationMode: this.lineItemCreationMode,
      }
      });

    this.lineItemCreationDialogRef = this.dialog.open(LineItemCreationModalComponent, editorConfig);

    const closedCanceled = this.lineItemCreationDialogRef.afterClosed().pipe(
      filter(x => !x));

    const closedWithLineItem = this.lineItemCreationDialogRef.afterClosed().pipe(
      filter(x => x),
      tap(x => console.log(x,` string`)),
      map( x => (x as {lineItem: LineItem, exitStatus: EXIT_STATUS, oldLineItem: LineItem})),
      tap (x => {
        if (this.estimate !== null ) {
          x.lineItem.originatingEstimateDocId = this.estimate?.DocId();
        }
        // CREATE
        if (x.exitStatus === EXIT_STATUS.CREATE) {
          // If a selection / additonal modal lanched by "Edit", and workign with concreate inststances of line items
          if (lineItem !== null && this.lineItemCreationMode === LINE_ITEM_CREATION_MODE.INSTANTIATE) {
            this.editLineItem$.next([{old: lineItem, new: x.lineItem}]);
          } else {
            if (lineItem !== null) {
              this.removeLineItem$.next({lineItem, reason: LineItemRemovalReason.Unknown});
            }
            this.addLineItem$.next(x.lineItem);
          }
          // SELECT
        } else {
          if (x.exitStatus === EXIT_STATUS.SELECT) {
            if (lineItem === null) {
              this.addLineItem$.next(x.lineItem);
            }
            else {
              // If line items have same lineItemDocId then line item was passed in to edit,
              // then save was clicked w/o any changes to said line item.  It is a NOOP
              if (lineItem.lineItemDocId !== x.lineItem.lineItemDocId) {
                this.removeLineItem$.next({lineItem, reason: LineItemRemovalReason.Unknown});
                this.addLineItem$.next(x.lineItem);
              }
            }
          }
        if (x.exitStatus === EXIT_STATUS.UPDATE) {
          this.editLineItem$.next([{old: x.oldLineItem, new: x.lineItem}]);
        }
      }
    }));

    merge(closedCanceled, closedWithLineItem).pipe(
      take(1)).subscribe();

  }

  async presentDiscountCreationModal(discount: Discount) {
    const modal = await this.modalController.create({
      component: DiscountCreationIonicComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      componentProps: {
        passedInDiscount: discount,
      }
    });

    from(modal.onWillDismiss()).pipe(
      tap(x => {
        if (x.data && !x.data.dismissed) {
          const d = x.data.discount as Discount;
          if (discount === null) {
            this.addDiscount$.next(d);
          } else {
            if (discount.DocId() !== d.DocId()) {
              this.editDiscount$.next({old: discount, new: d});
            }
          }
        }
      }),
      tap(() => this.ref.markForCheck()),
      take(1)
    ).subscribe();

    return await modal.present();
  }

  async presentLineItemCreationModal(lineItem: LineItem) {
    const modal = await this.modalController.create({
      component: LineItemCreationIonicComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      componentProps: {
        lineItem: lineItem,
        lineItemCreationMode: this.lineItemCreationMode,
      }
    });

    from(modal.onWillDismiss()).pipe(
      tap(x => {
        if (x.data && !x.data.dismissed) {
          const l = x.data.lineItem as LineItem;
          if (lineItem !== null) {
            if (l.completed) {
              this.completedSelection.select(l.DocId());
            }
            this.editLineItem$.next([{old: lineItem, new: l}]);
          } else {
            this.addLineItem$.next(l);
          }
        }
      }),
      tap(() => this.ref.markForCheck()),
      take(1)
    ).subscribe();

    return await modal.present();
  }


  removeFromInvoice(lineItem: LineItem) {
    this.modifyLineItemsOnInvoice$.next({lineItems: [lineItem], checkedState: false});
  }

  displayRestoreMenu(lineItem: LineItem) {
    const abandoned = lineItem.abandoned;
    const rescheduled = lineItem.lineItemNeedsOrHasBeenRescheduled && lineItem.siteVisitReschedulingLineItemDocId === this.siteVisitViewingContextDocId;
    return abandoned || rescheduled;
  }

  restoreInactiveLineItems(lineItem: LineItem) {
    // restore abandoned line items.
    if (lineItem.abandoned) {
      this.formlyLineItemService.restoreAbandonedLineItem$.next({lineItem, siteVisitDocId: this.siteVisitViewingContextDocId});
    } else {
      // restore rescheduled line items
        const l = new LineItem(lineItem);
        l.completed = false;
        l.lineItemNeedsOrHasBeenRescheduled = false;
        l.siteVisitReschedulingLineItemDocId = null;
        this.lineItemService.retrieveDocId(l).pipe(
          tap(() => {
            this.editLineItem$.next([{old: lineItem,new: l}]);
            this.formlyLineItemService.restoreRescheduledLineItem$.next({lineItem, siteVisitDocId: this.siteVisitViewingContextDocId})
          }),
          take(1)
        ).subscribe();
      }
  }

  invoiceWithoutCompletion(lineItem: LineItem) {
    this.modifyLineItemsOnInvoice$.next({lineItems: [lineItem], checkedState: true});
  }

  addToActiveJobDisabled(lineItem: LineItem | Discount) {
    const retVal = this.disabled;
    if (lineItem === null) {
      return retVal;
    }

    if (lineItem instanceof LineItem) {
      return  retVal || (lineItem !== null && lineItem.lineItemPrototypeDocId === undefined) || this.editLineItem$.observers.length === 0
        || this.formlyLineItemService.addLineItemsToJob$.observers.length === 0 ||
        // if there is an active job we are working on, and line item in question is already present we can not add it again
        (this.formlyLineItemService.job !== undefined &&  lineItem.originatingLineItemDocId !== null &&
          this.formlyLineItemService.job.lineItems.map(x=>x.originatingLineItemDocId).includes(lineItem.originatingLineItemDocId)
           )
    } else {
      return retVal || this.addDiscount$.observers.length === 0 ||
      // if there is an active invoice we are working on, and discount in question is already present we can not add it again
      (this.formlyLineItemService.invoice !== undefined &&  this.formlyLineItemService.invoice.discounts.map(x=>x.DocId()).includes(lineItem.DocId()) )
    }
  }

  getTotalPriceLineItem(lineItem: LineItem|Discount|InvoicePayment) {
    if (lineItem instanceof Discount) {
      return lineItem.calculatedDiscountAmount;
    } else {
      if (lineItem instanceof InvoicePayment) {
        return -lineItem.amount;
      } else {
      return lineItem.pricePerItem * lineItem.quantity;
      }
    }
  }

  activateWithWait(toEdit: {old: LineItem,new: LineItem}[]) : Observable<any> {
    console.log(toEdit);

    const obs: Observable<any>[] = [];
    this.editLineItem$.next(toEdit);
    return (of(toEdit).pipe(
        delayWhen(editors => this.completedLineItemOperation$.pipe(
          filter(x=> editors.map(e => e.new.DocId()).includes(x.DocId())),
        )),
      ));
    }

  activateSelectedItems() {

    const lineItemsToAddToWorkNeedingCompleted : LineItem[] = [];
    let addedLineItem = false;

    const toEdit: {old: LineItem,new: LineItem}[] = [];
    const lineItemDocIds$ : Observable<void>[] = [of(void(0))];

    // add line items.
    this.lineItems.value.filter(x=> this.completedSelection.selected.includes(x.DocId())).forEach(lineItem =>
    {
      addedLineItem = true;
      // We add one copy of line item from estimate to work to be completed.
      const l = new LineItem(lineItem);
      if (l.originatingLineItemDocId === null) {
        l.originatingLineItemDocId = lineItem.lineItemDocId;
      }
      lineItemDocIds$.push(this.lineItemService.retrieveDocId(l));
      l.abandoned = false;
      l.completed = false;
      lineItemsToAddToWorkNeedingCompleted.push(l);

      // We create a second copy to reflect the mutation of state of line item on estimate (it is now scheduled)
      const updatedLineItem = new LineItem(lineItem);
      if (updatedLineItem.originatingLineItemDocId === null) {
        updatedLineItem.originatingLineItemDocId = lineItem.lineItemDocId;
      }
      lineItemDocIds$.push(this.lineItemService.retrieveDocId(updatedLineItem));
      updatedLineItem.scheduledFromEstimate = true;
      updatedLineItem.addToActiveJob = false;
      toEdit.push({old: lineItem,new: updatedLineItem});
    });

    // add discounts.
        this.discounts.value.filter(x => x.addToActiveJob).forEach(d => {
          d.addedFromEstimate = true;
        });
        this.formlyLineItemService.addDiscountsToInvoice$.next(this.discounts.value.filter(x=>x.addToActiveJob));
        this.discounts.next(this.discounts.value.map(x=>{
          x.addToActiveJob = false;
          return x;
        }));

      //remove discounts
      this.formlyLineItemService.removeDiscountsFromInvoice$.pipe(
        takeUntil(this.destroyCompoenent$),
      ).subscribe(
        x => this.discounts.next(this.discounts.value.filter(y => !x.map(y => y.DocId()).includes(y.DocId())))
      );

    if (addedLineItem) {
        // Delay is present to ensure there is time for the estimate to update before we next add work to complete ( and thus desubscribe from estimate updates)
        combineLatest(lineItemDocIds$).pipe(
          switchMap(() => this.activateWithWait(toEdit)),
          switchMap(() => this.customerCommunicationManagementService.cancelCustomerCommunicationsForEstimate(this.lineItems.value[0].originatingEstimateDocId,
          // this.customerCommunicationManagementService.cancelCustomerCommunicationsForEstimate(this.lineItems.value[0].originatingEstimateDocId,
            "LineItemDisplayComponent.activateSelectedItems")),
          tap(() => this.formlyLineItemService.addLineItemsToJob$.next(lineItemsToAddToWorkNeedingCompleted)),
          take(1)
          ).subscribe();
    }

  }

  hideAddDiscountWhenUnneeded() : any {
    if (this.lineItemControlType === LineItemControlType.InvoiceDisplay || this.lineItemControlType === LineItemControlType.InvoiceEditor
      || this.lineItemControlType === LineItemControlType.EstimateCreator) {
        return {
          'visibility' : 'inherit'
        };
      } else {
        return {
          'visibility' : 'hidden'
        };
    }
  }

  hideRemoveWhenUnneeded(lineItem: LineItem | Discount | InvoicePayment) : any {
    return {
      'visibility' : 'inherit'
    };
  }

  workToCompleteFromEstimateLabel() : string {
    return "Add to Job";
  }

  workToCompleteFromEstimateButtonLabel() : string {
    return "Activate Selected";
  }

  classesForRows(lineItem: LineItem | Discount | InvoicePayment) : any {
    if (lineItem instanceof LineItem) {
      return {
        'removed': lineItem.abandoned,
        'completed' : this.displayCompletedStyling(lineItem),
        'reschedule': this.displayRescheduleStyling(lineItem),
        'estimate scheduled' : (lineItem.scheduledFromEstimate && this.lineItemControlType === LineItemControlType.EstimateCreator &&
          // if there is an active job we are working on, and line item in question is already present we can not add it again
        (this.formlyLineItemService.job !== undefined &&  lineItem.originatingLineItemDocId !== null &&
          this.formlyLineItemService.job.lineItems.map(x=>x.originatingLineItemDocId).includes(lineItem.originatingLineItemDocId)
        )),
      };
    } else {
      if (lineItem instanceof Discount) {
        return {
          'discount': true,
          'estimate scheduled' : lineItem.addedFromEstimate && this.lineItemControlType === LineItemControlType.EstimateCreator,
        };
      } else {
        return {
          'payment': true,
        };
      }

    }
  }

  addLineItemDisabled(lineItem: LineItem) : boolean {
    return this.disabled || this.editLineItem$.observers.length === 0 || this.removeLineItem$.observers.length === 0 || this.addLineItem$.observers.length === 0;
  }

  addDiscountDisabled(lineItem: LineItem) : boolean {
    return this.disabled || this.editDiscount$.observers.length === 0 || this.removeDiscount$.observers.length === 0 || this.addDiscount$.observers.length === 0;
  }

  addToInvoiceDisabled(lineItem: LineItem) : boolean {
    return this.disabled || lineItem.abandoned || this.formlyLineItemService.addLineItemsToInvoice.observers.length === 0 ||
    this.formlyLineItemService.removeLineItemsFromInvoice$.observers.length === 0;
  }

  removeFromInvoiceDisabled(lineItem: LineItem) : boolean {
    return this.disabled || this.formlyLineItemService.removeLineItemsFromInvoice$.observers.length === 0;
  }

  getDateToSortLineItemsWith(l: LineItem) {
    if (l.originatingOrCurrentLineItemIdForSorting === l.DocId()) {
      return l.createdAt;
    } else {
      if (l.createdAt.getTime() < new Date(2023,2,19).getTime()) {
        return this.lineItemService.get(l.originatingOrCurrentLineItemIdForSorting) !== undefined ? this.lineItemService.get(l.originatingOrCurrentLineItemIdForSorting).createdAt :
        l.createdAt;
      } else {
        return l.createdAt;
      }
    }
  }

  ngOnInit(): void {

    if (this.lineItemControlType === LineItemControlType.LineItemsToComplete) {

      this.completedLineItemOperation$.pipe(
        map(x => x.DocId()),
        tap(docId => {
          const activelyEditingLineItemDocIds = this.activelyEditingLineItemDocIds$.value.filter(x => x !== docId);
          this.activelyEditingLineItemDocIds$.next(activelyEditingLineItemDocIds);
        }),
        takeUntil(this.destroyCompoenent$)
      ).subscribe();

      this.modifyLineItemsOnInvoice$.pipe(
        tap(l => {
          if (l.checkedState) {
            this.formlyLineItemService.addLineItemsToInvoice.next(l.lineItems);
          } else {
            this.formlyLineItemService.removeLineItemsFromInvoice$.next([{lineItem: l.lineItems[0], reason: LineItemRemovalReason.Unknown}]);
          }
        }),
        takeUntil(this.destroyCompoenent$)
      ).subscribe();

      this.sendLineItemToCompleted$.pipe(
        tap(l => {
          if (!this.sendLineItemsToCompleteStreamPerDocIdMap.get(l.lineItem.DocId())) {
            const perLineItemModification = new Subject<{lineItem: LineItem, checkedState: boolean}>();
            perLineItemModification.pipe(
              debounce(sendIt => this.activelyEditingLineItemDocIds$.pipe(map(x => !x.includes(sendIt.lineItem.DocId())), filter(x => x===true))),
              tap(x => this.activelyEditingLineItemDocIds$.next([...this.activelyEditingLineItemDocIds$.value, x.lineItem.DocId()])),
              tap(x => {
                const updated = new LineItem(x.lineItem);
                // Think on this, this is not correct, though perhaps useful.
                // x.lineItem.completed = x.checkedState;
                updated.completed = x.checkedState;
                this.editLineItem$.next([{old: x.lineItem,new: updated}]);
              }),
              takeUntil(this.destroyCompoenent$)
            ).subscribe();
            this.sendLineItemsToCompleteStreamPerDocIdMap.set(l.lineItem.DocId(), perLineItemModification);
          }
        }),
        tap(l => this.sendLineItemsToCompleteStreamPerDocIdMap.get(l.lineItem.DocId()).next(l)),
        takeUntil(this.destroyCompoenent$)
      ).subscribe();
    }


    // To maintain same order, we must ensure that both line items and their prototypes have been retrieved, since we order based on
      //time from epoch of prototype (if exists) or line item (if no prototype)

    const retrieveOriginatingLineItems = this.lineItems.pipe(
      map(x => {
        return {lineItems: x, orgins: x.filter(q => q.originatingLineItemDocId !== null && q.createdAt !== undefined).map(b => b.originatingLineItemDocId) }
      }),
      filter(x => x.orgins.length > 0),
      switchMap(x => this.lineItemService.loadMultiple$(x.orgins).pipe(
        map(() => x.lineItems)
      )));

    const noOrigins = this.lineItems.pipe(
      map(x => {
        return {lineItems: x, orgins: x.filter(q => q.originatingLineItemDocId !== null && q.createdAt !== undefined).map(b => b.originatingLineItemDocId) }
      }),
      filter(x => x.orgins.length === 0),
      map(x => x.lineItems)
    );

    //roll-back prob.
    const lineItemsToSort = merge(noOrigins, retrieveOriginatingLineItems).pipe(
      map(x=> {
        const retVal = [];
        x.forEach(y => {
          retVal.push(new LineItem(y));
      });
      return retVal;
    }));


    // combineLatest([lineItemsToSort.pipe(tap(() => console.log(`A  - ${this.guid}`))),this.discounts.pipe(tap(() => console.log(`B  - ${this.guid}`))), this.invoicePayments.pipe(tap(() => console.log(`C  - ${this.guid}`)))]).pipe(
      combineLatest([lineItemsToSort,this.discounts, this.invoicePayments]).pipe(
      filter( x => !(x[0].length + x[1].length + x[2].length === 0 && this.sortedLineItems$.value.length === 0)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map(x =>  {
        const lineItems = x[0];
        const discounts = x[1];
        const payments = x[2];
        payments.sort((a,b) => { return a.createdAt.getTime() - b.createdAt.getTime(); });
        const retVal : (LineItem|Discount|InvoicePayment)[] = [];
        const removedLineItems = lineItems.filter(y => y.abandoned || y.scheduledFromEstimate);
        const activeLineItems = lineItems.filter(y => (!y.abandoned && !y.scheduledFromEstimate));
        removedLineItems.sort((a, b) =>  this.getDateToSortLineItemsWith(a).valueOf() > this.getDateToSortLineItemsWith(b).valueOf() ? 1 :
        this.getDateToSortLineItemsWith(a).valueOf() === this.getDateToSortLineItemsWith(b).valueOf() ? (a.DocId() > b.DocId() ? 1 : -1) :
         -1);
        activeLineItems.sort((a, b) => this.getDateToSortLineItemsWith(a).valueOf() > this.getDateToSortLineItemsWith(b).valueOf() ? 1 :
        this.getDateToSortLineItemsWith(a).valueOf() === this.getDateToSortLineItemsWith(b).valueOf() ? (a.DocId() > b.DocId() ? 1 : -1) :
         -1);
        retVal.push(...activeLineItems.concat(removedLineItems));
        // the value of discount ( if %) is dynamically set based on the active line items.  Sort so that fixed discounts are taken into account b/f % discounts.

        const fixedDiscounts = discounts.filter(y => y.discountType === DiscountType.FIXED);
        const percentDiscounts = discounts.filter(y => y.discountType === DiscountType.PERCENTAGE);
        percentDiscounts.sort((a, b) => a.discountPercentage > b.discountPercentage ? 1 : -1);
        fixedDiscounts.sort((a, b) => a.createdAt.valueOf() > b.createdAt.valueOf() ? 1 : -1);
        let totalBeforeDiscountApplied = lineItems.length === 0 ? 0 :
              lineItems.map(t => t.pricePerItem * t.quantity).reduce((acc, value) => acc + value, 0);
        fixedDiscounts.forEach(d => d.setCalculatedDiscountAmount(totalBeforeDiscountApplied));
        totalBeforeDiscountApplied = totalBeforeDiscountApplied + fixedDiscounts.map(d => d.calculatedDiscountAmount).reduce((acc, value) => acc + value, 0);
        percentDiscounts.forEach(d => d.setCalculatedDiscountAmount(0));
        if (percentDiscounts.length > 0) {
          percentDiscounts[0].setCalculatedDiscountAmount(totalBeforeDiscountApplied);
        }
        retVal.push(...fixedDiscounts.concat(percentDiscounts));
        retVal.push(...payments);
        return retVal;
      }),
      tap(x => this.sortedLineItems$.next(x)),
      delay(1),
      tap(() => this.explicitRerenderRows$.next(true)),
      takeUntil(this.destroyCompoenent$)
    ).subscribe();

    switch (this.lineItemControlType) {
      case LineItemControlType.Unknown:
        // this.columnsToDisplay = ['title', 'timeDurationString', 'quantity', 'price',"docId"];
        this.columnsToDisplay = ['title', 'timeDurationString', 'quantity', 'price'];
        break;
      case LineItemControlType.LineItemsToComplete:
        this.columnsToDisplay = ['title', 'quantity', 'price', "addToInvoice"];
        break;
        // this.columnsToDisplay = ['title',  'quantity', 'price'];
        // break;
      case LineItemControlType.EstimateCreator:
        this.columnsToDisplay = ['title', 'quantity', 'price','addToWorkToComplete'];
        break;
      case LineItemControlType.InvoiceDisplay:
        this.columnsToDisplay = ['title', 'quantity', 'price'];
        break;
      case LineItemControlType.InvoiceEditor:
        this.columnsToDisplay = ['title', 'quantity', 'price'];
        break;
      default: throw new Error("LineItemControlType not implemented");
    }

    this.totalDuration$ = this.lineItems.pipe(
      map(lineItems => {
        if (lineItems !== undefined && lineItems.length > 0) {
               return lineItems.map(t => t.expectedDurationHours).reduce((acc, value) => acc + value, 0);
             } else {
               return 0;
             }
      }),
      map(totalHours => LineItem.timeDurationStringFromHours(totalHours)),
      );

    this.totalPrice$ = this.sortedLineItems$.pipe(
      map(lineItems => {
        if (lineItems !== undefined && lineItems.length > 0) {
          // Line items have idea of being "abandoned" ( when SP determines they will not be completed)
          // When totalling up price for line items, we must remove any abandoned line itmes from the total.
          const outsideAbandoned =  lineItems.map(t => this.getTotalPriceLineItem(t)).reduce((acc, value) => acc + value, 0);
          const abandoned = lineItems.map(x => x as LineItem)
            .filter(y => y.lineItemPrototypeDocId !== undefined && y.abandoned)
            .map(t => this.getTotalPriceLineItem(t)).reduce((acc, value) => acc + value, 0);
          return outsideAbandoned - abandoned;
         } else {
           return 0;
         }
      }),
      );
  }

}
