import { RetrieveFirestorePropertiesBase } from "../../database-backend/retrieve-firestore-properties";

export class CostStructure extends RetrieveFirestorePropertiesBase<CostStructure>{

  title: string;
  markup: number;
  code: string;
  importLastUpdated: Date;

  public constructor(init?: Partial<CostStructure>) {
    super();
    Object.assign(this, init);
  }
}
