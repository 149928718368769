export const skipAllMessages = [
  "Missing or insufficient permissions.",
]

export const skipMobileMessages = skipAllMessages.concat(
[
  "null is not an object (evaluating 'element.nodeType')"
]
);

export const skipWebMessages = skipAllMessages.concat(
[
  "Cannot read properties of null (reading 'nodeType')"
]
);

