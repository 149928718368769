<div class="header-label header-border">Invoices</div>
<div class="line-item-container">
  <table mat-table [dataSource]="invoices | async"
  multiTemplateDataRows class="mat-elevation-z8" style="background-color: rgba(0,0,0,0); width: 100%;">

  <!-- Amount paid to date.-->
<ng-container matColumnDef="amountPaidToDate">
  <th mat-header-cell *matHeaderCellDef>Paid To Date</th>
  <td mat-cell *matCellDef="let invoice">{{invoice.amountPaid | currency}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef> {{totalAmountPaid$ | async | currency}} </td>
</ng-container>

<!-- Total amount due-->
<ng-container matColumnDef="totalAmountDue">
  <th mat-header-cell *matHeaderCellDef>Total Invoiced</th>
  <td mat-cell *matCellDef="let invoice">{{invoice.totalAmount | currency}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef> {{totalAmount$ | async | currency}} </td>
</ng-container>

<!-- Outstanding due-->
<ng-container matColumnDef="outstandingDue">
  <th mat-header-cell *matHeaderCellDef>Outstanding Due</th>
  <td mat-cell *matCellDef="let invoice">{{invoice.outstandingDue | currency}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef> {{totalOutstandingAmount$ | async | currency}} </td>
</ng-container>

<!-- Due Date -->
<ng-container matColumnDef="dueDate">
  <th mat-header-cell *matHeaderCellDef>Due Date</th>
  <td mat-cell *matCellDef="let invoice">{{invoice.dueDate | date:'medium'}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Date Created -->
<ng-container matColumnDef="dateCreated">
  <th mat-header-cell *matHeaderCellDef>Created On</th>
  <td mat-cell *matCellDef="let invoice">{{invoice.createdAt | date:'medium'}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Customer Link -->
<ng-container matColumnDef="customerName">
  <th mat-header-cell *matHeaderCellDef>Billing Customer</th>
  <td mat-cell *matCellDef="let invoice">
    <a routerLink="/app-customer-page/{{invoice.billingCustomer.DocId()}}">
      {{invoice.billingCustomer.customerName}}
    </a>
    <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
  </td>
</ng-container>

<!-- Send along Invoice -->
<ng-container matColumnDef="sendInvoice">
  <th mat-header-cell *matHeaderCellDef>Send Invoice</th>
  <td mat-cell *matCellDef="let invoice">
    <div *ngIf="!invoice.sentInitially; else elseBlock">
      <button mat-button class="button-yellow" (click)=sendInvoice(invoice)>Send To Customer</button>
    </div>
    <ng-template #elseBlock>

    </ng-template>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- When last invoice communication sent out. -->
<ng-container matColumnDef="lastCommunication">
  <th mat-header-cell *matHeaderCellDef>Last Communication</th>
  <td mat-cell *matCellDef="let invoice">
    {{invoiceSentDateString(invoice)}}
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>


<!-- Add to Journal Entry -->
<ng-container matColumnDef="addToJournalEntry">
  <th mat-header-cell *matHeaderCellDef>Add </th>
  <td mat-cell *matCellDef="let invoice">
      <mat-checkbox disabled="{{invoice.outstandingDue == 0}}" [(ngModel)]="invoice.addToJournalEntry" >Assign Payment</mat-checkbox>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
    <button mat-button class="button-blue" disabled="{{disableWhenNoInvoiceSelected()}}" (click)="applyPayment()">Apply Payment</button>
  </td>
</ng-container>

<!-- Display line items -->
<ng-container matColumnDef="invoiceDetails">
  <th mat-header-cell *matHeaderCellDef>Details</th>
  <td mat-cell *matCellDef="let invoice">
    <button mat-button class="button-light-blue" (click)=openInvoice(invoice)>Open</button>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
  </td>
</ng-container>

<!-- Amount To Apply -->
<ng-container matColumnDef="allocateToJournalEntry">
  <th mat-header-cell *matHeaderCellDef>Amount to apply</th>
  <td mat-cell *matCellDef="let invoice">
    <div style="width:170px;" class="journal-amount">
      <div class="form-align journal-amount">
          <mat-form-field appearance="outline" style="width:170px;">
      <input currencyMask [options]="{align: 'left'}" placeholder="Amount" matInput [(ngModel)]="invoice.AmountAllocatedToJournalEntry"  (ngModelChange)="updateAmountAllocated$.next(null)">
    </mat-form-field>
  </div>
</div>
    </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef> {{totalAllocated$ | async | currency}} </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
<tr mat-row *matRowDef="let element; columns: columnsToDisplay;"class="example-element-row">
</tr>
<tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
  </table>
</div>
