import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { combineLatest, filter, map, merge, ReplaySubject, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { PriceBookUnitOfMeasure } from '../../../../../common/src/data/dao/pricebook/pricebook-unit-of-measure';
import { PriceBookEntry } from '../../../../../common/src/data/dao/pricebook/pricebook-entry';
import { PriceBookEntryService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-entry.service';
import { PriceBookUnitOfMeasureService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {compareWithDocIds} from '../../../../../common/src/util/util';
import { PriceBookCatagoryService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-catagory.service';
import { PriceBookCatagory } from '../../../../../common/src/data/dao/pricebook/pricebook-catagory';
import { ImportWorkflowAssignmentService } from '../import-workflow-assignment.service';

enum DataViewOption {
  UnitsOfMeasure,
  Category,
  Unassigned,
  New
}

@Component({
  selector: 'app-workflow-data-import',
  templateUrl: './workflow-data-import.component.html',
  styleUrls: ['./workflow-data-import.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WorkflowDataImportComponent implements AfterViewInit, OnInit, OnDestroy {

  importedDataToAssign$: Subject<(PriceBookUnitOfMeasure|PriceBookEntry)[]> = new Subject<(PriceBookUnitOfMeasure|PriceBookEntry)[]>();
  finessedImportedData$: ReplaySubject<(PriceBookUnitOfMeasure|PriceBookEntry)[]> = new ReplaySubject<(PriceBookUnitOfMeasure|PriceBookEntry)[]>(1);

  columnsToDisplay : string[] = [];
  columnsToDisplayOutsideTruthSource = ["code","itemCode","UOM","divideByCalc","totalCost","priceBookCatagoryString" ];
  columnsToDisplayWeakTruthSource = ["title", "notes"];
  //formFirestoreSummaryTitle is also vanguard source of truth.
  columnsToDisplayVanguardTruthSource = ["createdAt","active"];
  columnsToDisplayStandardText = this.columnsToDisplayOutsideTruthSource.concat(this.columnsToDisplayWeakTruthSource).concat(this.columnsToDisplayVanguardTruthSource)
    .filter(x => x !== "createdAt");

  columnsToDisplayHumanReadable = new Map([["code", "SKU"],["itemCode", "SKU"],["totalCost","Total Cost"],["title","Title"],
    ["notes","Notes"],["createdAt","Imported Date"],["active","Active"], ["priceBookCatagoryString","Catagory"],["productDescription","Description"],]);

  expandedInformationToDisplay: string[] = [];
  expandedElementDocIds: string[] = [];

  importedDataSource = new MatTableDataSource<(PriceBookUnitOfMeasure|PriceBookEntry)>();

  tableHeader : string = "";
  activeDataViewOption$: ReplaySubject<DataViewOption> = new ReplaySubject<DataViewOption>(1);

  catagoryForm: FormControl<PriceBookCatagory>;
  priceBookEntries: PriceBookEntry[] = [];
  catagoriesToSelectFrom: PriceBookCatagory[] = [];
  destroyComponent$: Subject<void> = new Subject<void>();
  catagoriesLoaded$: Subject<boolean> = new Subject<boolean>();
  priceBookEntriesLoaded$: Subject<boolean> = new Subject<boolean>();


  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;
  get table() : MatTable<any> { return this.tables?.first; }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private route: ActivatedRoute, private priceBookEntryService: PriceBookEntryService,
    private priceBookUnitOfMeasureService: PriceBookUnitOfMeasureService, private fb: FormBuilder, public priceBookCatagoryService: PriceBookCatagoryService,
    private ref: ChangeDetectorRef, public importWorkflowAssignmentService: ImportWorkflowAssignmentService) {

      this.catagoryForm = this.fb.control<PriceBookCatagory>(null);

      // this.importedDataSource.paginator = this.paginator;
      // this.importedDataSource.sort = this.sort;
  }
  ngOnDestroy(): void {
    this.importWorkflowAssignmentService.workflowAssignmentActive = false;
    this.destroyComponent$.next();
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map(x => x.get('importedCat')),
      tap(cat => {
        switch (cat) {
          case "unitsOfMeasure":
            this.activeDataViewOption$.next(DataViewOption.UnitsOfMeasure);
            this.loadUnitsOfMeasureView();
            break;
          case "catagory":
            this.activeDataViewOption$.next(DataViewOption.Category);
            this.loadCatagoryView();
            break;
          case "unassigned":
            this.activeDataViewOption$.next(DataViewOption.Unassigned);
            this.loadUnassignedView();
            break;
          case "new":
            this.activeDataViewOption$.next(DataViewOption.New);
            this.loadNewEntriesView();
            break;
          default:
            break;
        }
      })
    ).subscribe();
  }

  loadNewEntriesView() {
    this.tableHeader = "New";
  }

  loadUnassignedView() {
    this.tableHeader = "Unassigned";
  }

  loadCatagoryView() {
    this.tableHeader = "Catagory";
    this.displayPriceBookEntriesColumns();
    this.loadPriceBookEntries();
    this.importedDataToAssign$.next([]);
    combineLatest([this.priceBookEntriesLoaded$, this.catagoriesLoaded$]).pipe(
      switchMap(() => this.catagoryForm.valueChanges.pipe(
        startWith(this.catagoryForm.value)
      )),
      tap(() => this.importWorkflowAssignmentService.workflowAssignmentActive = true),
      map(x => {
        if (x.code === "ALL") {
          return this.priceBookEntries;
        } else {
          return this.priceBookEntries.filter((entry) => entry.priceBookCatagory.DocId() === x.DocId());
        }
      }),
      tap((uoms) => this.importedDataToAssign$.next(uoms)),
      takeUntil(this.destroyComponent$)
    ).subscribe();
  }

  loadUnitsOfMeasureView() {
    this.tableHeader = "Units of Measure";
        this.displayPriceBookUnitsOfMeasureColumns();
        this.loadUnitsOfMeasure();
        this.importWorkflowAssignmentService.workflowAssignmentActive = true;
  }

  ngAfterViewInit(): void {

    this.priceBookEntryService.loadAll$().pipe(
      tap((entries) => this.priceBookEntries = entries),
      tap(() => this.table.renderRows()),
      tap(() => this.ref.markForCheck()),
      tap(() => this.priceBookEntriesLoaded$.next(true)),
      takeUntil(this.destroyComponent$)
    ).subscribe();

  this.importWorkflowAssignmentService.externalSelectedWorkflowAssignmensUpdated$.pipe(
    tap(() => this.table.renderRows()),
    tap(() => this.ref.markForCheck()),
    takeUntil(this.destroyComponent$)
  ).subscribe();

    this.priceBookCatagoryService.loadAll$().pipe(
      map(x => [new PriceBookCatagory({name: "All", code: "ALL", docId: "ALL"})].concat(x.sort((a,b) => a.name.localeCompare(b.name)))),
      tap((catagories) => this.catagoriesToSelectFrom = catagories),
      tap(() => this.catagoryForm.patchValue(this.catagoriesToSelectFrom.find(x => x.code === "ALL"))),
      tap(() => this.catagoriesLoaded$.next(true)),
      takeUntil(this.destroyComponent$)
    ).subscribe();

    this.finessedImportedData$.pipe(
      tap(data => {
        this.importedDataSource.data = data;
      }),
      takeUntil(this.destroyComponent$)
    ).subscribe();
  }

  SelectColor(c: PriceBookEntry | PriceBookUnitOfMeasure) {
    if ((c as PriceBookEntry).formFirestoreSummaryDefiningPriceBookEntry !== undefined && (c as PriceBookEntry).formFirestoreSummaryDefiningPriceBookEntry !== null) {
      return {'background-color': 'lightblue'};
    } else {
      return {};
    }
  }

  loadUnitsOfMeasure() {
    this.importedDataToAssign$.pipe(
      map(data => data as PriceBookUnitOfMeasure[]),
      map(data => {
        data.forEach((d) => {
          d['formFirestoreSummaryTitle'] = d.formFirestoreSummary ? d.formFirestoreSummary.title : "NONE";
        });
        return data.sort((a,b) => a.title.localeCompare(b.title));
      }),
      tap(data => this.finessedImportedData$.next(data)),
      takeUntil(merge(this.activeDataViewOption$.pipe(filter(x => x !== DataViewOption.UnitsOfMeasure)), this.destroyComponent$))
    ).subscribe();

    this.priceBookUnitOfMeasureService.loadAll$().pipe(
        tap((uoms) => this.importedDataToAssign$.next(uoms)),
        takeUntil(merge(this.activeDataViewOption$.pipe(filter(x => x !== DataViewOption.UnitsOfMeasure)), this.destroyComponent$))
      ).subscribe();
  }

  loadPriceBookEntries() {
    this.importedDataToAssign$.pipe(
      map(data => data as PriceBookEntry[]),
      map(data => {
        data.forEach((d) => {
          d['formFirestoreSummaryTitle'] = d.formFirestoreSummaryDefiningPriceBookEntry ? d.formFirestoreSummaryDefiningPriceBookEntry.title :
          d.formFirestoreSummaryContainingPriceBookEntries.length > 0 ? d.formFirestoreSummaryContainingPriceBookEntries[0].title :
          d.unitOfMeasure.formFirestoreSummary ? d.unitOfMeasure.formFirestoreSummary.title :
          "NONE";
          d['totalCost'] = d.costs.reduce((acc, cost) => acc + Number(cost.amount), 0);
          d['UOM'] = d.unitOfMeasure ? d.unitOfMeasure.code : "NONE";
          d['priceBookCatagoryString'] = d.priceBookCatagory.name;
        });
        //sort by catagory then by title
        return data.sort((a,b) => a.priceBookCatagoryString.localeCompare(b.priceBookCatagoryString) || a.title.localeCompare(b.title));
      }),
      tap(data => this.finessedImportedData$.next(data)),
      takeUntil(merge(this.activeDataViewOption$.pipe(filter(x => x === DataViewOption.UnitsOfMeasure)), this.destroyComponent$))
    ).subscribe();
  }

  displayPriceBookUnitsOfMeasureColumns() : void {
    this.columnsToDisplay = ["select","code","title","notes","formFirestoreSummaryTitle","createdAt"];
    this.expandedInformationToDisplay = ["Notes"];
  }

  displayPriceBookEntriesColumns() : void {
    this.columnsToDisplay = ["select","code","title","UOM","priceBookCatagoryString","formFirestoreSummaryTitle","createdAt"];
    this.expandedInformationToDisplay = ["divideByCalc", "productDescription", "totalCost","notes"];
  }


  toggleExpandedElementView(elm : PriceBookUnitOfMeasure|PriceBookEntry) {
    const index = this.expandedElementDocIds.indexOf(elm.DocId());
    if (index > -1) {
      this.expandedElementDocIds.splice(index, 1);
    } else {
     this.expandedElementDocIds.push(elm.DocId());
    }
   }

   expandedElementContainsData(elm : PriceBookUnitOfMeasure|PriceBookEntry) : boolean {
    return this.expandedElementDocIds.includes(elm.DocId());
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.importWorkflowAssignmentService.selection.selected.length;
    const numRows = this.importedDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.importWorkflowAssignmentService.selection.clear() :
        this.importedDataSource.data.forEach(row => this.importWorkflowAssignmentService.selection.select(row));
  }

  allSelectable() {
    return  this.importedDataSource.data.length > 0 &&
    this.importedDataSource.data[0] instanceof PriceBookEntry &&
    this.importedDataSource.data.every((row) => this.selectable(row));
  }

  selectable(row:PriceBookUnitOfMeasure|PriceBookEntry) {
    return true;
  }

  compareWithDocIds(o1:any, o2:any) {
      return compareWithDocIds(o1,o2);
  }

  applyFilter(event: KeyboardEvent) {
    let filterValue: string = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.importedDataSource.filter = filterValue;
  }

}
