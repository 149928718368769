import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LogEntry } from './logEntry';

@Injectable({
  providedIn: 'root'
})
export class NgxLoggerService {

  constructor(private logger: NGXLogger) {
  }

  public addLog(l: LogEntry, logImmediately : boolean = false) {
    // Get the current config
    var config = this.logger.getConfigSnapshot();
    const targetCallTimer = logImmediately ? 1 : 3000;

    // Update to log config ( where calls are batched and queued)
    if (config.serverCallsTimer !== targetCallTimer) {
      config.serverCallsTimer = targetCallTimer;
      config.serverCallsBatchSize = logImmediately ? 2 : 10;
      config.serverLoggingUrl= l.app === "WEB" ? 'https://us-central1-service-vanguard.cloudfunctions.net/ml-logWeb' :
       'https://us-central1-service-vanguard.cloudfunctions.net/ml-logMobile';
      // Setting the config
      this.logger.updateConfig(config);
    }
    this.submitLog(l);
  }

  private submitLog(l: LogEntry, errorCount: number = 0) {
    try {
      this.logger.log(l.message, l, l.logType, l.origin !== null ? l.origin : 'Logging Service');
    }
    catch (e) {
      if (errorCount < 5) {
        setTimeout(() => {
          this.submitLog(l, errorCount + 1);
        }, errorCount * 1000);
      } else {
        throw e;
      }
    }
  }

}
