import { AfterViewInit, ChangeDetectionStrategy,   ChangeDetectorRef,   Component, ComponentFactoryResolver, inject, Injector, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DragDropZone } from '../../../shared/Track.model'
import { CdkDragDrop,  moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BehaviorSubject, debounce, interval, merge, Observable,of,ReplaySubject,share,Subject, zip } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ImageControlComponent } from '../component-models/image-control/image-control.component';
import { TextboxControlComponent } from '../component-models/textbox-control/textbox-control.component';
import { WhitespaceControlComponent } from '../component-models/whitespace-control/whitespace-control.component';
import { ControlContainerComponent, FormlyView } from '../component-models/control-container.component';
import { ColumnSplitterComponent } from '../column-splitter/column-splitter.component';
import {  debounceTime, delay, filter, map, mergeMap,  pairwise,  switchMap,  take, takeUntil, tap } from 'rxjs/operators';
import { FormBuilderDirective } from '../form-builder.directive';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ComponentFromFormlyFactoryService } from '../component-from-formly-factory.service';
import { ChoiceComponent } from '../choice/choice.component';
import { PageBreakComponent } from '../page-break/page-break.component';
import { SectionComponent } from '../section/section.component';
import { PhotoAdderComponent } from '../photo-adder/photo-adder.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SaveFormComponent } from '../storage/save-form/save-form.component';
import { LoadFormComponent } from '../storage/load-form/load-form.component';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { FormElementComponent } from '../form-element/form-element.component';
import { RatingControlComponent } from '../component-models/rating-control/rating-control.component';
import { SignaturePadEditorComponent } from '../component-models/signature-pad-editor/signature-pad-editor.component';
import {ContainsControlComponentsInterface, instanceOfControlReportsOnViewInitilizationInterface} from '../../form-builder/containsComponents';
import { Audience, FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { LineItemsComponent } from '../component-models/line-items/line-items.component';
import { BranchingContainerComponent } from '../branching-container/branching-container.component';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { CustomerService } from '../../../../../common/src/data/dao-services/customer.service';
import { SiteVisitService } from '../../../../../common/src/data/dao-services/site-visit.service';
import { FormModelFirestore } from '../../../../../common/src/data/dao/form-model-firestore';
import { ControlReportsOnViewInitilizationInterface } from '../containsComponents';
import { FetchUpdatedWorkflowService, WORKFLOW_STAGE } from '../component-models/formly-controls/utilities/fetch-updated-workflow.service';
import { LocalSettingsService } from 'web-app/src/app/settings/local-settings.service';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';
import { EstimateService } from '../../../../../common/src/data/dao-services/estimate.service';
import { FormlyLineItemService } from '../component-models/formly-controls/formly-line-item/formly-line-item.service';
import { FormlyComponentUtilityService } from '../component-models/formly-controls/formly-component-utility.service';
import { limit, where } from 'firebase/firestore';
import { ControlContainsControlsComponent } from '../component-models/control-contains-controls.component';
import { ImportWorkflowAssignmentService } from '../import-workflow-assignment.service';
import { DerivedWorkflowFieldService } from '../derived-workflow-field.service';
import { PriceBookEntryService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-entry.service';
import { PriceBookUnitOfMeasureService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-unit-of-measure.service';
import { FormlySectionPricebookMapping } from '../component-models/formly-controls/formly-section/formly-section-pricebook-mapping';
import { PriceBookEntry } from '../../../../../common/src/data/dao/pricebook/pricebook-entry';
import { FormModelFirestoreService } from '../../../../../common/src/data/dao-services/form-model-firestore.service';
import { FormFirestoreWorkflowComponentService } from '../../../../../common/src/components/form-firestore-workflow/form-firestore-workflow-component.service';
import { ComponentResolutionResult, RetrieveNestedComponentsService } from '../retrieve-nested-components.service';
import { UserDelationConfirmationModalComponent } from '../user-deletion-confirmation-modal/user-delation-confirmation-modal/user-delation-confirmation-modal.component';

export enum WorkflowType {
  Top = "topLevel",
  Section = "section",
}

export interface subDragDropZone extends DragDropZone {
  subIndex: number;
}

@Component({
  selector: 'app-form-designer',
  templateUrl: './form-designer.component.html',
  styleUrls: ['./form-designer.component.scss', './_partial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormDesignerComponent implements OnInit, OnDestroy, AfterViewInit, ContainsControlComponentsInterface {


  activeWorkflowType = undefined;
  subComponentBeingViewed : ControlContainsControlsComponent = undefined;

  static activeView: FormlyView = FormlyView.Technician;

  sideBarDropZone: DragDropZone;
  mainDropZone: DragDropZone;
  mainDropZones: DragDropZone[] = [];

  changeDetect$: Subject<any> = new Subject();

  @ViewChild(FormBuilderDirective, {static: true}) formBuilderControlHost: FormBuilderDirective;

  unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  childSectionCountChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  turnAutosaveBackOn: Subject<boolean> = new Subject();

  dropListIdsForMain: string[] = ["sidebarDragDropZone"];
  dropListIdsForSidebar: string[] = ["mainDragDropZone"];

  form: UntypedFormGroup;
  newColumnFormGroup: UntypedFormGroup;

  _automaticallySaveForm: boolean;
  invoiceDocId: string | null = null;
  estimateDocId: string | null = null;

  get automaticallySaveForm(): boolean {
    return this._automaticallySaveForm;
  }

  set automaticallySaveForm(value: boolean) {
    this._automaticallySaveForm = value;
    this.localSettingsService.saveToLocalStorage("WorkflowDesigner", "autoSaveForms", this._automaticallySaveForm);
    if (value) {
      this.turnAutosaveBackOn.next(true);
    }
  }


  componentSideViewForm$: BehaviorSubject<UntypedFormGroup>;
  componentSideViewParentForm$: BehaviorSubject<UntypedFormGroup>;

  triggerSideViewUpdate = new Subject();
  loadedInitialTechView: boolean = false;

  focusedComponentModel: ControlContainerComponent;
  focusedComponentFormGroup: UntypedFormGroup = undefined;

  destroyComponent$: Subject<any> = new Subject();
  itemDroppedInComposingControl$ = new Subject<CdkDragDrop<ControlContainerComponent[]>>();
  addColumnDropListId$ = new Subject<string>();
  removeColumnDropListId$ = new Subject<string>();
  componentClicked$= new Subject<ControlContainerComponent>();
  newFormForSidebar$ = new Subject<any>();
  saveFormDialogRef: MatDialogRef<SaveFormComponent>;
  loadFormDialogRef: MatDialogRef<LoadFormComponent>;
  componentInjector: Injector;
  numberControlsRendered: number = 0;

  formlyForm = new UntypedFormGroup({});
  formlyModel = {};
  formlyOptions: FormlyFormOptions = {};
  formlyFields: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject<FormlyFieldConfig[]>([]);

  formlyViewInitializing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  sectionUpdated$: Subject<any> = new Subject();

  activeFormFirestore$: BehaviorSubject<FormFirestore> = new BehaviorSubject(undefined);
  loadImportedData$: Subject<string> = new Subject();
  loadLiveAction$: Subject<void> = new Subject();
  generatePricebookData$: Subject<void> = new Subject();
  loadbuildCalculatedField$: Subject<void> = new Subject();
  prepareToLoadCalcuatedField$: Subject<void> = new Subject();
  deleteCalculationFromField$: Subject<void> = new Subject();
  userDeletionConfirmationModalRef: MatDialogRef<UserDelationConfirmationModalComponent>;

  private formFirestoreWorkflowComponentService: FormFirestoreWorkflowComponentService;

  constructor(private fb: UntypedFormBuilder, private componentFactoryResolver: ComponentFactoryResolver, private componentFromFormlyFactory: ComponentFromFormlyFactoryService,
    private dialog: MatDialog,  private resolver: ComponentFactoryResolver, private _ngZone: NgZone, private ref: ChangeDetectorRef, public formlyUtilityService:  FormlyUtilityService,
    private formCatagoryService: FormCatagoryService, private formFirestoreService: FormFirestoreService, private formFirestoreSummaryService: FormFirestoreSummaryService,
    private route: ActivatedRoute, private router: Router, private settingsService: SettingsService, private jobService: JobService, private customerService: CustomerService,
    private siteVisitService: SiteVisitService, private localSettingsService: LocalSettingsService, private invoiceService: InvoiceService, private estimateService: EstimateService,
    private formlylineItemService: FormlyLineItemService, private formlyComponentUtilityService: FormlyComponentUtilityService,
    private importDataWorkflowService: ImportWorkflowAssignmentService, private derivedWorkflowService: DerivedWorkflowFieldService,
    private priceBookEntryService: PriceBookEntryService, private unitOfMeasureService: PriceBookUnitOfMeasureService,
    private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService, private formModelFirestoreService: FormModelFirestoreService,
    private retrieveNestedComponentsService: RetrieveNestedComponentsService) {

      this.formlyUtilityService.workFlowStage = WORKFLOW_STAGE.DESIGN;
      this.formFirestoreWorkflowComponentService = inject(FormFirestoreWorkflowComponentService);

      this.activeFormFirestore$.pipe(
        filter(x => x!== undefined && x !== null),
        tap(x => {
          this.derivedWorkflowService.oldestAncestorFormSummaryDocId = x.formSummary.DocId();
        }),
        takeUntil(this.destroyComponent$)
      ).subscribe();

      this.formlyUtilityService.addContainedComponentFormGroup$.pipe(
        tap(x => (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).push(x)),
        takeUntil(this.destroyComponent$)
      ).subscribe();

      this.formlyFields.pipe(
        tap(x => this.derivedWorkflowService.activeDesignViewForm = x),
        takeUntil(this.destroyComponent$)
      ).subscribe();

      this.automaticallySaveForm = localSettingsService.loadFromLocalStorage("WorkflowDesigner", "autoSaveForms", true);

      zip([this.jobService.load$(this.settingsService.getValue('demoJobDocId')), this.siteVisitService.load$(this.settingsService.getValue('demoSiteVisitDocId')),
      this.customerService.load$(this.settingsService.getValue('demoCustomerDocId')), this.invoiceService.load$(this.settingsService.getValue('demoInvoiceDocId')),
      this.estimateService.load$(this.settingsService.getValue('demoEstimateDocId')) ]).pipe(
        tap(([job, , , invoice, estimate  ]) => {
          this.formlylineItemService.job = job;
          this.formlylineItemService.explicitEstimateDocId = estimate.DocId();
          this.formlylineItemService.explicitInvoiceDocId = invoice.DocId();
          }),
        take(1)
      ).subscribe();

      this.initilizeComponentInjector();
      this.formCatagoryService.loadAll$().pipe(take(1)).subscribe();

      this.generatePricebookData$.pipe(
        tap(() => this.formlyUtilityService.generatePricebookData$.next(void(0))),
        takeUntil(this.destroyComponent$),
      ).subscribe();

      this.loadLiveAction$.pipe(
        tap(() => {
          this.derivedWorkflowService.Reset();
          if (this.activeFormFirestore$.value !== undefined) {
            this.jobService.load$(this.settingsService.getValue('demoJobDocId')).pipe(
              tap(j => {
                console.log(this.formlyUtilityService.activeFormModelFirestore);
                this.router.navigate([{outlets: {techView:
                  ['load-workflow', null, 'job', this.settingsService.getValue('demoJobDocId'),
                  'sv',this.settingsService.getValue('demoSiteVisitDocId'),'cust',this.settingsService.getValue('demoCustomerDocId'),
                  'inv',this.invoiceDocId,'est',this.estimateDocId ]
                }}],{relativeTo: this.route, skipLocationChange: true});
              }
              ),
              take(1)
            ).subscribe();
          } else {
            this.router.navigate([{outlets: {techView:
              []}}],{relativeTo: this.route, skipLocationChange: true});
          }
        }),
        takeUntil(this.destroyComponent$)
      ).subscribe();

    }

  // ******************** Members required for live view of formly form.   ******************* //
  submit() {
    if (this.form.valid) {
      alert(JSON.stringify(this.formlyModel));
    }
  }

  associateWithImportedData() : void {
    const asSection = this.subComponentBeingViewed as SectionComponent;
    if (asSection) {
      if (asSection.associateImportedDataWithContainedControls(this.mainDropZone.draggableComponents, this.activeFormFirestore$.value.formSummary)) {
        const val = (asSection.componentFormGroup.get("priceBookMappings").value as Array<any>);
        val[val.length-1]=JSON.parse(JSON.stringify(val[val.length-1]));
        this.sectionUpdated$.next(true);
      }
    } else {
      this.associateTopLevelWithImportedData();
    }
  }

  assignedSectionToPriceBookEntryWorkflow() : void {
    const asSection = this.subComponentBeingViewed as SectionComponent;
    if (asSection && asSection.associateSectionToPriceBookEntryWorkflow(this.mainDropZone.draggableComponents, this.activeFormFirestore$.value.formSummary)) {
      this.sectionUpdated$.next(true);
    } else {
      this.associateTopLevelWithPriceBookEntryWorkflow();
    }
  }

  associateTopLevelWithPriceBookEntryWorkflow() {
    if (SectionComponent.validateSectionReadyToAssociateWithPricebookWorkflow(this.mainDropZone.draggableComponents)) {
      if (this.importDataWorkflowService.selection.selected[0] instanceof PriceBookEntry) {
        this.importDataWorkflowService.defineWorkflowWithSection(this.activeFormFirestore$.value.formSummary, this.importDataWorkflowService.selection.selected[0] as PriceBookEntry).pipe(
          take(1)
        ).subscribe();
      } else {
        window.alert("Top level sections can not define unit of measure.");
      }
    }
  }

  deleteImportedDataAssociation() {
    const asSection = this.subComponentBeingViewed as SectionComponent;
    if (asSection) {
      if (asSection.deleteImportedDataAssociation(this.mainDropZone.draggableComponents, this.activeFormFirestore$.value.formSummary)) {
        this.sectionUpdated$.next(true);
      }
    } else {
      const containedControls = this.mainDropZone.draggableComponents as any[];
      let associatedTextBoxControl = undefined;
      // if single textbox is contained, validate it and assign it to the pricebook entry.
      if (containedControls.length === 1 &&
        containedControls[0].columnFormGroups.value.length === 1 &&
        containedControls[0].columnFormGroups.value[0].cdkDropListData.length === 1) {
          associatedTextBoxControl = (containedControls[0].columnFormGroups.value[0].cdkDropListData[0] as TextboxControlComponent);
      } else {
        //Currently this will always fail validation, as we only support associating price book and unit of measure entries with text box controls.
        if (this.formlyComponentUtilityService.componentSelectedForSideView.component.name !== "TextboxControlComponent") {
          associatedTextBoxControl = this.formlyComponentUtilityService.componentSelectedForSideView;
        } else {
          const guidToFind = this.formlyComponentUtilityService.componentSelectedForSideView.componentForm.controls.guid.value;
          for (let control of containedControls) {
            const found = control.columnFormGroups.value[0].cdkDropListData.find(x => x.componentForm.controls.guid !== undefined &&
                x.componentForm.controls.guid.value === guidToFind);
            if (found !== undefined) {
              associatedTextBoxControl = (found as TextboxControlComponent);
              break;
            }
          }
        }
        if (SectionComponent.validateImportedDataDeletion(associatedTextBoxControl,this.activeFormFirestore$.value.formlySectionPricebookMappings)) {
          associatedTextBoxControl.linkedToImportedData$.next(null);
          this.activeFormFirestore$.value.formlySectionPricebookMappings = this.activeFormFirestore$.value.formlySectionPricebookMappings.filter(x => x.formlyTextboxGuid !==
            associatedTextBoxControl.form.controls.guid.value);
          this.unsavedChanges.next(true);
        }
      }
    }
  }

  associateTopLevelWithImportedData() {
    const guidToFind = this.formlyComponentUtilityService.componentSelectedForSideView.componentForm.controls.guid.value;
    let associatedTextBoxControl: TextboxControlComponent = undefined;
    (this.mainDropZone.draggableComponents as any[]).forEach(control => {
      const found = control.columnFormGroups.value[0].cdkDropListData.find(x => x.componentForm.controls.guid !== undefined &&
          x.componentForm.controls.guid.value === guidToFind);
      if (found !== undefined) {
        associatedTextBoxControl = (found as TextboxControlComponent);
      }
    });
    if (SectionComponent.validateImportedDataAssocation(associatedTextBoxControl, this.importDataWorkflowService)) {
      if (this.importDataWorkflowService.selection.selected[0] instanceof PriceBookEntry) {
        associatedTextBoxControl.linkedToImportedData$.next(this.importDataWorkflowService.selection.selected[0]);
        const priceBookEntry = this.importDataWorkflowService.selection.selected[0] as PriceBookEntry;
        this.activeFormFirestore$.value.formlySectionPricebookMappings.push(new FormlySectionPricebookMapping({formlyTextboxGuid: guidToFind,
          priceBookEntryDocId: priceBookEntry.DocId()}));
        this.unsavedChanges.next(true);
        this.importDataWorkflowService.selection.clear();
      } else {
        window.alert("Top level sections can not contain definitions for unit of measure.");
      }
    }
  }

  patchChangesToDropZonesAndItemsFromContainedControlsToFormDesigner(containedComponentFormGroup: UntypedFormGroup) {

    console.log("PatchChangesToDropZonesAndItemsFromContainedControlsToFormDesigner");
    if (containedComponentFormGroup.controls["sectionUpdated"] !== undefined) {

      containedComponentFormGroup.controls["sectionUpdated"].valueChanges.pipe(
        debounce(() => this.formlyUtilityService.sidebarDebounceActive$.pipe(filter(x => x === false))),
        tap(x => console.log("SECTION WAS UPDATED")),
        tap(x => this.sectionUpdated$.next(x)),
        takeUntil(this.destroyComponent$)
      ).subscribe();
    }

    containedComponentFormGroup.controls["addDropZoneId"].valueChanges.pipe(
      tap(x => this.addColumnDropListId$.next(x)),
      tap(() => this.retrieveCurrentFormlyFieldRepresentation(false)),
    ).subscribe();

    containedComponentFormGroup.controls["removeDropZoneId"].valueChanges.pipe(
      tap(x => this.removeColumnDropListId$.next(x)),
      tap(x => {
        if ( (containedComponentFormGroup.get("columnFormGroups").value as UntypedFormArray).length === 1) {
          const spliceIndex = this.mainDropZone.draggableComponents.findIndex(c =>  (c.form.get("controlName").value === "Columns" ||  c.form.get("controlName").value === "Section")
          && c.componentForm.get("menuLanchedByContainerId").value == x)
          // Index is only found when deleting a top level component, but a splice on -1 === a splice on last element, and we only want to remove
          // Component if it was deleted, not if a composed component within it was deleted...
            if (spliceIndex > -1) {
              this.mainDropZone.draggableComponents.splice(spliceIndex,1);
            }
        }
      }),
      debounceTime(10),
      delay(1),
      tap(() => this.retrieveCurrentFormlyFieldRepresentation(false))
    ).subscribe();

    containedComponentFormGroup.controls["explicitRegenerateFormly"].valueChanges.pipe(
      tap(() => this.retrieveCurrentFormlyFieldRepresentation(true))
    ).subscribe();

    containedComponentFormGroup.controls["itemRemoved"].valueChanges.pipe(
      tap(() => console.log("item removed")),
      tap(() => this.retrieveCurrentFormlyFieldRepresentation(true))
    ).subscribe();

    containedComponentFormGroup.controls["itemDrop"].valueChanges.pipe(
      filter(x => x!==null),
      tap(x => this.itemDroppedInComposingControl$.next(x)),
      tap(() => FormElementComponent.emitComponentClicked=true),
      delay(1),
      tap(() => this.retrieveCurrentFormlyFieldRepresentation(true)),
    ).subscribe();
  }

  buildBranchingContainerFormGroup() : UntypedFormGroup {
    const retVal = BranchingContainerComponent.buildBranchingFormGroup();
    return this.patchUpdatesToContainedControlFormGroups(retVal);
  }

  buildSectionFormGroup() : UntypedFormGroup {
    const retVal = SectionComponent.generateSectionFormGroup();
    return this.patchUpdatesToContainedControlFormGroups(retVal);
  }

  buildColumnSplitterFormGroup() : UntypedFormGroup {
    const retVal = ColumnSplitterComponent.buildColumnSplitterFormGroup();
    return this.patchUpdatesToContainedControlFormGroups(retVal);
  }

  patchUpdatesToContainedControlFormGroups(f: UntypedFormGroup) {
    f.patchValue({connectedDropLists: this.dropListIdsForMain.slice()});
    this.patchChangesToDropZonesAndItemsFromContainedControlsToFormDesigner(f);
    return f;
  }

  trackByComponentPerInstance(index,component: ControlContainerComponent){
    return component.perInstance;
   }

    updateDropListsAndPatchToContainedControls() {
      this.removeColumnDropListId$.subscribe(x =>
        {
          if (this.dropListIdsForMain.indexOf(x) > -1) {
            this.dropListIdsForMain.splice(this.dropListIdsForMain.indexOf(x),1);
            this.dropListIdsForSidebar.splice(this.dropListIdsForSidebar.indexOf(x),1);
            (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).controls.forEach(column => column.patchValue({connectedDropLists: this.dropListIdsForMain}));
          }
        });

        this.addColumnDropListId$.pipe(
          ).subscribe(x => {
          const asSet = new Set(this.dropListIdsForMain);
          if (!asSet.has(x)) {
            // Splice to beginning of array b/c drops in first match found.
            this.dropListIdsForMain.splice(0,0,x);
            this.dropListIdsForSidebar.splice(0,0,x);
            (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).controls.forEach(column => {
              column.patchValue({connectedDropLists: this.dropListIdsForMain})
            });
          }
        });
    }

    detectUnsavedChanges() {

      const detectUnsavedChangesInterval = 500;

      const updatedSubComponent = interval(detectUnsavedChangesInterval).pipe(
        filter(() => this.unsavedChanges.value === false),
        filter(() => this.formlyUtilityService.sidebarDebounceActive$.value === false),
        map(() => {
          const f = this.mainDropZone.draggableComponents.filter(x => x.form.get("controlName").value !== "Section" && x.serializeToDatabase).map(x => x.toFormlyFieldConfigJsonOnly());
          f.forEach(form => this.formlyUtilityService.stripKeysAllTheWayDown(form));
          return JSON.stringify(f);
        }),
        pairwise(),
        filter(x => x[0] !== x[1]),
        );

        // section count change
        interval(detectUnsavedChangesInterval).pipe(
          filter(() => this.unsavedChanges.value === false),
          filter(() => this.formlyUtilityService.sidebarDebounceActive$.value === false),
          map(() =>  this.mainDropZone.draggableComponents.filter(x => x.form.get("controlName").value === "Section" && x.serializeToDatabase &&
            x.form.value?.controlComponentFormGroup?.value?.formFirestoreSummaryDocId !== null && x.form.value?.controlComponentFormGroup?.value?.title !== "").length),
          pairwise(),
          filter(x => x[0] !== x[1]),
          tap(() => this.childSectionCountChange$.next(true)),
          takeUntil(this.destroyComponent$)
          ).subscribe();

          // const updateTriggeredTopLevel = merge(this.formlyUtilityService.triggerUnsavedChangeDetection$.pipe(
          //   filter(x => x === "mainDragDropZone")), this.formlyUtilityService.triggerUnsavedChangeDetection$.pipe(
          //     filter(x => x !== "mainDragDropZone"), delay(50)));

          const updateTriggeredTopLevel = this.formlyUtilityService.triggerUnsavedChangeDetection$.pipe(
            filter(x => x === "mainDragDropZone"),
          );

        merge(updateTriggeredTopLevel, updatedSubComponent, ...this.mainDropZone.draggableComponents.filter(x => x.form.get("controlName").value === "Section")
            .map(y => (y as SectionComponent).updatedSectionProperties$.pipe(debounceTime(50)))).pipe(
          tap(() => console.log("UPDATED TOP LEVEL")),
          tap(() => this.unsavedChanges.next(true)),
          takeUntil(merge(this.destroyComponent$, this.formlyViewInitializing$.pipe(filter(x => x === true)))),
        ).subscribe();
    }

    checkForSaveValidity() : boolean {
      // We must iterate through all controls and check if there are any formly-sections which have not yet been saved.
      // If there are, we must prompt the user to save the form before proceeding.
      const toCheck = this.mainDropZone.draggableComponents.filter(x => x.serializeToDatabase).map(x => x.toFormlyFieldConfig() as FormlyFieldConfig);
      for (const formlySection of toCheck) {
        const sectionRetVal = this.checkForSectionValidity(formlySection);
        if (!sectionRetVal) {
          return false;
        }
      }
      return true;
    }

    checkForSectionValidity(f : FormlyFieldConfig) : boolean {
      if (f.type === "formlySection" && !f.props.formFirestoreSummaryDocId) {
        return false;
      } else {
        if (f.fieldGroup) {
          for (const sub of f.fieldGroup) {
            if (!this.checkForSectionValidity(sub)) {
              return false;
            }
          }
      }
        return true;
      }
    }

    retrieveFormToSave() : string {
      if (this.activeWorkflowType === WorkflowType.Section) {
        (this.subComponentBeingViewed as SectionComponent).componentFormGroup.patchValue({columnFormGroups: [{cdkDropListData: this.mainDropZone.draggableComponents}]});
        (this.subComponentBeingViewed as SectionComponent).fields[0].props["formFirestoreDocId"] = "<<formFirestoreDocId>>";
        (this.subComponentBeingViewed as SectionComponent).fields[0].props["formFirestoreSummaryDocId"] = "<<formFirestoreSummaryDocId>>";
        (this.subComponentBeingViewed as SectionComponent).fields[0].props["priceBookMappings"] = (this.subComponentBeingViewed as SectionComponent).componentFormGroup.get("priceBookMappings").value;
        (this.subComponentBeingViewed as SectionComponent).fields[0].props["unitOfMeasureMappings"] = (this.subComponentBeingViewed as SectionComponent).componentFormGroup.get("unitOfMeasureMappings").value;
        return JSON.stringify(this.subComponentBeingViewed.toFormlyFieldConfigJsonOnly());
      } else {
        return JSON.stringify(this.mainDropZone.draggableComponents.filter(x => x.serializeToDatabase && (x['patchedFromBranch'] === undefined || x['patchedFromBranch'] === false))
          .map(x => x.toFormlyFieldConfigJsonOnly()));
      }
    }

    autoSaveForm() {
      const savedVersion = this.retrieveFormToSave();
      const newFormFireStore = new FormFirestore();
      //if saving a top level form we need to add formlySectionPriceMappings to saved formFirestore.
      if (this.subComponentBeingViewed === undefined) {
        newFormFireStore.formlySectionPricebookMappings = this.activeFormFirestore$.value.formlySectionPricebookMappings;
      }
      newFormFireStore.formSummary = this.activeFormFirestore$.value.formSummary;
      newFormFireStore.title = this.activeFormFirestore$.value.title;
      newFormFireStore.form = savedVersion;
      this.formFirestoreService.retrieveDocId(newFormFireStore).pipe(
        map(() => {
          newFormFireStore.formSummary.currentDesignFirestoreDocId = newFormFireStore.DocId();
          if (this.activeWorkflowType === WorkflowType.Section) {
            newFormFireStore.form = newFormFireStore.form.replace("<<formFirestoreDocId>>", newFormFireStore.DocId());
            newFormFireStore.form = newFormFireStore.form.replace("<<formFirestoreSummaryDocId>>", newFormFireStore.formSummary.DocId());
          }
          return newFormFireStore;
        }),
        switchMap(newFormFireStore => this.formFirestoreService.retrieveFirestoreBatchString().pipe(
          map(batch => {
            return {batch: batch, newFormFireStore: newFormFireStore};
          })
        )),
        switchMap(val => this.formFirestoreService.create$(val.newFormFireStore,val.batch).pipe(
          map(() => val)
        )),
        map(val => {
          this.formlyUtilityService.activeFormModelFirestore.formFirestore = val.newFormFireStore;
          return {batch: val.batch, formModelFirestore: this.formlyUtilityService.activeFormModelFirestore};
        }),
        switchMap(x => this.jobService.load$(this.settingsService.getValue('demoJobDocId')).pipe(
          map(j => {
           j.formModelFirestore = x.formModelFirestore;
           return  {job: j, val: x}
          })
        )),
        switchMap(x => this.jobService.update$(x.job, x.val.batch).pipe(
          map(() => x)
        )),
        switchMap(x => this.formModelFirestoreService.commitExternallyManagedFirestoreBatch(x.val.batch).pipe(
          map(() => x.val.formModelFirestore.formFirestore)
        )),
        tap(() => this.unsavedChanges.next(false)),
        switchMap(x => this.formFirestoreService.load$(x.DocId())),
        filter(x => x.formSummary !== null),
        tap(() => console.log("Loading to tech view b/c auto saved.")),
        tap(() => {
          this.fetchUpdatedWorkflowService.formModelFirestoreMapping.clear();
          this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.clear();
        }),
        tap(x => this.activeFormFirestore$.next(x)),
        tap(() => this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore$.value)),
        take(1),
        ).subscribe();
    }

    saveToFirestore(toSave: FormFirestore) {
      // Ensure that form is in a savable state.
      if (this.mainDropZone.draggableComponents.length === 0) {
        window.alert("You must add at least one component to save form.");
        return;
      }
      if (!this.checkForSaveValidity()) {
        window.alert("You first must save sub sections.");
        return;
      }

      let formFirestoreSummaryDocId = this.activeFormFirestore$.value?.formSummary.DocId();
      let  formFirestoreSummaryDocId$ : Observable<string> = undefined;

      if ( !formFirestoreSummaryDocId) {
        formFirestoreSummaryDocId$ = this.formFirestoreService.retrieveFirestoreRawDocId();
      } else {
        formFirestoreSummaryDocId$ = of(formFirestoreSummaryDocId);
      }
      zip(formFirestoreSummaryDocId$,this.formFirestoreService.retrieveFirestoreRawDocId()).pipe(
        tap(x => formFirestoreSummaryDocId = x[0]),
        tap(x => {
          const formFirestoreDocId = x[1];
          const editorConfig = new MatDialogConfig();
          let savedVersion = this.retrieveFormToSave();
          if (this.activeWorkflowType === WorkflowType.Section) {
            savedVersion = savedVersion.replace("<<formFirestoreDocId>>", formFirestoreDocId);
            savedVersion = savedVersion.replace("<<formFirestoreSummaryDocId>>", formFirestoreSummaryDocId);
          }
          Object.assign(editorConfig, {
            data: {
            form: savedVersion,
            formType: this.activeWorkflowType === WorkflowType.Section ? "section" : "topLevel",
            formFirestore: toSave,
            formFirestoreDocId: formFirestoreDocId,
            formFirestoreSummaryDocId: formFirestoreSummaryDocId,
            }
          });

          this.saveFormDialogRef = this.dialog.open(SaveFormComponent, editorConfig);

          this.saveFormDialogRef.afterClosed().pipe(
            filter(x => x !== undefined),
            switchMap(x => this.formFirestoreService.load$(x.DocId())),
            filter(x => x.formSummary !== null),
            tap(x => this.loadForm(x)),
            take(1),
          ).subscribe();
        }),
        take(1)
      ).subscribe();
    }

    ngOnInit(): void {

      merge(this.sectionUpdated$.pipe(filter(() => this.subComponentBeingViewed !== undefined)),this.unsavedChanges, this.turnAutosaveBackOn,
      this.childSectionCountChange$.pipe(
        filter(x => x === true),
        tap(() => this.childSectionCountChange$.next(false)),
      )).pipe(
        filter(x => x === true),
        filter(() => this.automaticallySaveForm),
        filter(() => this.checkForSaveValidity()),
        // Initial save must be manual, even in auto save mode as there is no name or catagory assigned.
        filter(() => this.activeFormFirestore$.value !== undefined),
        debounce(() => this.formFirestoreWorkflowComponentService.loadedInitialFormModelFirestore$.pipe(filter(x => x === true))),
        debounceTime(10),
        tap(() => this.autoSaveForm()),
        tap(() => {
          if (!this.importDataWorkflowService.workflowAssignmentActive && !this.loadedInitialTechView) {
            this.loadInitialTechView();
          }
        }),
        takeUntil(this.destroyComponent$),
      ).subscribe();

      this.sectionUpdated$.pipe(
        filter(() => this.subComponentBeingViewed === undefined),
        tap(() => console.log("Loaded to tech view b/c section updated.")),
        tap(() => this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore$.value)),
        takeUntil(this.destroyComponent$)
      ).subscribe();



      this.formlyUtilityService.designMode = true;
      this.form = this.fb.group({
        containedComponentFormGroups : new UntypedFormArray([]),
      });

      this.componentClicked$.subscribe(x => {
        if (this.derivedWorkflowService.creatingDerivedField) {
          if (x.form.controls.controlName.value === "Text Box") {
            if (x.form.controls.controlComponentFormGroup.value.controls.numericMaskDesired.value) {
              // need to validate that form summary is set (has been saved once) before allowing user to save derived field...
              this.derivedWorkflowService.oldestAncestorFormSummaryDocId = this.activeFormFirestore$.value.formSummary.DocId();
              (x as TextboxControlComponent).badgeNumber.next(this.derivedWorkflowService.activeChipCounter);
              (x as TextboxControlComponent).selectedForDerivedField.next(true);
              (x as TextboxControlComponent).changeDetect$.next(null);
              this.derivedWorkflowService.textboxSelectedToAddToCalc$.next(x as TextboxControlComponent);
            } else {
              window.alert("Only numeric fields can be used to build a numeric calculated field.");
            }
          } else {
            window.alert("Either text box is already added to calculation, or you have clicked on another component type.");
          }
        } else {
          this.loadComponentDetailsSidebar(x);
        }
      });

      // When item is dropped in composing control, add back to side bar if item originated there.
      this.itemDroppedInComposingControl$.pipe(
        debounceTime(10),
        takeUntil(this.destroyComponent$)
      ).subscribe(event => {
        if (event.previousContainer?.id === "sidebarDragDropZone") {
        this.AddElementBackToSidebar(event.previousIndex,event.item.data);
        }
      });

      this.updateDropListsAndPatchToContainedControls();

      this.sideBarDropZone = this.initilizeSiderBarDragDropZone();
      this.mainDropZone = this.initilizeMainDragDropZone();
      this.addMainDragDropZone();
      this.formlyUtilityService.mainDropZoneComponents = this.mainDropZone.draggableComponents;

      this.formlyUtilityService.addFormFirestoreToFormDesigner$.pipe(
        mergeMap(x => this.formFirestoreSummaryService.load$(x.firestoreSummaryDocId).pipe(
          map(summary => {
            return {x, formFirestoreDocId: summary.currentDesignFirestoreDocId}
          }),
          take(1),
        )),
        mergeMap(toLoad => this.formFirestoreService.load$(toLoad.formFirestoreDocId).pipe(
          map(formFirestore => {
            return {formFirestore, formFirestoreSummaryDocId: toLoad.x.firestoreSummaryDocId, originSourceGuid: toLoad.x.originSourceGuid,
              parentContainerGuid: toLoad.x.parentContainerGuid, parentDropZoneId: toLoad.x.parentDropZoneId, priceBookEntryDocId: toLoad.x.priceBookEntryDocId,
              patchUpdatedFormSummaryDocId$: toLoad.x.patchUpdatedFormSummaryDocId$, formlySectionConfig: toLoad.x.formlySectionConfig,
              patchUpdatedFormlySectionConfig$: toLoad.x.patchUpdatedFormlySectionConfig$};
          }),
          take(1)
        ))).subscribe( x => this.addFirestore(x));

      this.formlyUtilityService.removeSectionFromDesignView$.pipe(
        tap(x => {
          const parsed = this.checkIfElementContainsGuid((this.form.controls["containedComponentFormGroups"] as UntypedFormArray),
          x.originSourceGuid,x.parentContainerGuid,x.parentDropZoneId,null, x.formFirestoreSummaryDocId, "Remove");
          // top level won't be found
          if (!parsed) {
            const sectionToRemoveIndex = this.mainDropZone.draggableComponents.findIndex(q => q.componentForm?.value?.formFirestoreSummaryDocId === x.formFirestoreSummaryDocId);
          if (sectionToRemoveIndex > -1) {
          const sectionToRemove = (this.mainDropZone.draggableComponents[sectionToRemoveIndex] as SectionComponent);
            sectionToRemove.removeSection();
          } else {
            console.warn("Section not found to remove.");
          }
        }
        }),
        takeUntil(this.destroyComponent$)
      ).subscribe();
    }

  ngAfterViewInit(): void {

    this.formlyViewInitializing$.pipe(
      filter(x => x === false),
      tap(() => this.detectUnsavedChanges()),
      takeUntil(this.destroyComponent$),
    ).subscribe();

    this.loadImportedData$.subscribe(param => {
      console.log(param);
      this.router.navigate([{outlets: {techView:
        ['load-importedData', param ]
      }}],{relativeTo: this.route, skipLocationChange: true});
    });

    this.loadbuildCalculatedField$.subscribe(() => {
      this.derivedWorkflowService.creatingDerivedField = true;
      this.router.navigate([{outlets: {techView:
        ['load-derived-workflow-builder']
      }}],{relativeTo: this.route, skipLocationChange: true});
      this.derivedWorkflowService.derivedWorkflowViewExit$.pipe(
        tap(() => {
          this.derivedWorkflowService.Reset();
          this.loadLiveAction$.next(void(0));
        }),
        take(1)
      ).subscribe();
    });

    this.deleteCalculationFromField$.pipe(
      tap(() => {
        const componentBuilding = (this.formlyComponentUtilityService.componentSelectedForSideView  as TextboxControlComponent);
        const inputComponentsUsedInCalculation = this.derivedWorkflowService.GetInputComponentsAssociatedWithCalculation(this.mainDropZone.draggableComponents,componentBuilding, true);
        inputComponentsUsedInCalculation.forEach(x => {
          const nodeMappings = (x.componentForm.value['calculatedComponentNodeMappings'] as Array<any>);
          if (nodeMappings) {
            const updatedNodeMappings = nodeMappings.filter(x => x.CalculatedComponentPath.controlGuid !== componentBuilding.componentForm.value.guid);
            x.componentForm.patchValue({calculatedComponentNodeMappings: updatedNodeMappings});
          }
        });
        componentBuilding.componentForm.patchValue({inputComponentNodeMappings: [], derivedCalculation: null,
          derivedCalculationNote: ""});
      }),
      takeUntil(this.destroyComponent$)
    ).subscribe();

    this.prepareToLoadCalcuatedField$.pipe(
      tap(() => {
        const componentBuilding = (this.formlyComponentUtilityService.componentSelectedForSideView  as TextboxControlComponent);
        componentBuilding.suppressClicks = true;
        const inputComponentsUsedInCalculation = this.derivedWorkflowService.GetInputComponentsAssociatedWithCalculation(this.mainDropZone.draggableComponents,componentBuilding);
        // once field is ready to be loaded.
        this.derivedWorkflowService.componentBuilding = componentBuilding;
        this.derivedWorkflowService.derivedWorkflowViewAfterViewInit$.pipe(
          delay(1),
          tap(() => {
            inputComponentsUsedInCalculation.forEach(x => {
              x.unfilteredComponentClicked$.next(x);
            });
          }),
          delay(1),
          tap(() => {
            if (inputComponentsUsedInCalculation.length > 0) {
              this.derivedWorkflowService.buildTagifyFromPatchedInputComponents$.next(void(0));
            }
          }),
          take(1)
        ).subscribe();
        this.loadbuildCalculatedField$.next(null);
      })
    ).subscribe();

    //PRELOAD GARRETY FORM.
    this.formFirestoreSummaryService.load$("kbzaYaLObaGhF3A3JGGb").pipe(
    // this.formFirestoreSummaryService.load$("WmlglX53OYDZwRX8EeUZ").pipe(
      filter(x => x !== null),
      switchMap(x=> this.formFirestoreService.load$(x.currentDesignFirestoreDocId)),
      tap(x => this.loadForm(x)),
      take(1)
    ).subscribe();

  }

    ngOnDestroy(): void {
      this.formlyComponentUtilityService.componentSelectedForSideView = undefined;
      this.formlyUtilityService.designMode = false;
      this.formlyUtilityService.workFlowStage = WORKFLOW_STAGE.DEPLOYED;
      this.destroyComponent$.next(null);
      this.destroyComponent$.complete();
    }

  loadInitialTechView() {
    this.loadedInitialTechView = true;
    this.jobService.load$(this.settingsService.getValue('demoJobDocId')).pipe(
      map(job => {
        job.lineItems = [];
        job.abandonedLineItems = [];
        job.formModelFirestore = new FormModelFirestore({formFirestore: this.activeFormFirestore$.value, formFirestoreDocId: this.activeFormFirestore$.value.DocId() });
        return job;
      }),
      take(1),
      switchMap(j => this.jobService.update$(j)),
      switchMap(j => this.invoiceService.queryFirestoreDeep$([where('jobDocId', '==', j.DocId()),
        limit(1)]).pipe(
        map(invoice => {
          return {job: j, invoice: invoice.length > 0 ? invoice[0] : null}
        }),
      )),
      switchMap(j => this.estimateService.queryFirestoreDeep$([where('jobDocId', '==', j.job.DocId()),
        limit(1)]).pipe(
        map(estimate => {
          return {job: j.job, invoice: j.invoice, estimate: estimate.length > 0 ? estimate[0] : null}
        }),
      )),
      tap(j => {
        this.estimateDocId = j.estimate == undefined ? null : j.estimate.DocId();
        this.invoiceDocId = j.invoice == undefined ? null : j.invoice.DocId();
        this.router.navigate([{outlets: {techView:
          ['load-workflow', j.job.formModelFirestore.DocId(), 'job', this.settingsService.getValue('demoJobDocId'),
          'sv',this.settingsService.getValue('demoSiteVisitDocId'),'cust',this.settingsService.getValue('demoCustomerDocId'),
          'inv',j.invoice? j.invoice.DocId() : null,'est',j.estimate? j.estimate.DocId() : null ]
        }}],{relativeTo: this.route, skipLocationChange: true});
      }
      ),
      take(1)
    ).subscribe();
  }

  formlyFormValid() {
    if (!this.formlyForm.valid && !this.formlyForm.disabled) {
      console.log("this",this.formlyForm);
    }
    return this.formlyForm.valid;
  }

  deployWorkflow(refresh: boolean) {
    const f = this.activeFormFirestore$.value.formSummary;
    f.currentDeployedFirestoreDocId = f.currentDesignFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      tap(() => {
        if (refresh) {
          this.loadForm(this.activeFormFirestore$.value);
        } else {
          this.activeFormFirestore$.next(this.activeFormFirestore$.value);
        }
      }),
      take(1)
    ).subscribe();
  }

  revertToDeployedWorkflow() {
    const f = this.activeFormFirestore$.value.formSummary;
    f.currentDesignFirestoreDocId = f.currentDeployedFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      switchMap(x => this.formFirestoreService.load$(f.currentDesignFirestoreDocId)),
      filter(x => x.formSummary !== null),
      tap(() => this.formlyViewInitializing$.next(true)),
      tap(x => this.activeFormFirestore$.next(x)),
      map(x => JSON.parse(x.form)),
      tap(() => this.unsavedChanges.next(false)),
      tap(x => this.populateFromJson(x)),
      tap(x => this.changeDetect$.next(null)),
      take(1)
      ).subscribe();
  }

  newWorkflow(workflowType: WorkflowType) {
    if (workflowType === "section") {
      this.subComponentBeingViewed = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
        this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService,
      this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowService);
        (this.subComponentBeingViewed as SectionComponent).componentFormGroup = this.buildSectionFormGroup();
    } else {
      this.subComponentBeingViewed = undefined;
    }
    this.activeWorkflowType = workflowType;
    this.activeFormFirestore$.next(undefined);
    this.populateFromJson([]);
    this.unsavedChanges.next(false);
    this.changeDetect$.next(null);
    this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(null);
  }

  loadForm(formFirestore: FormFirestore) {
    this.derivedWorkflowService.derivedWorkflowViewExit$.next(void 0);
      this.formlyViewInitializing$.next(true);
      this.activeWorkflowType = formFirestore.formSummary.formType;
      this.fetchUpdatedWorkflowService.formModelFirestoreMapping.clear();
      this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.clear();
      this.activeFormFirestore$.next(formFirestore);
      console.log(formFirestore);
      const formlyConfig = JSON.parse(formFirestore.form);
      if (this.activeWorkflowType === "section") {
        this.subComponentBeingViewed = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
          this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService,
          this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowService);
          (this.subComponentBeingViewed as SectionComponent).componentFormGroup = this.buildSectionFormGroup();
          (this.subComponentBeingViewed as SectionComponent).patchInSectionConfigurationComponents(formlyConfig);
      } else {
        this.subComponentBeingViewed = undefined;
      }
      this.unsavedChanges.next(false);
      this.populateFromJson(formlyConfig);
      if (this.activeWorkflowType === "section") {
        const priceBookEntries = (this.subComponentBeingViewed as SectionComponent).componentForm.get("priceBookMappings").value;
        const unitOfMeasureEntries = (this.subComponentBeingViewed as SectionComponent).componentForm.get("unitOfMeasureMappings").value;
        SectionComponent.populateMappedImportedDataEntries(this.mainDropZone.draggableComponents,
          priceBookEntries.concat(unitOfMeasureEntries), this.unitOfMeasureService, this.priceBookEntryService);
      } else {
        SectionComponent.populateMappedImportedDataEntries(this.mainDropZone.draggableComponents,
          this.activeFormFirestore$.value.formlySectionPricebookMappings, this.unitOfMeasureService, this.priceBookEntryService);
      }
      if (!this.importDataWorkflowService.workflowAssignmentActive && !this.loadedInitialTechView && (formlyConfig.length > 0 || formlyConfig.fieldArray?.fieldGroup)) {
        this.loadInitialTechView();
      } else {
        this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore$.value);
      }
      this.changeDetect$.next(null);
  }

  loadFromFirestore() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      data: {
      formCatagory: null,
      formType: "all"
      }
    });

    this.loadFormDialogRef = this.dialog.open(LoadFormComponent, editorConfig);

    // Event dialog closure:
    this.loadFormDialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(x => this.loadForm(x)),
      take(1),
      ).subscribe();
  }

  ShowCustomerView() {
    this.formlyUtilityService.activeAudience = Audience.Customer;
    this.formlyUtilityService.triggerReload$.next(null);
  }

  ShowTechView() {
    this.formlyUtilityService.activeAudience = Audience.Internal;
    this.formlyUtilityService.triggerReload$.next(null);
  }

  saveWorkflow(workflowType: WorkflowType | null) {
    if (workflowType === WorkflowType.Section && this.subComponentBeingViewed === undefined) {
      this.activeWorkflowType = WorkflowType.Section;
      this.subComponentBeingViewed = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
        this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService,
        this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowService);
        (this.subComponentBeingViewed as SectionComponent).componentFormGroup = this.buildSectionFormGroup();
    }
    this.saveToFirestore(this.activeFormFirestore$.value);
  }

  loadWorkflow() {
    this.loadFromFirestore();
  }

  copyWorkflow() {
    this.formFirestoreService.copyWorkflow(this.activeFormFirestore$.value).pipe(
      tap(copy => this.saveToFirestore(copy)),
      take(1)
    ).subscribe();
  }

  toggleWorkflowActivationStatus() {
    this.activeFormFirestore$.value.formSummary.active = !this.activeFormFirestore$.value.formSummary.active;
    this.activeFormFirestore$.next(this.activeFormFirestore$.value);
    this.formFirestoreSummaryService.update$(this.activeFormFirestore$.value.formSummary).pipe(
      take(1)
    ).subscribe();
  }

  reinitilizeDesignForm() {

    this.mainDropZone.draggableComponents = [];
    this.formlyUtilityService.mainDropZoneComponents = this.mainDropZone.draggableComponents;
    (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).clear();
    this.dropListIdsForMain = ["sidebarDragDropZone"].concat(...this.mainDropZones.map(x => x.id));
    this.dropListIdsForSidebar = this.mainDropZones.map(x => x.id);
  }

  populateFromJson(fields: FormlyFieldConfig[] ) {
    FormElementComponent.emitComponentClicked=false;
    const inilizationReportedBack : ControlReportsOnViewInitilizationInterface[] = [];
    console.log(fields);
    this.reinitilizeDesignForm();
    let fieldsToParse : FormlyFieldConfig[] = fields;
    // For sections, load sub components in when viewing in main design container.
    if (this.activeWorkflowType === WorkflowType.Section && (fields as any).fieldArray) {
      fieldsToParse = (fields as any).fieldArray.fieldGroup;
    }
    fieldsToParse.filter(x => x.key !== "_id" ).forEach(field => {
      var c : ColumnSplitterComponent | SectionComponent | PageBreakComponent | PhotoAdderComponent | BranchingContainerComponent;
        switch (field.type) {
          case "formlySplitter":
            c = new ColumnSplitterComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.formlyComponentUtilityService,this.formlyUtilityService, this.derivedWorkflowService);
            c.columnFormGroup = this.buildColumnSplitterFormGroup();
            c.columnFormGroup.patchValue({borderColor: field.props["borderColor"], border: field.props["border"]  });
            break;
          case "formlySection":
            c = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
              this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService,
              this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowService);
            c.componentFormGroup = this.buildSectionFormGroup();
            break;
          case "formlyBranchingContainer":
            c = new BranchingContainerComponent(this.fb, this.formlyUtilityService, this.formFirestoreService);
            break;
          case "formlyPageBreak":
            c = new PageBreakComponent(this.fb);
            break;
          case "formlyPhotoAdder":
            c = new PhotoAdderComponent(this.fb);
            break;
          default:
            console.error("Attempted to parese a field with an unsupported type.");
          break;
        }
        if (c) {
          c.patchInFormlyFieldConfig(field);
        (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).push(c.componentForm);
          if (instanceOfControlReportsOnViewInitilizationInterface(c)) {
            inilizationReportedBack.push(c as ControlReportsOnViewInitilizationInterface);
          }
          this.mainDropZone.draggableComponents.push(c as ControlContainerComponent);
          this.addMainDragDropZone();
        }
    });

    if (inilizationReportedBack.length === 0) {
      this.formlyViewInitializing$.next(false);
    } else {
      zip(...inilizationReportedBack.map(x => x.afterViewInitilized$), of(null)).pipe(
        tap(() => this.formlyViewInitializing$.next(false)),
        take(1)
      ).subscribe();
    }
  }

  initilizeComponentInjector() {
    this.componentInjector = Injector.create({providers: [
      {provide : WhitespaceControlComponent},
      {provide: TextboxControlComponent},
      {provide: ImageControlComponent},
      {provide: ColumnSplitterComponent},
      {provide: ChoiceComponent},
      {provide: PageBreakComponent},
      {provide: SectionComponent},
      {provide: PhotoAdderComponent},
      {provide: RatingControlComponent},
      {provide: SignaturePadEditorComponent},
      {provide: LineItemsComponent},
      {provide: BranchingContainerComponent},
    ]});
  }

  AddElementBackToSidebar(index: number, componentToClone: ControlContainerComponent) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToClone.component);
    const adder = componentFactory.create(this.componentInjector).instance;
    if ((adder as ControlContainerComponent).form.get("controlName").value === "Columns") {
      (adder as ColumnSplitterComponent).columnFormGroup = this.buildColumnSplitterFormGroup();
      (adder as ColumnSplitterComponent).addColumnAtIndex(0);
    } else if ((adder as BranchingContainerComponent).form.get("controlName").value === "Branching") {
      // (adder as BranchingContainerComponent).columnFormGroup = this.buildSectionFormGroup() ;
      console.log(adder);
    }else if ((adder as SectionComponent).form.get("controlName").value === "Section") {
      (adder as SectionComponent).componentFormGroup = this.buildSectionFormGroup() ;
      console.log(adder);
    }
    if (componentToClone.form.get("controlName").value === "Label") {
      (adder as TextboxControlComponent).convertToLabel();
    }
    this.sideBarDropZone.draggableComponents.splice(index,0,adder);
  }

  retrieveCurrentFormlyFieldRepresentation(explictRegenerate : boolean) : void {
    let formlyFields = [];
    const currentCount = this.mainDropZone.draggableComponents.map(x => x.NumberComponents).reduce((acc,value) => acc + value,0);
    if (currentCount !== this.numberControlsRendered) {
       console.log("Number controls:",currentCount);
      (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).controls.forEach(column => column.patchValue({connectedDropLists: this.dropListIdsForMain}));
    }
    if (explictRegenerate || currentCount !== this.numberControlsRendered) {
      let val: FormlyFieldConfig[];
      setTimeout(() => {
      val = ((this.mainDropZone.draggableComponents.map(x => x.toFormlyFieldConfig())).reduce((retVal: FormlyFieldConfig[], o) => {
        if (Array.isArray(o)) {
          o.forEach(aVal => retVal.push(aVal))
        } else {
          retVal.push(o);
        }
        return retVal;
      }, []) as FormlyFieldConfig[]);
      this.numberControlsRendered = currentCount;
      formlyFields = val;
      this.formlyFields.next(formlyFields);
    }, 50);
    } else {
      formlyFields = this.formlyFields.value;
    }
}

  getFirstFormSummaryDocIdParent(c: ControlContainerComponent) : string {
    if (c === null) {
        return this.activeFormFirestore$.value.formSummary.DocId();
    }
    if (c.componentForm?.value?.formFirestoreSummaryDocId !== undefined) {
      return c.componentForm.value.formFirestoreSummaryDocId;
    } else {
      return this.getFirstFormSummaryDocIdParent(c.parentContainer);
    }
  }


  onTalkDropSidebar(event: CdkDragDrop<ControlContainerComponent[]>) {
    let parentFormSummaryDocId = this.getFirstFormSummaryDocIdParent(event.item.data);
    const checkIfUserConfirmationNeeded =
    this.retrieveNestedComponentsService.ReturnCalculatedFieldsPresentInComponent(event.item.data, "Calculated",parentFormSummaryDocId).pipe(
      switchMap(calculatedFields => this.retrieveNestedComponentsService.ReturnPriceBookEntriesPresentInComponent(event.item.data, parentFormSummaryDocId).pipe(
        map( priceBookEntries => calculatedFields.concat(priceBookEntries.filter(x => calculatedFields.findIndex(y => y.linkedGuid === x.fieldGuid) === -1)))
      )),
      share(),
      take(1)
    );

    const confirmationResult : ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    const confirmatonNeeded = checkIfUserConfirmationNeeded.pipe(
      filter(x => x.length > 0),
      switchMap(x => this.priceBookEntryService.loadMultiple$([...new Set(x.flatMap(y => y.PriceBookEntryDocIds))]).pipe(
        map(priceBookEntries => {
          const retVal : ComponentResolutionResult[] = [];
          x.forEach(y => {
            if (y.PriceBookEntryDocIds.length === 0) {
              retVal.push(y);
            } else {
              y.PriceBookEntryDocIds.forEach(z => {
                const add = new ComponentResolutionResult(y);
                add.PriceBookEntryDocIds = [];
                add.PriceBookEntries.push(priceBookEntries.find(a => a.DocId() === z));
                add.PriceBookEntryDocIds.push(z);
                retVal.push(add);
              })
            }
          });
          return retVal;
        })
      )),
      tap(x => {
        const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          disableClose : false,
          autoFocus    : true,
          data         :
          {
            dataSource: x,
          }
          });
        this.userDeletionConfirmationModalRef = this.dialog.open(UserDelationConfirmationModalComponent, editorConfig);
        this.userDeletionConfirmationModalRef.afterClosed().pipe(
          map(x => x === undefined ? false : x),
          tap(x => confirmationResult.next(x)),
          take(1)
        ).subscribe();
        }
      ),
    );

    const noConfirmationNeeded = checkIfUserConfirmationNeeded.pipe(
      filter(x => x.length === 0),
      tap(() => confirmationResult.next(true)),
    );

    merge(confirmatonNeeded,noConfirmationNeeded).pipe(
      take(1)
    ).subscribe();

    const proceed = confirmationResult.pipe(
      filter(x => x === true),
      tap(() => {
        if (event.previousContainer !== event.container)  {
          event.previousContainer.data.splice(event.previousIndex,1);
          this.retrieveCurrentFormlyFieldRepresentation(true);
          const validate = this.derivedWorkflowService.ValidateComponentMovement(event.container.id, event.currentIndex, event.previousContainer.id, event.previousIndex,
            this.formlyUtilityService.mainDropZoneComponents);
          if (!validate.valid) {
            window.alert(validate.message);
            return;
          }
          this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
          // this.formlyUtilityService.triggerUnsavedChangeDetection$.next("mainDragDropZone");
        }
      })
    );

    const noProceed = confirmationResult.pipe(
      filter(x => x === false),
    );

    merge(proceed,noProceed).pipe(
      take(1)
    ).subscribe();

  }

  mutateComponentWhenDroppedInContainer(c: ControlContainerComponent): ControlContainerComponent {
    if (c.form.get("composerComponent").value || c.form.get("ControlRequiresFullWidth").value) {
      return c;
    } else {
      const columnSplitterComponent = new ColumnSplitterComponent(this.fb,this.componentFromFormlyFactory,this._ngZone, this.formlyComponentUtilityService,this.formlyUtilityService, this.derivedWorkflowService);
      columnSplitterComponent.columnFormGroup = this.buildColumnSplitterFormGroup();
      columnSplitterComponent.addColumnAtIndex(0,true);
      const dropListToPushTo = (((columnSplitterComponent.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup).get("cdkDropListData").value as Array<ControlContainerComponent>);
      c.parentContainer = columnSplitterComponent;
      dropListToPushTo.push(c);
      return columnSplitterComponent;
    }
  }


  checkIfElementContainsGuid(c: any, componentGuid: string, parentGuid: string, parentDropZoneId : string,
    sectionToSplice: ControlContainerComponent | null, formFirestoreSummaryDocIdToRemove: string | null, addRemove: "Add" | "Remove") : boolean {


    let retVal = false;

    const commonRecursion = (field:any) => {
      if (this.checkIfElementContainsGuid(field,componentGuid,parentGuid,parentDropZoneId,sectionToSplice, formFirestoreSummaryDocIdToRemove, addRemove)) {
        retVal= true;
      }
      return retVal;
    }

    if (!c) {
      return false;
    }

    if (c.value?.id === parentDropZoneId) {
      if (addRemove === "Add") {
        const spliceIndex = c.value.cdkDropListData.findIndex(x => x.guid === parentGuid)+1;
        if (spliceIndex > -1) {
          c.value.cdkDropListData.splice(spliceIndex,0,sectionToSplice);
          return true;
        }
      } else {
        const sectionToRemoveIndex = c.value.cdkDropListData.findIndex(x => x.componentForm?.value?.formFirestoreSummaryDocId === formFirestoreSummaryDocIdToRemove);
          if (sectionToRemoveIndex > -1) {
          const sectionToRemove = (c.value.cdkDropListData[sectionToRemoveIndex] as SectionComponent);
            sectionToRemove.removeSection();
            c.value.cdkDropListData.splice(sectionToRemoveIndex,1);
          } else {
            console.error("Unable to find section to remove index.");
          }
      }
    }

    if (c.value?.cdkDropListData) {
      if (Array.isArray(c.value?.cdkDropListData)) {
        for (var x in c.value.cdkDropListData) {
          if (commonRecursion(c.value.cdkDropListData[x])){
            break;
          }
        }
      }
    }

    if (c.controls) {
      if (Array.isArray(c.controls)) {
        for (var x in c.controls) {
          if (commonRecursion(c.controls[x])) {
            break;
          }
        }
      } else if (c.controls.columnFormGroups) {
        commonRecursion(c.controls.columnFormGroups);
      }
    }

    if (c.columnFormGroups?.controls) {
      for (var x in c.columnFormGroups.controls) {
        if (commonRecursion(c.columnFormGroups.controls[x])) {
          break;
        }
      }
    }
    return retVal;
  }

  addSectionFromParsed(contents: FormlyFieldConfig[], f: FormFirestore, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string, priceBookEntryDocId: string | null = null,
    patchUpdatedFormSummaryDocId$: Subject<{priceBookEntry: string, summaryDocId: string}>, formlySectionConfig: any, patchUpdatedFormlySectionConfig$: Subject<any>) {

    const retSection = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
      this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService,
      this.importDataWorkflowService, this.priceBookEntryService, this.unitOfMeasureService, this.derivedWorkflowService);
    retSection.patchedFromBranch = true;
    retSection.componentFormGroup = this.buildSectionFormGroup();
    retSection.activeFormFirestore.next(f);
    const field = this.formlyUtilityService.getSection(contents,true,f.formSummary.title,f.formSummary.DocId());
    if (priceBookEntryDocId !== null && field.props["unitOfMeasureMappings"] && field.props["unitOfMeasureMappings"].length > 0) {
      field.props["priceBookMappings"] = [
        new FormlySectionPricebookMapping({priceBookEntryDocId: priceBookEntryDocId, formlyTextboxGuid: field.props["unitOfMeasureMappings"][0].formlyTextboxGuid})
      ];
      field.props["unitOfMeasureMappings"] = new Array();
      retSection.componentFormGroup.patchValue({preventSave: true});
      retSection.patchedInUnitOfMeasureMapping = true;
      retSection.patchUpdatedSectionToFormlyBranch$ = patchUpdatedFormSummaryDocId$;
    }
    this.formlyUtilityService.addContainedComponentFormGroup$.next(retSection.componentForm);
    if (formlySectionConfig) {
      //iterate over properties and set field[prop] = formlySectionConfig[prop]
      for (const prop in formlySectionConfig) {
          field.props[prop] = formlySectionConfig[prop];
      }
    }
    retSection.patchUpdatedFormlySectionConfig$ = patchUpdatedFormlySectionConfig$;
    retSection.patchInFormlyFieldConfig(field);
    retSection.form.patchValue({serializeToDatabase : false});

    const AddToParentDropZoneIfNotMain = this.checkIfElementContainsGuid((this.form.controls["containedComponentFormGroups"] as UntypedFormArray),
          originSourceGuid,parentContainerGuid,parentDropZoneId,(retSection as unknown) as ControlContainerComponent, null, "Add");

    if (!AddToParentDropZoneIfNotMain) {
      const draggableIndex = this.mainDropZone.draggableComponents.findIndex(x => x.guid === parentContainerGuid) + 1;
      this.mainDropZone.draggableComponents.splice(draggableIndex,0,(retSection as unknown) as ControlContainerComponent);
    }
    this.formlyUtilityService.sectionKeyAddedToFormDesigner$.next(
      {sectionKey: retSection.fields[0].key as string, firestoreDocId: f.DocId(), source: originSourceGuid+parentContainerGuid});
    setTimeout(() => {
      this.patchUpdatesToContainedControlFormGroups((retSection as SectionComponent).form.get("controlComponentFormGroup").value);
    },500);
  }

addFirestore(val: {formFirestore: FormFirestore, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string, priceBookEntryDocId: string | null,
  patchUpdatedFormSummaryDocId$: Subject<{priceBookEntry: string, summaryDocId: string}>, formlySectionConfig: any, patchUpdatedFormlySectionConfig$: Subject<any>}) : void {
      const parsed = JSON.parse(val.formFirestore.form) as FormlyFieldConfig[];
      this.addSectionFromParsed(parsed, val.formFirestore, val.originSourceGuid, val.parentContainerGuid, val.parentDropZoneId, val.priceBookEntryDocId, val.patchUpdatedFormSummaryDocId$,
        val.formlySectionConfig, val.patchUpdatedFormlySectionConfig$);
}

  onTalkDropMain(event: CdkDragDrop<ControlContainerComponent[]>) {
    FormElementComponent.emitComponentClicked=true;

    const updateDerivedFieldMappingsIfNeeded = this.derivedWorkflowService.ValidateComponentMovement("mainDragDropZone", ((event.container as any)  as subDragDropZone).subIndex,
      event.previousContainer.id, event.previousIndex,this.formlyUtilityService.mainDropZoneComponents);
    console.log(`Derived fields needed updated on drop: ${updateDerivedFieldMappingsIfNeeded.componentsNeededUpdate}`);
    if (!updateDerivedFieldMappingsIfNeeded.valid) {
      window.alert(updateDerivedFieldMappingsIfNeeded.message);
      return;
    }

    // moved within same container.
    if (event.previousContainer === event.container) {
      if (event.previousIndex !== event.currentIndex)  {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        moveItemInArray(this.formlyFields.value, event.previousIndex, event.currentIndex)
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
        this.retrieveCurrentFormlyFieldRepresentation(true);
      }
    // Moved from a different container.
    } else {
        let controlWithMutationIfNeeded: ControlContainerComponent;
        // if previous container was sidebar, mutate element b/f transferring it.
        if (event.previousContainer.id === "sidebarDragDropZone") {
          controlWithMutationIfNeeded = this.mutateComponentWhenDroppedInContainer(event.previousContainer.data[event.previousIndex]);
          if (controlWithMutationIfNeeded.component.name  === "SectionComponent") {
            (controlWithMutationIfNeeded as SectionComponent).formlyViewInitializing$.next(false);
          }
          event.previousContainer.data[event.previousIndex] = controlWithMutationIfNeeded;
        } else {
          //20241219
          // if control is moved from previous container, and is a textbox control, need to validate that derived and input components are still valid.
          if (event.container.data) {
          console.log(event.container.data)
        }
          controlWithMutationIfNeeded = event.previousContainer.data[event.previousIndex];
        }
        transferArrayItem(event.previousContainer.data,this.mainDropZone.draggableComponents,event.previousIndex,((event.container as any)  as subDragDropZone).subIndex);
        // Originating from sidebar
        if (event.previousContainer.id === "sidebarDragDropZone") {
          this.AddElementBackToSidebar(event.previousIndex,event.item.data);
          controlWithMutationIfNeeded.form.get("triggerUpdate").valueChanges.pipe(
            tap(() => console.log("manual update of live view triggered!")),
            tap(() => this.retrieveCurrentFormlyFieldRepresentation(true)),
            takeUntil(controlWithMutationIfNeeded.destroyingComponent$)
          ).subscribe();
        }
        (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).push(controlWithMutationIfNeeded.componentForm);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(this.mainDropZone.draggableComponents);
        this.addMainDragDropZone();
        this.retrieveCurrentFormlyFieldRepresentation(true);
      }
  }

  getIconStyling(c:  ControlContainerComponent)  {
    return {
      'padding-right' : '10px',
      'color': `${c.form.get("iconColor").value}`
    };
  }

  private loadComponentDetailsSidebar(c: ControlContainerComponent) {
    if (this.focusedComponentModel) {
      this.focusedComponentModel.form.patchValue({focused: false});
    }

    if (c.fields[0].type !== "formlySection") {
      c.form.patchValue({focused: true});
    }

    this.focusedComponentModel = c;

    this.formlyComponentUtilityService.componentSelectedForSideView = c;

    if (this.componentSideViewForm$ === undefined) {
      this.componentSideViewForm$ = new BehaviorSubject<UntypedFormGroup>(c.form);
    } else {
      this.componentSideViewForm$.next(c.form);
    }

    if (this.componentSideViewParentForm$ === undefined) {
      this.componentSideViewParentForm$ = new BehaviorSubject<UntypedFormGroup>(c.parentContainer === undefined || c.parentContainer === null ? c.form : c.parentContainer.form);
    } else {
      this.componentSideViewParentForm$.next(c.parentContainer === undefined || c.parentContainer === null ? c.form : c.parentContainer.form);
    }

    this.formlyUtilityService.sidebarDebounceActive$.next(false);
    this.newFormForSidebar$.next(null);

    // on change, activate debounce.  After debouncing, deactivate it.  This is to reduce auto-saving when user is actively updating control
    // particularly text box controls.....
    const debounceObs = [c.form.get("controlComponentFormGroup").value.valueChanges];

    if (c.form.get("controlComponentFormGroup").value.get("data") !== undefined && c.form.get("controlComponentFormGroup").value.get("data") !== null) {
      debounceObs.push(c.form.get("controlComponentFormGroup").value.get("data").valueChanges);
    }
    if (c.form.get("controlComponentFormGroup").value.get("columns") !== undefined && c.form.get("controlComponentFormGroup").value.get("columns") !== null) {
      debounceObs.push(c.form.get("controlComponentFormGroup").value.get("columns").valueChanges);
    }

    merge(...debounceObs)
    .pipe(
      tap(() => this.formlyUtilityService.sidebarDebounceActive$.next(true)),
      takeUntil(this.newFormForSidebar$)
    ).subscribe();

    merge(...debounceObs).pipe(
      debounceTime(700),
      tap(() => this.formlyUtilityService.sidebarDebounceActive$.next(false)),
      takeUntil(this.newFormForSidebar$)
    ).subscribe();

  }

  private initilizeSiderBarDragDropZone() : DragDropZone {
    const columnSplitter = this.resolver.resolveComponentFactory(ColumnSplitterComponent).create(this.componentInjector).instance;
    columnSplitter.columnFormGroup = this.buildColumnSplitterFormGroup();
    columnSplitter.addColumnAtIndex(0);
    const textBox = this.resolver.resolveComponentFactory(TextboxControlComponent).create(this.componentInjector).instance;
    const label = this.resolver.resolveComponentFactory(TextboxControlComponent).create(this.componentInjector).instance;
    label.convertToLabel();
    const whiteSpace = this.resolver.resolveComponentFactory(WhitespaceControlComponent).create(this.componentInjector).instance;
    const imageControl = this.resolver.resolveComponentFactory(ImageControlComponent).create(this.componentInjector).instance;
    const choice = this.resolver.resolveComponentFactory(ChoiceComponent).create(this.componentInjector).instance;
    const rating = this.resolver.resolveComponentFactory(RatingControlComponent).create(this.componentInjector).instance;
    const pageBreak = this.resolver.resolveComponentFactory(PageBreakComponent).create(this.componentInjector).instance;
    const section = this.resolver.resolveComponentFactory(SectionComponent).create(this.componentInjector).instance;
    section.componentFormGroup = this.buildSectionFormGroup();
    const photoAdder = this.resolver.resolveComponentFactory(PhotoAdderComponent).create(this.componentInjector).instance;
    const signaturePad = this.resolver.resolveComponentFactory(SignaturePadEditorComponent).create(this.componentInjector).instance;
    const lineItems = this.resolver.resolveComponentFactory(LineItemsComponent).create(this.componentInjector).instance;
    const branching = this.resolver.resolveComponentFactory(BranchingContainerComponent).create(this.componentInjector).instance;

    return  {
        "name" :  "Form Components",
        "id" : "sidebarDragDropZone",
        "draggableComponents" : [
          textBox,
          whiteSpace,
          imageControl,
          choice,
          rating,
          pageBreak,
          section,
          photoAdder,
          columnSplitter,
          label,
          signaturePad,
          lineItems,
          branching,
        ]
      };
  }

  private initilizeMainDragDropZone(): DragDropZone {
    return {
        "name": "Design View",
        "id": "mainDragDropZone",
        "draggableComponents": [],
      };
  }

  private addMainDragDropZone() : void {
    const dropZoneToAdd = {
      name: "Design View",
      id: `mainDragDropZone #${this.mainDropZones.length}`,
      draggableComponents: [],
      subDragDropZone: this.mainDropZones.length
    };
    this.mainDropZones.push(dropZoneToAdd);
    this.addColumnDropListId$.next(dropZoneToAdd.id);
  }

  dropListForMainFiltered(index: number) : string[] {
    return this.mainDropZones.filter(x => x.id !== `mainDragDropZone #${index}`).map(x => x.id);
  }
}
