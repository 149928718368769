import { Injectable } from '@angular/core';
import { Job } from '../../../../common/src/data/dao/job';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { Subject, map, switchMap, zip } from 'rxjs';
import { addMinutes, subMinutes, differenceInMinutes,  getMinutes, getHours, startOfDay } from 'date-fns';
import { PhysicalAddressRoutingService } from '../physical-address-routing.service';
import { SiteVisitSchedulingService } from './site-visit-scheduling.service';
import { ResourceAvailibility, RESOURCE_AVAILIBILITY_CALCULATION_METHOD } from './resource-availibility';
import { ResourceAvailibilityService } from './resource-availibility.service';
import { EmployeeAvailabilityService } from '../../../../common/src/data/dao-services/employee-availability.service';
import { SettingsService } from '../settings/settings.service';
import { CommuteCharacterization } from '../../../../common/src/data/dao/commute';
import { Assignment } from '../../../../common/src/data/dao/assignment';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';
import { SiteVisitService } from '../../../../common/src/data/dao-services/site-visit.service';
import { EmployeeService } from '../../../../common/src/data/dao-services/employee.service';
import { exhaustMap, take } from 'rxjs/operators';
import { EmployeeAvailability } from '../../../../common/src/data/dao/employee-availability';

export class ProspectiveSchedule {
  resourceAvailibility: ResourceAvailibility[];
  schedulingGuidance: SchedulingGuidance;
}

export class SchedulingGuidance {
  assignment: Assignment;
  siteVisits: SiteVisit[];
  numberSiteVisitsMutated: number;
  mutationMinutes: number;
  commuteMinutesDelta: number;
  commuteMinutesBumpPreventLastSiteVisit: number;
  guid: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  get totalCommuteConsiderationMinutes(): number {
    return this.commuteMinutesDelta + this.commuteMinutesBumpPreventLastSiteVisit;
  }

  public constructor(init?: Partial<SchedulingGuidance>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SchedulingGuidanceService {

  activeJobs$: Subject<Job>;


  jobDurationBufferPercent = 0;

  constructor(private jobService: JobService, private siteVisitService: SiteVisitService,
    private addressRoutingService: PhysicalAddressRoutingService, private siteVisitSchedulingService: SiteVisitSchedulingService,
    private resourceAvailabilityService: ResourceAvailibilityService, private employeeAvailibilityService: EmployeeAvailabilityService,
    private settingsService: SettingsService, private employeeService: EmployeeService) {
  }

  retrieveEndOfWorkHoursFromProspectiveSchedule(startTimeToEvaluate: Date, prospectiveSiteVisit: SiteVisit, postceedingSiteVisits: SiteVisit[],
    employeeDocId: string, employeeWorkStartTime: Date) : Date
  {

    let retVal = addMinutes(startTimeToEvaluate, Math.round(prospectiveSiteVisit.expectedDurationHours * 60));
    var commuteDelta = 0;
    let postceedingIndex = 0;
    postceedingSiteVisits.forEach(p => {
      let commuteTimeToNewSites = p.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService, this.employeeService, employeeDocId,
        employeeWorkStartTime, postceedingIndex === 0 ? prospectiveSiteVisit : postceedingSiteVisits[postceedingIndex - 1], null, true).commuteTimeMinutes;
      if (postceedingIndex === postceedingSiteVisits.length -1 && p.commuteSiteToShopIncludedInWorkDay) {
        commuteTimeToNewSites += p.retrieveEndOfDayCommuteTime(true, this.addressRoutingService, this.settingsService,
          this.employeeService, employeeDocId, this.siteVisitService, true).commuteTimeMinutes;
      }
      commuteDelta += p.commuteTimeMinutes - commuteTimeToNewSites;
      postceedingIndex++;
    });
    for (const siteVisit of postceedingSiteVisits ) {
      retVal = addMinutes(retVal, Math.round(siteVisit.expectedDurationHours * 60) + siteVisit.commuteTimeMinutesToSite );
    }
    if (postceedingSiteVisits.length > 0) {
      const lastSiteVisit =  postceedingSiteVisits[postceedingSiteVisits.length - 1];
      if (lastSiteVisit.commuteSiteToShopIncludedInWorkDay) {
        retVal = addMinutes(retVal, lastSiteVisit.commuteTimeMinutesFromSiteBackToShop);
      }
    }
    const compareWithEmployeeEndTime = subMinutes(retVal,commuteDelta);
    return compareWithEmployeeEndTime;
  }

  retrieveStartOfWorkHoursFromProspectiveSchedule(endTimeToEvaluate: Date, prospectiveSiteVisit: SiteVisit, preceedingSiteVisits: SiteVisit[],
    employeeDocId: string, employeeWorkStartTime: Date) : Date
  {
    let retVal = new Date(endTimeToEvaluate);
    for (const siteVisit of preceedingSiteVisits) {
      retVal = subMinutes(retVal, Math.round(siteVisit.expectedDurationHours * 60) + siteVisit.commuteTimeMinutesToSite);
    }

    var commuteDelta = 0;
    let postceedingIndex = 0;
    preceedingSiteVisits.forEach(p => {
      let delta = p.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService, this.employeeService, employeeDocId,
        employeeWorkStartTime, postceedingIndex === 0 ? prospectiveSiteVisit : preceedingSiteVisits[postceedingIndex - 1], null, false).commuteTimeMinutes;
      commuteDelta += p.commuteTimeMinutes - delta;
      postceedingIndex++;
    });


    if (preceedingSiteVisits.length > 0) {
      const firstSiteVisit = preceedingSiteVisits[0];
      // if commute to first site is not included in work day, remove it.
      if (!firstSiteVisit.commuteToSiteIncludedInWorkDay) {
        retVal = addMinutes(retVal, firstSiteVisit.commuteTimeMinutesToSite);
      }
    }
    return retVal;
  }



  // Returns earliest possible ( if earliest == true) start time for site visit to occur and be situated before / after
  // the next bound visit while respecting previous committment windows, start of day and buffer time, or latest start
  // time ( if earliest == false)
  buildArrivalBounds(employeeDocId: string, prospectiveVisit: SiteVisit, daysSiteVisits: SiteVisit[],
    earliest: boolean, prospectiveDay: Date, startTimeForEmployee: Date, endTimeForEmployee: Date, nextBoundVisit?: SiteVisit): Date {

      if (startTimeForEmployee === null || endTimeForEmployee === null) {
        return null;
      }

      // #1  First handle case where you have recursed all the way to the (earliest or latest) bound visit.
      if (nextBoundVisit === null) {
        // Earliest visit is bound by employee start time and (depending on settings)
        // the commute time to get to job site..  Earliest will return the earliest the site visit can start.
        if (earliest) {
          let earliestStartBecauseOfStartOfDay : Date = startTimeForEmployee;
          const commuteToFirstSite = prospectiveVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService,
            this.employeeService, employeeDocId,startTimeForEmployee, null);
          if (commuteToFirstSite.commuteCharacterization === CommuteCharacterization.WORKDAY) {
            earliestStartBecauseOfStartOfDay = addMinutes(startTimeForEmployee,commuteToFirstSite.commuteTimeMinutes);
          }
          const earliestConsideringStartOfDayAndArrivalWindow = prospectiveVisit.arrivalWindowStartForScheduling === null ? earliestStartBecauseOfStartOfDay :
            prospectiveVisit.arrivalWindowStartForScheduling.getTime() > earliestStartBecauseOfStartOfDay.getTime() ?
            prospectiveVisit.arrivalWindowStartForScheduling : earliestStartBecauseOfStartOfDay;
            // If starting at earliest time yields a valid schedule, return it else return null.
            if (this.retrieveEndOfWorkHoursFromProspectiveSchedule(earliestConsideringStartOfDayAndArrivalWindow, prospectiveVisit,
              daysSiteVisits.filter(x=>x.docId !== prospectiveVisit.docId),employeeDocId, startTimeForEmployee).getTime() < endTimeForEmployee.getTime()) {
              return earliestConsideringStartOfDayAndArrivalWindow;
            } else {
              return null;
            }
        } else {
          // Latest visit is bound by employee end time and (depending on settings) the commute time to get from job site to final destination address.
            let latestStartBecauseOfEndOfDay = subMinutes(endTimeForEmployee,Math.round(prospectiveVisit.expectedDurationHours * 60) *
            (1 + this.jobDurationBufferPercent / 100));
            const commuteFromLastSite = prospectiveVisit.retrieveEndOfDayCommuteTime(true, this.addressRoutingService, this.settingsService, this.employeeService,
              employeeDocId, this.siteVisitService );
            // If commute from last site is part of work day, subtract it from the latest start.
            if (commuteFromLastSite.commuteCharacterization === CommuteCharacterization.WORKDAY) {
              latestStartBecauseOfEndOfDay = subMinutes(latestStartBecauseOfEndOfDay,commuteFromLastSite.commuteTimeMinutes);
            }
            const LatestArrivalConsideringEndOfDayAndArrivalWindow = prospectiveVisit.arrivalWindowEndForScheduling === null ?
            latestStartBecauseOfEndOfDay : latestStartBecauseOfEndOfDay.getTime() < prospectiveVisit.arrivalWindowEndForScheduling.getTime() ?
            latestStartBecauseOfEndOfDay : prospectiveVisit.arrivalWindowEndForScheduling;
            // If latest start time is before employee start time, return null, otherwise return latest start time.
            // if (this.retrieveStartOfWorkHoursFromProspectiveSchedule(LatestArrivalConsideringEndOfDayAndArrivalWindow, prospectiveVisit,
            //    daysSiteVisits.slice(0,daysSiteVisits.length -1),employeeDocId, startTimeForEmployee).getTime() < startTimeForEmployee.getTime()) {
              if (this.retrieveStartOfWorkHoursFromProspectiveSchedule(LatestArrivalConsideringEndOfDayAndArrivalWindow, prospectiveVisit,
                daysSiteVisits.slice(0,daysSiteVisits.length).filter(x => x.DocId() !== prospectiveVisit.DocId()),employeeDocId, startTimeForEmployee).getTime() < startTimeForEmployee.getTime()) {
              return null;
            } else {
              return LatestArrivalConsideringEndOfDayAndArrivalWindow;
            }
          }
      // #2 Now handle case where you have not recursed all the way to the (earliest or latest) bound visit.
      } else {
        if (earliest) {
          // get earliest possible to arrive b/c of preceeding appointments.
          const indexOfPreceedingVisit = daysSiteVisits.findIndex(x => x.docId === nextBoundVisit.docId);
          const preceederToPreceedingVisit = indexOfPreceedingVisit - 1 === -1 ? null :
            daysSiteVisits.slice(indexOfPreceedingVisit - 1, indexOfPreceedingVisit)[0];
          // recurse through earlier visits, with the next bound visit becomes the prospective visit, and the visit before next bound (if it exists)
          // becomes the next bound visit.
          const earliestDerivedFromRecursing = this.buildArrivalBounds(employeeDocId, nextBoundVisit,
            // daysSiteVisits.filter(x => x.docId !== nextBoundVisit.docId),
            daysSiteVisits, earliest, prospectiveDay, startTimeForEmployee, endTimeForEmployee, preceederToPreceedingVisit);
          const minutesCompletingNextBoundVisit = Math.round(nextBoundVisit.expectedDurationHours * 60) * (1 + this.jobDurationBufferPercent / 100);
          const minutesToCommuteToProspectiveSite = prospectiveVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService, this.employeeService,
            employeeDocId, startTimeForEmployee, nextBoundVisit).commuteTimeMinutes;
          const earliestFromPreceedingVisitRestrictions = addMinutes(earliestDerivedFromRecursing,minutesCompletingNextBoundVisit +
            minutesToCommuteToProspectiveSite);

          const earliestFromPreceedingAndArrivalWindow =  prospectiveVisit.arrivalWindowStartForScheduling === null ? earliestFromPreceedingVisitRestrictions :
          earliestFromPreceedingVisitRestrictions.getTime() > prospectiveVisit.arrivalWindowStartForScheduling.getTime() ? earliestFromPreceedingVisitRestrictions :
          prospectiveVisit.arrivalWindowStartForScheduling;
          // Check to make sure that schedule as arranged does not involve employee working before start of day or past end of day.
          if (this.retrieveEndOfWorkHoursFromProspectiveSchedule(earliestFromPreceedingAndArrivalWindow, prospectiveVisit, daysSiteVisits.slice(indexOfPreceedingVisit+2),
            employeeDocId, startTimeForEmployee).getTime() <=
              endTimeForEmployee.getTime() &&
              this.retrieveStartOfWorkHoursFromProspectiveSchedule(earliestFromPreceedingAndArrivalWindow, prospectiveVisit, daysSiteVisits.slice(0,indexOfPreceedingVisit),
              employeeDocId, startTimeForEmployee).getTime() >=
              startTimeForEmployee.getTime()) {
              return earliestFromPreceedingAndArrivalWindow;
            } else {
              return null;
            }
          // If placing visit before specified site visit + prospective duration + subsequent site visit durations and commutes exceeds
          //  employee end time return null.  RECURSE TO LATER VISIT.
        } else {
          const indexOfPostceedingVisit = daysSiteVisits.findIndex(x => x.docId === nextBoundVisit.docId);
          const postceederToPostceedingVisit = indexOfPostceedingVisit + 1 === daysSiteVisits.length ? null :
            daysSiteVisits.slice(indexOfPostceedingVisit + 1, indexOfPostceedingVisit + 2)[0];

            const prospectiveCommuteToNextBoundSite = nextBoundVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService,
            this.employeeService, employeeDocId, startTimeForEmployee, prospectiveVisit);

            const latestDerivedFromRecursing = this.buildArrivalBounds(employeeDocId, nextBoundVisit,
              daysSiteVisits,earliest, prospectiveDay, startTimeForEmployee, endTimeForEmployee, postceederToPostceedingVisit);
            const minutesCompletingProspectiveVisit = Math.round(prospectiveVisit.expectedDurationHours * 60 * ( 1 + this.jobDurationBufferPercent / 100));
          const latestFromPostceedingVisitRestrictions = subMinutes(latestDerivedFromRecursing,
            (prospectiveCommuteToNextBoundSite.commuteTimeMinutes + minutesCompletingProspectiveVisit));
          const latestFromPostceedingAndArrivalWindow = prospectiveVisit.arrivalWindowEndForScheduling === null ? latestFromPostceedingVisitRestrictions :
            latestFromPostceedingVisitRestrictions.getTime() < prospectiveVisit.arrivalWindowEndForScheduling.getTime() ? latestFromPostceedingVisitRestrictions :
            prospectiveVisit.arrivalWindowEndForScheduling;

          const preceedingVisits = indexOfPostceedingVisit > 1 ? daysSiteVisits.slice(0,indexOfPostceedingVisit -2) : [];
            // If placing visit after specified site visit + prospective duration + subsequent site visit durations and commutes exceeds employee end time return null;

            // const endOfWorkFromProspective = this.retrieveEndOfWorkHoursFromProspectiveSchedule(latestFromPostceedingAndArrivalWindow, prospectiveVisit,
            //   indexOfPostceedingVisit > -1 ? daysSiteVisits.slice(indexOfPostceedingVisit) : null,
            // employeeDocId, startTimeForEmployee);


            const startOfWorkFromProspective = this.retrieveStartOfWorkHoursFromProspectiveSchedule(latestFromPostceedingAndArrivalWindow, prospectiveVisit, preceedingVisits
              , employeeDocId, startTimeForEmployee);
            // if (endOfWorkFromProspective.getTime() <= endTimeForEmployee.getTime() && startOfWorkFromProspective.getTime() >= startTimeForEmployee.getTime()) {
            //   return latestFromPostceedingAndArrivalWindow;

            if (startOfWorkFromProspective.getTime() >= startTimeForEmployee.getTime()) {
              return latestFromPostceedingAndArrivalWindow;
            } else {
              return null;
            }
        }
      }
    }

  generateSiteVisitsToBeBeforeArrivalTime(activeVisit: SiteVisit, siteVisitsToModify: SiteVisit[],
    latestArrivalTime: Date, employeeDocId: string, startTimeForEmployee : Date, preceedingVisit?: SiteVisit): {mutationCount: number, mutationMinutes: number, siteVisits: SiteVisit[]} {

      activeVisit.recalculateCommuteTimeMinutes(preceedingVisit, false, this.addressRoutingService, this.settingsService, this.employeeService, employeeDocId,
        startTimeForEmployee, this.siteVisitService);
        const minutesScheduleNeedsToShift = differenceInMinutes(activeVisit.startDate, latestArrivalTime);
        const mutationCount = minutesScheduleNeedsToShift > 0 ? 1 : 0;
        if (minutesScheduleNeedsToShift > 0) {
        activeVisit.startDate = subMinutes(activeVisit.startDate, minutesScheduleNeedsToShift);
        activeVisit.endDate = subMinutes(activeVisit.endDate, minutesScheduleNeedsToShift);
        }
        if (preceedingVisit !== null) {
            latestArrivalTime = subMinutes(activeVisit.startDate,
              activeVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService,
                this.employeeService, employeeDocId, startTimeForEmployee, preceedingVisit).commuteTimeMinutes +
            Math.round(preceedingVisit.expectedDurationHours * 60));
            const indexOfPreceedingVisit = siteVisitsToModify.findIndex(x => x.docId === preceedingVisit.docId);
            const preceederToPreceedingVisit = indexOfPreceedingVisit - 1 === -1 ? null :
            siteVisitsToModify.slice(indexOfPreceedingVisit - 1, indexOfPreceedingVisit)[0];
            const recursiveRetVal = this.generateSiteVisitsToBeBeforeArrivalTime(preceedingVisit,
              siteVisitsToModify.slice(0, siteVisitsToModify.findIndex(x => x.docId === activeVisit.docId)),
              latestArrivalTime, employeeDocId,startTimeForEmployee, preceederToPreceedingVisit);
            recursiveRetVal.siteVisits = recursiveRetVal.siteVisits.concat(activeVisit);
            recursiveRetVal.mutationCount += mutationCount;
            recursiveRetVal.mutationMinutes += minutesScheduleNeedsToShift > 0 ? minutesScheduleNeedsToShift : 0;
            return recursiveRetVal;
          } else {
            return ({mutationMinutes: minutesScheduleNeedsToShift > 0 ? minutesScheduleNeedsToShift : 0 , mutationCount, siteVisits: [activeVisit]});
          }
  }

  generateSiteVisitsToBeAfterArrivalTime(activeVisit: SiteVisit, siteVisitsToModify: SiteVisit[],
    earliestArrivalTime: Date, employeeDocId: string, startTimeForEmployee: Date, postceedingVisit?: SiteVisit): {mutationCount: number, mutationMinutes: number, siteVisits: SiteVisit[]} {
    {
      const minutesScheduleNeedsToShift = differenceInMinutes(earliestArrivalTime, activeVisit.startDate);
      const mutationCount = minutesScheduleNeedsToShift > 0 ? 1 : 0;
      if (postceedingVisit !== null) {
        const indexOfPostceedingVisit = siteVisitsToModify.findIndex(x => x.docId === postceedingVisit.docId);
        const lastVisitOfDay = indexOfPostceedingVisit === siteVisitsToModify.length - 1;
        postceedingVisit.recalculateCommuteTimeMinutes(activeVisit, lastVisitOfDay,this.addressRoutingService, this.settingsService, this.employeeService,
          employeeDocId, startTimeForEmployee, this.siteVisitService);
      }
      if (minutesScheduleNeedsToShift > 0) {
      activeVisit.startDate = addMinutes(activeVisit.startDate, minutesScheduleNeedsToShift);
      activeVisit.endDate = addMinutes(activeVisit.endDate, minutesScheduleNeedsToShift);
      }
      if (postceedingVisit !== null) {
          earliestArrivalTime = addMinutes(activeVisit.startDate,
            postceedingVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService,
              this.employeeService, employeeDocId, startTimeForEmployee, activeVisit).commuteTimeMinutes +
            Math.round(activeVisit.expectedDurationHours * 60));
          const indexOfPostceedingVisit = siteVisitsToModify.findIndex(x => x.docId === postceedingVisit.docId);
          const sucessorToPostceedingVisit = indexOfPostceedingVisit === siteVisitsToModify.length - 1  ? null :
          siteVisitsToModify.slice(indexOfPostceedingVisit + 1, indexOfPostceedingVisit + 2)[0];
          const recursiveRetVal = this.generateSiteVisitsToBeAfterArrivalTime(postceedingVisit,
            siteVisitsToModify.slice(indexOfPostceedingVisit+1), earliestArrivalTime, employeeDocId,startTimeForEmployee, sucessorToPostceedingVisit);
          recursiveRetVal.siteVisits = recursiveRetVal.siteVisits.concat(activeVisit);
          recursiveRetVal.mutationCount += mutationCount;
          recursiveRetVal.mutationMinutes += minutesScheduleNeedsToShift > 0 ? minutesScheduleNeedsToShift : 0;
          return recursiveRetVal;
        } else {
          return ({mutationMinutes: minutesScheduleNeedsToShift > 0 ? minutesScheduleNeedsToShift : 0 , mutationCount, siteVisits: [activeVisit]});
        }
    }
  }

  calculatePreceedingBounds(employeeDocId: string, activeVisit: SiteVisit, preceedingVisits: SiteVisit[], latestDepartureFromPostceeding: Date,
    employeeAvailability: EmployeeAvailability, dateInQuestion: Date): Date[] {
    if (preceedingVisits.length === 0) {
      return [];
    }
    // add inflection from current.
    const current = preceedingVisits.splice(0, 1)[0];
    // inflection for current begins at end?
    let inflectionBegin = addMinutes(current.endDate, activeVisit.commuteTimeMinutesToSite);
    const endTimeForEmployee = this.employeeAvailibilityService.getEmployeeEndTimeForAvailibility(employeeAvailability, dateInQuestion);
    const latestStartBecauseOfEndOfDay = addMinutes(startOfDay(current.endDate), getHours(endTimeForEmployee) * 60 + getMinutes(endTimeForEmployee));
    if (inflectionBegin.getTime() > latestStartBecauseOfEndOfDay.getTime()) {
      inflectionBegin = latestStartBecauseOfEndOfDay;
    }
    const associatedInflectionTime = subMinutes(latestDepartureFromPostceeding, differenceInMinutes(current.endDate, current.startDate) +
     current.commuteTimeMinutesToSite);
    const earlierInflectionTimesNeedingRolling = this.calculatePreceedingBounds(employeeDocId, current, preceedingVisits, associatedInflectionTime, employeeAvailability, dateInQuestion);
    const addCurrentDurationToEarlierInflectionTimes: Date[] = [];
    earlierInflectionTimesNeedingRolling.forEach( x=> addCurrentDurationToEarlierInflectionTimes.push(addMinutes(x, Math.round(current.expectedDurationHours * 60) +  activeVisit.commuteTimeMinutesToSite)));

    if (inflectionBegin.getTime() !== latestDepartureFromPostceeding.getTime()) {
      return addCurrentDurationToEarlierInflectionTimes.concat(inflectionBegin);
    } else {
      return addCurrentDurationToEarlierInflectionTimes;
    }
  }

  calculatePostceedingBounds(employeeDocId: string, activeVisit: SiteVisit, postceedingVisits: SiteVisit[], lastestDepartureFromPreceeding: Date,
    employeeAvailability: EmployeeAvailability, dateInQuestion: Date): Date[] {
    if (postceedingVisits.length === 0) {
      return [];
    }

    const current = postceedingVisits.splice(0, 1 )[0];
    const startTimeForEmployee = this.employeeAvailibilityService.getEmployeeStartTimeForAvailibility(employeeAvailability, dateInQuestion);
    current.recalculateStartOfDayCommuteTime(activeVisit,this.addressRoutingService, this.settingsService, this.employeeService, employeeDocId, startTimeForEmployee, null);
    let inflectionBegin = subMinutes(current.startDate, current.commuteTimeMinutesToSite + Math.round(activeVisit.expectedDurationHours * 60));
    const earliestStartBecauseOfStartOfDay = addMinutes(startOfDay(current.startDate), getHours(startTimeForEmployee) * 60 + getMinutes(startTimeForEmployee) + activeVisit.commuteTimeMinutesToSite);
    if (earliestStartBecauseOfStartOfDay.getTime() > inflectionBegin.getTime()) {
      inflectionBegin = earliestStartBecauseOfStartOfDay;
    }
    const latestDepartureFromCurrentToNext = addMinutes(lastestDepartureFromPreceeding,Math.round(
      current.expectedDurationHours * 60) + current.commuteTimeMinutesToSite);
    const laterInflectionTimesNeedingRolling = this.calculatePostceedingBounds(employeeDocId, current, postceedingVisits,
      latestDepartureFromCurrentToNext, employeeAvailability, dateInQuestion);
    const subtractCurrentDurationFromLaterInflectioNTimes: Date[] = [];
    laterInflectionTimesNeedingRolling.forEach( x => {
      let rolledStartTime = subMinutes(x, Math.round(activeVisit.expectedDurationHours * 60) +  current.commuteTimeMinutesToSite);
      if (earliestStartBecauseOfStartOfDay.getTime() > rolledStartTime.getTime()) {
        rolledStartTime = earliestStartBecauseOfStartOfDay;
      }
      subtractCurrentDurationFromLaterInflectioNTimes.push(rolledStartTime);
    });
    if (inflectionBegin.getTime() !== lastestDepartureFromPreceeding.getTime()) {
      return subtractCurrentDurationFromLaterInflectioNTimes.concat(inflectionBegin);
    } else {
      return subtractCurrentDurationFromLaterInflectioNTimes;
    }
  }

  buildPreceedingVisits(prospectiveVisit: SiteVisit, daysSiteVisits: SiteVisit[], latestArrivalTime: Date, employeeDocId: string,
    startTimeForEmployee: Date, preceedingVisit?: SiteVisit ):
    {siteVisits: SiteVisit[], mutationCount: number, mutationMinutes: number} {
    if (preceedingVisit !== null) {
      if (prospectiveVisit.startDate === undefined) {
        latestArrivalTime = subMinutes(latestArrivalTime,
          prospectiveVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService,
            this.employeeService, employeeDocId, startTimeForEmployee, preceedingVisit).commuteTimeMinutes + Math.round(preceedingVisit.expectedDurationHours * 60));
      } else {
        latestArrivalTime = subMinutes(prospectiveVisit.startDate,
          prospectiveVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService,this.settingsService,
            this.employeeService, employeeDocId, startTimeForEmployee, preceedingVisit).commuteTimeMinutes + Math.round(preceedingVisit.expectedDurationHours * 60));
      }
      const indexOfPreceedingVisit = daysSiteVisits.findIndex(x => x.docId === preceedingVisit.docId);
      const preceederToPreceedingVisit = indexOfPreceedingVisit - 1 === -1 ? null :
      daysSiteVisits.slice(indexOfPreceedingVisit - 1, indexOfPreceedingVisit)[0];
      return this.generateSiteVisitsToBeBeforeArrivalTime(preceedingVisit,
        daysSiteVisits.slice(0, indexOfPreceedingVisit), latestArrivalTime, employeeDocId, startTimeForEmployee, preceederToPreceedingVisit);
    } else {
      return {siteVisits: [], mutationCount:  0, mutationMinutes:  0};
    }
  }

  buildPostceedingVisits(prospectiveVisit: SiteVisit, daysSiteVisits: SiteVisit[], earliestArrivalTime: Date, employeeDocId: string, startTimeForEmployee: Date, suceedingVisit?: SiteVisit):
    {siteVisits: SiteVisit[], mutationCount: number, mutationMinutes: number} {
      if (suceedingVisit !== null) {
        const indexOfSuceedingVisit = daysSiteVisits.findIndex(x => x.docId === suceedingVisit.docId);
        const suceedorToSucceedingVisit = daysSiteVisits.length - 1 === indexOfSuceedingVisit  ? null :
        daysSiteVisits.slice(indexOfSuceedingVisit + 1, indexOfSuceedingVisit + 2)[0];
        suceedingVisit.recalculateCommuteTimeMinutes(prospectiveVisit, suceedorToSucceedingVisit === null,this.addressRoutingService, this.settingsService, this.employeeService,
          employeeDocId, startTimeForEmployee, this.siteVisitService );
        earliestArrivalTime = addMinutes(prospectiveVisit.startDate, Math.round(prospectiveVisit.expectedDurationHours * 60) +
          suceedingVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService, this.employeeService, employeeDocId, startTimeForEmployee, prospectiveVisit).commuteTimeMinutes);
        return this.generateSiteVisitsToBeAfterArrivalTime(suceedingVisit,
          daysSiteVisits.slice(indexOfSuceedingVisit + 1), earliestArrivalTime, employeeDocId,  startTimeForEmployee, suceedorToSucceedingVisit);
      } else {
        return {siteVisits: [], mutationCount:  0, mutationMinutes:  0};
      }
    }

  generateProsectiveEmployeeScheduleIfValidSiteVisitSlot(prospectiveVisit: SiteVisit, daysSiteVisits: SiteVisit[], prospectiveDay: Date,
    employeeDocId: string,  startTimeForEmployee: Date, endTimeForEmployee: Date, preceedingVisit?: SiteVisit, suceedingVisit?: SiteVisit):
    ProspectiveSchedule {

      // If day being examined is in past, return null as it doesn't make sense to schedule a visit in the past
      if (startOfDay(prospectiveDay).getTime() < startOfDay(new Date()).getTime()) {
        return null;
      }


      const resourceAvailibility: ResourceAvailibility[] = [];
      const originalTotalCommuteMinutes = daysSiteVisits.reduce((acc, value) => acc + value.commuteTimeMinutes, 0);
      const employeeAvailability = this.employeeAvailibilityService.getAvailibilityForDate(prospectiveDay, employeeDocId);

      prospectiveVisit.recalculateCommuteTimeMinutes(preceedingVisit, suceedingVisit === null, this.addressRoutingService,this.settingsService,
        this.employeeService, employeeDocId,  startTimeForEmployee, this.siteVisitService);

      let unMutatedArrivalWindowStartTime = null;
      let unMutatedArrivalWindowEndTime = null;
      if (prospectiveVisit.arrivalWindowStartForScheduling !== null) {
          unMutatedArrivalWindowStartTime = new Date(prospectiveVisit.arrivalWindowStartForScheduling);
          unMutatedArrivalWindowEndTime = new Date(prospectiveVisit.arrivalWindowEndForScheduling);
          prospectiveVisit.arrivalWindowStartDate = null;
          prospectiveVisit.arrivalWindowEndDate = null;
      }

      let earliestArrivalTime = this.buildArrivalBounds(employeeDocId, prospectiveVisit, daysSiteVisits, true, prospectiveDay, startTimeForEmployee, endTimeForEmployee, preceedingVisit);
      let latestArrivalTime = this.buildArrivalBounds(employeeDocId, prospectiveVisit, daysSiteVisits, false, prospectiveDay, startTimeForEmployee, endTimeForEmployee, suceedingVisit);

      if (earliestArrivalTime === null || latestArrivalTime === null) {
        return null;
      }

      // If the latest we can arrive and complete job is before the current date/time, it isn't a valid slot.
      //*****************************   GET RID OF THIS RESTRICTION WHEN DONE TESTING
      // if (latestArrivalTime.getTime() < new Date().getTime()) {
      //   console.log(`Unable to schedule event before present time.  Latest arrival for slot:
      //   ${latestArrivalTime.toLocaleString()}.  Current time: ${new Date().toLocaleString()}`);
      //   return null;
      // }

        const initialProspectiveVisitStartDate =  prospectiveVisit.startDate ? new Date(prospectiveVisit.startDate) : undefined;
        prospectiveVisit.startDate = undefined;
        let preceedingSiteVisitMovementInflectionPoints: Date[] = [];
        let postceedingSiteVisitMovementInflectionPoints: Date[] = [];

        if (preceedingVisit !== null) {
          const latestDepartureFromPostceeding = subMinutes(earliestArrivalTime, prospectiveVisit.commuteTimeMinutesToSite);
          const preceedingVisitsToConsider = daysSiteVisits.slice(0,
          daysSiteVisits.findIndex(x => x.docId === preceedingVisit.docId) + 1).reverse();
          preceedingSiteVisitMovementInflectionPoints = this.calculatePreceedingBounds(employeeDocId, prospectiveVisit,
            preceedingVisitsToConsider, latestDepartureFromPostceeding, employeeAvailability, prospectiveDay);
        }

        if (suceedingVisit !== null) {
          const latestDepartureFromPreceedingVisit = addMinutes(latestArrivalTime, Math.round(prospectiveVisit.expectedDurationHours * 60));
          postceedingSiteVisitMovementInflectionPoints = this.calculatePostceedingBounds(employeeDocId, prospectiveVisit,
            daysSiteVisits.slice(daysSiteVisits.findIndex(x => x.docId === suceedingVisit.docId)), latestDepartureFromPreceedingVisit,
            employeeAvailability, prospectiveDay);
        }

        let inflectionArray: Date[] = [];
        if (earliestArrivalTime.getTime() === latestArrivalTime.getTime()) {
          inflectionArray = [earliestArrivalTime, earliestArrivalTime ];

        } else {
          const inflectionPointSet = new Set([earliestArrivalTime.getTime(), latestArrivalTime.getTime()]);
          preceedingSiteVisitMovementInflectionPoints.filter(x => x.getTime() > earliestArrivalTime.getTime()
        && x.getTime() < latestArrivalTime.getTime())
          .forEach(pre => inflectionPointSet.add(pre.getTime()));
          postceedingSiteVisitMovementInflectionPoints.filter(x => x.getTime() > earliestArrivalTime.getTime()
        && x.getTime() < latestArrivalTime.getTime())
          .forEach(post => inflectionPointSet.add(post.getTime()));
          inflectionArray = Array.from(inflectionPointSet).map(x => new Date(x));
          inflectionArray.sort((a, b) => a.getTime() - b.getTime());
        }
        let i: number;
        for ( i = 0; i < inflectionArray.length - 1; i++) {
          prospectiveVisit.startDate = new Date(inflectionArray[i]);
          const preceedingVisits = this.buildPreceedingVisits(prospectiveVisit, daysSiteVisits.map(x=>new SiteVisit(x)),
            latestArrivalTime,employeeDocId, startTimeForEmployee, preceedingVisit !== null ? new SiteVisit(preceedingVisit) : preceedingVisit);
          const postceedingVisits = this.buildPostceedingVisits(prospectiveVisit, daysSiteVisits.map(x=>new SiteVisit(x)),
            earliestArrivalTime, employeeDocId, startTimeForEmployee, suceedingVisit !== null ? new SiteVisit(suceedingVisit) : suceedingVisit);
          const siteVisitNumber = preceedingVisits.siteVisits.length + 1;
          const siteVisits = preceedingVisits.siteVisits.concat(prospectiveVisit).concat(postceedingVisits.siteVisits);
          const siteVisitCommuteTime = siteVisits.reduce((acc, value) => acc + value.commuteTimeMinutes, 0);
          const commuteMinutesDelta =  siteVisitCommuteTime - originalTotalCommuteMinutes;

          resourceAvailibility.push(new ResourceAvailibility({startDate: prospectiveVisit.startDate, endDate: inflectionArray[i + 1],
                                                              actualDate: prospectiveDay, employeeDocId, preceedingSiteVisits: preceedingVisits.siteVisits,
                                                              postceedingSiteVisits: postceedingVisits.siteVisits, siteVisitNumber,
                                                              numberPreceedingSiteVisitsMutated: preceedingVisits.mutationCount, minutesMutatedAtStartOfSegmentPreceedingSiteVisits:
                                                              preceedingVisits.mutationMinutes, numberPostceedingSiteVisitsMutated: postceedingVisits.mutationCount, commuteMinutesDelta,
                                                              commuteMinutesBeforeSiteVisit: prospectiveVisit.commuteTimeMinutesToSite,
                                                              commuteMinutesAfterSiteVisit: prospectiveVisit.commuteTimeMinutesFromSiteBackToShop,
                                                              minutesMutatedAtStartOfSegmentPostceedingSiteVisits: postceedingVisits.mutationMinutes}));
        }
        prospectiveVisit.startDate = initialProspectiveVisitStartDate;

    // If site visit placement between preceeding and suceeding is valid.
      if (earliestArrivalTime <= latestArrivalTime) {
        if (prospectiveVisit.startDate < earliestArrivalTime || prospectiveVisit.startDate > latestArrivalTime) {
          return null;
        }
        const preceedingVisits = this.buildPreceedingVisits(prospectiveVisit, daysSiteVisits, latestArrivalTime, employeeDocId,startTimeForEmployee, preceedingVisit);

        if (prospectiveVisit.startDate === undefined) {
          const startTimeForEmployee = this.employeeAvailibilityService.getEmployeeStartTimeForAvailibility(employeeAvailability, prospectiveDay);
          // If no proceeding visit, we must set start date.
          if (preceedingVisit === null) {
            // if no other site visits exist, we set to desired virgin time if possible.
            if (suceedingVisit === null && latestArrivalTime.getTime() >
              addMinutes(startOfDay(latestArrivalTime),getHours(this.settingsService.getValue('defaultStartTimeVirginSiteVisits')) * 60 +
              getMinutes(this.settingsService.getValue('defaultStartTimeVirginSiteVisits'))).getTime()) {
                prospectiveVisit.startDate =  addMinutes(startOfDay(prospectiveDay), getHours(this.settingsService.getValue('defaultStartTimeVirginSiteVisits')) * 60
                + getMinutes(this.settingsService.getValue('defaultStartTimeVirginSiteVisits')));
            } else {
              if (this.employeeService.get(employeeDocId).dispatchOrginAddressCommuteDispensation === CommuteCharacterization.COMMUTETIME) {
                prospectiveVisit.startDate =  addMinutes(prospectiveDay, getHours(startTimeForEmployee) * 60 + getMinutes(startTimeForEmployee));
              } else {
                prospectiveVisit.startDate = addMinutes(prospectiveDay, getHours(startTimeForEmployee) * 60 + getMinutes(startTimeForEmployee) +
                prospectiveVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService, this.settingsService,
                this.employeeService, employeeDocId,startTimeForEmployee, null).commuteTimeMinutes);
              }
            }
          } else {
            prospectiveVisit.startDate = addMinutes(preceedingVisit.startDate,
              Math.round(preceedingVisit.expectedDurationHours * 60) +
              prospectiveVisit.retrieveProspectiveCommuteBeforeSiteVisit(this.addressRoutingService,this.settingsService,
                this.employeeService, employeeDocId, startTimeForEmployee, preceedingVisit).commuteTimeMinutes);
          }

          prospectiveVisit.endDate = addMinutes(prospectiveVisit.startDate, Math.round(prospectiveVisit.expectedDurationHours * 60));
        }

        const postceedingVisits = this.buildPostceedingVisits(prospectiveVisit, daysSiteVisits, earliestArrivalTime, employeeDocId, startTimeForEmployee, suceedingVisit);


        const prospectiveAssignment =new Assignment({employeeDocId , siteVisitDocId: prospectiveVisit.docId, jobDocId: prospectiveVisit.jobDocId,
          siteVisitAddressDocId: prospectiveVisit.siteVisitAddress.DocId() });
        prospectiveVisit.assignments = [prospectiveAssignment];
        if (unMutatedArrivalWindowStartTime === null || prospectiveVisit.startDate.getTime() < unMutatedArrivalWindowStartTime.getTime() ||
         prospectiveVisit.startDate.getTime() > unMutatedArrivalWindowEndTime.getTime()) {
          const arrivalSettings = this.setArrivalWindows(prospectiveVisit, prospectiveDay, prospectiveAssignment);
          prospectiveVisit.arrivalWindowStartDate = arrivalSettings.arrivalWindowStart;
          prospectiveVisit.arrivalWindowEndDate = arrivalSettings.arrivalWindowEnd;
        } else {
          prospectiveVisit.arrivalWindowStartDate = unMutatedArrivalWindowStartTime;
          prospectiveVisit.arrivalWindowEndDate = unMutatedArrivalWindowEndTime;
        }
        const siteVisits = preceedingVisits.siteVisits.concat(prospectiveVisit).concat(postceedingVisits.siteVisits);
        const siteVisitCommuteTime = siteVisits.reduce((acc, value) => acc + value.commuteTimeMinutes, 0);
        const commuteMinutesDelta =  siteVisitCommuteTime - originalTotalCommuteMinutes;
        prospectiveVisit.commuteDeltaFromAdding = commuteMinutesDelta;
        if (siteVisits.find(x => x.commuteTimeMinutesFromSiteBackToShop === 120 || x.commuteTimeMinutesToSite === 120)) {
          console.warn("Commute time between locations not found.");
          console.log(siteVisits.map(s => s.siteVisitAddress.formattedAddress()));
          console.log(siteVisits.map(s => s));
          return null;
        }
        const guidanceToReturn = new SchedulingGuidance({numberSiteVisitsMutated: preceedingVisits.mutationCount + postceedingVisits.mutationCount, mutationMinutes:
          preceedingVisits.mutationMinutes + postceedingVisits.mutationMinutes, commuteMinutesDelta, siteVisits, assignment: prospectiveAssignment});
        const retGuidance = this.penalizeLastSiteVisitOfDayIfApplicable(guidanceToReturn,  preceedingVisits.siteVisits,postceedingVisits.siteVisits,prospectiveVisit)
        resourceAvailibility.forEach(r => r.guid = retGuidance.guid);
        return { schedulingGuidance: retGuidance, resourceAvailibility};
      } else {
        return null;
      }
    }

  getMinutesFromWorkingSchedule(siteVisits: SiteVisit[], employeeDocId: string): number {
    if (siteVisits.length ===0) {
      return 0;
    }
    let totalMinutes = 0;
    const firstVisit = siteVisits.splice(0,1)[0];
    if (firstVisit.commuteToSiteIncludedInWorkDay) {
      totalMinutes = totalMinutes + firstVisit.commuteTimeMinutesToSite + firstVisit.expectedDurationHours * 60;
    } else {
      totalMinutes = totalMinutes + firstVisit.expectedDurationHours * 60;
    }
    if (siteVisits.length === 0) {
      return totalMinutes;
    }
    const lastSiteVisit = siteVisits.splice(siteVisits.length - 1, 1)[0];
    if (lastSiteVisit.commuteSiteToShopIncludedInWorkDay) {
      totalMinutes = totalMinutes + lastSiteVisit.commuteTimeMinutesFromSiteBackToShop + lastSiteVisit.expectedDurationHours * 60;
    } else {
      totalMinutes = totalMinutes + lastSiteVisit.commuteTimeMinutesFromSiteBackToShop + lastSiteVisit.expectedDurationHours * 60;
    }
    siteVisits.forEach(midVisit => {
      totalMinutes = totalMinutes + midVisit.expectedDurationHours * 60 + midVisit.commuteTimeMinutesToSite;
    });
    return totalMinutes;
  }

  penalizeLastSiteVisitOfDayIfApplicable(schedulingGuidance: SchedulingGuidance, preceedingVisits: SiteVisit[], postceedingVisits: SiteVisit[], prospectiveSiteVisit: SiteVisit ) : SchedulingGuidance {
    // Penalty applies if prospective site will be last site visit of day, there are preceeding site visits, and the commute time from previous last site visit back to shop is greator then commute
    // time from new site visit to tech's final destination (else it is on the way back)
    if (postceedingVisits.length === 0 && preceedingVisits.length > 0) {

      const preceedingCommuteToShop = this.addressRoutingService.orginAddressIdToAssociatedCommutes
      .get(preceedingVisits[preceedingVisits.length - 1].siteVisitAddress.docId)
      .get(this.employeeService.get(schedulingGuidance.assignment.employeeDocId).dispatchDestinationAddressDocId);
      const preceedingCommuteTimeToShop = preceedingCommuteToShop === undefined ? 120 : preceedingCommuteToShop.timeEstimateSeconds / 60;
      if (prospectiveSiteVisit.commuteTimeMinutesFromSiteBackToShop <  preceedingCommuteTimeToShop) {
        schedulingGuidance.commuteMinutesBumpPreventLastSiteVisit = 0;
      } else {
        // Size of penalty is based on how full schedule will be after site visit is added.
        const minutessResourceAvailiableOnDay = this.employeeAvailibilityService.getMinutesWorkingForDate(prospectiveSiteVisit.startDate, schedulingGuidance.assignment.employeeDocId);
        const getMinutesFromWorkingSchedule = this.getMinutesFromWorkingSchedule(preceedingVisits.concat(prospectiveSiteVisit), schedulingGuidance.assignment.employeeDocId);
        // if day is at least 90% full, we don't penalize last site visit.
        if (getMinutesFromWorkingSchedule / minutessResourceAvailiableOnDay >= .9) {
          schedulingGuidance.commuteMinutesBumpPreventLastSiteVisit = 0;
        }
        // over 80% , a one minute bump which effectively will preference site visit being placed mid-day, but will still allow day w/ site visit to be winner against (any) longer commutes.
        if (getMinutesFromWorkingSchedule / minutessResourceAvailiableOnDay >= .8) {
          schedulingGuidance.commuteMinutesBumpPreventLastSiteVisit = 1;
        }
        // over 70% ; a stronger penalty which may very well bump top result to different day if site visit can't slide in amoung it's peers.
        if (getMinutesFromWorkingSchedule / minutessResourceAvailiableOnDay >= .7) {
          schedulingGuidance.commuteMinutesBumpPreventLastSiteVisit = schedulingGuidance.commuteMinutesDelta * 1.3 > 7 ? schedulingGuidance.commuteMinutesDelta * 1.3 : 7;
        } else {
          // less then 70%, even stronger penalty.
          schedulingGuidance.commuteMinutesBumpPreventLastSiteVisit = schedulingGuidance.commuteMinutesDelta * 1.4 > 9 ? schedulingGuidance.commuteMinutesDelta * 1.4 : 9;
        }
      }
    } else {
      schedulingGuidance.commuteMinutesBumpPreventLastSiteVisit = 0;
    }
    return schedulingGuidance;
  }

  setArrivalWindows(prospectiveVisit: SiteVisit, prospectiveDay: Date, prospectiveAssignment: Assignment): {arrivalWindowStart: Date, arrivalWindowEnd: Date} {
    const retVal =  this.siteVisitSchedulingService.regenerateArrivalWindow(prospectiveVisit.startDate, prospectiveVisit, prospectiveAssignment);
    return {arrivalWindowStart: retVal.arrivalWindowStartDate, arrivalWindowEnd: retVal.arrivalWindowEndDate};
  }

  firstAvailable(prospectiveSchedules: SchedulingGuidance[], sampleSize: number ): SchedulingGuidance[] {
      prospectiveSchedules.sort( (a, b) =>  a.siteVisits.find(z => z.prospectiveSiteVisit).startDate.getTime() <
      b.siteVisits.find(z => z.prospectiveSiteVisit).startDate.getTime() ? -1 :
      a.siteVisits.find(z => z.prospectiveSiteVisit).startDate.getTime() >
      b.siteVisits.find(z => z.prospectiveSiteVisit).startDate.getTime() ? 1 :
      a.numberSiteVisitsMutated < b.numberSiteVisitsMutated ? -1 : a.numberSiteVisitsMutated > b.numberSiteVisitsMutated ? 1 :
      0);
      prospectiveSchedules = this.singleOptionPerResourceDate(prospectiveSchedules, sampleSize);
      this.populateIcon(prospectiveSchedules, "b-fa-clock", "First Available");
      return prospectiveSchedules;
    }

  scheduledTime(schedule: SiteVisit[]): number {
    return schedule.reduce((acc, value) => acc + Math.round(value.expectedDurationHours * 60) + (value.commuteTimeMinutes > 0 ?
       value.commuteTimeMinutes : 0), 0);
  }

  lowestSiteVisitMutation(prospectiveSchedules: SchedulingGuidance[], lowestMutationResourceAvailArray: ResourceAvailibility[], updatedSiteVisitStartDate: Date, sampleSize: number,
      existingSiteVisits: SiteVisit[]):
  SchedulingGuidance[]  {
      prospectiveSchedules.sort( (a, b) => {
        // If same resourceDay, sort based on minimum site visit mutations, then lowest commute
        if (a.assignment.employeeDocId === b.assignment.employeeDocId &&
          startOfDay(a.siteVisits[0].startDate).getTime() === startOfDay(b.siteVisits[0].startDate).getTime()) {
          return this.sortSchedulingGuidanceMinimizeMutations(a, b, lowestMutationResourceAvailArray, updatedSiteVisitStartDate, existingSiteVisits);
        } else {
          return this.lowestCommute([a,b],2,false)[0] === a ? -1 : 1;
        }
      });
      prospectiveSchedules = this.singleOptionPerResourceDate(prospectiveSchedules, sampleSize);
      return prospectiveSchedules;
    }

  fillsDay(prospectiveSchedules: SchedulingGuidance[], sampleSize: number):
  SchedulingGuidance[]  {
      prospectiveSchedules.sort( (a, b) => {
        // If same resourceDay, sort based on lowest commute ( driving more does fill up more of the day, but not in the way we want!)
        if (a.assignment.employeeDocId === b.assignment.employeeDocId &&
          startOfDay(a.siteVisits[0].startDate).getTime() === startOfDay(b.siteVisits[0].startDate).getTime()) {
          return this.sortSchedulingGuidance(a, b);
        } else {
          // if both over schedule, return one that is less so otherwise if one over, retrun the non-over one.
          if (this.scheduledTime(a.siteVisits) > this.employeeAvailibilityService.getMinutesWorkingForDate(a.siteVisits[0].startDate,a.assignment.employeeDocId) &&
          this.scheduledTime(b.siteVisits) > this.employeeAvailibilityService.getMinutesWorkingForDate(b.siteVisits[0].startDate,b.assignment.employeeDocId)) {
            return this.scheduledTime(a.siteVisits) > this.scheduledTime(b.siteVisits) ? -1 :
            this.scheduledTime(a.siteVisits) < this.scheduledTime(b.siteVisits) ? 1 : 0;
          } else {
          if (this.scheduledTime(a.siteVisits) > this.employeeAvailibilityService.getMinutesWorkingForDate(a.siteVisits[0].startDate,a.assignment.employeeDocId)) {
            return 1;
          }
          if (this.scheduledTime(b.siteVisits) > this.employeeAvailibilityService.getMinutesWorkingForDate(b.siteVisits[0].startDate,b.assignment.employeeDocId)) {
            return -1;
          }
          return this.scheduledTime(a.siteVisits) < this.scheduledTime(b.siteVisits) ? 1 :
            this.scheduledTime(a.siteVisits) > this.scheduledTime(b.siteVisits) ? -1 : 0;
          }
        }
      });
      prospectiveSchedules = this.singleOptionPerResourceDate(prospectiveSchedules, sampleSize);
      this.populateIcon(prospectiveSchedules, "b-fa-angry", "Full Schedule");
      return prospectiveSchedules;
    }

    // Because lowest commute is default sort (to facilitate prefering low commute results on same filling of day)
  lowestCommute(prospectiveSchedules: SchedulingGuidance[], sampleSize: number, populateIcons: boolean = true) {
    prospectiveSchedules = this.singleOptionPerResourceDate(prospectiveSchedules, sampleSize);
    if (populateIcons) {
      this.populateIcon(prospectiveSchedules, "b-fa-shuttle-van", "Low Commute");
    }
    return prospectiveSchedules;
    }

  populateIcon(prospectiveSchedules: SchedulingGuidance[], iconCls: string, friendlyName: string)
  {
    prospectiveSchedules.forEach(x => {
      x.siteVisits.forEach(siteVisit => {
        if (siteVisit.prospectiveSiteVisit) {
          siteVisit.prospectiveIcon.push({class: `b-fa ${iconCls}`, name: friendlyName});
        }
      }
    )});
  }

  singleOptionPerResourceDate(prospectiveSchedules: SchedulingGuidance[], sampleSize: number) {
    const retVal: SchedulingGuidance[] = [];
    let outOfElements = false;
    do {
      if (prospectiveSchedules.length === 0) {
        outOfElements = true;
      } else {
        const toConsider = prospectiveSchedules.splice(0,1)[0];
        if (!retVal.some(x => startOfDay(x.siteVisits[0].startDate).getTime() ===
          startOfDay(toConsider.siteVisits[0].startDate).getTime()
          && x.assignment.employeeDocId === toConsider.assignment.employeeDocId)) {
          retVal.push(toConsider);
        }
      }
    } while (retVal.length < sampleSize && !outOfElements);
    return retVal;
  }


  private updateSiteVisits(siteVisitsToUpdate: SiteVisit[]) {
    this.siteVisitService.retrieveFirestoreBatchString().pipe(
      switchMap(wb => zip(...siteVisitsToUpdate.map(siteVisit => this.siteVisitService.update$(siteVisit,wb))).pipe(
        map(() => wb)
      )),
      exhaustMap(wb => this.siteVisitService.commitExternallyManagedFirestoreBatch(wb)),
      take(1)
    ).subscribe();
  }

  public CheckIfValidSchedulePossible(siteVisits: SiteVisit[], employeeDocId: string, prospectiveDate: Date, startTimeForEmployeeUnMutated: Date, endTimeForEmployeeUnMutated: Date, invalidSchedule: boolean,
    updateSiteVisits: boolean = true, respectExistingArrivalWindowBounds: boolean = false) {
    const startTimeForEmployee = addMinutes(prospectiveDate, startTimeForEmployeeUnMutated.getHours() * 60 + startTimeForEmployeeUnMutated.getMinutes());
    const endTimeForEmployee = addMinutes(prospectiveDate, endTimeForEmployeeUnMutated.getHours() * 60 + endTimeForEmployeeUnMutated.getMinutes());

    siteVisits.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
    let svIndex = 0;
    const siteVisitsToUpdate = [];
      for (const siteVisit of siteVisits) {
      const earliestArrivalTime = this.buildArrivalBounds(employeeDocId, siteVisit,
        siteVisits, true, prospectiveDate, startTimeForEmployee, endTimeForEmployee, svIndex === 0 ? null :
        siteVisits[svIndex - 1]);
      if (earliestArrivalTime === null || (respectExistingArrivalWindowBounds && earliestArrivalTime.getTime() > siteVisit.arrivalWindowEndForScheduling.getTime())) {
        invalidSchedule = true;
        siteVisitsToUpdate.forEach(s => {
          s.siteVisit.startDate = s.oStart;
          s.siteVisit.endDate = s.oEnd;
        });
        return {invalidSchedule};
      }
      if (earliestArrivalTime.getTime() !== siteVisit.startDate.getTime()) {
        const durationHours = siteVisit.expectedDurationHours;
        const oStart = new Date(siteVisit.startDate);
        const oEnd = new Date(siteVisit.endDate);
        siteVisit.startDate = earliestArrivalTime;
        siteVisit.endDate = addMinutes(earliestArrivalTime, Math.round(durationHours * 60));
        siteVisitsToUpdate.push({siteVisit, oStart, oEnd});
      }
      svIndex++;
    };
    if (!updateSiteVisits) {
      siteVisitsToUpdate.forEach(s => {
        s.siteVisit.startDate = s.oStart;
        s.siteVisit.endDate = s.oEnd;
      });
    }
    return { siteVisitsToUpdate: siteVisitsToUpdate.map(x => x.siteVisit), invalidSchedule };
  }

  setSiteVisitsToEarliestPossible(siteVisits: SiteVisit[], employeeDocId: string, prospectiveDate: Date, alertOnError: boolean = true) {
    let invalidSchedule = false;

    if (siteVisits.map(q => q.explicitErrored).reduce((a,b) => a || b, false) === true) {
      if (alertOnError) {
        window.alert("You can not automatically move site visits to earliest time on schedules which are errored.");
      }
      return null;
    }

    if (siteVisits.flatMap(s => s.prospectiveCommutes).findIndex(q => q.errored) > -1) {
      console.error("You can not move site visits to earliest time when commute time between site visits is not known");
      return null;
    }

    const employeeAvailability = this.employeeAvailibilityService.getAvailibilityForDate(prospectiveDate, employeeDocId);
    const startTimeForEmployee = this.employeeAvailibilityService.getEmployeeStartTimeForAvailibility(employeeAvailability, prospectiveDate)
    const endTimeForEmployee = this.employeeAvailibilityService.getEmployeeEndTimeForAvailibility(employeeAvailability, prospectiveDate);

    let siteVisitsToUpdate;
    ({ siteVisitsToUpdate, invalidSchedule } = this.CheckIfValidSchedulePossible(siteVisits, employeeDocId, prospectiveDate, startTimeForEmployee, endTimeForEmployee, invalidSchedule));

    if (invalidSchedule) {
      if (alertOnError) {
      window.alert("You can not automatically move site visits to earliest time on schedules which do not validate.");
      }
      return null;
    }

    if (siteVisitsToUpdate.length > 0) {
      this.updateSiteVisits(siteVisitsToUpdate);
    }
  }
  setSiteVisitsToLatestPossible(siteVisitsLatestToEarliest: SiteVisit[], employeeDocId: string, prospectiveDate: Date) {
    let invalidSchedule = false;

    if (siteVisitsLatestToEarliest.map(q => q.explicitErrored).reduce((a,b) => a || b, false) === true) {
        window.alert("You can not automatically move site visits to earliest time on schedules which are errored.");
      return null;
    }

    if (siteVisitsLatestToEarliest.flatMap(s => s.prospectiveCommutes).findIndex(q => q.errored) > -1) {
      console.error("You can not move site visits to latest time when commute time between site visits is not known");
      return null;
    }
    const employeeAvailability = this.employeeAvailibilityService.getAvailibilityForDate(prospectiveDate, employeeDocId);
    const startTimeForEmployee = this.employeeAvailibilityService.getEmployeeStartTimeForAvailibility(employeeAvailability, prospectiveDate);
      const endTimeForEmployee = this.employeeAvailibilityService.getEmployeeEndTimeForAvailibility(employeeAvailability, prospectiveDate);
    siteVisitsLatestToEarliest.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
    const siteVisitsEarliestToLatest = siteVisitsLatestToEarliest.slice().reverse();
    let svIndex = 0;
    const siteVisitsToUpdate = [];
    // siteVisits here is in latest => earliest order.
    siteVisitsLatestToEarliest.forEach(siteVisit => {
      const postceedingSiteVisit = svIndex === 0 ? null : siteVisitsLatestToEarliest[svIndex-1];
      const latestArrivalTime = this.buildArrivalBounds(employeeDocId, siteVisit,
        siteVisitsEarliestToLatest, false, prospectiveDate, startTimeForEmployee, endTimeForEmployee, postceedingSiteVisit);
      if (latestArrivalTime === null) {
        invalidSchedule = true;
        return null;
      }
      if (!invalidSchedule && latestArrivalTime.getTime() !== siteVisit.startDate.getTime()) {
        const durationHours = siteVisit.expectedDurationHours;
        siteVisit.startDate = latestArrivalTime;
        siteVisit.endDate = addMinutes(latestArrivalTime, Math.round(durationHours * 60));
        siteVisitsToUpdate.push(siteVisit);
      }
      svIndex++;
    });

    if (invalidSchedule) {
      window.alert("You can not automatically move site visits to latest time on schedules which do not validate.");
      return null;
    }

    if (siteVisitsToUpdate.length > 0) {
      this.updateSiteVisits(siteVisitsToUpdate);
    }
  }


  retrieveProspectiveSiteVisitAssignments(newSiteVisit: SiteVisit, existingSiteVisits: SiteVisit[], assignments: Assignment[],
    prospectiveDates: Date[], allowedEmployeeDocIds: string[], minimizeSiteVisitMutations: boolean = false, forceOneProspective : boolean = false):
    {resourceAvailibility: { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility[]}[], schedulingGuidance: SchedulingGuidance[]} {
      newSiteVisit.prospectiveSiteVisit = true;
      // I believe this is almost certainly hitting an outside resource, but we will scaffold a response in for moment.
      let retProspectiveSchedule: ProspectiveSchedule[] = [];
      let schedulingGuidance: SchedulingGuidance[] = [];
      let retResourceAvailibility: { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility[]}[] = [];
      prospectiveDates.forEach( day => {
        const existingSiteVisitsForDay = existingSiteVisits
          .filter(x => {
            if (x.startDate === undefined) {
              console.log(x);
            }
            return x.startDate?.toDateString() === day.toDateString();
          });
        allowedEmployeeDocIds.forEach(employeeDocId => {
          const employeeAvailability = this.employeeAvailibilityService.getAvailibilityForDate(day, employeeDocId);
          const startTimeForEmployee = this.employeeAvailibilityService.getEmployeeStartTimeForAvailibility(employeeAvailability, day);
          const endTimeForEmployee = this.employeeAvailibilityService.getEmployeeEndTimeForAvailibility(employeeAvailability, day);
          // If employee is not available on this day, skip
          if (startTimeForEmployee !== null) {
          const existingAssignmentsForEmployeeDay = assignments
            .filter(x => existingSiteVisitsForDay.some(y => y.docId === x.siteVisitDocId && x.employeeDocId === employeeDocId));
          // Sort by start time, then by site visit doc id ( arbitrary, but even in errored cases we must have consistent sorting)
          const existingSiteVisitsForEmployeeDay = existingSiteVisitsForDay.filter(x => existingAssignmentsForEmployeeDay
            .some(y => y.siteVisitDocId === x.docId)).sort((a, b) => a.startDate.getTime() < b.startDate.getTime() ? -1 :
              b.startDate.getTime() < a.startDate.getTime() ? 1 : a.docId < b.docId ? -1 : 1);
          let retSchedulingGuidanceForResourceDay: ProspectiveSchedule[] = [];
          if (existingSiteVisitsForEmployeeDay.length === 0) {
            const prospectiveSiteVisit = new SiteVisit(newSiteVisit);
            prospectiveSiteVisit.prospectiveIcon= [];
            // this.siteVisitService.retrieveDocId(prospectiveSiteVisit);
            retSchedulingGuidanceForResourceDay.push(this.generateProsectiveEmployeeScheduleIfValidSiteVisitSlot(prospectiveSiteVisit,
                                        existingSiteVisitsForEmployeeDay.map(x => new SiteVisit(x)), day, employeeDocId, startTimeForEmployee, endTimeForEmployee,
                                        null, null));

          } else {
            for (let i = 0; i <= existingSiteVisitsForEmployeeDay.length; i++) {
              const prospectiveSiteVisit = new SiteVisit(newSiteVisit);
              prospectiveSiteVisit.prospectiveIcon= [];
              // this.siteVisitService.retrieveDocId(prospectiveSiteVisit);
              const existingForConsiderationThisLoop = existingSiteVisitsForEmployeeDay.map(x => new SiteVisit(x));
              // consider prospective as first site visit assuming existing first site visit isn't locked to start of day.
              if (i === 0 && !existingSiteVisitsForEmployeeDay[0].lockPreceedingSiteVisitOrder ) {
                retSchedulingGuidanceForResourceDay.push(this.generateProsectiveEmployeeScheduleIfValidSiteVisitSlot(prospectiveSiteVisit,
                              existingForConsiderationThisLoop, day, employeeDocId, startTimeForEmployee,  endTimeForEmployee, null, existingForConsiderationThisLoop[i]));
              } else if (i>0) {
                // consider prospective as last site visit assuming existing last site visit isn't locked to end of day.
                if (i === existingForConsiderationThisLoop.length && !existingSiteVisitsForEmployeeDay[existingForConsiderationThisLoop.length - 1].lockNextSiteVisitOrder) {
                  retSchedulingGuidanceForResourceDay.push(this.generateProsectiveEmployeeScheduleIfValidSiteVisitSlot(prospectiveSiteVisit,
                                           existingForConsiderationThisLoop, day, employeeDocId,startTimeForEmployee, endTimeForEmployee,
                                           existingForConsiderationThisLoop[i - 1], null));
                } else {
                  // consider prospective as middle site visit assuming existing site visit before and after aren't locked to each other.
                  if (!existingForConsiderationThisLoop[i - 1].lockNextSiteVisitOrder )
                  retSchedulingGuidanceForResourceDay.push(this.generateProsectiveEmployeeScheduleIfValidSiteVisitSlot(prospectiveSiteVisit,
                                existingForConsiderationThisLoop, day, employeeDocId, startTimeForEmployee,  endTimeForEmployee, existingForConsiderationThisLoop[i - 1],
                                existingForConsiderationThisLoop[i]));
                }}
            }
          }
          // Update the guidance here to provide proper commute times for overlapping segments.

          retSchedulingGuidanceForResourceDay = retSchedulingGuidanceForResourceDay.filter(x => x !== null);
          const resourceAvailibilityForDay = this.resourceAvailabilityService.eliminateOverlapOfWorseResourceTimeSlots(
            retSchedulingGuidanceForResourceDay.flatMap(x => x.resourceAvailibility), existingSiteVisitsForEmployeeDay);
          if (resourceAvailibilityForDay.length !== 0) {
            retResourceAvailibility = retResourceAvailibility.concat(resourceAvailibilityForDay);
          }
          retProspectiveSchedule = retProspectiveSchedule.concat(retSchedulingGuidanceForResourceDay);
        }
        });
      });
      schedulingGuidance = retProspectiveSchedule.filter(x => x.schedulingGuidance.siteVisits !== undefined)
        .map(x => x.schedulingGuidance);
      if (schedulingGuidance.length === 0 ) {
        return null;
      }
      schedulingGuidance.sort((a,b) => this.sortSchedulingGuidance(a, b));
      const fillsDay = this.settingsService.getValue("numberFillsDaySiteVisits") > 0 ?
        this.fillsDay(schedulingGuidance.map(x => x),  this.settingsService.getValue("numberFillsDaySiteVisits")) : [];
      const firstAvailiable = this.settingsService.getValue("numberFirstAvailiableSiteVisits") > 0 ?
      this.firstAvailable(schedulingGuidance.map(x => x), this.settingsService.getValue("numberFirstAvailiableSiteVisits")) : [];
      const lowCommute = this.settingsService.getValue("numberLowCommuteSiteVisits") > 0 ?
      this.lowestCommute(schedulingGuidance.map(x => x), this.settingsService.getValue("numberLowCommuteSiteVisits")) : [];
      const lowestResourceAvailibilityMutations = retResourceAvailibility.filter(x => x.CalcuationModel === RESOURCE_AVAILIBILITY_CALCULATION_METHOD.MINIMZE_SITE_VISIT_MUTATIONS).flatMap(p => p.ResourceAvailibility);
      if (minimizeSiteVisitMutations && newSiteVisit.startDate) {
        const lowestMutations = this.lowestSiteVisitMutation(schedulingGuidance.map(x => x), lowestResourceAvailibilityMutations, newSiteVisit.startDate, 1, existingSiteVisits);
        schedulingGuidance = fillsDay.concat(firstAvailiable).concat(lowCommute);
        schedulingGuidance = schedulingGuidance.concat(lowestMutations);
        schedulingGuidance.sort((a,b) => this.sortSchedulingGuidanceMinimizeMutations(a, b,lowestResourceAvailibilityMutations, newSiteVisit.startDate, existingSiteVisits ));
      } else
      {
        schedulingGuidance = fillsDay.concat(firstAvailiable).concat(lowCommute);
        if (schedulingGuidance.length === 0 && forceOneProspective) {
          schedulingGuidance = [this.lowestCommute(schedulingGuidance.map(x => x), 1)[0]];
        }
        schedulingGuidance.sort((a,b) => this.sortSchedulingGuidance(a, b));
      }
      schedulingGuidance = this.singleOptionPerResourceDate(schedulingGuidance, schedulingGuidance.length);
      // We need doc ids here for uncommitted b/c we use them to modify schedules to display propsective schedules to user properly.
      schedulingGuidance.forEach(s => {
        s.siteVisits.forEach(sv => {
          if (sv.prospectiveSiteVisit && (sv.DocId() === undefined || sv.DocId() === null || sv.DocId() === "")) {
            sv.SetDocId(this.siteVisitService.getUnassignedSiteVisitDocId());
            sv.uncommitedDocId=true;
            s.assignment.siteVisitDocId = sv.docId;
          }
        });
      });
      return {resourceAvailibility: retResourceAvailibility,schedulingGuidance};
    }

  private sortSchedulingGuidance(a: SchedulingGuidance, b: SchedulingGuidance): number {
    // default sortation is lowest commute minutes delta + additional commute minutes if new site visit is last site visit of the day where
    // the amount of addition varies based on how full day will be.
    const retVal =  a.totalCommuteConsiderationMinutes < b.totalCommuteConsiderationMinutes ? -1 : b.totalCommuteConsiderationMinutes < a.totalCommuteConsiderationMinutes ? 1 :
       a.numberSiteVisitsMutated < b.numberSiteVisitsMutated ? -1 :
      b.numberSiteVisitsMutated < a.numberSiteVisitsMutated ? 1 : a.mutationMinutes < b.mutationMinutes ? -1 :
        b.mutationMinutes < a.mutationMinutes ? 1 : 0;
    return retVal;
  }

  private sortSchedulingGuidanceMinimizeMutations(a: SchedulingGuidance, b: SchedulingGuidance, lowestMutationResourceAvailArray: ResourceAvailibility[], updatedSiteVisitStartDate : Date, existingSiteVisits: SiteVisit[]): number {
    // find the matched resource availibility for a and b
    const aResourceAvail = lowestMutationResourceAvailArray.find(l => l.guid === a.guid && updatedSiteVisitStartDate.getTime() >= l.startDate.getTime() && updatedSiteVisitStartDate.getTime() <= l.endDate.getTime());
    const bResourceAvail = lowestMutationResourceAvailArray.find(l => l.guid === b.guid && updatedSiteVisitStartDate.getTime() >= l.startDate.getTime() && updatedSiteVisitStartDate.getTime() <= l.endDate.getTime());
    if (aResourceAvail === undefined &&  bResourceAvail === undefined) {
      return 0;
    } else if (aResourceAvail === undefined) {
      return 1;
    } else if (bResourceAvail === undefined) {
      return -1;
    }
    const winner = this.resourceAvailabilityService.generateResourceGuidanceFavoringLowSiteVisitMutations([aResourceAvail,bResourceAvail], updatedSiteVisitStartDate, existingSiteVisits);
    if (winner === aResourceAvail) {
      return -1;
    } else if (winner === bResourceAvail) {
      return 1;
    } else {
      return 0;
    }
  }

  isDuplicateSchedule(a: SiteVisit[], b: SiteVisit[]): boolean {
      if ( a === undefined || b === undefined || a.length !== b.length) {
          return false;
        }
      for (let i = 0; i < a.length; i++) {
          if (a[i].docId !== b[i].docId || a[i].startDate.getTime() !== b[i].startDate.getTime()) {
            return false;
          }
        }
      return true;
    }

  schedulesAreOnSameDay(a: SiteVisit[], b: SiteVisit[]): boolean {
    if (a === undefined || b === undefined || a.length === 0 || b.length === 0) {
      return false;
    } else {
      return  startOfDay(a[0].startDate).getTime() === startOfDay(b[0].startDate).getTime();
    }
  }
  }
