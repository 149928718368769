import { Injectable } from "@angular/core";
import { FormlyNodeStructureComponentMapping } from "./component-models/formly-controls/utilities/component-path";
import { FormlyUtilityService } from "./component-models/formly-controls/formly-utility.service";

@Injectable({
  providedIn: 'root'
})

export class DerivedWorkflowFieldPopulatorService {

  constructor(private formlyUtilityService: FormlyUtilityService) { }


  GetFormlyTextboxAssociatedWithGuid(textBoxGuid: string, inputComponentNodeMappings: FormlyNodeStructureComponentMapping[], field:any) {
    const inputComponent = inputComponentNodeMappings.find(x => x.InputComponentPath.controlGuid === textBoxGuid);
    let pathToComponent = inputComponent.InputComponentPath.pathToComponentFromFirstCommonFormSummaryAncestor;
    //replace -formSum:ABCDEF.fieldGroup with '.fieldGroup'
    pathToComponent = pathToComponent.replace(/-formSum:[^[]+\.fieldGroup/g,'.fieldGroup');
    // replace formSum:ABCDEF[ with [
    pathToComponent = pathToComponent.replace(/formSum:[^[]+\[/g,'[');
    const pathToCommonAncestor = this.PathToParentFormSummaryDocId(inputComponent.firstCommonFormFirestoreSummaryDocId, field);
    // path to component is path from common ancestor to component.  Need to add in path from root -> common ancestor.
    pathToComponent =  pathToCommonAncestor === '' ? pathToComponent : pathToCommonAncestor +  pathToComponent;
    //regex to split on .,[,]
    const pathArray = pathToComponent.split(/\.|\[|\]/).filter(x => x !== "");
    const componentTextBoxControl = pathArray.reduce(function(result,currentKey) {
    let res : any;
      try {
        res = result[currentKey];
      } catch (e) {
        console.error(e);
      }
      return result[currentKey];
    }, this.formlyUtilityService.activeFormlyFieldConfig);
    if (componentTextBoxControl?.props === undefined || componentTextBoxControl.props.guid !== textBoxGuid) {
      return null;
    }
    return componentTextBoxControl;
  }


  PathToParentFormSummaryDocId(formSummaryDocId: string, field: any ) : string {
    let retVal = "";
    let previousField = undefined;
    let currentField = field;
    let found = false;
    while (currentField !== undefined) {
      if (currentField.props.formFirestoreSummaryDocId === formSummaryDocId) {
        found = true;
        if (previousField) {
          retVal = `fieldGroup[${previousField.index}]`;
        }
        break;
      } else {
        previousField = currentField;
        currentField = currentField.parent;
      }
    };
    if (found) {
      while (currentField.parent !== undefined) {
        const index = currentField.index;
        currentField = currentField.parent;
          retVal = `fieldGroup[${index}]${retVal}`;
      };
      // replace first instance of "fieldGroup[x]"" with "[x]"
      retVal = retVal.replace(/fieldGroup\[(\d+)\]/,"[$1]");
      return retVal;
    } else {
      return "";
    }
  }

}
