<div style="display: flex;">
<div class="header-border header-label">{{tableHeader}}</div>
</div>

<div style="display: grid; grid-template-columns: .5fr 1fr .5fr 1fr; padding: 30px 20px 20px 20px;">
<ng-container *ngIf="(activeDataViewOption$|async) === 1" style="display: contents;">
  <div class="label-style">Category</div>
  <div class="medium-field">
  <mat-form-field appearance="outline" style="width: 100%;">
  <mat-select [formControl]="catagoryForm" placeholder="Choose Category" [compareWith]="compareWithDocIds">
    <mat-option *ngFor="let cat of catagoriesToSelectFrom" [value]="cat">{{cat.name}}</mat-option>
  </mat-select>
</mat-form-field>
</div>
</ng-container>

<div class="label-style">Filter</div>
<mat-form-field appearance="outline" style="width: 100%;">
  <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
</mat-form-field>
</div>

<div class="mat-table-container">
<table mat-table [dataSource]="importedDataSource"  multiTemplateDataRows class="mat-elevation-z8" style="background-color: rgba(0,0,0,0); recycleRows">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="importWorkflowAssignmentService.selection.hasValue() && isAllSelected()"
                    [indeterminate]="importWorkflowAssignmentService.selection.hasValue() && !isAllSelected()"
                    [disabled]="!allSelectable()"
                    >
      </mat-checkbox>
    </th>
    <td *matCellDef="let row" style="text-align: center;">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? importWorkflowAssignmentService.selection.toggle(row) : null"
                    [checked]="importWorkflowAssignmentService.selection.isSelected(row)"
                    [disabled]="!selectable(row)"
                    >
      </mat-checkbox>
    </td>
  </ng-container>

<!-- Collapsed Content View. -->
<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplayStandardText">
  <th mat-header-cell class="w-75" *matHeaderCellDef> {{columnsToDisplayHumanReadable.get(column) === undefined ? column : columnsToDisplayHumanReadable.get(column)}} </th>
  <td class="line-item-border-top w-75" mat-cell *matCellDef="let data;">{{data[column]}}</td>
</ng-container>

<ng-container matColumnDef="formFirestoreSummaryTitle">
  <th mat-header-cell class="w-75" *matHeaderCellDef> Workflow </th>
  <td class="line-item-border-top w-75" mat-cell [ngStyle]="SelectColor(data)" *matCellDef="let data;">{{data['formFirestoreSummaryTitle']}}</td>
</ng-container>

<ng-container matColumnDef="createdAt">
  <th mat-header-cell *matHeaderCellDef class="w-75">Imported On</th>
  <!-- <td class="line-item-border-top bullocks" class="w-75" mat-cell *matCellDef="let data" >{{data | date:'medium' }} </td> -->
  <td class="line-item-border-top bullocks" class="w-75" mat-cell *matCellDef="let data" >{{data['createdAt'] | date:'short' }} </td>
</ng-container>


<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
<ng-container matColumnDef="expandedDetail">
  
  <td mat-cell class="w-75" *matCellDef="let data" [attr.colspan]="columnsToDisplay.length" style="border-bottom: 0px solid lightgray;">
    <div style="display: grid; grid-template-columns: 1fr 2fr; grid-column-gap: 10px;">
    <div class="second-row-layout" *ngFor="let info of expandedInformationToDisplay" [@detailExpand]="expandedElementContainsData(data) ? 'expanded' : 'collapsed'">
      <div style="font-weight: 700; padding: 15px; text-align: right;"class="description-internal-label">{{info}}:</div>
      
      <div style="padding: 15px; text-align: left;">{{data[info]}}</div>
      
    </div>
  </div>
      <ng-container *ngIf="data.formFirestoreSummaries">
        <div [@detailExpand]="expandedElementContainsData(data) ? 'expanded' : 'collapsed'">
          <div *ngFor="let workflow of data.formFirestoreSummaries">
            <div style="grid-column: 1/2; grid-row: 1/2;" class="description-internal-label">Workflow</div>
            <div style="grid-column: 2/5; grid-row: 1/2;">
              <div >{{workflow.title}}</div>
            </div>
          </div>
        </div>
      </ng-container>
  </td>
  
</ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay" style="height: 40px;"></tr>
<tr mat-row *matRowDef="let data; columns: columnsToDisplay;"
    class="example-lineItem-row"
    [class.example-expanded-row]="expandedElementContainsData(data)"
    (click)="toggleExpandedElementView(data)" style="height: 40px;">
</tr>
<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
<!-- <tr mat-row *matRowDef="let row; columns: []" style="height: 0px;"></tr> -->
<!-- <tr mat-footer-row *matFooterRowDef="columnsToDisplay" style="height: 40px; border-left: none; border-right: none;"></tr>
<tr mat-footer-row *matFooterRowDef="footerSecondRowColumns" class="example-second-footer-row donotprint" style="border-top: none; border-left: none; border-right: none;"></tr> -->
</table>
</div>
