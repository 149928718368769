import { Injectable } from '@angular/core'
import { CostStructure } from '../../dao/pricebook/cost-structure';
import { DatabaseStoreService } from '../../database-backend/database-store.service';
import { AuthenticationService } from '../../../util/authentication.service';
import { StateChangeStoreService } from '../../database-backend/state-change-store.service';
import { FirestoreDiffService } from '../firestore-diff.service';
import { FirestoreBackend } from '../../database-backend/retrieve-from-firestore';


@Injectable({
  providedIn: 'root'
})
export class CostStructureService extends DatabaseStoreService<CostStructure> {

  constructor( fs: CostStructureFirestoreService, store: CostStructureStoreService, authenticationService: AuthenticationService) {
    super(fs, store, authenticationService);
  }

  populateGarretyCostStructures() {
    this.create$(new CostStructure({title: "Sub Contractor", code: "Sub Cost", markup: 1.42, importLastUpdated: new Date()})).subscribe();
    this.create$(new CostStructure({title: "Material Cost", code: "Material Cost", markup: 2.13, importLastUpdated: new Date()})).subscribe();
    this.create$(new CostStructure({title: "Labor Cost", code: "Labor Cost", markup: 2.13, importLastUpdated: new Date()})).subscribe();
    this.create$(new CostStructure({title: "Permit Cost", code: "Permit Cost", markup: 2.13, importLastUpdated: new Date()})).subscribe();
  }

}

@Injectable({
  providedIn: 'root'
})
export class CostStructureStoreService extends StateChangeStoreService<CostStructure> {
  protected store = "CostStructure-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, CostStructure>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class CostStructureFirestoreService extends FirestoreBackend<CostStructure> {

 protected basePath = "CostStructure";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): CostStructure {
return new CostStructure(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new CostStructure(), authService);
 }
}
