import { ErrorHandler, Injectable, NgZone, Optional, inject } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { AuthenticationService } from '../../../util/authentication.service';
import { ErrorLogEntry } from '../../../data/logging/errorLogEntry';
import * as ErrorStackParser from 'error-stack-parser';
import StackTraceGPS from 'stacktrace-gps';
import { Platform } from '@ionic/angular';
import { LocalSettingsService } from '../../../../../web-app/src/app/settings/local-settings.service';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { skipAllMessages, skipMobileMessages, skipWebMessages } from './skipErrorLogging';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';

//Adapted from :  https://pkief.medium.com/global-error-handling-in-angular-ea395ce174b1
// Mapping the frames:  https://github.com/stacktracejs/stacktrace-gps


var callback = function myCallback(foundFunctionName, frames: string[], frameIndex: number, originalFrame: any) {
  frames[frameIndex]=foundFunctionName;
};

var errback = function myErrback(error, frames:string[], frameIndex, originalFrame: string) {
  try {
    frames[frameIndex]=`ERR- ${originalFrame}`
  } catch (error) {
    frames[frameIndex]=`ERROR PARSING IN ERROR HANDLER`;
  }
};

// Wrap function to allow error callback access to parameters.
function attemptProcessFrame(frames: string[], frameIndex: number, originalFrame: string) {
  return callback;
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  platform: Platform;
  suppressUserNotification: boolean = false;
  topLevelMessaging: boolean = false;

  constructor(private zone: NgZone,private logger: NGXLogger, platform: Platform,
    @Optional() private authenticationService: AuthenticationService, @Optional() private localSettingsService: LocalSettingsService ) {
      this.platform = platform;
    }

  async handleError(error: any) {

    let handledInnodbException = false;

    try {
      // return;
      // Bail out of top level error handling for developer (me)
      if (this.authenticationService && this.authenticationService._userId === "OgJHFCUleFUTe9VJtJfFoyUXBJ52") {
        console.error(error);
        return;
      }

      // if (this.authenticationService && this.authenticationService._userId === "73AXL8o8KROSLJPxVeisMzRAQal2") {
      //   this.topLevelMessaging = true;
      // }

      let errorsToSkip: string[] = [];
      if (this.localSettingsService) {
        errorsToSkip = this.localSettingsService.app === "WEB" ? skipWebMessages : skipMobileMessages;
      } else {
        errorsToSkip = skipAllMessages;
      }
      // If error doesn't need logged, bail.
      if (errorsToSkip.includes(error.message)) {
        return;
      }

      const writeSecretFile = async (error:any, tryMkDir: boolean = false) => {

        try {
          await Filesystem.writeFile({
            path: 'secrets/fatalError.txt',
            data: JSON.stringify({message: error.message, err:error}),
            directory: Directory.Documents,
            encoding: Encoding.UTF8,
          });
          console.log("WROTE SECRET FILE");
        } catch (dirError) {
          if (!tryMkDir) {
            await Filesystem.mkdir({
              path: 'secrets',
              directory: Directory.Documents,
              recursive: true
            });
            await writeSecretFile(error,true);
          } else {
            if (error["extra"] === undefined) {
              await writeSecretFile({message: error.message, extra: `Failed to write secret file with error: ${dirError.message}`}, true)
            } else {
              window.alert(`Failed to write error file.  dirError: ${dirError.message}  error: ${error.message}`);
            }
          }
        }
      };

      //TO handle errors we can't handle gracefully:  ( From here:  https://github.com/firebase/firebase-js-sdk/issues/2581)
      const fatalErrorsRegExp = [
        /Exceeded allowed soft restarts of firestore web worker.*/,
      ];

      for (const fatalErrorRegExp of fatalErrorsRegExp) {
        const re = new RegExp(fatalErrorRegExp);
        const isMatch = re.test(error) || re.test(error.message);
        if (isMatch) {
          const retryGlobalCaught= new Error(`RETRY GLOBAL CAUGHT window.location: ${window.location.href}`);
          try {
            this.submitErrorToLogs(retryGlobalCaught);
          } catch (error) {
          }
          setTimeout(async () => {
            if (window.location.hash.indexOf("#retryglobal") === -1) {
              window.location.hash = '#retryglobal-0';
            }
            await writeSecretFile(error);
            const retryGlobalCaught= new Error(`SECRET FILE WRITTEN SUCCSSFULLY`);
            try {
              this.submitErrorToLogs(retryGlobalCaught);
            } catch (error) {
            }
            // mark the page so we can delay reloading if retryglobal is caught again here to try to avoid looping.
            window.location.reload();
            return;
          }, window.location.hash.indexOf("#retryglobal") > -1 ? 2000 : 0 );
        }
      }

    if (handledInnodbException) {
      return;
    }

    this.submitErrorToLogs(error);

    // TESTES   TESTES
    if (!this.suppressUserNotification && this.topLevelMessaging) {
      window.alert(`Top lvl err:  ${error}`);
      this.suppressUserNotification=  true;
      of(null).pipe(
        delay(5000),
        tap(()=>this.suppressUserNotification=false)
      ).subscribe();
    }



    const errorLog : ErrorLogEntry = new ErrorLogEntry();
      errorLog.generationTime = new Date();
      errorLog.logType = NgxLoggerLevel.ERROR;
      errorLog.message = error.message;

      // if local settings service is availiable, use it.
      if (this.localSettingsService) {
        errorLog.version = this.localSettingsService.appVersion;
        errorLog.app = this.localSettingsService.app;
        errorLog.extraInfo = this.localSettingsService.extraLogInfo;
        if (errorLog.message.indexOf("INTERNAL ASSERTION FAILED") > -1) {
          errorLog.extraInfo = "INTERNAL ASSERTION FAILED";
        }
      }

      // if authentication service is availiable, use it.
      if (this.authenticationService) {
        errorLog.employeeGuid = this.authenticationService._userId;
        // if employee service is availiable, use it.
        if (this.authenticationService.activelyLoggedInEmployee) {
            errorLog.userDocId = this.authenticationService.activelyLoggedInEmployee.DocId();
            errorLog.userName = this.authenticationService.activelyLoggedInEmployee.name;
          }
      }
      var stackFrames = null;
      try {
        stackFrames = ErrorStackParser.parse(error);
      } catch {
        this.logger.error(`Failed parsing stacktrace on ${error.message}`, error, NgxLoggerLevel.ERROR, 'Error Stack Parser CATCH BLOCK');
      }
      const semiProcessedLog = {...errorLog};
      semiProcessedLog.stackTrace = stackFrames;
      semiProcessedLog.processState = "RAW";
      this.logger.error(`${semiProcessedLog.app} RAW - ${error.message}`, semiProcessedLog, NgxLoggerLevel.ERROR, 'Semi-processed Stack Trace');

      const mappedFrames : any[] = [];
      // process parsed stack frames to set line and column numbers + actual source file.
      if (!this.platform.is('desktop') && stackFrames) {
        // for capacitor.
        stackFrames.forEach(frame => {
          if (frame.functionName) {
            frame.source = frame.source.replace(`${frame.functionName}`,'');
          }
          if (!frame.lineNumber) {
            frame.lineNumber = 1;
          }
          if (!frame.columnNumber) {
            frame.columnNumber = 1;
          }
        });
        // for webpage.
      } else {
        if (stackFrames) {
          var frameRE = /https?.*:(\d+:\d+)[^\d]*$/;
          stackFrames.forEach(frame => {
            const proc = frameRE.exec(frame.source);
            frame.fileName = proc[0].slice(0,proc[0].indexOf(proc[1])-1);
            frame.source = frame.fileName;
            frame.lineNumber = parseInt(proc[1].split(':')[0]);
            frame.columnNumber = parseInt(proc[1].split(':')[1]);
          });
        }
      }

      // resolve stack frames as best we can, reverting to original frame prepended with ERR if resolution fails.
      var gps = new StackTraceGPS();
      const resolvedStackFrames = [];
      let frameIndex = 0;
      if (stackFrames) {
        stackFrames.forEach(frame => {
          const index = parseInt(frameIndex.toString());
          resolvedStackFrames.push(
            gps.pinpoint(frame).then(a => attemptProcessFrame(mappedFrames,index,frame.toString())(a,mappedFrames,index,frame.toString()))
                               .catch( () => {
                                  errback(error,mappedFrames,index,frame.toString());
                                }));
          frameIndex++;
        });
      }

      await Promise.all(resolvedStackFrames);
      errorLog.stackTrace = mappedFrames;
      errorLog.srcStackTrace = mappedFrames.filter(x => x.fileName && x.fileName.indexOf('//src/') > -1);
      errorLog.processState = "PROC";
      this.logger.error(`${semiProcessedLog.app} PROC - ${error.message}`, errorLog, NgxLoggerLevel.ERROR, 'GlobalErrorHandler');
    } catch (error) {

      this.logger.error(error.message, error, NgxLoggerLevel.ERROR, 'GlobalErrorHandler CATCH BLOCK');
      console.warn(error);


      // TESTES   TESTES
      // window.alert(`Error in global error handler:  ${error}`);




    }
  }

  submitErrorToLogs(error:any) {
    this.logger.flushServerQueue();
    // Get the current config
    var config = this.logger.getConfigSnapshot();
    // Update to error config (send immediately, no batching)
    config.serverCallsTimer = undefined;
    config.serverCallsBatchSize = undefined;
    config.serverLoggingUrl = this.localSettingsService && this.localSettingsService.app === "WEB" ?
      'https://us-central1-service-vanguard.cloudfunctions.net/ml-logWebError' :
      'https://us-central1-service-vanguard.cloudfunctions.net/ml-logMobileError';
    // Setting the config
    this.logger.updateConfig(config);
    this.logger.error(error.message, error, NgxLoggerLevel.ERROR, 'RAW WHOLLY UNPROCESSED ERROR');
  }
}
