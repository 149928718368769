import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DerivedWorkflowFieldService } from '../derived-workflow-field.service';
import { ControlContainerComponent } from '../component-models/control-container.component';
import { TextboxControlComponent } from '../component-models/textbox-control/textbox-control.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TagData, TagifySettings, TagifyService } from 'ngx-tagify';
import { BehaviorSubject, combineLatest, debounceTime, delay, filter, map, merge, Observable, of, share, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { CalculateFieldService } from '../../../../../common/src/util/calculate-field.service';
import { DataLinkService } from '../data-link-populator/data-link.service';
import { FormlyNodeStructureComponentMapping } from '../component-models/formly-controls/utilities/component-path';
import { PriceBookEntryService } from '../../../../../common/src/data/dao-services/pricebook/pricebook-entry.service';
import { FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SaveFormComponent } from '../storage/save-form/save-form.component';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { ImportWorkflowAssignmentService } from '../import-workflow-assignment.service';
import { FormlySectionPricebookMapping } from '../component-models/formly-controls/formly-section/formly-section-pricebook-mapping';
import { SectionComponent } from '../section/section.component';
import { FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { RemapAssociatedComponentsService } from '../component-models/textbox-control/remap-associated-components.service';

function transformTag( tagData ){
  tagData.color = getColorString(tagData['BadgeNumber'] as number);
  tagData.style = "--tag-bg:" + tagData.color;
}

function getColorString(badge: number) : string {
  let color = "";
  switch (badge) {
    // handle 0-9 with different background color style entry for each.
    case 0:
      color = '#ca7878';
      break;
    case 1:
      color = '#ca7878';
      break;
    case 2:
      color = '#7bafd3';
      break;
    case 3:
      color = '#87b456';
      break;
    case 4:
      color = '#33baa8';
      break;
    case 5:
      color = 'darkorange';
      break;
    case 6:
      color = '#7878fc';
      break;
    case 7:
      color = '#07a8a8';
      break;
    case 8:
      color = '#ca9178';
      break;
    case 9:
      color = '#ffbc40';
      break;
    default:
      color = 'rgb(1, 100, 200)';
      break;
  }
  return color;
}

@Component({
  selector: 'app-derived-workflow-field',
  templateUrl: './derived-workflow-field.component.html',
  styleUrls: ['./derived-workflow-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DerivedWorkflowFieldComponent implements OnInit, AfterViewInit, OnDestroy {

  formulaPreview$ : BehaviorSubject<string> = new BehaviorSubject<string>("");
  formulaPreviewIsNumeric$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  formulaPreviewNumeric$ : BehaviorSubject<string> = new BehaviorSubject<string>("");
  formulaComponentUpdate$: BehaviorSubject<null> = new BehaviorSubject<null>(null);
  destroyingComponent$ = new Subject();

  saveFormDialogRef: MatDialogRef<SaveFormComponent>;

  mixedSettings: TagifySettings = {
    mode: 'mix',
    pattern: /:/,
    tagTextProp: 'text',
    duplicates: true,
    dropdown: {
      includeSelectedTags: true,
      enabled: 0,
      position: 'text',
      mapValueTo: 'text',
      highlightFirst: true,
      fuzzySearch: true,
    },
    enforceWhitelist: true,
    transformTag: transformTag,
  };

  formDesignerComponentsInCalculation: ControlContainerComponent [] = [];

  componentsUsedInCalculation$: BehaviorSubject<ControlContainerComponent []> = new BehaviorSubject<ControlContainerComponent []>([]);
  formula = new FormGroup({
    tags: new FormControl<string>(""),
    notes: new FormControl<string>(""),
  });

  tagRegex = /\[\[{"id":"([^"]+)"[^}]*}]]/g

  constructor(public derivedWorkflowFieldService: DerivedWorkflowFieldService, private tagService: TagifyService, private calculationService: CalculateFieldService,
    private dataLinkService: DataLinkService, private fb: FormBuilder, private priceBookEntryService: PriceBookEntryService, private formFirestoreService: FormFirestoreService, private dialog: MatDialog,
    private importDataWorkflowService: ImportWorkflowAssignmentService, private formlyUtilityService: FormlyUtilityService, private remapAssociatedComponentsService: RemapAssociatedComponentsService)
   {

    this.formulaPreview$.pipe(
      map(x => {
        if (x === "") {
          return false;
        }
        return !isNaN(parseFloat(x));
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe(x => this.formulaPreviewIsNumeric$.next(x));

    this.formulaPreview$.pipe(
      filter(x => x !== ""),
      filter(x => !isNaN(parseFloat(x))),
      map(x => parseFloat(x)),
      map(x => Math.round(x * 10**this.derivedWorkflowFieldService.componentBuilding.fields[1].props.numberDecimalPlaces) /
        10**this.derivedWorkflowFieldService.componentBuilding.fields[1].props.numberDecimalPlaces),
      tap(x => this.formulaPreviewNumeric$.next(`${x}`)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.derivedWorkflowFieldService.textboxSelectedToAddToCalc$.pipe(
      takeUntil(this.destroyingComponent$)
    )
      .subscribe((control) => {
      control.suppressClicks = true;
      this.formDesignerComponentsInCalculation.push(control);
      const toAdd = new TextboxControlComponent(this.fb, this.dataLinkService, this.formlyUtilityService, remapAssociatedComponentsService, this.derivedWorkflowFieldService);
      const conf = control.toFormlyFieldConfig();
      toAdd.patchInFormlyFieldConfig(conf);
      toAdd.fields.forEach(x => {
        x.props.numericMaskDesired = conf.props.numericMaskDesired;
        x.props.numberDecimalPlaces = conf.props.numberDecimalPlaces;
        x.props.disabled = false;
        x.modelOptions = {
          updateOn: 'change',
        };
      });
      toAdd.formlyForm.valueChanges.pipe(
        tap(() => this.formulaComponentUpdate$.next(null)),
        takeUntil(this.destroyingComponent$),
      ).subscribe();

      this.formula.patchValue({notes: this.derivedWorkflowFieldService.componentBuilding.componentForm.get("derivedCalculationNote").value});

      this.tagService.get("formula").whitelist = (this.tagService.get("formula").whitelist as TagData[]).concat(
        {"id": toAdd.componentForm.value.guid, "value": `A${control.badgeNumber.value}`, "title": control.componentForm.controls.label.value, "BadgeNumber": control.badgeNumber.value}
      );
      this.componentsUsedInCalculation$.next(this.componentsUsedInCalculation$.value.concat(toAdd));
      toAdd.badgeNumber.next(control.badgeNumber.value);
      toAdd.selectedForDerivedField.next(true);
    });

    this.derivedWorkflowFieldService.buildTagifyFromPatchedInputComponents$.pipe(
      takeUntil(this.destroyingComponent$),
    )
    .subscribe(() => {
      this.buildTagifyFromPatchedInputComponents();
    });

  }
  ngOnDestroy(): void {
    this.destroyingComponent$.next(void 0);
  }
  ngAfterViewInit(): void {
    this.derivedWorkflowFieldService.derivedWorkflowViewAfterViewInit$.next(void 0);

    console.warn(this.derivedWorkflowFieldService.componentBuilding.fields[1]);
  }

  buildTagifyFromPatchedInputComponents() {
    let formlyCalcRepresentation = this.derivedWorkflowFieldService.componentBuilding.componentForm.value.derivedCalculation;
    //iterate over <<guid>> tags in formula and substitute in matching entris from this.tagService.get("formula").whitelist
    formlyCalcRepresentation = formlyCalcRepresentation.replace(/<<[\w|.]+>>/g, (match) => {
      const guid = match.substring(2,match.length-2);
        return `[[${JSON.stringify(this.tagService.get("formula").whitelist.map(x => x as TagData).find(x => x.id === guid))}]]`;
    });
    this.formula.controls.tags.setValue(formlyCalcRepresentation);
  }

  retrieveFormulaWithSubstitutedValues(val: string) : string {
    // replace tags with controls whose guid's match the id's in the tags.
    const components = this.componentsUsedInCalculation$.value;
    let retVal = val.replace(this.tagRegex, (match,p1)  => {
      const component = components.find(c => c.componentForm.value.guid === p1);
      const formlyField = component.componentForm.controls.multiline.value ? component.fields[1] : component.fields[0];
      return typeof (formlyField.formControl.value) === 'string' ? formlyField.formControl.value.replace(",","") : formlyField.formControl.value;
    });
    retVal = this.stripExtraCharactersFromTagControlOutput(retVal);
    return retVal;
  }

  private stripExtraCharactersFromTagControlOutput(retVal: string) {
    retVal = retVal.replace("\r\n", "");
    retVal = retVal.replace(/[\u0000-\u001F\u007F-\u009F\u200B\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "");
    retVal = retVal.trim();
    return retVal;
  }

  ngOnInit(): void {
    combineLatest([this.formula.controls.tags.valueChanges,this.formulaComponentUpdate$]).pipe(
      map(x => x[0]),
      debounceTime(10),
      map(x => this.retrieveFormulaWithSubstitutedValues(x)),
      filter(x => x !== ""),
      map(x => this.calculationService.CalcExpression(x)),
      tap(y => this.formulaPreview$.next(y)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  copySectionAndSaveToFirestore(toSave: FormFirestoreSummary, defaultTitle: string) :Observable<{formFirestore :FormFirestore,guid: string}> {

    const existingDesignFormFirestore$ = this.formFirestoreService.load$(toSave.currentDesignFirestoreDocId);
    const updatedCalculatedComponentGuid = Math.random().toString(36).substring(0,14);
    return existingDesignFormFirestore$.pipe(
      switchMap(existingDesignFormFirestore => this.formFirestoreService.copyWorkflow(existingDesignFormFirestore)),
      take(1),
      tap(formFirestore => {
        formFirestore.form = formFirestore.form.replaceAll(this.derivedWorkflowFieldService.componentBuilding.componentForm.value.guid,updatedCalculatedComponentGuid);
        const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          data: {
          form: formFirestore.form,
          formType: "section",
          formFirestoreDocId: formFirestore.DocId(),
          formFirestoreSummaryDocId: formFirestore.formSummary.DocId(),
          defaultTitle: defaultTitle,
          }});
          this.saveFormDialogRef = this.dialog.open(SaveFormComponent, editorConfig);
        }),
        switchMap(() => this.saveFormDialogRef.afterClosed()),
        switchMap(x => {
          if (x === undefined) {
            return (of(undefined));
          } else {
            return this.formFirestoreService.load$(x.DocId()).pipe(
              filter(x => x.formSummary !== null),
              map(x => {
                return {formFirestore: x, guid: updatedCalculatedComponentGuid};
              })
            );
          }
        })
      );
  }

  Save(): void {
      //Need a bit of thought here as to when we need to save a fresh section, and when we do not.  Definately need to save fresh section when:
      // 1.  is a Unit of Measure section.
      // 2.  is Section, has inputComponentNodeMappings and the full FormSummary chain doesn't contain the first common ancestor for the built node structure mapping.
      // the second of these is a general concern, and should be part of the validation of

      let validate = this.validateCalculation();
      if (!validate) {
        return;
      }

      let currentControl = this.derivedWorkflowFieldService.componentBuilding as ControlContainerComponent;
      do {
        currentControl = currentControl.parentContainer;
      } while (currentControl && currentControl !== null && currentControl.form.value.controlName !== "Section");
      if (currentControl !== null &&  currentControl.componentForm.controls.priceBookMappings?.value !== null &&
          currentControl.componentForm.controls.priceBookMappings.value.find(x => x.formlyTextboxGuid ===
          this.derivedWorkflowFieldService.componentBuilding.componentForm.value.guid)) {
        const priceBookEntryDocId = currentControl.componentForm.controls.priceBookMappings.value.find(x => x.formlyTextboxGuid === this.derivedWorkflowFieldService.componentBuilding.componentForm.value.guid).priceBookEntryDocId;
        const priceBookEntry = this.priceBookEntryService.load$(priceBookEntryDocId).pipe(
          take(1)
        );
        const priceBookEntryFormSummaryNeedsSaved = priceBookEntry.pipe(
          filter(() => (currentControl as SectionComponent).patchedInUnitOfMeasureMapping),
          switchMap(priceBookEntry => this.copySectionAndSaveToFirestore(priceBookEntry.unitOfMeasure.formFirestoreSummary, priceBookEntry.title).pipe(
            map(x => {
              return {priceBookEntry: priceBookEntry, val: x};
            })
          )
        ),
        take(1),
        share(),
        );

        const userFailedToSave = priceBookEntryFormSummaryNeedsSaved.pipe(
          filter(x => x === undefined),
          tap(() => window.alert("To save a calculated field, you must save section as default Unit of Measure section can not be over-written with a calculated field."))
        );

        const userSavedSucessfully = priceBookEntryFormSummaryNeedsSaved.pipe(
          tap(x => {
          (currentControl as SectionComponent).componentFormGroup.patchValue({preventSave: false});
          }),
          filter(x => x.val.formFirestore !== undefined),
          switchMap(x => this.importDataWorkflowService.associateWorkflowWithPriceBookEntry(x.val.formFirestore.formSummary, x.priceBookEntry).pipe(
            tap(() => {
                (currentControl as SectionComponent).patchUpdatedSectionToFormlyBranch$.next({priceBookEntry: x.priceBookEntry.DocId(), summaryDocId: x.val.formFirestore.formSummary.DocId()});
            }),
            map(() => {
              const formlyConfig = JSON.parse(x.val.formFirestore.form);
              formlyConfig.props.title = x.val.formFirestore.formSummary.title;
              formlyConfig.props.formFirestoreDocId = x.val.formFirestore.DocId();
              formlyConfig.props.formFirestoreSummaryDocId = x.val.formFirestore.formSummary.DocId();
              formlyConfig.props.unitOfMeasureMappings = new Array();
              formlyConfig.props.priceBookMappings = [new FormlySectionPricebookMapping({priceBookEntryDocId:  x.priceBookEntry.DocId(), formlyTextboxGuid: x.val.guid})];
              x.val.formFirestore.form = JSON.stringify(formlyConfig);
              (currentControl as SectionComponent).loadForm(x.val.formFirestore);
            return {val: x, section: (currentControl as SectionComponent)};
            }),
            delay(650),
          tap(x => {
            const textBoxFriend = this.derivedWorkflowFieldService.getComponentWithGuid(x.val.val.guid,
              x.section.componentFormGroup.value.columnFormGroups[0].cdkDropListData);
              this.DeleteFromCalc(textBoxFriend);
              textBoxFriend.suppressClicks = true;
              this.formDesignerComponentsInCalculation.push(textBoxFriend);
            this.calculatedComponentPassedPricebookEntryValidation(textBoxFriend);
          })
        )));

        const priceBookEntryFormSummaryValid = priceBookEntry.pipe(
          filter(() => !(currentControl as SectionComponent).patchedInUnitOfMeasureMapping),
          tap(() => this.calculatedComponentPassedPricebookEntryValidation())
        );

        merge(userFailedToSave, userSavedSucessfully, priceBookEntryFormSummaryValid).pipe(
          take(1)
        ).subscribe();

      } else {
        this.calculatedComponentPassedPricebookEntryValidation();
      }
}

  validateCalculation() {
    const formula = this.formula.controls.tags.value;
    const subbed = this.retrieveFormulaWithSubstitutedValues(formula);
    // Confirm that formula is not empty, and valid.
    if (subbed === "") {
      window.alert("Formula can not be empty.");
      return false;
    } else {
      try {
        const calculated = this.calculationService.CalcExpression(subbed);
        if (calculated.indexOf && calculated.indexOf("error") > -1) {
          window.alert("Formula contains syntax error, please correct.");
          return false;
        }
      } catch (e) {
        window.alert(e);
        return false;
      }
    }
    return true;
}

  calculatedComponentPassedPricebookEntryValidation(componentBuilding: ControlContainerComponent = undefined) {
    if (!this.validateCalculation()) {
      return;
    }
    const formula = this.formula.controls.tags.value;
    const pathToCalculatedComponent = this.derivedWorkflowFieldService.PathToComponent(this.derivedWorkflowFieldService.activeDesignViewForm,
        this.derivedWorkflowFieldService.componentBuilding.componentForm.controls.guid.value, this.derivedWorkflowFieldService.oldestAncestorFormSummaryDocId);
    const inputComponentNodeMappings : FormlyNodeStructureComponentMapping[] = [];
    const mappedInputComponentGuids = new Set<string>();
    const subInTextBoxes = formula.replace(this.tagRegex, (match,guid)  => {
      if (!mappedInputComponentGuids.has(guid)) {
        mappedInputComponentGuids.add(guid);
        const component = this.componentsUsedInCalculation$.value.find(c => c.componentForm.value.guid === guid);
        const pathToInputComponent = this.derivedWorkflowFieldService.PathToComponent(this.derivedWorkflowFieldService.activeDesignViewForm,
          component.componentForm.controls.guid.value, this.derivedWorkflowFieldService.oldestAncestorFormSummaryDocId);
        // add path to derived field to each component used in calculation.
        const componentNodeMappings = component.componentForm.controls.calculatedComponentNodeMappings.value as Array<FormlyNodeStructureComponentMapping>;
        const nodeStructureRelationship = this.derivedWorkflowFieldService.BuildFormlyNodeStructureMapping(pathToCalculatedComponent, pathToInputComponent);
        if (componentNodeMappings.find(x => x.CalculatedComponentPath.controlGuid === this.derivedWorkflowFieldService.componentBuilding.componentForm.value.guid) === undefined) {
          componentNodeMappings.push(nodeStructureRelationship);
          component.componentForm.patchValue({calculatedComponentNodeMappings: componentNodeMappings});
        }
          // add path to component field to derived field.
          inputComponentNodeMappings.push(nodeStructureRelationship);
        }
      return `<<${guid}>>`;
    });
    const santizedInput = this.stripExtraCharactersFromTagControlOutput(subInTextBoxes);
    if (componentBuilding === undefined) {
      this.derivedWorkflowFieldService.componentBuilding.componentForm.patchValue({inputComponentNodeMappings: inputComponentNodeMappings, derivedCalculation: santizedInput,
        derivedCalculationNote: this.formula.controls.notes.value});
    } else {
      componentBuilding.componentForm.patchValue({inputComponentNodeMappings: inputComponentNodeMappings, derivedCalculation: santizedInput,
        derivedCalculationNote: this.formula.controls.notes.value});
    }
  }

  SelectBackgroundColor(c: ControlContainerComponent) {
    let color = getColorString((c as TextboxControlComponent).badgeNumber.value);
    return {'background-color': `${color}`};
  }

  DeleteFromCalc(c: ControlContainerComponent) {
    c.suppressClicks = false;
    (c as TextboxControlComponent).selectedForDerivedField.next(false);
    this.componentsUsedInCalculation$.next(this.componentsUsedInCalculation$.value.filter(x => x !== c));
  }

  Exit() {
    this.derivedWorkflowFieldService.componentBuilding.suppressClicks = false;
    this.formDesignerComponentsInCalculation.forEach(x => {
      x.suppressClicks = false;
      (x as TextboxControlComponent).selectedForDerivedField.next(false);
    });
    this.formDesignerComponentsInCalculation = [];
    this.componentsUsedInCalculation$.next([]);
    this.derivedWorkflowFieldService.derivedWorkflowViewExit$.next(void 0);
  }

}
