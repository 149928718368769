<div style="display: flex;">
<div class="header-border header-label">Pricebook Entries</div>
</div>

<div class="mat-table-container">
<table mat-table [dataSource]="importedDataSource"  multiTemplateDataRows class="mat-elevation-z8" recycleRows>

  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplayStandardText">
    <th mat-header-cell class="w-75" *matHeaderCellDef> {{column}} </th>
    <td class="line-item-border-top w-75" mat-cell *matCellDef="let data;">{{data[column]}}</td>
  </ng-container>

  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplayCurrency">
    <th mat-header-cell class="w-75" *matHeaderCellDef> {{column}} </th>
    <td class="line-item-border-top w-75" mat-cell *matCellDef="let data;">{{data[column] | currency}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay" style="height: 40px;"></tr>
<tr class="example-element-row" mat-row *matRowDef="let data; columns: columnsToDisplay;"></tr>
</table>
</div>
