import { Injectable } from '@angular/core'

import {  delayWhen, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../util/authentication.service'
import { CustomerService } from  './customer.service'
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { DiscountService } from './discount.service';
import { EmployeeService } from './employee.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FormFirestore } from '../dao/form-firestore'
import { FormFirestoreSummaryService } from './form-firestore-summary.service';
import { FormFirestoreService } from '../../../../common/src/data/dao-services/form-firestore.service';
import { FormModelFirestoreService } from '../../../../common/src/data/dao-services/form-model-firestore.service';
import { JobService } from './job.service';
import { LineItemService } from './line-item.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { Invoice } from '../dao/invoice';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { Observable, of } from 'rxjs';
import { FormlyUtilityService } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-utility.service';
import { WORKFLOW_STAGE } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/utilities/fetch-updated-workflow.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends DatabaseStoreService<Invoice> {

  invoiceFormFirestore: FormFirestore;

  constructor( fs: InvoiceFirestoreService, authenticationService: AuthenticationService, store: InvoiceStoreService, private customerService: CustomerService,
    private employeeService: EmployeeService, private lineItemService: LineItemService, private discountService: DiscountService,
    private formModelFirestoreService: FormModelFirestoreService, private settingsService: SettingsService, private formFirestoreSummaryService: FormFirestoreSummaryService,
    private formFirestoreService: FormFirestoreService, private jobService: JobService, private formlyUtilityService: FormlyUtilityService) {
super(fs, store, authenticationService,
  new Map([["billingCustomer", {associatedDocumentId: "billingCustomerDocId",compositionStoreService: customerService}],
  ["employee", {associatedDocumentId: "employeeDocId",compositionStoreService: employeeService}],
  ["lineItems", {associatedDocumentId: "lineItemDocIds",compositionStoreService: lineItemService}],
  ["formModelFirestore", {associatedDocumentId: "formModelFirestoreDocId",compositionStoreService: formModelFirestoreService}],
  ["discounts", {associatedDocumentId: "discountDocIds",compositionStoreService: discountService}]]));

  of(null).pipe(
    delayWhen(() => this.settingsService.settingsLoaded$),
    switchMap(() => this.formFirestoreSummaryService.load$(this.settingsService.getValue('defaultFormFirestoreSummaryInvoiceDocId'))),
    filter(x => x != null),
    switchMap(summary => this.formFirestoreService.load$(
      this.formlyUtilityService.workFlowStage === WORKFLOW_STAGE.DESIGN ?
      summary.currentDesignFirestoreDocId : summary.currentDeployedFirestoreDocId)),
    tap(formFirestore => this.invoiceFormFirestore = formFirestore),
    take(1),
  ).subscribe();
  }

  popluateJobsToInvoices(invoices: Invoice[]) : Observable<Invoice[]>{
    return this.jobService.loadMultiple$(invoices.map(invoice => invoice.jobDocId)).pipe(
      map(jobs => {
        jobs.forEach(job => {
          let invoice = undefined;
          do {
            invoice = invoices.find(invoice => invoice.jobDocId === job.jobDocId && invoice.job === null);
            if (invoice !== undefined) {
              invoice.job = job;
            }
          } while (invoice !== undefined);
        });
        return invoices;
      }),
      take(1)
      );
  }

  override performAnyCalculationsNeededToStoreObject(obj: Invoice) : Invoice {
    if (obj.totalAmount!== undefined && obj.totalAmount !== null) {
      obj._totalAmount = obj.totalAmount;
      obj._outstandingDue = obj.outstandingDue;
    }
    return obj;
  }

}

@Injectable({
  providedIn: 'root'
})
export class InvoiceStoreService extends StateChangeStoreService<Invoice> {
  protected store = "Invoice-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, Invoice>(), true, firestoreDiffService);
  }
}
@Injectable({
  providedIn: 'root'
  })
class InvoiceFirestoreService extends FirestoreBackend<Invoice> {

 protected basePath = "Invoice";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Invoice {
return new Invoice(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new Invoice(), authService);
 }
}
