import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AddJobBoardComponent} from './add-job-board/add-job-board.component';
import {JobsPageComponent} from './jobs-page/jobs-page.component';
import {SchedulerViewComponent} from './scheduler-view/scheduler-view.component';
import { CustomerPageComponent } from './customer-page/customer-page.component';
import { SvDataDisplaySelectionComponent } from './scheduler-view/scheduler-view-header/sv-data-display-selection/sv-data-display-selection.component';
import { EmployeeComponent } from './employee.component';
import { FormBuilderComponent } from './form-builder/form-builder/form-builder.component';
import { AuthGuard } from './guard/auth.guard';
import { SettingsComponent } from './setting components/settings.component';
import { CompanySettingsComponent } from './setting components/company-settings/company-settings.component';
import { SchedulerSettingsComponent } from './setting components/scheduler-settings/scheduler-settings.component';
import { CommunicationPreferenceSettingsComponent } from './setting components/communication-preference-settings/communication-preference-settings.component';
import { StatusSettingsComponent } from './setting components/status-settings/status-settings.component';
import { PricebookSettingsComponent } from './setting components/pricebook-settings/pricebook-settings.component';
import { RoleSettingsComponent } from './setting components/role-settings/role-settings.component';
import { BillingSettingsComponent } from './setting components/billing-settings/billing-settings.component';
import { PriceBookComponent } from './price-book/price-book.component';
import { JobTypeComponent } from './setting components/pricebook-settings/job-type/job-type.component';
import { WorkflowSettingsComponent } from './setting components/workflow-settings/workflow-settings.component';
import { AdminComponent } from './admin/admin.component';
import { FormFirestoreWorkflowComponent } from '../../../common/src/components/form-firestore-workflow/form-firestore-workflow.component';
import { ReportTableViewComponent } from './report-table-view/report-table-view.component';
import { TableBasicExample } from './greg-test/greg-test.component';
import { WorkflowDataImportComponent } from './form-builder/workflow-data-import/workflow-data-import.component';
import { DerivedWorkflowFieldComponent } from './form-builder/derived-workflow-field/derived-workflow-field.component';

const routes: Routes = [
  {path: '', redirectTo: "/add-job-board", pathMatch: 'full'},
  {path: 'sign-in', loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)},
  {path: 'reporting', component: ReportTableViewComponent, canActivate:[AuthGuard]},
  {path: 'price-book', component: PriceBookComponent, canActivate:[AuthGuard]},
  {path: 'add-job-board/:jobDocId', component: AddJobBoardComponent, canActivate:[AuthGuard]},
  {path: 'add-job-board', component: AddJobBoardComponent, canActivate:[AuthGuard]},
  {path: 'job-page/:jobDocId', component: JobsPageComponent, canActivate:[AuthGuard]},
  {path: 'job-page', component: JobsPageComponent, canActivate:[AuthGuard]},
  {path: 'app-scheduler-view', component: SchedulerViewComponent , canActivate:[AuthGuard]},
  {path: 'app-customer-page/:customerDocId', component: CustomerPageComponent , canActivate:[AuthGuard]}, //Greg created page
  {path: 'app-customer-page', component: CustomerPageComponent , canActivate:[AuthGuard]}, //Greg created page
  {path: 'app-sv-data-display-selection', component: SvDataDisplaySelectionComponent , canActivate:[AuthGuard]}, //Greg created page

  {path: 'gregTest', component: TableBasicExample , canActivate:[AuthGuard]}, //Greg created page

  {path: 'app-employee', component: EmployeeComponent, canActivate:[AuthGuard]},
  {path: 'app-form-builder', component: FormBuilderComponent, canActivate:[AuthGuard], children: [
    {path: 'load-workflow/:formModelFirestoreDocId/job/:jobDocId/sv/:siteVisitDocId/cust/:customerDocId/inv/:invoiceDocId/est/:estimateDocId', outlet: 'techView', component: FormFirestoreWorkflowComponent},
    {path: 'load-importedData/:importedCat', outlet: 'techView', component: WorkflowDataImportComponent},
    {path: 'load-derived-workflow-builder', outlet: 'techView', component: DerivedWorkflowFieldComponent},
  ]},
  {path: 'load-workflow/facing', component: FormFirestoreWorkflowComponent, canActivate:[AuthGuard]},

  {path: 'app-settings', component: SettingsComponent, canActivate: [AuthGuard], children: [
    {path: 'app-company-settings', outlet: 'settings', component: CompanySettingsComponent},
    {path: 'app-scheduler-settings', outlet: 'settings', component: SchedulerSettingsComponent},
    {path: 'app-communication-preference-settings', outlet: 'settings', component: CommunicationPreferenceSettingsComponent},
    {path: 'app-status-settings', outlet: 'settings', component: StatusSettingsComponent},
    {path: 'app-pricebook-settings', outlet: 'settings', component: PricebookSettingsComponent},
    {path: 'app-role-settings', outlet: 'settings', component: RoleSettingsComponent},
    {path: 'app-billing-settings', outlet: 'settings', component: BillingSettingsComponent},
    {path: 'app-jobtype-settings', outlet: 'settings', component: JobTypeComponent},
    {path: 'app-workflow-settings', outlet: 'settings', component: WorkflowSettingsComponent},
    {path: '', redirectTo: '/app-settings/(settings:app-scheduler-settings)', pathMatch: 'full'}
  ]},
  {path: 'bird-dog', component: AdminComponent, canActivate:[AuthGuard] },
  // Directly loaded when printing out PDF for customer, otherwise customer-preview
  {
    path: 'load-customer-workflow-view/:formModelFirestoreDocId/job/:jobDocId/sv/:siteVisitDocId/cust/:customerDocId/version/:versionNumber',
    children: [
      {
      path: '',
      loadChildren: () => import('../../../common/src/components/customer-facing-workflow/customer-facing-workflow.module').then(m => m.CustomerFacingWorkflowModule),
      }
    ],
    canActivate:[AuthGuard]},

    {
      path: 'load-customer-workflow-view/facing',
      children: [
        {
        path: '',
        loadChildren: () => import('../../../common/src/components/customer-facing-workflow/customer-facing-workflow.module').then(m => m.CustomerFacingWorkflowModule),
        }
      ],
      canActivate:[AuthGuard]},
  {
    path: 'load-customer-preview',
    children: [
      {
      path: '',
      loadChildren: () => import('./customer-preview/customer-preview.module').then(m => m.CustomerPreviewModule),
      }
    ],
    canActivate:[AuthGuard]},
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
