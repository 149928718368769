<div style="display:none">{{changeDetect$|async}}</div>
<!-- <button (click)="testit()">TEST IT NOW</button> -->
<app-navigation class="do-not-print-this"></app-navigation>
<div>
 <mat-checkbox [(ngModel)]="automaticallySaveForm">Autosave</mat-checkbox>
</div>
<div class="board">
 <!-- Sidebar Component. -->
 <div cdkDropList class = "card-list sidebar-style mat-elevation-z1 do-not-print-this" [cdkDropListData]="sideBarDropZone.draggableComponents"
   (cdkDropListDropped)="onTalkDropSidebar($event)" [cdkDropListConnectedTo]="dropListIdsForSidebar" [cdkDropListDisabled]=false
   [id]="sideBarDropZone.id">
   <h2 class="mat-h2 h-tag" style="font-size: 30px;">{{sideBarDropZone.name}}</h2>
   <div class="control-columns">
   <div *ngFor="let c of sideBarDropZone.draggableComponents; trackBy:trackByComponentPerInstance"
   cdkDrag [cdkDragData]="c" [cdkDragStartDelay]="50"
   [formGroup]="c.untypedForm">
     <button value="{{c}}" style="background-color: white; border: 1px solid #8d8d8d; border-radius: 4px;">
       <mat-icon [ngStyle]="getIconStyling(c)">{{c.untypedForm.get('icon').value}}</mat-icon>
       {{c.untypedForm.get('controlName').value}}
     </button>
     </div>
   </div>
   <div >
     <div >
     </div>
     <app-sidebar-component-editor *ngIf="componentSideViewForm$|async"
       [form$]=componentSideViewForm$
       [parentForm$]=componentSideViewParentForm$
       [newControl]=newFormForSidebar$
       (buildCaclulatedField)="prepareToLoadCalcuatedField$.next($event)"
       (deleteCalculatedField)="deleteCalculationFromField$.next($event)"
       >
     </app-sidebar-component-editor>
   </div>
 </div>


 <div class="main-and-live" main-section>




 <!-- Main Component. -->
 <div class="do-not-print-this form-design-side" style="margin-right: 8px;">
   <app-workflow-header class="main"
     [activeFormFirestore$]="activeFormFirestore$"
     [referencedComponents]="mainDropZone.draggableComponents"
     [isMain]="true"
     [activeWorkflowType]="activeWorkflowType"
     [unsavedChanges$]="unsavedChanges"
     (copyWorkflowClicked)="copyWorkflow()"
     (loadWorkflowClicked)="loadWorkflow()"
     (saveWorkflowClicked)="saveWorkflow($event)"
     (newWorkflowClicked)="newWorkflow($event)"
     (workflowActivationStatusToggled)="toggleWorkflowActivationStatus()"
     (revertToDeployedWorkflowClicked) = "revertToDeployedWorkflow()"
     (associateWithImportedDataClicked)="associateWithImportedData()"
     (assignSectionToPricebookEntryClicked)="assignedSectionToPriceBookEntryWorkflow()"
     (deleteImportedDataAssociationClicked)="deleteImportedDataAssociation()"
     (deployWorkflowClicked) = "deployWorkflow($event)"
   ></app-workflow-header>
   <div cdkDropList class = "card-list design-view mat-elevation-z1 main"
      [cdkDropListData]="mainDropZone.draggableComponents"
      [cdkDropListDisabled]=false
      [id]="mainDropZone.id"
    >

      <!-- Drop zone before first component -->
      <div cdkDropList class = "main-form-content"
        style="background-color: red; width: 100%;"
        [cdkDropListData]="mainDropZones[0].draggableComponents"
        (cdkDropListDropped)="onTalkDropMain($event)"
        [cdkDropListConnectedTo]="dropListForMainFiltered(0)"
        [cdkDropListDisabled]=false
        [id]="mainDropZones[0].id"
      >
      <h5>{{mainDropZones[0].id}}</h5>
    </div>
        <div style="padding-top: 35px;"> <!--This div only exists so the above H2 tag does not overlap the live view proper-->
          <div *ngFor="let component of mainDropZone.draggableComponents; trackBy:trackByComponentPerInstance; let i = index"
            cdkDrag [cdkDragStartDelay]="100"
            [cdkDragData]="component"
            [cdkDragDisabled]="!component.serializeToDatabase"
            class = "main-form-content"
            style="width: 100%"
          >
            <app-form-element
            [componentToInstantiate]="component"
            (componentClicked$)="componentClicked$.next($event)"
            ></app-form-element>
            <div cdkDropList class = "main-form-content"
              style="background-color: red; width: 100%;"
              [cdkDropListData]="mainDropZones[i+1].draggableComponents"
              (cdkDropListDropped)="onTalkDropMain($event)"
              [cdkDropListConnectedTo]="dropListForMainFiltered(i+1)"
              [cdkDropListDisabled]=false
              [id]="mainDropZones[i+1].id"
            >
            <div *cdkDragPreview class = "main-form-content" style="width: 100%">
              <app-form-element
                [componentToInstantiate]="component"
                (componentClicked$)="componentClicked$.next($event)"
            ></app-form-element>
            </div>

      <h5>{{mainDropZones[i+1].id}}</h5>
    </div>
          </div>
        </div>
      </div>
   </div>




 <div class="pageBreak" #referencePageBreak style='top:11in;'></div>


 <div class="tech-view-side">
   <div class="tech-view-header">
     <app-auxiliary-header
     (loadImportedData)="loadImportedData$.next($event)"
     (loadLiveAction)="loadLiveAction$.next($event)"
     (generatePricebookData)="generatePricebookData$.next($event)"
     style="max-width: 80px;"
     ></app-auxiliary-header>
 <!-- Live view component-->
     <div style="display: flex; justify-content: center; align-items: center;">
       <div class="form-designer-header">Technician View</div>
     </div>
     <div *ngIf="formlyUtilityService.activeAudience == 2; else loadTechView" style="display: flex; justify-content: flex-end; align-items: center; margin-right: 5px;">
         <button  mat-button class="button-blue" (click)="ShowCustomerView()" [disabled]="!loadedInitialTechView">Customer View</button>
     </div>

     <ng-template #loadTechView>
       <div style="display: flex; justify-content: flex-end; align-items: center; margin-right: 5px;">
       <button style="min-width: 112.61px;" #loadTechView mat-button class="button-blue" (click)="ShowTechView()" [disabled]="!loadedInitialTechView">Tech View</button>
       </div>
     </ng-template>

 </div>
   <div class = "live-view">
     <!-- Tech View -->
       <router-outlet name="techView"></router-outlet>
   </div>
   </div>
 </div>
</div>






