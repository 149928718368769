import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnDestroy, OnInit, inject } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { FormlyFieldTextArea } from '@ngx-formly/material/textarea';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { BehaviorSubject, combineLatest, map, Observable, of, startWith, Subject } from 'rxjs';
import { debounceTime, delay, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FieldType,FormlyFieldProps } from '@ngx-formly/material/form-field';
import { CalculateFieldService } from '../../../../../../../common/src/util/calculate-field.service';
import { FormlyNodeStructureComponentMapping } from '../utilities/component-path';
import { DerivedWorkflowFieldPopulatorService } from '../../../derived-workflow-field-populator.service';
import { FormFirestoreWorkflowComponentService } from '../../../../../../../common/src/components/form-firestore-workflow/form-firestore-workflow-component.service';

interface TextAreaProps extends FormlyFieldProps {
  autosize?: boolean;
  autosizeMinRows?: number;
  autosizeMaxRows?: number;
  numericMaskDesired?: boolean;
  numberDecimalPlaces?: number;
  inputComponentNodeMappings: FormlyNodeStructureComponentMapping[];
  derivedCalculation: null | string;
  guid: string;
  designView: boolean;
  priceBookEntryToolTip$: BehaviorSubject<string | null>;
}

@Component({
  selector: 'app-formly-textbox-autosize-triggerable',
  styleUrls: ['./formly-textbox-autosize-triggerable.component.scss'],
  template: `
    <textarea
      matInput
      [id]="id"
      [readonly]="props.readonly"
      [required]="required"
      [formControl]="typedFormControl"
      [errorStateMatcher]="errorStateMatcher"
      [cols]="props.cols"
      [rows]="props.rows"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [tabindex]="props.tabindex"
      [cdkTextareaAutosize]="props.autosize"
      [cdkAutosizeMinRows]="props.autosizeMinRows"
      [cdkAutosizeMaxRows]="props.autosizeMaxRows"
      [class.cdk-textarea-autosize]="props.autosize"
      [dropSpecialCharacters]="false"
      [mask]="numericMask"
      [thousandSeparator]="thousandSeparator"
      [allowNegativeNumbers]="true"
      [clearIfNotMatch]="false"
      #cfcAutosize="cdkTextareaAutosize"
      [ngClass]="props.derivedCalculation && !props.designView ? 'derived-calc' : ''"
    >
    </textarea>
    <div *ngIf="props.designView && (props.priceBookEntryToolTip$|async) !== null" class="dataset-linked-icon" matPrefix style="max-height: 10px; display: contents;">
   <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format"
      matTooltipPosition="right"
      matTooltip="{{props.priceBookEntryToolTip$|async}}">
      dataset_linked
    </mat-icon>
   </div>
   <div *ngIf="props.derivedCalculation" class="calculate-icon" matPrefix style="max-height: 10px; display: contents;">
   <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format"
      matTooltipPosition="right"
      matTooltip="Field is a calculated value">
      calculate
  </mat-icon>
   </div>

  `,

  providers: [
    // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
    // rely on formControl value instead of elementRef which return empty value in Firefox.
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: FormlyFieldTextArea },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormlyTextboxAutosizeTriggerableComponent extends FieldType<FieldTypeConfig<TextAreaProps>> implements AfterViewInit, OnInit, OnDestroy
{

  constructor(private formFirestoreWorkflowComponentService: FormFirestoreWorkflowComponentService) {
    super();
  }

  numericMask = "";
  thousandSeparator=",";
  expandedCalculation: string = "";
  private calculationService: CalculateFieldService = inject(CalculateFieldService);
  private derivedWorkflowFieldPopulatorService : DerivedWorkflowFieldPopulatorService = inject(DerivedWorkflowFieldPopulatorService);

  ngOnInit(): void {
    if (this.props.numericMaskDesired) {
      this.numericMask = `separator.${this.props.numberDecimalPlaces}`;
      this.thousandSeparator=",";
    } else {
      this.numericMask = ""
      this.thousandSeparator="";
    }

  }

  destroyingComponent$ = new Subject();
  afterViewInitCalled$ = new Subject();

  get typedFormControl() { return this.formControl as FormControl<any>; }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  @ViewChild('cfcAutosize') contentFCAutosize: CdkTextareaAutosize;

  ngAfterViewInit(): void {

    if (this.props.derivedCalculation !== null && this.props.designView === false) {
      const obs : Observable<any>[] = [];
      // regEx which matches all characters between << and >>.  Each of this maps 1:1 to inputComponent.props.guid
      const regEx = /<<([^>>]+)>>/g;
      this.props.derivedCalculation.match(regEx).forEach((match) => {
        const textBoxGuid = match.replace("<<","").replace(">>","");
        const componentTextBoxControl = this.derivedWorkflowFieldPopulatorService.GetFormlyTextboxAssociatedWithGuid(textBoxGuid, this.props.inputComponentNodeMappings, this.field);
        if (componentTextBoxControl?.formControl) {
          obs.push(componentTextBoxControl.formControl.valueChanges.pipe(
            startWith(componentTextBoxControl.formControl.value),
            tap(x => {
              try {
                if (x === "" && componentTextBoxControl.props.numericMaskDesired === true) {
                  componentTextBoxControl.formControl.setValue(componentTextBoxControl.defaultValue);
                }
              } catch (e) {
                console.error(e);
              }

            }),
            map(x => {
              return {textBoxGuid: textBoxGuid, value: x};
            }),
          ));
        }
      });

      combineLatest(obs).pipe(
        map(x => {
          let populatedCalc = this.props.derivedCalculation;
          x.forEach(y => {
            populatedCalc = populatedCalc.replaceAll(`<<${y.textBoxGuid}>>`, typeof(y.value) === "string"  ? y.value.replaceAll(",","") : y.value);
          });
          return populatedCalc;
        }),
        map(x => this.calculationService.CalcExpression(x)),
        tap(x => this.formControl.setValue(x)),
        takeUntil(this.formFirestoreWorkflowComponentService.destroyAllFormFirestoreWorkflowComponentObservables),
      ).subscribe();
    }

    if (this.props['changeDetect'] !== undefined) {
      this.props['changeDetect'].pipe(
        // This debounce shouldn't really need to be here.  But as components are moved around the form design view, an
        // increasing number of emissions on change detect occur.  Should be fixed at some point, to replicate add
        // a single text box, and then move it between two columns.  This is a workaround for now.
        debounceTime(25),
        tap(() => this.contentFCAutosize.resizeToFitContent(true)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
    } else {
      of(true).pipe(
        delay(50),
        tap(() => this.contentFCAutosize.resizeToFitContent(true))).subscribe();
    }
  }

  override defaultOptions = {
    props: {
      cols: 1,
      rows: 1,
      guid: Math.random().toString(36).substring(0,14),
      derivedCalculation: null,
      inputComponentNodeMappings: [],
      designView: false,
      priceBookEntryToolTip$: new BehaviorSubject(<string | null>(null)),
    },
  };
}
